import NabuButton from '@/common/components/atoms/button/button';
import LengthSelector from '@/common/components/atoms/lengthSelector/lengthSelector';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import { DeliverableTypeOptions, EditingProvisionsOptions } from '@/common/constants/constants';
import { StatusCodes } from '@/common/enums/StatusCodes';
import useNotification from '@/common/hooks/useNotification';
import { getNotificationText } from '@/common/utils/utils';
import { saveDeliverable } from '@/services/element';
import { yupResolver } from '@hookform/resolvers/yup';
import { Save } from '@mui/icons-material';
import { Stack } from '@mui/joy';
import { FormControl, FormLabel, Grid } from '@mui/material';
import { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { DeliverableModel } from '../shared/models/deliverableModel';
import { formSchema } from '../shared/schema/schema';
import styles from './../deliverables.module.scss';

interface IProp {
	onClose: () => void;
	slot?: string;
	isEdit: boolean;
	formState: boolean;
	formData: DeliverableModel
	refresh: () => any;
}

const DeliverabelForm = (props: IProp) => {
	const [loading, setLoading] = useState(false);

	const form = useForm<DeliverableModel>({
		defaultValues: { ...props.formData },
		resolver: yupResolver(formSchema),
		mode: 'onChange'
	});

	const errors = form.formState.errors;

	const notification = useNotification();

	const { mutate: addDeliverable } = useMutation({
		mutationFn: saveDeliverable,
		onSuccess: (res) => {
			if(res?.status === StatusCodes.OK) {
				notification.notify({
					message: props.isEdit ? getNotificationText('Deliverable', 'UPDATE') : getNotificationText('Deliverable', 'CREATE'),
					type: 'success'
				});
				props.onClose && props.onClose();
				props.refresh && props.refresh();
			}
			else
				notification.notify({
					message: 'Error while creating Deliverable.',
					type: 'error'
				});
			setLoading(false);
		},
		onError: () => {
			notification.notify({
				message: 'Error while creating Payroll Vendor.',
				type: 'error'
			});
		}
	});

	const onSubmit = (data: DeliverableModel) => {
		addDeliverable(data);
		setLoading(true);
	};

	useEffect(() => {
		form.reset({ ...props.formData });
	}, [props.formState]);

	const deliverableTypeOptions = DeliverableTypeOptions.map((option) => {
		return {
			label: option,
			value: option
		};
	});

	const editingProvisionOptions = EditingProvisionsOptions.map((option) => {
		return {
			label: option,
			value: option
		};
	});

	const numberDefinedValue = 'NUMBER DEFINED';

	return <form onSubmit={form.handleSubmit(onSubmit)}>
		<div className={styles.linkedAssetsSection}>
			<Grid item container columnSpacing={3}>
				<Grid item sm={12} md={2.4}>
					<FormControl fullWidth>
						<FormLabel>Number Of Assets<sup>*</sup></FormLabel>
						<Controller
							name={'numberOfAssets'}
							control={form.control}
							render={({ field }) => (
								<NabuTextbox
									className={styles.websiteInput}
									data-cy='person-firstname'
									{...field}
									onChange={e => {
										if(parseInt(e?.target?.value) >= 1 || e?.target?.value ==='') field.onChange(e);
									}}
									placeholder={'Number of assets'}
									error={errors?.numberOfAssets ? true : false}
									helperText={errors?.numberOfAssets && errors?.numberOfAssets?.message} />
							)} />
					</FormControl>
				</Grid>
				<Grid item sm={12} md={2.4}>
					<FormControl fullWidth>
						<FormLabel>Type<sup>*</sup></FormLabel>
						<Controller
							name={'type'}
							control={form.control}
							render={({ field }) => (
								<NabuSelect
									data-cy='contact_name_select'
									placeholder='Select Type'
									{...field}
									error={errors?.type ? true : false}
									helperText={errors?.type && errors?.type?.message}
									options={deliverableTypeOptions} />
							)} />
					</FormControl>
				</Grid>
				<Grid item sm={12} md={2.4}>
					<FormControl fullWidth className={styles.lengthField}>
						<FormLabel>Length <sup></sup></FormLabel>
						<Controller
							control={form.control}
							name='length'
							render={({ field }) => (
								<LengthSelector 
									{...field}
									placeholder='Length (MM : SS)'
									rootClassName={styles.assetLengthSelector}
								/>
							)}
						/>
					</FormControl>
				</Grid>
				<Grid item sm={12} md={2.4}>
					<FormControl fullWidth>
						<FormLabel>Editing Provisions <sup></sup></FormLabel>
						<Controller
							name={'editingProvisions'}
							control={form.control}
							render={({ field }) => (
								<NabuSelect
									data-cy='contact_name_select'
									placeholder='Select Editing Provision'
									{...field}
									options={editingProvisionOptions}
									onChange={(e) => {
										field.onChange(e);
										if(e?.target?.value === 'UNLIMITED'){
											form.setValue('noOfEdits', '');
										}
									}}
								/>
							)} />
					</FormControl>
				</Grid>
				{form.watch('editingProvisions') == numberDefinedValue && <Grid item sm={12} md={2.4}>
					<FormControl fullWidth>
						<FormLabel>Number Of Edits Allowed<sup></sup></FormLabel>
						<Controller
							name={'noOfEdits'}
							control={form.control}
							render={({ field }) => (
								<NabuTextbox
									className={styles.websiteInput}
									data-cy='person-firstname'
									{...field}
									onChange={e => {
										if(parseInt(e?.target?.value) >= 1 || e?.target?.value ==='') field.onChange(e);
									}}
									placeholder={'Number of edits'}
									error={errors?.noOfEdits ? true : false}
									helperText={errors?.noOfEdits && errors?.noOfEdits?.message} />
							)} />
					</FormControl>
				</Grid>}
				<Grid item sm={12} md={12} dir='rtl'>
					<Stack direction={'row'}>
						<NabuButton variant='outlined' shouldHandleConfirmAction={form.formState.isDirty} onClick={props.onClose}>Cancel</NabuButton> &nbsp;
						{!loading ? <NabuButton variant='filled' type='submit'>Save <Save /></NabuButton> : <NabuButton variant='filled' type='submit'>Submitting<Save /></NabuButton>} &nbsp;

					</Stack>
				</Grid>
			</Grid>
		</div></form>;
};

export default DeliverabelForm;