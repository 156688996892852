import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import { IBaseContract } from '@/common/types/contract';
import { FormControl, FormHelperText } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import styles from '../forms/forms.module.scss';
import { CONTRACT_REF_ID } from '@/common/constants/constants';

interface Props {
	contractForm: UseFormReturn<IBaseContract | any, any, undefined>;
	isPrint?: boolean;
}

const ContractNYWageTheftPreventionForm = (props: Props) => {
	const contractForm = props?.contractForm as UseFormReturn<IBaseContract, any, undefined>;


	return (
		<>
			<div id={CONTRACT_REF_ID.NY_THEFT_PREVENTION_FORM} className={styles.contractFormBlock} style={{padding:'20px'}}>
				<div className={styles.bgWhite}>
					<h1 className={styles.nyFormTitle}>NY Wage Theft Prevention Form </h1>
					<p>
						<b>
							NOTICE AND ACKNOWLEDGMENT OF PAY RATE AND PAYDAY UNDER SECTION
							195.1 OF THE NEW YORK STATE LABOR LAW
						</b>
					</p>
					<br></br>
					<p>
						Notice for Employees Paid Salary for Varying Hours, Day Rate, Piece
						Rate, Flat Rate or Other Non-Hourly Pay
					</p>

					<table
						className={styles.contractForms}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
					>
						<tbody>
							<tr style={{ background: '#00b0b9' }}>
								<td colSpan={2} style={{ color: '#FFF' }}>
									EMPLOYER INFORMATION
								</td>
							</tr>
							<tr>
								<td>
									<p>Name</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractNYTheftPreventionForm.employerName'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractNYTheftPreventionForm?.employerName?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractNYTheftPreventionForm?.employerName?.message
											}
										</FormHelperText>
									)}
								</td>
								<td>
									<p>Doing Business As (DBA) Name(s)</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractNYTheftPreventionForm.dbaName'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractNYTheftPreventionForm?.dbaName?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractNYTheftPreventionForm?.dbaName?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
							<tr>
								<td>
									<p>FEIN (optional)</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractNYTheftPreventionForm.fein'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractNYTheftPreventionForm?.fein?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractNYTheftPreventionForm?.fein?.message
											}
										</FormHelperText>
									)}
								</td>
								<td>
									<p>Phone</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractNYTheftPreventionForm.phone'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint} type='number'/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractNYTheftPreventionForm?.phone?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractNYTheftPreventionForm?.phone?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<p>Physical Address</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractNYTheftPreventionForm.physicalAddress'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractNYTheftPreventionForm?.physicalAddress?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractNYTheftPreventionForm?.physicalAddress?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<p>Mailing Address</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractNYTheftPreventionForm.mailingAddress'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractNYTheftPreventionForm?.mailingAddress?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractNYTheftPreventionForm?.mailingAddress?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
						</tbody>
					</table>
					<table
						className={styles.contractForms}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
						style={{ borderTop: '0' }}
					>
						<tbody>
							<tr style={{ background: '#00b0b9' }}>
								<td colSpan={4} style={{ color: '#FFF' }}>
									WAGE DETAILS
								</td>
							</tr>
							<tr>
								<td width={180}>Notice Given</td>

								<td>
									<div
										style={{
											display: 'flex',
											gap: '5px',
											alignItems: 'center',
										}}
									>
										<Controller
											control={contractForm.control}
											name='contractNYTheftPreventionForm.isNoticeGivenAtHiring'
											defaultValue={false}
											render={({ field }) => (
												<div
													onClick={() => field.onChange(!field.value)}
													style={{ userSelect: 'none' }}
												>
													<input
														{...field}
														value={field.value + ''}
														checked={field.value}
														onChange={(e) => field.onChange(e)}
														type='checkbox'
														disabled={props?.isPrint}
														style={{ width: '20px', marginTop: '2px' }}
													/>
													<span>At Hiring</span>
												</div>
											)}
										/>
									</div>
								</td>
								<td>
									<div
										style={{
											display: 'flex',
											gap: '5px',
											alignItems: 'center',
										}}
									>
										<Controller
											control={contractForm.control}
											name='contractNYTheftPreventionForm.isNoticeGivenAtBeforeChangeInPay'
											defaultValue={false}
											render={({ field }) => (
												<div
													onClick={() => field.onChange(!field.value)}
													style={{ userSelect: 'none' }}
												>
													<input
														{...field}
														value={field.value + ''}
														checked={field.value}
														onChange={(e) => field.onChange(e)}
														type='checkbox'
														disabled={props?.isPrint}
														style={{ width: '20px', marginTop: '2px' }}
													/>
													<span>
														Before a change in pay rate(s), allowances claimed or
														payday
													</span>
												</div>
											)}
										/>
									</div>
								</td>
								<td>
									Regular Payday
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractNYTheftPreventionForm.regularPayDay'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractNYTheftPreventionForm?.regularPayDay?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractNYTheftPreventionForm?.regularPayDay?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
							<tr>
								<td colSpan={4}>
									<p>
										Employee’s Pay Rate (specify the basis for the rate paid,
										i.e. salary for varying hours, day rate, etc.)
									</p>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											flexWrap: 'wrap',
										}}
									>
										<span>$</span>
										<FormControl className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='contractNYTheftPreventionForm.employeesPayRate'
												render={({ field }) => (
													<>
														<NabuTextbox {...field} disabled={props?.isPrint} type='number'/>
													</>
												)}
											/>
										</FormControl>
										{contractForm?.formState?.errors?.contractNYTheftPreventionForm?.employeesPayRate?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractNYTheftPreventionForm?.employeesPayRate?.message
												}
											</FormHelperText>
										)}
										<span>per </span>
										<FormControl className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='contractNYTheftPreventionForm.employeesPayPer'
												render={({ field }) => (
													<>
														<NabuTextbox {...field} disabled={props?.isPrint}/>
													</>
												)}
											/>
										</FormControl>
										{contractForm?.formState?.errors?.contractNYTheftPreventionForm?.employeesPayPer?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractNYTheftPreventionForm?.employeesPayPer?.message
												}
											</FormHelperText>
										)}
									</div>
									<p>
										Employers may not pay a non-hourly rate to a non-exempt
										employee in the Hospitality Industry, except for
										commissioned sales people.
									</p>
								</td>
							</tr>
						</tbody>
					</table>
					<table
						className={styles.contractForms}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
						style={{ borderTop: '0' }}
					>
						<tr>
							<td rowSpan={2} width={180}>
								Allowances Taken
							</td>
							<td>
								<div
									style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
								>
									<Controller
										control={contractForm.control}
										name='contractNYTheftPreventionForm.isAllowanceTaken'
										defaultValue={false}
										render={({ field }) => (
											<div
												onClick={() => field.onChange(!field.value)}
												style={{ userSelect: 'none' }}
											>
												<input
													{...field}
													value={field.value + ''}
													checked={field.value}
													onChange={(e) => field.onChange(e)}
													type='checkbox'
													disabled={props?.isPrint}
													style={{ width: '20px', marginTop: '2px' }}
												/>
												<span>None</span>
											</div>
										)}
									/>
								</div>
							</td>
							<td>
								<div
									style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
								>
									<Controller
										control={contractForm.control}
										name='contractNYTheftPreventionForm.isAllowanceTipTaken'
										defaultValue={false}
										render={({ field }) => (
											<div
												onClick={() => field.onChange(!field.value)}
												style={{ userSelect: 'none' }}
											>
												<input
													{...field}
													value={field.value + ''}
													checked={field.value}
													onChange={(e) => field.onChange(e)}
													type='checkbox'
													disabled={props?.isPrint}
													style={{ width: '20px', marginTop: '2px' }}
												/>
												<span>Tips</span>
											</div>
										)}
									/>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractNYTheftPreventionForm.allowanceTipAmount'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractNYTheftPreventionForm?.allowanceTipAmount?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractNYTheftPreventionForm?.allowanceTipAmount?.message
											}
										</FormHelperText>
									)}
									<span>per hour</span>
								</div>
							</td>
							<td colSpan={2}>
								<div
									style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
								>
									<Controller
										control={contractForm.control}
										name='contractNYTheftPreventionForm.isAllowanceMealTaken'
										defaultValue={false}
										render={({ field }) => (
											<div
												onClick={() => field.onChange(!field.value)}
												style={{ userSelect: 'none' }}
											>
												<input
													{...field}
													value={field.value + ''}
													checked={field.value}
													onChange={(e) => field.onChange(e)}
													type='checkbox'
													disabled={props?.isPrint}
													style={{ width: '20px', marginTop: '2px' }}
												/>
												<span>Meals</span>
											</div>
										)}
									/>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractNYTheftPreventionForm.allowanceMealAmount'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractNYTheftPreventionForm?.allowanceMealAmount?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractNYTheftPreventionForm?.allowanceMealAmount?.message
											}
										</FormHelperText>
									)}
									<span>Meals</span>
								</div>
							</td>
						</tr>
						<tr>
							<td colSpan={2}>
								<div
									style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
								>
									<Controller
										control={contractForm.control}
										name='contractNYTheftPreventionForm.isAllowanceLodgingTaken'
										defaultValue={false}
										render={({ field }) => (
											<div
												onClick={() => field.onChange(!field.value)}
												style={{ userSelect: 'none' }}
											>
												<input
													{...field}
													value={field.value + ''}
													checked={field.value}
													onChange={(e) => field.onChange(e)}
													type='checkbox'
													disabled={props?.isPrint}
													style={{ width: '20px', marginTop: '2px' }}
												/>
												<span>Lodging:</span>
											</div>
										)}
									/>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractNYTheftPreventionForm.lodgingAmount'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint} type='number'/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractNYTheftPreventionForm?.lodgingAmount?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractNYTheftPreventionForm?.lodgingAmount?.message
											}
										</FormHelperText>
									)}
								</div>
							</td>
							<td colSpan={2}>
								<div
									style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
								>
									<Controller
										control={contractForm.control}
										name='contractNYTheftPreventionForm.isOtherAllowance'
										defaultValue={false}
										render={({ field }) => (
											<div
												onClick={() => field.onChange(!field.value)}
												style={{ userSelect: 'none' }}
											>
												<input
													{...field}
													value={field.value + ''}
													checked={field.value}
													onChange={(e) => field.onChange(e)}
													type='checkbox'
													disabled={props?.isPrint}
													style={{ width: '20px', marginTop: '2px' }}
												/>
												<span>Other:</span>
											</div>
										)}
									/>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractNYTheftPreventionForm.otherAllowanceAmount'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractNYTheftPreventionForm?.otherAllowanceAmount?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractNYTheftPreventionForm?.otherAllowanceAmount?.message
											}
										</FormHelperText>
									)}
								</div>
							</td>
						</tr>
						<tr>
							<td>Pay Is</td>
							<td colSpan={2}>
								<div
									style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
								>
									<Controller
										control={contractForm.control}
										name='contractNYTheftPreventionForm.isPayWeekly'
										defaultValue={false}
										render={({ field }) => (
											<div
												onClick={() => field.onChange(!field.value)}
												style={{ userSelect: 'none' }}
											>
												<input
													{...field}
													value={field.value + ''}
													checked={field.value}
													onChange={(e) => field.onChange(e)}
													type='checkbox'
													disabled={props?.isPrint}
													style={{ width: '20px', marginTop: '2px' }}
												/>
												<span>Weekly</span>
											</div>
										)}
									/>
								</div>
							</td>
							<td>
								<div
									style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
								>
									<Controller
										control={contractForm.control}
										name='contractNYTheftPreventionForm.isPayBiWeekly'
										defaultValue={false}
										render={({ field }) => (
											<div
												onClick={() => field.onChange(!field.value)}
												style={{ userSelect: 'none' }}
											>
												<input
													{...field}
													value={field.value + ''}
													checked={field.value}
													onChange={(e) => field.onChange(e)}
													type='checkbox'
													disabled={props?.isPrint}
													style={{ width: '20px', marginTop: '2px' }}
												/>
												<span>Bi-weekly:</span>
											</div>
										)}
									/>
								</div>
							</td>
							<td>
								<div
									style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
								>
									<Controller
										control={contractForm.control}
										name='contractNYTheftPreventionForm.isOtherPay'
										defaultValue={false}
										render={({ field }) => (
											<div
												onClick={() => field.onChange(!field.value)}
												style={{ userSelect: 'none' }}
											>
												<input
													{...field}
													value={field.value + ''}
													checked={field.value}
													onChange={(e) => field.onChange(e)}
													type='checkbox'
													disabled={props?.isPrint}
													style={{ width: '20px', marginTop: '2px' }}
												/>
												<span>Other:</span>
											</div>
										)}
									/>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractNYTheftPreventionForm.otherPayType'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractNYTheftPreventionForm?.otherPayType?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractNYTheftPreventionForm?.otherPayType?.message
											}
										</FormHelperText>
									)}
								</div>
							</td>
						</tr>
						<tr>
							<td colSpan={5}>
								<p>
									<b>Overtime Pay Rate:</b> In most cases the overtime rate will
									be 1½ times the regular rate of pay for the week. The regular
									rate of pay is the total weekly pay divided by the hours
									worked in the week. In most cases, it is illegal to pay a
									fixed weekly rate for varying hours worked over 40 per week.
									The Department of Labor strongly discourages weekly rates for
									non-exempt employees, since underpayments often result
								</p>
								<br />
							</td>
						</tr>
					</table>
					<table
						className={styles.contractForms}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
						style={{ borderTop: '0' }}
					>
						<tbody>
							<tr style={{ background: '#00b0b9' }}>
								<td colSpan={2} style={{ color: '#FFF' }}>
									EMPLOYEE ACKNOWLEDGMENT
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<p>
										On this day, I received notice of my pay rate, overtime rate
										(if eligible), allowances, and designated payday. I told my
										employer what my primary language is.
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<div
										style={{
											display: 'flex',
											gap: '5px',
											alignItems: 'center',
										}}
									>
										<Controller
											control={contractForm.control}
											name='contractNYTheftPreventionForm.isPrimaryLanguageEnglish'
											defaultValue={false}
											render={({ field }) => (
												<div
													onClick={() => field.onChange(!field.value)}
													style={{ userSelect: 'none' }}
												>
													<input
														{...field}
														value={field.value + ''}
														checked={field.value}
														onChange={(e) => field.onChange(e)}
														type='checkbox'
														disabled={props?.isPrint}
														style={{ width: '20px', marginTop: '2px' }}
													/>
													<span>At Hiring</span>
												</div>
											)}
										/>
										<span>
											I have been given this pay notice in English because it is
											my primary language.
										</span>
									</div>
								</td>
								<td>
									<div
										style={{
											display: 'flex',
											gap: '5px',
											alignItems: 'center',
										}}
									>
										<Controller
											control={contractForm.control}
											name='contractNYTheftPreventionForm.isPrimaryLanguageOther'
											defaultValue={false}
											render={({ field }) => (
												<div
													onClick={() => field.onChange(!field.value)}
													style={{ userSelect: 'none' }}
												>
													<input
														{...field}
														value={field.value + ''}
														checked={field.value}
														onChange={(e) => field.onChange(e)}
														type='checkbox'
														disabled={props?.isPrint}
														style={{ width: '20px', marginTop: '2px' }}
													/>
													<span>At Hiring</span>
												</div>
											)}
										/>
										<span>My primary language is:</span>
										<FormControl className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='contractNYTheftPreventionForm.primaryLanguage'
												render={({ field }) => (
													<>
														<NabuTextbox {...field} disabled={props?.isPrint}/>
													</>
												)}
											/>
										</FormControl>
										{contractForm?.formState?.errors?.contractNYTheftPreventionForm?.primaryLanguage?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractNYTheftPreventionForm?.primaryLanguage?.message
												}
											</FormHelperText>
										)}
									</div>
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									I have been given this pay notice in English only, because the
									Department of Labor does not yet offer a pay notice form in my
									primary language
								</td>
							</tr>
							<tr>
								<td>
									<p>Employee Signature</p>
									<FormControl fullWidth className={styles.tableForms}>
										<NabuTextbox disabled={true}/>
									</FormControl>
								</td>
								<td>
									<p>Date</p>
									<FormControl fullWidth className={styles.tableForms}>
										<NabuTextbox disabled={true}/>
									</FormControl>
								</td>
							</tr>
							<tr>
								<td>
									<p>Preparer Name</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractNYTheftPreventionForm.preparerName'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractNYTheftPreventionForm?.preparerName?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractNYTheftPreventionForm?.preparerName?.message
											}
										</FormHelperText>
									)}
								</td>
								<td>
									<p>Preparer Title</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractNYTheftPreventionForm.preparerTitle'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractNYTheftPreventionForm?.preparerTitle?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractNYTheftPreventionForm?.preparerTitle?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
						</tbody>
					</table>
					<br></br>

					<table
						className={styles.contractForms}
						border={0}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
						style={{ borderTop: '0' }}
					>
						<tr>
							<td width={'75%'}>
								<p>
									Extreme Reach Talent is the employer of record for the sole
									purposes of withholding and remitting employment taxes,
									providing workers’ compensation insurance and facilitating
									unemployment benefit claims.
								</p>
								<br></br>
								<p>
									PRODUCER Please note copies of the completed and signed forms
									must be distributed: One copy to the employee, one copy to
									Extreme Reach Talent with other employment documents, and
									retain a cop
								</p>
							</td>
							<td>
								<div
									style={{ display: 'flex', gap: '5px', alignItems: 'center' }}
								>
									<Controller
										control={contractForm.control}
										name='contractNYTheftPreventionForm.isEmployeeDeclinedToSign'
										defaultValue={false}
										render={({ field }) => (
											<div
												onClick={() => field.onChange(!field.value)}
												style={{ userSelect: 'none' }}
											>
												<input
													{...field}
													value={field.value + ''}
													checked={field.value}
													onChange={(e) => field.onChange(e)}
													type='checkbox'
													disabled={props?.isPrint}
													style={{ width: '20px', marginTop: '2px' }}
												/>
												<span>
														Check this box if employee declined<br></br> to sign this
														form
												</span>
											</div>
										)}
									/>
								</div>
								<p>
									<b> Contact:</b>
									<br></br>
									Extreme Reach Talent, Inc.,<br></br>
									111 W. Jackson Blvd., Suite 1525, Chicago, IL 60604<br></br>
									Tel 312 726 4404
								</p>
							</td>
						</tr>
					</table>
				</div>
			</div>
		</>
	);
};

export default ContractNYWageTheftPreventionForm;
