import * as schema from 'yup';
import { TemplateModel } from '../models/template';

const templateSchema = () => schema.object<Partial<Record<keyof TemplateModel, schema.AnySchema>>>({
	templateName: schema.string().required('Template name is required'),
	companyId: schema.string().required('Company is required'),
	unionType: schema.string().required('Union type is required'),
	templateType: schema.string().required('Template type is required')
});

export default templateSchema;