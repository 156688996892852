import useBackNavigation from '@/common/hooks/useBackNavigation';
import { Avatar, FormHelperText, FormLabel, IconButton, InputAdornment, Link, Menu, MenuItem, TextField } from '@mui/material';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import { ReactNode, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TextWrap from '../../atoms/textWrap/textWrap';
import styles from './subHeader.module.scss';
import { ArrowBackIosNew, Info, Save, Visibility, VisibilityOff } from '@mui/icons-material';
import store, { IStore } from '@/store';
import React from 'react';
import USER from './../sidebar/img/user.png';
import { Controller, useForm } from 'react-hook-form';
import NabuButton from '../../atoms/button/button';
import { NabuCard } from '../card/card';
import NabuDialog, { IDialogAction } from '../dialog/dialog';
import { NabuSideDrawer } from '../sideDrawer/SideDrawer';
import { ChangePasswordModel } from '@/modules/auth/shared/models';
import { changePasswordFormSchema } from '@/modules/auth/shared/schemas';
import { yupResolver } from '@hookform/resolvers/yup';
import { userChangePassword } from '@/services/auth';
import { setIsEditOpen, setProfileUrl, showNotification } from '@/store/app/reducer';
import { resetUserContext } from '@/store/auth/reducer';
import { useLocation, useNavigate } from 'react-router-dom';
import useNotification from '@/common/hooks/useNotification';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { NabuChip } from '../../atoms/chip/chip';
import { ContractStatus } from '@/common/constants/constants';

interface Props {
	title: string;
	id?: string;
	titlePrefix?: string;
	children?: ReactNode;
	subHeaderActions?: (() => JSX.Element)[];
	breadCrumb?: JSX.Element;
	infos?: any[];
	status?: string;
}

type ResetFields = {
	UserName: '',
	OldPassword: '',
	NewPassword: '',
}

const clearIt: ResetFields = {
	UserName: '',
	OldPassword: '',
	NewPassword: '',
};

export const SubHeader = (props: Props) => {
	const signedInUser = useSelector( (state: IStore) => state.userContext?.userInfo?.userName || state.userContext?.userAuthInfo?.userName) || '';
	const backNavigation = useBackNavigation();
	const [openPasswordDrawer, setOpenPasswordDrawer] = useState(false);
	const [isDialogOn, setIsDialogOn] = useState(false);
	const navigate = useNavigate();
	const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(null);
	const isEditOpen = useSelector((state: IStore) => state.appContext?.isEditOpen);

	const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorElUser(event.currentTarget);
	};

	const handleCloseUserMenu = () => {
		setAnchorElUser(null);
	};

	const onChangePassword = () => {
		handleCloseUserMenu();
		setOpenPasswordDrawer(true);
	};

	const handleDialog = () => {
		handleCloseUserMenu();
		setIsDialogOn(true);
	};

	const { control, formState, getValues, reset } = useForm<ChangePasswordModel>({
		resolver: yupResolver(changePasswordFormSchema),
		defaultValues: {UserName: signedInUser},
		mode: 'onChange',
	});

	const { errors, isValid } = formState;
	const canSubmitForm = isValid;
	const dispatch = useDispatch();
	const [state, setStateData] = useState({
		isVisible: false,
		isOldVisible: false,
	});

	const handleClickShowNewPassword = () => {
		setStateData((currentData) => {
			return {
				...currentData,
				isVisible: !currentData.isVisible,
			};
		});
	};

	const handleClickShowOldPassword = () => {
		setStateData((currentData) => {
			return {
				...currentData,
				isOldVisible: !currentData.isOldVisible,
			};
		});
	};

	function handleLogout() {
		dispatch(resetUserContext());
		navigate('/login');
		store.dispatch(setIsEditOpen(false));
	}

	const dialogActions: IDialogAction[] = [
		{
			'data-cy': 'yes_logout',
			name: 'Yes',
			onClick: handleLogout,
			variant: 'contained',
			color: 'error',
		},
		{
			'data-cy': 'yes_logout',
			name: 'No',
			onClick: () => setIsDialogOn(false),
			variant: 'contained',
			color: 'primary',
		},
	];

	function handleChangePassword() {
		const payload = {
			UserName: getValues().UserName,	
			OldPassword: getValues().OldPassword,
			NewPassword: getValues().NewPassword,
		};

		userChangePassword(payload).then((res: any) => {
			if (res.status === 204) {
				store.dispatch(
					showNotification({
						showNotification: true,
						message: 'Password changed successfully',
						type: 'success',
					})
				);
				reset(clearIt);
				setOpenPasswordDrawer(false);
				return;
			} else {
				store.dispatch(
					showNotification({
						showNotification: true,
						message: res.data.errorMessage,
						type: 'error',
					})
				);
			}
		});
	}

	const notification = useNotification();
	const formName = useSelector((state: IStore) => state.appContext?.formName);

	const showEditSectionNotification = () => {
		notification.notify({
			type: 'error',
			message: `${formName} form is open`
		});
	};
	const location = useLocation();
	const pathname = location?.pathname;
	const segments = pathname.split('/').filter(segment => segment !== '');
	const rightsExpiration = 'RIGHTS-EXPIRATION';
	const reportsUploadTabName = 'REPORTS UPLOAD';

	const title = `IP360 | ${segments.at(0)?.toUpperCase() == rightsExpiration ? reportsUploadTabName : segments.at(0)?.toUpperCase()}${segments.at(1)?.length !== 36 ? (segments.at(1)?.toUpperCase() ? '/'+segments.at(1)?.toUpperCase() : '') : '/'+props?.title}${segments.at(2)?.toUpperCase() ? '/'+segments.at(2)?.toUpperCase():''}`;
	const handleGoBackClick = () => {
		store.dispatch(setProfileUrl({url: '', blobId: '', profileType: ''}));
		backNavigation.goBack();
	};

	const getContractStatusColor = (status: string) => {
		switch(status?.toLowerCase()){
		case ContractStatus.Expired.toLowerCase():{
			return styles.expiredStatusColor;
		}
		case ContractStatus.Draft.toLowerCase():{
			return styles.draftStatusColor;
		}
		case ContractStatus.Active.toLowerCase():{
			return styles.activeStatusColor;
		}
		case ContractStatus.DueReview.toLowerCase():{
			return styles.dueReviewStatusColor;
		}
		case ContractStatus.DueSigning.toLowerCase():{
			return styles.dueSigningStatusColor;
		}
		case ContractStatus.Voided.toLowerCase():{
			return styles.voidedStatusColor;
		}
		}
	};

	return (
		<>
			<HelmetProvider>
				<Helmet>
					<title>
						{title}
					</title>
				</Helmet>
			</HelmetProvider>
			<div className={styles.fixedHeader}>
				<NabuSideDrawer
					isOpen={openPasswordDrawer}
					isPersistent={false}
					isCancelHidden={true}
					isFullScreenHidden={true}
					anchor='right'
					title='Change Password'
					onClose={() => setOpenPasswordDrawer(false)}
				>
					<NabuCard>
						<Grid container item columnSpacing={2}>
							<Grid item xs={12}>
								<Controller
									name='UserName'
									control={control}
									key='UserName'
									render={({ field }) => (
										<Grid container sx={{ mb: 3 }}>
											<Grid item xs={12} className={styles.textNormalCase}>
												<FormLabel data-cy='email_label_sign_in'>
												UserName <sup>*</sup>
												</FormLabel>
												<TextField
													{...field}
													id='UserName'
													placeholder='Enter username'
													type='text'
													fullWidth
													required={true}
													variant='standard'
													error={!!errors?.UserName}
													disabled={true}
													data-cy='email_sign_in_field'
												/>
												{!!errors?.UserName && (
													<FormHelperText
														error={true}
														className={styles.errorHelper}
														data-cy='email_helper_sign_in'
													>
														<Info /> {errors?.UserName?.message}
													</FormHelperText>
												)}
											</Grid>
										</Grid>
									)}
								/>
							</Grid>
							<Grid item xs={12} sx={{ mb: 3 }} className={styles.textNormalCase}>
								<Controller
									control={control}
									name='OldPassword'
									key='OldPassword'
									render={({ field }) => (
										<Grid container>
											<Grid item xs={12}>
												<FormLabel data-cy='OldPassword_label_sign_in'>
												Old Password <sup>*</sup>
												</FormLabel>
												<TextField
													data-cy='OldPassword_input_sign_in'
													{...field}
													id='OldPassword'
													placeholder='Enter Old Password'
													type={state.isVisible ? 'text' : 'Password'}
													required={true}
													fullWidth
													error={!!errors?.OldPassword}
													InputProps={{
														endAdornment: (
															<InputAdornment position='start'>
																<IconButton onClick={handleClickShowNewPassword}>
																	{!state.isVisible ? (
																		<Visibility />
																	) : (
																		<VisibilityOff />
																	)}
																</IconButton>
															</InputAdornment>
														),
													}}
												/>
												{!!errors?.OldPassword && (
													<FormHelperText
														className={styles.errorHelper}
														error={true}
														data-cy='OldPassword_helper_sign_in'
													>
														<Info /> {errors?.OldPassword?.message}
													</FormHelperText>
												)}
											</Grid>
										</Grid>
									)}
								/>
							</Grid>
							<Grid item xs={12} sx={{ mb: 3 }} className={styles.textNormalCase}>
								<Controller
									control={control}
									name='NewPassword'
									key='NewPassword'
									render={({ field }) => (
										<Grid container>
											<Grid item xs={12}>
												<FormLabel data-cy='password_label_sign_in'>
												New Password <sup>*</sup>
												</FormLabel>
												<TextField
													data-cy='newpassword_input_sign_in'
													{...field}
													id='NewPassword'
													placeholder='Enter old password'
													type={state.isOldVisible ? 'text' : 'Password'}
													required={true}
													fullWidth
													error={!!errors?.NewPassword}
													InputProps={{
														endAdornment: (
															<InputAdornment position='start'>
																<IconButton onClick={handleClickShowOldPassword}>
																	{!state.isOldVisible ? (
																		<Visibility />
																	) : (
																		<VisibilityOff />
																	)}
																</IconButton>
															</InputAdornment>
														),
													}}
												/>
												{!!errors?.NewPassword && (
													<FormHelperText
														className={styles.errorHelper}
														error={true}
														data-cy='newPassword_helper_sign_in'
													>
														<Info /> {errors?.NewPassword?.message}
													</FormHelperText>
												)}
											</Grid>
										</Grid>
									)}
								/>
							</Grid>
						</Grid>
					</NabuCard>
					<div className='saveSectionDefault'>
						<Grid container>
							<Grid item xs={12} md={2}>
								<NabuButton
									variant='outlined'
									onClick={() => setOpenPasswordDrawer(false)}
								>
								Cancel
								</NabuButton>
							</Grid>
							<Grid item xs={12} md={10} dir='rtl'>
								<NabuButton
									variant='filled'
									disabled={!canSubmitForm}
									onClick={handleChangePassword}
								>
								Save &nbsp; <Save />
								</NabuButton>
							</Grid>
						</Grid>
					</div>
				</NabuSideDrawer>
				<NabuDialog
					isPersistent={true}
					title='Logout'
					open={isDialogOn}
					maxWidth='xs'
					fullWidth={true}
					handleClose={() => setIsDialogOn(false)}
					dialogActions={dialogActions}
					data-cy='logout_dialog'
				>
					<strong> Do you really want to logout?</strong>
				</NabuDialog>
				<section className={styles.subHeader}>
					<Box sx={{ flexGrow: 1 }}>
						<Grid container columnSpacing={3}>
							<Grid item xs={12} md={12}>
								<div className={styles.breadCrumbSection}>
									<div className={styles.goBack} style={{ opacity: store.getState().appContext.urlStack.length > 0 ? 1 : 0.5, cursor:  store.getState().appContext.urlStack.length > 0 ? 'pointer' : 'no-drop' }}>
										{/*{appContext.backNavigationVisible &&*/}
										<ArrowBackIosNew/>
										<Link
											component="button"
											data-cy='back_link'
											onClick={isEditOpen ? showEditSectionNotification : handleGoBackClick}
											className={styles.disabledLink}
										>
								Go Back
										</Link>
										{/*}*/}
									</div>
									<div className={styles.breadcrumbBlock}>
										{ props.breadCrumb && props.breadCrumb}
									</div>
									<div className={styles.userInfo}>
										{`Welcome ${signedInUser}`}<IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
											<Avatar alt="A" src={USER} />
										</IconButton><Menu
											sx={{ mt: '45px' }}
											id="menu-appbar"
											anchorEl={anchorElUser}
											anchorOrigin={{
												vertical: 'top',
												horizontal: 'right',
											}}
											keepMounted
											transformOrigin={{
												vertical: 'top',
												horizontal: 'right',
											}}
											open={Boolean(anchorElUser)}
											onClose={handleCloseUserMenu}
										>
											<MenuItem data-cy='change_pass_btn' onClick={onChangePassword}>Change password</MenuItem>
											<MenuItem data-cy='change_pass_btn' onClick={handleDialog}>Logout</MenuItem>
										</Menu>
									</div>
								</div>
								<div data-cy='subHeaderMainTag' className={styles.pageTitle}>
									<div className={styles.chipBlock}> 
										{
											props?.titlePrefix && (
												<div data-cy='subHeaderTitle_mainChip'>
													<Chip
														label={`${props?.titlePrefix || ''}`}
														color='default'
														variant='outlined'
														size='small'
														data-cy='subHeaderTitle_chip'
													/>
												</div>)
										}
										<h2 data-cy='subHeaderTitle'><TextWrap title={props?.title || ''} maxLength={30} /></h2>
										{
											props?.id && (
												<div data-cy='subHeaderTitle_mainChip'>
													<Chip className={styles.chpCard}
														label={`#${props.id || ''}`}
														color='primary'
														variant='outlined'
														size='small'
														style={{ marginLeft: 4 }}
														data-cy='subHeaderTitle_chip'
													/>
												</div>)
										}
										{
											props?.status && (
												<div data-cy='subHeaderTitle_mainChip'>
													<NabuChip 
														className={getContractStatusColor(props.status === ContractStatus.Cancelled ? ContractStatus.Voided : props.status)}
														label={(props.status === ContractStatus.Cancelled ? ContractStatus.Voided : props.status) || ''}
														variant='outlined'
														size='small'
														style={{ marginLeft: 4 }}
														data-cy='subHeaderTitle_chip'
													/>
												</div>)
										}
									</div>
									<div className={styles.headerInfo}>
										{props.infos && props.infos.map((info) => info)}
										{/* <div className={styles.active}>
										<div className={styles.activeholder}>
											<div className={styles.statusactive}>
												<div></div> <span>Active </span>
											</div>
										</div>
									</div> */}
									</div>
								</div>
							</Grid>
						</Grid>
					</Box>
				</section>
			</div>
		</>
		
	);
};