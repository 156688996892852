import * as schema from 'yup';

const getRequiredSchema = (schema, field: string) => schema?.required(`${field} is required field.`).typeError(`${field} is required field.`);

export const CompensationSchema = schema.object({
	compensations: schema.array().of(
		schema.object({
			type: getRequiredSchema(schema.string(), 'Compensation Type'),
			ratePerHour: getRequiredSchema(schema.number(), 'Rate Per Hour'),
		})
	),
});