import { Suspense, lazy } from 'react';
import { Navigate, Outlet, createHashRouter } from 'react-router-dom';
import { Loader } from './common/components/atoms/loader/loader';
import PayrollVenorCompany from './modules/company/components/companyInfo/components/tabs/payrolVenorNew/payrollVendorNew';
import SaveSnippet from './modules/contracts/components/tabs/snippets/snippetEditInfo/components/saveSnippet';
import TemplateList from './modules/contracts/components/tabs/templates';
import SaveTemplate from './modules/contracts/components/tabs/templates/templateEditInfo/components/saveTemplate';
import Deliverables from './modules/elements/components/tabs/deliverables';

import { createAssetDocument, deleteAssetDocument, getAssetDocumentList } from './services/asset';
import { createDocument, deleteDocument, getDocumentList } from './services/company';
import { createContractDocument, deleteContractDocument, getContractDocumentList } from './services/contract';
import { createElementDocument, deleteElementDocument, getElementDocumentList } from './services/element';
import { createDocument as createPersonDocument, deleteDocument as deletePersonDocument, getDocumentList as getPersonDocuments } from './services/person';
import { createCompletionRepDocument, deleteCompletionRepDocument, getAllCompletionRepDocumentList } from './services/completionReport';
import ContractProfileSectionView from './modules/contracts/components/tabs/contractView/contractProfileSectionView';
import CompletionReport from './modules/asset/components/tabs/CompletionReport';
const DashboardLandingMainPage = lazy(() => import('./modules/dashboardLanding/dashboardMainPage'));

const ReportLandingPage = lazy(() => import('./modules/reports'));
const ReportsMainPage  = lazy(() => import('./modules/reports/reportsMainPage'));
const CompletionReportProfile = lazy(() => import('./modules/completionReports/components/tabs/completionReportProfile/completionReportProfile'));
const CompletionReportProfileNavigation = lazy(() => import('./modules/completionReports/components/CompletionReportNavigation'));
const CompletionReportListAll = lazy(() => import('./modules/completionReports/components/completionReportListAll/completionReportList'));
const CompletionReportMainPage = lazy(() => import('./modules/completionReports/CompletionReportMainPage'));
const CompletionReportCastList = lazy(() => import('./modules/completionReports/components/tabs/completionReportCast/completionReportCastList'));
const CompletionReportElement = lazy(() => import('./modules/completionReports/components/tabs/completionReportElement/completionReportElement'));
const CompletionRepParticipant = lazy(() => import('./modules/completionReports/components/tabs/completionReportParticipant/completionRepParticipant'));
const CompletionRepCrew = lazy(() => import('./modules/completionReports/components/tabs/completionReportCrew/completionRepCrew'));
const LogTable = lazy(() => import('./common/components/molecules/logsTable/logsTable'));

const TemplatesMainPage = lazy(() => import('./modules/contracts/templatesMainPage'));
const LayoutWrapper = lazy(() => import( './common/components/templates/LayoutWrapper'));
const ErrorPage = lazy(() => import( './common/components/templates/pages/error/errorPage'));
const ResetPasswordPage = lazy(() => import( './modules/auth/components/ResetPassword/ResetPasswordPage'));
const ResetNewPassword = lazy(() => import( './modules/auth/components/ResetPassword/ResetNewPassword'));
const SignIn = lazy(() => import( './modules/auth/components/signIn/signin'));
const Members = lazy(() => import( './modules/company/components/companyInfo/components/tabs/members/members'));
const MSAContractsExpiringList = lazy(() => import( './modules/dashboard/components/listView/MSAContractsExpiringList'));
const PeopleInfo = lazy(() => import('./modules/people/components/peopleInfo'));

// Company list
const Company = lazy(() => import('./modules/company'));
const CompanyList = lazy(() => import('./modules/company/components/companyList/companyList'));
const ArchivedCompany = lazy(() => import('./modules/company/components/archivedCompany'));

// Company Informations
const CompanyInfo = lazy(() => import('./modules/company/components/companyInfo'));
const CompanyProfile = lazy(() =>import('./modules/company/components/companyInfo/components/tabs/companyProfile'));
const Staff = lazy(() => import('./modules/company/components/companyInfo/components/tabs/staff'));
const ClientAdvertiser = lazy(() => import('./modules/company/components/companyInfo/components/tabs/clientAdvertiser'));
const MinorPermit = lazy(() =>import('./modules/company/components/companyInfo/components/tabs/minorPermits'));
const PayrollVendor = lazy(() =>import('./modules/company/components/companyInfo/components/tabs/payrollVendor/payrollVendor'));
const SignatoryVendors = lazy(() => import('./modules/company/components/companyInfo/components/tabs/SignatoryVendors/companySignatoryVendor'));
const Insurance = lazy(() => import('./modules/company/components/companyInfo/components/tabs/insurance/index'));
const AdId = lazy(() =>	import('./modules/company/components/companyInfo/components/tabs/advertiseID/index'));
const CompanyTransferOfRight = lazy(() =>	import('./modules/company/components/companyInfo/components/tabs/transferOfRights'));
const Documents = lazy(() =>	import('./modules/company/components/companyInfo/components/tabs/documents/documents'));
const CompanyLogs = lazy(() =>	import('./modules/company/components/companyInfo/components/tabs/companyLogs/CompanyLogs'));
const CompanyNotes = lazy(() => import('./modules/company/components/companyInfo/components/tabs/companyNotes/companyNotes'));
const BrandsProducts = lazy(()=> import('./modules/company/components/companyInfo/components/tabs/brandsProducts/brandsProducts'));
const Banks = lazy(() => import('./modules/company/components/companyInfo/components/tabs/bank/index'));
const CompanyRelationships = lazy(() => import('./modules/company/components/companyInfo/components/tabs/relationships/index'));

// People routes
const People = lazy(() => import('./modules/people'));
const PeopleList = lazy(() => import('./modules/people/components/peopleList/peopleList'));
const ArchivedPeople = lazy(() => import('./modules/people/components/archivedPeople'));
const PeopleProfile = lazy(() => import('./modules/people/components/peopleInfo/components/tabs/peopleProfile/peopleProfile'));
const PeopleAgentInfo = lazy(() => import('./modules/people/components/peopleInfo/components/tabs/agnetInfo/agentInfo'));
const PeoplePayrollVendor = lazy(() =>import('./modules/people/components/peopleInfo/components/tabs/payrollVendor'));
const Specification = lazy(() => import('./modules/people/components/peopleInfo/components/tabs/specifications'));
const Photos = lazy(() => import('./modules/people/components/peopleInfo/components/tabs/photos'));
const TravelInfo = lazy(() => import('./modules/people/components/peopleInfo/components/tabs/travelInfo'));
const Relationships = lazy(() => import('./modules/people/components/peopleInfo/components/tabs/relationships'));
const SnippetsMainPage = lazy(() => import('./modules/contracts/snippetMainPage'));
const PeopleLogs = lazy(() => import('./modules/people/components/peopleInfo/components/tabs/peopleLogs/PeopleLogs'));

// Asset Routes
const Asset = lazy(() => import('./modules/asset'));
const AssetListAll = lazy(() => import('./modules/asset/components/assetListAll/assetListAll'));
const AssetNavigation = lazy(()=> import('./modules/asset/components/AssetProfileNavigation'));
const AssetProfile = lazy(() => import('./modules/asset/components/tabs/AssetProfile/AssetProfilePage'));
const AssetCastListPage = lazy(() => import('./modules/asset/components/tabs/AssetCastList/AssetCastListPage'));
const AssetElementPage = lazy(() => import('./modules/asset/components/tabs/AssetElements/AssetElementPage'));
const AssetRightsTrackerPage = lazy(() => import('./modules/asset/components/tabs/AssetRightsTracker/AssetRightsTrackerPage'));
const AssetInfoPage = lazy(() => import('./modules/asset/components/tabs/AssetInfo/AssetInfoPage'));
const AssetProductPage = lazy(() => import('./modules/asset/components/tabs/AssetProduct/AssetProductPage'));
const AssetUnionPage = lazy(() => import('./modules/asset/components/tabs/AssetUnion/AssetUnionPage'));
const AssetSignatoryPage = lazy(() => import('./modules/asset/components/tabs/AssetSignatory/SignatoryGridInAsset'));
const Participants = lazy(() => import('./modules/asset/components/tabs/participants/assetParticipant'));
const AssetAdvertiserPage = lazy(() => import('./modules/asset/components/tabs/AssetAdvertiser/AssetAdvertiserPage'));
const AssetEditVersion = lazy(() => import('./modules/asset/components/tabs/edit/assetEditVersion'));
const AssetCrew = lazy(() => import('./modules/asset/components/tabs/crew/assetCrew'));
const AssetLogs = lazy(() => import('./modules/asset/components/tabs/assetLogs/AssetLogs'));
const AssetEstimation = lazy(() => import('./modules/asset/components/tabs/estimation/assetEstimation'));
const AssetTransferOfRight = lazy(() => import('./modules/asset/components/tabs/transferOfRight/assetTransferOfRight'));

// Element Routes
const ElementsMainPage = lazy(() =>import( './modules/elements/ElementsMainPage'));
const ElementsListAll = lazy(() => import('./modules/elements/components/elementsAll/elementList'));
const ElementNavigation = lazy(() => import('./modules/elements/components/ElementProfileNavigation'));
const ElementProfile = lazy(() => import('./modules/elements/components/tabs/ElementProfile/ElementProfileMainPage'));
const VendorLicensorInformation = lazy(()=> import('./modules/elements/components/tabs/VendorLicensor/VendorLicensorInformation'));
const PaymentInvoicesPage = lazy(() => import('./modules/elements/components/tabs/PaymentInvoices/PaymentInvoicesPage'));
const RightsAcquiredPage = lazy(() => import('./modules/elements/components/tabs/RightsAcquired/RightsAcquiredPage'));
const RightsUsedPage = lazy(() => import('./modules/elements/components/tabs/RightsUsed/RightsUsedPage'));
const UsedInPage = lazy(() => import('./modules/elements/components/tabs/UsedIn/UsedInPage'));
const MusicTab = lazy(() => import('./modules/elements/components/tabs/Music/MusicTabInElement'));
const TalentTab = lazy(() => import('./modules/elements/components/tabs/Talent/TalentTabInElement'));
const ElementLogs = lazy(() => import('./modules/elements/components/tabs/elementLogs/ElementLogs'));
const LinkedAssets = lazy(() => import('./modules/elements/components/tabs/linkedAssets/linkedAssets'));
const RightsHolders = lazy(() => import('./modules/elements/components/tabs/rightsHolders/rightsHolders'));
const Restrictions = lazy(() => import('./modules/elements/components/tabs/restrictions/restrictions'));
const ElementRelationships = lazy(() => import('./modules/elements/components/tabs/relationships/index'));

// Rights and exp
const RightsAndExpListAll = lazy(() => import('./modules/rightsExpriation/components/RightsAndExpListAll'));
const RightsAndExpMainPage = lazy(() => import('./modules/rightsExpriation/RightsAndExpMainPage'));
const RecentActivities = lazy(() => import('./modules/rightsExpriation/components/RecentActivities'));
const RecentUploadIssues = lazy(() => import('./modules/rightsExpriation/components/recentUploadIssues'));

// Contracts
const ContractTabNavigation = lazy(() => import('./modules/contracts/components/contractTabNavigation'));
const ContractsListAll = lazy(() => import('./modules/contracts/components/contractListAll/contractListAll'));
const ContractsMainPage = lazy(() => import('./modules/contracts/contractsMainPage'));
const WorkDaysHours = lazy(() => import( './modules/contracts/components/workDaysHours'));
const GuaranteePayment = lazy(() => import( './modules/contracts/components/tabs/guaranteePayment'));
const BankingAndTax = lazy(() => import( './modules/contracts/components/tabs/bankingAndTax/contractBankingAndTax'));
const AdditionalDealPoints = lazy(() => import( './modules/contracts/components/additionalDealPoints'));
const Misc = lazy(() => import( './modules/contracts/components/misc'));
const UseRights = lazy(() => import( './modules/contracts/components/useRights'));
const Notes = lazy(() => import( './modules/contracts/components/notes'));
const UsedIn = lazy(() => import( './modules/contracts/components/usedIn'));
const SnippetList = lazy(() => import( './modules/contracts/components/tabs/snippets'));
const ContractServiceAndMaterial = lazy(() => import('./modules/contracts/components/tabs/serviceAndMaterial/contractServiceAndMaterial'));
const AgentRepresentation = lazy(() => import( './modules/contracts/components/tabs/agentRepresentative/agentRepresentative'));

// dashboard
const DashboardMainPage = lazy(() => import('./modules/dashboard/DashboardMainPage'));
const AlertListView = lazy(() => import('./modules/dashboard/components/listView/AlertListView'));
const TodoList = lazy(() => import('./modules/dashboard/components/listView/toDoList'));

// Common
const AddNewEntity = lazy(() => import('@/common/components/templates/pages/add/addEntity'));

// Protected Routes
const ProtectedRoute = () => {
	const isAuthenticated = localStorage.getItem('access_token') && new Date() < new Date(localStorage.getItem('token_expiry') || '');
	return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
};

const WrapLoader = (Component: React.ReactNode) =><Suspense fallback={<Loader showLoader={true}/>}>{Component}</Suspense>;

const router = createHashRouter([
	{
		element: <ProtectedRoute />,
		errorElement: <ErrorPage />,
		children: [
			{
				element: <LayoutWrapper />,
				children: [
					// NOTE: Protected routes go here. 
					{ 
						path: '/', 
						element: <Navigate to='/dashboard' replace />,
					},
					{ 
						path: 'companies', 
						element: WrapLoader(<Company />),
						children: [
							{ index: true, path: 'all', element: WrapLoader(<CompanyList />)},
							{ path: 'archive', element: WrapLoader(<ArchivedCompany />)},
						],
					},
					{
						path: 'add/:module', 
						element: WrapLoader(<AddNewEntity />),
					},
					{
						path: 'company/:companyId',
						element: WrapLoader(<CompanyInfo />),
						children: [
							{ index: true, path: 'company-profile', element: WrapLoader(<CompanyProfile />)},
							{ path: 'staff', element: WrapLoader(<Staff />)},
							{ path: 'advertiser', element: WrapLoader(<ClientAdvertiser />)},
							{ path: 'minor-permit', element: WrapLoader(<MinorPermit />)},
							{ path: 'payroll-vendor', element: WrapLoader(<PayrollVendor />)},
							{ path: 'brandsproducts', element: WrapLoader(<BrandsProducts />)},
							{ path: 'payroll-vendor-company', element: WrapLoader(<PayrollVenorCompany />)},
							{ path: 'signatory-vendors', element: WrapLoader(<SignatoryVendors />)},
							{ path: 'insurance', element: WrapLoader(<Insurance />)},
							{ path: 'ad-id', element: WrapLoader(<AdId />)},
							{ path: 'transfer-of-rights', element: WrapLoader(<CompanyTransferOfRight />)},
							{ path: 'relationships', element: WrapLoader(<CompanyRelationships />)},
							{ path: 'documents', element: WrapLoader(<Documents 
								getDocumentList={getDocumentList} 
								createDocument={createDocument}
								deleteDocument={deleteDocument}
							/>)},
							// { path: 'relationships', element: WrapLoader(<Relationships />)},
							{ path: 'officers', element: WrapLoader(<Members />)},
							{ path: 'logs', element: WrapLoader(<CompanyLogs />)},
							{ path: 'notes', element: WrapLoader(<CompanyNotes />)},
							{ path: 'banks', element: WrapLoader(<Banks />)}
						],
					},
					{
						path: 'people', 
						element: WrapLoader(<People />),
						children: [
							{ index: true, path: 'all', element: WrapLoader(<PeopleList />)},
							{ path: 'archive', element: WrapLoader(<ArchivedPeople />)},
						],
					},
					{
						path: 'people/:peopleId',
						element: WrapLoader(<PeopleInfo />),
						children: [
							{index: true, path: 'people-profile', element: WrapLoader(<PeopleProfile />)},
							{path: 'agent-info', element: WrapLoader(<PeopleAgentInfo />)},
							{path: 'photos', element: WrapLoader(<Photos />)},
							{path: 'documents', element: WrapLoader(<Documents
								getDocumentList={getPersonDocuments} 
								createDocument={createPersonDocument}
								deleteDocument={deletePersonDocument}
							/>)},
							{path: 'payrollVendor', element: WrapLoader(<PeoplePayrollVendor/>)},
							{path: 'banks', element: WrapLoader(<Banks />)},
							{path: 'specification', element: WrapLoader(<Specification />)},
							{path: 'travelInfo', element: WrapLoader(<TravelInfo />)},
							{path: 'relationships', element: WrapLoader(<Relationships />)},
							{ path: 'logs', element: WrapLoader(<PeopleLogs />)}
						]
					},
					{
						path: 'assets',
						element: WrapLoader(<Asset />),
						children: [
							{index: true, path: 'all', element: WrapLoader(<AssetListAll />)},
						]
					},
					{
						path: 'asset/:assetId',
						element: WrapLoader(<AssetNavigation />),
						children: [
							{index: true, path: 'asset-profile', element: WrapLoader(<AssetProfile />)},
							{path: 'asset-info', element: WrapLoader(<AssetInfoPage />)},
							{path: 'completion-report', element: WrapLoader(<CompletionReport />)},
							{path: 'cast-list', element: WrapLoader(<AssetCastListPage />)},
							{path: 'elements', element: WrapLoader(<AssetElementPage />)},
							{path: 'advertisers', element: WrapLoader(<AssetAdvertiserPage />)},
							{path: 'products', element: WrapLoader(<AssetProductPage />)},
							{path: 'unions', element: WrapLoader(<AssetUnionPage />)},
							{path: 'signatories', element: WrapLoader(<AssetSignatoryPage />)},
							{path: 'participants', element: WrapLoader(<Participants/>)},
							{path: 'rights-tracker', element: WrapLoader(<AssetRightsTrackerPage />)},
							{path: 'documents', element: WrapLoader(<Documents 
								getDocumentList={getAssetDocumentList} 
								createDocument={createAssetDocument}
								deleteDocument={deleteAssetDocument}
							/>)},
							{ path: 'edit-version', element: WrapLoader(<AssetEditVersion />) },
							{ path: 'crew', element: WrapLoader(<AssetCrew />) },
							{ path: 'estimates', element: WrapLoader(<AssetEstimation />) },
							{ path: 'transfer-of-rights', element: WrapLoader(<AssetTransferOfRight />) },
							{ path: 'logs', element: WrapLoader(<AssetLogs />)}
						]
					},
					{
						path: 'elements',
						element: WrapLoader(<ElementsMainPage />),
						children: [
							{index: true, path: 'all', element: WrapLoader(<ElementsListAll	 />)},
						]
					},
					{
						path: 'elements/:elementId',
						element: WrapLoader(<ElementNavigation />),
						children: [
							{	index: true, path: 'element-profile', element: WrapLoader(<ElementProfile />)},
							{	path: 'vendor-licensor', element: WrapLoader(<VendorLicensorInformation />)},
							{	path: 'payment-invoices', element: WrapLoader(<PaymentInvoicesPage />)},
							{	path: 'rights-contracted', element: WrapLoader(<RightsAcquiredPage for='ELEMENT'/>)},
							{	path: 'rights-used', element: WrapLoader(<RightsUsedPage />)},
							{	path: 'used-in', element: WrapLoader(<UsedInPage />)},
							{	path: 'music', element: WrapLoader(<MusicTab />) },
							{ path: 'cast-list', element: WrapLoader(<TalentTab />) },
							{ path: 'documents', element: WrapLoader(<Documents 
								getDocumentList={getElementDocumentList} 
								createDocument={createElementDocument}
								deleteDocument={deleteElementDocument}
							/>)},
							{ path: 'logs', element: WrapLoader(<ElementLogs />)},
							{ path: 'linkedAssets', element: WrapLoader(<LinkedAssets />)},
							{ path: 'details-covered-tracker', element: WrapLoader(<Deliverables />)},
							{ path: 'rights-holders', element: WrapLoader(<RightsHolders />)},
							{ path: 'restrictions', element: WrapLoader(<Restrictions />)},
							{ path: 'relationships', element: WrapLoader(<ElementRelationships />)}
						]
					},
					{
						path: 'reports',
						element: WrapLoader(<ReportsMainPage />),
						children: [
							{
								index: true, path: 'all', element: WrapLoader(<ReportLandingPage />)
							}
						]
					},
					{
						path: 'reports',
						element: WrapLoader(<ReportsMainPage />),
						children: [
							{
								index: true, path: 'all', element: WrapLoader(<ReportLandingPage />)
							}
						]
					},
					{
						path: 'completion-reports',
						element: WrapLoader(<CompletionReportMainPage />),
						children: [
							{
								index: true, path: 'all', element: WrapLoader(<CompletionReportListAll />)
							}
						]
					},
					{
						path: 'completion-reports/:completionReportId',
						element: WrapLoader(<CompletionReportProfileNavigation />),
						children: [
							{	index: true, path: 'completion-report-profile', element: WrapLoader(<CompletionReportProfile />)},
							{	path: 'castList', element: WrapLoader(<CompletionReportCastList />)},
							{	path: 'elements', element: WrapLoader(<CompletionReportElement />)},
							{	path: 'participant', element: WrapLoader(<CompletionRepParticipant />)},
							{	path: 'crew', element: WrapLoader(<CompletionRepCrew />)},
							{ path: 'documents', element: WrapLoader(<Documents 
								getDocumentList={getAllCompletionRepDocumentList} 
								createDocument={createCompletionRepDocument}
								deleteDocument={deleteCompletionRepDocument}
							/>)},
							{	path: 'logs', element: WrapLoader(<LogTable />)},
						]
					},
					
					{
						path: 'rights-expiration',
						element: WrapLoader(<RightsAndExpMainPage />),
						children: [
							{ index: true, path: 'all', element: WrapLoader(<RightsAndExpListAll />)},
							{ index: true, path: 'recent-activities', element: WrapLoader(<RecentActivities/>)},
							{ index: true, path: 'upload-issues', element: WrapLoader(<RecentUploadIssues/>)},
						]
					},
					{
						path: 'contracts',
						element: WrapLoader(<ContractsMainPage />),
						children: [
							{ index: true, path: 'all', element: WrapLoader(<ContractsListAll />)}
						]
					},
					{
						path: 'contracts/snippets',
						element: WrapLoader(<SnippetsMainPage />),
						children: [
							{ index: true, path: 'all', element: WrapLoader(<SnippetList/>)},
						]
					},
					{
						path: 'contracts/templates',
						element: WrapLoader(<TemplatesMainPage />),
						children: [
							{ index: true, path: 'all', element: WrapLoader(<TemplateList/>)}
						]
					},
					{
						path: 'contracts/templates/add',
						element: WrapLoader(<SaveTemplate />)
					},
					{
						path: 'contracts/templates/:templateId/edit',
						element: WrapLoader(<SaveTemplate />)
					},
					{
						path: 'contracts/templates/:templateId/template-view',
						element: WrapLoader(<SaveTemplate />)
					},
					{
						path: 'contracts/snippets/add',
						element: WrapLoader(<SaveSnippet />)
					},
					{
						path: 'contracts/snippets/:snippetId/edit',
						element: WrapLoader(<SaveSnippet />)
					},
					{
						path: 'contracts/snippets/:snippetId/snippet-view',
						element: WrapLoader(<SaveSnippet />)
					},
					{
						path: 'contracts/:contractId',
						element: WrapLoader(<ContractTabNavigation />),
						children: [
							{ path: 'contract-view', element: WrapLoader(<ContractProfileSectionView />)},
							{ path: 'service-material', element: WrapLoader(<ContractServiceAndMaterial />)},
							{ path: 'guarantee-payment', element: WrapLoader(<GuaranteePayment/>)},
							{ path: 'workDaysHours', element: WrapLoader(<WorkDaysHours/>)},
							{ path: 'additionalDealPoints', element: WrapLoader(<AdditionalDealPoints/>)},
							{ path: 'tax-banking', element: WrapLoader(<BankingAndTax/>)},
							{ path: 'misc', element: WrapLoader(<Misc/>)},
							{ path: 'useRights', element: WrapLoader(<UseRights/>)},
							{ path: 'agent-representation', element: WrapLoader(<AgentRepresentation/>)},
							{ path: 'usedIn', element: WrapLoader(<UsedIn/>)},
							{path: 'documents', element: WrapLoader(<Documents
								getDocumentList={getContractDocumentList} 
								createDocument={createContractDocument}
								deleteDocument={deleteContractDocument}
							/>)},
							{ path: 'notes', element: WrapLoader(<Notes/>)},
						]
					},
					{
						path: 'alert-dashboard',
						element: WrapLoader(<DashboardMainPage />),
						children: [
							{
								index: true, path: 'all', element: WrapLoader(<DashboardMainPage />)
							}
						]
					},
					// {
					// 	path: 'reports',
					// 	element: WrapLoader(<ReportLandingPage />),
					// },
					{
						path: 'reports/:reportType',
						element: WrapLoader(<ReportsMainPage />),
					},
					{
						path: 'reports',
						element: WrapLoader(<ReportsMainPage />),
						children: [
							{
								index: true, path: 'all', element: WrapLoader(<ReportLandingPage />)
							}
						]
					},
					{
						path: 'dashboard',
						element: WrapLoader(<DashboardLandingMainPage />),
					},
					{
						path: 'alert-dashboard/holding-fee',
						element: WrapLoader(<AlertListView view='HOLDING_FEE'/>),
					},
					{
						path: 'alert-dashboard/use-rights-expiring',
						element: WrapLoader(<AlertListView view='USAGE_RIGHT'/>)
					},
					{
						path: 'alert-dashboard/mpu-expiring',
						element: WrapLoader(<AlertListView view='MPU_EXPIRE'/>)
					},
					{
						path: 'alert-dashboard/talent-contracts-expiring',
						element: WrapLoader(<AlertListView view='TALENT_CONTRACT'/>)
					},
					{
						path: 'alert-dashboard/elements-expiring',
						element: WrapLoader(<AlertListView view='ELEMENT_EXPIRE' />)
					},
					{
						path: 'alert-dashboard/msa-contracts-expiring',
						element: WrapLoader(<MSAContractsExpiringList />)
					},
					{
						path: 'alert-dashboard/to-do-list',
						element: WrapLoader(<TodoList/>)
					}
				],
			},
		]
	},
	// NOTE: Unprotected routes go here. (Ex: Login, signup, verfy/reset email)
	{
		path: 'login',
		element: WrapLoader(<SignIn />)
	},
	{
		path: 'reset-password',
		element: WrapLoader(<ResetPasswordPage />)
	},
	{
		path: 'reset-new-password',
		element: WrapLoader(<ResetNewPassword />)
	}
]);

export default router;
