import useNotification from '@/common/hooks/useNotification';
import { IContractRecord } from '@/common/types/contract';
import {
	getNotificationText,
	getUnionTypeFromFormName,
} from '@/common/utils/utils';
import { getContractGetById } from '@/services/contract';
import { Grid } from '@mui/material';
import { HttpStatusCode } from 'axios';
import { useState } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import ContractProfileSection from '../contractProfile/contractProfileSection';

const ContractProfileSectionView = () => {
	const contractId = useParams()?.contractId ?? '';
	const notification = useNotification();
	const [, setEditContract] = useState<boolean>(false);

	const [contractData, setContractData] = useState<IContractRecord>();

	// API logic
	const { refetch: fetchContractDetail } = useQuery({
		queryKey: ['contractProfile', contractId],
		queryFn: () => getContractGetById(contractId),
		refetchOnWindowFocus: false,
		onSuccess: (res) => {
			if (res?.status === HttpStatusCode.Ok) {
				setContractData({
					...(res?.data || {}),
					contractType: getUnionTypeFromFormName(res?.data?.contractName || ''),
				});
			}
		},
		onError: () => {
			notification.notify({
				type: 'error',
				message: getNotificationText('Contract', 'FETCHING', true),
			});
		},
	});

	const onSaveContract = () => {
		setContractData(undefined);
		fetchContractDetail();
	};

	return (
		<>
			<Grid item container columnSpacing={3}>
				<Grid item xs={12}>
					{contractData?.id && (
						<ContractProfileSection
							contractData={contractData}
							onSave={onSaveContract}
							onEditClick={(isEdit) => setEditContract(isEdit)}
							fetchContractDetail={fetchContractDetail}
						/>
					)}
				</Grid>
			</Grid>
		</>
	);
};

export default ContractProfileSectionView;
