export enum StatusCodes {
	OK = 200,
	CREATED = 201,
	NO_CONTENT = 204,
	CONFLICT= 409,
	FOUND= 302,
}

export enum  ErrorType
{
	UNKNOWN = 0,
	DUPLICATE_NAME = 1,
	NOT_FOUND = 2
}