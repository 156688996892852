import NabuButton from '@/common/components/atoms/button/button';
import DocViewer, { DocViewerRenderers, IHeaderOverride } from '@cyntler/react-doc-viewer';
import CloseIcon from '@mui/icons-material/Close';
import DownloadForOfflineIcon from '@mui/icons-material/DownloadForOffline';
import PropTypes from 'prop-types';
import styles from './peopleSubTabs.module.scss';
import TextWrap from '@/common/components/atoms/textWrap/textWrap';
interface Props {
	pdfUrl: any,
	documentName?: any,
	onClose?: () => void
}
const PDFViewer = (props: Props) => {
	const { pdfUrl, documentName, onClose} = props;
	const docs = [
		{
			uri: pdfUrl,
			title: 'Document',
			fileName: documentName || pdfUrl
		},
	];

	const DocHeader: IHeaderOverride = (state) => {
		if (!state.currentDocument || state.config?.header?.disableFileName) {
			return null;
		}
  
		return (
			<>
				<div className={styles.fileName}>
					<TextWrap 
						title={state.currentDocument.fileName || state.currentDocument.uri || ''} 
						maxLength={60} 
					/>
				</div>
			</>
		);
	};
	
	return (
		<>
			<div className='close-btn'>
				<NabuButton
					data-cy='drawer_close_btn'
					onClick={onClose}
					shouldHandleConfirmAction={false}
					variant='text'
				>
					<CloseIcon data-cy='drawer_close_icon' />
				</NabuButton>
			</div>
			<div className={styles.pdf_viewer}>
				<DocViewer 
					documents={docs} 
					pluginRenderers={DocViewerRenderers} 
					config={{
						header: {
							overrideComponent: DocHeader,
						},
					}}
				/>
			</div><div className={styles.pdfDownloadLink} data-cy='download_file_wrapper'>
				<a data-cy='download_file_link' className={styles.pdfDownload} href={pdfUrl}><DownloadForOfflineIcon data-cy='download_icon_svg' />Download File</a>
			</div></>
	);
};
  
PDFViewer.propTypes = {
	pdfUrl: PropTypes.string.isRequired,
};

export default PDFViewer;