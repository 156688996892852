import Button from '@mui/material-next/Button';
import style from './button.module.scss';
import { ReactNode, useState } from 'react';
import NabuDialog, { IDialogAction } from '../../molecules/dialog/dialog';

// eslint-disable-next-line react/display-name
export interface IButton {
	color?: 'primary' | 'secondary' | 'tertiary';
	disabled?: boolean;
	size?: 'small' | 'medium' | 'large';
	variant: 'text' | 'outlined' | 'filled' | 'filledTonal' | 'elevated';
	children: ReactNode;
	onClick?: React.MouseEventHandler<HTMLButtonElement> | undefined;
	shouldHandleConfirmAction?:boolean;
	confirmDialogTitile?: string;
	confirmDialogBodyText?: string;
}

const NabuButton = (props: IButton & { [key: string]: unknown }) => {
	const {shouldHandleConfirmAction, confirmDialogTitile, confirmDialogBodyText, ...restProp}= props;
	const [ isDeleteDialogVisible, setDeleteDialogVibility] = useState<boolean>(false);
	const openDeleteDialog = () => setDeleteDialogVibility(true);
	const closeDeleteDialog = () => setDeleteDialogVibility(false);

	const defaultDialogValues = {
		title: 'Are you sure, you want to Cancel ?',
		bodyText: 'Discard all the data entered and close the form ?',
	};

	const deleteDialogActions : IDialogAction[] = [
		{	
			name: 'Yes, Cancel', 
			onClick: props?.onClick, 
			color: 'info', 
			variant: 'contained',
			'data-cy': 'yes_btn'
		},
		{	
			name: 'Do not cancel', 
			onClick: closeDeleteDialog,
			variant: 'outlined',
			'data-cy': 'no_btn'
		},
	];

	const onClick = shouldHandleConfirmAction ? openDeleteDialog : props.onClick;

	return (
		<>
			<Button className={style.button} {...restProp} onClick={onClick}>
				{props.children}
			</Button>
			{/* Confirm Dialog */}
			<NabuDialog
				title={confirmDialogTitile || defaultDialogValues.title}
				open= {isDeleteDialogVisible}
				maxWidth= 'xs'
				handleClose= {closeDeleteDialog}
				fullWidth= {true}
				dialogActions= {deleteDialogActions}
				isPersistent={true}
				data-cy='from_confirm_dialog'
			>
				<p className={style.action_body_text} data-cy='action_body_text'>{ confirmDialogBodyText || defaultDialogValues.bodyText}</p>
			</NabuDialog>
		</>
	);
};

export default NabuButton;
