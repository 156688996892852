import Box from '@mui/material/Box';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import styles from './SideDrawer.module.scss';
import { Drawer, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import NabuButton from '../../atoms/button/button';
import { Fullscreen } from '@mui/icons-material';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

interface Props {
	title: string;
	drawerInitiator: JSX.Element;
	anchor: Anchor;
	children: ReactNode;
	keepMounted?: boolean;
	width?:number| string;
	drawerOpen?: boolean
}

const SideDrawer = (props: Props) => {
	const [state, setState] = useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const toggleDrawer =
		(anchor: Anchor, open: boolean) =>
			(event: React.KeyboardEvent | React.MouseEvent) => {
				if (
					event &&
				event.type === 'keydown' &&
				((event as React.KeyboardEvent).key === 'Tab' ||
					(event as React.KeyboardEvent).key === 'Shift')
				) {
					return;
				}
				setState({ ...state, [anchor]: open });
			};

	const SideDrawerContent = (anchor: Anchor) => {
		const style = {
			width: anchor === 'top' || anchor === 'bottom' ? 'auto' : props?.width || 750,
		} as CSSProperties;
		return (
			<Box
				sx={style}
				role='presentation'
				onClick={toggleDrawer(anchor, !!props.keepMounted)}
				onKeyDown={toggleDrawer(anchor, !!props.keepMounted)}
			>
				<h3 className={styles.drawerHeading}>{props.title}</h3>
				<div className={styles.drawerInner}>		{props.children}</div>
			</Box>
		);
	};

	useEffect(() => {
		if(props.drawerOpen)
			setState((currentData: any) => {
				return {
					...currentData,
					[props.anchor]: true
				};
			});
	}, [props.drawerOpen]);

	return (
		<div>
			{props.drawerOpen && <React.Fragment>
				<SwipeableDrawer
					anchor={props.anchor}
					open={state[props.anchor]}
					onClose={toggleDrawer(props.anchor, false)}
					onOpen={toggleDrawer(props.anchor, true)}
					ModalProps={{
						keepMounted: props.keepMounted || false,
					}}
				>
					{SideDrawerContent(props.anchor)}
				</SwipeableDrawer>
			</React.Fragment> || ([props.anchor] as const).map((anchor) => (
				<React.Fragment key={anchor}>
					<span onClick={toggleDrawer(anchor, true)}>
						{props.drawerInitiator}
					</span>
					<SwipeableDrawer
						anchor={anchor}
						open={state[anchor]}
						onClose={toggleDrawer(anchor, false)}
						onOpen={toggleDrawer(anchor, true)}
						ModalProps={{
							keepMounted: props.keepMounted || false,
						}}
					>
						{SideDrawerContent(anchor)}
					</SwipeableDrawer>
				</React.Fragment>
			))}
		</div>
	);
};

interface NabuDrawerProps {
	anchor: Anchor;
	children: ReactNode;
	isOpen: boolean;
	keepMounted?: boolean;
	onClose: () => void;
	title: string;
	isFullScreenHidden?: boolean;
	width?: string;
	isPersistent?: boolean;
	isCancelHidden?: boolean;
}

export const NabuSideDrawer  = (props: NabuDrawerProps) => {
	const [state, setState] = useState({
		top: false,
		left: false,
		bottom: false,
		right: false,
	});

	const handleClose = () =>{
		!props.isPersistent && props.onClose();
	};

	const toggleDrawer =
		(anchor: Anchor, open: boolean) =>
			(event: React.KeyboardEvent | React.MouseEvent) => {
				if (
					event &&
				event.type === 'keydown' &&
				((event as React.KeyboardEvent).key === 'Tab' ||
					(event as React.KeyboardEvent).key === 'Shift')
				) {
					return;
				}
				setState({ ...state, [anchor]: open });
			};

	const SideDrawerContent = (anchor: Anchor) => {

		const [drawerWidth, setDrawerWidth] = useState(props.width || 750);
		
		const toggleDrawerFullScreen = () => {
			// Toggle logic for the full-screen icon click
			setDrawerWidth((prevWidth) => (prevWidth === 1350 ? 750 : 1350));
		};

		const style = {
			width: props.anchor === 'top' || props.anchor === 'bottom' ? 'auto' : `${drawerWidth}px`,
		};




		return (
			<Box
				sx={style}
				role='presentation'
				onClick={toggleDrawer(anchor, !!props.keepMounted)}
				onKeyDown={toggleDrawer(anchor, !!props.keepMounted)}
			>
				<Grid container>
					<Grid item md={11}> 
						<div className={styles.headerSectionDocView}>
							<h3 className={styles.drawerHeading}>{props.title}</h3>
							{!props?.isFullScreenHidden && <div className={styles.fullscrennViewCOntrols}>
								<NabuButton variant='outlined' onClick={toggleDrawerFullScreen}><Fullscreen/></NabuButton>
							</div>}
						</div>
					</Grid>
					<Grid item md={2} dir="rtl">
						{!props.isCancelHidden && 
							<div className={styles.closeButton} ><NabuButton data-cy='drawer_close_btn' onClick={() => props.onClose()} shouldHandleConfirmAction={true} variant='text'><CloseIcon data-cy='drawer_close_icon'/></NabuButton></div>
						}
					</Grid>
				</Grid>	
				
				<div className={styles.drawerInner}>	{props.children}</div>
			</Box>
		);
	};

	return (
		<Drawer
			anchor={props.anchor}
			open={props.isOpen || false}
			onClose={handleClose}
		>
			{SideDrawerContent(props.anchor)}
		</Drawer>
	);
};

export default SideDrawer;
