import { getBaseUrl } from '@/common/utils/utils';
import { formSchema } from '@/modules/people/components/peopleList/shared/schema/personSchema';
import { PersonSaveModel } from '@/modules/people/shared/models/person';
import { yupResolver } from '@hookform/resolvers/yup';
import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import style from './addPerson.module.scss';

interface IPersonDrawerProps {
	formValues?: any;
	refresh?: any;
	unionOptions?: any[];
	formState?: boolean;
	showOnlyPersonNameAndPersonType?: boolean;
	onPersonCreated: (createdPerson: any) => void;
	onClose?: VoidFunction;
	isOpen?: boolean;
	event?: HTMLButtonElement | null ;
	onPopOverOpen?: CallableFunction;
	title?: string;
	initialValues?: any;
	isPersistent?: boolean;
}

const AddPersonInline = (props: IPersonDrawerProps) => {

	const personForm = useForm<PersonSaveModel>({
		defaultValues: {...props.formValues, companyId: {label: props.formValues?.staffs?.companyNames[0]?.name, value: props.formValues?.staffs?.id }, ...props?.initialValues},
		resolver: yupResolver(formSchema),
		mode: 'onChange'
	});

	useEffect(() => {
		personForm.reset({...props.formValues});
		personForm.setValue('isMinor', false);
		personForm.setValue('companyId', props.formValues?.staffsDetails?.id ? {label: props.formValues?.staffsDetails?.companyNames[0]?.name, value: props.formValues?.staffsDetails?.id } : null);
		props?.initialValues?.personType && personForm.setValue('personType', props?.initialValues?.personType);
	}, [props.formState]);


	useEffect(() => {
		if(personForm.formState.isValid) personForm.trigger();
	}, [personForm.formState.isValid, personForm.formState.isDirty]);

	const baseURL = getBaseUrl(window.location.href);
	const handleCreateAgent = () => {
		const link = document.createElement('a');
		const personType = props?.initialValues?.personType ? `?personTypes=${JSON.stringify(props?.initialValues?.personType)}` : '';
		const companyId = props?.initialValues?.companyId ? `&companyId=${props.initialValues.companyId}` : '';
		link.href =  `${baseURL}/#/add/people` + personType + companyId;
		link.target = '_blank';
		link.click();
	};

	return(
		<>
			<div
				onClick={handleCreateAgent}
				className={style.addIcon}
				style={{ marginTop: 7 }} >
				<AddIcon/>{props.title ? props.title : 'Create Advertiser'}
			</div>
		</>
	);
};

export default AddPersonInline;