import NabuBreadcrumbs, { ICustomCrumb } from '@/common/components/atoms/breadcrumbs/breadcrumbs';
import { SubHeader } from '@/common/components/molecules/subHeader/subHeader';

interface Props {
	snippetId: string;
	snippetName: string;
}

const SnippetInfoSubheader = (props: Props) => {

	const TempMoreAction = () => (
		<div>
		</div>
	);

	const subheaderActions = [TempMoreAction];

	const customCrumb: ICustomCrumb[] = [
		{
			crumbToFind: '/contracts/snippets',
			crumbToReplaceWith: '/contracts/snippets',
			crumbReplaceLabel: 'Snippets',
		},
		{
			crumbToFind: '/contracts/snippets/add',
			crumbToReplaceWith: '/contracts/snippets/add',
			crumbReplaceLabel: 'Add',
		},
		{
			crumbToFind: `/contracts/snippets/${props.snippetId}`,
			crumbToReplaceWith: `/contracts/snippets/${props.snippetId}/edit`,
			crumbReplaceLabel: 'Snippet',
		},
		{
			crumbToFind: `/contracts/snippets/${props.snippetId}/edit`,
			crumbToReplaceWith: `/contracts/snippets/${props.snippetId}/edit`,
			crumbReplaceLabel: 'Edit'
		}
	];

	const CustomBreadCrumb = <NabuBreadcrumbs customCrumb={customCrumb}/>;

	return (
		<SubHeader
			breadCrumb={CustomBreadCrumb}
			title={props.snippetName}
			subHeaderActions={subheaderActions}
		/>
	);
};

export default SnippetInfoSubheader;
