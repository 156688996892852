import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import { Delete } from '@mui/icons-material';
import { IconButton, Tooltip, FormControl } from '@mui/material';
import styles from './formsNonUnion.module.scss';
import { PartType } from '@/common/constants/constants';
import { DragHandle } from '../components/formPart';

export const NonUnionTalentAgreementParts = (handleDelete: any, isActionVisible: boolean = true) => {
	return [
		{
			id: 1,
			type: PartType.FORMPART,
			component: <div>
				{isActionVisible && <div className={styles.rearangeButtonControls}>
					<IconButton onClick={() => handleDelete(1)} aria-label="delete" size="small">
						<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>
					</IconButton>
					<DragHandle />
				</div>}
				<table className={styles.contractForms} width={'100%'}>
					<tbody>
						<tr>
							<td align='center'><h2>NON-UNION TALENT AGREEMENT</h2> </td>
						</tr>
					</tbody>
				</table>
				<hr></hr>
				<br></br>
			</div>
		}, {
			id: 3,
			type: PartType.FORMPART,
			component: <div>
				{isActionVisible && <div className={styles.rearangeButtonControls}>
					<IconButton onClick={() => handleDelete(3)} aria-label="delete" size="small">
						<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>
					</IconButton>
					<DragHandle />
				</div>}
				<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
					<tr>
						<td>
						Role
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<NabuTextbox value="OCP-BEACHGOER"/>
							</FormControl>
						</td>
						<td>
						On Location
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<NabuTextbox value="Atlanta, GA"/>
							</FormControl>
						
						</td>
						<td>
						Session Fee
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<NabuTextbox value="OCP-BEACHGOER"/>
							</FormControl>
						</td>
					</tr>
					<tr>

						<td>
						Work Day(s)
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<NabuTextbox value="08/30/23/8/31"/>
							</FormControl>
						
						</td>
						<td >
						Fitting Fee
						</td>
						<td colSpan={3}>
							<FormControl fullWidth className={styles.tableForms}>
								<NabuTextbox value="$50/hr"/>
							</FormControl>
						
						</td>
					</tr>
				</table>
				<br></br>
			</div>
		}, {
			id: 4,
			type: PartType.FORMPART,
			component: <div>
				{isActionVisible && <div className={styles.rearangeButtonControls}>
					<IconButton onClick={() => handleDelete(4)} aria-label="delete" size="small">
						<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>
					</IconButton>
					<DragHandle />
				</div>}
				<table width={'100%'} border={0} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
					<tr>
						<td>
							<b>Stunt Bump</b> <span>$350, if talent performance includes using a harness for aerial suspension 3-4ft above ground</span>
						</td>
						<td align='right'>
							<div style={{display:'flex', alignItems:'center',}}>
								<span><b>Producer Approval : </b></span>
								<FormControl  className={styles.tableForms}>
									<NabuTextbox placeholder="Producer Approval"/>
								</FormControl>
							</div>	
						</td>
					</tr>
				</table>
				<hr></hr>
				<p>
					<b>Use Fee:</b> $500 to be paid Oct 2023 for 3 months use; an additional use payment for 2024 use rights to be paid in Jan 2024 for $1000. Use fees total $1,500 and covers 15 months of use rights.
				</p>
				<p>
					<b>Agent Fees:</b> All fees plus 20%, only paid if talent is represented.
				</p>
				<p>
					<b>Term : </b> 15 months, from first air date
				</p>
				<br></br>
			</div>
		}, {
			id: 5,
			type: PartType.FORMPART,
			component: <div>
				{isActionVisible && <div className={styles.rearangeButtonControls}>
					<IconButton onClick={() => handleDelete(5)} aria-label="delete" size="small">
						<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>
					</IconButton>
					<DragHandle />
				</div>}
				<table width={'100%'} border={0} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
					<tr>
						<td width={160} valign='top'>
							<b>Media & Territory :</b>
						</td>
						<td>
						Regional Broadcast (Phoenix, Oklahoma City, and Tulsa) <br></br>
Industrial use - USA<br></br>
All Digital Media - Worldwide.
						</td>
					</tr>
					<tr>
						<td>
							<b>Exclusivity:</b>
						</td>
						<td>
							None
						</td>
					</tr>
				</table>
				<br></br>
				<p>
					<b>All Media Use Rights:</b> The talent performer agreement will detail that Advertiser shall have the right to use All images, footage and/or audio recordings to be used in any type of media, either known now or in the future, including but not limited to broadcast, internet, industrial, and new media including all lifts, variations and cut downs.
				</p>
				<br></br>
				<p>
					<b>Historical Use Rights:</b> Advertising Materials may be used anywhere in the world by Advertiser and Agency at sales meetings and for the intra-company, research, award, publicity (including, without limitation, on Agency&apos;s advertising agency &apos;reel&apos; and website), as a historical archive file for reference and educational purposes to be granted worldwide and in perpetuity.
				</p>
				<br></br>
				<p>
					<b>Renewal Option:</b> Advertiser may extend all above terms and conditions for up to one consecutive 15 month-term for an additional payment for $2200 for All Media Use Rights plus agent fees (20%). Agency or Advertiser will inform Talent of intention to renew.
				</p>
				<br></br>
				<p>
					<b>Conditions:</b>
Talent will be paid from timecard/contract. Talent and agent fees will be paid through a Payroll Vendor (HTP). No Agent invoices are needed. Agency or Advertiser shall cause a third-party payroll provider to process and issue talent payments to Talent. Accordingly, Advertiser understands that the Payroll Vendor (HTP) shall be the employer of record for payroll purposes and shall process all the applicable state, federal and local taxes on such fees as noted above. Agency is the hiring entity.
				</p>
				<br></br>
				<p><b>For good and valuable consideration, receipt, and sufficiency of which is hereby acknowledged, I hereby agree as follows:</b></p>
				<p>
For good and valuable consideration, receipt of which is hereby acknowledged, I hereby grant to 9th Wonder/Gravity Global (hereinafter called &quot;Agency&quot;), and its client, MIDFIRST BANK (hereinafter &quot;Advertiser&quot;), and Agency&apos;s and Advertiser&apos;s respective subsidiaries, affiliates, successors and assigns and those acting under their permission, the absolute right and permission to use, publish, distribute and/or broadcast my image, likeness, picture, and photograph in whole or in part, or distorted in character or form, in conjunction with my own name or a fictitious name, together with or without written or spoken copy, for advertising, publicity, business, trade or any other lawful purpose whatsoever per the term, territory and use rights noted above.
				</p>
				<br></br>
				<p>
I further agree in the event of a retake of any or all scenes in which I participate, or if additional scenes are required (whether originally completed or not) I will return to work and render my services in such scenes at the same rate of compensation as agreed for the original production hourly rate.
				</p>
			</div>
		}
	];
};