export class SnippetModel {
	id?: string;
	seriesNo: number = 1;
	name: string = '';
	type: string = '';
	preview: string = '';
}

export class SnippetFilterModel {
	searchText: string = '';
	type: string = '';
}