import NabuButton from '@/common/components/atoms/button/button';
import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import LookAheadSelect from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuMultiselect from '@/common/components/atoms/multiselect/multiselect';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuTextArea } from '@/common/components/atoms/textArea/textArea';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import NabuTimePicker from '@/common/components/molecules/timepicker/timePicker';
import AddAssetInline from '@/common/components/templates/pages/add/addAssetInline';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonAddressInline from '@/common/components/templates/pages/add/addPersonAddressInline';
import AddPersonInline from '@/common/components/templates/pages/add/addPersonInline';
import AddTalentAgentInline from '@/common/components/templates/pages/add/addTalentAgentInline';
import { AdditionalFeeDefineOptions, ContractStatus, DefaultPageSize, PeriodUnitsOptions } from '@/common/constants/constants';
import { COMPANY_MAIN_TYPE, OPERATORS } from '@/common/enums';
import { CompanyMainType, ICompanyAddress, ICompanyRecord } from '@/common/types/company';
import { IAdditionalFees, IBaseContract, ICommercialDetail, IContractNonUnionForm } from '@/common/types/contract';
import { IFilterOption } from '@/common/types/filterModel';
import { displayAddress, displayOnlyEmail, displayPhoneNumberWithoutLabel, getCompanyDisplayName, getCountryName, getLocaleDate, getLocaleDateTime, getNotificationText, getPersonAliasFullName, getPersonFullName, getStateName, sortEmailOrPhoneNumberForDropDown } from '@/common/utils/utils';
import { getAllAssetForBasicDetail, getAllSignatory } from '@/services/asset';
import { getAllAddressByCompany, getCompanyBasicDetails, getStaffListByCompany } from '@/services/company';
import { addContractNonUnion, updateContractNonUnion } from '@/services/contract';
import { IAssetRecord } from '@/services/models/asset';
import { IPersonAddress, IPersonRecord } from '@/services/models/person';
import { getAgentInfo, getAllAddressByPerson, GetByWithFullNameSearchForBasicDetail } from '@/services/person';
import store from '@/store';
import { updateContractFormState } from '@/store/contract/reducer';
import { Add, DeleteForever, HelpOutline } from '@mui/icons-material';
import { Autocomplete, Box, FormControl, FormHelperText, FormLabel, Grid, TextField, Tooltip } from '@mui/material';
import { HttpStatusCode } from 'axios';
import { continents, countries } from 'countries-list';
import { City, Country, State } from 'country-state-city';
import { addDays, addMonths, addWeeks, addYears, isBefore } from 'date-fns';
import { useEffect, useState } from 'react';
import { Controller, useFieldArray, useForm, UseFormReturn } from 'react-hook-form';
import { useMutation } from 'react-query';
import styles from '../../addContracts.module.scss';
import ContractMutationWrapper, { IContractFormState } from '../contractMutationWrapper/contractMutationWrapper';
import useContractMutation from '../contractMutationWrapper/useContractMutation';
import { yupResolver } from '@hookform/resolvers/yup';
import contractTerritorySchema from './schema';
import ExpiryDateForm from '../expiryDateForm';

interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	initialValue?: IContractNonUnionForm;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	defaultValue?: IBaseContract,
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
	assets?:IAssetRecord[];
	isDuplicateContractName?: boolean;
}

const NonUnionTalentAgreementVoiceOver = (props: Props) => {

	// #region State variable & hooks.
	const contractForm = useForm<IContractNonUnionForm>({
		defaultValues: !props?.initialValue
			? {
				...(props?.mainForm?.getValues() || {}),
				...(props?.defaultValue || {}),
				workDays: [{}],
				commercials: [{}],
				territories: [{countries: [], region: ''}],
				additionalFees:[{fees: 0,additionalFeeFlatFee:true,additionalFeeHourly:false,additionalFeeDefine:'',otherFee:''}]
			}
			: {...(props?.initialValue || {}), 
				commercials: props?.initialValue?.commercials?.length ? props?.initialValue?.commercials  :[{}], 
				workDays: props?.initialValue?.workDays?.length ? props?.initialValue?.workDays : [{}],
				additionalFees:props?.initialValue?.additionalFees?.length ? props?.initialValue?.additionalFees : [{}],
			},
		resolver: yupResolver(contractTerritorySchema),
		shouldFocusError: false
	});

	const { 
		handlePreContractSubmissionTasks, 
		addMultipleContractDetails, 
		notification, 
		setPacketFormState, 
		params, 
		setParams, 
		onSubmitError ,
		ADVERTISER_FILTER, 
		AD_AGENCY_FILTER, 
		DEFAULT_COUNTRY_CODE, 
	} = useContractMutation<IContractNonUnionForm>();

	const [contractFormState, setContractFormState] = useState<IContractFormState>({});
	const [contractFormData, setContractFormData] = useState<IContractNonUnionForm>();
	const [personOptions, setPersonOptions] = useState<IPersonRecord[]>(
		props?.people || []
	);
	const [addressOptions, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [assetOptions, setAssetOptions] = useState<IAssetRecord[]>(
		props?.assets || []
	);
	const [addressPopOver, setAddressPopOver] = useState<IPopOverState>({
		isOpen: false,
	});
	const [companyPopoverInline, setCompanyPopoverInline] = useState<IPopOverState>({
		isOpen: false,
	});
	const [staffOptions, setStaffOptions] = useState([] as any);

	const commercialFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'commercials',
	});
	const workDayFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'workDays',
	});
	const additionalFeeArray = useFieldArray({
		control: contractForm.control,
		name: 'additionalFees',
	});
	const territoryFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'territories'
	});
	// #endregion
	
	// #region Look a head option generator helper functions.
	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		const address = responseData?.reduce((acc, curr) => {
			if (curr?.addresses?.length) {
				acc.push(...(curr?.addresses || []));
			}
			return acc;
		}, [] as ICompanyAddress[]);

		handleStateOptions(address, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};

	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const lookAheadAssetOptionGenerator = (responseData: IAssetRecord[]) => {
		handleStateOptions(responseData, 'ASSET');

		return responseData?.map((d) => ({
			value: d?.id || '',
			label: d?.title || '',
		}));
	};

	const adAgencyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const getCompanyEmailOptions = (addressId?: string) => {
		const unsortedEmails =
			addressOptions?.find((a) => a?.id === addressId)?.emails || [];
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const getPerformerPhoneNumberOptions = () => {
		const phoneNumbers =
			personOptions?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.phoneNumbers || [];

		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const getPhoneNumberOptions = (addressId: string = '') => {
		const unsortedPhoneNumbers =
			addressOptions?.find((a) => a?.id === addressId)?.phoneNumbers || [];
		
		const phoneNumbers = sortEmailOrPhoneNumberForDropDown(unsortedPhoneNumbers);
		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const getPerformerEmailsOptions = () => {
		const unsortedEmails =
			personOptions?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.emails || [];

		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};
	// #endregion

	// #region Helper function section.
	const handleStateOptions = (
		responseData: any[],
		type: 'PERSON' | 'ADDRESS' | 'ASSET'
	) => {
		const cb = {
			PERSON: setPersonOptions,
			ADDRESS: setAddressOptions,
			ASSET:setAssetOptions,
		}[type];

		if (responseData && Array.isArray(responseData)) {
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);
				return [...oldFilter, ...responseData];
			});
		}
	};

	const getAssetFilters = () =>{
		const ASSET_FILTER: IFilterOption[] = [
			{
				isCaseSensitive: false,
				operator: OPERATORS.NOT_EQUAL,
				property: 'Title',
				value: '',
			},
			{
				isCaseSensitive: false,
				operator: OPERATORS.IN,
				property: 'Advertisers.Id',
				value: JSON.stringify([contractForm.watch('advertiserId.value')]),
			},
		];
		return ASSET_FILTER;
	};

	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	const isMinor = () => {
		return personOptions?.find(
			(a) => a?.id === contractForm.watch('performerId.value')
		)?.isMinor;
	};

	const onPersonAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('performerId.value')
			);
		
			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
						person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('performerAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const getTalentAliasName = () => {
		const person = personOptions?.find((a) => a?.id === contractForm.watch('performerId.value'));
		const aliasName = getPersonAliasFullName(person?.aliasName);
		const formData: any = contractForm.getValues();
		return aliasName === '' && formData?.contractNonUnion?.akaName || aliasName;
	};

	const continentsOptions = Object.keys(continents).map((key) => ({
		value: key,
		label: continents[key]
	}));

	const getCountryOptions = Country.getAllCountries().map( country => ({ label: country.name?.toUpperCase(), value: country.isoCode }));
	
	const getStateOptions = () => {
		const states = State.getStatesOfCountry(DEFAULT_COUNTRY_CODE).map(
			(state) => ({ label: state.name?.toUpperCase(), value: state.isoCode })
		);
		if (!states.length) states.unshift({ label: 'NA', value: 'NA' });
		return states;
	};

	const getCityOptions = (stateCode?: string) =>
		stateCode
			? City.getCitiesOfState(DEFAULT_COUNTRY_CODE, stateCode)?.map((city) => ({
				label: city.name?.toUpperCase(),
				value: city.name?.toUpperCase(),
			})) || []
			: [];	
	
	const getCountryLabel = () =>{
		const result= getCountryName(contractForm.watch('sessionCountry')|| '' )?.toUpperCase();
		return result;
	};

	const getStateLabel = ()=>{
		const result= getStateName( DEFAULT_COUNTRY_CODE,contractForm?.watch('sessionState')||'')?.toUpperCase();
		return result;
	};

	const getAssetAdIdByAssetIndex = (index: number) => {
		const assetId = contractForm.watch(`commercials.${index}.assetId`)?.value;
		return assetOptions?.find((d) => d?.id === assetId)?.adId || '';
	};

	const onAddCommercial = () => {
		commercialFieldArray.append({} as ICommercialDetail);
	};

	const onRemoveCommercial = (index: number) => {
		if (commercialFieldArray.fields.length > 1) {
			commercialFieldArray.remove(index);
		}
	};

	const onAddWorkDay = () => {
		if (workDayFieldArray.fields.length) {
			const previousRow = {
				...contractForm.watch(
					`workDays.${workDayFieldArray.fields.length - 1}`
				),
				id: undefined,
			};

			workDayFieldArray.append({
				...previousRow,
				date: previousRow?.date ? addDays(new Date(previousRow?.date || ''), 1) : '',
			});
		}
	};

	const onRemoveWorkDay = (index: number) => {
		if (workDayFieldArray.fields.length > 1) {
			workDayFieldArray.remove(index);
		}
	};

	const onAddAdditionalFees = () => {
		additionalFeeArray.append({} as IAdditionalFees);
	};

	const onRemoveAdditionalFees = (index: number) => {
		if (additionalFeeArray.fields.length > 1) {
			additionalFeeArray.remove(index);
		}
	};

	const resetTerritoriesValues = () => {
		contractForm.getValues('territories')?.map((_,index) => {
			contractForm.setValue(`territories.${index}`, {countries: [], region: ''});
		});
		contractForm.setValue('territories', [{countries: [], region: ''}]);
	};

	const getContinentCountries = (continent: string) => {
		const continentCountries = Object.keys(countries)
			.filter((k) => k !== 'AQ')
			.map((k) => ({ ...countries[k], iso: k }))
			.sort((a, b) => a.name.localeCompare(b.name))
			.filter((c) => c.continent == continent)
			.map((c) => ({label: c.name.toUpperCase(), value: c.iso}));
		return continentCountries;
	};

	const handleProducerChange = (index?: any) => {
		if (index == 0) {
			contractForm.setValue('productionCompanyId', null, { shouldDirty: true });
		}
		if (contractForm.watch(`commercials.${0}.assetId.value`)?.length == 0) {
			contractForm.setValue('productionCompanyId', null, { shouldDirty: true });
		}
	};
	// #endregion

	// #region Form submission helper functions.
	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};
	
	const handleSaveAndDuplicate = (data: IContractNonUnionForm) => {
		if(props?.isDuplicateContractName){
			notification.notify({
				message: 'Contract with the same name already exists',
				type: 'error',
			});
			return;
		}
		else{
			setContractFormData(data);
		}
	};

	const handleTalentDetailsForm = (data) => {
		const handleMultipleContractAdd = (payloadData) => {
			if(data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						status:ContractStatus?.Draft,
						partName: talent?.role,
						talentAgencyId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${index+1}`,
						compensation: talent?.compensation,
						performerAddressId:undefined,
						performerEmailId:undefined,
						performerPhoneId:undefined,
						talentAgencyAddressId:undefined,
						talentAgencyStaffId:undefined,
						talentAgencyPhoneId:undefined,
						talentAgencyEmailId:undefined
					};
					return submitData;
				});
				const multipleContractData = {
					contractAddNonUnionModels: contractAddModels,
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData &&
			handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};

	const handleContractSubmission = async (submitData: IContractNonUnionForm, handleMultipleContractAdd?: CallableFunction) => {
		handlePreContractSubmissionTasks(submitData, (data) => {

			const workDays = data?.workDays?.filter((d) => d?.date) || [];
			const mainFormValues = props?.mainForm?.getValues();
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};
	
			const commercials = data?.commercials?.map((d) => ({
				...d,
				assetId: d?.assetId?.value,
			}));
	
			const submitData = {
				...data,
				...addMainFormValues,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value,
				contractNonUnion: undefined,
				payrollVendorId: data?.payrollVendorId?.value,
				performerId: data?.performerId?.value,
				advertiserId: data?.advertiserId?.value,
				adAgencyId: data?.adAgencyId?.value,
				productId: data?.productId?.value,
				performerAddressId: data?.performerAddressId?.value,
				recordingCompanyId: data?.recordingCompanyId?.value,
				adAgencyAddressId: data?.adAgencyAddressId?.value,
				talentAgencyAddressId: data?.talentAgencyAddressId?.value,
				talentAgencyId: data?.talentAgencyId?.value,
				talentAgencyStaffId: data?.talentAgencyStaffId?.value,
				brandId:data?.brandId?.value,
				workDays,
				commercials,
				productionCompanyId: data?.productionCompanyId?.value,
			};
	
			if (!props?.isEdit) {
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					},
				});
			} else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 

		});
	};

	const onSubmit = (data: IContractNonUnionForm) => {
		if(!props?.mainForm?.watch('contractDisplayName') && !props?.isEdit){
			notification.notify({
				message: 'Please fill Contract Display Name.',
				type: 'error'
			});
			return;
		}
		if(props?.isDuplicateContractName){
			notification.notify({
				message: 'Contract with the same name already exists',
				type: 'error',
			});
			return;
		}
		else{
			openExpiryDateForm(data);
		}
	};
	// #endregion

	// #region API logics
	const { mutate: addContractDetails, isLoading: isAddingContract } =
	useMutation({
		mutationKey: ['contract-add'],
		mutationFn: (payload: any) => addContractNonUnion(payload),
		onSuccess: (res) => {
			if (res?.status === HttpStatusCode.Ok) {
				if (res.data?.error?.errorMessage) {
					notification.notify({
						message: res.data?.error?.errorMessage ?? getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
					return;
				}
				notification.notify({
					message: getNotificationText('Contract', 'CREATE'),
					type: 'success',
				});
				handleFormClose();
				if(res?.data?.id && props?.onSave){
					props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
				}
				store.dispatch(updateContractFormState({
					isContractUpdated: true,
					updatedContractId: res?.data?.id
				}));
			} else
				notification.notify({
					message:
						res?.data?.error?.errorMessage ??
						getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
		},
		onError: () => {
			notification.notify({
				message: getNotificationText('Contract', 'CREATE', true),
				type: 'error',
			});
		},
	});

	const { mutate: editContractDetails, isLoading: isEditingContract } =
	useMutation({
		mutationKey: ['contract-edit'],
		mutationFn: (payload : any) => updateContractNonUnion(props?.contractId || '', payload),
		onSuccess: (res) => {
			if (res?.status === HttpStatusCode.Ok) {
				if (res.data?.error?.errorMessage) {
					notification.notify({
						message: res.data?.error?.errorMessage ?? getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
					return;
				}
				notification.notify({
					message: getNotificationText('Contract', 'UPDATE'),
					type: 'success',
				});
				handleFormClose();
				if(res?.data?.id && props?.onSave){
					props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
				}
				store.dispatch(updateContractFormState({
					isContractUpdated: true,
					updatedContractId: res?.data?.id
				}));
			} else
				notification.notify({
					message:
						res?.data?.error?.errorMessage ??
						getNotificationText('Contract', 'UPDATE', true),
					type: 'error',
				});
		},
		onError: () => {
			notification.notify({
				message: getNotificationText('Contract', 'CREATE', true),
				type: 'error',
			});
		},
	});
	// #endregion

	// #region Side Effects.
	useEffect(()=> {
		if (props?.isEdit) {
			if(!commercialFieldArray?.fields?.length) {
				onAddCommercial();
			}
			if (!additionalFeeArray?.fields?.length) {
				onAddAdditionalFees();
			}
			if (!workDayFieldArray?.fields?.length) {
				onAddWorkDay();
			}
		}
	}, [props?.isEdit]);

	useEffect(() => {
		if(contractForm.watch('talentAgencyId')){
			const getStaffOptions = (filter: any) => {
				getStaffListByCompany(filter, contractForm.watch('talentAgencyId')?.value).then((res) => {
					const options: any = res?.data?.records?.map((staff: any) => {
						return {value: staff?.id, label: getPersonFullName(staff?.personName)};
					}) ?? [];
					setStaffOptions(options);
				});
			};
			getStaffOptions({
				pageNumber: 0,
				pageSize: DefaultPageSize,
				sortDescending: true,
				sortField: 'createdAt',
			});
		}
	}, [contractForm.watch('talentAgencyId')]);
	// #endregion

	//Expiry Date Dialog Code
	const [isExpiryDateDialogOpen, setExpiryDateDialog] = useState<{isOpen: boolean, contractData: IContractNonUnionForm | undefined, contractExpiryDate: Date | undefined}>({isOpen: false, contractData: undefined, contractExpiryDate: undefined});

	const calculateEndDate = (termValues) => {
		if(termValues.termDuration && termValues.termDurationUnit && termValues.termFromFirstUseDate){
			const days = termValues.termDuration;
			const startDate = new Date(termValues.termFromFirstUseDate);
			const date = startDate.setDate(startDate.getDate() - 1);
			const unit = termValues.termDurationUnit;
			if(date){
				let finalDate;
				if(unit == 'WEEK (S)'){
					finalDate = (addWeeks(new Date(date), days));
				}
				else if(unit == 'MONTH (S)'){
					finalDate = addMonths(new Date(date), days);
				}
				else if(unit == 'YEAR (S)'){
					finalDate = addYears(new Date(date), days);
				}
				else{
					finalDate = addDays(new Date(date), days);
				}
				return finalDate;
			}
		}
	};

	const openExpiryDateForm = (data) => {
		const termValues = {
			termDuration: data.termDuration,
			termDurationUnit: data.termDurationUnit,
			termFromFirstUseDate: data.termFromDate || data.termFromFirstUseDate
		};
		const endDate = calculateEndDate(termValues) || undefined;
		setExpiryDateDialog({isOpen: true, contractData: data, contractExpiryDate: endDate});
	};

	const closeExpiryDateForm = () => setExpiryDateDialog({isOpen: false, contractData: undefined, contractExpiryDate: undefined});
	const onSubmitOfExpiryDateForm = (data) => {
		if(isExpiryDateDialogOpen.contractData){
			const expiredStatus = data.expirationDate ? (isBefore(data.expirationDate, new Date()) ? ContractStatus.Expired : undefined) : undefined;
			const submittedData: IContractNonUnionForm = {
				...isExpiryDateDialogOpen.contractData,
				expirationDate: data.expirationDate,
				status: expiredStatus
			};
			submittedData && handleContractSubmission(submittedData);
		}
	};

	return (
		<>
			<ContractMutationWrapper
				isEdit={props?.isEdit}
				isPrint={props?.isPrint}
				contractForm={contractForm}
				contractFormState={contractFormState}
				setContractFormState={setContractFormState}
				handleFormClose={handleFormClose}
				handleSaveAndDuplicate={handleSaveAndDuplicate}
				handleTalentDetailsForm={handleTalentDetailsForm}
				isAddingContract={isAddingContract}
				isEditingContract={isEditingContract}
				onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}
				setPacketFormState={setPacketFormState}
				isUnion={false}
				mainForm={props?.mainForm}
				isDuplicateContractName={props?.isDuplicateContractName}
			>
				<div className={styles.contractFormBlock}>
					<div className={styles.bgWhite} style={{padding:'20px'}}>
						<Grid item container columnSpacing={3}>
							<Grid item md={12} >
								<Box sx={{pt:4}}>
									<h2 style={{textAlign:'center'}}>NON-UNION TALENT AGREEMENT- VOICE OVER</h2>
								</Box>
							</Grid>
						</Grid>
						<hr></hr>
						<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
							<tr>
								<td width={200}>Agency</td>
								<td><FormControl fullWidth className={styles.tableForms}>
									<Controller
										key={`adAgency-${contractForm.watch('adAgencyId.value')}`}
										control={contractForm.control}
										name='adAgencyId'
										render={({ field }) => (
											<>
												<LookAheadSelect
													className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													data-cy='ad_agency'
													{...field}
													placeholder={
														!props?.isPrint
															? 'Ad-Agency'
															: ''
													}
													filterProperty='CompanyNames.Name'
													APICaller={getCompanyBasicDetails}
													optionGenerator={
														companyOptionGenerator
													}
													defaultFilter={AD_AGENCY_FILTER}
													isDisabled={props?.isPrint}
													isClearable={true}
													onChange={(e: any) => {
														field.onChange(e);
														contractForm.setValue(
															'adAgencyAddressId',
															null,
															{ shouldDirty: true }
														);
														contractForm.setValue(
															'adAgencyPhoneId',
															'',
															{ shouldDirty: true }
														);
														contractForm.setValue(
															'adAgencyEmailId',
															'',
															{ shouldDirty: true }
														);
													}}
													cacheOptions={false}
													defaultOptions
												/>
											</>
										)}
									/>
									<FormHelperText error={true}>
										{contractForm.formState.errors?.adAgencyId?.message}
									</FormHelperText>
								</FormControl> </td>
								<td width={200}>Agency Job#:  </td>
								<td><FormControl fullWidth className={styles.tableForms}>
									<Controller
										control={contractForm.control}
										name='agencyJobTitle'
										defaultValue=''
										render={({ field }) => (
											<NabuTextbox
												{...field}
												disabled={props?.isPrint}
												placeholder={
													!props?.isPrint ? 'Job' : ''
												}
											/>
										)}
									/>
									<FormHelperText error={true}>
										{contractForm.formState.errors?.agencyJobTitle?.message}
									</FormHelperText>
								</FormControl>
								</td>
							</tr>
							<tr>
								<td>Advertiser</td>
								<td>
									<div>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												key={`advertiser-${contractForm.watch(
													'advertiserId.value'
												)}-${contractForm.watch(
													'productId.value'
												)}`}
												control={contractForm.control}
												name='advertiserId'
												render={({ field }) => (
													<>
														<LookAheadSelect
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
															{...field}
															placeholder={
																!props?.isPrint ? 'Advertiser' : ''
															}
															filterProperty='CompanyNames.Name'
															APICaller={getCompanyBasicDetails}
															optionGenerator={
																companyOptionGenerator
															}
															defaultFilter={ADVERTISER_FILTER}
															isDisabled={props?.isPrint}
															isClearable={true}
															onChange={(e: any) => {
																field.onChange(e);
																contractForm.setValue('commercials', [{} as any], { shouldDirty: true});
																contractForm.resetField(
																	'productId'
																);
															}}
															cacheOptions={false}
															defaultOptions
														/>
													</>
												)}
											/>
											<FormHelperText error={true}>
												{contractForm.formState.errors?.advertiserId?.message}
											</FormHelperText>
										</FormControl>
										{!props?.isPrint && (
											<div className={styles.inlineAdd}>
												<AddCompanyInline
													title='Company'
													companyType={{
														mainType: COMPANY_MAIN_TYPE.ADVERTISER as CompanyMainType,
														subType: [],
													}}
												/>
											</div>
										)}
									</div></td>
								<td>Job Name  </td>
								<td><FormControl fullWidth className={styles.tableForms}>
									<Controller
										control={contractForm.control}
										name='advertiserJobTitle'
										defaultValue=''
										render={({ field }) => (
											<NabuTextbox
												{...field}
												disabled={props?.isPrint}
												placeholder={
													!props?.isPrint ? 'Job Name' : ''
												}
											/>
										)}
									/>
									<FormHelperText error={true}>
										{contractForm?.formState?.errors?.advertiserJobTitle?.message}
									</FormHelperText>
								</FormControl> </td>
							</tr>
							<tr>
								<td>Production Co</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											key={`commercial-assetId
												-${contractForm.watch(`commercials.${0}.assetId.value`)}
												`}
											control={contractForm.control}
											name='productionCompanyId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														key={`commercial-assetId
														-${contractForm.watch(`commercials.${0}.assetId.value`)}
														`}
														data-cy='productionCompanyId'
														{...field}
														placeholder={
															!props?.isPrint
																? 'Production Company'
																: ''
														}
														filterProperty='CompanyNames.Name'
														APICaller={(filterModel) => getAllSignatory(contractForm.watch(`commercials.${0}.assetId.value`),filterModel)}
														optionGenerator={
															companyOptionGenerator
														}
														// defaultFilter={PRODUCTION_COMPANY_FILTER}
														isDisabled={props?.isPrint}
														isClearable={true}
														onChange={(e: any) => {
															field.onChange(e);
														}}
														cacheOptions={false}
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
												</>
											)}
										/>
									</FormControl>
									{!props?.isPrint && (
										<div className={styles.inlineAdd}>
											<AddCompanyInline
												title='Company'
												companyType={{
													mainType: COMPANY_MAIN_TYPE.PRODUCTION_COMPANY as CompanyMainType,
													subType: [],
												}}
											/>
										</div>
									)}
								</td>
								<td>Talent Name</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='performerId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														{...field}
														placeholder={
															!props?.isPrint
																? 'Select Talent'
																: ''
														}
														key={`performer-name-${contractForm.watch(
															'performerId.value'
														)}`}
														isClearable={true}
														filterProperty='PersonName.FirstName'
														APICaller={(
															filterModel,
															searchText
														) =>
															GetByWithFullNameSearchForBasicDetail(
																filterModel,
																searchText
															)
														}
														isSearchText={true}
														isDisabled={props?.isPrint}
														optionGenerator={
															performerOptionGenerator
														}
														defaultFilter={getPerformerFilter()}
														onChange={(e: any) => {
															field.onChange(e);
															contractForm.trigger(
																'performerEmailId'
															);
															contractForm.setValue(
																'performerEmailId',
																'',
																{ shouldDirty: true }
															);
															contractForm.setValue(
																'performerAddressId',
																undefined,
																{ shouldDirty: true }
															);
															contractForm.trigger(
																'performerEmailId'
															);
															contractForm.setValue(
																'printName',
																contractForm.watch(
																	'performerId.label'
																),
																{ shouldDirty: true }
															);
															contractForm.setValue(
																'akaName',
																contractForm.watch('performerId.label') && getTalentAliasName() || '',
																{ shouldDirty: true }
															);
															contractForm.setValue('printName', contractForm.watch('performerId.label') || '', { shouldDirty: true });
															contractForm.setValue('performerPhoneId', null, { shouldDirty: true });
															contractForm.setValue('talentAgencyId', null, { shouldDirty: true });
															contractForm.setValue('talentAgencyAddressId', null, { shouldDirty: true });
															contractForm.setValue('talentAgencyEmailId', null, { shouldDirty: true });
															contractForm.setValue('talentAgencyPhoneId', null, { shouldDirty: true });
															contractForm.setValue('talentAgencyStaffId', null, { shouldDirty: true });
														}}
														cacheOptions={false}
														defaultOptions
													/>
												</>
											)}
										/>
										{contractForm.formState?.errors?.performerId?.message && (
											<FormHelperText
												data-cy='errorHelper_first_session_date'
												error={true}
											>
												{
													contractForm.formState?.errors
														?.performerId?.message
												}
											</FormHelperText>
										)}
									</FormControl>
									{!props?.isPrint && (
										<div className={styles.inlineAdd}>
											<AddPersonInline
												title='Person'
												onPersonCreated={() => ({})}
												initialValues={{
													personType: ['TALENT'],
													isMinor: false,
												}}
											/>
										</div>
									)}
								</td>
							</tr>
							<tr>
								<td>Part</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='partName'
											defaultValue=''
											render={({ field }) => (
												<NabuTextbox
													{...field}
													disabled={props?.isPrint}
													placeholder =
														{
															!props?.isPrint
																? 'Part Name'
																: ''
														}
												/>
											)}
										/>
										<FormHelperText error={true}> 
											{contractForm.formState.errors?.partName?.message}
										</FormHelperText>
									</FormControl> </td>
								<td>Pay Master  </td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='payMaster'
											defaultValue=''
											render={({ field }) => (
												<NabuTextbox
													{...field}
													disabled={props?.isPrint}
													placeholder =
														{
															!props?.isPrint
																? 'Pay Master'
																: ''
														}
												/>
											)}
										/>
										<FormHelperText error={true}>
											{contractForm.formState.errors?.payMaster?.message}
										</FormHelperText>
									</FormControl> </td>
							</tr>
							<tr>
								<td>Session Workday(s):</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='sessionWorkDays'
											defaultValue=''
											render={({ field }) => (
												<NabuTextbox
													{...field}
													disabled={props?.isPrint}
													placeholder =
														{
															!props?.isPrint
																? 'Session WorkDays'
																: ''
														}
												/>
											)}
										/>
										<FormHelperText error={true}>
											{contractForm.formState?.errors?.sessionWorkDays?.message}
										</FormHelperText>
									</FormControl> </td>
								<td colSpan={2}>
									<p>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											<FormLabel>Location</FormLabel>
										</FormControl>
									</p>
									<table>
										{(!props?.isPrint) && (<tr>
											<td>
												<FormControl>
													<FormLabel>Country: </FormLabel>
												</FormControl>
											</td>
											<td width={200} valign='middle'>
												<FormControl style={{ minWidth: '60%' }}>
													<Controller
														control={contractForm.control}
														name='sessionCountry'
														defaultValue={DEFAULT_COUNTRY_CODE}
														render={({ field }) => (
															<NabuSelect
																{...field}	
																disabled={props?.isPrint}
																options={getCountryOptions}
																onChange={(e) => {
																	field.onChange(e);
																	contractForm.setValue('printState','',{shouldDirty:true});
																	contractForm.setValue('sessionCity','',{shouldDirty:true});
																	contractForm.setValue('sessionState','',{shouldDirty:true});
																	getCountryLabel();
																}}
																placeholder={!props?.isPrint ? '' : 'Select Country'}
															/>
														)}
													/>
													<FormHelperText error={true}>
														{contractForm.formState?.errors?.sessionCountry?.message}
													</FormHelperText>
												</FormControl>
											</td>
											{(contractForm.watch('sessionCountry') === DEFAULT_COUNTRY_CODE) && (<>
												<td>
													<FormControl>
														<FormLabel>State: </FormLabel>
													</FormControl>
												</td>
												<td width={200} valign='middle'>
													<FormControl
														style={{ minWidth: '60%' }}
													>
														<Controller
															control={contractForm.control}
															name='sessionState'
															defaultValue=''
															render={({ field }) => (
																<NabuSelect
																	{...field}
																	options={getStateOptions()}
																	disabled={props?.isPrint}
																	convertValueToUpperCase={false}
																	placeholder={!props?.isPrint ? 'Select State' : ''}
																	onChange={(e: any) => {
																		field.onChange(e);
																		contractForm.setValue(
																			'printState',
																			contractForm.watch(
																				'sessionState'
																			),
																			{ shouldDirty: true }
																		);
																		contractForm.setValue('sessionCity','',{shouldDirty:true});
																		getStateLabel();
																	}}
																/>
															)}
														/>
														<FormHelperText error={true}>
															{contractForm?.formState?.errors?.sessionState?.message}
														</FormHelperText>
													</FormControl>
												</td>
											</>)}
											<td valign='middle' width={30}>
												<FormControl
													fullWidth
												>
													<FormLabel>City: </FormLabel>
												</FormControl>
											</td>
											<td width={200} valign='middle'>
												<FormControl
													style={{ minWidth: '60%' }}
												>
													<Controller
														control={contractForm.control}
														name='sessionCity'
														defaultValue=''
														render={({ field }) => (
															(contractForm.watch('sessionCountry') === DEFAULT_COUNTRY_CODE) ? 
																(<NabuSelect
																	{...field}
																	disabled={props?.isPrint}
																	options={getCityOptions(
																		contractForm.watch('sessionState')
																	)}
																	convertValueToUpperCase={true}
																	placeholder={!props?.isPrint ? 'Select City' : ''}
																/>):
																(<NabuTextbox
																	{...field}
																	placeholder={!props?.isPrint ? 'City' : ''}
																	disabled={props?.isPrint}
																/>
																)
														)}
													/>
													<FormHelperText error={true}>
														{contractForm.formState?.errors?.sessionCity?.message}
													</FormHelperText>
												</FormControl>
											</td>
										</tr>)}
										{props?.isPrint && (
											<div style={{display:'flex', flexDirection: 'column', flexWrap:'wrap', textAlign:'left'}}>
												{(contractForm.watch('sessionCountry') || contractForm.watch('sessionState') || contractForm.watch('sessionCity')) &&
																					<span>{`${contractForm.watch('sessionCity') || ''}${contractForm.watch('sessionCity') && (getCountryLabel() || contractForm.watch('sessionState')) ? '.' : ''}${getCountryLabel() || ''}${contractForm.watch('sessionState') ? '/' + getStateLabel() : ''}`}</span>
												}
											</div>
										)}
									</table>
								</td>
							</tr>
						</table>
						<br></br>
						<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`} align='center' >
							<tr>
								<td>Commercial Title(s)<sup></sup>
									{ !props?.isPrint && (
										<Tooltip
											placement='right-end'
											title={!contractForm.watch('advertiserId.value') ? 'Select Advertiser before selecting commercials' : 'Commercials are fetched from the selected advertiser.'}
										>
											<HelpOutline fontSize='inherit' />
										</Tooltip>)
									}
								</td>
								<td>Ad-ID(s)<sup></sup></td>
								<td># Spots</td>
								{!props?.isPrint && <td></td>}
							</tr>
							{commercialFieldArray.fields.map((field, index) => (
								<tr key={`commercial-${field.id}`}>
									<td width={300}>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											<Controller
												control={contractForm.control}
												name={`commercials.${index}.assetId`}
												render={({ field }) => (
													<>
														<LookAheadSelect
															{...field}
															value={contractForm.watch(`commercials.${index}.assetId`)}
															key={`commercial-${contractForm.watch(`commercials.${index}.assetId.value`)}
															-${contractForm.watch('advertiserId.value')}`}
															placeholder={!props?.isPrint ? 'Commercial' : ''}
															filterProperty='Title'
															isDisabled={props?.isPrint || !contractForm.watch('advertiserId.value')}
															defaultFilter={getAssetFilters()}
															APICaller={(filterModel) => getAllAssetForBasicDetail(filterModel, false)}
															optionGenerator={lookAheadAssetOptionGenerator}
															onChange={(e: any) => {
																field.onChange(e);
																handleProducerChange(index);
															}}
															isClearable={true}  
															cacheOptions
															defaultOptions
															dropDownsize={25}
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
														{!(props?.isPrint || !contractForm.watch('advertiserId.value')) && (
															<Box sx={{mt: 1}}>
																<AddAssetInline
																	title='Asset' />
															</Box>
														)}
													</>
												)}
											/>
										</FormControl>
									</td>
									<td>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											<NabuTextbox
												placeholder={!props?.isPrint ? 'AD-ID' : ''}
												isDisabled={props?.isPrint}
												readOnly={true}
												value={getAssetAdIdByAssetIndex(
													index
												)}
											/>
										</FormControl>
									</td>
									<td>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											<Controller
												control={contractForm.control}
												name={`commercials.${index}.spots`}
												defaultValue=''
												render={({ field }) => (
													<NabuTextbox
														{...field}
														placeholder={!props?.isPrint ? 'Spots' : ''}
														disabled={props?.isPrint}
													/>
												)}
											/>
										</FormControl>
									</td>
									{!props?.isPrint && (
										<>
											<td>
												{commercialFieldArray.fields.length - 1 === index && (
													<NabuButton
														variant='text'
														onClick={onAddCommercial}
													>
														<Add />
													</NabuButton>
												)}
												{commercialFieldArray?.fields.length > 1 && (
													<NabuButton
														variant='text'
														onClick={() => {onRemoveCommercial(index);handleProducerChange(index);}}
													>
														<DeleteForever color='error' />
													</NabuButton>
												)}
											</td>
										</>
									)}
								</tr>
							))}
						</table>
						<br></br>
						<table
							width={'100%'}
							border={1}
							cellPadding={0}
							cellSpacing={0}
							className={`${styles.contractFormsInner} keep-together`}
						>
							<tr style={{ background: '#808080' }}>
								<th style={{ textAlign: 'center' }} colSpan={2}>Date<sup></sup>
								</th>
								<th style={{ textAlign: 'center' }} colSpan={2}>Work Time
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-around',
										}}
									>
										<span>Time in</span> <span>Time Out</span>{' '}
									</div>
								</th>
								<th style={{ textAlign: 'center' }} colSpan={2}>Lunch Time
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-around',
										}}
									>
										<span>Lunch in</span> <span>Lunch Out</span>{' '}
									</div>
								</th>
								{!props?.isPrint && (
									<th style={{ textAlign: 'center' }}></th>
								)}
							</tr>
							{workDayFieldArray.fields.map((field, index) => (
								<tr key={`work-field-${field.id}`}>
									<td height={40} colSpan={2}>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											<Controller
												control={contractForm.control}
												name={`workDays.${index}.date`}
												defaultValue=''
												render={({ field }) => (
													<NabuDatepicker
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Date' : ''}
													/>
												)}
											/>
											{!!contractForm.formState?.errors?.workDays
												?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[
															index
														]?.date?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											{props?.isPrint ? (
												<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeStartAt`) || '', 'hh:mm aa')}</p>
											) : (<Controller
												control={contractForm.control}
												name={`workDays.${index}.workTimeStartAt`}
												render={({ field }) => (
													<NabuTimePicker
														{...field}
														ampm={true}
														disabled={!(contractForm.watch(`workDays.${index}.date`))}
													/>
												)}
											/>)}
											{!!contractForm.formState?.errors?.workDays
												?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[
															index
														]?.workTimeStartAt?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											{props?.isPrint ? (
												<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeEndAt`) || '', 'hh:mm aa')}</p>
											) : (<Controller
												control={contractForm.control}
												name={`workDays.${index}.workTimeEndAt`}
												render={({ field }) => (
													<NabuTimePicker
														{...field}
														ampm={true}
														disabled={!(contractForm.watch(`workDays.${index}.date`))}
													/>
												)}
											/>)}
											{!!contractForm.formState?.errors?.workDays
												?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[
															index
														]?.workTimeEndAt?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											{props?.isPrint ? (
												<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.mealTimeStartAt`) || '', 'hh:mm aa')}</p>
											) : (<Controller
												control={contractForm.control}
												name={`workDays.${index}.mealTimeStartAt`}
												render={({ field }) => (
													<NabuTimePicker
														{...field}
														ampm={true}
														disabled={!(contractForm.watch(`workDays.${index}.date`))}
													/>
												)}
											/>)}
											{!!contractForm.formState?.errors?.workDays
												?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[
															index
														]?.mealTimeStartAt?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											{props?.isPrint ? (
												<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.mealTimeEndAt`) || '', 'hh:mm aa')}</p>
											) : (<Controller
												control={contractForm.control}
												name={`workDays.${index}.mealTimeEndAt`}
												render={({ field }) => (
													<NabuTimePicker
														{...field}
														ampm={true}
														disabled={!(contractForm.watch(`workDays.${index}.date`))}
													/>
												)}
											/>)}
											{!!contractForm.formState?.errors?.workDays
												?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[
															index
														]?.mealTimeEndAt?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									{!props?.isPrint && (
										<>
											<td style={{ display: 'flex' }}>
												{workDayFieldArray.fields.length - 1 ===
																	index && (
													<NabuButton
														variant='text'
														onClick={onAddWorkDay}
													>
														<Add />
													</NabuButton>
												)}
												{workDayFieldArray?.fields.length > 1 && (
													<NabuButton
														variant='text'
														onClick={() => onRemoveWorkDay(index)}
													>
														<DeleteForever color='error' />
													</NabuButton>
												)}
											</td>
										</>
									)}
								</tr>
							))}
						</table>
						<br></br>

						<div className='keep-together'>
							<p><b>COMPENSATION</b></p>
							<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
								<tr>
									<td>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<Controller
												control={contractForm.control}
												name='sessionFeeCheck'
												defaultValue={true}
												render={({ field }) => (
													<div
														onClick={() => {
															(!props?.isPrint) && field.onChange(!field.value);
															contractForm.setValue('sessionFeeAndUseFeeCheck', !contractForm.watch('sessionFeeCheck'));
														}}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => {
																	field.onChange(e);
																	contractForm.setValue('sessionFeeAndUseFeeCheck', !contractForm.watch('sessionFeeCheck'));
																}}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '2px' }}
															/>
															<span>Session Fee: $</span>
														</Box>
													</div>
												)}
											/>
											<FormControl sx={{minWidth:'100px'}} className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='sessionFee'
													render={({ field }) => (
														<input
															{...field}
															disabled={props?.isPrint}
															min={0}
															type='number'
															placeholder =
																{
																	!props?.isPrint
																		? 'Session Fee'
																		: ''
																}
															onChange={(e: any) => {
																const value = e?.target?.value || '';
																if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																	field.onChange(value);
																} else {
																	const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																	field.onChange(num);
																}
															}}
															onBlur={() => {
																const numericValue = parseFloat((field.value ?? '0').toString());
																if (!isNaN(numericValue)) {
																	field.onChange(numericValue.toFixed(2));
																} else {
																	field.onChange('0.00');
																}
															}}
														/>
													)}
												/>
												<FormHelperText error={true}>
													{contractForm.formState.errors?.sessionFee?.message}
												</FormHelperText>
											</FormControl> 
											<span>Flat fee for </span>
											<FormControl sx={{minWidth:'100px'}} className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='sessionFeeHour'
													render={({ field }) => (
														<input
															{...field}
															disabled={props?.isPrint}
															min={0}
															type='number'
															placeholder =
																{
																	!props?.isPrint
																		? 'Session Fee Hour'
																		: ''
																}
															onChange={(e: any) => {
																const value = e?.target?.value || '';
																if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																	field.onChange(value);
																} else {
																	const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																	field.onChange(num);
																}
															}}
															onBlur={() => {
																const numericValue = parseFloat((field.value ?? '0').toString());
																if (!isNaN(numericValue)) {
																	field.onChange(numericValue.toFixed(2));
																} else {
																	field.onChange('0.00');
																}
															}}
														/>
													)}
												/>
												<FormHelperText error={true}>
													{contractForm.formState.errors?.sessionFeeHour?.message}
												</FormHelperText>
											</FormControl> 
											<span>hours  OR $ </span>
											<FormControl sx={{minWidth:'270px'}} className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='sessionFeePerScript'
													render={({ field }) => (
														<input
															{...field}
															disabled={props?.isPrint}
															min={0}
															type='number'
															placeholder =
																{
																	!props?.isPrint
																		? 'Session Fee Per Script/Asset'
																		: ''
																}
															onChange={(e: any) => {
																const value = e?.target?.value || '';
																if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																	field.onChange(value);
																} else {
																	const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																	field.onChange(num);
																}
															}}
															onBlur={() => {
																const numericValue = parseFloat((field.value ?? '0').toString());
																if (!isNaN(numericValue)) {
																	field.onChange(numericValue.toFixed(2));
																} else {
																	field.onChange('0.00');
																}
															}}
														/>
													)}
												/>
												<FormHelperText error={true}>
													{contractForm.formState.errors?.sessionFeePerScript?.message}
												</FormHelperText>
											</FormControl> 
											<span>Per Script/Asset </span>
										</div>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<Controller
												control={contractForm.control}
												name='sessionFeeAndUseFeeCheck'
												// defaultValue={true}
												render={({ field }) => (
													<div
														onClick={() => {
															(!props?.isPrint) && field.onChange(!field.value);
															contractForm.setValue('sessionFeeCheck', !contractForm.watch('sessionFeeAndUseFeeCheck'));
														}}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e: any) => {
																	const value = e?.target?.value || '';
																	if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																		field.onChange(value);
																	} else {
																		const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																		field.onChange(num);
																	}
																	contractForm.setValue('sessionFeeCheck', !contractForm.watch('sessionFeeAndUseFeeCheck'));
																}}
																onBlur={() => {
																	const numericValue = parseFloat((field.value ?? '0').toString());
																	if (!isNaN(numericValue)) {
																		field.onChange(numericValue.toFixed(2));
																	} else {
																		field.onChange('0.00');
																	}
																}}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '2px' }}
															/>
															<span>Session +Use Fee: $ </span>
														</Box>
													</div>
												)}
											/>
											<FormControl sx={{maxWidth:'150px'}} className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='sessionFeePlusUseFee'
													render={({ field }) => (
														<input
															{...field}
															disabled={props?.isPrint}
															min={0}
															type='number'
															placeholder =
																{
																	!props?.isPrint
																		? 'Flat fee for'
																		: ''
																}
															onChange={(e: any) => {
																const value = e?.target?.value || '';
																if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																	field.onChange(value);
																} else {
																	const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																	field.onChange(num);
																}
															}}
															onBlur={() => {
																const numericValue = parseFloat((field.value ?? '0').toString());
																if (!isNaN(numericValue)) {
																	field.onChange(numericValue.toFixed(2));
																} else {
																	field.onChange('0.00');
																}
															}}
														/>
													)}
												/>
												<FormHelperText error={true}>
													{contractForm.formState.errors?.sessionFeePlusUseFee?.message}
												</FormHelperText>
											</FormControl> 
											<span>Flat fee for </span>
											<FormControl sx={{minWidth:'240px'}} className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='sessionFeePlusUseFeeHour'
													render={({ field }) => (
														<input
															{...field}
															disabled={props?.isPrint}
															min={0}
															type='number'
															placeholder =
																{
																	!props?.isPrint
																		? 'Session Fee + Use Fee Hour'
																		: ''
																}
															onChange={(e: any) => {
																const value = e?.target?.value || '';
																if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																	field.onChange(value);
																} else {
																	const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																	field.onChange(num);
																}
															}}
															onBlur={() => {
																const numericValue = parseFloat((field.value ?? '0').toString());
																if (!isNaN(numericValue)) {
																	field.onChange(numericValue.toFixed(2));
																} else {
																	field.onChange('0.00');
																}
															}}
														/>
													)}
												/>
											</FormControl> 
											<span>hours  OR $ </span>
											<FormControl sx={{minWidth:'300px'}} className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='sessionFeePlusUseFeePerScript'
													render={({ field }) => (
														<input
															{...field}
															disabled={props?.isPrint}
															min={0}
															type='number'
															placeholder =
																{
																	!props?.isPrint
																		? 'Session Fee + Use Fee Script/Asset'
																		: ''
																}
															onChange={(e: any) => {
																const value = e?.target?.value || '';
																if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																	field.onChange(value);
																} else {
																	const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																	field.onChange(num);
																}
															}}
															onBlur={() => {
																const numericValue = parseFloat((field.value ?? '0').toString());
																if (!isNaN(numericValue)) {
																	field.onChange(numericValue.toFixed(2));
																} else {
																	field.onChange('0.00');
																}
															}}
														/>
													)}
												/>
												<FormHelperText error={true}>
													{contractForm.formState.errors?.sessionFeePlusUseFeePerScript?.message}
												</FormHelperText>
											</FormControl> 
											<span>Per Script/Asset </span>
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<span>Tags: $ </span>    
											<FormControl sx={{minWidth:'350px'}} className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='tagAmount'
													render={({ field }) => (
														<input
															{...field}
															disabled={props?.isPrint}
															min={0}
															type='number'
															placeholder =
																{
																	!props?.isPrint
																		? 'Tags'
																		: ''
																}
															onChange={(e: any) => {
																const value = e?.target?.value || '';
																if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																	field.onChange(value);
																} else {
																	const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																	field.onChange(num);
																}
															}}
															onBlur={() => {
																const numericValue = parseFloat((field.value ?? '0').toString());
																if (!isNaN(numericValue)) {
																	field.onChange(numericValue.toFixed(2));
																} else {
																	field.onChange('0.00');
																}
															}}
														/>
													)}
												/>
												<FormHelperText error={true}>
													{contractForm.formState.errors?.tagAmount?.message}
												</FormHelperText>
											</FormControl>                             
										</div>
									</td>
								</tr>
								<tr>
									<td>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<span>Pick Up/ADR Session: $</span>    
											<FormControl sx={{minWidth:'350px'}} className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='sessionPickUpAdr'
													render={({ field }) => (
														<input
															{...field}
															disabled={props?.isPrint}
															min={0}
															type='number'
															placeholder =
																{
																	!props?.isPrint
																		? 'Pick Up/ADR Session'
																		: ''
																}
															onChange={(e: any) => {
																const value = e?.target?.value || '';
																if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																	field.onChange(value);
																} else {
																	const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																	field.onChange(num);
																}
															}}
															onBlur={() => {
																const numericValue = parseFloat((field.value ?? '0').toString());
																if (!isNaN(numericValue)) {
																	field.onChange(numericValue.toFixed(2));
																} else {
																	field.onChange('0.00');
																}
															}}
														/>
													)}
												/>
												<FormHelperText error={true}>
													{contractForm.formState.errors?.sessionPickUpAdr?.message}
												</FormHelperText>
											</FormControl>                             
										</div>
									</td>
								</tr>
								<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={styles.contractFormsInner} style={{borderTop:'0px',borderBottom:'0px'}}>
									<tr>
										<td width={120} style={{verticalAlign:'top'}}>
											<span>Additional Fees: $ </span>
										</td>
										<td>
											{additionalFeeArray.fields.map((field, index) => (
												<div key={`additionalFees-${field.id}`} style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
													<FormControl
														className={styles.tableForms}
													>
														<Controller
															control={contractForm.control}
															name={`additionalFees.${index}.fees`}
															render={({ field }) => (
																<input
																	{...field}
																	disabled={props?.isPrint}
																	min={0}
																	type='number'
																	placeholder =
																		{
																			!props?.isPrint
																				? 'Additional Fee'
																				: ''
																		}
																	onChange={(e: any) => {
																		const value = e?.target?.value || '';
																		if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																			field.onChange(value);
																		} else {
																			const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																			field.onChange(num);
																		}
																		contractForm.setValue('useFlatFeeCheck', !contractForm.watch('usePerScriptCheck'));
																	}}
																	onBlur={() => {
																		const numericValue = parseFloat((field.value ?? '0').toString());
																		if (!isNaN(numericValue)) {
																			field.onChange(numericValue.toFixed(2));
																		} else {
																			field.onChange('0.00');
																		}
																	}}
																/>
															)}
														/>
													</FormControl>
													<span>Define</span>
													<FormControl>
														<Controller
															control={contractForm.control}
															name={`additionalFees.${index}.additionalFeeDefine`}
															defaultValue=''
															render={({ field }) => (
																<>
																	<NabuSelect
																		{...field}
																		placeholder={
																			!props?.isPrint ? 'Select Additional Fee' : ''
																		}
																		options={AdditionalFeeDefineOptions || []}
																		convertValueToUpperCase={false}
																		onChange={(e) => field.onChange(e)}
																		disabled={props?.isPrint}
																	/>
																</>
															)}
														/>
													</FormControl>
													{!props?.isPrint && (
														<>
															{additionalFeeArray.fields.length - 1 ===
																	index && (
																<NabuButton
																	variant='text'
																	onClick={onAddAdditionalFees}
																>
																	<Add />
																</NabuButton>
															)}
															{additionalFeeArray?.fields.length > 1 && (
																<NabuButton
																	variant='text'
																	onClick={() => onRemoveAdditionalFees(index)}
																>
																	<DeleteForever color='error' />
																</NabuButton>
															)}
														</>
													)}
												</div>
											))}
										</td>
									</tr>
								</table>
								<tr>
									<td>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<span>Use Fee: $ </span>
											<FormControl sx={{minWidth:'100px'}} className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='useFee'
													render={({ field }) => (
														<input
															{...field}
															disabled={props?.isPrint}
															min={0}
															type='number'
															placeholder =
																{
																	!props?.isPrint
																		? 'Use Fee'
																		: ''
																}
															onChange={(e: any) => {
																const value = e?.target?.value || '';
																if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																	field.onChange(value);
																} else {
																	const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																	field.onChange(num);
																}
															}}
															onBlur={() => {
																const numericValue = parseFloat((field.value ?? '0').toString());
																if (!isNaN(numericValue)) {
																	field.onChange(numericValue.toFixed(2));
																} else {
																	field.onChange('0.00');
																}
															}}
														/>
													)}
												/>
												<FormHelperText error={true}>
													{contractForm.formState.errors?.useFee?.message}
												</FormHelperText>
											</FormControl> 
											<Controller
												control={contractForm.control}
												name='useFlatFeeCheck'
												defaultValue={true}
												render={({ field }) => (
													<div
														onClick={() => {
															(!props?.isPrint) && field.onChange(!field.value);
															contractForm.setValue('usePerScriptCheck', !contractForm.watch('useFlatFeeCheck'));
														}}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e: any) => {
																	const value = e?.target?.value || '';
																	if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																		field.onChange(value);
																	} else {
																		const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																		field.onChange(num);
																	}
																	contractForm.setValue('usePerScriptCheck', !contractForm.watch('useFlatFeeCheck'));
																}}
																onBlur={() => {
																	const numericValue = parseFloat((field.value ?? '0').toString());
																	if (!isNaN(numericValue)) {
																		field.onChange(numericValue.toFixed(2));
																	} else {
																		field.onChange('0.00');
																	}
																}}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '2px' }}
															/>
															<span>Flat Fee Or </span>
														</Box>
													</div>
												)}
											/>
											<Controller
												control={contractForm.control}
												name='usePerScriptCheck'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => {
															(!props?.isPrint) && field.onChange(!field.value);
															contractForm.setValue('useFlatFeeCheck', !contractForm.watch('usePerScriptCheck'));
														}}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e: any) => {
																	const value = e?.target?.value || '';
																	if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																		field.onChange(value);
																	} else {
																		const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																		field.onChange(num);
																	}
																	contractForm.setValue('useFlatFeeCheck', !contractForm.watch('usePerScriptCheck'));
																}}
																onBlur={() => {
																	const numericValue = parseFloat((field.value ?? '0').toString());
																	if (!isNaN(numericValue)) {
																		field.onChange(numericValue.toFixed(2));
																	} else {
																		field.onChange('0.00');
																	}
																}}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '2px' }}
															/>
															<span> Per Script/Asset </span>
														</Box>
													</div>
												)}
											/>
										</div>
									</td>
								</tr>
							</table>
						</div>
						<br></br>
						<div className='keep-together'>
							<p><b>USE RIGHTS</b></p>
							<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
								<tr>
									<td colSpan={3}>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<span><b>Term : </b></span>
											<FormControl
												className={styles.tableForms}
												fullWidth
												sx={{maxWidth:200}}
											>
												<Controller
													control={contractForm.control}
													name='termDuration'
													defaultValue=''
													render={({ field }) => (
														<NabuTextbox
															{...field} 
															data-cy='termDuration_input' 
															placeholder='Duration'
															type='number'
															disabled={props?.isPrint}
															onChange={e => {
																if(parseInt(e?.target?.value) >= 1 || e?.target?.value ==='') field.onChange(e);
															}}/>
													)}
												/>
											</FormControl>
											<FormControl
												className={styles.tableForms}
												sx={{minWidth:200}}
											>
												<Controller
													control={contractForm.control}
													name='termDurationUnit'
													defaultValue=''
													render={({ field }) => (
														<>
															<NabuSelect 
																{...field}
																placeholder='Select Unit'
																isClearable={true}
																options={PeriodUnitsOptions?.map(op => ({ label: op?.toUpperCase(), value: op?.toUpperCase() }))}
																convertValueToUpperCase={false}
																shouldOverrideDefaultSort={true}
																disabled={props?.isPrint}
															/>
														</>
													)}
												/>
											</FormControl>
											<Controller
												control={contractForm.control}
												name='isTermFromFirstUseDate'
												defaultValue={true}
												render={({ field }) => (
													<Box 
														display={'flex'} 
														alignItems={'flex-start'} 
														onClick={() => field.onChange(!contractForm.watch('isTermFromFirstUseDate'))}
													>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => {
																field.onChange(e);
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span>from first use date</span>
													</Box>
												)}
											/>
										</div>
									</td>
								</tr>
								<tr>
									<td width={150}>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<span><b>Territory : </b></span>
										</div>
									</td>
									{!props?.isPrint && (
										<td width={150}>
											<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
												{(contractForm.watch('isWorldWide') && props?.isPrint) && (
													<Controller
														control={contractForm.control}
														name={'isWorldWide'}
														render={({ field }) => (
															<Box 
																display={'flex'} 
																alignItems={'flex-start'}
															>
																<input
																	{...field}
																	checked={field.value || contractForm.watch('isWorldWide')}
																	value={field.value + ''}
																	onChange={(e) => {
																		if(e.target.checked){
																			resetTerritoriesValues();
																		}
																		else{
																			territoryFieldArray.fields.length == 0 && territoryFieldArray.append({countries: [], region: ''});
																		}
																		field.onChange(e);
																	}}
																	type='checkbox'
																	disabled={props?.isPrint}
																	style={{ marginTop: '2px' }}
																/>
																<span>WorldWide</span>
															</Box>
														)}
													/>
												)}
												{(!props?.isPrint) && (
													<Controller
														control={contractForm.control}
														name={'isWorldWide'}
														render={({ field }) => (
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	checked={field.value || contractForm.watch('isWorldWide')}
																	value={field.value + ''}
																	onChange={(e) => {
																		if(e.target.checked){
																			resetTerritoriesValues();
																		}
																		else{
																			territoryFieldArray.fields.length == 0 && territoryFieldArray.append({countries: [], region: ''});
																		}
																		field.onChange(e);
																	}}
																	type='checkbox'
																	disabled={props?.isPrint}
																	style={{ marginTop: '2px' }}
																/>
																<span>WorldWide</span>
															</Box>
														)}
													/>
												)}
											</div>
										</td>)}
									<td colSpan={props?.isPrint ? 2 : 1}>
										{props?.isPrint && (
											<div style={{display:'flex', flexDirection: 'column', flexWrap:'wrap', textAlign:'left'}}>
												{contractForm.watch('territories')?.filter(t => t.region && t.region != '').map((territory, index) => {
													return (
														<span key={index}>{`${continents[territory?.region]} - ${territory?.countries?.map((iso) => {
															return getCountryName(iso);
														}).join(', ')}`}</span>
													);
												})}
											</div>
										)}

										{!props?.isPrint &&territoryFieldArray.fields.map((region, index) => {
											return(
												<Grid item md={12} key={index}>
													<Grid item container columnSpacing={3}>
														<Grid item xs={12} md={4}>
															<FormControl fullWidth>
																<FormLabel
																	id='select-field-demo-label'
																	htmlFor='select-field-demo-button'
																	data-cy='region_label'
																>
														Region <sup></sup>
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name={`territories.${index}.region`}
																	defaultValue={''}
																	render={({ field }) => (
																		<>
																			<NabuSelect
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				size='small'
																				placeholder={
																					!props?.isPrint
																						? 'Select Region Type'
																						: ''
																				}
																				data-cy='region_select_input'
																				{...field}
																				onChange={(e: any) => { field.onChange(e); }}
																				options={continentsOptions}
																				disabled={props?.isPrint || contractForm.watch('isWorldWide') || false}
																			>
																			</NabuSelect>
																		</>
																	)} />
															</FormControl>
														</Grid>
														<Grid item xs={12} md={4} >
															<FormControl fullWidth>
																<FormLabel
																	id='select-field-demo-label'
																	htmlFor='select-field-demo-button'
																	data-cy='country_label'
																>
														Country <sup></sup>
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name={`territories.${index}.countries`}
																	defaultValue={[]}
																	render={({ field }) => (
																		<NabuMultiselect
																			size='small'
																			placeholder={
																				!props?.isPrint
																					? 'SELECT COUNTRY TYPE'
																					: ''
																			}
																			data-cy='country_select_input'
																			selectedItems={field.value}
																			{...field}
																			onChange={(e: any) => { field.onChange(e); contractForm.trigger('territories');}}
																			options={getContinentCountries(contractForm.watch(`territories.${index}.region`))}
																			disabled={props?.isPrint || contractForm.watch('isWorldWide') || false}
																		>
																		</NabuMultiselect>
																	)}
																/>
															</FormControl>
														</Grid>
														{!props?.isPrint && (
															<>
																{index > 0 && <Grid item xs={12} md={0.5} >
																	<Box sx={{pt:3.5}}>
																		<NabuButton 
																			onClick={() => territoryFieldArray.remove(index)} 
																			variant='text' 
																			disabled={props?.isPrint || contractForm.watch('isWorldWide') || false}
																		>
																			<DeleteForever color='error' />
																		</NabuButton> &nbsp;
																	</Box>
																</Grid>}
																{index == (territoryFieldArray.fields?.length-1) && <Grid item xs={12} md={3} >
																	<Box sx={{pt:3.5}}>
																		<NabuButton 
																			variant='text' 
																			onClick={() => territoryFieldArray.append({countries: [], region: ''})}
																			disabled={props?.isPrint || contractForm.watch('isWorldWide') || false}
																		>
																			<Add/>
																		</NabuButton>
																	</Box>
																</Grid>}
															</>
														)}
													</Grid>
												</Grid>
											);
										})}
										{!props?.isPrint && <Grid item xs={12} >
											<FormHelperText error={true} key={`territory_${contractForm.watch('territories')}_${territoryFieldArray.fields}_${contractForm.formState.errors?.territories?.message}`}>
												{contractForm.formState.errors?.territories?.message || ''}
											</FormHelperText>
										</Grid>}
									</td>
								</tr>
								<tr>
									<td colSpan={3}>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<span><b>Exclusivity : </b></span> 
											<Controller
												control={contractForm.control}
												name='exclusitivityNone'
												defaultValue={true}
												render={({ field }) => (
													<Box display={'flex'} alignItems={'flex-start'}>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => {
																field.onChange(e);
																contractForm.setValue('exclusitivityYes', !contractForm.watch('exclusitivityNone'));
																contractForm.setValue('exclusitivityNote', '');
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span> NONE </span>
													</Box>
												)}
											/>{' '}
											<Controller
												control={contractForm.control}
												name='exclusitivityYes'
												render={({ field }) => (
													<Box display={'flex'} alignItems={'flex-start'}>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => {
																field.onChange(e);
																contractForm.setValue('exclusitivityNone', !contractForm.watch('exclusitivityYes'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '2px' }}
														/>
														<span> Yes </span>
													</Box>
												)}
											/>
										</div>
										{contractForm.watch('exclusitivityYes') && (
											<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
												<FormControl
													className={styles.tableForms}
													fullWidth
												>
													<Controller
														control={contractForm.control}
														name='exclusitivityNote'
														render={({ field }) => (
															<NabuTextArea
																{...field}
																disabled={props?.isPrint}
																placeholder='Add Note'
															/>
														)}
													/>
												</FormControl>
											</div>
										)}
									</td>
								</tr>
								<tr>
									<td colSpan={3}>
										<p><b>Media Use Rights : </b>All audio recordings to be used in any and all types of media, either known now or in the future including but not limited to broadcast, streaming, internet, digital media and industrial including all lifts, variations and cut downs. </p>
									</td>
								</tr>
								<tr>
									<td colSpan={3}>
										<p><b>Historical uses: </b>
										Advertising materials may be used in perpetuity anywhere in the world by the Advertiser and its Agency at sales meetings and for intra-company, research, award shows, publicity (including, without limitation, on Ad Agency ‘s ‘reel’ and website), as a historical archive file for reference and educational purposes to be granted worldwide and in perpetuity. It is understood that social posts will remain in perpetuity on the Advertiser’s social media sites as long as no new reposts are made after the term.
										</p>
									</td>
								</tr>
								<tr>
									<td colSpan={3}>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<b>Renewal Option: </b>
										Advertiser may extend all above use right terms and conditions for a consecutive term for renewal fee of $ 
											<FormControl
												className={styles.tableForms}
											>
												<Controller
													control={contractForm.control}
													name='renewalFee'
													render={({ field }) => (
														<input
															{...field}
															disabled={props?.isPrint}
															min={0}
															type='number'
															onChange={(e: any) => {
																const value = e?.target?.value || '';
																if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																	field.onChange(value);
																} else {
																	const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																	field.onChange(num);
																}
																contractForm.setValue('usePerScriptCheck', !contractForm.watch('useFlatFeeCheck'));
															}}
															onBlur={() => {
																const numericValue = parseFloat((field.value ?? '0').toString());
																if (!isNaN(numericValue)) {
																	field.onChange(numericValue.toFixed(2));
																} else {
																	field.onChange('0.00');
																}
															}}
														/>
													)}
												/>
											</FormControl>
										plus 
											<FormControl
												className={styles.tableForms}
											>
												<Controller
													control={contractForm.control}
													name='renewalFeePlus'
													render={({ field }) => (
														<input
															{...field}
															disabled={props?.isPrint}
															min={0}
															max={100}
															type='number'
															onChange={(e: any) => {
																const inputValue = e.target.value;
						
																if (/^\d*\.?\d{0,2}$/.test(inputValue) && (inputValue === '' || parseFloat(inputValue) <= 100)) {
																	field.onChange(inputValue);
																}
															}}
															onBlur={() => {
																const numericValue = parseFloat(String(field.value));
																if (!isNaN(numericValue)) {
																	const cappedValue = Math.min(100, numericValue);
																	field.onChange(cappedValue.toFixed(2));
																} else {
																	field.onChange('');
																}
															}}
														/>
													)}
												/>
											</FormControl>
										% agent fee, only if talent is presented. Option can be exercised by Advertiser within the original term from first use and if talent remains in final assets. Agency or Advertiser will notify the talent of their intention to renew prior to expiration.
										</div>
									</td>
								</tr>
								<tr>
									<td colSpan={3}>
										<p><b>Conditions:</b></p>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<p>	<span>All fees are plus </span>
												<FormControl
													className={styles.tableForms}
												>
													<Controller
														control={contractForm.control}
														name='allFee'
														render={({ field }) => (
															<input
																{...field}
																disabled={props?.isPrint}
																min={0}
																max={100}
																type='number'
																onChange={(e: any) => {
																	const inputValue = e.target.value;
							
																	if (/^\d*\.?\d{0,2}$/.test(inputValue) && (inputValue === '' || parseFloat(inputValue) <= 100)) {
																		field.onChange(inputValue);
																	}
																}}
																onBlur={() => {
																	const numericValue = parseFloat(String(field.value));
																	if (!isNaN(numericValue)) {
																		const cappedValue = Math.min(100, numericValue);
																		field.onChange(cappedValue.toFixed(2));
																	} else {
																		field.onChange('');
																	}
																}}
															/>
														)}
													/>
												</FormControl>
												<span>% agent fee, only if talent is represented.  All fees are paid in USD currency. Agency shall cause a third-party Pay Master to process and issue talent payments to Talent. Talent will be paid from timecard. The Pay Master shall be the employer of record for payroll purposes and shall process all the applicable state, federal and local taxes on such fees as noted above. Ad Agency is the hiring entity. Agent fees to be paid thru a payroll provider, no agent invoices needed. Session and Use payments are typically processed separately.  </span>
											</p>
										</div>
									</td>
								</tr>
							</table>
						</div>
						<br></br>
						<p><b>For good and valuable consideration, receipt and sufficiency of which is hereby acknowledged, I hereby agree as follows:</b></p>
						<p>
							I am providing voice over services as an off camera talent in advertising material being produced by Agency and Advertiser on the work schedule listed above. I hereby agree to be so recorded, and I grant to Agency and Advertiser, and to other such persons as Agency or Advertiser may designate from time to time, the absolute right and permission to use any such material, including, without limitation, therein and includes without limitation, the right to reproduce and use any recordings of my voice and all instrumental, musical, or other sounds produced by me during the Term in the Territory in accordance with the terms of Media and Historical Use Rights outlined above
						</p>
						<br></br>
						<p>I agree that I will not hold Agency, Advertiser, or anyone who receives permission from either of them, responsible for any liability resulting from the use of my voice in any audio recordings in accordance with the terms hereof, including what might be deemed to be misrepresentation of me, my character or my person due to distortion or faulty reproduction which may occur in the finished product. I hereby waive any right that I may have to inspect or approve the finished product or products or the advertising copy or other advertising materials that may be used in connection therewith </p>
						<br></br>
						<p>
							I hereby agree to not post any advertising material or behind the scenes material to my social media or any other venue which can be viewed by the public without the express written consent of Advertiser.  I also agree that I shall abide by any restrictions placed by Advertiser on my use of the advertising material.  I shall not use or disclose any of the Advertiser and or Agency trade secrets or confidential information (including, without limitation, the terms of this agreement). Any advertising materials placed during the Term which remain on display or in circulation following the expiration of the Term shall not give rise to a claim against the Advertiser and or Agency, provided, however that they shall not place any additional advertising materials produced hereunder in any media (other than as permitted herein) following the expiration of the Term
						</p>

						<br></br>
						<p>I further agree in the event of a re-record of any or all scripts in which I participate, or if additional records are required (whether originally completed or not) I will return to work and render my services in such scripts at the same rate of compensation as agreed for the original production hourly rate.</p>
						<br></br>
						<p>I understand that I will not receive any other payments and my compensation is outlined above. I understand that all payments and notices to me shall be delivered to the address listed on this agreement. I also understand that it is my sole responsibility to inform the Agency and Advertiser of any change of address in writing.  If a payment does not reach me due to a change of address, Agency and Advertiser will hold such payments until notified by me in writing of the new mailing address.  Notices with respect to any provisions herein, including but not limited to notices to renew, extend or exercise any rights or options herein shall be deemed accepted by me if Agency and/or Advertiser sent such notice to the address which I provided and is unable to reach me due to my failure to provide change of address notification to Agency and/or Advertiser in writing</p>
						<br></br>
						<p>During the Term and throughout the Territory, I will not accept employment using my voice in any advertising material, for a competitive product or service of Advertiser, as defined above in the Exclusivity clause. I warrant and represent that this agreement does not in any way conflict with any existing exclusivity commitments on my part. Nothing herein will constitute any obligation on the Ad Agency or Advertiser to make any use of any of the rights set forth herein</p>
						<br></br>
						<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
							<p>This agreement is governed by the laws of the <b>State of</b> 
								<FormControl className={styles.tableForms} >
									<Controller
										control={contractForm.control}
										name='printState'
										defaultValue=''
										render={({ field }) => (
											(contractForm.watch('sessionCountry') === DEFAULT_COUNTRY_CODE) ? (<NabuTextbox
												placeholder={!props?.isPrint ? 'Enter State' : ''}
												data-cy='job_name_textbox'
												{...field}
												value={getStateLabel()}
												disabled={props?.isPrint}
												type='text'
											/>):
												(<input
													{...field}
													placeholder={!props?.isPrint ? 'State' : ''}
													disabled={props?.isPrint}
												/>
												)
										)}
									/>
								</FormControl>
								, and the parties agree to that jurisdiction if a dispute arises. This Agreement contains the entire understanding between the parties regarding the subject matter hereof and supersedes all prior understandings. No waiver, modification or additions to this Agreement shall be valid unless in writing and signed by the parties hereto.</p>
						</div>
						<br></br>
						<p> By signing this agreement, I hereby agree that the Agency and/or Advertiser may take deductions from my earnings to adjust previous overpayments if and when said overpayments occur. I am over the age of 18 (19 in Alabama or Nebraska) and have the right to enter into this Agreement on my own behalf. By signing below, I acknowledge that I have read and understood all the terms and conditions of this Agreement and agree to be bound thereby.</p>
						<br></br>
						<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
							<tr>
								<td>
									Signature 
								</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}><NabuTextbox value="" placeholder={props?.isPrint ? '' : 'Signature'} /></FormControl> 
								</td>
								<td>
									Date
								</td>
								<td>
									<FormControl className={styles.tableForms} >
										{props?.isPrint ? (
											<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('agreementDate') || '')}</p>
										) :(<Controller
											control={contractForm.control}
											name='agreementDate'
											render={({ field }) => (
												<NabuDatepicker
													{...field}
													disabled={props?.isPrint}
													placeholder={!props?.isPrint ? 'Date' : ''}
												/>
											)}
										/> )}
									</FormControl>
								</td>
							</tr>
							<tr>
								<td>
									Print Legal Talent Name
								</td>
								<td>
									<FormControl fullWidth className={styles.tableForms} >
										<Controller
											control={contractForm.control}
											key={`printName_${contractForm.watch(
												'performerId.value'
											)}`}
											name='printName'
											disabled={true}
											render={({ field }) => (
												<>
													<NabuTextbox
														data-cy='job_name_textbox'
														placeholder='Performer'
														{...field}
														value={contractForm.watch('performerId.label')}
													/>
												</>
											)}
										/> 
									</FormControl>
								</td>
								<td>
									AKA Name
								</td>
								<td>
									<FormControl fullWidth className={styles.tableForms} >
										<Controller
											control={contractForm.control}
											name='akaName'
											disabled={
												!contractForm.watch(
													'performerId.value'
												) || props?.isPrint
											}
											render={({ field }) => (
												<>
													<NabuTextbox
														data-cy='job_name_textbox'
														placeholder='Performer'
														{...field}
														value={contractForm.watch('performerId.label') && getTalentAliasName() || ''}
													/>
												</>
											)}
										/> 
									</FormControl>
								</td>
							</tr>
							<tr>
								<td width={200}>
									Talent Address
								</td>
								<td colSpan={3}>
									<FormControl fullWidth className={styles.tableForms} >
										<Controller
											control={contractForm.control}
											key={`select_Address_${contractForm.watch(
												'performerId.value'
											)}`}
											name='performerAddressId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														{...field}
														placeholder={
															!props?.isPrint
																? 'Select Address'
																: ''
														}
														key={`performer-address-${contractForm.watch(
															'performerId.value'
														)}`}
														filterProperty='Line1'
														sortField='IsPrimary'
														sortDescending={true}
														onOptionLoaded={(options) => {
															if(options?.length && !contractForm.watch('performerAddressId.value')){
																contractForm.setValue('performerAddressId', options[0], { shouldDirty: true});
																contractForm.trigger('performerAddressId', { shouldFocus: true});																						
															}
														}}
														APICaller={(filterModel) =>
															getAllAddressByPerson(
																filterModel,
																contractForm.watch(
																	'performerId.value'
																) || ''
															)
														}
														optionGenerator={
															adAgencyAddressOptionGenerator
														}
														dropDownsize={50}
														isDisabled={
															props?.isPrint ||
																						!contractForm.watch(
																							'performerId.value'
																						)
														}
														isClearable={true}
														isSearchText={true}
														onChange={(e: any) => {
															field.onChange(e);
														}}
														cacheOptions={false}
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
													{																							
														<Box sx={{mt:1.3}}>
															{(contractForm.watch('performerId.value') && (!props.isPrint)) && 
																							<AddPersonAddressInline
																								personId={contractForm.watch('performerId.value')}
																								title='ADDRESS'
																								isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'performer-address' || false}
																								event={addressPopOver?.event}
																								onAddressCreated={(d) => onPersonAddressCreated(d)}
																								onClose={() => setAddressPopOver({ isOpen: false })}
																								onPopOverOpen={(e) => setAddressPopOver({
																									isOpen: true,
																									event: e?.currentTarget,
																									for: 'performer-address'
																								})}
																								isPersistent={true} />
															}
														</Box>
													}
												</>
											)}
										/>
									</FormControl>
								</td>
							</tr>
							<tr>
								<td>
									Talent Phone
								</td>
								<td width={450}>
									<FormControl 
										fullWidth 
										className={styles.tableForms}
										key={`select-phone-${contractForm.watch(
											'performerPhoneId'
										)}-${contractForm.watch(
											'performerId'
										)}`} >
										<Controller
											control={contractForm.control}
											name='performerPhoneId'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuSelect
														{...field}
														key={`select-phone-${contractForm.watch(
															'performerPhoneId'
														)}-${contractForm.watch(
															'performerId'
														)}`}
														placeholder={!props?.isPrint ? 'Select Phone' : ''}
														convertValueToUpperCase={false}
														enableEmptySelection={true}
														disabled={
															props?.isPrint || !contractForm.watch('performerId.value')
														}
														options={getPerformerPhoneNumberOptions() || [] }
														handleDefaultSelection={(options) => {
															if((!contractForm.watch('performerPhoneId') && options?.length)){
																contractForm.setValue('performerPhoneId', options[0]?.value, { shouldDirty: true, shouldValidate: true});
															}
														}}
													/>
												</>
											)}
										/>
									</FormControl>
								</td>
								<td width={120}>
									Email
								</td>
								<td>
									<FormControl fullWidth className={styles.tableForms} >
										<Controller
											key={`select-phone-${contractForm.watch(
												'performerEmailId'
											)}-${contractForm.watch(
												'performerId.value'
											)}`}
											control={contractForm.control}
											name='performerEmailId'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuSelect
														{...field}
														key={`select-phone-${contractForm.watch(
															'performerEmailId'
														)}-${contractForm.watch(
															'performerId.value'
														)}`}
														placeholder={
															!props?.isPrint ? 'Select Email' : ''
														}
														convertValueToUpperCase={false}
														enableEmptySelection={true}
														disabled={
															props?.isPrint ||
																					!contractForm.watch(
																						'performerId.value'
																					)
														}
														options={getPerformerEmailsOptions()}
														handleDefaultSelection={() => !contractForm.watch('performerEmailId')}
													/>
												</>																				
											)}
										/>
									</FormControl>
								</td>
							</tr>
						</table>
						<br></br>
						<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
							<tr>
								<td width={200}>
									Talent Agency
								</td>
								<td width={450}>
									<FormControl  fullWidth className={styles.tableForms} >
										<Controller
											// key={`agency-cr-${contractForm.watch('performerId.value')}`}
											control={contractForm.control}
											name='talentAgencyId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														data-cy='ad_agency'
														key={`agency-${contractForm.watch('performerId.value')}-${contractForm.watch('talentAgencyId.value')}`}
														{...field}
														value={contractForm.watch('talentAgencyId')}
														placeholder={
															!props?.isPrint ? 'Ad-Agency' : ''
														}
														filterProperty='CompanyNames.Name'
														APICaller={(filter) => {
															filter.filters = filter.filters?.map((f) => f.property == 'CompanyNames.Name' ? {...f, value: f?.value?.trim() || '' } : f) || [];
															return getAgentInfo(filter, contractForm.watch('performerId.value') || '');
														}}
														optionGenerator={
															companyOptionGenerator
														}
														isClearable={true}
														isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
														onChange={(e: any) => {
															field.onChange(e);
															contractForm.setValue('talentAgencyAddressId', null, { shouldDirty: true } );
															contractForm.setValue('talentAgencyEmailId', '', { shouldDirty: true } );
															contractForm.setValue('talentAgencyPhoneId', null, { shouldDirty: true } );
															contractForm.setValue('talentAgencyStaffId', null, { shouldDirty: true } );
															contractForm.trigger('talentAgencyStaffId' );
															contractForm.trigger('talentAgencyAddressId' );
															contractForm.trigger('talentAgencyEmailId');
															contractForm.trigger('talentAgencyPhoneId');
														}}
														onOptionLoaded={(options) => {
															if(options?.length && !contractForm.watch('talentAgencyId.value')){
																contractForm.setValue('talentAgencyId', options[0], { shouldDirty: true});
																contractForm.trigger('talentAgencyId', { shouldFocus: true});
															}
														}}
														cacheOptions
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
													{!props?.isPrint && contractForm.watch('performerId.value') && (<Box sx={{float:'right'}}><AddTalentAgentInline
														onCompanyCreated={(createdAgent) => contractForm.setValue('talentAgencyId', createdAgent, { shouldDirty: true})}
														onClose={() => setCompanyPopoverInline({ isOpen: false})}
														onPopOverOpen={(e) => setCompanyPopoverInline({ isOpen: true, event: e?.currentTarget, for: 'talentAgency' })}
														event={companyPopoverInline?.event}
														isOpen={companyPopoverInline.isOpen && companyPopoverInline.for === 'talentAgency'}
														personId={contractForm.watch('performerId.value') || ''}
													/></Box> )}
												</>
											)}
										/>
									</FormControl>
								</td>
								<td width={120}>
									Agent
								</td>
								<td>
									<FormControl fullWidth className={styles.tableForms} >
										<Controller
											key={`talentAgency_StaffName-${contractForm.watch(
												'performerId.value'
											)}-${contractForm.watch('talentAgencyId.value')}`}
											control={contractForm.control}
											name='talentAgencyStaffId'
											render={({ field }) => (
												<Autocomplete
													key={`talentAgency_StaffName-${contractForm.watch(
														'performerId.value'
													)}-${contractForm.watch('talentAgencyId.value')}`}
													data-cy='StaffSelect'
													id="combo-box-demo"
													options={staffOptions}
													{...field}
													onChange={(e, item :any) => {
														contractForm.setValue('talentAgencyStaffId', item);
													}}
													disabled={
														props?.isPrint ||
																				!contractForm.watch(
																					'talentAgencyId.value'
																				)
													}
												
													renderInput={(params) => <TextField placeholder='Agent Name' data-cy='StaffSelectTextField' {...params} />}
												/>
											)}
										/> 
									</FormControl>
								</td>
							</tr>
							<tr>
								<td>
									Talent Agency Address
								</td>
								<td colSpan={3}>
									<FormControl fullWidth className={styles.tableForms} >
										<Controller
											control={contractForm.control}
											key={`agency-address-cr-${contractForm.watch(
												'talentAgencyId.value'
											)}`}
											name='talentAgencyAddressId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														{...field}
														placeholder={
															!props?.isPrint
																? 'Select Address'
																: ''
														}
														key={`agency-address-${contractForm.watch(
															'talentAgencyId.value'
														)}`}
														filterProperty='Line1'
														sortField='IsPrimary'
														sortDescending={true}
														onOptionLoaded={(options) => {
															if(options?.length && !contractForm.watch('talentAgencyAddressId.value')){
																contractForm.setValue('talentAgencyAddressId', options[0], { shouldDirty: true});
																contractForm.trigger('talentAgencyAddressId', { shouldFocus: true});																						
															}
														}}
														APICaller={(filterModel) =>
															getAllAddressByCompany(
																filterModel,
																contractForm.watch(
																	'talentAgencyId.value'
																) || ''
															)
														}
														optionGenerator={
															adAgencyAddressOptionGenerator
														}
														isDisabled={
															!contractForm.watch(
																'talentAgencyId.value'
															) || props?.isPrint
														}
														dropDownsize={50}
														isClearable={true}
														isSearchText={true}
														onChange={(e: any) => {
															field.onChange(e);
															contractForm.setValue('talentAgencyEmailId','',{ shouldDirty: true });
															contractForm.setValue('talentAgencyPhoneId',null,{ shouldDirty: true });
															contractForm.trigger('talentAgencyEmailId');
															contractForm.trigger('talentAgencyPhoneId');
														}}
														cacheOptions={false}
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
												</>
											)}
										/>
									</FormControl>	
								</td>
							</tr>
							<tr>
								<td>
									Agent Phone
								</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											key={`select-phone-${contractForm.watch(
												'talentAgencyPhoneId'
											)}-${contractForm.watch(
												'talentAgencyAddressId.value'
											)}`}
											name='talentAgencyPhoneId'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuSelect
														{...field}
														key={`select-phone-${contractForm.watch(
															'talentAgencyPhoneId'
														)}-${contractForm.watch(
															'talentAgencyAddressId.value'
														)}`}
														placeholder={
															!props?.isPrint ? 'Select Phone' : ''
														}
														convertValueToUpperCase={false}
														enableEmptySelection={true}
														disabled={
															props?.isPrint ||
																					!contractForm.watch(
																						'talentAgencyAddressId.value'
																					)
														}
														options={getPhoneNumberOptions(
															contractForm.watch(
																'talentAgencyAddressId.value'
															)
														)}
														handleDefaultSelection={(options) => {
															if((!contractForm.watch('talentAgencyPhoneId') && options?.length)){
																contractForm.setValue('talentAgencyPhoneId', options[0]?.value, { shouldDirty: true, shouldValidate: true});
															}
														}}
													/>
												</>
											)}
										/>
									</FormControl>
								</td>
								<td>
									Email
								</td>
								<td>
									<FormControl className={styles.tableForms} fullWidth>
										<Controller
											control={contractForm.control}
											key={`select-em-${contractForm.watch(
												'talentAgencyAddressId.value'
											)}-${contractForm.watch('talentAgencyEmailId')}`}
											defaultValue = ''
											name='talentAgencyEmailId'
											render={({ field }) => (
												<>
													<NabuSelect
														{...field}
														key={`select-em-${contractForm.watch(
															'talentAgencyAddressId.value'
														)}-${contractForm.watch('talentAgencyEmailId')}`}
														placeholder={
															!props?.isPrint ? 'Select Email' : ''
														}
														convertValueToUpperCase={false}
														enableEmptySelection={true}
														disabled={
															props?.isPrint ||
																					!contractForm.watch(
																						'talentAgencyAddressId.value'
																					)
														}
														options={getCompanyEmailOptions(
															contractForm.watch(
																'talentAgencyAddressId.value'
															)
														)}
														handleDefaultSelection={() => !contractForm.watch('talentAgencyEmailId')}
													/>
												</>	
											)}
										/>
									</FormControl>
								</td>
							</tr>

						</table>
						<br></br>

						<div className='keep-together'>
							<p><b><u>Payment Authorization: </u></b></p>
							<p><b>Talent authorizes payments to which Talent may be entitled hereunder, as follows:</b></p>
							<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
								<Controller
									control={contractForm.control}
									name='isPaymentAtPerformerAddress'
									defaultValue={true}
									disabled={props?.isPrint}
									render={({ field }) => (
										<div
											onClick={props?.isPrint ? undefined :  (() => {
												field.onChange(!field.value);
												(!props?.isPrint) && (contractForm.setValue('isPaymentAtAgentAddress', !contractForm.watch('isPaymentAtPerformerAddress'))) ;
											})}
											style={{ userSelect: 'none' }}
										>
											<Box display={'flex'} alignItems={'flex-start'}>
												<input
													{...field}
													value={field.value + ''}
													checked={field.value}
													onChange={(e) => {
														field.onChange(e);
														contractForm.setValue('isPaymentAtAgentAddress', !contractForm.watch('isPaymentAtPerformerAddress'));
													}}
													type='checkbox'
													disabled={props?.isPrint}
													style={{ marginTop: '3px' }}
												/>
												<span>To <b>Talent</b> at address listed above</span>
											</Box>
										</div>
									)}
								/>
								<span>OR</span>
								<Controller
									control={contractForm.control}
									name='isPaymentAtAgentAddress'
									disabled={props?.isPrint}
									render={({ field }) => (
										<div
											onClick={props?.isPrint ? undefined : () => {
												field.onChange(!field.value);
												(!props?.isPrint) && contractForm.setValue('isPaymentAtPerformerAddress', !contractForm.watch('isPaymentAtAgentAddress'));
											}}
											style={{ userSelect: 'none' }}
										>
											<Box display={'flex'} alignItems={'flex-start'}>
												<input
													{...field}
													value={field.value + ''}
													checked={field.value}
													onChange={(e) => {
														field.onChange(e);
														contractForm.setValue('isPaymentAtPerformerAddress', !contractForm.watch('isPaymentAtAgentAddress'));
													}}
													type='checkbox'
													disabled={props?.isPrint}
													style={{marginTop: '3px' }}
												/>
												<span>To <b>Agent</b> at address listed above</span>
											</Box>
										</div>
									)}
								/>
							</div>
						</div>
						<br></br>
						<div className='keep-together'>
							<p><b><u>Minor</u></b></p>
							<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
								<tr>
									<td colSpan={4}>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<span>I, as parent/legal guardian of</span>
											<FormControl className={styles.tableForms} >
												<Controller
													control={contractForm.control}
													name='printName'
													defaultValue={contractForm.watch('performerId.label')}
													render={({ field }) => (
														<>
															<NabuTextbox
																data-cy='job_name_textbox'
																placeholder={props?.isPrint ? '' : 'Performer'}
																{...field}
																disabled={props?.isPrint}
																value={isMinor() ? contractForm.watch('performerId.label') : ''}
															/>
														</>
													)}
												/> 
											</FormControl>
											<span>, agree to the above and I sign this document to signify my agreement.</span>
										</div>
									</td>
								</tr>
								<tr>
									<td width={250}>
									Parent/Guardian Signature
									</td>
									<td width={450}>
										<FormControl fullWidth className={styles.tableForms}><NabuTextbox value ='' placeholder={props?.isPrint ? '' : 'Parent/Guardian Signature'} /></FormControl> 
									</td>
									<td width={120}>
									Date
									</td>
									<td>
										<FormControl  className={styles.tableForms} >
											{props?.isPrint ? (
												<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('signatureDate') || '')}</p>
											) :(<Controller
												control={contractForm.control}
												name='signatureDate'
												render={({ field }) => (
													<NabuDatepicker
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Date' : ''}
													/>
												)}
											/>)}
										</FormControl>
									</td>
								</tr>
								<tr>
									<td width={250}>
									Parent/Guardian Print Name
									</td>
									<td colSpan={3}>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='guardianPrintName'
												render={({ field }) => (
													<NabuTextbox 
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Parent/Guardian Print Name' : ''} 
													/>
												)}
											/>
										</FormControl> 
									</td>
								</tr>
							</table>
						</div>
						<br></br>
						<div className='keep-together'>
							<p><b><u>Witnesses:</u></b></p>
							<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
								<tr>
									<td width={250}>
									Producer’s Signature
									</td>
									<td width={450}>
										<FormControl fullWidth className={styles.tableForms}><NabuTextbox value='' placeholder={!props?.isPrint ? 'Producer’s Signature' : ''} /></FormControl> 
									</td>
									<td width={120}>
									Date
									</td>
									<td>
										<FormControl  className={styles.tableForms} >
											{props?.isPrint ? (
												<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('producerSignatureDate') || '')}</p>
											) :(<Controller
												control={contractForm.control}
												name='producerSignatureDate'
												render={({ field }) => (
													<NabuDatepicker
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Date' : ''}
													/>
												)}
											/>)}
										</FormControl>
									</td>
								</tr>
								<tr>
									<td width={250}>
									Business Affairs Mgr
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='businessAffairMgrName'
												render={({ field }) => (
													<NabuTextbox
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Business Affairs Mgr' : ''}
													/>
												)}
											/>
										</FormControl> 
									</td>
									<td width={120}>
									Date
									</td>
									<td>
										<FormControl className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='businessAffairDate'
												render={({ field }) => (
													<NabuDatepicker
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Date' : ''}
													/>
												)}
											/>
										</FormControl> 
									</td>
								</tr>
							</table>
						</div>
					</div>
				</div>
			</ContractMutationWrapper>
			{isExpiryDateDialogOpen.isOpen && <ExpiryDateForm
				contractExpiryDate={isExpiryDateDialogOpen.contractExpiryDate || props?.initialValue?.expirationDate}
				isOpen={isExpiryDateDialogOpen.isOpen}
				handleExpiryDateForm={(values) => onSubmitOfExpiryDateForm(values)}
				handleFormClose={closeExpiryDateForm}
			/>}
		</>
	);
};

export default NonUnionTalentAgreementVoiceOver;
