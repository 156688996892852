import React from 'react';
import ReactDOM from 'react-dom/client';
// import 'rsuite/dist/rsuite.min.css';
import { QueryClient, QueryClientProvider } from 'react-query';
// import '@/assets/scss/main.scss';
import { Provider } from 'react-redux';
import App from './modules/app/app';
import reportWebVitals from './reportWebVitals';
import store from './store';

import '../../FrontEnd/src/main.module.scss';
import { LoaderContextProvider } from './common/utils/loaderService';
import { NotificationContextProvider } from './common/utils/notificationService';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<Provider store={store}>
				{/* <ConfirmDialogProvider> */}
				<LoaderContextProvider>
					<NotificationContextProvider>
						<App />
					</NotificationContextProvider>
				</LoaderContextProvider>
				{/* </ConfirmDialogProvider> */}
			</Provider>
		</QueryClientProvider>
	</React.StrictMode>
);

reportWebVitals();
