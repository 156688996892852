import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useRef, useState } from 'react';
import './text-editor.scss';

interface TextEditorProps {
    initialValue?: string;
    onChange: (value: string) => void;
    isNoteEditor?: boolean;
    editorRef?: any;
	id?: string;
	onInit?: CallableFunction;
}

const TextEditor = (props: TextEditorProps) => {
	const { initialValue, onChange } = props;
	const srollRef = useRef<HTMLDivElement>(null);
	const editorRef = props.editorRef ? props.editorRef : useRef<any>();
	const [content, setContent] = useState(initialValue);

	const handleEditorChange = (content: string) => {
		setContent(content);
		onChange && onChange(content);
	};

	useEffect(() => {
		setContent(initialValue);
	}, [initialValue]);

	return (
		<>
			<Editor
				apiKey={process.env.REACT_APP_TINYMCE_API_KEY}
				// licenseKey={process.env.REACT_APP_TINYMCE_LICENSE_KEY}
				value={content}
				init={{
					menubar: false,
					plugins: [
						'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
						'anchor', 'searchreplace', 'visualblocks', 'code',
						'insertdatetime', 'media', 'table', 'code', 'help', 'wordcount',
						'directionality', 'visualchars', 'codesample', 'pagebreak', 'nonbreaking',
						'quickbars', 'emoticons', 'accordion'
					],
					toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | align numlist bullist | link image | table media | lineheight outdent indent| forecolor backcolor removeformat | charmap emoticons | code preview | print | pagebreak anchor codesample | advlist autolink lists | searchreplace visualblocks insertdatetime'
				}}
				onInit={(evt, editor) => {
					editorRef.current = editor;
					props?.onInit && props?.onInit(editor, srollRef.current);
				}}
				onEditorChange={handleEditorChange}
				id={props?.id}
			/>
			<div ref={srollRef}></div>
		</>
	);
};

export default TextEditor;
