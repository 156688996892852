import NabuButton from '@/common/components/atoms/button/button';
import DataTable from '@/common/components/molecules/dataTable/dataTable';
import NabuDialog, {
	IDialogAction,
} from '@/common/components/molecules/dialog/dialog';
import { NabuSearchFilters } from '@/common/components/molecules/searchFIlter/searchFIlter';
import { DefaultPageSize } from '@/common/constants/constants';
import { OPERATORS } from '@/common/enums';
import useNotification from '@/common/hooks/useNotification';
import { IFormState } from '@/common/types/asset';
import { ICompensationRecord } from '@/common/types/contract';
import { columnsType, ITableActionMenuModel } from '@/common/types/dataTable';
import { IFilterModel, IPagedResultModel } from '@/common/types/filterModel';
import {
	getNotificationText,
	getFormattedCurrency,
	mapToData,
	notificationMessageForFormOpenState,
} from '@/common/utils/utils';
import {
	deleteContractCompensation,
	getByCompensationForContract,
} from '@/services/contract';
import store, { IStore } from '@/store';
import { setFormName, setIsEditOpen } from '@/store/app/reducer';
import { Add, DeleteForever, Edit } from '@mui/icons-material';
import SearchIcon from '@mui/icons-material/Search';
import { Box } from '@mui/joy';
import { Grid, InputAdornment } from '@mui/material';
import { HttpStatusCode } from 'axios';
import { useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import styles from '../dealPoints.module.scss';
import CompensationForm, { ICompensation } from './contractCompensationForm';
import TextWrap from '@/common/components/atoms/textWrap/textWrap';

const ContractCompensation = () => {
	const contractId = useParams()?.contractId || '';
	const notification = useNotification();
	const DEFAULT_SORT_FIELD = 'Type';
	const appContext = useSelector((state: IStore) => state.appContext);

	const [modelState, setModelState] =
		useState<IFormState<ICompensationRecord | ICompensation[]>>();
	const [compensationPage, setCompensationPage] = useState<IFilterModel>({
		pageNumber: 0,
		pageSize: DefaultPageSize,
		sortDescending: false,
		sortField: DEFAULT_SORT_FIELD,
	});
	const [compensationsData, setCompensationsData] = useState<
		IPagedResultModel<ICompensationRecord>
	>({
		records: [],
		totalRecords: 0,
	});

	// Table Configs and Action menus
	const TABLE_COLUM_CONFIG: columnsType[] = [
		{
			id: 'type',
			label: 'COMPENSATION TYPE',
			allowSort: true,
			sortKey: DEFAULT_SORT_FIELD,
		},
		{
			id: 'getServiceRenderedOn',
			label: 'RATE PER (HOUR/# OF HOURS)',
			allowSort: true,
			sortKey: 'RatePerHour',
		},
		{
			id: 'actions',
			label: '',
		},
	];

	const PRIMARY_ACTIONS: ITableActionMenuModel[] = [
		{
			title: () => 'Edit',
			icon: () => Edit,
			iconColor: 'primary',
			onClick: (record) => handlePrimaryAction(record, 'EDIT'),
			'data-cy': 'edit_compensation_list_btn',
		},
		{
			title: () => 'Delete',
			icon: () => DeleteForever,
			iconColor: 'error',
			onClick: (record) => handlePrimaryAction(record, 'DELETE'),
			'data-cy': 'delete_compensation_list_btn',
		},
	];

	const deleteCompensationDialogActions: IDialogAction[] = [
		{
			name: 'Yes, Remove',
			onClick: handleContractCompensationDelete,
			color: 'error',
			variant: 'contained',
			'data-cy': 'yes_remove_btn',
		},
		{
			name: 'Do not remove',
			onClick: () => onModelClose(),
			variant: 'outlined',
			'data-cy': 'no_remove_btn',
		},
	];

	// Helper functions
	const showEditSectionNotification = () => {
		notification.notify({
			type: 'error',
			message: notificationMessageForFormOpenState(appContext?.formName),
		});
	};

	function onModelClose(shouldCloseForm: boolean = false) {
		if(shouldCloseForm){
			store.dispatch(setIsEditOpen(false));
		}
		setModelState({});
	}

	function handleContractCompensationDelete() {
		modelState?.isDelete &&
			modelState?.selectedId &&
			deleteContractCompensationById(modelState?.selectedId || '');
	}

	function handlePrimaryAction(props, action: 'EDIT' | 'DELETE' | 'ADD') {
		const record = props as ICompensationRecord;

		switch (action) {
		case 'EDIT':
			{
				if (!appContext?.isEditOpen) {
					store.dispatch(setFormName('Contract Compensation'));
					store.dispatch(setIsEditOpen(true));
					setModelState({
						isOpen: true,
						for: 'edit-compensation',
						rIdx: record?.rowIndex,
						selectedData: [
							{
								ratePerHour: record?.ratePerHour,
								type: record?.type,
								id: record?.id,
							},
						],
					});
				} else showEditSectionNotification();
			}
			break;
		case 'DELETE':
			setModelState({
				isDelete: true,
				selectedId: record?.id,
				for: 'delete-compensation',
			});
			break;
		case 'ADD':
			{
				if (!appContext?.isEditOpen) {
					store.dispatch(setFormName('Contract Compensation'));
					store.dispatch(setIsEditOpen(true));
					setModelState({
						isOpen: true,
						for: 'add-compensation',
					});
				} else showEditSectionNotification();				
			}
			break;
		}
	}

	const compensationDataFieldMapping = (
		record: ICompensationRecord
	) => ({
		getServiceRenderedOn: {
			get: () => getFormattedCurrency(record?.ratePerHour),
		}
	});

	// API and Filter logics
	const { refetch: fetchContractCompensation } = useQuery({
		queryKey: ['contract', 'getAll', compensationPage],
		queryFn: () => getByCompensationForContract(contractId, compensationPage),
		refetchOnWindowFocus: false,
		onSuccess: (res) => {
			if (!(res?.status === HttpStatusCode.Ok)) {
				notification.notify({
					type: 'error',
					message: getNotificationText(
						'Contract Compensation',
						'FETCHING',
						true
					),
				});
				return;
			}

			const data = mapToData<ICompensationRecord>(res?.data?.records || [], compensationDataFieldMapping);
			setCompensationsData({
				records: data || [],
				totalRecords: res?.data?.totalRecords || 0,
			});
		},
		onError: () => {
			notification.notify({
				type: 'error',
				message: getNotificationText('Contract Compensation', 'FETCHING', true),
			});
		},
	});

	const { mutate: deleteContractCompensationById } = useMutation({
		mutationFn: deleteContractCompensation,
		onSuccess: (res) => {
			if (res.status === HttpStatusCode.Ok) {
				notification.notify({
					message: getNotificationText('Contract Compensation', 'DELETE'),
					type: 'success',
				});
				onModelClose();
				fetchContractCompensation();
			} else {
				notification.notify({
					message: getNotificationText('Contract Compensation', 'DELETE', true),
					type: 'error',
				});
			}
		},
		onError: () =>
			notification.notify({
				message: getNotificationText('Contract Compensation', 'DELETE', true),
				type: 'error',
			}),
	});

	const handleTableSort = (sort: {
		sortFields: string;
		sortDescending: boolean;
	}) => {
		setCompensationPage((prev) => ({
			...prev,
			filters: prev?.filters && [...prev.filters],
			pageNumber: 0,
			sortDescending: sort.sortDescending,
			sortField: sort?.sortFields?.replace('-', '') || DEFAULT_SORT_FIELD,
		}));
	};

	const handleSearchText = (searchText: string) => {
		const SEARCH_FILTER_PROPERTY = 'Type';
		const otherFilter =
			compensationPage?.filters?.filter(
				(af) => af?.property !== SEARCH_FILTER_PROPERTY
			) || [];
		setCompensationPage((prev) => ({
			...prev,
			filters: [
				...otherFilter,
				{
					isCaseSensitive: false,
					operator: OPERATORS.CONTAINS,
					property: SEARCH_FILTER_PROPERTY,
					value: searchText,
				},
			],
		}));
	};

	const CompensationTypeCell = (record:any) => {
		return(
			<>
				<TextWrap title={record?.type}/>
			</>
		);
	};

	return (
		<>
			<div className={styles.sectionRightsUsedExcluvity}>
				<Grid item container columnSpacing={3}>
					<Grid item sm={12} md={6}>
						<NabuSearchFilters
							fullWidth
							id='search-input-text-field'
							placeholder='Search By Type'
							variant='outlined'
							size='small'
							dependency={compensationPage?.filters}
							onDebounceTextChange={handleSearchText}
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchIcon />
									</InputAdornment>
								),
							}}
							data-cy='searchInputFilterTextBox'
						/>
					</Grid>
					<Grid item sm={12} md={6} dir='rtl'>
						<NabuButton variant='filled' onClick={() => handlePrimaryAction(undefined, 'ADD')}>
							Compensation <Add />
						</NabuButton>
					</Grid>
					<Grid item md={12} sx={{ mt: 3 }}>
						{modelState?.isOpen && modelState?.for == 'add-compensation' && (
							<CompensationForm
								isEdit={false}
								onClose={() => onModelClose(true)}
								refresh={fetchContractCompensation}
							/>
						)}
					</Grid>
					<Grid item md={12}>
						<Box>
							<DataTable
								rows={compensationsData?.records}
								columns={TABLE_COLUM_CONFIG}
								actionsMenu={PRIMARY_ACTIONS}
								totalRowsCount={compensationsData?.totalRecords}
								currentPage={compensationPage?.pageNumber}
								rowsPerPage={compensationPage?.pageSize}
								tableName='compensation'
								editIndex={modelState?.rIdx}
								inlineRows={{
									slot: 'compensationEdit',
									rowIndex: modelState?.rIdx ?? -1,
									isOpen: !!(
										modelState?.isOpen && modelState?.for == 'edit-compensation'
									),
								}}
								defaultSortDescending={true}
								onChange={handleTableSort}
							>
								<CompensationForm
									slot='compensationEdit'
									isEdit={!!modelState?.selectedData}
									initialValue={modelState?.selectedData as ICompensation[]}
									onClose={() => onModelClose(true)}
									refresh={fetchContractCompensation}
								/>
								<CompensationTypeCell slot = 'type'/>
							</DataTable>
						</Box>
					</Grid>
				</Grid>
			</div>
			{/* Delete compensation dialog */}
			<NabuDialog
				title='Delete Compensation from Contract ?'
				open={
					!!modelState?.isDelete && modelState?.for === 'delete-compensation'
				}
				maxWidth='xs'
				handleClose={() => onModelClose()}
				dialogActions={deleteCompensationDialogActions}
				isPersistent={true}
			>
				<strong>Are you sure you want to delete compensation ?</strong>
				<p>
					This action <b>can not be undone</b>
				</p>
			</NabuDialog>
		</>
	);
};

export default ContractCompensation;
