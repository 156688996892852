import SearchIcon from '@mui/icons-material/Search';
import { InputAdornment } from '@mui/material';
import Box from '@mui/material/Box';
import { NabuTextbox } from '../../atoms/textbox/textbox';
import styles from './searchFIlter.module.scss';
import { forwardRef, useCallback } from 'react';
import { debounce } from '@/common/utils/utils';
import { DebounceTimeSearchMillis } from '@/common/constants/constants';

// eslint-disable-next-line react/display-name
export const NabuSearchFilters = forwardRef((props: any, ref: any) => {
	const { onDebounceTextChange, searchByField} = props;
	
	const handleSearchText = (event: React.ChangeEvent<HTMLInputElement>) => {
		onDebounceTextChange && onDebounceTextChange(event?.target?.value?.trim() || '');
	};
	
	const updateDebounceText = useCallback( 
		debounce(handleSearchText, DebounceTimeSearchMillis),
		[props?.dependency]
	);

	return (
		<>
			<Box>
				<div className={styles.search} style={{ ...props.style }}>
					<div className={styles.searchsection}>
						<NabuTextbox
							className={styles.searchMain}
							fullWidth
							id='search-input-text-field'
							placeholder={searchByField ? `Search by ${searchByField}` : 'Search'}
							variant='outlined'
							size='small'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'>
										<SearchIcon />
									</InputAdornment>
								),
							}}
							{...props}
							ref={ref}
							onChange={(e) => {
								props?.onChange && props.onChange(e);
								updateDebounceText(e);
							}}
							data-cy='searchInputFilterTextBox'
						/>
					</div>
				</div>
			</Box>
		</>
	);
});
