import NabuButton from '@/common/components/atoms/button/button';
import NabuDialog from '@/common/components/molecules/dialog/dialog';
import { getLocaleDate, getSecondsInMMSS } from '@/common/utils/utils';
import { RemoveRedEye } from '@mui/icons-material';
import { Grid } from '@mui/material';
import { continents } from 'countries-list';
import { Country as CountriesData } from 'country-state-city';
import { Fragment, useState } from 'react';
import AddRightsAcquiredForm from './AddRightsAcquiredForm';
import styles from './nestedTable.module.scss';

type Props = {  
	rightsContractedData: any;
	fetchRightsAcquired: () => void;
	for: 'ELEMENT' | 'CONTRACT';
	initialValue: any;
}

interface IRightsAcquiredFormDrawer {
	isOpen: boolean;
	selectedRightsAcquired?: any;
	rIdx: number; 
}

export const RightsAcquiredTableItem = (props: Props) => {

	const continentsOptions = Object.keys(continents).map((key) =>
	{
		return {
			value: key,
			label: continents[key]
		};
	});

	const getRegionName = (regionCode: string) => {
		return continentsOptions.find(e => e.value == regionCode)?.label;
	};

	const { rightsContractedData, fetchRightsAcquired } = props;
	const data = {
		rightsContractedDetail: {
			address: rightsContractedData?.territoryAddresses || [],
			region: rightsContractedData?.region || [],
			restrictions: rightsContractedData?.restrictions || '',
			costs: rightsContractedData?.costs || '',
			currency: rightsContractedData?.currency || '',
			procurementFee: rightsContractedData.procurementFee || '',
			totalCosts: rightsContractedData.totalCost || '',
			renewalCosts: rightsContractedData?.renewalCost || '',
			renewalOptions: rightsContractedData?.renewalOptions ? 'Yes' : 'No',
			optionsForTalent: rightsContractedData?.optionsForTalent ? 'Yes' : 'No',
			isWorldWide: rightsContractedData?.isWorldWide ? 'Yes' : 'No',
			period: rightsContractedData?.period || '',
			week: rightsContractedData?.unit || '',
			endDate: rightsContractedData?.endDate || '',
			startDate: rightsContractedData?.startDate || '',
			territory: rightsContractedData?.territory || 'territory',
		}
	};

	const [ rightsAcquiredForm, setRightsAcquiredForm] = useState<IRightsAcquiredFormDrawer>({isOpen: false, rIdx: -1});

	const [countryPopUp, setCountryPopUp] = useState<{
		isOpen: boolean,
		title: string,
		data: any
	}>({
		isOpen: false,
		title: '',
		data: []
	});

	const handleAddressPopUpClose = () => {
		setCountryPopUp({
			isOpen: false,
			data: [],
			title: '',
		});
	};

	const [territoryDialog, setTerritoryDialog] = useState<{isOpen: boolean, data: any}>({
		isOpen: false,
		data: []
	});
	
	const handleTerritoryDialogClose = () => {
		setTerritoryDialog({
			isOpen: false,
			data: []
		});
	};

	return (
		<>
			<section id='rights_contract_details' data-cy='rights_contract_details'>
				{<section className={styles.mainCard}>
					{!rightsAcquiredForm.isOpen ? <> <Grid container item>
						<Grid item md={12}>
							<div className={styles.oddRow}>
								<Grid item container columnSpacing={3}>
									<Grid item md={12}>
										<div style={{borderBottom:'#ededed solid 1px', paddingBottom:'3px', marginBottom:'3px'}}>
											<b>Term</b>
										</div>
									</Grid>
									<Grid item sm={12} md={3}>
										<div
											className={styles.elementsRightsUsedcontract}
											data-cy='RightsUsedDashboard'
										>
											<span>Period</span>
											{data.rightsContractedDetail.period}
										</div>
									</Grid>
									<Grid item sm={12} md={3}>
										<div
											className={styles.elementsRightsUsedcontract}
											data-cy='RightsUsedDashboard'
										>
											<span>Unit</span>
											{data.rightsContractedDetail.week}
										</div>
									</Grid>
									<Grid item sm={12} md={3}>
										<div
											className={styles.elementsRightsUsedcontract}
											data-cy='RightsUsedDashboard'
										>
											<span>Start Date</span>
											{ getLocaleDate(data.rightsContractedDetail.startDate)}
										</div>
									</Grid>
									<Grid item sm={12} md={3}>
										<div
											className={styles.elementsRightsUsedcontract}
											data-cy='RightsUsedDashboard'
										>
											<span>End Date</span>
											{getLocaleDate(data.rightsContractedDetail.endDate)}
										</div>
									</Grid>
								</Grid>
							</div>
							<div className={styles.oddRow}>
								<Grid item container columnSpacing={3}>
									<Grid item md={12}>
										<div style={{borderBottom:'#ededed solid 1px', paddingBottom:'3px', marginBottom:'3px'}}>
											<b>Territory</b>
										</div>
									</Grid>
									<Grid item sm={12} md={3}>
										<div
											className={styles.elementsRightsUsedcontract}
											data-cy='RightsUsedDashboard'
										>
											<span>World Wide</span>
											<div className={styles.breakWord}>
												<div className={styles.actionMore}>
													{data?.rightsContractedDetail?.isWorldWide.toUpperCase()}
												</div>
											</div>
										</div>
									</Grid>
									<Grid item sm={12} md={3}>
										<div
											className={styles.elementsRightsUsedcontract}
											data-cy='RightsUsedDashboard'
										>
											<span>Region - Country</span>
											<div className={styles.breakWord}>
												<div className={styles.actionMore}>
													{rightsContractedData?.territories?.length > 0 && <><>{getRegionName(rightsContractedData?.territories[0]?.region).toUpperCase()+(rightsContractedData?.territories[0]?.countries?.length > 0 ? '-' : '')+ (rightsContractedData?.territories[0]?.countries?.length > 0 ? CountriesData?.getCountryByCode(rightsContractedData?.territories[0]?.countries[0])?.name.toUpperCase() : '')}</> <NabuButton variant='text' onClick={() => setTerritoryDialog({data: rightsContractedData?.territories, isOpen: true})}><RemoveRedEye/></NabuButton></>}
												</div>
											</div>
										</div>
									</Grid>
								</Grid>
							</div>
							<div className={styles.oddRow}>
								<Grid item container columnSpacing={3}>
									<Grid item sm={12} md={3}>
										<div
											className={styles.elementsRightsUsedcontract}
											data-cy='RightsUsedDashboard'
										>
											<span>Costs</span>
											{data.rightsContractedDetail.costs && (Number(data?.rightsContractedDetail?.costs).toLocaleString('en-US', { style: 'currency', currency: data?.rightsContractedDetail?.currency }))}
										</div>
									</Grid>
									<Grid item sm={12} md={3}>
										<div className={styles.elementsRightsUsedcontract} 	data-cy='RightsUsedDashboard'
										>
											<span>Handling/Mark Up %</span>
											{data.rightsContractedDetail.procurementFee}
										</div>
									</Grid>
									<Grid item sm={12} md={3}>
										<div
											className={styles.elementsRightsUsedcontract}
											data-cy='RightsUsedDashboard'
										>
											<span>Total</span>
											{data.rightsContractedDetail.totalCosts && (Number(data?.rightsContractedDetail?.totalCosts).toLocaleString('en-US', { style: 'currency', currency: data?.rightsContractedDetail?.currency }))}
										</div>
									</Grid>
									<Grid item sm={12} md={3}>
										<div className={styles.elementsRightsUsedcontract} 	data-cy='RightsUsedDashboard'
										>
											<span>Renewal Costs</span>
											{data.rightsContractedDetail.renewalCosts && (Number(data?.rightsContractedDetail?.renewalCosts).toLocaleString('en-US', { style: 'currency', currency: data?.rightsContractedDetail?.currency }))}
										</div>
									</Grid>
								</Grid>
							</div>
							<div className={styles.oddRow}>
								<Grid item container columnSpacing={3}>
									<Grid item sm={12} md={12}>
										<div
											className={styles.elementsRightsUsedcontract}
											data-cy='RightsUsedDashboard'
										>
											<span>Contract Restrictions</span>
											<div className={styles.restriction}>{data?.rightsContractedDetail?.restrictions.toUpperCase()}</div>
										</div>
									</Grid>
								</Grid>
							</div>
							{props?.for === 'ELEMENT' && <><div className={styles.oddRow}>
								<Grid item container columnSpacing={3}>
									<Grid item sm={12} md={12}>
										<h4>Permitted Deliverables Tracker</h4>
									</Grid>
								</Grid>
							</div>
							<div className={styles.oddRow}>
								<Grid item container columnSpacing={3}>
									<Grid item sm={12} md={2.4}>
										<div
											className={styles.elementsRightsUsedcontract}
											data-cy='RightsUsedDashboard'
										>
											<span>Number Of Assets</span>
											{/* <div className={styles.restriction}>{data?.rightsContractedDetail?.restrictions.toUpperCase()}</div> */}
										</div>
									</Grid>
									<Grid item sm={12} md={2.4}>
										<div
											className={styles.elementsRightsUsedcontract}
											data-cy='RightsUsedDashboard'
										>
											<span>Type</span>
											{/* <div className={styles.restriction}>{data?.rightsContractedDetail?.restrictions.toUpperCase()}</div> */}
										</div>
									</Grid>
									<Grid item sm={12} md={2.4}>
										<div
											className={styles.elementsRightsUsedcontract}
											data-cy='RightsUsedDashboard'
										>
											<span>Length</span>
											{/* <div className={styles.restriction}>{data?.rightsContractedDetail?.restrictions.toUpperCase()}</div> */}
										</div>
									</Grid>
									<Grid item sm={12} md={2.4}>
										<div
											className={styles.elementsRightsUsedcontract}
											data-cy='RightsUsedDashboard'
										>
											<span>Editing Provisions</span>
											{/* <div className={styles.restriction}>{data?.rightsContractedDetail?.restrictions.toUpperCase()}</div> */}
										</div>
									</Grid>
									<Grid item sm={12} md={2.4}>
										<div
											className={styles.elementsRightsUsedcontract}
											data-cy='RightsUsedDashboard'
										>
											<span>No of Edits</span>
											{/* <div className={styles.restriction}>{data?.rightsContractedDetail?.restrictions.toUpperCase()}</div> */}
										</div>
									</Grid>
								</Grid>
							</div>
							{rightsContractedData?.deliverables?.length > 0 && rightsContractedData?.deliverables?.map((deliverable, index) => (
								<div key={index} className={styles.oddRow}>
									<Grid item container columnSpacing={3}>
										<Grid item sm={12} md={2.4}>
											<div
												className={styles.elementsRightsUsedcontract}
												data-cy='RightsUsedDashboard'
											>
												<div className={styles.restriction}>{deliverable?.numberOfAssets}</div>
											</div>
										</Grid>	
										<Grid item sm={12} md={2.4}>
											<div
												className={styles.elementsRightsUsedcontract}
												data-cy='RightsUsedDashboard'
											>
												<div className={styles.restriction}>{deliverable?.type?.toUpperCase()}</div>
											</div>
										</Grid>
										<Grid item sm={12} md={2.4}>
											<div
												className={styles.elementsRightsUsedcontract}
												data-cy='RightsUsedDashboard'
											>
												<div className={styles.restriction}>{deliverable?.length ? getSecondsInMMSS(deliverable?.length) : ''}</div>
											</div>
										</Grid>
										<Grid item sm={12} md={2.4}>
											<div
												className={styles.elementsRightsUsedcontract}
												data-cy='RightsUsedDashboard'
											>
												<div className={styles.restriction}>{deliverable?.editingProvisions?.toUpperCase() || 'UNLIMITED'}</div>
											</div>
										</Grid>
										<Grid item sm={12} md={2.4}>
											<div
												className={styles.elementsRightsUsedcontract}
												data-cy='RightsUsedDashboard'
											>
												<div className={styles.restriction}>{deliverable?.noOfEdits || 'UNLIMITED'}</div>
											</div>
										</Grid>
									</Grid>
								</div>
							)) }</>}
						</Grid>
						<br></br>
					</Grid>
					<Grid container item sx={{ mt: 1 }}>
					</Grid></> : 
						<AddRightsAcquiredForm
							isEdit={!!rightsAcquiredForm?.selectedRightsAcquired || false}
							onClose={() => { setRightsAcquiredForm({ isOpen: false, rIdx: -1 }); } }
							fetchRightsAcquired={fetchRightsAcquired}
							initialValue={rightsAcquiredForm?.selectedRightsAcquired}
							isFromRenewalTerms={true}
							for='ELEMENT'
						/> }
				</section>}
			</section>
			<Fragment>
				<NabuDialog
					open={countryPopUp.isOpen}
					title={countryPopUp.title}
					dialogActions={[]}
					handleClose={handleAddressPopUpClose}
					maxWidth='md'
					isPersistent={true}
				>
					<>
						<ul>
							{countryPopUp?.data?.map((country: any, index) => {
								return (
									<li key={index}>{CountriesData.getCountryByCode(country)?.name}</li>
								);
							})}
						</ul>
					</>

				</NabuDialog>
			</Fragment>
			<Fragment>
				<NabuDialog
					open={territoryDialog.isOpen}
					title={'Region and Country'}
					dialogActions={[]}
					handleClose={handleTerritoryDialogClose}
					maxWidth='md'
					isPersistent={true}
				>
					<>
						<ul>
							{territoryDialog?.data?.map((territory: any, index) => {
								return (
									<li key={index}>
										{getRegionName(territory?.region)}
										<ul>
											{territory?.countries?.length > 0 && territory?.countries?.map((country: any, i) => {
												return (
													<li key={i}>{country ? CountriesData?.getCountryByCode(country)?.name : ''}</li>
												);
											})}
										</ul>
									</li>
								);
							})}
						</ul>
					</>
				</NabuDialog>
			</Fragment>
		</>
	);
};