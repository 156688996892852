import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import { IBaseContract } from '@/common/types/contract';
import { FormControl, FormHelperText, Link } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import styles from '../forms/forms.module.scss';
import { CONTRACT_REF_ID } from '@/common/constants/constants';

interface Props {
	contractForm: UseFormReturn<IBaseContract | any, any, undefined>;
	isPrint?: boolean;
}

const ContractW9BenShortForm = (props: Props) => {
	const contractForm = props?.contractForm as UseFormReturn<IBaseContract, any, undefined>;

	const replaceCharAt = (str: string, index: number, char: string) => {
		return str.substring(0, index) + char + str.substring(index + 1);
	};

	return (
		<>
			<div id={CONTRACT_REF_ID.W9_BEN_SHORT_FORM} className={styles.contractFormBlock} style={{padding:'20px'}}>
				<div className={styles.bgWhite}>
					<table
						className={styles.contractForms}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
					>
						<tbody>
							<tr>
								<td rowSpan={2} width={250}>
									Form{' '}
									<span style={{ fontSize: '40px', fontWeight: 'bold' }}>
										W-9
									</span>
									<p>
										(Rev. October 2018)<br></br>
										Department of the Treasury<br></br>
										Internal Revenue Service
									</p>
								</td>
								<td rowSpan={2} align='center' color='black'>
									<h5>
										Request for Taxpayer<br></br>
										Identification Number and Certification
									</h5>
									<p>
										<b>
											{' '}
											Go to{' '}
											<em>
												<Link>
													<b>www.irs.gov/FormW9</b>
												</Link>
											</em>{' '}
											for instructions and the latest information.
										</b>
									</p>
								</td>
								<td align='left' width={250}>
									<b>
										Give Form to the<br></br>
										requester. Do not<br></br>
										send to the IRS.
									</b>
								</td>
							</tr>
						</tbody>
					</table>

					<table
						className={styles.contractForms}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
						style={{ borderTop: '0px' }}
					>
						<tbody>
							<tr>
								<td rowSpan={5} width={250} align='center'>
									<div style={{ transform: 'rotate(270deg)' }}>
										<b className={styles.heading3}>
											Print or type<br></br>
											See <b>Specific Instructions</b> on page 3
										</b>
									</div>
								</td>
								<td colSpan={2} align='left'>
									<b>1</b> Name (as shown on your income tax return). Name is
									required on this line; do not leave this line blank.
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW9BenShortForm.nameAsPerIncomeTax'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint} />
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractW9BenShortForm
										?.nameAsPerIncomeTax?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractW9BenShortForm
													?.nameAsPerIncomeTax?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
							<tr>
								<td>
									<b>2</b> Business name/disregarded entity name, if different
									from above
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW9BenShortForm.businessName'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint} />
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractW9BenShortForm
										?.businessName?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractW9BenShortForm
													?.businessName?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
							<tr>
								<td align='left'>
									<p>
										<b>3</b> Check appropriate box for federal tax
										classification of the person whose name is entered on line
										1. Check only one of the following seven boxes.{' '}
									</p>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '6px',
											flexWrap: 'wrap',
										}}
									>
										<Controller
											control={contractForm.control}
											name='contractW9BenShortForm.isIndividual'
											defaultValue={false}
											render={({ field }) => (
												<div
													onClick={() => field.onChange(!field.value)}
													style={{ userSelect: 'none' }}
												>
													<span>Individual/sole proprietor or</span>
													<input
														{...field}
														value={field.value + ''}
														checked={field.value}
														onChange={(e) => field.onChange(e)}
														type='checkbox'
														disabled={props?.isPrint}
														style={{ width: '20px', marginTop: '2px' }}
													/>
												</div>
											)}
										/>

										<Controller
											control={contractForm.control}
											name='contractW9BenShortForm.isCCorporation'
											defaultValue={false}
											render={({ field }) => (
												<div
													onClick={() => field.onChange(!field.value)}
													style={{ userSelect: 'none' }}
												>
													<span>C Corporation</span>
													<input
														{...field}
														value={field.value + ''}
														checked={field.value}
														onChange={(e) => field.onChange(e)}
														type='checkbox'
														disabled={props?.isPrint}
														style={{ width: '20px', marginTop: '2px' }}
													/>
												</div>
											)}
										/>
										<Controller
											control={contractForm.control}
											name='contractW9BenShortForm.isSCorporation'
											defaultValue={false}
											render={({ field }) => (
												<div
													onClick={() => field.onChange(!field.value)}
													style={{ userSelect: 'none' }}
												>
													<span>S Corporation</span>
													<input
														{...field}
														value={field.value + ''}
														checked={field.value}
														onChange={(e) => field.onChange(e)}
														type='checkbox'
														disabled={props?.isPrint}
														style={{ width: '20px', marginTop: '2px' }}
													/>
												</div>
											)}
										/>
										<Controller
											control={contractForm.control}
											name='contractW9BenShortForm.isPartnership'
											defaultValue={false}
											render={({ field }) => (
												<div
													onClick={() => field.onChange(!field.value)}
													style={{ userSelect: 'none' }}
												>
													<span>Partnership</span>
													<input
														{...field}
														value={field.value + ''}
														checked={field.value}
														onChange={(e) => field.onChange(e)}
														type='checkbox'
														disabled={props?.isPrint}
														style={{ width: '20px', marginTop: '2px' }}
													/>
												</div>
											)}
										/>
										<Controller
											control={contractForm.control}
											name='contractW9BenShortForm.isTrust'
											defaultValue={false}
											render={({ field }) => (
												<div
													onClick={() => field.onChange(!field.value)}
													style={{ userSelect: 'none' }}
												>
													<span>Trust/estate</span>
													<input
														{...field}
														value={field.value + ''}
														checked={field.value}
														onChange={(e) => field.onChange(e)}
														type='checkbox'
														disabled={props?.isPrint}
														style={{ width: '20px', marginTop: '2px' }}
													/>
												</div>
											)}
										/>
										<Controller
											control={contractForm.control}
											name='contractW9BenShortForm.isSingleMemberLlc'
											defaultValue={false}
											render={({ field }) => (
												<div
													onClick={() => field.onChange(!field.value)}
													style={{ userSelect: 'none' }}
												>
													<span>single-member LLC</span>
													<input
														{...field}
														value={field.value + ''}
														checked={field.value}
														onChange={(e) => field.onChange(e)}
														type='checkbox'
														disabled={props?.isPrint}
														style={{ width: '20px', marginTop: '2px' }}
													/>
												</div>
											)}
										/>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												gap: '8px',
												flexWrap: 'wrap',
											}}
										>
											<Controller
												control={contractForm.control}
												name='contractW9BenShortForm.isLimitedLiability'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<span>
															Limited liability company. Enter the tax
															classification
															<br></br> (C=C corporation, S=S corporation,
															P=Partnership)
														</span>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => field.onChange(e)}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ width: '20px', marginTop: '2px' }}
														/>
													</div>
												)}
											/>
											<FormControl className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='contractW9BenShortForm.taxClassification'
													defaultValue=''
													render={({ field }) => (
														<>
															<NabuTextbox
																{...field}
																disabled={props?.isPrint}
															/>
														</>
													)}
												/>
											</FormControl>
											{contractForm?.formState?.errors?.contractW9BenShortForm
												?.taxClassification?.message && (
												<FormHelperText error={true}>
													{
														contractForm?.formState?.errors
															?.contractW9BenShortForm?.taxClassification
															?.message
													}
												</FormHelperText>
											)}
											<p style={{ paddingLeft: '25px' }}>
												<b>Note:</b> Check the appropriate box in the line above
												for the tax classification of the single-member owner.
												Do not check LLC if the LLC is classified as a
												single-member LLC that is disregarded from the owner
												unless the owner of the LLC is another LLC that is{' '}
												<b>not</b> disregarded from the owner for U.S. federal
												tax purposes. Otherwise, a single-member LLC that is
												disregarded from the owner should check the appropriate
												box for the tax classification of its owner.
											</p>
										</div>
										<p>
											<Controller
												control={contractForm.control}
												name='contractW9BenShortForm.isOther'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<span> Other (see instructions)</span>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => field.onChange(e)}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ width: '20px', marginTop: '2px' }}
														/>
													</div>
												)}
											/>
										</p>
									</div>
								</td>
								<td width={250}>
									<b>4</b> Exemptions (codes apply only to certain entities, not
									individuals; see instructions on page 3):<br></br>
									<br></br>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '8px',
											flexWrap: 'wrap',
										}}
									>
										<span>Exempt payee code (if any)</span>
										<FormControl className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='contractW9BenShortForm.exemptPayeeCode'
												defaultValue=''
												render={({ field }) => (
													<>
														<NabuTextbox {...field} disabled={props?.isPrint} />
													</>
												)}
											/>
										</FormControl>
										{contractForm?.formState?.errors?.contractW9BenShortForm
											?.exemptPayeeCode?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors
														?.contractW9BenShortForm?.exemptPayeeCode?.message
												}
											</FormHelperText>
										)}
									</div>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '8px',
											flexWrap: 'wrap',
										}}
									>
										<span>Exemption from FATCA reporting code (if any)</span>
										<FormControl className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='contractW9BenShortForm.exemptFatcaCode'
												defaultValue=''
												render={({ field }) => (
													<>
														<NabuTextbox {...field} disabled={props?.isPrint} />
													</>
												)}
											/>
										</FormControl>
										{contractForm?.formState?.errors?.contractW9BenShortForm
											?.exemptFatcaCode?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors
														?.contractW9BenShortForm?.exemptFatcaCode?.message
												}
											</FormHelperText>
										)}
									</div>
									<br></br>
									<p>
										<em>(Applies to accounts maintained outside the U.S.)</em>
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<b>5</b> Address (number, street, and apt. or suite no.) See
									instructions.
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW9BenShortForm.address'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint} />
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractW9BenShortForm?.address?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractW9BenShortForm
													?.address?.message
											}
										</FormHelperText>
									)}
								</td>
								<td rowSpan={2}>
									<b>7 </b>List account number(s) here (optional)
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW9BenShortForm.accountNumber'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint} />
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractW9BenShortForm
										?.accountNumber?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractW9BenShortForm
													?.accountNumber?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
							<tr>
								<td style={{ display: 'flex', alignItems: 'center'}}>
									<b>6</b> &nbsp; City, state, and Zip Code
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW9BenShortForm.cityStateZipCode'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint} />
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractW9BenShortForm
										?.cityStateZipCode?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractW9BenShortForm
													?.cityStateZipCode?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
						</tbody>
					</table>

					<br></br>
					<table cellPadding={0} cellSpacing={0} width={'100%'}>
						<tr>
							<td colSpan={2} style={{ padding: '0' }}>
								<div className={styles.partSection}>
									<b>Part I</b> Taxpayer Identification Number (TIN)
								</div>
							</td>
						</tr>
						<tr>
							<td style={{ padding: '0', paddingRight: '25px' }}>
								<p>
									Enter your TIN in the appropriate box. The TIN provided must
									match the name given on line 1 to avoid backup withholding.
									For individuals, this is generally your social security number
									(SSN). However, for a resident alien, sole proprietor, or
									disregarded entity, see the instructions for Part I, later.
									For other entities, it is your employer identification number
									(EIN). If you do not have a number, see{' '}
									<em>How to get a TIN, later.</em>
								</p>
								<br></br>
								<p>
									<b>Note:</b> If the account is in more than one name, see the
									instructions for line 1. Also see What Name and Number To Give
									the Requester for guidelines on whose number to enter.
								</p>
							</td>
							<td width={450} valign='top'>
								<div>
									<b>Social security number</b>
									<div
										style={{
											display: 'flex',
											alignItems: 'center',
											gap: '8px',
											flexWrap: 'wrap',
										}}
									>
										<Controller
											control={contractForm.control}
											name={'contractW9BenShortForm.ssn'}
											defaultValue=''
											render={({ field }) => {
												const fieldValue = (field?.value?.trim() || '').padEnd(
													9,
													''
												);
												return (
													<>
														<input
															type='text'
															maxLength={1}
															disabled={props?.isPrint}
															className={styles.numberinput}
															value={fieldValue?.at(0) || ''}
															onChange={(e) => {
																const value = e?.target?.value || '';
																field.onChange(
																	replaceCharAt(fieldValue, 0, value)
																);
															}}
														/>
														<input
															type='text'
															maxLength={1}
															disabled={props?.isPrint}
															className={styles.numberinput}
															value={fieldValue?.at(1) || ''}
															onChange={(e) => {
																const value = e?.target?.value || '';
																field.onChange(
																	replaceCharAt(fieldValue, 1, value)
																);
															}}
														/>
														<input
															type='text'
															maxLength={1}
															disabled={props?.isPrint}
															className={styles.numberinput}
															value={fieldValue?.at(2) || ''}
															onChange={(e) => {
																const value = e?.target?.value || '';
																field.onChange(
																	replaceCharAt(fieldValue, 2, value)
																);
															}}
														/>
														<div>-</div>
														<input
															type='text'
															maxLength={1}
															disabled={props?.isPrint}
															className={styles.numberinput}
															value={fieldValue?.at(3) || ''}
															onChange={(e) => {
																const value = e?.target?.value || '';
																field.onChange(
																	replaceCharAt(fieldValue, 3, value)
																);
															}}
														/>
														<input
															type='text'
															maxLength={1}
															disabled={props?.isPrint}
															className={styles.numberinput}
															value={fieldValue?.at(4) || ''}
															onChange={(e) => {
																const value = e?.target?.value || '';
																field.onChange(
																	replaceCharAt(fieldValue, 4, value)
																);
															}}
														/>
														<div>-</div>
														<input
															type='text'
															maxLength={1}
															disabled={props?.isPrint}
															className={styles.numberinput}
															value={fieldValue?.at(5) || ''}
															onChange={(e) => {
																const value = e?.target?.value || '';
																field.onChange(
																	replaceCharAt(fieldValue, 5, value)
																);
															}}
														/>
														<input
															type='text'
															maxLength={1}
															disabled={props?.isPrint}
															className={styles.numberinput}
															value={fieldValue?.at(6) || ''}
															onChange={(e) => {
																const value = e?.target?.value || '';
																field.onChange(
																	replaceCharAt(fieldValue, 6, value)
																);
															}}
														/>
														<input
															type='text'
															maxLength={1}
															disabled={props?.isPrint}
															className={styles.numberinput}
															value={fieldValue?.at(7) || ''}
															onChange={(e) => {
																const value = e?.target?.value || '';
																field.onChange(
																	replaceCharAt(fieldValue, 7, value)
																);
															}}
														/>
														<input
															type='text'
															maxLength={1}
															disabled={props?.isPrint}
															className={styles.numberinput}
															value={fieldValue?.at(8) || ''}
															onChange={(e) => {
																const value = e?.target?.value || '';
																field.onChange(
																	replaceCharAt(fieldValue, 8, value)
																);
															}}
														/>
													</>
												);
											}}
										/>
									</div>
								</div>
								<br></br>
								<b>or</b>
								<br></br>
								<b>Employer identification number</b>
								<div
									style={{
										display: 'flex',
										alignItems: 'center',
										gap: '8px',
										flexWrap: 'wrap',
									}}
								>
									<Controller
										control={contractForm.control}
										name={'contractW9BenShortForm.employerId'}
										defaultValue=''
										render={({ field }) => {
											const fieldValue = (field?.value?.trim() || '').padEnd(
												9,
												''
											);
											return (
												<>
													<input
														type='text'
														maxLength={1}
														disabled={props?.isPrint}
														className={styles.numberinput}
														value={fieldValue?.at(0) || ''}
														onChange={(e) => {
															const value = e?.target?.value || '';
															field.onChange(
																replaceCharAt(fieldValue, 0, value)
															);
														}}
													/>
													<input
														type='text'
														maxLength={1}
														disabled={props?.isPrint}
														className={styles.numberinput}
														value={fieldValue?.at(1) || ''}
														onChange={(e) => {
															const value = e?.target?.value || '';
															field.onChange(
																replaceCharAt(fieldValue, 1, value)
															);
														}}
													/>
													<div>-</div>
													<input
														type='text'
														maxLength={1}
														disabled={props?.isPrint}
														className={styles.numberinput}
														value={fieldValue?.at(2) || ''}
														onChange={(e) => {
															const value = e?.target?.value || '';
															field.onChange(
																replaceCharAt(fieldValue, 2, value)
															);
														}}
													/>
													<input
														type='text'
														maxLength={1}
														disabled={props?.isPrint}
														className={styles.numberinput}
														value={fieldValue?.at(3) || ''}
														onChange={(e) => {
															const value = e?.target?.value || '';
															field.onChange(
																replaceCharAt(fieldValue, 3, value)
															);
														}}
													/>
													<input
														type='text'
														maxLength={1}
														disabled={props?.isPrint}
														className={styles.numberinput}
														value={fieldValue?.at(4) || ''}
														onChange={(e) => {
															const value = e?.target?.value || '';
															field.onChange(
																replaceCharAt(fieldValue, 4, value)
															);
														}}
													/>
													<input
														type='text'
														maxLength={1}
														disabled={props?.isPrint}
														className={styles.numberinput}
														value={fieldValue?.at(5) || ''}
														onChange={(e) => {
															const value = e?.target?.value || '';
															field.onChange(
																replaceCharAt(fieldValue, 5, value)
															);
														}}
													/>
													<input
														type='text'
														maxLength={1}
														disabled={props?.isPrint}
														className={styles.numberinput}
														value={fieldValue?.at(6) || ''}
														onChange={(e) => {
															const value = e?.target?.value || '';
															field.onChange(
																replaceCharAt(fieldValue, 6, value)
															);
														}}
													/>
													<input
														type='text'
														maxLength={1}
														disabled={props?.isPrint}
														className={styles.numberinput}
														value={fieldValue?.at(7) || ''}
														onChange={(e) => {
															const value = e?.target?.value || '';
															field.onChange(
																replaceCharAt(fieldValue, 7, value)
															);
														}}
													/>
													<input
														type='text'
														maxLength={1}
														disabled={props?.isPrint}
														className={styles.numberinput}
														value={fieldValue?.at(8) || ''}
														onChange={(e) => {
															const value = e?.target?.value || '';
															field.onChange(
																replaceCharAt(fieldValue, 8, value)
															);
														}}
													/>
												</>
											);
										}}
									/>
								</div>
							</td>
						</tr>
					</table>

					<table cellPadding={0} cellSpacing={0} width={'100%'}>
						<tr>
							<td style={{ padding: '0' }}>
								<div className={styles.partSection}>
									<b>Part II </b> &nbsp;Certification
								</div>
							</td>
						</tr>
						<tr>
							<td style={{ padding: '0' }}>
								<p>
									<b>Under penalties of perjury, I certify that:</b>
								</p>
								<ol>
									<li>
										The number shown on this form is my correct taxpayer
										identification number (or I am waiting for a number to be
										issued to me); and
									</li>
									<li>
										I am not subject to backup withholding because: (a) I am
										exempt from backup withholding, or (b) I have not been
										notified by the Internal Revenue Service (IRS) that I am
										subject to backup withholding as a result of a failure to
										report all interest or dividends, or (c) the IRS has
										notified me that I am no longer subject to backup
										withholding; and
									</li>
									<li>
										I am a U.S. citizen or other U.S. person (defined below);
										and
									</li>
									<li>
										The FATCA code(s) entered on this form (if any) indicating
										that I am exempt from FATCA reporting is correct.
									</li>
								</ol>
								<p>
									<b>Certification</b> instructions. You must cross out item 2
									above if you have been notified by the IRS that you are
									currently subject to backup withholding because you have
									failed to report all interest and dividends on your tax
									return. For real estate transactions, item 2 does not apply.
									For mortgage interest paid, acquisition or abandonment of
									secured property, cancellation of debt, contributions to an
									individual retirement arrangement (IRA), and generally,
									payments other than interest and dividends, you are not
									required to sign the certification, but you must provide your
									correct TIN. See the instructions for Part II, later
								</p>
							</td>
						</tr>
					</table>
					<br></br>
					<table
						width={'100%'}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						className={styles.contractFormsInner}
					>
						<tr>
							<td width={80}>
								<b>Sign Here</b>
							</td>
							<td width={190}>Signature of U.S. person ▶</td>
							<td>
								<FormControl className={styles.tableForms} fullWidth>
									<NabuTextbox disabled={true}/>{' '}
								</FormControl>
							</td>
							<td width={50}>Date</td>
							<td>
								<FormControl className={styles.tableForms} fullWidth>
									<NabuTextbox disabled={true}/>{' '}
								</FormControl>
							</td>
						</tr>
					</table>					
				</div>
			</div>
			<div id={CONTRACT_REF_ID.W9_BEN_SHORT_FORM_PART_2} className={styles.contractFormBlock} style={{padding:'20px'}}>
				<div className={styles.bgWhite}>
					<table cellPadding={0} cellSpacing={0} width={'100%'}>
						<tr>
							<td valign='top' width={'50%'}>
								<h5>General Instructions</h5>
								<p>
									Section references are to the Internal Revenue Code unless
									otherwise noted.
								</p>
								<br></br>
								<p>
									Future developments. For the latest information about
									developments related to Form W-9 and its instructions, such as
									legislation enacted after they were published, go to{' '}
									<Link>
										<em>www.irs.gov/FormW9</em>
									</Link>
								</p>
								<br></br>
								<h5>Purpose of Form</h5>
								<p>
									An individual or entity (Form W-9 requester) who is required
									to file an information return with the IRS must obtain your
									correct taxpayer identification number (TIN) which may be your
									social security number (SSN), individual taxpayer
									identification number (TIN), adoption taxpayer identification
									number (ATIN), or employer identification number (EIN), to
									report on an information return the amount paid to you, or
									other amount reportable on an information return. Examples of
									information returns include, but are not limited to, the
									following.
								</p>
							</td>
							<td valign='top' width={'50%'}>
								<br></br>
								<ul>
									<li>Form 1099-INT (interest earned or paid)</li>
									<li>
										Form 1099-DIV (dividends, including those from stocks or
										mutual funds)
									</li>
									<li>
										Form 1099-MISC (various types of income, prizes, awards, or
										gross proceeds)
									</li>
									<li>
										Form 1099-B (stock or mutual fund sales and certain other
										transactions by brokers)
									</li>
									<li>Form 1099-S (proceeds from real estate transactions)</li>
									<li>
										Form 1099-K (merchant card and third party network
										transactions)
									</li>
									<li>
										Form 1098 (home mortgage interest), 1098-E (student loan
										interest), 1098-T (tuition)
									</li>
									<li>Form 1099-C (canceled debt)</li>
									<li>
										Form 1099-A (acquisition or abandonment of secured property)
										Use Form W-9 only if you are a U.S. person (including a
										resident alien), to provide your correct TIN.
									</li>
									<li>
										<em>
											If you do not return Form W-9 to the requester with a TIN,
											you might be subject to backup withholding. See What is
											backup withholding, later.
										</em>
									</li>
								</ul>
							</td>
						</tr>
					</table>
					<hr></hr>

					<table width={'100%'}>
						<tr>
							<td></td>
							<td align='center'>Cat. No. 10231X</td>
							<td align='right'>
								Form <b>W-9</b> (Rev. 10-2018)
							</td>
						</tr>
					</table>
				</div>
			</div>
		</>
	);
};

export default ContractW9BenShortForm;
