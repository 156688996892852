import NabuButton from '@/common/components/atoms/button/button';
import NabuMultiselect from '@/common/components/atoms/multiselect/multiselect';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import NabuDialog, { IDialogAction } from '@/common/components/molecules/dialog/dialog';
import { CONTRACT_FORM, Contract_Note_Type, ContractStatus, ContractStatusOptions, SagAftraAudioCommercialsPerformerTypeOptions } from '@/common/constants/constants';
import { StatusCodes } from '@/common/enums/StatusCodes';
import useNotification from '@/common/hooks/useNotification';
import { IFormState } from '@/common/types/asset';
import { ICompanyAddress, ICompanyRecord } from '@/common/types/company';
import { IBaseContract, ICommercialDetail, IContractMSAForm, IContractNonUnionForm, IContractRecord, IContractSagAftraAudioComHTForm, IContractSagAftraCOEDForm, IContractSagAftraSdAudComForm, IContractSagAftraSdAudTeamComForm, IContractSagAftraSdEmpCtrComPriPerY22Form, IContractSagAftraSdEmpCtrIndEduFilmVideoPgmForm, IContractSagAftraSdExtComEx2Y22Form, IContractSagAftraSdExtComExTeamForm, IContractSagAftraTvExtraHTForm, IContractSagAftraTvPrincipalForm, IContractSdSagAftra22Form, IContractTeamSagAftraSdEmpCtrIndEduForm } from '@/common/types/contract';
import {
	addressShortner,
	dataUriToFile,
	displayAddress,
	displayDate,
	generateUniquePassword,
	getCompanyDisplayName,
	getEmailContent,
	getEmailDetails,
	getLocaleDate,
	getNestedContractDetail,
	getNotificationText,
	getPersonFullName,
} from '@/common/utils/utils';
import TextEditor from '@/modules/dashboard/components/text-editor';
import { sendEmail } from '@/services/alertsAndNotificationsService';
import { uploadFile } from '@/services/common';
import { getBlobURL } from '@/services/company';
import { editContractStatus, updateContractPdfBlob } from '@/services/contract';
import store, { IStore } from '@/store';
import { setLoader } from '@/store/app/reducer';
import { updateContractFormState } from '@/store/contract/reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { Cancel, CopyAll, Download, Edit, ExpandMore, ListAlt, Save } from '@mui/icons-material';
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import { Accordion, AccordionDetails, AccordionSummary, Box, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, Tooltip, Typography } from '@mui/material';
import * as kendoDrawing from '@progress/kendo-drawing';
import { PDFExport } from '@progress/kendo-react-pdf';
import { HttpStatusCode } from 'axios';
import { useEffect, useRef, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import * as schema from 'yup';
import { ContractStatusFrom } from '../../addContractForms/contarctFormSchema';
import MsaCreatorAgreement from '../../addContractForms/msaForms/msaCreatorAgreement';
import MsaInfluenceAgreement from '../../addContractForms/msaForms/msaInfluenceAgreement';
import MsaTemplateThree from '../../addContractForms/msaForms/msaTemplateThree';
import NonUnionPerAgrmntOffCamPri from '../../addContractForms/nonUnionForms/nonUnionPerAgrmntOffCamPri';
import NonUnionPerAgrmntOnCamPri from '../../addContractForms/nonUnionForms/nonUnionPerAgrmntOnCamPri';
import NonUnionPerAgrmntOnCamPriTalentAgrmnt from '../../addContractForms/nonUnionForms/nonUnionPerformerAgreementOnCameraPrincipal';
import NonUnionTalentAgreement from '../../addContractForms/nonUnionForms/nonUnionTalentAgreement';
import NonUnionTalentAgreementExtra from '../../addContractForms/nonUnionForms/nonUnionTalentAgreementExtra';
import NonUnionTalentAgreementOnCameraPrincipal from '../../addContractForms/nonUnionForms/nonUnionTalentAgreementOnCameraPrincipal';
import NonUnionTalentAgreementVoiceOver from '../../addContractForms/nonUnionForms/nonUnionTalentAgreementVoiceOver';
import NonUnionTalentPrintModel from '../../addContractForms/nonUnionForms/nonUnionTalentPrintModel';
import SagAftraCOED from '../../addContractForms/unionForms/sagAftraCOED';
import SagAftraForAudioCommercialsHT from '../../addContractForms/unionForms/sagAftraForAudioCommercials';
import SagAftraSdAcsAudComY22 from '../../addContractForms/unionForms/sagAftraSdAcsAudComY22';
import SagAftraSdAudCom from '../../addContractForms/unionForms/sagAftraSdAudCom';
import SagAftraSdEmpCtrComPriPerY22 from '../../addContractForms/unionForms/sagAftraSdEmpCtrComPriPerY22';
import SagAftraSdEmpCtrIndEduFilmVideoPgm from '../../addContractForms/unionForms/sagAftraSdEmpCtrIndEduFilmVideoPgm';
import SagAftraSdExtComEx2Y22 from '../../addContractForms/unionForms/sagAftraSdExtComEx2Y22';
import SagAftraTvExtraHT from '../../addContractForms/unionForms/sagAftraTvExtraHT';
import SagAftraTvPrincipal from '../../addContractForms/unionForms/sagAftraTvPrincipal';
import StandardSagAftraEmployment2022 from '../../addContractForms/unionForms/standardSagAftraEmployment2022';
import TeamACSAudioContract from '../../addContractForms/unionForms/teamACSAudioContract';
import TeamExtraContract from '../../addContractForms/unionForms/teamExtraContract';
import styles from './../contractView/contractview.module.scss';
import TeamSagAftraSdEmpCtrIndEduFilmVideoPgm from '../../addContractForms/unionForms/teamIndustrialContract';
import TextWrap from '@/common/components/atoms/textWrap/textWrap';
import useBackNavigation from '@/common/hooks/useBackNavigation';
import useContractMutation from '../../addContractForms/contractMutationWrapper/useContractMutation';
import TalentDetailsForm from '../../addContractForms/talentDetailsForm';
import {
	default as stylesBlueSection,
} from './contractProfileStyle.module.scss';
import ContractCompensation from './components/compensation/contractCompensation';
import ContractNoteForm from './components/contractNoteForm';
import ContractUseRightTerritoryAndRenewal from './components/contractUseRightTerritoryAndRenewal';

interface Props {
	contractData?: IContractRecord;
	onSave?: VoidFunction;
	onEditClick?: (isEdit: boolean) => void;
	fetchContractDetail: CallableFunction;
}

export interface SendEmailModel {
	toEmail: string;
	content?: string;
	reason?: string;
	govForms?: string[];
	isGovFormNeeded?: boolean | undefined;
}

export const sendEmailSchema = schema.object({
	toEmail: schema.string().matches(
		/^$|^([a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*([,;]\s*)?)+$/,
		'Invalid value'
	).required('Email field is required'),
	reason: schema.string().required('Reason field is required'),
	govForms: schema.array().test('requiredIfLocation', 'Tattoo image is required if location or description is provided', function () {
		const isGovFormNeeded: any = this.parent.isGovFormNeeded;
		if (isGovFormNeeded?.toString() === 'false') {
			return false;
		}
		return true;
	})
});

const ContractProfileSection = (props: Props) => {
	const notification = useNotification();
	const { contractData } = props;
	const { contractContext, appContext } = useSelector((store: IStore) => store);

	const [editorContent, setEditorContent] = useState('');
	const [isEmailDialogOpen, setEmailDialogOpen] = useState<boolean>(false);
	const [isStatusEditDialogOpen, setStatusEditDialogOpen] = useState<boolean>(false);
	const [cancelContractDialogOpen, setCancelContractDialogOpen] = useState<boolean>(false);
	const [updatedBlobName, setUpdatedBlobName] = useState<string>();

	const location = useLocation();
	const params = new URLSearchParams(location.search);

	const governmentFormOptions = [
		{ label: 'W4 - short version', value: 'W4' },
		{ label: 'W8 Ben short version', value: 'W8' },
		{ label: 'W9 Ben short version', value: 'W9' },
		{ label: 'Minor Trust Form', value: 'MinorTrustInformationForm' },
		{ label: 'NY wage theft prevention form', value: 'NYWageTheftPreventionForm' },
		{ label: 'CA wage theft prevention form', value: 'CAWageTheftPreventionForm' }
	];

	const sendEmailForm = useForm<SendEmailModel>({
		resolver: yupResolver(sendEmailSchema),
		mode: 'onChange',
	});

	const contractForm = useForm<IBaseContract>({
		defaultValues: {status: contractData?.status},
		resolver: yupResolver(ContractStatusFrom),
	});
	

	const reportTemplateRef = useRef<any>(null);
	const pdfExportComponent = useRef<any>(null);
	const [contractFormState, setContractFormState] =
		useState<IFormState<IContractSdSagAftra22Form>>();

	const handleGeneratePdf = async (uploadAndReturnBlobName?: boolean) => {
		if (pdfExportComponent.current) {
			const element = reportTemplateRef.current.querySelector('#contract-form-block-packet');
			element.classList.add('download-pdf');
			
			const pdfData = await drawDomOnPdf(reportTemplateRef.current);
			if (uploadAndReturnBlobName) {
				const pdfFile = await uploadPdf(pdfData);
				element.classList.remove('download-pdf');
				return pdfFile;
			} else {
				await downloadPdf(pdfData);
				element.classList.remove('download-pdf');
			}
		}
	};
	
	const drawDomOnPdf = async (element: HTMLElement) => {
		const group = await kendoDrawing.drawDOM(element, {
			paperSize: 'Letter',
			scale: 0.5,
		});
		const pdfData = await kendoDrawing.exportPDF(group, {
			paperSize: 'Letter',
			margin: '2pt',
		});
		return pdfData;
	};
	
	const uploadPdf = async (pdfData: string) => {
		const pdfFile = dataUriToFile(pdfData, 'contract.pdf');
		const res = await uploadFile(pdfFile);
		if (res) {
			if (res.status === HttpStatusCode.PayloadTooLarge) {
				notification.notify({
					message: 'File is too large.',
					type: 'error',
				});
			} else if (res.status !== StatusCodes.OK) {
				notification.notify({
					message: 'Error occurred while uploading the file, try again',
					type: 'error',
				});
			}
		}
		return res?.data;
	};
	
	const downloadPdf = async (pdfData: string) => {
		// Convert the data URI to a blob
		const byteString = atob(pdfData.split(',')[1]);
		const mimeString = pdfData.split(',')[0].split(':')[1].split(';')[0];
		const ab = new ArrayBuffer(byteString.length);
		const ia = new Uint8Array(ab);
		for (let i = 0; i < byteString.length; i++) {
			ia[i] = byteString.charCodeAt(i);
		}
		const blob = new Blob([ab], { type: mimeString });
		const url = URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.href = url;
		a.download = `${contractData?.contractDisplayName}-contract.pdf`;
		document.body.appendChild(a);
		a.click();
		URL.revokeObjectURL(url);
		document.body.removeChild(a);
	};

	const handleEdit = () => {
		setContractFormState({
			isOpen: true,
			selectedData: getContractStandardSagAftraEmployment2022FormData(),
		});
		props?.onEditClick && props?.onEditClick(true);
	};

	const handleStatusEdit = () => {
		setStatusEditDialogOpen(true);
	};
	
	const getContractStandardSagAftraEmployment2022FormData = (): IContractSdSagAftra22Form | undefined => {
		if (!contractData) return undefined;

		let commercials: ICommercialDetail[] =
			contractData?.contractSagAftraSdEmpCtrPriComEx1Y22?.commercials?.map((asset) => ({
				spots: asset?.commercialRelationData?.spots || '',
				tags: asset?.commercialRelationData?.tags || '',
				demos: asset?.commercialRelationData?.demos || '',
				assetId: {
					label: asset?.title || '',
					value: asset?.id || '',
				},
			})) || [];
		
		const sagData = contractData?.contractSagAftraSdEmpCtrPriComEx1Y22;
		
		// const returnToEmail = sagData?.returnToPersonData?.emails?.find(e => e?.id === sagData?.returnToEmail);

		commercials = commercials?.sort((a, b) => 
			(a?.assetId?.label || '').localeCompare(b?.assetId?.label || '')
		);
		const data: IContractSdSagAftra22Form = {
			...(contractData || {}),
			...(sagData || {}) as IContractSdSagAftra22Form,
			commercials,
			isJPCAuthorizer: sagData?.isJPCAuthorizer || false,
			workDays: sagData?.workDays || [],
			contractW4Form: contractData?.contractW4Form,
			contractW8BenShortForm: contractData?.contractW8BenShortForm,
			contractW9BenShortForm: contractData?.contractW9BenShortForm,
			contractMinorTrustForm: contractData?.contractMinorTrustForm,
			payrollVendorAddressId: {
				label: displayAddress(contractData?.payrollVendorAddressData),
				value: contractData?.payrollVendorAddressData?.id || contractData?.payrollVendorAddressId || '',
			},
			// returnToEmail: {
			// 	label: returnToEmail?.emailId ? `${getPersonFullName(sagData?.returnToPersonData?.personName)} (${returnToEmail?.emailId || ''})` : '',
			// 	value: sagData?.returnToEmail || '',
			// },
			adAgencyId: {
				label: getCompanyDisplayName(contractData?.adAgencyData?.companyNames),
				value: contractData?.adAgencyData?.id || '',
			},
			adAgencyAddressId: {
				label: displayAddress(sagData?.adAgencyAddressData),
				value: sagData?.adAgencyAddressData?.id || '',
			},
			performerAddressId: {
				label: addressShortner(sagData?.performerAddressData),
				value: sagData?.performerAddressData?.id || '',
			},
			performerAdAgencyId: {
				label: getCompanyDisplayName(
					sagData?.performerAdAgency?.companyNames
				),
				value: sagData?.performerAdAgency?.id || '',
			},
			performerAdAgencyAddressId: {
				label: addressShortner(sagData?.performerAdAgencyAddressData),
				value: sagData?.performerAdAgencyAddressData?.id || '',
			},
			performerAdAgencyPhoneId: sagData?.performerAdAgencyPhoneId || '',
			advertiserId: {
				label: getCompanyDisplayName(contractData?.advertiser?.companyNames),
				value: contractData?.advertiser?.id || '',
			},
			performerId: {
				label: getPersonFullName(contractData?.performer?.personName),
				value: contractData?.performer?.id || '',
			},
			productId: sagData?.product?.map((product: any) => {
				return { label: product?.productName || '',
					value: product?.id || ''
				};
			}),
			producerCompanyId: {
				label:
					getCompanyDisplayName(sagData?.producer?.companyNames) || '',
				value: sagData?.producer?.id || '',
			},
			producerAddressId: {
				label: addressShortner(sagData?.producerAddressData),
				value: sagData?.producerAddressData?.id || '',
			},
			payrollVendorId: {
				label:
					getCompanyDisplayName(contractData?.payrollVendor?.companyNames) ||
					'',
				value: contractData?.payrollVendor?.id || '',
			},
		};

		return data;
	};

	const getSagAftraSdEmpCtrComPriPerY22FormData = (): IContractSagAftraSdEmpCtrComPriPerY22Form | undefined => {
		if (!contractData) return undefined;

		let commercials: ICommercialDetail[] =
			contractData?.contractSagAftraSdEmpCtrComPriPerY22?.commercials?.map((asset) => ({
				spots: asset?.commercialRelationData?.spots || '',
				tags: asset?.commercialRelationData?.tags || '',
				demos: asset?.commercialRelationData?.demos || '',
				assetId: {
					label: asset?.title || '',
					value: asset?.id || '',
				},
			})) || [];

		commercials = commercials?.sort((a, b) => 
			(a?.assetId?.label || '').localeCompare(b?.assetId?.label || '')
		);
		
		const sagData = contractData?.contractSagAftraSdEmpCtrComPriPerY22;
		const data: IContractSagAftraSdEmpCtrComPriPerY22Form = {
			...(contractData || {}),
			...(sagData || {}) as IContractSagAftraSdEmpCtrComPriPerY22Form,
			commercials,
			isJPCAuthorizer: sagData?.isJPCAuthorizer || false,
			workDays: sagData?.workDays || [],
			contractW4Form: contractData?.contractW4Form,
			contractW8BenShortForm: contractData?.contractW8BenShortForm,
			contractW9BenShortForm: contractData?.contractW9BenShortForm,
			contractMinorTrustForm: contractData?.contractMinorTrustForm,
			payrollVendorAddressId: {
				label: displayAddress(contractData?.payrollVendorAddressData),
				value: contractData?.payrollVendorAddressData?.id || contractData?.payrollVendorAddressId || '',
			},
			adAgencyId: {
				label: getCompanyDisplayName(contractData?.adAgencyData?.companyNames),
				value: contractData?.adAgencyData?.id || '',
			},
			adAgencyAddressId: {
				label: displayAddress(sagData?.adAgencyAddressData),
				value: sagData?.adAgencyAddressData?.id || '',
			},
			performerId: {
				label: getPersonFullName(contractData?.performer?.personName),
				value: contractData?.performer?.id || '',
			},
			productId: sagData?.product?.map((product: any) => {
				return { label: product?.productName || '',
					value: product?.id || ''
				};
			}),
			producerCompanyId: {
				label:
					getCompanyDisplayName(sagData?.producer?.companyNames) || '',
				value: sagData?.producer?.id || '',
			},
			producerAddressId: {
				label: displayAddress(sagData?.producerAddressData),
				value: sagData?.producerAddressData?.id || '',
			},
			payrollVendorId: {
				label:
					getCompanyDisplayName(contractData?.payrollVendor?.companyNames) ||
					'',
				value: contractData?.payrollVendor?.id || '',
			},
			advertiserId: {
				label: getCompanyDisplayName(contractData?.advertiser?.companyNames),
				value: contractData?.advertiser?.id || '',
			}
		};

		return data;
	};

	const getSagAftraSdEmpCtrIndEduFilmVideoPgmFormData = (): IContractSagAftraSdEmpCtrIndEduFilmVideoPgmForm | undefined => {
		if (!contractData) return undefined;
		
		const sagData = contractData?.contractSagAftraSdEmpCtrIndEduFilmVideoPgm;

		// const returnToEmail = sagData?.returnToPersonData?.emails?.find(e => e?.id === sagData?.returnToEmail);

		const data: IContractSagAftraSdEmpCtrIndEduFilmVideoPgmForm = {
			...(contractData || {}),
			...(sagData || {}) as IContractSagAftraSdEmpCtrIndEduFilmVideoPgmForm,
			isJPCAuthorizer: sagData?.isJPCAuthorizer || false,
			workDays: sagData?.workDays || [],
			contractW4Form: contractData?.contractW4Form,
			contractW8BenShortForm: contractData?.contractW8BenShortForm,
			contractW9BenShortForm: contractData?.contractW9BenShortForm,
			contractMinorTrustForm: contractData?.contractMinorTrustForm,
			payrollVendorAddressId: {
				label: displayAddress(contractData?.payrollVendorAddressData),
				value: contractData?.payrollVendorAddressData?.id || contractData?.payrollVendorAddressId || '',
			},
			adAgencyId: {
				label: getCompanyDisplayName(contractData?.adAgencyData?.companyNames),
				value: contractData?.adAgencyData?.id || '',
			},
			agentAddressId: {
				label: addressShortner(sagData?.adAgencyAddressData),
				value: sagData?.adAgencyAddressData?.id || '',
			},
			performerId: {
				label: getPersonFullName(contractData?.performer?.personName),
				value: contractData?.performer?.id || '',
			},
			performerAddressId: {
				label: addressShortner(sagData?.performerAddressData),
				value: sagData?.performerAddressData?.id || '',
			},
			producerCompanyId: {
				label:
					getCompanyDisplayName(sagData?.producer?.companyNames) || '',
				value: sagData?.producer?.id || '',
			},
			producerAddressId: {
				label: displayAddress(sagData?.producerAddressData),
				value: sagData?.producerAddressData?.id || '',
			},
			payrollVendorId: {
				label:
					getCompanyDisplayName(contractData?.payrollVendor?.companyNames) ||
					'',
				value: contractData?.payrollVendor?.id || '',
			},
			advertiserId: {
				label: getCompanyDisplayName(contractData?.advertiser?.companyNames),
				value: contractData?.advertiser?.id || '',
			},
			agentId: {
				label: getCompanyDisplayName(sagData?.talentAgency?.companyNames),
				value: sagData?.talentAgency?.id || '',
			},
			// returnToEmail: {
			// 	label: returnToEmail?.emailId ? `${getPersonFullName(sagData?.returnToPersonData?.personName)} (${returnToEmail?.emailId || ''})` : '',
			// 	value: sagData?.returnToEmail || '',
			// },
		};

		return data;
	};

	const getSagAftraCOEDFormData = (): IContractSagAftraCOEDForm | undefined => {
		if (!contractData) return undefined;
		
		const sagData = contractData?.contractSagAftraCOED;

		const returnToEmail = sagData?.returnToPersonData?.emails?.find(e => e?.id === sagData?.returnToEmail);

		const data: IContractSagAftraCOEDForm = {
			...(contractData || {}),
			...(sagData || {}) as IContractSagAftraCOEDForm,
			isJPCAuthorizer: sagData?.isJPCAuthorizer || false,
			contractW4Form: contractData?.contractW4Form,
			contractW8BenShortForm: contractData?.contractW8BenShortForm,
			contractW9BenShortForm: contractData?.contractW9BenShortForm,
			contractMinorTrustForm: contractData?.contractMinorTrustForm,
			payrollVendorAddressId: {
				label: displayAddress(contractData?.payrollVendorAddressData),
				value: contractData?.payrollVendorAddressData?.id || contractData?.payrollVendorAddressId || '',
			},
			adAgencyId: {
				label: getCompanyDisplayName(contractData?.adAgencyData?.companyNames),
				value: contractData?.adAgencyData?.id || '',
			},
			agentAddressId: {
				label: addressShortner(sagData?.adAgencyAddressData),
				value: sagData?.adAgencyAddressData?.id || '',
			},
			performerId: {
				label: getPersonFullName(contractData?.performer?.personName),
				value: contractData?.performer?.id || '',
			},
			performerAddressId: {
				label: addressShortner(sagData?.performerAddressData),
				value: sagData?.performerAddressData?.id || '',
			},
			producerCompanyId: {
				label:
					getCompanyDisplayName(sagData?.producer?.companyNames) || '',
				value: sagData?.producer?.id || '',
			},
			producerAddressId: {
				label: addressShortner(sagData?.producerAddressData),
				value: sagData?.producerAddressData?.id || '',
			},
			payrollVendorId: {
				label:
					getCompanyDisplayName(contractData?.payrollVendor?.companyNames) ||
					'',
				value: contractData?.payrollVendor?.id || '',
			},
			advertiserId: {
				label: getCompanyDisplayName(contractData?.advertiser?.companyNames),
				value: contractData?.advertiser?.id || '',
			},
			talentAgencyStaffId: {
				label: getPersonFullName(sagData?.talentAgencyStaffData?.personName),
				value: sagData?.talentAgencyStaffData?.id || '',
			},
			agentId:{
				label: getCompanyDisplayName(sagData?.talentAgency?.companyNames),
				value: sagData?.talentAgency?.id || '',
			},
			returnToEmail: {
				label: returnToEmail?.emailId ? `${getPersonFullName(sagData?.returnToPersonData?.personName)} (${returnToEmail?.emailId || ''})` : '',
				value: sagData?.returnToEmail || '',
			},
		};

		return data;
	};

	const getTeamSagAftraCOEDFormData = (): IContractTeamSagAftraSdEmpCtrIndEduForm | undefined => {
		if (!contractData) return undefined;
		
		const sagData = contractData?.contractTeamSagAftraCOED;

		// const returnToEmail = sagData?.returnToPersonData?.emails?.find(e => e?.id === sagData?.returnToEmail);

		const data: IContractTeamSagAftraSdEmpCtrIndEduForm = {
			...(contractData || {}),
			...(sagData || {}) as IContractTeamSagAftraSdEmpCtrIndEduForm,
			isJPCAuthorizer: sagData?.isJPCAuthorizer || false,
			contractW4Form: contractData?.contractW4Form,
			contractW8BenShortForm: contractData?.contractW8BenShortForm,
			contractW9BenShortForm: contractData?.contractW9BenShortForm,
			contractMinorTrustForm: contractData?.contractMinorTrustForm,
			payrollVendorAddressId: {
				label: displayAddress(contractData?.payrollVendorAddressData),
				value: contractData?.payrollVendorAddressData?.id || contractData?.payrollVendorAddressId || '',
			},
			adAgencyId: {
				label: getCompanyDisplayName(contractData?.adAgencyData?.companyNames),
				value: contractData?.adAgencyData?.id || '',
			},
			agentAddressId: {
				label: addressShortner(sagData?.adAgencyAddressData),
				value: sagData?.adAgencyAddressData?.id || '',
			},
			performerId: {	
				label: getPersonFullName(contractData?.performer?.personName),
				value: contractData?.performer?.id || '',
			},
			performerAddressId: {
				label: addressShortner(sagData?.performerAddressData),
				value: sagData?.performerAddressData?.id || '',
			},
			producerAddressId: {
				label: addressShortner(sagData?.producerAddressData),
				value: sagData?.producerAddressData?.id || '',
			},
			payrollVendorId: {
				label:
					getCompanyDisplayName(contractData?.payrollVendor?.companyNames) ||
					'',
				value: contractData?.payrollVendor?.id || '',
			},
			advertiserId: {
				label: getCompanyDisplayName(contractData?.advertiser?.companyNames),
				value: contractData?.advertiser?.id || '',
			},
			talentAgencyStaffId: {
				label: getPersonFullName(sagData?.talentAgencyStaffData?.personName),
				value: sagData?.talentAgencyStaffData?.id || '',
			},
			agentId: {
				label: getCompanyDisplayName(sagData?.agentData?.companyNames),
				value: sagData?.agentData?.id || '',
			},
			// returnToEmail: {
			// 	label: returnToEmail?.emailId ? `${getPersonFullName(sagData?.returnToPersonData?.personName)} (${returnToEmail?.emailId || ''})` : '',
			// 	value: sagData?.returnToEmail || '',
			// },
		};

		return data;
	};

	const getContractStandardSagAftraTvPrincipalFormData = (): IContractSagAftraTvPrincipalForm | undefined => {
		if (!contractData) return undefined;

		let commercials: ICommercialDetail[] =
			contractData?.contractSagAftraTvPrincipal?.commercials?.map((asset) => ({
				spots: asset?.commercialRelationData?.spots || '',
				tags: asset?.commercialRelationData?.tags || '',
				demos: asset?.commercialRelationData?.demos || '',
				assetId: {
					label: asset?.title || '',
					value: asset?.id || '',
				},
			})) || [];
		
		const sagData = contractData?.contractSagAftraTvPrincipal;

		commercials = commercials?.sort((a, b) => 
			(a?.assetId?.label || '').localeCompare(b?.assetId?.label || '')
		);
		
		// const returnToEmail = sagData?.returnToPersonData?.emails?.find(e => e?.id === sagData?.returnToEmail);

		const data: IContractSagAftraTvPrincipalForm = {
			...(contractData || {}),
			...(sagData || {}) as IContractSagAftraTvPrincipalForm,
			commercials,
			isJPCAuthorizer: sagData?.isJPCAuthorizer || false,
			workDays: sagData?.workDays || [],
			contractW4Form: contractData?.contractW4Form,
			contractW8BenShortForm: contractData?.contractW8BenShortForm,
			contractW9BenShortForm: contractData?.contractW9BenShortForm,
			contractMinorTrustForm: contractData?.contractMinorTrustForm,
			payrollVendorAddressId: {
				label: displayAddress(contractData?.payrollVendorAddressData),
				value: contractData?.payrollVendorAddressData?.id || contractData?.payrollVendorAddressId || '',
			},
			// returnToEmail: {
			// 	label: returnToEmail?.emailId ? `${getPersonFullName(sagData?.returnToPersonData?.personName)} (${returnToEmail?.emailId || ''})` : '',
			// 	value: sagData?.returnToEmail || '',
			// },
			adAgencyId: {
				label: getCompanyDisplayName(contractData?.adAgencyData?.companyNames),
				value: contractData?.adAgencyData?.id || '',
			},
			adAgencyAddressId: {
				label: displayAddress(sagData?.adAgencyAddressData),
				value: sagData?.adAgencyAddressData?.id || '',
			},
			performerAddressId: {
				label: displayAddress(sagData?.performerAddressData),
				value: sagData?.performerAddressData?.id || '',
			},
			performerAdAgencyId: {
				label: getCompanyDisplayName(
					sagData?.performerAdAgency?.companyNames
				),
				value: sagData?.performerAdAgency?.id || '',
			},
			performerAdAgencyAddressId: {
				label: displayAddress(sagData?.performerAdAgencyAddressData),
				value: sagData?.performerAdAgencyAddressData?.id || '',
			},
			performerAdAgencyPhoneId: sagData?.performerAdAgencyPhoneId || '',
			advertiserId: {
				label: getCompanyDisplayName(contractData?.advertiser?.companyNames),
				value: contractData?.advertiser?.id || '',
			},
			performerId: {
				label: getPersonFullName(contractData?.performer?.personName),
				value: contractData?.performer?.id || '',
			},
			productId: sagData?.product?.map((product: any) => {
				return { label: product?.productName || '',
					value: product?.id || ''
				};
			}),
			producerCompanyId: {
				label:
					getCompanyDisplayName(sagData?.producer?.companyNames) || '',
				value: sagData?.producer?.id || '',
			},
			producerAddressId: {
				label: displayAddress(sagData?.producerAddressData),
				value: sagData?.producerAddressData?.id || '',
			},
			payrollVendorId: {
				label:
					getCompanyDisplayName(contractData?.payrollVendor?.companyNames) ||
					'',
				value: contractData?.payrollVendor?.id || '',
			},
		};
		return data;
	};

	const getContractSagAftraSdExtComEx2Y22FormData = (): IContractSagAftraSdExtComEx2Y22Form | undefined => {
		if (!contractData) return undefined;

		let commercials: ICommercialDetail[] =
		contractData?.contractSagAftraSdExtComEx2Y22?.commercials?.map((asset) => ({
			spots: asset?.commercialRelationData?.spots || '',
			tags: asset?.commercialRelationData?.tags || '',
			demos: asset?.commercialRelationData?.demos || '',
			assetId: {
				label: asset?.title || '',
				value: asset?.id || '',
			},
		})) || [];

		commercials = commercials?.sort((a, b) => 
			(a?.assetId?.label || '').localeCompare(b?.assetId?.label || '')
		);
		
		const sagData = contractData?.contractSagAftraSdExtComEx2Y22;

		// const returnToEmail = sagData?.returnToPersonData?.emails?.find(e => e?.id === sagData?.returnToEmail);

		const data: IContractSagAftraSdExtComEx2Y22Form = {
			...(contractData || {}),
			...(sagData || {}) as IContractSagAftraSdExtComEx2Y22Form,
			commercials,
			isJPCAuthorizer: sagData?.isJPCAuthorizer || false,
			workDays: sagData?.workDays || [],
			contractW4Form: contractData?.contractW4Form,
			contractW8BenShortForm: contractData?.contractW8BenShortForm,
			contractW9BenShortForm: contractData?.contractW9BenShortForm,
			contractMinorTrustForm: contractData?.contractMinorTrustForm,
			payrollVendorAddressId: {
				label: displayAddress(contractData?.payrollVendorAddressData),
				value: contractData?.payrollVendorAddressData?.id || contractData?.payrollVendorAddressId || '',
			},
			adAgencyId: {
				label: getCompanyDisplayName(contractData?.adAgencyData?.companyNames),
				value: contractData?.adAgencyData?.id || '',
			},
			talentAgencyId: {
				label: getCompanyDisplayName(sagData?.talentAgency?.companyNames),
				value: sagData?.talentAgency?.id || '',
			},
			adAgencyAddressId: {
				label: displayAddress(sagData?.adAgencyAddressData),
				value: sagData?.adAgencyAddressData?.id || '',
			},
			productId: sagData?.product?.map((product: any) => {
				return { label: product?.productName || '',
					value: product?.id || ''
				};
			}),
			performerId: {
				label: getPersonFullName(contractData?.performer?.personName),
				value: contractData?.performer?.id || '',
			},
			performerAddressId: {
				label: addressShortner(sagData?.performerAddressData),
				value: sagData?.performerAddressData?.id || '',
			},
			performerAdAgencyId: {
				label:
					getCompanyDisplayName(sagData?.performerAdAgency?.companyNames) || '',
				value: sagData?.performerAdAgency?.id || '',
			},
			producerCompanyId: {
				label:
					getCompanyDisplayName(sagData?.producer?.companyNames) || '',
				value: sagData?.producer?.id || '',
			},
			performerAdAgencyAddressId: {
				label: addressShortner(sagData?.performerAdAgencyAddressData),
				value: sagData?.performerAdAgencyAddressData?.id || '',
			},
			payrollVendorId: {
				label:
					getCompanyDisplayName(contractData?.payrollVendor?.companyNames) ||
					'',
				value: contractData?.payrollVendor?.id || '',
			},
			advertiserId: {
				label: getCompanyDisplayName(contractData?.advertiser?.companyNames),
				value: contractData?.advertiser?.id || '',
			},
			// returnToEmail: {
			// 	label:  returnToEmail?.emailId ? `${getPersonFullName(sagData?.returnToPersonData?.personName)} (${returnToEmail?.emailId || ''})` : '',
			// 	value: sagData?.returnToEmail || '',
			// },
		};

		return data;
	};

	const getContractSagAftraSdAudComFormData = (): IContractSagAftraSdAudComForm | undefined => {
		if (!contractData) return undefined;

		const commercials: ICommercialDetail[] =
		contractData?.contractSagAftraSdAudCom?.commercials?.map((asset) => ({
			spots: asset?.commercialRelationData?.spots || '',
			tags: asset?.commercialRelationData?.tags || '',
			demos: asset?.commercialRelationData?.demos || '',
			assetId: {
				label: asset?.title || '',
				value: asset?.id || '',
			},
		})) || [];
		
		const sagData = contractData?.contractSagAftraSdAudCom;

		// const returnToEmail = sagData?.returnToPersonData?.emails?.find(e => e?.id === sagData?.returnToEmail);

		const data: IContractSagAftraSdAudComForm = {
			...(contractData || {}),
			...(sagData || {}) as IContractSagAftraSdAudComForm,
			commercials,
			workDays: sagData?.workDays || [],
			contractW4Form: contractData?.contractW4Form,
			contractW8BenShortForm: contractData?.contractW8BenShortForm,
			contractW9BenShortForm: contractData?.contractW9BenShortForm,
			contractMinorTrustForm: contractData?.contractMinorTrustForm,
			payrollVendorAddressId: {
				label: displayAddress(contractData?.payrollVendorAddressData),
				value: contractData?.payrollVendorAddressData?.id || contractData?.payrollVendorAddressId || '',
			},
			adAgencyId: {
				label: getCompanyDisplayName(contractData?.adAgencyData?.companyNames),
				value: contractData?.adAgencyData?.id || '',
			},
			adAgencyAddressId: {
				label: displayAddress(sagData?.adAgencyAddressData),
				value: sagData?.adAgencyAddressData?.id || '',
			},
			productId: sagData?.product?.map((product: any) => {
				return { label: product?.productName || '',
					value: product?.id || ''
				};
			}),
			performerId: {
				label: getPersonFullName(contractData?.performer?.personName),
				value: contractData?.performer?.id || '',
			},
			performerAddressId: {
				label: addressShortner(sagData?.performerAddressData),
				value: sagData?.performerAddressData?.id || '',
			},
			performerAdAgencyId: {
				label:
					getCompanyDisplayName(sagData?.performerAdAgency?.companyNames) || '',
				value: sagData?.performerAdAgency?.id || '',
			},
			producerCompanyId: {
				label:
					getCompanyDisplayName(sagData?.producer?.companyNames) || '',
				value: sagData?.producer?.id || '',
			},
			producerCompanyAddressId: {
				label: addressShortner(sagData?.producerCompanyAddressData),
				value: sagData?.producerCompanyAddressData?.id || '',
			},
			performerAdAgencyAddressId: {
				label: addressShortner(sagData?.performerAdAgencyAddressData),
				value: sagData?.performerAdAgencyAddressData?.id || '',
			},
			payrollVendorId: {
				label:
					getCompanyDisplayName(contractData?.payrollVendor?.companyNames) ||
					'',
				value: contractData?.payrollVendor?.id || '',
			},
			advertiserId: {
				label: getCompanyDisplayName(contractData?.advertiser?.companyNames),
				value: contractData?.advertiser?.id || '',
			},
			// returnToEmail: {
			// 	label:  returnToEmail?.emailId ? `${getPersonFullName(sagData?.returnToPersonData?.personName)} (${returnToEmail?.emailId || ''})` : '',
			// 	value: sagData?.returnToEmail || '',
			// },
		};

		return data;
	};

	const getContractTeamSagAftraSdAudComFormData = (): IContractSagAftraSdAudTeamComForm | undefined => {
		if (!contractData) return undefined;

		const commercials: ICommercialDetail[] =
		contractData?.contractTeamSagAftraSdAudCom?.commercials?.map((asset) => ({
			spots: asset?.commercialRelationData?.spots || '',
			tags: asset?.commercialRelationData?.tags || '',
			demos: asset?.commercialRelationData?.demos || '',
			assetId: {
				label: asset?.adId || '',
				value: asset?.id || '',
			},
		})) || [];
		
		const sagData = contractData?.contractTeamSagAftraSdAudCom;

		let workDays= sagData?.workDays || [];
		workDays = workDays?.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());

		const returnToEmail = sagData?.returnToPersonData?.emails?.find(e => e?.id === sagData?.returnToEmail);

		const data: IContractSagAftraSdAudTeamComForm = {
			...(contractData || {}),
			...(sagData || {}) as IContractSagAftraSdAudTeamComForm,
			commercials,
			workDays,
			contractW4Form: contractData?.contractW4Form,
			contractW8BenShortForm: contractData?.contractW8BenShortForm,
			contractW9BenShortForm: contractData?.contractW9BenShortForm,
			contractMinorTrustForm: contractData?.contractMinorTrustForm,
			payrollVendorAddressId: {
				label: displayAddress(contractData?.payrollVendorAddressData),
				value: contractData?.payrollVendorAddressData?.id || contractData?.payrollVendorAddressId || '',
			},
			adAgencyId: {
				label: getCompanyDisplayName(contractData?.adAgencyData?.companyNames),
				value: contractData?.adAgencyData?.id || '',
			},
			adAgencyAddressId: {
				label: displayAddress(sagData?.adAgencyAddressData),
				value: sagData?.adAgencyAddressData?.id || '',
			},
			productId: sagData?.product?.map((product: any) => {
				return { label: product?.productName || '',
					value: product?.id || ''
				};
			}),
			performerId: {
				label: getPersonFullName(contractData?.performer?.personName),
				value: contractData?.performer?.id || '',
			},
			performerAddressId: {
				label: addressShortner(sagData?.performerAddressData),
				value: sagData?.performerAddressData?.id || '',
			},
			performerAdAgencyId: {
				label:
					getCompanyDisplayName(sagData?.performerAdAgency?.companyNames) || '',
				value: sagData?.performerAdAgency?.id || '',
			},
			producerCompanyId: {
				label:
					getCompanyDisplayName(sagData?.producer?.companyNames) || '',
				value: sagData?.producer?.id || '',
			},
			producerCompanyAddressId: {
				label: addressShortner(sagData?.producerCompanyAddressData),
				value: sagData?.producerCompanyAddressData?.id || '',
			},
			performerAdAgencyAddressId: {
				label: addressShortner(sagData?.performerAdAgencyAddressData),
				value: sagData?.performerAdAgencyAddressData?.id || '',
			},
			payrollVendorId: {
				label:
					getCompanyDisplayName(contractData?.payrollVendor?.companyNames) ||
					'',
				value: contractData?.payrollVendor?.id || '',
			},
			advertiserId: {
				label: getCompanyDisplayName(contractData?.advertiser?.companyNames),
				value: contractData?.advertiser?.id || '',
			},
			loanOutId:{
				label: getCompanyDisplayName(sagData?.loanOutCorp?.companyNames) || '',
				value: sagData?.loanOutCorp?.id || '',
			},
			returnToEmail: {
				label:  returnToEmail?.emailId ? `${getPersonFullName(sagData?.returnToPersonData?.personName)} (${returnToEmail?.emailId || ''})` : '',
				value: sagData?.returnToEmail || '',
			},
		};

		return data;
	};

	const getContractSagAftraAudioComHTFormData = (): IContractSagAftraAudioComHTForm | undefined => {
		if (!contractData) return undefined;

		const commercials: ICommercialDetail[] =
		contractData?.contractSagAftraAudioComHT?.commercials?.map((asset) => ({
			spots: asset?.commercialRelationData?.spots || '',
			tags: asset?.commercialRelationData?.tags || '',
			demos: asset?.commercialRelationData?.demos || '',
			assetId: {
				label: asset?.title || '',
				value: asset?.id || '',
			},
		})) || [];
		
		const sagData = contractData?.contractSagAftraAudioComHT;
		let workDays= sagData?.workDays || [];

		workDays = workDays?.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
		const returnToEmail = sagData?.returnToPersonData?.emails?.find(e => e?.id === sagData?.returnToEmail);

		const data: IContractSagAftraAudioComHTForm = {
			...(contractData || {}),
			...(sagData || {}) as IContractSagAftraAudioComHTForm,
			commercials,
			workDays,
			contractW4Form: contractData?.contractW4Form,
			contractW8BenShortForm: contractData?.contractW8BenShortForm,
			contractW9BenShortForm: contractData?.contractW9BenShortForm,
			contractMinorTrustForm: contractData?.contractMinorTrustForm,
			payrollVendorAddressId: {
				label: displayAddress(contractData?.payrollVendorAddressData),
				value: contractData?.payrollVendorAddressData?.id || contractData?.payrollVendorAddressId || '',
			},
			adAgencyId: {
				label: getCompanyDisplayName(contractData?.adAgencyData?.companyNames),
				value: contractData?.adAgencyData?.id || '',
			},
			adAgencyAddressId: {
				label: displayAddress(sagData?.adAgencyAddressData),
				value: sagData?.adAgencyAddressData?.id || '',
			},
			productId: sagData?.product?.map((product: any) => {
				return { label: product?.productName || '',
					value: product?.id || ''
				};
			}),
			performerId: {
				label: getPersonFullName(contractData?.performer?.personName),
				value: contractData?.performer?.id || '',
			},
			performerAddressId: {
				label: displayAddress(sagData?.performerAddressData),
				value: sagData?.performerAddressData?.id || '',
			},
			performerAdAgencyId: {
				label:
					getCompanyDisplayName(sagData?.performerAdAgency?.companyNames) || '',
				value: sagData?.performerAdAgency?.id || '',
			},
			producerCompanyId: {
				label:
					getCompanyDisplayName(sagData?.producer?.companyNames) || '',
				value: sagData?.producer?.id || '',
			},
			producerCompanyAddressId: {
				label: displayAddress(sagData?.producerCompanyAddressData),
				value: sagData?.producerCompanyAddressData?.id || '',
			},
			performerAdAgencyAddressId: {
				label: displayAddress(sagData?.performerAdAgencyAddressData),
				value: sagData?.performerAdAgencyAddressData?.id || '',
			},
			payrollVendorId: {
				label:
					getCompanyDisplayName(contractData?.payrollVendor?.companyNames) ||
					'',
				value: contractData?.payrollVendor?.id || '',
			},
			advertiserId: {
				label: getCompanyDisplayName(contractData?.advertiser?.companyNames),
				value: contractData?.advertiser?.id || '',
			},
			returnToEmail: {
				label:  returnToEmail?.emailId ? `${getPersonFullName(sagData?.returnToPersonData?.personName)} (${returnToEmail?.emailId || ''})` : '',
				value: sagData?.returnToEmail || '',
			},
		};

		return data;
	};

	const getContractSagAftraTvExtraHTFormData = (): IContractSagAftraTvExtraHTForm | undefined => {
		if (!contractData) return undefined;

		let commercials: ICommercialDetail[] =
		contractData?.contractSagAftraTvExtraHT?.commercials?.map((asset) => ({
			spots: asset?.commercialRelationData?.spots || '',
			tags: asset?.commercialRelationData?.tags || '',
			demos: asset?.commercialRelationData?.demos || '',
			assetId: {
				label: asset?.title || '',
				value: asset?.id || '',
			},
		})) || [];

		commercials = commercials?.sort((a, b) => 
			(a?.assetId?.label || '').localeCompare(b?.assetId?.label || '')
		);
		
		const sagData = contractData?.contractSagAftraTvExtraHT;

		let workDays= sagData?.workDays || [];

		workDays = workDays?.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());
		const returnToEmail = sagData?.returnToPersonData?.emails?.find(e => e?.id === sagData?.returnToEmail);

		const data: IContractSagAftraTvExtraHTForm = {
			...(contractData || {}),
			...(sagData || {}) as IContractSagAftraTvExtraHTForm,
			commercials,
			isJPCAuthorizer: sagData?.isJPCAuthorizer || false,
			workDays,
			contractW4Form: contractData?.contractW4Form,
			contractW8BenShortForm: contractData?.contractW8BenShortForm,
			contractW9BenShortForm: contractData?.contractW9BenShortForm,
			contractMinorTrustForm: contractData?.contractMinorTrustForm,
			payrollVendorAddressId: {
				label: displayAddress(contractData?.payrollVendorAddressData),
				value: contractData?.payrollVendorAddressData?.id || contractData?.payrollVendorAddressId || '',
			},
			adAgencyId: {
				label: getCompanyDisplayName(contractData?.adAgencyData?.companyNames),
				value: contractData?.adAgencyData?.id || '',
			},
			talentAgencyId: {
				label: getCompanyDisplayName(sagData?.talentAgency?.companyNames),
				value: sagData?.talentAgency?.id || '',
			},
			adAgencyAddressId: {
				label: displayAddress(sagData?.adAgencyAddressData),
				value: sagData?.adAgencyAddressData?.id || '',
			},
			productId: sagData?.product?.map((product: any) => {
				return { label: product?.productName || '',
					value: product?.id || ''
				};
			}),
			performerId: {
				label: getPersonFullName(contractData?.performer?.personName),
				value: contractData?.performer?.id || '',
			},
			performerAddressId: {
				label: addressShortner(sagData?.performerAddressData),
				value: sagData?.performerAddressData?.id || '',
			},
			performerAdAgencyId: {
				label:
					getCompanyDisplayName(sagData?.performerAdAgency?.companyNames) || '',
				value: sagData?.performerAdAgency?.id || '',
			},
			producerCompanyId: {
				label:
					getCompanyDisplayName(sagData?.producer?.companyNames) || '',
				value: sagData?.producer?.id || '',
			},
			performerAdAgencyAddressId: {
				label: addressShortner(sagData?.performerAdAgencyAddressData),
				value: sagData?.performerAdAgencyAddressData?.id || '',
			},
			payrollVendorId: {
				label:
					getCompanyDisplayName(contractData?.payrollVendor?.companyNames) ||
					'',
				value: contractData?.payrollVendor?.id || '',
			},
			advertiserId: {
				label: getCompanyDisplayName(contractData?.advertiser?.companyNames),
				value: contractData?.advertiser?.id || '',
			},
			returnToEmail: {
				label:  returnToEmail?.emailId ? `${getPersonFullName(sagData?.returnToPersonData?.personName)} (${returnToEmail?.emailId || ''})` : '',
				value: sagData?.returnToEmail || '',
			},
		};
		return data;
	};

	const getContractTeamSagAftraSdExtCom = ():IContractSagAftraSdExtComExTeamForm | undefined => {
		if (!contractData) return undefined;

		const commercials: ICommercialDetail[] =
		contractData?.contractTeamSagAftraSdExtCom?.commercials?.map((asset) => ({
			spots: asset?.commercialRelationData?.spots || '',
			tags: asset?.commercialRelationData?.tags || '',
			demos: asset?.commercialRelationData?.demos || '',
			assetId: {
				label: asset?.title || '',
				value: asset?.id || '',
			},
		})) || [];
		
		const sagData = contractData?.contractTeamSagAftraSdExtCom;
		let workDays= sagData?.workDays || [];
		workDays = workDays?.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());

		// const returnToEmail = sagData?.returnToPersonData?.emails?.find(e => e?.id === sagData?.returnToEmail);

		const data: IContractSagAftraSdExtComExTeamForm = {
			...(contractData || {}),
			...(sagData || {}) as IContractSagAftraSdExtComExTeamForm,
			commercials,
			workDays,
			contractW4Form: contractData?.contractW4Form,
			contractW8BenShortForm: contractData?.contractW8BenShortForm,
			contractW9BenShortForm: contractData?.contractW9BenShortForm,
			contractMinorTrustForm: contractData?.contractMinorTrustForm,
			payrollVendorAddressId: {
				label: displayAddress(contractData?.payrollVendorAddressData),
				value: contractData?.payrollVendorAddressData?.id || contractData?.payrollVendorAddressId || '',
			},
			adAgencyId: {
				label: getCompanyDisplayName(contractData?.adAgencyData?.companyNames),
				value: contractData?.adAgencyData?.id || '',
			},
			adAgencyAddressId: {
				label: displayAddress(sagData?.adAgencyAddressData),
				value: sagData?.adAgencyAddressData?.id || '',
			},
			productId: sagData?.product?.map((product: any) => {
				return { label: product?.productName || '',
					value: product?.id || ''
				};
			}),
			performerId: {
				label: getPersonFullName(contractData?.performer?.personName),
				value: contractData?.performer?.id || '',
			},
			performerAddressId: {
				label: addressShortner(sagData?.performerAddressData),
				value: sagData?.performerAddressData?.id || '',
			},
			performerAdAgencyId: {
				label:
					getCompanyDisplayName(sagData?.performerAdAgency?.companyNames) || '',
				value: sagData?.performerAdAgency?.id || '',
			},
			producerCompanyId: {
				label:
					getCompanyDisplayName(sagData?.producer?.companyNames) || '',
				value: sagData?.producer?.id || '',
			},
			producerCompanyAddressId: {
				label: addressShortner(sagData?.producerCompanyAddressData),
				value: sagData?.producerCompanyAddressData?.id || '',
			},
			performerAdAgencyAddressId: {
				label: addressShortner(sagData?.performerAdAgencyAddressData),
				value: sagData?.performerAdAgencyAddressData?.id || '',
			},
			payrollVendorId: {
				label:
					getCompanyDisplayName(contractData?.payrollVendor?.companyNames) ||
					'',
				value: contractData?.payrollVendor?.id || '',
			},
			advertiserId: {
				label: getCompanyDisplayName(contractData?.advertiser?.companyNames),
				value: contractData?.advertiser?.id || '',
			},
			loanOutId:{
				label: getCompanyDisplayName(sagData?.loanOutCorp?.companyNames),
				value: sagData?.loanOutCorp?.id || '',
			},
			talentAgencyId:{
				label: getCompanyDisplayName(sagData?.talentAgency?.companyNames),
				value: sagData?.talentAgency?.id || '',
			},
			// returnToEmail: {
			// 	label:  returnToEmail?.emailId ? `${getPersonFullName(sagData?.returnToPersonData?.personName)} (${returnToEmail?.emailId || ''})` : '',
			// 	value: sagData?.returnToEmail || '',
			// },
		};

		return data;
	};

	const getContractNonUnionFormData = (): IContractNonUnionForm | undefined => {
		
		if (!contractData) return undefined;

		const shouldShowAssetTitle = [
			CONTRACT_FORM.NON_UNION_TALENT_AGREEMENT_ON_CAMERA_PRINCIPAL,
			CONTRACT_FORM.NON_UNION_TALENT_AGREEMENT_EXTRA,
			CONTRACT_FORM.NON_UNION_TALENT_AGREEMENT_VOICE_OVER,
		].includes(contractData?.contractName?.toUpperCase());

		let commercials: ICommercialDetail[] =
		contractData?.contractNonUnion?.commercials?.map((asset) => ({
			spots: asset?.commercialRelationData?.spots || '',
			tags: asset?.commercialRelationData?.tags || '',
			demos: asset?.commercialRelationData?.demos || '',
			assetId: {
				label: (shouldShowAssetTitle ? asset?.title :  asset?.adId) || '',
				value: asset?.id || '',
			},
		})) || [];
		
		const sagData = contractData?.contractNonUnion;
		let workDays= sagData?.workDays || [];
		workDays = workDays?.sort((a: any, b: any) => new Date(a.date).getTime() - new Date(b.date).getTime());

		let additionalFees= sagData?.additionalFees || [];
		additionalFees = additionalFees?.sort((a: any, b: any) => Number(b?.fees) - Number(a?.fees));

		commercials = commercials?.sort((a, b) => 
			(a?.assetId?.label || '').localeCompare(b?.assetId?.label || '')
		);
		const data: IContractNonUnionForm = {
			...(contractData || {}),
			...(sagData || {}) as IContractNonUnionForm,
			contractW4Form: contractData?.contractW4Form,
			contractW8BenShortForm: contractData?.contractW8BenShortForm,
			contractW9BenShortForm: contractData?.contractW9BenShortForm,
			contractMinorTrustForm: contractData?.contractMinorTrustForm,
			payrollVendorAddressId: {
				label: displayAddress(contractData?.payrollVendorAddressData),
				value: contractData?.payrollVendorAddressData?.id || contractData?.payrollVendorAddressId || '',
			},
			commercials,
			workDays,
			additionalFees,
			adAgencyId: {
				label: getCompanyDisplayName(contractData?.adAgencyData?.companyNames),
				value: contractData?.adAgencyData?.id || '',
			},
			adAgencyAddressId: {
				label: displayAddress(sagData?.adAgencyAddressData),
				value: sagData?.adAgencyAddressData?.id || '',
			},
			productId: {
				label: sagData?.product?.productName || '',
				value: sagData?.product?.id || '',
			},
			performerId: {
				label: getPersonFullName(contractData?.performer?.personName),
				value: contractData?.performer?.id || '',
			},
			performerAddressId: {
				label: displayAddress(sagData?.performerAddressData),
				value: sagData?.performerAddressData?.id || '',
			},
			payrollVendorId: {
				label:
					getCompanyDisplayName(contractData?.payrollVendor?.companyNames) ||
					'',
				value: contractData?.payrollVendor?.id || '',
			},
			advertiserId: {
				label: getCompanyDisplayName(contractData?.advertiser?.companyNames),
				value: contractData?.advertiser?.id || '',
			},
			recordingCompanyId: {
				label: getCompanyDisplayName(sagData?.recordingCompany?.companyNames),
				value: sagData?.recordingCompany?.id || '',
			},
			talentAgencyId: {
				label: getCompanyDisplayName(sagData?.talentAgency?.companyNames),
				value: sagData?.talentAgency?.id || '',
			},
			talentAgencyAddressId: {
				label: displayAddress(sagData?.talentAgencyAddressData),
				value: sagData?.talentAgencyAddressData?.id || '',
			},
			talentAgencyStaffId: {
				label: getPersonFullName(sagData?.talentAgencyStaffData?.personName),
				value: sagData?.talentAgencyStaffData?.id || '',
			},
			brandId:{
				label: sagData?.brandData?.brandName || '',
				value: sagData?.brandData?.id || '',
			},
			productionCompanyId: {
				label: getCompanyDisplayName(sagData?.producer?.companyNames),
				value: sagData?.producer?.id || '',
			},
		};

		return data;
	};

	const getContractMSAFormFormData = (): IContractMSAForm | undefined => {
		if (!contractData) return undefined;
		
		const sagData = contractData?.contractMSA;

		const data: IContractMSAForm = {
			...(contractData || {}),
			...(sagData || {}) as IContractMSAForm,
			contractW4Form: contractData?.contractW4Form,
			contractW8BenShortForm: contractData?.contractW8BenShortForm,
			contractW9BenShortForm: contractData?.contractW9BenShortForm,
			contractMinorTrustForm: contractData?.contractMinorTrustForm,
			payrollVendorAddressId: {
				label: displayAddress(contractData?.payrollVendorAddressData),
				value: contractData?.payrollVendorAddressData?.id || contractData?.payrollVendorAddressId || '',
			},
			adAgencyId: {
				label: getCompanyDisplayName(contractData?.adAgencyData?.companyNames),
				value: contractData?.adAgencyData?.id || '',
			},
			talentAgencyId: {
				label: getCompanyDisplayName(sagData?.talentAgency?.companyNames),
				value: sagData?.talentAgency?.id || '',
			},
			talentAgencyAddressId: {
				label: displayAddress(sagData?.talentAgencyAddressData),
				value: sagData?.talentAgencyAddressData?.id || '',
			},
			adAgencyAddressId: {
				label: displayAddress(sagData?.adAgencyAddressData),
				value: sagData?.adAgencyAddressData?.id || '',
			},
			productId: {
				label: sagData?.product?.productName || '',
				value: sagData?.product?.id || '',
			},
			performerId: {
				label: getPersonFullName(contractData?.performer?.personName),
				value: contractData?.performer?.id || '',
			},
			payrollVendorId: {
				label:
					getCompanyDisplayName(contractData?.payrollVendor?.companyNames) ||
					'',
				value: contractData?.payrollVendor?.id || '',
			},
			advertiserId: {
				label: getCompanyDisplayName(contractData?.advertiser?.companyNames),
				value: contractData?.advertiser?.id || '',
			},
			// Creator Agreement
			lenderCompanyId: {
				label: getCompanyDisplayName(sagData?.lenderCompany?.companyNames),
				value: sagData?.lenderCompany?.id || '',
			},
			onBehalfOfCompanyId:{
				label: getCompanyDisplayName(sagData?.onBehalfOfCompany?.companyNames),
				value: sagData?.onBehalfOfCompany?.id || '',
			},
			lenderCompanyAddressId: {
				label: displayAddress(sagData?.lenderCompanyAddressData),
				value: sagData?.lenderCompanyAddressData?.id || '',
			},
			onBehalfOfCompanyAddressId: {
				label: displayAddress(sagData?.onBehalfOfCompanyAddressData),
				value: sagData?.onBehalfOfCompanyAddressData?.id || '',
			},
			lenderCompanyStaffId : {
				label: getPersonFullName(sagData?.lenderCompanyStaffData?.personName),
				value: sagData?.lenderCompanyStaffData?.id || '',
			},
			onBehalfOfCompanyStaffId : {
				label: getPersonFullName(sagData?.onBehalfOfCompanyStaffData?.personName),
				value: sagData?.onBehalfOfCompanyStaffData?.id || '',
			},
		};

		return data;
	};

	const handleContractBlobSave = async (contractId: string) => {
		try {
			const blobName = await handleGeneratePdf(true);
			if (blobName && contractData?.id) {
				setUpdatedBlobName(blobName);
				return await updateContractPdfBlob({ contractId, blobName });
			}
		} catch (e) {
			console.error('Error: while generating pdf');
		}
	};

	useEffect(() => {
		if(!appContext?.showLoader && !contractFormState?.isOpen && contractContext?.formState?.isContractUpdated && (contractContext?.formState?.updatedContractId === contractData?.id)){
			store.dispatch(setLoader(true));
			setTimeout(() => handleContractBlobSave(contractData?.id || '').then(() => {
				props?.fetchContractDetail && props?.fetchContractDetail();
			}), 1000);			
			store.dispatch(updateContractFormState({}));
			store.dispatch(setLoader(false));				
		}
	}, [contractData, contractContext?.formState?.isContractUpdated, contractContext?.formState?.updatedContractId, contractFormState?.isOpen, appContext?.showLoader]);

	useEffect(() => {
		if(!appContext?.showLoader && !contractFormState?.isOpen && !contractData?.contractPdfBlobName){
			store.dispatch(setLoader(true));
			setTimeout(() => handleContractBlobSave(contractData?.id || ''), 1000);
			store.dispatch(setLoader(false));
			props?.fetchContractDetail && props?.fetchContractDetail();
		}
	}, [contractData, contractFormState?.isOpen, appContext?.showLoader]);

	const onSaveContract = async() => {
		setContractFormState({});
		props?.onSave && props?.onSave();
		props?.onEditClick && props?.onEditClick(false);
	};

	const onCloseContract = () => {
		setContractFormState({});
		props?.onEditClick && props?.onEditClick(false);
	};

	const handlePrintStateOfContract = (isPrint: boolean = false) => {
		setContractFormState(prev => ({
			...prev,
			isOpen: isPrint,
		}));
	};

	const handleEmailIconClick = () => {
		const contractFormData = contractData?.contractName === CONTRACT_FORM.SAG_AFTRA_SD_EMP_CTR_PRI_COM_EX1_Y22 ? contractData.contractSagAftraSdEmpCtrPriComEx1Y22 :
			contractData?.contractName === CONTRACT_FORM.SAG_AFTRA_SD_EMP_CTR_COM_PRI_PER_Y22 ? contractData.contractSagAftraSdEmpCtrComPriPerY22 :
				contractData?.contractName === CONTRACT_FORM.AFTRA_SD_EMP_CTR_IND_EDU_FILM_VIDEO_PGM ? contractData.contractSagAftraSdEmpCtrIndEduFilmVideoPgm :
					contractData?.contractName === CONTRACT_FORM.SAG_AFTRA_SD_EXT_COM_EX2_Y22 ? contractData.contractSagAftraSdExtComEx2Y22 :
						contractData?.contractName === CONTRACT_FORM.SAG_AFTRA_SD_AUD_COM ? contractData.contractSagAftraSdAudCom :
							contractData?.contractName === CONTRACT_FORM.SAG_AFTRA_SD_ACS_AUD_COM_Y22 ? contractData.contractSagAftraSdAudCom : 
								contractData?.contractName === CONTRACT_FORM.SAG_AFTRA_AUDIO_COMMERCIALS_HIGHLAND_TALENT ? contractData.contractSagAftraAudioComHT : 
									contractData?.contractName === CONTRACT_FORM.SAG_AFTRA_TV_EXTRA_HIGHLAND_TALENT ? contractData.contractSagAftraTvExtraHT :
										contractData?.contractName === CONTRACT_FORM.NON_UNION_PER_AGRMNT_OFF_CAM_PRI ? contractData.contractNonUnion :
											contractData?.contractName === CONTRACT_FORM.NON_UNION_PER_AGRMNT_ON_CAM_PRI ? contractData.contractNonUnion :
												contractData?.contractName === CONTRACT_FORM.NON_UNION_TALENT_AGRMNT ? contractData.contractNonUnion : contractData?.contractMSA;
		const emailData = getEmailDetails('CONTRACT', contractFormData);
		const content = getEmailContent('CONTRACT', emailData.emailData);
		setEditorContent(content);
		setEmailDialogOpen(true);
	};

	const handleCancelIconClick = () => {
		setCancelContractDialogOpen(true);
	};

	const handleEmailDialogClose = () => {
		setEmailDialogOpen(false);
		sendEmailForm.setValue('toEmail', '');
		sendEmailForm.setValue('reason', '');
		sendEmailForm.setValue('isGovFormNeeded', undefined);
		sendEmailForm.setValue('govForms', ['']);
	};

	const handleStatusEditDialogClose = () => {
		setStatusEditDialogOpen(false);
	};

	const handleCancelContractDialogClose = () => {
		setCancelContractDialogOpen(false);
	};

	const sendEmailAction: IDialogAction[] = [
		{
			name: 'Cancel',
			variant: 'outlined',
			'data-cy': 'cancel_btn',
			onClick: handleEmailDialogClose
		},
		{
			name: 'Send Email',
			color: 'primary',
			variant: 'contained',
			'data-cy': 'send_email_btn',
			onClick: () => {
				handleEmailSubmit(sendEmailForm.getValues());
			}
		},
	];

	const cancelContractDialogActions: IDialogAction[] = [
		{
			name: 'Yes, Cancel',
			onClick: () => handleContractStatus(ContractStatus.Cancelled),
			color: 'error',
			variant: 'contained',
			'data-cy': 'yes_add_btn',
		},
		{
			name: 'Close',
			onClick: handleCancelContractDialogClose,
			variant: 'outlined',
			'data-cy': 'no_btn',
		},
	];

	const handleEditorChange = (value: string) => {
		setEditorContent(value);
	};

	const statusEditOnSubmit = (data) => {
		if(data?.status){
			handleContractStatus(data?.status);
		}
	};

	const handleContractCancelStatus = (status: any) => {
		const payload = {
			contractId: contractData?.id,
			status: status
		};
		editContractStatus(payload).then((res) => {
			if (res?.status === HttpStatusCode.Ok) {
				notification.notify({
					message: getNotificationText('Contract Status', 'UPDATE'),
					type: 'success',
				});
				handleCancelContractDialogClose();
				handleStatusEditDialogClose();
				// setContractData(null);
				// fetchAllContract();
				props?.fetchContractDetail && props?.fetchContractDetail();
			} else
				notification.notify({
					message:
						getNotificationText('Contract Status', 'UPDATE', true),
					type: 'error',
				});
		});
	};

	const ref = useRef();

	const handleContractStatus = (status: any) => {
		handleContractCancelStatus(status);
	};

	const handleEmailSubmit = (data: any) => {
		data.toEmail = data?.toEmail?.split(/[,;]\s*/);
		if(data.toEmail != ''){
			if(data.toEmail && data.reason){
				if(contractData && (updatedBlobName || contractData?.contractPdfBlobName)){
					getBlobURL(updatedBlobName || contractData.contractPdfBlobName).then((res) => {
						if ((res?.status === StatusCodes.OK)) {
							store.dispatch(setLoader(true));
							fetch(res.data)
								.then(response => response.blob())
								.then(async blob => {
									const file = new File([blob], `${contractData?.contractDisplayName}-${contractData?.status}-contract.pdf`, { type: 'application/pdf' });
									const pdfPassword = generateUniquePassword();
									const emailData = {
										toMails: [...data.toEmail],
										content: editorContent,
										attachments: [file],
										govForms: data.govForms,
										attachmentPassword: pdfPassword,
										contractIds: [contractData?.id],
										contractStatus: data.reason === ContractStatus.DueReview ? ContractStatus.DueReview : ContractStatus.DueSigning,
									};
									const passWordEmailData = {
										toMails: [...data.toEmail],
										content: `<h3>Password for pdf is: ${pdfPassword}</h3>`,
									};
									store.dispatch(setLoader(false));
									sendEmail(emailData).then((res) => {
										if(res?.status == 204){
											notification.notify({
												message: 'Contract Mail sent successfully',
												type: 'success'
											});
											sendEmail(passWordEmailData).then((res) => {
												if(res?.status == 204){
													notification.notify({
														message: 'Contract Password Mail sent successfully',
														type: 'success'
													});
													props?.onSave && props?.onSave();
													sendEmailForm.reset();
													setEmailDialogOpen(false);
												}	
												else{
													notification.notify({
														message: 'Error occurred while sending mail',
														type: 'error'
													});
												}
											});
										}
										else{
											notification.notify({
												message: 'Error occurred while sending mail',
												type: 'error'
											});
										}
									});
								})
								.catch(error => {
									console.error('Error fetching Blob data:', error);
								});
						}
					});
				}
			}
			else{
				!data.toEmail  && sendEmailForm.setError('toEmail', { type: 'required', message: 'To Email field is required' });
				!data.reason && sendEmailForm.setError('reason', { type: 'required', message: 'Reason field is required' });
			}
		}
		else{
			!data.reason && sendEmailForm.setError('reason', { type: 'required', message: 'Reason field is required' });
		}
	};

	const isFormVisible = (form: string) => contractData?.contractName?.toUpperCase() === form;

	useEffect(() => {
		const isEdit = params?.get('isEdit') || null;
		if(isEdit){
			handleEdit();
		}
	}, []);

	const [assetDialogOpen, setAssetDialogOpen] = useState({
		isOpen: false
	} as any);

	const backNavigation = useBackNavigation();

	const getCommercialDetails = (row: any) => {
		const commercial: any = row?.commercials?.length > 0 ? row?.commercials[0] : '';

		return (
			commercial ? <Box display={'flex'}>
				{<Link className={styles.contractProfileLink}
					to={`/asset/${commercial?.id}/asset-profile`}
					onClick={backNavigation.startBackNavigation}
				>
					{<TextWrap title={commercial?.title ?? ''} maxLength={10} />}
				</Link>}
				{row?.commercials?.length >= 2 ? <div
					onClick={() => setAssetDialogOpen({
						isOpen: true,
						data: row?.commercials
					})}>
					<span className={styles.tableLink}>+{row?.commercials?.length-1}</span>
				</div>: null}
			</Box> : <></>
		);
	};

	const { 
		addMultipleContractDetails,
	} = useContractMutation<any>();

	const handleTalentDetailsForm = (data) => {
		const handleMultipleContractAdd = async () => {
			if(data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const data = getContractDetail(contractData);
					data.w4FormId = contractData?.contractW4Form?.id;
					data.w8BenShortFormId = contractData?.contractW8BenShortForm?.id;
					data.w9BenShortFormId = contractData?.contractW9BenShortForm?.id;
					data.minorTrustFormId = contractData?.contractMinorTrustForm?.id;
					data.nYTheftPreventionFormId = contractData?.contractNYTheftPreventionForm?.id;
					data.cATheftPreventionFormId = contractData?.contractCATheftPreventionForm?.id;
					const commercials = data?.commercials?.map((d) => ({
						...d,
						assetId: d?.assetId?.value
					}));
					const workDays = data?.workDays?.filter((d) => d?.date) || [];
					const submitData = {
						...data,
						contractTeamSagAftraCOED: undefined,
						contractMSA: undefined,
						payrollVendorId: data?.payrollVendorId?.value,
						advertiserId: data?.advertiserId?.value,
						adAgencyId: data?.adAgencyId?.value,
						productionCompanyId: data?.productionCompanyId?.value,
						adAgencyAddressId: data?.adAgencyAddressId?.value,
						recordingCompanyId: data?.recordingCompanyId?.value,
						lenderCompanyId: data?.lenderCompanyId?.value,
						lenderCompanyAddressId: data?.lenderCompanyAddressId?.value,
						lenderCompanyStaffId: data?.lenderCompanyStaffId?.value,
						onBehalfOfCompanyId: data?.onBehalfOfCompanyId?.value,
						producerCompanyAddressId: data?.producerCompanyAddressId?.value,
						onBehalfOfCompanyAddressId: data?.onBehalfOfCompanyAddressId?.value,
						onBehalfOfCompanyStaffId: data?.onBehalfOfCompanyStaffId?.value,
						loanOutId: undefined,
						contractNonUnion: undefined,
						contractSagAftraTvExtraHT: undefined,
						brandId: data?.brandId?.value,
						payrollVendorAddressId: data?.payrollVendorAddressId?.value,
						contractSagAftraCOED: undefined,
						contractSagAftraAudioComHT: undefined,
						contractSagAftraSdAudCom: undefined,
						returnToEmail: data?.returnToEmail?.value,
						producerCompanyId: data?.producerCompanyId?.value,
						agentId: data?.agentId?.value,
						agentAddressId: data?.agentAddressId?.value,
						producerAddressId: data?.producerAddressId?.value,
						performerAdAgencyAddressId: data?.performerAdAgencyAddressId?.value,
						performerAdAgencyId: data?.performerAdAgencyId?.value,
						productId: Array.isArray(data?.productId)
							? data?.productId.map((product) => product?.value)
							: data?.productId?.value,
						commercials,
						workDays,
						contractSagAftraTvPrincipal: undefined,
						id: undefined,
						partName: talent?.role,
						talentAgencyId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${contractData?.contractDisplayName}_copy_${index+1}`,
						compensation: talent?.compensation,
						performerAddressId: undefined,
						performerEmailId: undefined,
						performerPhoneId: undefined,
						talentAgencyAddressId: undefined,
						talentAgencyStaffId: undefined,
						talentAgencyPhoneId: undefined,
						talentAgencyEmailId: undefined
					};
					return submitData;
				});

				let multipleContractData;

				if(contractData?.contractName === CONTRACT_FORM.SAG_AFTRA_SD_EMP_CTR_PRI_COM_EX1_Y22){
					multipleContractData = {
						contractAddModels: contractAddModels
					};
				}else if(contractData?.contractName === CONTRACT_FORM.SAG_AFTRA_TV_PRINCIPAL){
					multipleContractData = {
						ContractAddSagAftraTvPrincipalModels: contractAddModels,
					};
				}else if(contractData?.contractName === CONTRACT_FORM.SAG_AFTRA_SD_EMP_CTR_COM_PRI_PER_Y22){
					multipleContractData = {
						contractAddSagAftraSdEmpCtrComPriPerY22Models: contractAddModels
					};
				}else if(contractData?.contractName === CONTRACT_FORM.AFTRA_SD_EMP_CTR_IND_EDU_FILM_VIDEO_PGM){
					multipleContractData = {
						contractAddSagAftraSdEmpCtrIndEduFilmVideoPgmModels: contractAddModels
					};
				}else if(contractData?.contractName === CONTRACT_FORM.SAG_AFTRA_COED){
					multipleContractData = {
						contractAddSagAftraCOEDModels: contractAddModels
					};
				}else if(contractData?.contractName === CONTRACT_FORM.SAG_AFTRA_SD_EXT_COM_EX2_Y22){
					multipleContractData = {
						contractAddSagAftraSdExtComEx2Y22Models: contractAddModels
					};
				}else if(contractData?.contractName === CONTRACT_FORM.SAG_AFTRA_SD_AUD_COM){
					multipleContractData = {
						contractAddSagAftraSdAudComModels: contractAddModels
					};
				}else if(contractData?.contractName === CONTRACT_FORM.SAG_AFTRA_SD_ACS_AUD_COM_Y22){
					multipleContractData = {
						contractAddSagAftraSdAudComModels: contractAddModels,
					};
				}else if(contractData?.contractName === CONTRACT_FORM.SAG_AFTRA_AUDIO_COMMERCIALS_HIGHLAND_TALENT){
					multipleContractData = {
						contractAddSagAftraAudioComHTModels: contractAddModels
					};
				}else if(contractData?.contractName === CONTRACT_FORM.NON_UNION_PER_AGRMNT_ON_CAM_PRI || 
          contractData?.contractName === CONTRACT_FORM.NON_UNION_TALENT_AGRMNT ||
          contractData?.contractName === CONTRACT_FORM.NON_UNION_PER_AGRMNT_ON_CAM_PRI_TALENT_AGRMNT ||
          contractData?.contractName === CONTRACT_FORM.NON_UNION_PER_AGRMNT_OFF_CAM_PRI ||
          contractData?.contractName === CONTRACT_FORM.NON_UNION_PRINT_MODEL_AGREEMENT || contractData?.contractName === CONTRACT_FORM.NON_UNION_TALENT_AGREEMENT_ON_CAMERA_PRINCIPAL
				|| contractData?.contractName === CONTRACT_FORM.NON_UNION_TALENT_AGREEMENT_EXTRA){
					multipleContractData = {
						contractAddNonUnionModels: contractAddModels,
					};
				}else if(contractData?.contractName === CONTRACT_FORM.MSA_TEMPLATE_THREE
          || contractData?.contractName === CONTRACT_FORM.CREATOR_AGREEMENT
          || contractData?.contractName === CONTRACT_FORM.INFLUENCER_AGREEMENT
				){
					multipleContractData = {
						contractAddMSAModels: contractAddModels
					};
				}else if(contractData?.contractName === CONTRACT_FORM.SAG_AFTRA_TV_EXTRA_HIGHLAND_TALENT){
					multipleContractData = {
						ContractAddSagAftraTvExtraHTModels: contractAddModels
					};
				}else if(contractData?.contractName === CONTRACT_FORM.TEAM_AFTRA_SD_EMP_CTR_IND_EDU_FILM_VIDEO_PGM){
					multipleContractData = {
						ContractAddTeamSagAftraCOEDModels: contractAddModels
					};
				}else if(contractData?.contractName === CONTRACT_FORM.SAG_AFTRA_AUDIO_COMMERCIAL_TEAMS){
					multipleContractData = {
						contractAddTeamSagAftraSdAudComModels: contractAddModels
					};
				}else if(contractData?.contractName === CONTRACT_FORM.SAG_AFTRA_SD_EXT_COM_TEAMS){
					multipleContractData = {
						contractAddTeamSagAftraSdExtComModels: contractAddModels
					};
				}

				addMultipleContractDetails(multipleContractData);
			}
		};
		handleMultipleContractAdd();
	};

	const getContractCombinedDetails = () => {
		return {
			...contractData,
			...(getNestedContractDetail(contractData) || {}),
		};
	};

	const getContractDetail = (baseContract: any) => {
		switch(baseContract?.contractName){
		case CONTRACT_FORM.SAG_AFTRA_SD_EMP_CTR_PRI_COM_EX1_Y22: return getContractStandardSagAftraEmployment2022FormData();
		case CONTRACT_FORM.SAG_AFTRA_SD_EMP_CTR_COM_PRI_PER_Y22: return getSagAftraSdEmpCtrComPriPerY22FormData();
		case CONTRACT_FORM.AFTRA_SD_EMP_CTR_IND_EDU_FILM_VIDEO_PGM: return getSagAftraSdEmpCtrIndEduFilmVideoPgmFormData();
		case CONTRACT_FORM.SAG_AFTRA_COED : return getSagAftraCOEDFormData();
		case CONTRACT_FORM.SAG_AFTRA_TV_PRINCIPAL: return getContractStandardSagAftraTvPrincipalFormData();
		case CONTRACT_FORM.SAG_AFTRA_SD_EXT_COM_EX2_Y22: return getContractSagAftraSdExtComEx2Y22FormData();
		case CONTRACT_FORM.SAG_AFTRA_SD_AUD_COM: return getContractSagAftraSdAudComFormData();
		case CONTRACT_FORM.SAG_AFTRA_SD_ACS_AUD_COM_Y22: return getContractSagAftraSdAudComFormData();
		case CONTRACT_FORM.SAG_AFTRA_AUDIO_COMMERCIALS_HIGHLAND_TALENT: return getContractSagAftraAudioComHTFormData();
		case CONTRACT_FORM.NON_UNION_PER_AGRMNT_ON_CAM_PRI: return getContractNonUnionFormData();
		case CONTRACT_FORM.NON_UNION_TALENT_AGRMNT: return getContractNonUnionFormData();
		case CONTRACT_FORM.NON_UNION_PER_AGRMNT_ON_CAM_PRI_TALENT_AGRMNT: return getContractNonUnionFormData();
		case CONTRACT_FORM.NON_UNION_PER_AGRMNT_OFF_CAM_PRI: return getContractNonUnionFormData();
		case CONTRACT_FORM.MSA_TEMPLATE_THREE: return getContractMSAFormFormData();
		case CONTRACT_FORM.CREATOR_AGREEMENT: return getContractMSAFormFormData();
		case CONTRACT_FORM.INFLUENCER_AGREEMENT: return getContractMSAFormFormData();
		case CONTRACT_FORM.SAG_AFTRA_TV_EXTRA_HIGHLAND_TALENT: return getContractSagAftraTvExtraHTFormData();
		case CONTRACT_FORM.NON_UNION_TALENT_AGREEMENT_VOICE_OVER: return getContractNonUnionFormData();
		case CONTRACT_FORM.NON_UNION_PRINT_MODEL_AGREEMENT: return getContractNonUnionFormData();
		case CONTRACT_FORM.NON_UNION_TALENT_AGREEMENT_ON_CAMERA_PRINCIPAL: return getContractNonUnionFormData();
		case CONTRACT_FORM.TEAM_AFTRA_SD_EMP_CTR_IND_EDU_FILM_VIDEO_PGM: return getTeamSagAftraCOEDFormData();
		case CONTRACT_FORM.SAG_AFTRA_AUDIO_COMMERCIAL_TEAMS: return getContractTeamSagAftraSdAudComFormData();
		case CONTRACT_FORM.SAG_AFTRA_SD_EXT_COM_TEAMS: return getContractTeamSagAftraSdExtCom();
		case CONTRACT_FORM.NON_UNION_TALENT_AGREEMENT_EXTRA: return getContractNonUnionFormData();
		}

		return {} as any;
	};

	const [noOfTalentsSelected, setNoOfTalentsSelected] =
		useState<boolean>(false);

	const handleTalentDetailsFormState = (data) => {
		setNoOfTalentsSelected(false);
		handleTalentDetailsForm(data);
	};

	const handleSaveAndDuplicate = () => {
		setNoOfTalentsSelected(true);
	};

	useEffect(() => {
		if(contractData?.status && contractForm.watch('status') !== contractData?.status){
			contractForm.setValue('status', contractData?.status, { shouldDirty: true});
		}
	}, [contractData?.status]);

	return (
		<>
			<>
				<Box>
					<div>
						<div className={stylesBlueSection.profileInfoSection}>
							<Grid container item columnSpacing={3}>
								<Grid item xs={12} md={3}>
									<div className={stylesBlueSection.contyractInfo}>
										<span>Talent name</span>
										<TextWrap className={stylesBlueSection.txtDetail} title={getPersonFullName(contractData?.performer?.personName)}/>
									</div>
								</Grid>
								{/* <Grid item xs={12} md={3}>
								<div className={styles.contyractInfo}>
									<span>Talent Id</span>
									<div className={styles.wrapInsidee}>
									-
									</div>
								</div>
							</Grid> */}
								<Grid item xs={12} md={3}>
									<div className={stylesBlueSection.contyractInfo}>
										<span>Contract Name </span>
										<div className={stylesBlueSection.wrapInsidee}>
											<TextWrap
												title={contractData?.contractDisplayName || ''}
												maxLength={25}
											/>
										</div>
									</div>
								</Grid>
								<Grid item xs={12} md={2}>
									<div className={stylesBlueSection.contyractInfo}>
										<span>Contract Number</span># 
										{contractData?.contractId}
									</div>
								</Grid>
								<Grid item xs={12} md={1} dir='rtl'>
									{/* <div className={styles.editInfoContract}>
									<NabuButton className={styles.editInfo} variant='text'>
										<Edit />
									</NabuButton>
								</div> */}
								</Grid>
							</Grid>
							<Grid container item columnSpacing={3}>
								<Grid item xs={12} md={3}>
									<div className={stylesBlueSection.contyractInfo}>
										<span>Contract Type </span>
										<TextWrap title={contractData?.contractType || '-'}/>
									</div>
								</Grid>
								<Grid item xs={12} md={3}>
									<div className={stylesBlueSection.contyractInfo}>
										<span>Term</span>
										<div className={stylesBlueSection.wrapInsidee}>
											{contractData?.expirationDate && getLocaleDate(contractData?.expirationDate)}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} md={3}>
									<div className={stylesBlueSection.contyractInfo}>
										<span>Compensation</span>
										<TextWrap className={stylesBlueSection.txtDetail} title={getContractCombinedDetails()?.compensation || '-'}/>
									</div>
								</Grid>
								<Grid item xs={12} md={3}>
									<div className={stylesBlueSection.contyractInfo}>
										<span>Exclusivity</span>
										<div className={stylesBlueSection.wrapInsidee}>
											<TextWrap title={getContractCombinedDetails()?.exclusivity || '-'} maxLength={25}/>
										</div>
									</div>
								</Grid>
							</Grid>
							<Grid container item columnSpacing={3}>
								<Grid item xs={12} md={3}>
									<div className={stylesBlueSection.contyractInfo}>
										<span>Advertiser </span>
										<div className={stylesBlueSection.wrapInsidee}>
											<TextWrap
												title={
													getCompanyDisplayName(
														contractData?.advertiser?.companyNames
													) || ''
												}
												maxLength={25}
											/>
										</div>
									</div>
								</Grid>
								<Grid item xs={12} md={3}>
									<div className={stylesBlueSection.contyractInfo}>
										<span>AD Agency</span>
										<div className={stylesBlueSection.wrapInsidee}>
											<TextWrap
												title={
													getCompanyDisplayName(
														contractData?.adAgencyData?.companyNames
													) || '-'
												}
												maxLength={25}
											/>
										</div>
									</div>
								</Grid>
								<Grid item xs={12} md={3}>
									<div className={stylesBlueSection.contractProfileLink}>
										<span>Asset</span>
										<div className={stylesBlueSection.wrapInsidee}>
											{getCommercialDetails(getContractCombinedDetails())}
										</div>
									</div>
								</Grid>
								<Grid item xs={12} md={3}>
									<div className={stylesBlueSection.contyractInfo}>
										<span>Signatory </span>
										<div className={stylesBlueSection.wrapInsidee}>
									-
										</div>
									</div>
								</Grid>
							</Grid>
						</div>
					</div>
					{contractData?.contractType === 'MSA' && <><h4 className={styles.headingDueWIndow}>Installments Due</h4>
						<div className={styles.contractProfileDashboardcard}>
							<div className={styles.header}>
								<Grid item container columnSpacing={3} >
									<Grid item sm={12} md={6} lg={4} >
										<div className={styles.assetExpInfoDashboard} data-cy='asset_exp'>
											<div className={styles.title}>Installment Number</div>
										</div>
									</Grid>
									<Grid item sm={12} md={6} lg={4} >
										<div className={styles.assetExpInfoDashboard} data-cy='asset_exp'>
											<div data-cy='ein' className={styles.title}>Amount</div>
										</div>
									</Grid>
									<Grid item sm={12} md={6} lg={4}>
										<div className={styles.assetExpInfoDashboard} data-cy='asset_exp'>
											<div data-cy='ein' className={styles.title}>Due Date</div>
										</div>
									</Grid>
								</Grid>
							</div>
							<div className={styles.evenRow}>
								<Grid item container columnSpacing={3} >
									<Grid item sm={12} md={6} lg={4} >
										<div className={styles.value} data-cy='asset_exp'>
											{contractData?.installmentDueRecord?.instalmentNumber}
										</div>
									</Grid>
									<Grid item sm={12} md={6} lg={4} >
										<div className={styles.value} data-cy='asset_exp'>
											{contractData?.installmentDueRecord?.instalmentAmount && `$${contractData?.installmentDueRecord?.instalmentAmount}`}
										</div>
									</Grid>
									<Grid item sm={12} md={6} lg={4}>
										<div className={styles.value} data-cy='asset_exp'>
											{displayDate(contractData?.installmentDueRecord?.dueDate)}
										</div>
									</Grid>
								</Grid>
							</div>
						</div></>}
					<br></br>
					<br></br>
				</Box>
				<Accordion defaultExpanded>
					<AccordionSummary
						expandIcon={<ExpandMore />}
						aria-controls='panel3-content'
						id='panel3-header'
					>
						<Typography className={styles.componentHeader}>
								Contract
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						{ !(!contractData?.id || !!(contractFormState?.isOpen && contractFormState?.selectedData)) && (
							<div className={styles.statusButtonSectionContract}>
								<div>
									<NabuButton
										disabled={!contractData?.id || !!(contractFormState?.isOpen && contractFormState?.selectedData)}
										variant='filled'
										onClick={handleStatusEdit}
									>
										<ListAlt /> Status
									</NabuButton>
								</div>
								<div>
									<NabuButton
										disabled={!contractData?.id || !!(contractFormState?.isOpen && contractFormState?.selectedData)}
										variant='filled'
										onClick={handleEdit}
									>
										<Edit /> Edit
									</NabuButton>
								</div>
								<div>
									<NabuButton
										disabled={!contractData?.id || !!(contractFormState?.isOpen && contractFormState?.selectedData)}
										variant='filled'
										onClick={() => handleGeneratePdf()}
									>
										<Download /> Download
									</NabuButton>
								</div>
								<Tooltip title={!contractData?.contractPdfBlobName ? 'PDF file of the contract not available to send with email. Please generate the PDF of the contract first.' : ''} >
									<div>
										<NabuButton
											disabled={contractFormState?.isOpen || !contractData?.contractPdfBlobName}
											variant='filled'
											onClick={handleEmailIconClick}
										>
											<ForwardToInboxIcon />  Send Email
										</NabuButton>
									</div>
								</Tooltip>
								<div>
									<NabuButton
										variant='filled'
										onClick={handleSaveAndDuplicate}					
									>
					Duplicate <CopyAll />
									</NabuButton>
								</div>
								<div>
									<NabuButton
										disabled={contractFormState?.isOpen}
										variant='filled'
										onClick={handleCancelIconClick}
									>
										<Cancel /> Void Cancel
									</NabuButton>
								</div>
							</div>
						)
						}
						{contractData?.id && (
							<div>
								<div ref={reportTemplateRef} id='contract-main-form-wrapper'>	
									<PDFExport paperSize={'A4'} margin={'2pt'} fileName={`${contractData?.contractName}-contract.pdf`} ref={pdfExportComponent}>
										{
											isFormVisible(CONTRACT_FORM.SAG_AFTRA_SD_EMP_CTR_PRI_COM_EX1_Y22) && (
												<StandardSagAftraEmployment2022
													ref={ref}
													contractId={contractData?.id}
													initialValue={getContractStandardSagAftraEmployment2022FormData()}
													key={`contract-StandardSagAftraEmployment2022-${contractFormState?.isOpen}`}
													isPrint={!contractFormState?.isOpen}
													handlePrintStateOfContract={handlePrintStateOfContract}
													isEdit={
														!!(contractFormState?.isOpen && contractFormState?.selectedData)
													}
													// reportFileGeneration={handleContractPdfSave}
													onSave={onSaveContract}
													onCancel={onCloseContract}
													assets={contractData?.contractSagAftraSdEmpCtrPriComEx1Y22?.commercials || []}
													address={
														contractData?.contractSagAftraSdEmpCtrPriComEx1Y22?.performerAdAgencyAddressData && [
															contractData?.contractSagAftraSdEmpCtrPriComEx1Y22?.performerAdAgencyAddressData,
														]
													}
													// updateContractBlob={updateContractBlob}
													people={contractData?.performer && [contractData?.performer]}
												/>
											)
										}
										{
											isFormVisible(CONTRACT_FORM.SAG_AFTRA_SD_EMP_CTR_COM_PRI_PER_Y22) && (
												<SagAftraSdEmpCtrComPriPerY22
													ref={ref}
													contractId={contractData?.id}
													initialValue={getSagAftraSdEmpCtrComPriPerY22FormData()}
													isPrint={!contractFormState?.isOpen}
													isEdit={
														!!(contractFormState?.isOpen && contractFormState?.selectedData)
													}
													key={`contract-SagAftraSdEmpCtrComPriPerY22-${contractFormState?.isOpen}`}
													handlePrintStateOfContract={handlePrintStateOfContract}
													onSave={onSaveContract}
													onCancel={onCloseContract}
													// reportFileGeneration={handleContractPdfSave}
													assets={contractData?.contractSagAftraSdEmpCtrComPriPerY22?.commercials || []}
													address={
														contractData?.contractSagAftraSdEmpCtrComPriPerY22?.adAgencyAddressData && [
															contractData?.contractSagAftraSdEmpCtrComPriPerY22?.adAgencyAddressData,
														]
													}
													// updateContractBlob={updateContractBlob}
													people={contractData?.performer && [contractData?.performer]}
												/>
											)
										}
										{
											isFormVisible(CONTRACT_FORM.AFTRA_SD_EMP_CTR_IND_EDU_FILM_VIDEO_PGM) && (
												<SagAftraSdEmpCtrIndEduFilmVideoPgm
													ref={ref}
													contractId={contractData?.id}
													initialValue={getSagAftraSdEmpCtrIndEduFilmVideoPgmFormData()}
													isPrint={!contractFormState?.isOpen}
													// reportFileGeneration={handleContractPdfSave}
													isEdit={
														!!(contractFormState?.isOpen && contractFormState?.selectedData)
													}
													key={`contract-SagAftraSdEmpCtrIndEduFilmVideoPgm-${contractFormState?.isOpen}`}
													handlePrintStateOfContract={handlePrintStateOfContract}
													onSave={onSaveContract}
													onCancel={onCloseContract}
													address={
														contractData?.contractSagAftraSdEmpCtrComPriPerY22?.adAgencyAddressData && [
															contractData?.contractSagAftraSdEmpCtrComPriPerY22?.adAgencyAddressData,
														]
													}
													people={contractData?.performer && [contractData?.performer]}
													// updateContractBlob={updateContractBlob}
												/>
											)
										}
										{
											isFormVisible(CONTRACT_FORM.SAG_AFTRA_COED) && (
												<SagAftraCOED
													ref={ref}
													contractId={contractData?.id}
													initialValue={getSagAftraCOEDFormData()}
													isPrint={!contractFormState?.isOpen}
													// reportFileGeneration={handleContractPdfSave}
													isEdit={
														!!(contractFormState?.isOpen && contractFormState?.selectedData)
													}
													key={`contract-SagAftraCOED-${contractFormState?.isOpen}`}
													handlePrintStateOfContract={handlePrintStateOfContract}
													onSave={onSaveContract}
													onCancel={onCloseContract}
													address={
														contractData?.contractSagAftraCOED?.adAgencyAddressData && [
															contractData?.contractSagAftraCOED?.adAgencyAddressData,
														]
													}
													people={contractData?.performer && [contractData?.performer]}
													// updateContractBlob={updateContractBlob}
												/>
											)
										}
										{
											isFormVisible(CONTRACT_FORM.TEAM_AFTRA_SD_EMP_CTR_IND_EDU_FILM_VIDEO_PGM) && (
												<TeamSagAftraSdEmpCtrIndEduFilmVideoPgm
													contractId={contractData?.id}
													initialValue={getTeamSagAftraCOEDFormData()}
													isPrint={!contractFormState?.isOpen}
													// reportFileGeneration={handleContractPdfSave}
													isEdit={
														!!(contractFormState?.isOpen && contractFormState?.selectedData)
													}
													key={`contract-SagAftraCOED-${contractFormState?.isOpen}`}
													handlePrintStateOfContract={handlePrintStateOfContract}
													onSave={onSaveContract}
													onCancel={onCloseContract}
													address={
														contractData?.contractTeamSagAftraCOED?.adAgencyAddressData && [
															contractData?.contractTeamSagAftraCOED?.adAgencyAddressData,
														]
													}
													people={contractData?.performer && [contractData?.performer]}
													// updateContractBlob={updateContractBlob}
												/>
											)
										}
										{
											isFormVisible(CONTRACT_FORM.SAG_AFTRA_TV_PRINCIPAL) && (
												<SagAftraTvPrincipal
													ref={ref}
													contractId={contractData?.id}
													initialValue={getContractStandardSagAftraTvPrincipalFormData()}
													key={`contract-StandardSagAftraTvPrincipal-${contractFormState?.isOpen}`}
													isPrint={!contractFormState?.isOpen}
													handlePrintStateOfContract={handlePrintStateOfContract}
													isEdit={
														!!(contractFormState?.isOpen && contractFormState?.selectedData)
													}
													// reportFileGeneration={handleContractPdfSave}
													onSave={onSaveContract}
													onCancel={onCloseContract}
													assets={contractData?.contractSagAftraTvPrincipal?.commercials || []}
													address={
														contractData?.contractSagAftraTvPrincipal?.performerAdAgencyAddressData && [
															contractData?.contractSagAftraTvPrincipal?.performerAdAgencyAddressData,
														]
													}
													// updateContractBlob={updateContractBlob}
													people={contractData?.performer && [contractData?.performer]}
												/>
											)
										}
										{
											isFormVisible(CONTRACT_FORM.SAG_AFTRA_SD_EXT_COM_EX2_Y22) && (
												<SagAftraSdExtComEx2Y22
													ref={ref}
													contractId={contractData?.id}
													initialValue={getContractSagAftraSdExtComEx2Y22FormData()}
													isPrint={!contractFormState?.isOpen}
													isEdit={
														!!(contractFormState?.isOpen && contractFormState?.selectedData)
													}
													key={`contract-SagAftraSdExtComEx2Y22-${contractFormState?.isOpen}`}
													handlePrintStateOfContract={handlePrintStateOfContract}
													onSave={onSaveContract}
													onCancel={onCloseContract}
													// reportFileGeneration={handleContractPdfSave}
													address={[
														contractData?.contractSagAftraSdExtComEx2Y22?.adAgencyAddressData ?? {},
														contractData?.contractSagAftraSdExtComEx2Y22?.performerAddressData ?? {},
														contractData?.contractSagAftraSdExtComEx2Y22?.performerAdAgencyAddressData ?? {},
													] as ICompanyAddress[]}
													people={contractData?.performer && [contractData?.performer]}
													assets={contractData?.contractSagAftraSdExtComEx2Y22?.commercials || []}
													// updateContractBlob={updateContractBlob}
												/>
											)
										}
										{isFormVisible(CONTRACT_FORM.SAG_AFTRA_SD_AUD_COM) && (
											<SagAftraSdAudCom
												ref={ref}
												contractId={contractData?.id}
												initialValue={getContractSagAftraSdAudComFormData()}
												isPrint={!contractFormState?.isOpen}
												isEdit={
													!!(contractFormState?.isOpen && contractFormState?.selectedData)
												}
												key={`contract-SagAftraSdAudCom-${contractFormState?.isOpen}`}
												handlePrintStateOfContract={handlePrintStateOfContract}
												onSave={onSaveContract}
												onCancel={onCloseContract}
												// reportFileGeneration={handleContractPdfSave}
												address={[
													contractData?.contractSagAftraSdAudCom?.performerAddressData ?? {},
													contractData?.contractSagAftraSdAudCom?.adAgencyAddressData ?? {},
													contractData?.contractSagAftraSdAudCom?.performerAdAgencyAddressData ?? {},
													contractData?.contractSagAftraSdAudCom?.producerCompanyAddressData ?? {},
												] as ICompanyAddress[]}
												people={contractData?.performer && [contractData?.performer]}
												assets={contractData?.contractSagAftraSdAudCom?.commercials || []}
												// updateContractBlob={updateContractBlob}
											/>
										)}
										{isFormVisible(CONTRACT_FORM.SAG_AFTRA_AUDIO_COMMERCIALS_HIGHLAND_TALENT) && (
											<SagAftraForAudioCommercialsHT
												contractId={contractData?.id}
												initialValue={getContractSagAftraAudioComHTFormData()}
												isPrint={!contractFormState?.isOpen}
												isEdit={
													!!(contractFormState?.isOpen && contractFormState?.selectedData)
												}
												key={`contract-SagAftraAudioComHT-${contractFormState?.isOpen}`}
												handlePrintStateOfContract={handlePrintStateOfContract}
												onSave={onSaveContract}
												onCancel={onCloseContract}
												// reportFileGeneration={handleContractPdfSave}
												address={[
													contractData?.contractSagAftraAudioComHT?.performerAddressData ?? {},
													contractData?.contractSagAftraAudioComHT?.adAgencyAddressData ?? {},
													contractData?.contractSagAftraAudioComHT?.performerAdAgencyAddressData ?? {},
													contractData?.contractSagAftraAudioComHT?.producerCompanyAddressData ?? {},
												] as ICompanyAddress[]}
												people={contractData?.performer && [contractData?.performer]}
												assets={contractData?.contractSagAftraAudioComHT?.commercials || []}
												// updateContractBlob={updateContractBlob}
											/>
										)}
										{
											isFormVisible(CONTRACT_FORM.SAG_AFTRA_TV_EXTRA_HIGHLAND_TALENT) && (
												<SagAftraTvExtraHT
													contractId={contractData?.id}
													initialValue={getContractSagAftraTvExtraHTFormData()}
													isPrint={!contractFormState?.isOpen}
													isEdit={
														!!(contractFormState?.isOpen && contractFormState?.selectedData)
													}
													key={`contract-SagAftraTvExtraHT-${contractFormState?.isOpen}`}
													handlePrintStateOfContract={handlePrintStateOfContract}
													onSave={onSaveContract}
													onCancel={onCloseContract}
													// reportFileGeneration={handleContractPdfSave}
													address={[
														contractData?.contractSagAftraTvExtraHT?.adAgencyAddressData ?? {},
														contractData?.contractSagAftraTvExtraHT?.performerAddressData ?? {},
														contractData?.contractSagAftraTvExtraHT?.performerAdAgencyAddressData ?? {},
													] as ICompanyAddress[]}
													people={contractData?.performer && [contractData?.performer]}
													assets={contractData?.contractSagAftraTvExtraHT?.commercials || []}
													// updateContractBlob={updateContractBlob}
												/>
											)
										}
										{isFormVisible(CONTRACT_FORM.SAG_AFTRA_SD_ACS_AUD_COM_Y22) && (
											<SagAftraSdAcsAudComY22
												ref={ref}
												contractId={contractData?.id}
												initialValue={getContractSagAftraSdAudComFormData()}
												isPrint={!contractFormState?.isOpen}
												isEdit={
													!!(contractFormState?.isOpen && contractFormState?.selectedData)
												}
												key={`contract-SagAftraSdAudCom-${contractFormState?.isOpen}`}
												handlePrintStateOfContract={handlePrintStateOfContract}
												onSave={onSaveContract}
												onCancel={onCloseContract}
												// reportFileGeneration={handleContractPdfSave}
												address={[
													contractData?.contractSagAftraSdAudCom?.performerAddressData ?? {},
													contractData?.contractSagAftraSdAudCom?.adAgencyAddressData ?? {},
													contractData?.contractSagAftraSdAudCom?.performerAdAgencyAddressData ?? {},
													contractData?.contractSagAftraSdAudCom?.producerCompanyAddressData ?? {},
												] as ICompanyAddress[]}
												people={contractData?.performer && [contractData?.performer]}
												assets={contractData?.contractSagAftraSdAudCom?.commercials || []}
												// updateContractBlob={updateContractBlob}
											/>
										)}
										{isFormVisible(CONTRACT_FORM.SAG_AFTRA_AUDIO_COMMERCIAL_TEAMS) && (
											<TeamACSAudioContract
												contractId={contractData?.id}
												initialValue={getContractTeamSagAftraSdAudComFormData()}
												isPrint={!contractFormState?.isOpen}
												isEdit={
													!!(contractFormState?.isOpen && contractFormState?.selectedData)
												}
												key={`contract-TeamACSAudioContract-${contractFormState?.isOpen}`}
												handlePrintStateOfContract={handlePrintStateOfContract}
												onSave={onSaveContract}
												onCancel={onCloseContract}
												// reportFileGeneration={handleContractPdfSave}
												address={[
													contractData?.contractTeamSagAftraSdAudCom?.performerAddressData ?? {},
													contractData?.contractTeamSagAftraSdAudCom?.adAgencyAddressData ?? {},
													contractData?.contractTeamSagAftraSdAudCom?.performerAdAgencyAddressData ?? {},
													contractData?.contractTeamSagAftraSdAudCom?.producerCompanyAddressData ?? {},
												] as ICompanyAddress[]}
												people = {contractData?.performer && [contractData?.performer]}
												assets={contractData?.contractTeamSagAftraSdAudCom?.commercials || []}
												companies={[
													contractData?.contractTeamSagAftraSdAudCom?.loanOutCorp ?? {},
												]as ICompanyRecord[]}
											/>
										)}
										{isFormVisible(CONTRACT_FORM.SAG_AFTRA_SD_EXT_COM_TEAMS) && (
											<TeamExtraContract
												contractId={contractData?.id}
												initialValue={getContractTeamSagAftraSdExtCom()}
												isPrint={!contractFormState?.isOpen}
												isEdit={
													!!(contractFormState?.isOpen && contractFormState?.selectedData)
												}
												key={`contract-TeamACSAudioContract-${contractFormState?.isOpen}`}
												handlePrintStateOfContract={handlePrintStateOfContract}
												onSave={onSaveContract}
												onCancel={onCloseContract}
												// reportFileGeneration={handleContractPdfSave}
												address={[
													contractData?.contractTeamSagAftraSdExtCom?.performerAddressData ?? {},
													contractData?.contractTeamSagAftraSdExtCom?.adAgencyAddressData ?? {},
													contractData?.contractTeamSagAftraSdExtCom?.performerAdAgencyAddressData ?? {},
													contractData?.contractTeamSagAftraSdExtCom?.producerCompanyAddressData ?? {},
												] as ICompanyAddress[]}
												people = {contractData?.performer && [contractData?.performer]}
												assets={contractData?.contractTeamSagAftraSdExtCom?.commercials || []}
												companies={[
													contractData?.contractTeamSagAftraSdExtCom?.loanOutCorp ?? {},
												]as ICompanyRecord[]}
											/>
										)}
										{/* Non Union */}
										{isFormVisible(CONTRACT_FORM.NON_UNION_PER_AGRMNT_OFF_CAM_PRI) && (
											<NonUnionPerAgrmntOffCamPri
												ref={ref}
												contractId={contractData?.id}
												initialValue={getContractNonUnionFormData()}
												key={`contract-NonUnionPerAgrmntOffCamPri-${contractFormState?.isOpen}`}
												handlePrintStateOfContract={handlePrintStateOfContract}
												isPrint={!contractFormState?.isOpen}
												isEdit={
													!!(contractFormState?.isOpen && contractFormState?.selectedData)
												}
												onSave={onSaveContract}
												onCancel={onCloseContract}
												// reportFileGeneration={handleContractPdfSave}
												address={[
													contractData?.contractNonUnion?.performerAddressData ?? {},
													contractData?.contractNonUnion?.adAgencyAddressData ?? {},
												] as ICompanyAddress[]}
												people={contractData?.performer && [contractData?.performer]}
												// updateContractBlob={updateContractBlob}
											/>
										)}
										{isFormVisible(CONTRACT_FORM.NON_UNION_PER_AGRMNT_ON_CAM_PRI) && (
											<NonUnionPerAgrmntOnCamPri
												ref={ref}
												contractId={contractData?.id}
												initialValue={getContractNonUnionFormData()}
												isPrint={!contractFormState?.isOpen}
												isEdit={
													!!(contractFormState?.isOpen && contractFormState?.selectedData)
												}
												key={`contract-NonUnionPerAgrmntOnCamPri-${contractFormState?.isOpen}`}
												handlePrintStateOfContract={handlePrintStateOfContract}
												onSave={onSaveContract}
												onCancel={onCloseContract}
												// reportFileGeneration={handleContractPdfSave}
												address={[
													contractData?.contractNonUnion?.performerAddressData ?? {},
													contractData?.contractNonUnion?.adAgencyAddressData ?? {},
												] as ICompanyAddress[]}
												people={contractData?.performer && [contractData?.performer]}
												// updateContractBlob={updateContractBlob}
											/>
										)}
										{isFormVisible(CONTRACT_FORM.NON_UNION_TALENT_AGRMNT) && (
											<NonUnionTalentAgreement
												ref={ref}
												contractId={contractData?.id}
												initialValue={getContractNonUnionFormData()}
												isPrint={!contractFormState?.isOpen}
												isEdit={
													!!(contractFormState?.isOpen && contractFormState?.selectedData)
												}
												key={`contract-NonUnionTalentAgreement-${contractFormState?.isOpen}`}
												handlePrintStateOfContract={handlePrintStateOfContract}
												onSave={onSaveContract}
												onCancel={onCloseContract}
												// reportFileGeneration={handleContractPdfSave}
												address={[
													contractData?.contractNonUnion?.performerAddressData ?? {},
													contractData?.contractNonUnion?.adAgencyAddressData ?? {},
													contractData?.contractNonUnion?.talentAgencyAddressData ?? {},
												] as ICompanyAddress[]}
												people={contractData?.performer && [contractData?.performer]}
												assets={contractData?.contractSagAftraSdAudCom?.commercials || []}
												// updateContractBlob={updateContractBlob}
											/>
										)}						
										{isFormVisible(CONTRACT_FORM.NON_UNION_PER_AGRMNT_ON_CAM_PRI_TALENT_AGRMNT) && (
											<NonUnionPerAgrmntOnCamPriTalentAgrmnt
												ref={ref}
												contractId={contractData?.id}
												initialValue={getContractNonUnionFormData()}
												isPrint={!contractFormState?.isOpen}
												isEdit={
													!!(contractFormState?.isOpen && contractFormState?.selectedData)
												}
												key={`contract-NonUnionPerAgrmntOnCamPriTalentAgrmnt-${contractFormState?.isOpen}`}
												handlePrintStateOfContract={handlePrintStateOfContract}
												onSave={onSaveContract}
												onCancel={onCloseContract}
												// reportFileGeneration={handleContractPdfSave}
												address={[
													contractData?.contractNonUnion?.performerAddressData ?? {},
													contractData?.contractNonUnion?.adAgencyAddressData ?? {},
													contractData?.contractNonUnion?.talentAgencyAddressData ?? {},
												] as ICompanyAddress[]}
												people={contractData?.performer && [contractData?.performer]}
												assets={contractData?.contractSagAftraSdAudCom?.commercials || []}
												// updateContractBlob={updateContractBlob}
											/>
										)}
										{isFormVisible(CONTRACT_FORM.NON_UNION_TALENT_AGREEMENT_ON_CAMERA_PRINCIPAL) && (
											<NonUnionTalentAgreementOnCameraPrincipal
												ref={ref}
												contractId={contractData?.id}
												initialValue={getContractNonUnionFormData()}
												isPrint={!contractFormState?.isOpen}
												isEdit={
													!!(contractFormState?.isOpen && contractFormState?.selectedData)
												}
												key={`contract-NonUnionTalentAgreementOnCamera-${contractFormState?.isOpen}`}
												handlePrintStateOfContract={handlePrintStateOfContract}
												onSave={onSaveContract}
												onCancel={onCloseContract}
												address={[
													contractData?.contractNonUnion?.performerAddressData ?? {},
													contractData?.contractNonUnion?.adAgencyAddressData ?? {},
													contractData?.contractNonUnion?.talentAgencyAddressData ?? {},
												] as ICompanyAddress[]}
												people={contractData?.performer && [contractData?.performer]}
												assets={contractData?.contractNonUnion?.commercials || []}
											/>
										)}						
										{isFormVisible(CONTRACT_FORM.NON_UNION_TALENT_AGREEMENT_VOICE_OVER) && (
											<NonUnionTalentAgreementVoiceOver
												contractId={contractData?.id}
												initialValue={getContractNonUnionFormData()}
												isPrint={!contractFormState?.isOpen}
												isEdit={
													!!(contractFormState?.isOpen && contractFormState?.selectedData)
												}
												key={`contract-NonUnionTalentAgreementOnCamera-${contractFormState?.isOpen}`}
												handlePrintStateOfContract={handlePrintStateOfContract}
												onSave={onSaveContract}
												onCancel={onCloseContract}
												address={[
													contractData?.contractNonUnion?.performerAddressData ?? {},
													contractData?.contractNonUnion?.adAgencyAddressData ?? {},
													contractData?.contractNonUnion?.talentAgencyAddressData ?? {},
												] as ICompanyAddress[]}
												people={contractData?.performer && [contractData?.performer]}
												assets={contractData?.contractNonUnion?.commercials || []}
											/>
										)}						
										{isFormVisible(CONTRACT_FORM.NON_UNION_PRINT_MODEL_AGREEMENT) && (
											<NonUnionTalentPrintModel
												contractId={contractData?.id}
												initialValue={getContractNonUnionFormData()}
												isPrint={!contractFormState?.isOpen}
												isEdit={
													!!(contractFormState?.isOpen && contractFormState?.selectedData)
												}
												key={`contract-NonUnionTalentAgreementOnCamera-${contractFormState?.isOpen}`}
												handlePrintStateOfContract={handlePrintStateOfContract}
												onSave={onSaveContract}
												onCancel={onCloseContract}
												address={[
													contractData?.contractNonUnion?.performerAddressData ?? {},
													contractData?.contractNonUnion?.adAgencyAddressData ?? {},
													contractData?.contractNonUnion?.talentAgencyAddressData ?? {},
												] as ICompanyAddress[]}
												people={contractData?.performer && [contractData?.performer]}
												assets={contractData?.contractNonUnion?.commercials || []}
											/>
										)}						
										{isFormVisible(CONTRACT_FORM.NON_UNION_TALENT_AGREEMENT_EXTRA) && (
											<NonUnionTalentAgreementExtra
												ref={ref}
												contractId={contractData?.id}
												initialValue={getContractNonUnionFormData()}
												isPrint={!contractFormState?.isOpen}
												isEdit={
													!!(contractFormState?.isOpen && contractFormState?.selectedData)
												}
												key={`contract-NonUnionTalentAgreementOnCamera-${contractFormState?.isOpen}`}
												handlePrintStateOfContract={handlePrintStateOfContract}
												onSave={onSaveContract}
												onCancel={onCloseContract}
												address={[
													contractData?.contractNonUnion?.performerAddressData ?? {},
													contractData?.contractNonUnion?.adAgencyAddressData ?? {},
													contractData?.contractNonUnion?.talentAgencyAddressData ?? {},
												] as ICompanyAddress[]}
												people={contractData?.performer && [contractData?.performer]}
												assets={contractData?.contractNonUnion?.commercials || []}
											/>
										)}						
										{isFormVisible(CONTRACT_FORM.INFLUENCER_AGREEMENT) && (
											<MsaInfluenceAgreement
												ref={ref}
												contractId={contractData?.id}
												initialValue={getContractMSAFormFormData()}
												key={`contract-MsaInfluenceAgreement-${contractFormState?.isOpen}`}
												handlePrintStateOfContract={handlePrintStateOfContract}
												isPrint={!contractFormState?.isOpen}
												isEdit={
													!!(contractFormState?.isOpen && contractFormState?.selectedData)
												}
												onSave={onSaveContract}
												onCancel={onCloseContract}
												// reportFileGeneration={handleContractPdfSave}
												address={[
													contractData?.contractMSA?.adAgencyAddressData ?? {},
													contractData?.contractMSA?.talentAgencyAddressData ?? {},
												] as ICompanyAddress[]}
												people={contractData?.performer && [contractData?.performer]}
												// updateContractBlob={updateContractBlob}
											/>
										)}
										{isFormVisible(CONTRACT_FORM.CREATOR_AGREEMENT) && (
											<MsaCreatorAgreement
												ref={ref}
												contractId={contractData?.id}
												key={`contract-MsaCreatorAgreement-${contractFormState?.isOpen}`}
												handlePrintStateOfContract={handlePrintStateOfContract}
												initialValue={getContractMSAFormFormData()}
												isPrint={!contractFormState?.isOpen}
												isEdit={
													!!(contractFormState?.isOpen && contractFormState?.selectedData)
												}
												onSave={onSaveContract}
												onCancel={onCloseContract}
												// reportFileGeneration={handleContractPdfSave}
												address={[
													contractData?.contractMSA?.adAgencyAddressData ?? {},
													contractData?.contractMSA?.talentAgencyAddressData ?? {},
													contractData?.contractMSA?.lenderCompanyAddressData ?? {},
													contractData?.contractMSA?.lenderCompanyAddressData ?? {},
													contractData?.contractMSA?.onBehalfOfCompanyAddressData ?? {},
												] as ICompanyAddress[]}
												people={contractData?.performer && [contractData?.performer]}
												// updateContractBlob={updateContractBlob}
											/>
										)}
										{isFormVisible(CONTRACT_FORM.MSA_TEMPLATE_THREE) && (
											<MsaTemplateThree
												ref={ref}
												contractId={contractData?.id}
												initialValue={getContractMSAFormFormData()}
												isPrint={!contractFormState?.isOpen}
												isEdit={
													!!(contractFormState?.isOpen && contractFormState?.selectedData)
												}
												key={`contract-MsaTemplateThree-${contractFormState?.isOpen}`}
												handlePrintStateOfContract={handlePrintStateOfContract}
												onSave={onSaveContract}
												onCancel={onCloseContract}
												// reportFileGeneration={handleContractPdfSave}
												address={[
													contractData?.contractMSA?.adAgencyAddressData ?? {},
													contractData?.contractMSA?.talentAgencyAddressData ?? {},
													contractData?.contractMSA?.lenderCompanyAddressData ?? {},
													contractData?.contractMSA?.lenderCompanyAddressData ?? {},
													contractData?.contractMSA?.onBehalfOfCompanyAddressData ?? {},
												] as ICompanyAddress[]}
												people={contractData?.performer && [contractData?.performer]}
												// updateContractBlob={updateContractBlob}
											/>
										)}
									</PDFExport>						
								</div>
							</div>
						)}
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMore />}
						aria-controls='panel3-content'
						id='panel3-header'
					>
						<Typography className={styles.componentHeader}>
								Compensation
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<Typography>
							<ContractCompensation />
						</Typography>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMore />}
						aria-controls='panel1-content'
						id='panel1-header'
					>
						<Typography className={styles.componentHeader}>
								Use Rights / Terms & Territories
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<ContractUseRightTerritoryAndRenewal 
							isRenewalSection={false}
						/>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMore />}
						aria-controls='panel5-content'
						id='panel5-header'
					>
						<Typography className={styles.componentHeader}>
								Special Provisions
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<ContractNoteForm noteType='Special Provisions' />
					</AccordionDetails>
				</Accordion>
				{/*<Accordion>
						<AccordionSummary
							expandIcon={<ExpandMoreIcon />}
							aria-controls='panel5-content'
							id='panel5-header'
						>
							<Typography className={styles.componentHeader}>
								{Contract_Note_Type.FREE_FORM}
							</Typography>
						</AccordionSummary>
						<AccordionDetails>
							<ContractNoteForm noteType='Free Form' />
						</AccordionDetails>
					</Accordion>*/}
				{/*Sudheer Suggested to remove this Free form*/}
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMore />}
						aria-controls='panel6-content'
						id='panel6-header'
					>
						<Typography className={styles.componentHeader}>
								Renewal
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<ContractUseRightTerritoryAndRenewal 
							isRenewalSection={true}
						/>
					</AccordionDetails>
				</Accordion>
				<Accordion>
					<AccordionSummary
						expandIcon={<ExpandMore />}
						aria-controls='panel6-content'
						id='panel6-header'
					>
						<Typography className={styles.componentHeader}>
							{Contract_Note_Type.NOTES}
						</Typography>
					</AccordionSummary>
					<AccordionDetails>
						<ContractNoteForm noteType='Notes' />
					</AccordionDetails>
				</Accordion>
				<TalentDetailsForm
					handleTalentDetailsForm={(value) => handleTalentDetailsFormState(value)} 
					noOfTalentsSelected={noOfTalentsSelected}
					setNoOfTalentsSelected={(value) => setNoOfTalentsSelected(value)} 
					performerCategory={SagAftraAudioCommercialsPerformerTypeOptions}
				/>
				{/*<ContractOptionsRenewals/>*/}
			</>
			{/* { !(!contractData?.id || !!(contractFormState?.isOpen && contractFormState?.selectedData)) && (
				<div className={styles.statusButtonSectionContract}>
				
					<div>
						<NabuButton
							disabled={!contractData?.id || !!(contractFormState?.isOpen && contractFormState?.selectedData)}
							variant='filled'
							onClick={handleStatusEdit}
						>
							<ListAlt /> Status
						</NabuButton>
					</div>
					<div>
						<NabuButton
							disabled={!contractData?.id || !!(contractFormState?.isOpen && contractFormState?.selectedData)}
							variant='filled'
							onClick={handleEdit}
						>
							<Edit /> Edit
						</NabuButton>
					</div>
					<div>
						<NabuButton
							disabled={!contractData?.id || !!(contractFormState?.isOpen && contractFormState?.selectedData)}
							variant='filled'
							onClick={() => handleGeneratePdf()}
						>
							<Download /> Download
						</NabuButton>
					</div>
					<div>
						<NabuButton
							disabled={contractFormState?.isOpen}
							variant='filled'
							onClick={handleEmailIconClick}
						>
							<ForwardToInboxIcon />  Send Email
						</NabuButton>
					</div>
					<div>
						<NabuButton
							disabled={contractFormState?.isOpen}
							variant='filled'
							onClick={handleCancelIconClick}
						>
							<Cancel /> Cancel
						</NabuButton>
					</div>
				</div>)
			} */}
			<NabuDialog
				open={isEmailDialogOpen}
				handleClose={() => {handleEmailDialogClose();}}
				maxWidth='md'
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				title='Send Email' 
				dialogActions={sendEmailAction}>
				<Grid item lg={12} sx={{mb:1}} data-cy='add_packagingRequirements_wrapper'>
					<FormControl fullWidth>
						<FormLabel>To Mail <sup>*</sup></FormLabel>
						<Controller
							name={'toEmail'}
							control={sendEmailForm.control}
							render={({ field }) => (
								<NabuTextbox convertCase={'lowerCase'} placeholder='Enter Mail Address'
									data-cy='package_text_area_product' {...field}/>
							)}
						/>
						{ sendEmailForm.formState.errors.toEmail && (
							<FormHelperText data-cy='elementName_required' error={true}>
								{sendEmailForm.formState.errors.toEmail?.message}
							</FormHelperText>)
						}
					</FormControl>
				</Grid>
				<Grid item lg={6} sx={{mb:1}} data-cy='add_packagingRequirements_wrapper'>
					<FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
						<FormLabel id='demo-row-radio-buttons-group-label' data-cy='term_label'>
								Reason <sup>*</sup>
						</FormLabel>
						<Controller
							control={sendEmailForm.control}
							name='reason'
							render={({ field }) => (
								<RadioGroup
									row
									aria-labelledby='demo-row-radio-buttons-group-label'
									data-cy='term_group_checkbox'
									{...field}
								>
									<FormControlLabel
										value='Due Review'
										control={<Radio />}
										label='For Review'
										data-cy='yes_term'
									/>
									<FormControlLabel
										value='Due Signing'
										control={<Radio />}
										label='For Signing'
										data-cy='no_term'
									/>
								</RadioGroup>
							)}
						/>
						{ sendEmailForm.formState.errors.reason && (
							<FormHelperText data-cy='elementName_required' error={true}>
								{sendEmailForm.formState.errors.reason?.message}
							</FormHelperText>)
						}
					</FormControl>
				</Grid>
				<Grid item lg={6} sx={{mb:1}} data-cy='add_packagingRequirements_wrapper'>
					<FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
						<FormLabel id='demo-row-radio-buttons-group-label' data-cy='term_label'>
							Attach Gov Forms<sup></sup>
						</FormLabel>
						<Controller
							control={sendEmailForm.control}
							name='isGovFormNeeded'
							render={({ field }) => (
								<RadioGroup
									row
									aria-labelledby='demo-row-radio-buttons-group-label'
									data-cy='term_group_checkbox'
									{...field}
								>
									<FormControlLabel
										value={true}
										control={<Radio />}
										label='Yes'
										data-cy='yes_term'
									/>
									<FormControlLabel
										value={false}
										control={<Radio />}
										label='No'
										data-cy='no_term'
										onClick={() => sendEmailForm.setValue('govForms', [])}
									/>
								</RadioGroup>
							)}
						/>
					</FormControl>
				</Grid>
				{sendEmailForm?.watch('isGovFormNeeded')?.toString() === 'true' && <Grid item lg={6} sx={{mb:1}} data-cy='add_packagingRequirements_wrapper'>
					<FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
						<FormLabel id='demo-row-radio-buttons-group-label' data-cy='term_label'>
							Select government forms
						</FormLabel>
						<Controller
							control={sendEmailForm.control}
							name='govForms'
							render={({ field }) => (
								<NabuMultiselect
									{...field}
									keepDefaultCase={true}
									selectedItems={sendEmailForm.getValues().govForms ?? []}
									options={governmentFormOptions}
								/>
							)}
						/>
					</FormControl>
				</Grid>}
				<Divider sx={{mt:1}}/>
				<TextEditor initialValue={editorContent} onChange={handleEditorChange} />
			</NabuDialog>
			<NabuDialog
				open={assetDialogOpen?.isOpen}
				title='Assets'
				dialogActions={[]}
				handleClose={() =>
					setAssetDialogOpen({ isOpen: false })
				}
			>
				<ul>
					{assetDialogOpen?.data?.map((linkedAsset: any) => {
						return(
							<Link
								to={`/asset/${linkedAsset?.id}`}
								onClick={backNavigation.startBackNavigation}
								key={linkedAsset?.title}
							>
								<li key={linkedAsset?.title}>{`${linkedAsset?.title}`}</li>
							</Link>
						);
					})}
				</ul>
			</NabuDialog>
			<NabuDialog
				open={isStatusEditDialogOpen}
				handleClose={() => setStatusEditDialogOpen(false)}
				maxWidth='md'
				aria-labelledby="alert-dialog-title"
				aria-describedby="alert-dialog-description"
				title='Edit Status' 
				dialogActions={[]}>
				<Grid item lg={12} sx={{mb:1}} data-cy='add_packagingRequirements_wrapper'>
					<FormControl fullWidth>
						<FormLabel>
								Status <sup />
						</FormLabel>
						<Controller
							control={contractForm.control}
							name='status'
							render={({ field }) => (
								<>
									<NabuSelect
										{...field}
										placeholder='Select Status'
										options={ContractStatusOptions || []}
										convertValueToUpperCase={false}
										onChange={(e) => field.onChange(e)}
									/>
								</>
							)}
						/>
					</FormControl>
				</Grid>
				<Grid item lg={12}>
					<div style={{float:'right'}}>
						<NabuButton variant='filled' onClick={contractForm.handleSubmit(statusEditOnSubmit)}>
							<Save />
							{'Save'}
							
						</NabuButton>
						&nbsp;
						<NabuButton shouldHandleConfirmAction={contractForm.formState.isDirty} variant='outlined' onClick={handleStatusEditDialogClose}>Cancel</NabuButton>
					</div>
				</Grid>
			</NabuDialog>
			<NabuDialog
				title='Cancel contract ?'
				open={cancelContractDialogOpen}
				maxWidth='xs'
				handleClose={handleCancelContractDialogClose}
				dialogActions={cancelContractDialogActions}
				isPersistent={true}
			>
				<strong>Are you sure you want to cancel contract ?</strong>
			</NabuDialog>
		</>
	);
};

export default ContractProfileSection;
