import { createSlice } from '@reduxjs/toolkit';
import { IUserContextModel } from './types';

const initialState: IUserContextModel = {
	isAuthenticated: false,
	userInfo: {
		firstName: '',
		email: '',
		is7MilesClient: false,
		lastName: '',
		userId: '',
		tenantId: ''
	}
};

export const userContextSlice = createSlice({
	name: 'userContext',
	initialState,
	reducers: {
		setUserContext: (state, action) => {
			if (action.payload?.userAuthInfo) {
				localStorage.setItem('access_token', action.payload.userAuthInfo.token);
				localStorage.setItem('token_expiry', action.payload.userAuthInfo.tokenExpiryDate);
				localStorage.setItem('user_info', JSON.stringify(action.payload.userAuthInfo));
				state.isAuthenticated = true;
				state.userAuthInfo = {...action.payload.userAuthInfo, token: undefined};
			}
			state.userInfo = action.payload.userInfo;
		},
		setAuthStatus: (state, action) => {
			state.isAuthenticated = action.payload;
		},
		resetUserContext: (state) => {
			state.isAuthenticated = false;
			state.userInfo = initialState.userInfo as any;
			state.userAuthInfo = undefined;
			localStorage.removeItem('access_token');
			localStorage.removeItem('token_expiry');
		}
	}
});

export const { setUserContext, setAuthStatus, resetUserContext } = userContextSlice.actions;
export default userContextSlice.reducer;