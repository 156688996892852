import styles from './nestedTable.module.scss';
import { RightsAcquiredTableItem } from './rightsAcquiredTableItem';

const NestedTable = (props: any) => {
	
	return (
		!props.isEdit ? <div>
			<div className={styles.nestedTable}>
				<RightsAcquiredTableItem
					rightsContractedData={props}
					fetchRightsAcquired={props.fetchRightsAcquired}
					initialValue={props}
					for={props?.for}
				/>
			</div>
		</div> : <></>
	);
};

export default NestedTable;
