import { IFormPropsModel } from '@/common/utils/withContext';
import { UseFormReturn } from 'react-hook-form';

export enum AddressType {
    Permanent = 1,
    Temporary = 2
  }

export class StaffModel{
	companyId?:string='';
	person:any={id:''};
	staffName :ILookAheadSelectOption=new ILookAheadSelectOption();
	jobTitle :string='';
	isFreeLancer:boolean=false;
	isPrimaryStaff:boolean=false;
	status:string='ACTIVE';
	terminationDate: Date | ''; 
	effectiveDate: Date  | '';
	addressId: any = '';
	contactType?: string = '';
	phoneNumberIds: any[] = [];
	emailId: any = '';
	company: any = {};
	constructor() {
		this.terminationDate = ''; // Assign the default value in the constructor
		this.effectiveDate ='';
	}
}

export class ILookAheadSelectOption {
	value: string='';
	label: string='';
}
export class StaffFormModel {
	staffFormModel: StaffModel[] =[];
}

export class AddressModel{
	addressType?:string='';
	isTemporary?:boolean=false;
	line1:string='';
	line2?:string='';
	zipCode :string='';
	city :string='';
	state :string='';
	country :string='US';
	addressName?:string='';
	emailId?:string='';
}

export interface IStaffFormPropsModel extends  IFormPropsModel<StaffFormModel> {
    staffForm?: UseFormReturn<StaffFormModel, any>
	companyId?:string;
	handleStaffFormClose: () => void;
	getStaffListByCompany?:()=>void;
	onStaffCreated?: (createdStaff: any) => void
    formValues?: any;
    formType?: string;
}

export class PhoneNumberFormModel {
	countryCode?: string='1';//Initalize with US country code
	number?: number;
	extenstion?: number;
	phoneNumberLabel?: string = '';
	isPrimary?: boolean;
}
