import { ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import styles from './page.module.scss';
import NavigationBlocker from './navigationBlocker';
import { IStore } from '@/store';
import { useSelector } from 'react-redux';

interface Props {
	children: ReactNode;
	hasChild?: boolean;
	hasShadow?: boolean;
	SubHeader?: JSX.Element;
}

export const Page = (props: Props) => {
	const { children, SubHeader } = props;
	const isEditOpen = useSelector((state: IStore) => state.appContext?.isEditOpen);

	return (
		<>
			<NavigationBlocker blockNavigation={isEditOpen}/>
			<div className={styles.page}>
				{!!SubHeader && SubHeader}
				{props.hasChild ? (
					<div className={`${styles.pageContent} ${props.hasShadow ? styles.pageContentShadow : ''}`}>
						{children}
					</div>
				) : (
					<div className={styles.pageBody}>
						<Outlet />
					</div>
				)}
			</div>
		</>
	);
};