import NabuButton from '@/common/components/atoms/button/button';
import { NabuCardDefault } from '@/common/components/molecules/cardWhite/cardWhite';
import { StatusCodes } from '@/common/enums/StatusCodes';
import useNotification from '@/common/hooks/useNotification';
import { getNotificationText } from '@/common/utils/utils';
import PDFViewer from '@/modules/people/components/peopleInfo/components/tabs/pdfViewer';
import { getAssetById, submitCompletionReportThroughAsset } from '@/services/asset';
import { getBlobURL } from '@/services/company';
import AddIcon from '@mui/icons-material/Add';
import { HttpStatusCode } from 'axios';
import { useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { Link, useNavigate, useParams } from 'react-router-dom';

const CompletionReport = () => {
	const { assetId } = useParams();
	const notification = useNotification();
	const [completionReport, setCompletionReport] = useState(null as any);
	const [pdfURL, setPDFURL] = useState('');

	const { refetch: fetchAssetDetail } = useQuery({
		queryKey: ['assetProfile', assetId],
		queryFn: () => getAssetById(assetId ?? ''),
		refetchOnWindowFocus: false,
		onSuccess: async (res: any) => {
			if (res?.status === StatusCodes.OK) {
				setCompletionReport(res?.data?.assetCompletionReport);
				const blobResult = await getBlobURL(res?.data?.assetCompletionReport?.excelFileName);
				if (blobResult?.status === HttpStatusCode.Ok) {
					setPDFURL(blobResult?.data ?? '');
				}
			}
		},
		onError: () => {
			notification.notify({
				type: 'error',
				message: getNotificationText('Asset', 'FETCHING', true),
			});
		},
	});

	useEffect(() => {
		fetchAssetDetail();
	}, []);

	const navigate = useNavigate();

	const { mutate: submitAsset } = useMutation({
		mutationFn: submitCompletionReportThroughAsset,
		onSuccess: (res: any) => {
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: 'Asset submitted sucessfully',
					type: 'success'
				});

				navigate(`/completion-reports/${res?.data?.id ?? ''}/completion-report-profile`);
			}
			else {
				notification.notify({
					message: 'Error while submitting asset',
					type: 'error',
				});
			}
		},
		onError: () =>
			notification.notify({
				message: 'Error while submitting asset',
				type: 'error',
			})
	});

	return (
		<>
			{completionReport ? <Link to={`/completion-reports/${completionReport?.id ?? ''}/completion-report-profile`}>{`${completionReport?.completionReportPrefix || ''}${completionReport?.completionReportId || ''}` || ' -'}</Link> : <NabuButton variant='text' onClick={() => submitAsset(assetId ?? '')}
			><AddIcon/>Add</NabuButton>}
			{pdfURL && <NabuCardDefault>
				<div className="App">
					<PDFViewer pdfUrl={pdfURL ?? ''} documentName={'Completion Report Document'}/>
				</div>
			</NabuCardDefault>}
		</>
	);
};

export default CompletionReport;
