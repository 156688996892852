import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IPersonContext, ISelectedPerson } from './types';

const initialState: IPersonContext = {
	totalPeople: 0,
};

export const PersonContextSlice = createSlice({
	name: 'PersonContect',
	initialState,
	reducers: {		
		setTotalPerson: (state, action: PayloadAction<{totalPerson: number}>) => {
			state.totalPeople = action.payload.totalPerson;
		},
		setSelectedPerson: (state, action: PayloadAction<ISelectedPerson>) => {
			state.selectedPerson = action.payload;
		},
	}
});

export const { setTotalPerson, setSelectedPerson} = PersonContextSlice.actions;
export default PersonContextSlice.reducer;