import NabuButton from '@/common/components/atoms/button/button';
import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import LookAheadSelect from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';

import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import AddCompanyAddressInline from '@/common/components/templates/pages/add/addCompanyAddressInline';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonInline from '@/common/components/templates/pages/add/addPersonInline';
import {
	CONTRACT_REF_ID,
	ContractStatus,
	ContractStatusOptions,
} from '@/common/constants/constants';
import { OPERATORS } from '@/common/enums';
import useNotification from '@/common/hooks/useNotification';
import useScrollToField from '@/common/hooks/useScrollToField';
import {
	CompanyMainType,
	ICompanyAddress,
	ICompanyRecord,
} from '@/common/types/company';
import {
	IBaseContract,
	IContractMSAForm,
	IPacketControlForm,
} from '@/common/types/contract';
import { IFilterOption } from '@/common/types/filterModel';
import {
	displayAddress,
	displayOnlyEmail,
	getBaseUrl,
	getCompanyDisplayName,
	getNotificationText,
	getPersonFullName,
	mutateContractAdditionalFormsData,
	sortEmailOrPhoneNumberForDropDown,
} from '@/common/utils/utils';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import {
	getAllAddressByCompany,
	getAllStaffOfCompany,
	getCompanyBasicDetails,
} from '@/services/company';
import {
	addContractMSA,
	addMultipleContract,
	updateContractMSA,
} from '@/services/contract';
import { IPersonRecord } from '@/services/models/person';
import { GetByWithFullNameSearchForBasicDetail } from '@/services/person';
import store from '@/store';
import { updateContractFormState } from '@/store/contract/reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { CopyAll, NoteAdd, Save } from '@mui/icons-material';
import {
	Box,
	Checkbox,
	FormControl,
	FormHelperText,
	FormLabel,
	Grid,
	ListItemButton,
	ListItemText,
} from '@mui/material';
import { HttpStatusCode } from 'axios';
import { addDays, addMonths, addWeeks, addYears, format, isBefore } from 'date-fns';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import { ContractFormGeneralSchema } from '../contarctFormSchema';
import ContractCAWageTheftPreventionForm from '../contractCAWageyTheftPreventionForm';
import ContractMinorTrustForm from '../contractMinorTrustForm';
import ContractNYWageTheftPreventionForm from '../contractNYWageTheftPreventionForm';
import ContractW4ShortForm from '../contractW4ShortForm';
import ContractW8BenShortForm from '../contractW8BenShortForm';
import ContractW9BenShortForm from '../contractW9BenShortForm';
import TalentDetailsForm from '../talentDetailsForm';
import { IContractFormState } from '../unionForms/standardSagAftraEmployment2022';
import ExpiryDateForm from '../expiryDateForm';

interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	initialValue?: IContractMSAForm;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	defaultValue?: IBaseContract,
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
	isDuplicateContractName?: boolean;
}
// eslint-disable-next-line react/display-name
const MsaCreatorAgreement = forwardRef((props: Props, ref) => {
	const { scroll } = useScrollToField();
	const [params, setParams] = useSearchParams();
	useImperativeHandle(ref, () => ({
		handleContractMsaCreatorAgreementFormCancel: (
			data: IContractMSAForm,
			statusValue: string
		) => {
			if (data) {
				const submitData = {
					...data,
					status: statusValue ? statusValue : ContractStatus.Draft,
					contractMSA: undefined,
					payrollVendorId: data?.payrollVendorId?.value,
					performerId: data?.performerId?.value,
					advertiserId: data?.advertiserId?.value,
					adAgencyId: data?.adAgencyId?.value,
					adAgencyAddressId: data?.adAgencyAddressId?.value,
					talentAgencyId: data?.talentAgencyId?.value,
					talentAgencyAddressId: data?.talentAgencyAddressId?.value,
					productId: data?.productId?.value,
					// creator Agreement fields,
					lenderCompanyId: data?.lenderCompanyId?.value,
					lenderCompanyAddressId: data?.lenderCompanyAddressId?.value,
					lenderCompanyStaffId: data?.lenderCompanyStaffId?.value,
					onBehalfOfCompanyId: data?.onBehalfOfCompanyId?.value,
					onBehalfOfCompanyAddressId: data?.onBehalfOfCompanyAddressId?.value,
					onBehalfOfCompanyStaffId: data?.onBehalfOfCompanyStaffId?.value,
				};
				editContractDetails(submitData);
			}
		},
	}));

	useEffect(() => {
		if(props?.defaultValue?.advertiserId)
			contractForm.setValue('advertiserId', props?.defaultValue?.advertiserId, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.advertiserId]);

	const notification = useNotification();

	const contractForm = useForm<IContractMSAForm>({
		defaultValues: !props?.initialValue
			? {
				...(props?.mainForm?.getValues() || {}),
				...(props?.defaultValue || {})
			}
			: props?.initialValue,
		resolver: yupResolver(ContractFormGeneralSchema()),
		shouldFocusError: false
	});
	const [addressPopOver, setAddressPopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const packetControlForm = useForm<IPacketControlForm>({
		defaultValues: {
			isW4shortForm: !!contractForm.watch('contractW4Form.firstAndMiddleName'),
			isW8BenShort: !!contractForm.watch('contractW8BenShortForm'),
			isW9BenShort: !!contractForm.watch('contractW9BenShortForm'),
			isMinorTrust: !!contractForm.watch('contractMinorTrustForm'),
			isNYTheftPreventionForm: !!contractForm.watch(
				'contractNYTheftPreventionForm'
			),
			isCATheftPreventionForm: !!contractForm.watch(
				'contractCATheftPreventionForm'
			),
		},
	});

	const [, setPersonOptions] = useState<IPersonRecord[]>(props?.people || []);
	const [addressOptions, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [contractFormState, setContractFormState] =
		useState<IContractFormState>({});

	const ADVERTISER_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'ADVERTISER',
		},
	];

	const VENDOR_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'VENDOR',
		},
	];

	const AD_AGENCY_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'AD AGENCY',
		},
	];

	// helper functions
	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	const handlePackageNotification = (key: keyof IPacketControlForm) => {
		notification.notify({
			message: `Form ${
				packetControlForm.watch(key) ? 'Added to' : 'Removed from'
			} Packet.`,
			type: 'info',
		});
	};

	const handleStateOptions = (
		responseData: any[],
		type: 'PERSON' | 'ADDRESS'
	) => {
		const cb = {
			PERSON: setPersonOptions,
			ADDRESS: setAddressOptions,
		}[type];

		if (responseData && Array.isArray(responseData)) {
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);
				return [...oldFilter, ...responseData];
			});
		}
	};	
	
	const onPayrollVendorAddressCreated = (createdAddress: ICompanyAddress) => {
		contractForm.setValue('payrollVendorAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		const address = responseData?.reduce((acc, curr) => {
			if (curr?.addresses?.length) {
				acc.push(...(curr?.addresses || []));
			}
			return acc;
		}, [] as ICompanyAddress[]);

		handleStateOptions(address, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};

	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const companyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const getCompanyEmailOptions = (addressId?: string) => {
		const unsortedEmails =
			addressOptions?.find((a) => a?.id === addressId)?.emails || [];
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const onClickSnippetBtn = () => {
		setContractFormState((prev) => ({
			isPacketOpen: !prev?.isPacketOpen,
		}));
	};

	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};

	const { mutate: addContractDetails, isLoading: isAddingContract } =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addContractMSA(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						const tabPath = contractForm.watch('isGuaranteeOpted') ? 'guarantee-payment' : 'contract-view';
						props?.onSave(`/contracts/${res?.data?.id}/${tabPath}`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const { mutate: editContractDetails, isLoading: isEditingContract } =
		useMutation({
			mutationKey: ['contract-edit'],
			mutationFn: (payload: any) =>
				updateContractMSA(props?.contractId || '', payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'UPDATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						const tabPath = contractForm.watch('isGuaranteeOpted') ? 'guarantee-payment' : 'contract-view';
						props?.onSave(`/contracts/${res?.data?.id}/${tabPath}`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const handleContractSubmission = async (
		data: IContractMSAForm,
		handleMultipleContractAdd?: CallableFunction
	) => {
		if (!packetControlForm.watch('isW4shortForm')) {
			data.w4FormId = undefined;
			data.contractW4Form = undefined;
		}
		if (!packetControlForm.watch('isW8BenShort')) {
			data.w8BenShortFormId = undefined;
			data.contractW8BenShortForm = undefined;
		}
		if (!packetControlForm.watch('isW9BenShort')) {
			data.w9BenShortFormId = undefined;
			data.contractW9BenShortForm = undefined;
		}
		if (!packetControlForm.watch('isMinorTrust')) {
			data.minorTrustFormId = undefined;
			data.contractMinorTrustForm = undefined;
		}
		if (!packetControlForm.watch('isNYTheftPreventionForm')) {
			data.nYTheftPreventionFormId = undefined;
			data.contractNYTheftPreventionForm = undefined;
		}
		if (!packetControlForm.watch('isCATheftPreventionForm')) {
			data.cATheftPreventionFormId = undefined;
			data.contractCATheftPreventionForm = undefined;
		}

		try {
			const notify = (message: string) => {
				notification.notify({
					message,
					type: 'error',
				});
			};
			// NOTE: These linked form's handled separately because the payload size is huge.
			const updatedContractIds = await mutateContractAdditionalFormsData(
				data,
				data?.performerId?.value || '',
				notify
			);
			data.w4FormId = updatedContractIds?.w4FormId;
			data.w8BenShortFormId = updatedContractIds?.w8BenShortFormId;
			data.w9BenShortFormId = updatedContractIds?.w9BenShortFormId;
			data.minorTrustFormId = updatedContractIds?.minorTrustFormId;
			data.nYTheftPreventionFormId =
				updatedContractIds?.nYTheftPreventionFormId;
			data.cATheftPreventionFormId =
				updatedContractIds?.cATheftPreventionFormId;

		} catch (error) {
			notification.notify({
				message: getNotificationText('Form', 'UPDATE', true),
				type: 'error',
			});
		} finally {
			data.contractW4Form = undefined;
			data.contractW8BenShortForm = undefined;
			data.contractW9BenShortForm = undefined;
			data.contractMinorTrustForm = undefined;
			data.contractNYTheftPreventionForm = undefined;
			data.contractCATheftPreventionForm = undefined;

			const mainFormValues = props?.mainForm?.getValues();
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};
			
			const submitData = {
				...data,
				...addMainFormValues,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value || '',
				contractMSA: undefined,
				payrollVendorId: data?.payrollVendorId?.value,
				performerId: data?.performerId?.value,
				advertiserId: data?.advertiserId?.value,
				adAgencyId: data?.adAgencyId?.value,
				adAgencyAddressId: data?.adAgencyAddressId?.value,
				talentAgencyId: data?.talentAgencyId?.value,
				talentAgencyAddressId: data?.talentAgencyAddressId?.value,
				productId: data?.productId?.value,
				// creator Agreement fields,
				lenderCompanyId: data?.lenderCompanyId?.value,
				lenderCompanyAddressId: data?.lenderCompanyAddressId?.value,
				lenderCompanyStaffId: data?.lenderCompanyStaffId?.value,
				onBehalfOfCompanyId: data?.onBehalfOfCompanyId?.value,
				onBehalfOfCompanyAddressId: data?.onBehalfOfCompanyAddressId?.value,
				onBehalfOfCompanyStaffId: data?.onBehalfOfCompanyStaffId?.value,
			};
			if (!props?.isEdit) {
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					},
				});
			} else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 
		}
	};

	const onSubmitError = (data) => {
		scroll(data);
		notification.notify({
			message: 'Please fill all required fields',
			type: 'error',
		});
	};

	const onSubmit = (data: IContractMSAForm) => {
		if(!props?.mainForm?.watch('contractDisplayName') && !props?.isEdit){
			notification.notify({
				message: 'Please fill Contract Display Name.',
				type: 'error'
			});
		}
		else if(props?.isDuplicateContractName){
			notification.notify({
				message: 'Contract with the same name already exists',
				type: 'error',
			});
			return;
		}
		else{
			openExpiryDateForm(data);
		}
	};

	useEffect(() => {
		if(props?.mainForm){
			contractForm.setValue('contractDisplayName', props?.mainForm?.watch('contractDisplayName') || '', { shouldDirty: true});
		}
	}, [props?.mainForm?.watch('contractDisplayName')]);

	const { mutate: addMultipleContractDetails } = useMutation({
		mutationKey: ['contract-add'],
		mutationFn: (payload: any) => addMultipleContract(payload),
		onSuccess: (res) => {
			if (res?.status === HttpStatusCode.Ok) {
				const baseURL = getBaseUrl(window.location.href);
				const contractIds: any = res?.data || [];
				const urls =
					contractIds.map((contract) => {
						return `${baseURL}/#/contracts/${contract}/contract-view?isEdit=true`;
					}) || [];

				urls.forEach((url) => {
					const a = document.createElement('a');
					a.href = url;
					a.target = '_blank';
					const evt = document.createEvent('MouseEvents');
					evt.initMouseEvent(
						'click',
						true,
						true,
						window,
						0,
						0,
						0,
						0,
						0,
						false,
						false,
						false,
						false,
						0,
						null
					);
					a.dispatchEvent(evt);
				});
				notification.notify({
					message: getNotificationText('Contract', 'CREATE'),
					type: 'success',
				});
			} else
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
		},
		onError: () => {
			notification.notify({
				message: getNotificationText('Contract', 'CREATE', true),
				type: 'error',
			});
		},
	});

	const [contractFormData, setContractFormData] = useState<IContractMSAForm>();

	const [noOfTalentsSelected, setNoOfTalentsSelected] = useState(false);

	const handleSaveAndDuplicate = (data: IContractMSAForm) => {
		if(props?.isDuplicateContractName){
			notification.notify({
				message: 'Contract with the same name already exists',
				type: 'error',
			});
			return;
		}
		else{
			setNoOfTalentsSelected(true);
			setContractFormData(data);
		}
	};

	const handleTalentDetailsForm = (data: any) => {
		setNoOfTalentsSelected(false);
		const handleMultipleContractAdd = (payloadData) => {
			if (data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						status:ContractStatus?.Draft,
						role: talent?.role,
						performerAdAgencyId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${
							index + 1
						}`,
						compensation: talent?.compensation,
					};
					return submitData;
				});
				const multipleContractData = {
					contractAddMSAModels: contractAddModels,
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData &&
			handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};

	//Expiry Date Dialog Code
	const [isExpiryDateDialogOpen, setExpiryDateDialog] = useState<{isOpen: boolean, contractData: IContractMSAForm | undefined, contractExpiryDate: Date | undefined}>({isOpen: false, contractData: undefined, contractExpiryDate: undefined});

	const calculateEndDate = (termValues) => {
		if(termValues.termDuration && termValues.termDurationUnit && termValues.termFromFirstUseDate){
			const days = termValues.termDuration;
			const startDate = new Date(termValues.termFromFirstUseDate);
			const date = startDate.setDate(startDate.getDate() - 1);
			const unit = termValues.termDurationUnit;
			if(date){
				let finalDate;
				if(unit == 'WEEK (S)'){
					finalDate = (addWeeks(new Date(date), days));
				}
				else if(unit == 'MONTH (S)'){
					finalDate = addMonths(new Date(date), days);
				}
				else if(unit == 'YEAR (S)'){
					finalDate = addYears(new Date(date), days);
				}
				else{
					finalDate = addDays(new Date(date), days);
				}
				return finalDate;
			}
		}
	};

	const openExpiryDateForm = (data) => {
		const termValues = {
			termDuration: data.termDuration,
			termDurationUnit: data.termDurationUnit,
			termFromFirstUseDate: data.termFromDate || data.termFromFirstUseDate
		};
		const endDate = calculateEndDate(termValues) || undefined;
		setExpiryDateDialog({isOpen: true, contractData: data, contractExpiryDate: endDate});
	};

	const closeExpiryDateForm = () => setExpiryDateDialog({isOpen: false, contractData: undefined, contractExpiryDate: undefined});
	const onSubmitOfExpiryDateForm = (data) => {
		if(isExpiryDateDialogOpen.contractData){
			const expiredStatus = data.expirationDate ? (isBefore(data.expirationDate, new Date()) ? ContractStatus.Expired : undefined) : undefined;
			const submittedData: IContractMSAForm = {
				...isExpiryDateDialogOpen.contractData,
				expirationDate: data.expirationDate,
				status: expiredStatus
			};
			submittedData && handleContractSubmission(submittedData);
		}
	};

	return (
		<>
			<div className={props?.isPrint ? styles.formWrapperPreview : ''}>
				<form onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}>
					<div className={styles.packetTemplate} id='form'>
						<Grid item container columnSpacing={3}>
							{contractFormState?.isPacketOpen && (
								<Grid item sm={12} md={4} lg={3}>
									<div className={styles.snippetSection}>
										<Grid item container columnSpacing={3}>
											<Grid item md={12}>
												<h3 className={styles.pocketTitle}>
													{' '}
												Government & Payroll forms
												</h3>
											</Grid>
										</Grid>
										<div className={styles.contractFormBlockSnippet}>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW4shortForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW4shortForm');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW4shortForm');
																		}}
																	/>{' '}
																	<span>W4- short version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW8BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW8BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW8BenShort');
																		}}
																	/>
																	<span>W8 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW9BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW9BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW9BenShort');
																		}}
																	/>
																	<span>W9 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isMinorTrust'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isMinorTrust');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isMinorTrust');
																		}}
																	/>
																	<span>Minor Trust Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isNYTheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isNYTheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isNYTheftPreventionForm'
																			);
																		}}
																	/>
																	<span>NY Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isCATheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isCATheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isCATheftPreventionForm'
																			);
																		}}
																	/>
																	<span>CA Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
										</div>
									</div>
								</Grid>
							)}
							<Grid
								item
								sm={12}
								md={4}
								lg={!contractFormState?.isPacketOpen ? 12 : 9}
							>
								<div
									className={
										!contractFormState?.isPacketOpen
											? undefined
											: styles.scrollFIle
									}
								>
									<div className={styles.contractFormBlockPacket} id='contract-form-block-packet'>
										<div className={styles.contractFormBlockPacketMSA}>
											<div className={styles.contractFormBlock}>
												{props?.isEdit && (
													<div className={styles.formFiledsBlock}>
														<Grid container item columnSpacing={3}>
															<Grid item md={6} lg={3}>
																<FormControl fullWidth>
																	<FormLabel>
															Contract Name<sup>*</sup>
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		name='contractDisplayName'
																		render={({ field }) => (
																			<>
																				<NabuTextbox
																					{...field}
																					placeholder={
																						!props?.isPrint ? 'Contract Name' : ''
																					}
																					disabled={props?.isPrint}
																				/>
																			</>
																		)}
																	/>
																	{contractForm.formState?.errors?.contractDisplayName
																		?.message && (
																		<FormHelperText error={true}>
																			{
																				contractForm.formState.errors
																					?.contractDisplayName?.message
																			}
																		</FormHelperText>
																	)}
																</FormControl>
															</Grid>
															<Grid item md={6} lg={3}>
																<FormControl fullWidth>
																	<FormLabel>
															Payroll Vendor <sup />
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		name='payrollVendorId'
																		render={({ field }) => (
																			<>
																				<LookAheadSelect
																					{...field}
																					placeholder={
																						!props?.isPrint ? 'Payroll Vendor' : ''
																					}
																					filterProperty='CompanyNames.Name'
																					APICaller={getCompanyBasicDetails}
																					optionGenerator={companyOptionGenerator}
																					defaultFilter={VENDOR_FILTER}
																					isClearable={true}
																					onChange={(e: any) => {
																						field.onChange(e);
																					}}
																					cacheOptions={false}
																					defaultOptions
																					className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				/>
																			</>
																		)}
																	/>
																	{contractForm.formState.errors?.payrollVendorId?.message && (
																		<FormHelperText
																			error={true}
																			data-cy='errorHelper_ad_agency'
																		>
																			{
																				contractForm.formState?.errors?.payrollVendorId?.message
																			}
																		</FormHelperText>
																	)}
																</FormControl>
															</Grid>
															<Grid item md={6} lg={3}>
																<FormControl fullWidth>
																	<FormLabel>
															Payroll Vendor Address
																		<sup />
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		key={`payroll-address-${contractForm.watch(
																			'payrollVendorAddressId.value'
																		)}`}
																		name='payrollVendorAddressId'
																		render={({ field }) => (
																			<>
																				<LookAheadSelect
																					{...field}
																					placeholder={'Select Address'}
																					key={`payroll-vendor-address-${contractForm.watch(
																						'payrollVendorAddressId.value'
																					)}-${contractForm.watch('payrollVendorId.value')}`}
																					filterProperty='Line1'
																					APICaller={(filterModel) =>
																						getAllAddressByCompany(
																							filterModel,
																							contractForm.watch('payrollVendorId.value') || ''
																						)
																					}
																					optionGenerator={
																						companyAddressOptionGenerator
																					}
																					isDisabled={!contractForm.watch('payrollVendorId.value')}
																					dropDownsize={50}
																					isClearable={true}
																					isSearchText={true}
																					onChange={(e: any) => {
																						field.onChange(e);
																						contractForm.trigger('payrollVendorAddressId.value');
																					}}
																					cacheOptions={false}
																					defaultOptions
																					className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				/>
																				{contractForm.watch('payrollVendorId.value') && <div style={{float:'right'}}>
																					<Box sx={{mt:0.9}}>
																						<AddCompanyAddressInline
																							companyId={contractForm.watch('payrollVendorId.value')}
																							title='ADDRESS'
																							isOpen={addressPopOver?.isOpen}
																							event={addressPopOver?.event}
																							onAddressCreated={(d) => onPayrollVendorAddressCreated(d)}
																							onClose={() => setAddressPopOver({ isOpen: false })}
																							onPopOverOpen={(e) => setAddressPopOver({
																								isOpen: true,
																								event: e?.currentTarget,
																							})}
																							isPersistent={true} />
																					</Box>
																				</div>}
																			</>
																	
																		)}
																	/>
																	{contractForm.formState.errors?.payrollVendorAddressId?.message && (
																		<FormHelperText
																			error={true}
																			data-cy='errorHelper_ad_agency'
																		>
																			{
																				contractForm.formState?.errors
																					?.payrollVendorAddressId.message
																			}
																		</FormHelperText>
																	)}
																</FormControl>
															</Grid>
															<Grid item md={6} lg={3}>
																<FormControl fullWidth>
																	<FormLabel>
															Status
																		<sup />
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		name='status'
																		render={({ field }) => (
																			<>
																				<NabuSelect
																					{...field}
																					placeholder={
																						!props?.isPrint ? 'Select Status' : ''
																					}
																					options={ContractStatusOptions || []}
																					convertValueToUpperCase={false}
																					onChange={(e) => field.onChange(e)}
																				/>
																			</>
																		)}
																	/>
																</FormControl>
															</Grid>
														</Grid>
													</div>
												)}
												{/* Main Contract Form Start */}
												<>
													<div
														className={styles.contractFormBlock}
													>
														<div
															id={CONTRACT_REF_ID.MAIN_FORM}
															className={styles.bgWhite} style={{padding:'20px'}}
														>
															<table
																className={styles.contractForms}
																width={'100%'}
															>
																<tbody>
																	<tr>
																		<h4>{contractForm.watch('contractId') ? `#${contractForm.watch('contractId')}` : ''}</h4>
																	</tr>
																	<tr>
																		<td align='center'>
																			<h2>CREATOR AGREEMENT</h2>{' '}
																		</td>
																	</tr>
																</tbody>
															</table>
															<hr></hr>
															<div>
																<div
																	style={{
																		display: 'flex',
																		alignItems: 'center',
																		gap: '12px',
																		flexWrap: 'wrap',
																	}}
																>
															This CREATOR <b>AGREEMENT</b> and all Schedules
															attached hereto (this “Agreement”) is made and
															entered into as of
																	<FormControl className={styles.tableForms}>
																		<Controller
																			control={contractForm.control}
																			name='effectiveDate'
																			defaultValue={new Date()}
																			render={({ field }) => (
																				<NabuDatepicker
																					{...field}
																					disabled={props?.isPrint}
																				/>
																			)}
																		/>
																	</FormControl>
																	{contractForm.formState?.errors?.effectiveDate
																		?.message && (
																		<FormHelperText error={true}>
																			{
																				contractForm.formState?.errors
																					?.effectiveDate.message
																			}
																		</FormHelperText>
																	)}
																	<span
																		key={`${contractForm.watch('effectiveDate')}`}
																	>
																		{contractForm.watch('effectiveDate') &&
																	format(
																		new Date(
																			contractForm.watch('effectiveDate') || ''
																		),
																		'EEEE do \'of\' MMMM yyyy'
																	)}
																	</span>
															(the <b>“Effective Date”</b>) by and between{' '}
																	<FormControl className={styles.tableFormsSelect}>
																		<Controller
																			key={`lender-${contractForm.watch(
																				'lenderCompanyId.value'
																			)}-${contractForm.watch('productId.value')}`}
																			control={contractForm.control}
																			name='lenderCompanyId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field}
																						placeholder={
																							!props?.isPrint ? 'Lender' : ''
																						}
																						filterProperty='CompanyNames.Name'
																						APICaller={getCompanyBasicDetails}
																						optionGenerator={companyOptionGenerator}
																						isDisabled={props?.isPrint}
																						isClearable={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																						}}
																						cacheOptions={false}
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{!props?.isPrint && (
																						<div
																							className={styles.inlineEditContract}
																						>
																							<AddCompanyInline title='Company' />
																						</div>
																					)}
																				</>
																			)}
																		/>
																		{contractForm.formState.errors?.lenderCompanyId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.lenderCompanyId.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
															. (“Lender”) f/s/o
																	<FormControl className={styles.tableFormsSelect}>
																		<Controller
																			control={contractForm.control}
																			name='performerId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						data-cy='name_select'
																						{...field}
																						key={`performer-name-${contractForm.watch(
																							'performerId.value'
																						)}`}
																						isClearable={true}
																						placeholder={
																							!props?.isPrint
																								? 'Select Performer'
																								: ''
																						}
																						filterProperty='PersonName.FirstName'
																						APICaller={(filterModel, searchText) =>
																							GetByWithFullNameSearchForBasicDetail(
																								filterModel,
																								searchText
																							)
																						}
																						isSearchText={true}
																						isDisabled={props?.isPrint}
																						optionGenerator={
																							performerOptionGenerator
																						}
																						defaultFilter={getPerformerFilter()}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.setValue(
																								'performerPrintName',
																								contractForm.watch(
																									'performerId.label'
																								),
																								{ shouldDirty: true }
																							);
																						}}
																						cacheOptions={false}
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{!props?.isPrint && (
																						<div
																							className={styles.inlineEditContract}
																						>
																							<AddPersonInline
																								onPersonCreated={() => ({})}
																								title='Person'
																							/>
																						</div>
																					)}
																				</>
																			)}
																		/>
																		{contractForm.formState?.errors?.performerId?.message && (
																			<FormHelperText
																				data-cy='errorHelper_first_session_date'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors
																						?.performerId?.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>
															(“<b>Creator</b>”), c/o{' '}
																	<FormControl className={styles.tableFormsSelect}>
																		<Controller
																			key={`advertiser-${contractForm.watch(
																				'advertiserId.value'
																			)}-${contractForm.watch('productId.value')}`}
																			control={contractForm.control}
																			name='advertiserId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						{...field}
																						placeholder={
																							!props?.isPrint ? 'Client' : ''
																						}
																						filterProperty='CompanyNames.Name'
																						APICaller={getCompanyBasicDetails}
																						optionGenerator={companyOptionGenerator}
																						defaultFilter={ADVERTISER_FILTER}
																						isDisabled={props?.isPrint}
																						isClearable={true}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.resetField('productId');
																						}}
																						cacheOptions={false}
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{!props?.isPrint && (
																						<div
																							className={styles.inlineEditContract}
																						>
																							<AddCompanyInline title='Company' />
																						</div>
																					)}
																				</>
																			)}
																		/>
																		{contractForm.formState.errors?.advertiserId?.message && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper_ad_agency'
																			>
																				{
																					contractForm.formState?.errors
																						?.advertiserId.message
																				}
																			</FormHelperText>
																		)}
																	</FormControl>{' '}
															, and
																	<div>
																		<Controller
																			control={contractForm.control}
																			name='adAgencyId'
																			render={({ field }) => (
																				<>
																					<LookAheadSelect
																						data-cy='ad_agency'
																						{...field}
																						placeholder={
																							!props?.isPrint ? 'Ad-Agency' : ''
																						}
																						filterProperty='CompanyNames.Name'
																						APICaller={getCompanyBasicDetails}
																						optionGenerator={companyOptionGenerator}
																						defaultFilter={AD_AGENCY_FILTER}
																						isClearable={true}
																						isDisabled={props?.isPrint}
																						onChange={(e: any) => {
																							field.onChange(e);
																							contractForm.setValue(
																								'adAgencyAddressId',
																								null,
																								{ shouldDirty: true }
																							);
																							contractForm.trigger(
																								'adAgencyAddressId'
																							);
																						}}
																						cacheOptions
																						defaultOptions
																						className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																					/>
																					{!props?.isPrint && (
																						<Box>
																							<div className={styles.inlineEditContract}>
																								<AddCompanyInline
																									title='Company'
																									companyType={{
																										mainType:
																					'AD AGENCY' as CompanyMainType,
																										subType: [],
																									}}
																								/>
																							</div>
																						</Box>
																					)}
																				</>
																			)}
																		/>
																	</div>
																	{contractForm.formState.errors?.adAgencyId?.message && (
																		<FormHelperText
																			error={true}
																			data-cy='errorHelper_ad_agency'
																		>
																			{
																				contractForm.formState?.errors?.adAgencyId.message
																			}
																		</FormHelperText>
																	)}
															.
															
															(“
																	<b>Company</b>” or “
																	<b>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='adAgencyPrintName'
																				render={({ field }) => (
																					<>
																						<NabuTextbox
																							{...field}
																							disabled={props?.isPrint}
																							placeholder={
																								!props?.isPrint
																									? 'Agency Short Name'
																									: ''
																							}
																							onChange={(e) => {
																								field.onChange(e);
																								contractForm.trigger(
																									'adAgencyPrintName'
																								);
																							}}
																						/>
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors
																				?.adAgencyPrintName?.message && (
																				<FormHelperText
																					data-cy='errorHelper_?.addressId'
																					error={true}
																				>
																					{
																						contractForm.formState.errors
																							?.adAgencyPrintName?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</b>
															”).
																</div>
																<br></br>
																<h4>
																	<u>
																		<b>WITNESSETH</b> 
																	</u>
																</h4>
																<div
																	key={`agency-short-name-${contractForm.watch(
																		'adAgencyPrintName'
																	)}`}
																>
																	<span>WHEREAS, Company desires to obtain the right to
															use the name, likeness and endorsement of Creator <br></br>
															solely in connection with the advertisement,
															marketing and promotion of Company’s{' '}
																	</span>	
																	<span
																		key={`agency-short-name-${contractForm.watch(
																			'adAgencyPrintName'
																		)}`}
																	>
																		{contractForm.watch('adAgencyPrintName') || (
																			<>
																				<FormControl>
																					<FormControl>
																						<NabuTextbox disabled={true}/>
																					</FormControl>
																				</FormControl>
																			</>
																		)}
																	</span>{' '}
															brand, debit card and the{' '}
																	<span
																		key={`agency-short-name-${contractForm.watch(
																			'adAgencyPrintName'
																		)}`}
																	>
																		{contractForm.watch('adAgencyPrintName') || (
																			<>
																				<FormControl>
																					<NabuTextbox disabled={true}/>
																				</FormControl>
																			</>
																		)}
																	</span>{' '}
															App (collectively,“ Products”):
																</div>
																<br />
																<span>
															WHEREAS, Lender has such rights to sublicense such
															rights to Company hereunder; and
																</span>
																<br />
																<span>
															WHEREAS, Lender desires to grant such rights to
															Company, subject to the terms of this Agreement.
																</span>
															</div>
															<br></br>
															<div>
																<b>
															NOW, THEREFORE, for and in consideration of the
															premises and of the mutual promises and conditions
															herein contained, the parties do hereby agree as
															follows:
																</b>
															</div>
															<ol>
																<li value={1}>
																	<div className={styles.listDrop}>
																		<b>
																			<u>Definitions.</u> As used herein, the
																	following terms shall be defined as set forth
																	below:
																		</b>
																	</div>

																	<div className={styles.listDrop}>
																(a) “<b>Creator Identification</b>” shall mean
																Creator’s approved professional name, Creator’s
																social media name(s), facsimile signature,
																biographical information, image and likeness.{' '}
																	</div>
																	<div className={styles.listDrop}>
																(b) “<b>Contract Period</b>” shall mean that
																period of time commencing on the Effective Date
																and concluding six (6) months thereafter, unless
																sooner terminated pursuant to the terms hereof.
																	</div>
																	<p className={styles.listDrop}>
																(c) “<b>Contract Media</b>” shall mean any and
																all formats and media, whether now known or
																hereafter known including but not limited to
																social media, digital advertisements, promoted
																paid media advertisements on social platforms,
																websites, email messages and other online
																platforms operated and owned by{' '}
																		<span>
																			{contractForm.watch('adAgencyPrintName') || (
																				<>
																					<FormControl>
																						<NabuTextbox disabled={true}/>
																					</FormControl>
																				</>
																			)}
																		</span>{' '}
																(and its sublicensees) and any media placements
																made by
																		<span>
																			{' '}
																			{contractForm.watch('adAgencyPrintName') || (
																				<>
																					<FormControl>
																						<NabuTextbox disabled={true}/>
																					</FormControl>
																				</>
																			)}
																		</span>
																.
																	</p>
																	<div className={styles.listDrop}>
																(d) “<b>Contract Territory</b>” shall mean
																throughout the universe.
																	</div>
																	<div className={styles.listDrop}>
																(e) “<b>Materials</b>” shall mean all materials
																produced by Creator in connection with the
																Services under this Agreement, including but not
																limited to all photos and/or videos taken at the
																photoshoot, materials identified on Schedule A,
																Creator’s social media posts and other written
																materials as identified on Schedule A, and all
																other previously approved materials produced
																solely in connection with the Services under
																this Agreement.
																	</div>
																	<div className={styles.listDrop}>
																(f) “<b>Services</b>” shall mean the services
																Lender shall perform during the Contract Period
																pursuant to this Agreement, as set forth in
																Schedule A.
																	</div>
																	<p className={styles.listDrop}>
																(g) “
																		<b>
																			<span>
																				{contractForm.watch(
																					'adAgencyPrintName'
																				) || (
																					<>
																						<FormControl>
																							<NabuTextbox disabled={true}/>
																						</FormControl>
																					</>
																				)}
																			</span>
																	Trademarks
																		</b>
																” shall mean the list of marks described in
																Schedule B.
																	</p>
																	<div className={styles.listDrop}>
																(h) “<b>Completion of Services</b>” shall mean
																the date Lender completes all Services outlined
																in Schedule A.
																	</div>
																	<div className={styles.listDrop}>
																(i) “<b>Creator Materials</b>” shall mean
																content created solely by Creator which does not
																contain content from the{' '}
																		<span>
																			{contractForm.watch('adAgencyPrintName') || (
																				<>
																					<FormControl>
																						<NabuTextbox disabled={true}/>
																					</FormControl>
																				</>
																			)}
																		</span>{' '}
																photo/video production shoot (as detailed in
																Schedule A).
																	</div>
																</li>
																<li value={2}>
																	<div className={styles.listDrop}>
																		<b>
																			<u>
																		Services of Creator; Grant of Endorsement
																		Rights.
																			</u>
																		</b>
																	</div>
																	<div className={styles.listDrop}>
																(a) Lender agrees to cause Creator to perform
																the Services, and hereby irrevocably (subject to
																Section 8 below) grants and licenses to Company
																and its affiliates, successors, licensees,
																agents, and assigns (&quot;Authorized
																Persons&quot;) the rights to digitize, modify,
																alter, edit combine with other materials
																(including, but not limited to in combination or
																conjunction with Creator’s Identification or any
																other name), adapt, create derivative works,
																display, publicly perform, exhibit, transmit,
																broadcast, reproduce, license, sublicense
																(solely to mutually agreed upon third parties),
																otherwise use, and permit mutually agreed upn
																others to use the Creator Materials and Creator
																Identification including but not limited to
																Creator(s) name, image, likeness, appearance,
																and voice as they appear in the Materials or
																Creator Materials, in solely during the Contract
																Period, throughout the universe, in any Contract
																Media without any royalty, payment, or other
																compensation to Lender except as otherwise
																expressly provided in this Agreement, with all
																of the foregoing subject to Lender’s approval
																rights in each instance. Further, solely during
																the Contract Period, Lender grants{' '}
																		<span>
																			{contractForm.watch('adAgencyPrintName') || (
																				<>
																					<FormControl>
																						<NabuTextbox disabled={true}/>
																					</FormControl>
																				</>
																			)}
																		</span>{' '}
																and Authorized Persons the right to repost all
																social media posts posted by Creator in
																connection with this Agreement on{' '}
																		<span>
																			{contractForm.watch('adAgencyPrintName') || (
																				<>
																					<FormControl>
																						<NabuTextbox disabled={true}/>
																					</FormControl>
																				</>
																			)}
																		</span>{' '}
																and/or Authorized Persons’ respective Contract
																Media, including but not limited to social media
																channels and websites. All rights not expressly
																granted to Company herein are hereby reserved by
																Lender and/or Creator. For the avoidance of
																doubt, solely during the Contract Period,
																Company shall be permitted to “re-post”,
																“coauthor,” “sponsor,” or “whitelist” Creator’s
																posts related to the Campaign (as defined in
																Schedule A) on relevant social media properties,
																which, for clarity includes the right for
																Company to boost or “promote” with paid media.
																		<div className={styles.listDrop}>
																	(b) Notwithstanding anything to the contrary
																	in this Agreement, (i) for a period of twelve
																	(12) months after the expiration of the
																	Contract Term, it is understood and agreed
																	that Company and/or Authorized Persons shall
																	not be required to remove from their
																	respective social media properties, including,
																	but not limited to on Tik Tok, Facebook,
																	Instagram, YouTube, and/or Twitter, Materials
																	that Company has distributed or caused to be
																	distributed during the Contract Period, so
																	long as no new or additional use is made, and
																	no use states or implies any ongoing
																	relationship with Lender and/or Creator.
																		</div>
																		<div className={styles.listDrop}>
																	(c) <u>Exclusivity.</u> Solely during the
																	Contract Period, Creator will not render any
																	paid promotional services for any Neo-Banks
																	including Current, Dave, Vary, and Credit
																	Karma; or any Online Banks including SoFi,
																	Ally, or Marcus; or any companies that provide
																	Payment-to-Payment services including Venmo,
																	Cash App, Velle, or Square. Creator will not
																	enter into any partnerships with the foregoing
																	entities and/or in the foregoing categories
																	that require public facing consumer marketing
																	activities with any Retail Banks or Credit
																	Card Companies during the Contract Period.
																	Notwithstanding the foregoing, nothing in this
																	Agreement shall preclude Lender and/or Creator
																	from working with any other financial service
																	institutions including but not limited to
																	investment banks, private equity and venture
																	capital funds, and other similar organizations
																	and platforms.
																		</div>
																		<div
																			className={styles.listDrop}
																			key={`agency-short-name-div-${contractForm.watch(
																				'adAgencyPrintName'
																			)}`}
																		>
																	(d){' '}
																			<u
																				key={`agency-short-name-u-${contractForm.watch(
																					'adAgencyPrintName'
																				)}`}
																			>
																		No Grant of Certain Trademark Rights.
																			</u>{' '}
																	Neither Lender nor Creator grants, or purports
																	to grant, to{' '}
																			<span
																				key={`agency-short-name-${contractForm.watch(
																					'adAgencyPrintName'
																				)}`}
																			>
																				{contractForm.watch(
																					'adAgencyPrintName'
																				) || (
																					<>
																						<FormControl>
																							<NabuTextbox disabled={true}/>
																						</FormControl>
																					</>
																				)}
																			</span>{' '}
																	any rights or uses of any names, logos,
																	trademarks, service marks, etc. owned and/or
																	controlled by any third party, including but
																	not limited to, the National Football League
																	(“NFL”), any NFL team, the National Football
																	League Players Association
																	(&quot;NFLPA&quot;), any individual country’s
																	Olympic Committee, the International Olympic
																	Committee, and/or any party affiliated with
																	any or all of these entities. Accordingly,
																			<span
																				key={`agency-short-name-${contractForm.watch(
																					'adAgencyPrintName'
																				)}`}
																			>
																				{contractForm.watch(
																					'adAgencyPrintName'
																				) || (
																					<>
																						<FormControl>
																							<NabuTextbox disabled={true}/>
																						</FormControl>
																					</>
																				)}
																			</span>{' '}
																	agrees that it shall make no use of the
																	Creator Identification and/or Creator
																	Materials in connection with any third party
																	without the prior written permission of such
																	third party.
																		</div>
																	</div>
																</li>														
															</ol>
														</div>
														<div
															id={CONTRACT_REF_ID.MAIN_FORM_PART_2}
															className={styles.bgWhite} style={{padding:'20px'}}
														>
															<Grid item container columnSpacing={3}>
																<Grid item md={12}>
																	<ol>
																		<li value={3}>
																			<div className={styles.listDrop}>
																				<b>
																					<u>Approvals</u>
																				</b>
																. <br></br>Prior to Creator’s release of any
																Materials, Lender shall submit all Materials
																intended to be displayed, published,
																distributed, or otherwise made publicly
																available to{' '}
																				<span>
																					{contractForm.watch('adAgencyPrintName') || (
																						<>
																							<FormControl>
																								<NabuTextbox disabled={true}/>
																							</FormControl>
																						</>
																					)}
																				</span>{' '}
																for one (1) round of prior approval.
																Furthermore,{' '}
																				<span>
																					{contractForm.watch('adAgencyPrintName') || (
																						<>
																							<FormControl>
																								<NabuTextbox disabled={true}/>
																							</FormControl>
																						</>
																					)}
																				</span>{' '}
																agrees to submit to Lender and Wasserman (Attn:
																Ted Yeschin,
																				<a href='mailto:tyeschin@teamwass.com'>
																					{' '}
																	tyeschin@teamwass.com
																				</a>
																, Doug Hendrickson,
																				<a href='mailto:dhendrickson@teamwass.com'>
																					{' '}
																	dhendrickson@teamwass.com
																				</a>
																, and Alexis Ramos, aramos@teamwass.com), for
																their approval and review, a copy of all
																materials utilizing the Creator Identification
																and/or Creator Materials (including any portion
																thereof) at least five (5) days prior to their
																release to the general public; and{' '}
																				<span>
																					{contractForm.watch('adAgencyPrintName') || (
																						<>
																							<FormControl>
																								<NabuTextbox disabled={true}/>
																							</FormControl>
																						</>
																					)}
																				</span>{' '}
																further agrees that the same shall not be
																released without prior written approval of
																Wasserman. Lender and Wasserman agree, however,
																that they shall not unreasonably withhold or
																delay their approval of said materials and that
																in absence of disapproval, within three (3)
																business days of receipt thereof, said materials
																shall be deemed approved.{' '}
																				<span>
																					{contractForm.watch('adAgencyPrintName') || (
																						<>
																							<FormControl>
																								<NabuTextbox disabled={true}/>
																							</FormControl>
																						</>
																					)}
																				</span>{' '}
																agrees to not use the Creator Identification
																and/or Creator Materials in any other manner
																whatsoever, including without limitation, as an
																endorsement, express or implied, of any
																particular company, product, product category,
																or service without approval by Lender
																			</div>
																		</li>
																		<li value={4}>
																			<b>
																				<u>Consideration.</u>
																			</b>
																			<br></br>
																	(a) In consideration of the grant of rights
																	and performance of Services set forth herein,
																	Company agrees to pay to Lender compensation
																	as follows:
																			<div style={{margin:'10px 0px'}}>
																				{
																					props?.isPrint ? (
																						<p className={styles.descriptionPrintText}>{contractForm.watch('guaranteeDetails')}</p>
																					) : (<> 
																						<FormControl
																							fullWidth
																							className={styles.tableForms}
																						>
																							<Controller
																								control={contractForm.control}
																								name='guaranteeDetails'
																								render={({ field }) => (
																									<>
																										<textarea
																											className={styles.specialProvision}
																											rows={3}
																											{...field}
																											disabled={props?.isPrint}
																											placeholder={
																												!props?.isPrint
																													? 'Guarantee Details'
																													: ''
																											}
																										/>
																									</>
																								)}
																							/>
																							{contractForm.formState?.errors
																								?.guaranteeDetails?.message && (
																								<FormHelperText
																									data-cy='errorHelper_?.addressId'
																									error={true}
																								>
																									{
																										contractForm.formState.errors
																											?.guaranteeDetails?.message
																									}
																								</FormHelperText>
																							)}
																						</FormControl>
																					</> )
																				}
																			</div>
																			{/*<div>
																			{
																				props?.isPrint ? (
																					<p>{contractForm.watch('guaranteeDetails')}</p>
																				) : (<> 
																					<FormControl
																						fullWidth
																						className={styles.tableForms}
																					>
																						<Controller
																							control={contractForm.control}
																							name='guaranteeDetails'
																							render={({ field }) => (
																								<>
																									<textarea
																										className={styles.specialProvision}
																										rows={3}
																										{...field}
																										disabled={props?.isPrint}
																										placeholder={
																											!props?.isPrint
																												? 'Guarantee Details'
																												: ''
																										}
																									/>
																								</>
																							)}
																						/>
																						{contractForm.formState?.errors
																							?.guaranteeDetails?.message && (
																							<FormHelperText
																								data-cy='errorHelper_?.addressId'
																								error={true}
																							>
																								{
																									contractForm.formState.errors
																										?.guaranteeDetails?.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</> )
																			}
																		</div>
																		<div>
																			{
																				props?.isPrint ? (
																					<p>{contractForm.watch('guaranteeDetails')}</p>
																				) : (<> 
																					<FormControl
																						fullWidth
																						className={styles.tableForms}
																					>
																						<Controller
																							control={contractForm.control}
																							name='guaranteeDetails'
																							render={({ field }) => (
																								<>
																									<textarea
																										className={styles.specialProvision}
																										rows={3}
																										{...field}
																										disabled={props?.isPrint}
																										placeholder={
																											!props?.isPrint
																												? 'Guarantee Details'
																												: ''
																										}
																									/>
																								</>
																							)}
																						/>
																						{contractForm.formState?.errors
																							?.guaranteeDetails?.message && (
																							<FormHelperText
																								data-cy='errorHelper_?.addressId'
																								error={true}
																							>
																								{
																									contractForm.formState.errors
																										?.guaranteeDetails?.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</> )
																			}
																		</div>*/}
																		
																			<div className={styles.listDrop}>
																		(ii) Company will pay a mutually agreed upon
																		fee covering all travel requirements,
																		hair/makeup, and stylist services as needed
																		(the “Buyout”)
																			</div>
																			<div className={styles.listDrop}>
																		(iii) Company will pay each invoice within
																		thirty (30) days of the receipt of such
																		invoice or after satisfactory completion of
																		Services as set forth in Schedule A,
																		whichever is later.
																			</div>
																			<div className={styles.listDrop}>
																		(iv) All payments to Lender shall be made to
																		“Bushrob Inc. c/o Wasserman Media Group,
																		LLC” and delivered to Wasserman, Attn:
																		Noeleen Meehan: 10900 Wilshire Blvd., Suite.
																		1200, Los Angeles, CA 90024 or wired to:
																			</div>
																			<div className={styles.listDrop}>
																				<FormControl fullWidth>
																					<b>Bank Name:</b>
																					<Controller
																						control={contractForm.control}
																						name='bankName'
																						render={({ field }) => (
																							<>
																								<NabuTextbox
																									{...field}
																									disabled={props?.isPrint}
																									placeholder={
																										!props?.isPrint
																											? 'Enter Bank Name'
																											: ''
																									}
																								/>
																							</>
																						)}
																					/>
																					{contractForm.formState?.errors?.bankName
																						?.message && (
																						<FormHelperText
																							data-cy='errorHelper_?.addressId'
																							error={true}
																						>
																							{
																								contractForm.formState.errors
																									?.bankName?.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																				<br></br>
																				<FormControl fullWidth>
																					<b>ABA/Routing Number for:</b>
																					<Controller
																						control={contractForm.control}
																						name='routingNumber'
																						render={({ field }) => (
																							<>
																								<NabuTextbox
																									{...field}
																									disabled={props?.isPrint}
																									placeholder={
																										!props?.isPrint
																											? 'Enter ABA/Routing Number'
																											: ''
																									}
																								/>
																							</>
																						)}
																					/>
																					{contractForm.formState?.errors
																						?.routingNumber?.message && (
																						<FormHelperText
																							data-cy='errorHelper_?.addressId'
																							error={true}
																						>
																							{
																								contractForm.formState.errors
																									?.routingNumber?.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																				<br></br>
																				<FormControl fullWidth>
																					<b>Swift Code:</b>
																					<Controller
																						control={contractForm.control}
																						name='swiftCode'
																						render={({ field }) => (
																							<>
																								<NabuTextbox
																									{...field}
																									disabled={props?.isPrint}
																									placeholder={
																										!props?.isPrint
																											? 'Enter Swift Code'
																											: ''
																									}
																								/>
																							</>
																						)}
																					/>
																					{contractForm.formState?.errors?.swiftCode
																						?.message && (
																						<FormHelperText
																							data-cy='errorHelper_?.addressId'
																							error={true}
																						>
																							{
																								contractForm.formState.errors
																									?.swiftCode?.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																				<br></br>
																				<FormControl fullWidth>
																					<b>Client Trust Account</b>
																					<Controller
																						control={contractForm.control}
																						name='clientTrustAccountNumber'
																						render={({ field }) => (
																							<>
																								<NabuTextbox
																									{...field}
																									disabled={props?.isPrint}
																									placeholder={
																										!props?.isPrint
																											? 'Enter Client Trust Account'
																											: ''
																									}
																								/>
																							</>
																						)}
																					/>
																					{contractForm.formState?.errors
																						?.clientTrustAccountNumber?.message && (
																						<FormHelperText
																							data-cy='errorHelper_?.addressId'
																							error={true}
																						>
																							{
																								contractForm.formState.errors
																									?.clientTrustAccountNumber
																									?.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																				<br></br>
																				<FormControl fullWidth>
																					<b>Bank Address:</b>
																					<Controller
																						control={contractForm.control}
																						name='bankAddress'
																						render={({ field }) => (
																							<>
																								<NabuTextbox
																									{...field}
																									disabled={props?.isPrint}
																									placeholder={
																										!props?.isPrint
																											? 'Enter Bank Address'
																											: ''
																									}
																								/>
																							</>
																						)}
																					/>
																					{contractForm.formState?.errors
																						?.bankAddress?.message && (
																						<FormHelperText
																							data-cy='errorHelper_?.addressId'
																							error={true}
																						>
																							{
																								contractForm.formState.errors
																									?.bankAddress?.message
																							}
																						</FormHelperText>
																					)}
																				</FormControl>
																			</div>
																		</li>
																		<li value={5}>
																			<div className={styles.listDrop}>
																				<b>
																					<u>Non-Disparagement. </u>
																				</b>
																				<br></br>
																		During the Contract Period, neither party
																		(which in the case of Lender, includes
																		Creator) shall not make any defamatory or
																		disparaging remarks, comments or statements
																		concerning the other party (which in the
																		case of Lender, includes Creator) , its
																		products, business or any of its employees.
																			</div>
																		</li>
																		<li value={6}>
																			<div className={styles.listDrop}>
																				<b>
																					<u>Representations and Warranties.</u>{' '}
																				</b>
																			</div>
																			<div className={styles.listDrop}>
																		(a) Each party represents and warrants that:
																		(i) the execution and delivery of this
																		Agreement and the performance of its
																		obligations hereunder do not interfere or
																		conflict with, nor will they cause such
																		party, with or without the passage of time
																		or the giving of notice, to be in default
																		under any agreement, contract, commitment,
																		order, judgment or any obligation by which
																		such party is bound, and (ii) such party has
																		the required power, authority and rights to
																		enter into this Agreement and to perform its
																		obligations hereunder, including, without
																		limitation, to grant the rights and licenses
																		granted hereunder.
																			</div>
																			<div className={styles.listDrop}>
																		(b) Lender represents and warrants that: (i)
																		to the best of Lender’s and Creator’s
																		knowledge, Lender and/or Creator has
																		obtained all permissions necessary for{' '}
																				<span>
																					{contractForm.watch(
																						'adAgencyPrintName'
																					) || (
																						<>
																							<FormControl>
																								<NabuTextbox disabled={true}/>
																							</FormControl>
																						</>
																					)}
																				</span>{' '}
																		and Authorized Persons to fully and lawfully
																		exercise the rights and licenses granted
																		under this Agreement, including but not
																		limited to all relevant intellectual
																		property licenses, solely when the foregoing
																		is used in accordance with Lender’s approval
																		rights herein; (ii) all Materials and
																		Services shall comply with Company’s Brand
																		Guidelines, attached hereto as Schedule B
																		(as may be amended from time to time); (iii)
																		Creator shall at all times comply with all
																		reasonable guidelines, project timelines,
																		and instructions provided by Company; and
																		(iv) Creator shall at all times comply with
																		all applicable rules and regulations,
																		including but not limited to all FTC
																		endorsement guidelines, sweepstakes
																		guidelines and other rules provided by
																		Company.
																			</div>
																		</li>
																		<li value={7}>
																			<b>
																				<u>Failure</u>
																			</b>
																			<br></br>
																			<div className={styles.listDrop}>
																		(a) If Creator dies or becomes physically or
																		mentally incapacitated before she has
																		rendered the Services specified in this
																		Agreement, Company may elect to immediately
																		terminate this Agreement by delivery of
																		written notice to Lender’s agent or
																		representative, which shall be provided by
																		Lender’s to Company. As used in this
																		Agreement, &quot;physically or mentally
																		incapacitated&quot; shall mean
																		Creator&apos;s physical, mental or emotional
																		inability to perform the services described
																		herein as determined by a mutually agreed
																		upon physician, which inability shall
																		continue for more than thirty (30)
																		consecutive days.
																			</div>
																			<div className={styles.listDrop}>
																		(b) If Creator dies or becomes physically or
																		mentally incapacitated after she has
																		performed any or all of the Services
																		required under this Agreement, Company may
																		continue to use previously produced
																		Materials as permitted under this Agreement
																		until the end of the Contract Period
																		provided Company has made all payments to
																		Lender as required hereunder and is not
																		otherwise in uncured material breach of this
																		Agreement.
																			</div>
																		</li>
																		<li value={8}>
																			<b>
																				<u>Termination.</u>
																			</b>

																			<div className={styles.listDrop}>
																		(a) This Agreement shall automatically
																		terminate without action of the parties to
																		this Agreement at the end of the Contract
																		Period.
																			</div>
																			<div className={styles.listDrop}>
																		(b) Company may at any time during the
																		Contract Period terminate this Agreement
																		without cause upon two (2) days’ written
																		notice to Creator. If Company terminates
																		this Agreement pursuant to this Section
																		8(b), then within thirty (30) days of the
																		Effective Date of termination,{' '}
																				<span
																					key={`agency-short-name-${contractForm.watch(
																						'adAgencyPrintName'
																					)}`}
																				>
																					{contractForm.watch(
																						'adAgencyPrintName'
																					) || (
																						<>
																							<FormControl>
																								<NabuTextbox disabled={true}/>
																							</FormControl>
																						</>
																					)}
																				</span>{' '}
																		shall pay to Lender all Compensation
																		contemplated by this Agreement as if this
																		Agreement were not earlier terminated. .
																			</div>
																			<div className={styles.listDrop}>
																		(c) Company may, at its option, terminate
																		this Agreement immediately upon written
																		notice to Lender, if Creator: (i) commits
																		any criminal act or other act involving
																		moral turpitude, drugs, or felonious
																		activities; (ii) commits any act which
																		brings Creator into significant and material
																		public disrepute, contempt, scandal, or
																		ridicule, or which shocks or offends the
																		community or any group or class thereof, or
																		which reflects unfavorably upon Company or
																		reduces the commercial value of
																		Company&apos;s association with Creator,
																		with all of the foregoing subject to an
																		objective, reasonable person standard; ;
																		(iii) during the Contract Period, Creator
																		takes any action (other than legal action or
																		arbitration arising out of this Agreement)
																		or makes or authorizes statements deemed,
																		reasonably and in good faith, by the Company
																		to be in derogation of Company or its
																		products; or (iv) Lender materially breaches
																		this Agreement and fails to sure such breach
																		within ten (10) business days of receipt of
																		notice thereof. Any of the acts described
																		above will be deemed a material breach of
																		the Agreement. In the event of termination
																		by Company pursuant to this Section 8(c),
																		then within thirty (30) days of the
																		Effective Date of termination, Company shall
																		pay to Lender a pro rata portion of the
																		Compensation contemplated herein for
																		Services provided by Lender and Creator
																		through the Effective Date of termination;
																		provided, in the event Company previously
																		paid Lender in excess of the aforementioned
																		pro rata amount, then within thirty (30)
																		days of the Effective Date of termination,
																		Lender shall refund such excess amount to
																		Company.
																			</div>																	
																		</li>
																	</ol>
																</Grid>
															</Grid>
														</div>
														<div 
															id={CONTRACT_REF_ID.MAIN_FORM_PART_3}
															className={styles.bgWhite}  style={{padding:'20px'}}>
															<Grid item container columnSpacing={3}>
																<Grid item md={12}>
																	<ol>
																		<div className={styles.listDrop}>
																		(d) Company agrees that Lender shall have
																		the right to immediately terminate this
																		Agreement in the event: (i) Company is
																		adjudged, declares, or files for bankruptcy;
																		or (ii) Company materially breaches this
																		Agreement and fails to cure such breach
																		within ten (10) business days of receiving
																		notice thereof. In the event of termination
																		by Lender pursuant to this Section 8(d),
																		then within thirty (30) days of the
																		Effective Date of termination, Company shall
																		pay to Lender all Compensation contemplated
																		by this Agreement as if this Agreement were
																		not earlier terminated.
																		</div>
																		<div className={styles.listDrop}>
																			(e) Upon any termination of this Agreement
																			by either party for any reason, all rights
																			granted to Company by Lender and/or Creator
																			shall automatically and irrevocably revert
																			back to Lender and/or Creator, and Company
																			shall no longer have any rights in or to the
																			Creator Identification and/or Creator
																			Materials, and except as otherwise expressly
																			set forth herein, must immediately cease all
																			such use.
																		</div>
																		<div className={styles.listDrop}>
																			(f) Take Down Request. Upon termination or
																			expiration of this Agreement, Lender agrees
																			to cause Creator to comply with Company’s
																			request to immediately discontinue use of
																			all Materials and/or{' '}
																			{contractForm.watch('adAgencyPrintName') ? (
																				<>
																					<FormControl>
																						<NabuTextbox disabled={true}
																							value={contractForm.watch('adAgencyPrintName')}
																						/>
																					</FormControl>
																				</>
																			):
																				<FormControl>
																					<NabuTextbox disabled={props?.isPrint}
																					/>
																				</FormControl>
																			}
																			Trademarks and to immediately comply with
																			Company’s request to remove Materials and/or{' '}
																			<span
																				key={`agency-short-name-${contractForm.watch(
																					'adAgencyPrintName'
																				)}`}
																			>
																				{contractForm.watch(
																					'adAgencyPrintName'
																				) || (
																					<>
																						<FormControl>
																							<NabuTextbox disabled={true}/>
																						</FormControl>
																					</>
																				)}
																			</span>{' '}
																			Trademarks previously published in
																			connection with this Agreement.
																		</div>
																	</ol>
																	<ol>
																		<li value={9}>
																			<div className={styles.listDrop}>
																				<b>
																					<u>Indemnity. </u>
																				</b>
																			</div>
																			<div className={styles.listDrop}>
																		(a) Company agrees to indemnify and hold
																		harmless Lender and Creator from and against
																		all actual, out of pocket, verifiable
																		expenses, damages, claims, suits, actions,
																		judgments and costs whatsoever, including
																		reasonable outside attorneys’ fees, arising
																		out of any third party claims brought
																		against Creator arising from: (i) Company’s
																		material breach of this Agreement; <br></br>
																		(ii) Company’s gross negligence or fraud,
																		(iii) any product or service offered by
																		Company; and (iv) claims that the{' '}
																				
																				{contractForm.watch(
																					'adAgencyPrintName'
																				) ? (
																						<>
																							<FormControl>
																								<NabuTextbox disabled={true}
																									value={contractForm.watch('adAgencyPrintName')}
																								/>
																							</FormControl>
																						</>
																					):
																					<FormControl>
																						<NabuTextbox
																						/>
																					</FormControl>
																				}
																			
																		Trademarks infringe or violate any
																		intellectual property or other rights of any
																		third party, solely when used in strict
																		accordance with{' '}
																				<span
																					key={`agency-short-name-${contractForm.watch(
																						'adAgencyPrintName'
																					)}`}
																				>
																					{contractForm.watch(
																						'adAgencyPrintName'
																					) || (
																						<>
																							<FormControl>
																								<NabuTextbox disabled={true}/>
																							</FormControl>
																						</>
																					)}
																				</span>
																		’s approval rights herein except for claims
																		arising out of Lender’s breach of this
																		Agreement.
																			</div>
																			<div className={styles.listDrop}>
																		(b) Except as may be subject to Company’s
																		indemnification obligations above,
																		Lenderagrees to indemnify and hold harmless
																		Company and Authorized Persons from and
																		against all actual, out of pocket,
																		verifiable expenses, damages, claims, suits,
																		actions, judgments and costs whatsoever,
																		including reasonable outside attorneys’
																		fees, arising out of any third party claims
																		brought against Company arising or relating
																		to (i) breach of any warranty or covenant
																		made in this Agreement by Lender; or (ii)
																		claims that the Creator Identification
																		and/or Creator Materials infringe or violate
																		any intellectual property or other rights of
																		any third party, solely when used in strict
																		accordance with Lender’s approval rights
																		herein
																			</div>
																			<div className={styles.listDrop}>
																		(c) In the event either party fails to meet
																		its indemnity obligations under this Section
																		of the Agreement, the aggrieved party is
																		entitled to offset the cost or recover the
																		cost, as the case may be, of any claims
																		related to such unmet indemnification
																		obligations.
																			</div>
																		</li>
																		<li value={10}>
																			<div className={styles.listDrop}>
																				<b>
																					<u>Liability.</u>
																				</b>{' '}
																				<br></br>Except in the case of Creator’s
																		violation of Company Brand Guidelines, set
																		forth herein as Schedule B, in no event
																		shall either party (which in the case of
																		Lender, includes Creator) be liable to the
																		other party for (a) any amount in excess of
																		the total consideration paid by Company to
																		Lender hereunder pursuant to Section 4, or
																		(b) any special, consequential, indirect,
																		exemplary and/or punitive damages, or for
																		loss of good will or business profits.
																			</div>
																		</li>
																		<li value={11}>
																			<div className={styles.listDrop}>
																				<b>
																					<u>Confidentiality.</u>
																				</b>
																		The specific terms and conditions of this
																		Agreement are confidential. Except as
																		otherwise required (i) by applicable law,
																		(ii) to exercise the rights granted herein
																		or (iii) with the consent of the party to
																		whom the information pertains, the parties
																		(which in the case of Lender, includes
																		Creator for all purposes of this Section 11)
																		agree not to disclose any of the terms of
																		this Agreement or any information they may
																		learn concerning the other’s business to any
																		third party or to the public, with the
																		exception of their respective lawyers,
																		accountants, and representatives who are
																		required to be informed in order to perform
																		obligations related to this Agreement and
																		who are under a similar obligation of
																		confidentiality.
																			</div>
																		</li>
																		<li value={12}>
																			<div className={styles.listDrop}>
																				<b>
																					<u>No Joint Venture. </u>
																				</b>
																		This Agreement does not constitute and shall
																		not be construed as constituting an
																		employer-employee relationship, partnership
																		or joint venture between Lender and Company.
																		Except as otherwise set forth herein,
																		neither party shall have any right to
																		obligate or bind the other party in any
																		manner whatsoever.
																			</div>
																		</li>
																		<li value={13}>
																			<div className={styles.listDrop}>
																				<b>
																					<u>Trademarks. </u>
																				</b>
																				{contractForm.watch(
																					'adAgencyPrintName'
																				) ? (
																						<>
																							<FormControl>
																								<NabuTextbox disabled={true}
																									value={contractForm.watch('adAgencyPrintName')}
																								/>
																							</FormControl>
																						</>
																					):
																					<FormControl>
																						<NabuTextbox disabled={true}
																							value={contractForm.watch('adAgencyPrintName')}
																						/>
																					</FormControl>
																				}
																		grants Lender and Creator a revocable,
																		non-exclusive, non-transferable license to
																		use the{' '}
																				
																				{contractForm.watch(
																					'adAgencyPrintName'
																				) ? (
																						<>
																							<FormControl>
																								<input disabled={true}
																									value={contractForm.watch('adAgencyPrintName')}
																								/>
																							</FormControl>
																						</>
																					):
																					<FormControl>
																						<NabuTextbox />
																					</FormControl>
																				}
																			
																		Trademarks in connection with the Services
																		for the Contract Period, subject to
																				{contractForm.watch(
																					'adAgencyPrintName'
																				) ? (
																						<>
																							<FormControl>
																								<NabuTextbox disabled={true}
																									value={contractForm.watch('adAgencyPrintName')}
																								/>
																							</FormControl>
																						</>
																					):
																					<FormControl>
																						<NabuTextbox/>
																					</FormControl>
																				}
																		&apos;s right to control the way in which
																		Lender and/or Creator uses the{' '}
																				{contractForm.watch(
																					'adAgencyPrintName'
																				) ? (
																						<>
																							<FormControl>
																								<NabuTextbox disabled={true}
																									value={contractForm.watch('adAgencyPrintName')}
																								/>
																							</FormControl>
																						</>
																					):
																					<FormControl>
																						<NabuTextbox />
																					</FormControl>
																				}
																		Trademarks. Creator acknowledges and agrees
																		that Creator is familiar with{' '}
																			
																				{contractForm.watch(
																					'adAgencyPrintName'
																				) ? (
																						<>
																							<FormControl>
																								<NabuTextbox disabled={true}
																									value={contractForm.watch('adAgencyPrintName')}
																								/>
																							</FormControl>
																						</>
																					):
																					<FormControl>
																						<NabuTextbox />
																					</FormControl>

																				}
																			
																		&apos;s existing quality standards and will
																		ensure that any use of the{' '}
																				{contractForm.watch(
																					'adAgencyPrintName'
																				) ? (
																						<>
																							<FormControl>
																								<NabuTextbox disabled={true}
																									value={contractForm.watch('adAgencyPrintName')}
																								/>
																							</FormControl>
																						</>
																					):
																					<FormControl>
																						<NabuTextbox />
																					</FormControl>

																				}
																		Trademarks complies with such existing
																		quality standards, including but not limited
																		to the Trademark Guidelines attached as
																		Schedule B hereto. Any promotional materials
																		created using the{' '}
																				{contractForm.watch(
																					'adAgencyPrintName'
																				) ? (
																						<>
																							<FormControl>
																								<NabuTextbox disabled={true}
																									value={contractForm.watch('adAgencyPrintName')}
																								/>
																							</FormControl>
																						</>
																					):
																					<FormControl>
																						<NabuTextbox />
																					</FormControl>

																				}
																		Trademarks will be subject to{' '}
																				{contractForm.watch(
																					'adAgencyPrintName'
																				) ? (
																						<>
																							<FormControl>
																								<input disabled={true}
																									value={contractForm.watch('adAgencyPrintName')}
																								/>
																							</FormControl>
																						</>
																					):
																					<FormControl>
																						<NabuTextbox />
																					</FormControl>

																				}
																		&apos;s review and approval before each
																		instance of publication.
																			</div>
																		</li>
																		<li value={14}>
																			<div className={styles.listDrop}>
																		Ownership. (a) Lender agrees that Company is
																		and will be the sole and exclusive owner of
																		all right, title, and interest in and to the
																		Materials, including but not limited to all
																		copyrights and other intellectual property
																		rights therein, in perpetuity throughout the
																		universe. In furtherance of the foregoing,
																		Lender hereby assigns, transfers, and
																		otherwise conveys to Company, irrevocably
																		and in perpetuity, throughout the universe,
																		all of right, title, and interest that
																		Creator may have in and to the Materials, if
																		any, including but not limited to all
																		copyright and other intellectual property
																		rights, including all registration, renewal,
																		and reversion rights, and the right to
																		register and sue to enforce such copyrights
																		against infringers and alleged infringers.
																		Lender irrevocably waives any and all claims
																		Creator may now or hereafter have in any
																		jurisdiction to socalled &quot;moral
																		rights&quot; or rights of droit moral in the
																		Materials.
																			</div>
																			<div className={styles.listDrop}>
																		(b) Company has no obligation to use the
																		Creator Identification, Materials or to
																		exercise any rights granted under this
																		Agreement; provided, subject to all other
																		provisions of this Agreement, Company shall
																		nonetheless pay Lender all Compensation
																		required by this Agreement.
																			</div>
																			<div className={styles.listDrop}>
																		(c) Creator and/or Lender shall maintain
																		ownership of Creator Materials and Creator
																		Identification. Lenderagrees that all
																		Creator Materials relating to this Agreement
																		must be pre-approved by{' '}
																				{contractForm.watch(
																					'adAgencyPrintName'
																				) ? (
																						<>
																							<FormControl>
																								<NabuTextbox disabled={true}
																									value={contractForm.watch('adAgencyPrintName')}
																								/>
																							</FormControl>
																						</>
																					):
																					<FormControl>
																						<NabuTextbox />
																					</FormControl>

																				}
																		in accordance with Section 3 above.
																			</div>
																		</li>
																		<li value={15}>
																			<div className={styles.listDrop}>
																				<b>
																					<u>Notices. </u>
																				</b>
																		All notices to the parties hereto shall be
																		in writing and may be served upon a
																		principal or officer or other authorized
																		representative of the applicable party
																		personally, or by certified mail (return
																		receipt requested), or any overnight mail
																		delivery service (e.g., Federal Express)
																		which provides proof of service addressed to
																		the appropriate party at the respective
																		addresses set forth herein or at such other
																		address as any party hereto may designate in
																		writing from time to time. All notices shall
																		be deemed given when personally delivered,
																		mailed or delivered to a delivery service,
																		all charges prepaid, except that notice of a
																		change of address shall be effective only
																		after actual receipt thereof.
																			</div>
																		</li>
																		<li value={16}>
																			<div className={styles.listDrop}>
																				<b>
																					<u>Assignment. </u>
																				</b>
																		This Agreement may not be assigned by either
																		party without the non-assigning party’s
																		written consent.
																			</div>
																			<div className={styles.listDrop}>
																		17. Force Majeure. If Lender is prevented or
																		hampered in performing a material portion of
																		Creators duties hereunder or Company is
																		unable to use the Materials for thirty (30)
																		consecutive days during the Contract Period,
																		in each case because of strikes, boycotts,
																		war, acts of God, pandemics, labor troubles,
																		riots, delays of commercial carriers,
																		restraints of public authority, or for any
																		other emergency beyond the parties&apos;
																		reasonable control, then the parties shall
																		have the mutual right to extend the Contract
																		Period, for an equivalent period, or a
																		period mutually agreed upon, which in no
																		event shall exceed one hundred eighty (180)
																		consecutive days.
																			</div>
																		</li>
																		<li value={17}>
																			<div className={styles.listDrop}>
																				<b>
																					<u>Force Majeure.</u>
																				</b>
																			</div>
																			<div className={styles.listDrop}>
																		If Lender is prevented or hampered in
																		performing a material portion of Creators
																		duties hereunder or Company is unable to use
																		the Materials for thirty (30) consecutive
																		days during the Contract Period, in each
																		case because of strikes, boycotts, war, acts
																		of God, pandemics, labor troubles, riots,
																		delays of commercial carriers, restraints of
																		public authority, or for any other emergency
																		beyond the parties&apos; reasonable control,
																		then the parties shall have the mutual right
																		to extend the Contract Period, for an
																		equivalent period, or a period mutually
																		agreed upon, which in no event shall exceed
																		one hundred eighty (180) consecutive days.
																			</div>
																		</li>																														
																	</ol>
																</Grid>
															</Grid>
														</div>
														<div id={CONTRACT_REF_ID.MAIN_FORM_PART_4} style={{padding:'20px'}}>
															<div className={styles.bgWhite}  >
																<Grid item container columnSpacing={3}>
																	<Grid >
																		<ol>
																			<li value={18}>
																				<div className={styles.listDrop}>
																					<b>
																						<u>Arbitration.</u>
																					</b>
																				</div>
																				<div className={styles.listDrop}>
																			In the event a dispute arises under this
																			Agreement which cannot be resolved, such
																			dispute shall be submitted to arbitration
																			and resolved by a single neutral arbitrator
																			(who shall be a lawyer experienced in
																			matters related to any dispute hereunder and
																			selected by the American Arbitration
																			Association) in accordance with the American
																			Arbitration Association in accordance with
																			its Commercial Arbitration Rules (including
																			the Optional Rules for Emergency Measures of
																			Protection). All such arbitration shall take
																			place at the office of the American
																			Arbitration Association in Los Angeles,
																			California. The award or decision rendered
																			by the arbitrator shall be final, binding
																			and conclusive and judgment may be entered
																			upon such award by any court. Either party
																			may apply to the arbitrator seeking
																			injunctive relief until the arbitration
																			award is rendered or the controversy is
																			otherwise resolved. Either party also may,
																			without waiving any remedy under this
																			Agreement, seek from any court having
																			jurisdiction any interim or provisional
																			relief that is necessary to protect the
																			rights or property of that party, pending
																			the establishment of the arbitral tribunal
																			(or pending the arbitral tribunal’s
																			determination of the merits of the
																			controversy).
																				</div>
																			</li>		
																			<li value={19}>
																				<div className={styles.listDrop}>
																					<b>
																						<u> Governing Law.</u>
																					</b>
																				</div>
																				<div className={styles.listDrop}>
																		This Agreement shall be governed and
																		construed according to the laws of the state
																		of California, without regard to any
																		conflict of law principles.
																				</div>
																			</li>
																			<li value={20}>
																				<div className={styles.listDrop}>
																					<b>
																						<u>Waiver; Amendment.</u>
																					</b>
																				</div>
																				<div className={styles.listDrop}>
																			No waiver, amendment or modification of any
																			of the terms of this Agreement shall be
																			binding or enforceable unless made in
																			writing and signed by the authorized
																			signatories of the parties hereto. No delay
																			of or omission in the exercise of any right,
																			power or remedy accruing to any party under
																			this Agreement shall impair any such right,
																			power or remedy, nor shall it be construed
																			as a waiver of any future exercise of any
																			right, power or remedy.
																				</div>
																			</li>
																			<li value={21}>
																				<div className={styles.listDrop}>
																					<b>
																						<u>Entire Agreement.</u>
																					</b>
																				</div>
																				<div className={styles.listDrop}>
																			This Agreement represents the entire
																			understanding between the parties regarding
																			the subject matter hereof, and supersedes
																			all prior and contemporaneous oral and
																			written agreements, negotiations,
																			representations, understandings and
																			correspondence. This Agreement may be
																			executed in multiple counterparts with the
																			same effect as if all signatories had signed
																			the same agreement. The parties may sign
																			this Agreement via electronic signature,
																			which shall have the same legal effect,
																			validity and enforceability as a manually
																			executed signature.
																				</div>
																				<div>
																					<div className={styles.listDrop}>
																				IN WITNESS WHEREOF, the parties hereto
																				have caused this Agreement to be executed
																				as of the last date of signature.
																					</div>
																				</div>
																			</li>
																		</ol>
																	</Grid>
																	<Grid item md={6}>
																		<h5>
																			<b>
																				<span>EXCEL SPORTS MANAGEMENT ON BEHALF OF{' '}</span>
																		
																				<span style={{ textTransform: 'uppercase' }} key={`agency-short-name-${contractForm.watch( 'adAgencyId.label' )}`} >
																					{contractForm.watch('adAgencyId.label') || (
																						<>
																					
																						</>
																					)}
																				</span>
																			</b>
																		</h5>
																	</Grid>
																	<Grid item md={6}>
																		<h5>
																			<b>LENDER</b>
																		</h5>
																	</Grid>
																	<Grid item md={6}>
																		
																		<table
																			width={'100%'}
																			border={1}
																			cellPadding={0}
																			cellSpacing={0}
																			className={styles.contractFormsInner}
																		>
																			<tr>
																				<td width={120}>By:</td>
																				<td width={250}>
																					<FormControl
																						fullWidth
																						className={styles.tableForms}
																					>
																						<NabuTextbox disabled />
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td width={120}>Title:</td>
																				<td width={250}>
																					<FormControl
																						fullWidth
																						className={styles.tableForms}
																					>
																						<Controller
																							key={`onBehalfOfCompanyId-${contractForm.watch(
																								'onBehalfOfCompanyId.value'
																							)}}`}
																							control={contractForm.control}
																							name='onBehalfOfCompanyId'
																							render={({ field }) => (
																								<>
																									<LookAheadSelect
																										{...field}
																										placeholder={
																											!props?.isPrint
																												? 'On Behalf Of Company'
																												: ''
																										}
																										filterProperty='CompanyNames.Name'
																										APICaller={getCompanyBasicDetails}
																										optionGenerator={
																											companyOptionGenerator
																										}
																										isDisabled={props?.isPrint}
																										isClearable={true}
																										onChange={(e: any) => {
																											field.onChange(e);
																											contractForm.setValue(
																												'onBehalfOfCompanyStaffId',
																												null,
																												{ shouldDirty: true }
																											);
																											contractForm.setValue(
																												'onBehalfOfCompanyAddressId',
																												null,
																												{ shouldDirty: true }
																											);
																											contractForm.trigger(
																												'onBehalfOfCompanyStaffId'
																											);
																											contractForm.trigger(
																												'onBehalfOfCompanyAddressId'
																											);
																										}}
																										cacheOptions={false}
																										defaultOptions
																										className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																									/>
																									{!props?.isPrint && (
																										<Box sx={{ mt: 0.5 }}>
																											<AddCompanyInline title='Company' />
																										</Box>
																									)}
																								</>
																							)}
																						/>
																						{contractForm.formState.errors
																							?.onBehalfOfCompanyId?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors?.onBehalfOfCompanyId.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td>Name:</td>
																				<td>
																					<FormControl
																						fullWidth
																						className={styles.tableForms}
																					>
																						<Controller
																							key={`staff-${contractForm.watch(
																								'onBehalfOfCompanyStaffId.value'
																							)}-${contractForm.watch(
																								'onBehalfOfCompanyId.value'
																							)}`}
																							control={contractForm.control}
																							name='onBehalfOfCompanyStaffId'
																							render={({ field }) => (
																								<>
																									<LookAheadSelect
																										{...field}
																										filterProperty='PersonName.FirstName'
																										APICaller={(filter) =>
																											getAllStaffOfCompany(
																												contractForm.watch(
																													'onBehalfOfCompanyId.value'
																												) || '',
																												filter
																											)
																										}
																										optionGenerator={
																											performerOptionGenerator
																										}
																										isDisabled={
																											props?.isPrint ||
																									!contractForm.watch(
																										'onBehalfOfCompanyId.value'
																									)
																										}
																										isClearable={true}
																										isSearchText={true}
																										onChange={(e: any) => {
																											field.onChange(e);
																										}}
																										cacheOptions={false}
																										defaultOptions
																										className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																									/>
																								</>
																							)}
																						/>
																						{contractForm.formState.errors
																							?.onBehalfOfCompanyStaffId?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors?.onBehalfOfCompanyStaffId.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td>Date:</td>
																				<td>
																					<FormControl
																						fullWidth
																						className={styles.tableForms}
																					>
																						<NabuTextbox disabled />
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td width={120}>Notice Address:</td>
																				<td>
																					<Controller
																						control={contractForm.control}
																						key={`lender-address-cr-${contractForm.watch(
																							'onBehalfOfCompanyId.value'
																						)}`}
																						name='onBehalfOfCompanyAddressId'
																						render={({ field }) => (
																							<LookAheadSelect
																								{...field}
																								placeholder={
																									!props?.isPrint
																										? 'Select Address'
																										: ''
																								}
																								key={`lender-address-${contractForm.watch(
																									'onBehalfOfCompanyId.value'
																								)}`}
																								filterProperty='Line1'
																								sortField='IsPrimary'
																								sortDescending={true}
																								onOptionLoaded={(options) => {
																									if(options?.length && !contractForm.watch('onBehalfOfCompanyAddressId.value')){
																										contractForm.setValue('onBehalfOfCompanyAddressId', options[0], { shouldDirty: true});
																										contractForm.trigger('onBehalfOfCompanyAddressId', { shouldFocus: true});																						
																									}
																								}}
																								APICaller={(filterModel) =>
																									getAllAddressByCompany(
																										filterModel,
																										contractForm.watch(
																											'onBehalfOfCompanyId.value'
																										) || ''
																									)
																								}
																								optionGenerator={
																									companyAddressOptionGenerator
																								}
																								isDisabled={
																									!contractForm.watch(
																										'onBehalfOfCompanyId.value'
																									) || props?.isPrint
																								}
																								dropDownsize={50}
																								isClearable={true}
																								isSearchText={true}
																								onChange={(e: any) => {
																									field.onChange(e);
																									contractForm.setValue(
																										'onBehalfOfCompanyEmailId',
																										'',
																										{ shouldDirty: true }
																									);
																									contractForm.trigger(
																										'onBehalfOfCompanyEmailId'
																									);
																								}}
																								cacheOptions={false}
																								defaultOptions
																								className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																							/>
																						)}
																					/>
																					{contractForm.formState.errors
																						?.onBehalfOfCompanyAddressId?.message && (
																						<FormHelperText
																							error={true}
																							data-cy='errorHelper_ad_agency'
																						>
																							{
																								contractForm.formState?.errors
																									?.onBehalfOfCompanyAddressId.message
																							}
																						</FormHelperText>
																					)}
																					<br></br>
																			Email Address:{' '}
																					<Controller
																						control={contractForm.control}
																						key={`select-em-${contractForm.watch(
																							'onBehalfOfCompanyAddressId.value'
																						)}-${contractForm.watch('onBehalfOfCompanyEmailId')}`}
																						name='onBehalfOfCompanyEmailId'
																						render={({ field }) => (
																							<NabuSelect
																								{...field}
																								key={`select-em-${contractForm.watch(
																									'onBehalfOfCompanyAddressId.value'
																								)}-${contractForm.watch('onBehalfOfCompanyEmailId')}`}
																								placeholder={
																									!props?.isPrint
																										? 'Select Email'
																										: ''
																								}
																								convertValueToUpperCase={false}
																								enableEmptySelection={true}
																								disabled={
																									props?.isPrint ||
																							!contractForm.watch(
																								'onBehalfOfCompanyAddressId.value'
																							)
																								}
																								options={getCompanyEmailOptions(
																									contractForm.watch(
																										'onBehalfOfCompanyAddressId.value'
																									)
																								)}
																								handleDefaultSelection={() => !contractForm.watch('onBehalfOfCompanyEmailId')}
																							/>
																						)}
																					/>
																					{contractForm.formState?.errors
																						?.onBehalfOfCompanyEmailId?.message && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors
																									?.onBehalfOfCompanyEmailId?.message
																							}
																						</FormHelperText>
																					)}
																				</td>
																			</tr>
																		</table>
																	</Grid>
																	<Grid item md={6}>
																		
																		<table
																			width={'100%'}
																			border={1}
																			cellPadding={0}
																			cellSpacing={0}
																			className={styles.contractFormsInner}
																		>
																			<tr>
																				<td width={120}>By:</td>
																				<td>
																					<FormControl
																						fullWidth
																						className={styles.tableForms}
																					>
																						<NabuTextbox disabled />
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td>Title:</td>
																				<td>
																					<FormControl
																						fullWidth
																						className={styles.tableForms}
																					>
																						<Controller
																							key={`lender-${contractForm.watch(
																								'lenderCompanyId.value'
																							)}-${contractForm.watch(
																								'productId.value'
																							)}`}
																							control={contractForm.control}
																							name='lenderCompanyId'
																							render={({ field }) => (
																								<>
																									<LookAheadSelect
																										{...field}
																										placeholder={
																											!props?.isPrint
																												? 'Select Lender Company'
																												: ''
																										}
																										filterProperty='CompanyNames.Name'
																										APICaller={getCompanyBasicDetails}
																										optionGenerator={
																											companyOptionGenerator
																										}
																										isDisabled={props?.isPrint}
																										isClearable={true}
																										onChange={(e: any) => {
																											field.onChange(e);
																											contractForm.setValue(
																												'lenderCompanyStaffId',
																												null,
																												{ shouldDirty: true }
																											);
																											contractForm.setValue(
																												'lenderCompanyAddressId',
																												null,
																												{ shouldDirty: true }
																											);
																											contractForm.trigger(
																												'lenderCompanyStaffId'
																											);
																											contractForm.trigger(
																												'lenderCompanyAddressId'
																											);
																										}}
																										cacheOptions={false}
																										defaultOptions
																										className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																									/>
																									{!props?.isPrint && (
																										<Box sx={{ mt: 0.5 }}>
																											<AddCompanyInline title='Company' />
																										</Box>
																									)}
																								</>
																							)}
																						/>
																						{contractForm.formState.errors
																							?.lenderCompanyId?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors?.lenderCompanyId.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td>Name:</td>
																				<td>
																					<FormControl
																						fullWidth
																						className={styles.tableForms}
																					>
																						<Controller
																							key={`staff-${contractForm.watch(
																								'lenderCompanyStaffId.value'
																							)}-${contractForm.watch(
																								'lenderCompanyId.value'
																							)}`}
																							control={contractForm.control}
																							name='lenderCompanyStaffId'
																							render={({ field }) => (
																								<>
																									<LookAheadSelect
																										{...field}
																										placeholder={
																											!props?.isPrint
																												? 'Select Staff'
																												: ''
																										}
																										filterProperty='PersonName.FirstName'
																										APICaller={(filter) =>
																											getAllStaffOfCompany(
																												contractForm.watch(
																													'lenderCompanyId.value'
																												) || '',
																												filter
																											)
																										}
																										optionGenerator={
																											performerOptionGenerator
																										}
																										isDisabled={
																											props?.isPrint ||
																									!contractForm.watch(
																										'lenderCompanyId.value'
																									)
																										}
																										isClearable={true}
																										isSearchText={true}
																										onChange={(e: any) => {
																											field.onChange(e);
																										}}
																										cacheOptions={false}
																										defaultOptions
																										className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																									/>
																								</>
																							)}
																						/>
																						{contractForm.formState.errors?.lenderCompanyStaffId?.message && (
																							<FormHelperText error={true}>
																								{
																									contractForm.formState?.errors?.lenderCompanyStaffId.message
																								}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td>Date:</td>
																				<td>
																					<FormControl
																						fullWidth
																						className={styles.tableForms}
																					>
																						<NabuTextbox disabled />
																					</FormControl>
																				</td>
																			</tr>
																			<tr>
																				<td>Notice Address:</td>
																				<td>
																					<Controller
																						control={contractForm.control}
																						key={`lender-address-cr-${contractForm.watch(
																							'lenderCompanyId.value'
																						)}`}
																						name='lenderCompanyAddressId'
																						render={({ field }) => (
																							<LookAheadSelect
																								{...field}
																								placeholder={
																									!props?.isPrint
																										? 'Select Address'
																										: ''
																								}
																								key={`lender-address-${contractForm.watch(
																									'lenderCompanyId.value'
																								)}`}
																								filterProperty='Line1'
																								sortField='IsPrimary'
																								sortDescending={true}
																								onOptionLoaded={(options) => {
																									if(options?.length && !contractForm.watch('lenderCompanyAddressId.value')){
																										contractForm.setValue('lenderCompanyAddressId', options[0], { shouldDirty: true});
																										contractForm.trigger('lenderCompanyAddressId', { shouldFocus: true});																						
																									}
																								}}
																								APICaller={(filterModel) =>
																									getAllAddressByCompany(
																										filterModel,
																										contractForm.watch(
																											'lenderCompanyId.value'
																										) || ''
																									)
																								}
																								optionGenerator={
																									companyAddressOptionGenerator
																								}
																								isDisabled={
																									!contractForm.watch(
																										'lenderCompanyId.value'
																									) || props?.isPrint
																								}
																								dropDownsize={50}
																								isClearable={true}
																								isSearchText={true}
																								onChange={(e: any) => {
																									field.onChange(e);
																									contractForm.setValue(
																										'lenderCompanyEmailId',
																										'',
																										{ shouldDirty: true }
																									);
																								}}
																								cacheOptions={false}
																								defaultOptions
																								className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																							/>
																						)}
																					/>
																					{contractForm.formState.errors?.lenderCompanyAddressId?.message && (
																						<FormHelperText
																							error={true}
																							data-cy='errorHelper_ad_agency'
																						>
																							{
																								contractForm.formState?.errors?.lenderCompanyAddressId.message
																							}
																						</FormHelperText>
																					)}
																					<br></br>
																			Email Address:{' '}
																					<Controller
																						control={contractForm.control}
																						key={`select-em-${contractForm.watch(
																							'lenderCompanyAddressId.value'
																						)}-${contractForm.watch(
																							'lenderCompanyEmailId'
																						)}`}
																						name='lenderCompanyEmailId'
																						render={({ field }) => (
																							<NabuSelect
																								{...field}
																								key={`select-em-${contractForm.watch(
																									'lenderCompanyAddressId.value'
																								)}-${contractForm.watch(
																									'lenderCompanyEmailId'
																								)}`}
																								placeholder={
																									!props?.isPrint
																										? 'Select Email'
																										: ''
																								}
																								convertValueToUpperCase={false}
																								enableEmptySelection={true}
																								disabled={
																									props?.isPrint ||
																							!contractForm.watch(
																								'lenderCompanyAddressId.value'
																							)
																								}
																								options={getCompanyEmailOptions(
																									contractForm.watch(
																										'lenderCompanyAddressId.value'
																									)
																								)}
																								handleDefaultSelection={() => {
																								
																									return !contractForm.watch('lenderCompanyEmailId');}}
																							/>
																						)}
																					/>
																					{contractForm.formState?.errors
																						?.lenderCompanyEmailId?.message && (
																						<FormHelperText error={true}>
																							{
																								contractForm.formState?.errors
																									?.lenderCompanyEmailId?.message
																							}
																						</FormHelperText>
																					)}
																				</td>
																			</tr>
																		</table>
																	</Grid>
																</Grid>
															</div>
														</div>
													</div>
												</>
												{/* Main Contract Form End */}

												{/* W4 Short form */}
												{packetControlForm.watch('isW4shortForm') && (
													<div className={styles.defaultTemplate}>
														<div className={styles.contractFormBlock}>
															<ContractW4ShortForm
																contractForm={contractForm}
																isPrint={props?.isPrint}
															/>
														</div>
													</div>
												)}
												{/* W8 Ben Short form */}
												{packetControlForm.watch('isW8BenShort') && (
													<div className={styles.defaultTemplate}>
														<div className={styles.contractFormBlock}>
															<ContractW8BenShortForm
																contractForm={contractForm}
																isPrint={props?.isPrint}
															/>
														</div>
													</div>
												)}

												{/* W9 Ben Short form */}
												{packetControlForm.watch('isW9BenShort') && (
													<div className={styles.defaultTemplate}>
														<div className={styles.contractFormBlock}>
															<ContractW9BenShortForm
																contractForm={contractForm}
																isPrint={props?.isPrint}
															/>
														</div>
													</div>
												)}

												{/* Minor Trust form */}
												{packetControlForm.watch('isMinorTrust') && (
													<div className={styles.defaultTemplate}>
														<div className={styles.contractFormBlock}>
															<ContractMinorTrustForm
																contractForm={contractForm}
																isPrint={props?.isPrint}
															/>
														</div>
													</div>
												)}

												{/* NY-Wage-Theft-prevention  form */}
												{packetControlForm.watch('isNYTheftPreventionForm') && (
													<div className={styles.defaultTemplate}>
														<div className={styles.contractFormBlock}>
															<ContractNYWageTheftPreventionForm
																contractForm={contractForm}
																isPrint={props?.isPrint}
															/>
														</div>
													</div>
												)}

												{/* CA-Wage-Theft-prevention  form */}
												{packetControlForm.watch('isCATheftPreventionForm') && (
													<div className={styles.defaultTemplate}>
														<div className={styles.contractFormBlock}>
															<ContractCAWageTheftPreventionForm
																contractForm={contractForm}
																isPrint={props?.isPrint}
															/>
														</div>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>

					{!props?.isPrint && (
						<div className={styles.contractTemplateBtn}>
							<Grid container>
								<Grid xs={12} md={6}>
									<NabuButton variant='filled' onClick={onClickSnippetBtn}>
										{!contractFormState?.isPacketOpen ? (
											<>
												<NoteAdd /> Create
											</>
										) : (
											'Close'
										)}
									&nbsp; Packet
									</NabuButton>
								</Grid>
								<Grid xs={12} md={6} dir='rtl'>
									<NabuButton
										variant='outlined'
										data-cy='cancel_btn'
										shouldHandleConfirmAction={contractForm.formState.isDirty}
										onClick={() => handleFormClose()}
									>
									Cancel
									</NabuButton>{' '}
								&nbsp;
									<NabuButton
										variant='filled'
										type='submit'
										data-cy='save_btn'
										disabled={
											!(
												contractForm.formState.isDirty ||
											packetControlForm.formState.isDirty
											) ||
										isAddingContract ||
										isEditingContract
										}
									>
										{isAddingContract || isEditingContract ? 'Saving' : 'Save'}
										<Save />
									</NabuButton>{' '}
								&nbsp;
									<NabuButton
										variant='filled'
										onClick={contractForm.handleSubmit(handleSaveAndDuplicate)}
										disabled={
											!(
												contractForm.formState.isDirty ||
											packetControlForm.formState.isDirty
											) ||
										isAddingContract ||
										isEditingContract || (!(props?.mainForm?.watch('contractDisplayName'))&& !props?.isEdit)
										}
									>
									Save & Duplicate <CopyAll />
									</NabuButton>
								&nbsp;
								</Grid>
							</Grid>
						</div>
					)}
				</form>
			</div>
			<TalentDetailsForm
				handleTalentDetailsForm={(value) => handleTalentDetailsForm(value)}
				noOfTalentsSelected={noOfTalentsSelected}
				setNoOfTalentsSelected={(value) => setNoOfTalentsSelected(value)}
				isUnion={false}
			/>
			{isExpiryDateDialogOpen.isOpen && <ExpiryDateForm
				contractExpiryDate={isExpiryDateDialogOpen.contractExpiryDate || props?.initialValue?.expirationDate}
				isOpen={isExpiryDateDialogOpen.isOpen}
				handleExpiryDateForm={(values) => onSubmitOfExpiryDateForm(values)}
				handleFormClose={closeExpiryDateForm}
			/>}
		</>
	);
});

export default MsaCreatorAgreement;
