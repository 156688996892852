import {
	closestCenter,
	DndContext,
	DragEndEvent,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
} from '@dnd-kit/core';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';
import {
	arrayMove,
	SortableContext,
	sortableKeyboardCoordinates,
	useSortable,
	verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragIndicator } from '@mui/icons-material';
import { TableCell, TableRow } from '@mui/material';
import { ReactNode } from 'react';

export type TSortableAdditionalDetail = {
	newStackPosition: number;
	newIndex: number;
	oldIndex: number;
	updatedRow: any;
};

interface ITableBodyProps {
	children: ReactNode;
	rows?: any[];
	currentPage: number; // Page starts from 0
	pageSize: number;
	onRowSorted?: (
		rows: any[],
		additionalDetail?: TSortableAdditionalDetail
	) => void;
}

const TableBodyWrapper = (props: ITableBodyProps) => {
	const { children } = props;
	const rowIds =
		Array.from({ length: props?.rows?.length || 0 }, (_, i) => `sIdx-${i}`) ||
		[];

	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	);

	const handleRowSort = (event: DragEndEvent) => {
		const { active, over } = event;

		if (active.id !== over?.id) {
			const oldIndex =
				props?.rows?.findIndex((_, i) => `sIdx-${i}` == active.id) ?? -1;
			const newIndex =
				props?.rows?.findIndex((_, i) => `sIdx-${i}` == over?.id) ?? -1;
			return {
				sortedRows: arrayMove(props?.rows || [], oldIndex, newIndex),
				newIndex,
				oldIndex,
			};
		}
	};

	const handleDragEnd = (event: DragEndEvent) => {
		const result = handleRowSort(event);
		if (result) {
			const newStackPosition =
				props.currentPage * props.pageSize + result.newIndex;
			if (props?.onRowSorted) {
				const updatedRow = result?.sortedRows?.[result.newIndex];
				props?.onRowSorted(result?.sortedRows, {
					newStackPosition,
					newIndex: result.newIndex,
					oldIndex: result.oldIndex,
					updatedRow,
				});
			}
		}
	};

	return (
		<DndContext
			sensors={sensors}
			collisionDetection={closestCenter}
			onDragEnd={handleDragEnd}
			modifiers={[restrictToVerticalAxis]}
		>
			<SortableContext items={rowIds} strategy={verticalListSortingStrategy}>
				{children}
			</SortableContext>
		</DndContext>
	);
};

interface ITableRowProps {
	children: ReactNode;
	className?: string;
	isSortable?: boolean;
	isDisabled?: boolean;
	id: number;
}

const TableRowWrapper = (props: ITableRowProps) => {
	const { attributes, listeners, setNodeRef, transform, transition } =
		useSortable({ id: `sIdx-${props?.id}` });
	const dragProps = !props?.isDisabled ? { ...attributes, ...listeners } : {};

	const style = {
		transform: CSS.Transform.toString(transform),
		transition,
	};

	return (
		<TableRow
			data-cy='data-row'
			className={props?.className}
			ref={setNodeRef}
			style={style}
		>
			{props?.isSortable && (
				<TableCell key={`drag-${props?.id}`} width={'10px'}>
					<DragIndicator
						style={{ cursor: !props?.isDisabled ? 'grab' : 'not-allowed', outline: 'none' }}
						{...dragProps}
					/>
				</TableCell>
			)}
			{props.children}
		</TableRow>
	);
};

export { TableBodyWrapper, TableRowWrapper };
