import NabuButton from '@/common/components/atoms/button/button';
import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import LengthSelector from '@/common/components/atoms/lengthSelector/lengthSelector';
import { ILookAheadSelectOption } from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuMultiselect from '@/common/components/atoms/multiselect/multiselect';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuAntSwitch } from '@/common/components/atoms/switch/switch';
import { NabuTextArea } from '@/common/components/atoms/textArea/textArea';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import NabuDialog from '@/common/components/molecules/dialog/dialog';
import { DeliverableTypeOptions, EditingProvisionsOptions, PeriodUnitsOptions, mediaInfoMediaSubTypeDetailOptions, mediaInfoMediaSubTypeOptions, mediaInfoMediaTypeOptions, mediaType } from '@/common/constants/constants';
import { MEDIAINFOMEDIASUBTYPES, MEDIAINFOMEDIATYPES } from '@/common/enums';
import { StatusCodes } from '@/common/enums/StatusCodes';
import useNotification from '@/common/hooks/useNotification';
import { IRightsAcquiredForm } from '@/common/types/element';
import { getNotificationText } from '@/common/utils/utils';
import { createContractRightsAcquired, updateContractRightsAcquired } from '@/services/contract';
import { createRightsAcquired, updateRightsAcquired } from '@/services/element';
import { yupResolver } from '@hookform/resolvers/yup';
import { AccountTree, Add, DeleteForever, Save, TableView } from '@mui/icons-material';
import {
	Box,
	FormControl,
	FormControlLabel,
	FormHelperText,
	FormLabel,
	Grid, MenuItem,
	Radio,
	RadioGroup,
	Select,
	Stack,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	ToggleButton,
	ToggleButtonGroup,
	Tooltip
} from '@mui/material';
import { GridDeleteForeverIcon } from '@mui/x-data-grid';
import { Divider, Tree } from 'antd';
import { continents, countries } from 'countries-list';
import { Country } from 'country-state-city';
import { addDays as addDaysFns, addMonths, addWeeks, addYears } from 'date-fns';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Controller, FieldError, useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import styles from '../../../../company/components/companyList/companyList.module.scss';
import rightsAcquiredSchema from './schema';

interface Props {
	onClose: () => void;
	fetchRightsAcquired: () => void;
	isEdit: boolean;
	initialValue: any;
	slot?: any;
	refresh?: () => void;
	elementData?: any;
	isFromRenewalTerms: boolean;
	for: 'ELEMENT' | 'CONTRACT';
	isUnion?: boolean;
}

const AddRightsAcquiredForm = (props: Props) => {

	const continentsOptions = Object.keys(continents).map((key) =>
	{
		return {
			value: key,
			label: continents[key]
		};
	});
	
	const getContinentCountries = (continent: string) => {
		const continentCountries = Object.keys(countries)
			.filter((k) => k !== 'AQ')
			.map((k) => ({ ...countries[k], iso: k }))
			.sort((a, b) => a.name.localeCompare(b.name))
			.filter((c) => c.continent == continent)
			.map((c) => ({label: c.name.toUpperCase(), value: c.iso}));
		return continentCountries;
	};

	const CURRENCY_OPTIONS  = useMemo((): ILookAheadSelectOption[] =>
		Array.from(
			new Set(Country.getAllCountries().map((c) => c.currency))
		).sort()?.map(c => ({ label: c , value: c})), [Country]);

	const handleOnClose = () => {
		reset();
		props.onClose();
		props.fetchRightsAcquired();
		props.refresh && props.refresh();
	};

	const initialFormValue = { rightsAcquired: props.isEdit ? props.initialValue : {} };
	
	const form = useForm<IRightsAcquiredForm>({
		resolver: yupResolver(rightsAcquiredSchema(props?.for === 'CONTRACT' ? false : true)),
		mode: 'all',
		defaultValues: props.isEdit ? 
			{
				...initialFormValue.rightsAcquired,
				isTerritoryAddress: initialFormValue.rightsAcquired.isTerritoryAddress, 
				isTerritoryRegion: initialFormValue.rightsAcquired.isTerritoryRegion, 
				mediaType: initialFormValue.rightsAcquired.mediaType, 
				term: initialFormValue.rightsAcquired.term, 
				unit: initialFormValue.rightsAcquired.unit, 
				period:initialFormValue.rightsAcquired.period,
				startDate: initialFormValue?.rightsAcquired?.startDate ? new Date(initialFormValue.rightsAcquired.startDate) : '', 
				endDate: initialFormValue?.rightsAcquired?.endDate ? new Date(initialFormValue.rightsAcquired.endDate) : '', 
				territoryUsage: initialFormValue.rightsAcquired.territoryUsage, 
				territory: initialFormValue.rightsAcquired.territory && {...initialFormValue.rightsAcquired.territory}, 
				isCountryTerritory: initialFormValue.rightsAcquired.isCountryTerritory, 
				isRegionTerritory: initialFormValue.rightsAcquired.isRegionTerritory, 
				countries: initialFormValue.rightsAcquired.countries || [], 
				state: initialFormValue.rightsAcquired.state || '', 
				city: initialFormValue.rightsAcquired.city || '', 
				region: initialFormValue.rightsAcquired.region, 
				paid: initialFormValue.rightsAcquired.paid, 
				restrictionsApplied: initialFormValue.rightsAcquired.restrictionsApplied, 
				restrictions: initialFormValue.rightsAcquired.restrictions, 
				renewalOptions: initialFormValue.rightsAcquired.renewalOptions, 
				optionsForTalent: initialFormValue.rightsAcquired.optionsForTalent, 
				territoryAddresses: initialFormValue?.rightsAcquired?.territoryAddresses?.length >=1 ? initialFormValue.rightsAcquired.territoryAddresses : [{ country: '', state: '', city: ''}],
				mediaInformation: (initialFormValue?.rightsAcquired?.mediaInformation && initialFormValue?.rightsAcquired?.mediaInformation?.length > 0) ? [...(initialFormValue?.rightsAcquired?.mediaInformation || [])] : [{mediaSubType: '', mediaType: '', mediaSubTypeDetails: []}],
				deliverables: props?.for === 'CONTRACT' ? null 
					: (initialFormValue?.rightsAcquired?.deliverables && initialFormValue?.rightsAcquired?.deliverables?.length > 0) 
						? [...(initialFormValue?.rightsAcquired?.deliverables || [])] 
						: null,
			} : 
			{ mediaType: [], term: 'Limited', unit: '',  territoryUsage: false, territory: '', isTerritoryRegion: false, isTerritoryAddress: false, isCountryTerritory: false, isRegionTerritory: false, countries: [], state: '', city: '', territories: [{region: '', country: []}], paid: false, restrictionsApplied: false, restrictions: '', renewalOptions: false, optionsForTalent: false, territoryAddresses: [{ country: '', state: '', city: ''}],
				mediaInformation: [{mediaSubType: '', mediaType: '', mediaSubTypeDetails: []}],
			}
	});
	
	const { control, handleSubmit, reset, watch, formState, setError, setValue} = form;
	const {fields, append, remove} = useFieldArray({
		control,
		name: 'territories'
	});

	const mediaInformationFormFields = useFieldArray({
		control,
		name: 'mediaInformation'
	});

	const permittedDeliverablesFormFields = useFieldArray({
		control,
		name: 'deliverables'
	});

	const [termLimited, setTermLimited] = useState(false);
	const [territoryUsage, setTerritoryUsage] = useState(false);
	const [, setIsTerritoryCountry] = useState(false);
	const [, setIsTerritoryRegion] = useState(false);
	const [isRestrictionsApplied, setIsRestrictionsApplied] = useState(false);
	const [endDate, setEndDate] = useState<Date>();
	const notification = useNotification();
	const { elementId, contractId } = useParams();

	const {mutate}=useMutation({
		mutationFn: props?.for == 'ELEMENT' ? createRightsAcquired : createContractRightsAcquired,
		onSuccess: (res) => {	
			if(res.status === StatusCodes.CREATED){
				notification.notify({
					message: getNotificationText(props?.isFromRenewalTerms ? 'Renewal Terms' : 'Rights Contracted', 'CREATE'),
					type: 'success',
				});
				handleOnClose();
			}
			else {
				notification.notify({
					message: getNotificationText(props?.isFromRenewalTerms ? 'Renewal Terms' : 'Rights Contracted', 'CREATE', true),
					type: 'error',
				});
			}
		},
		onError: () =>
			notification.notify({
				message: getNotificationText(props?.isFromRenewalTerms ? 'Renewal Terms' : 'Rights Contracted', 'CREATE', true),
				type: 'error',
			}),	
	});

	//Rights Contracted Edit
	const {mutate: updateRightsAcquiredMutate}=useMutation({
		mutationFn:  props?.for == 'ELEMENT' ? updateRightsAcquired : updateContractRightsAcquired,
		onSuccess: (res) => {	
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText(props?.isFromRenewalTerms ? 'Renewal Terms' : 'Rights Contracted', 'UPDATE'),
					type: 'success',
				});
				handleOnClose();
			}
			else {
				notification.notify({
					message: getNotificationText(props?.isFromRenewalTerms ? 'Renewal Terms' :'Rights Contracted', 'UPDATE', true),
					type: 'error',
				});
			}
		},
		onError: () =>
			notification.notify({
				message: getNotificationText('Rights Contracted', 'UPDATE', true),
				type: 'error',
			}),	
	});

	const onSubmit = (data: any) => {
		if(data.unit == '' || data.period == '' || !data.startDate){
			let shouldReturn = false;
			if(!data.startDate){
				setError('startDate', { type: 'required', message: 'Start Date is required'});
				shouldReturn = true;
			}
			if(data.unit == '' && data.term == 'Limited'){
				setError('unit', { type: 'required', message: 'Unit is required' });
				shouldReturn = true;
			}
			if(data.period == '' && data.term == 'Limited'){
				setError('period', { type: 'required', message: 'Period is required' });
				shouldReturn = true;
			}
			if(shouldReturn)	
				return;
		}
		const territories = data?.territories?.filter((ta) => ta.region !== '');
		if(props.isEdit){
			const rightsAcquiredEditFormData = {
				...data,
				Id: initialFormValue.rightsAcquired.Id,
				oldTerritoryIds: initialFormValue.rightsAcquired.territories.map((ta) => ta.id),
				territories: territories,
				isRenewal: props.isFromRenewalTerms ? true : false
			};
			updateRightsAcquiredMutate(rightsAcquiredEditFormData);
		}
		else{
			const rightsAcquiredAddFormData = {
				...data,
				elementId: elementId,
				contractId,
				territories: territories,
				isRenewal: props.isFromRenewalTerms ? true : false
			};
			mutate(rightsAcquiredAddFormData);
		}
	};

	const addDays = () => {
		if(watch('period') && watch('startDate') && watch('unit')){
			const days = Number(watch('period'));
			const startDate = new Date(watch('startDate'));
			const date = startDate.setDate(startDate.getDate() - 1);
			const unit = watch('unit');
			if(date){
				let finalDate;
				if(unit == 'WEEK (S)'){
					finalDate = (addWeeks(new Date(date), days));
				}
				else if(unit == 'MONTH (S)'){
					finalDate = addMonths(new Date(date), days);
				}
				else if(unit == 'YEAR (S)'){
					finalDate = addYears(new Date(date), days);
				}
				else{
					finalDate = addDaysFns(new Date(date), days);
				}
				form.setValue('endDate',finalDate);
				setEndDate(finalDate);
			}
		}
	};
	
	const onChangeCost = () => {
		const costs = Number(watch('costs')) || 0;
		const procurementFee = Number(watch('procurementFee')) || 0;
	
		let totalCost = costs;
	
		if (costs && procurementFee) {
			const percentageValue = costs * (procurementFee / 100);
			totalCost += percentageValue;
		}
	
		totalCost = parseFloat(totalCost.toFixed(2));
	
		setValue('totalCost', totalCost);
	};
	

	const [, setSelectedIds] = useState<any[]>([]);
	const [checkedNodes, setCheckedNodes] = useState<any[]>([]);
	const [, setAllCheckedNodes] = useState<any[]>([]);

	useEffect(() => {
		if(props.isEdit){
			const selectedValues: any[] = [];
			selectedValues.push(form?.watch('mediaType'));
			form.watch('mediaType')?.map(e => selectedValues.push(e));
			form.watch('rightsCoverageMainType')?.map(e => selectedValues.push(e));
			form.watch('rightsCoverageSubType')?.map(e => selectedValues.push(e));
			const selectedValuesIds = mediaType.filter(e => selectedValues.includes(e?.label)).map(e => e?.id);
			setSelectedIds(selectedValuesIds);
			setCheckedNodes(form.watch('checkedMediaTypeIds')|| []);
			setAllCheckedNodes(selectedValuesIds);
		}
	}, []);

	const [mediaMainTypeValues, setMediaMainTypeValues] = useState<{data: any, open: boolean, title: string}>({
		open: false,
		data: [],
		title: ''
	});

	const [mediaTypeValues, setMediaTypeValues] = useState<{data: any, open: boolean, title: string}>({
		open: false,
		data: [],
		title: ''
	});

	const [selectedMediaTypeDialog, setSelectedMediaTypeDialog] = useState<{
		isOpen: boolean,
		mediaType: any,
		selectedNodes: []
	}>({
		isOpen: false,
		mediaType: [],
		selectedNodes: []
	});

	const TABLEVIEW = 'TABLEVIEW';
	const TREEVIEW = 'TREEVIEW';
	const [mediaTypeView, setMediaTypeView] = useState<string>(TABLEVIEW);

	const resetTerritoriesValues = () => {
		form.getValues('territories')?.map((_,index) => {
			form.setValue(`territories.${index}`, {countries: [], region: ''});
		});
	};

	const getMediaTypeOptions = (unionType: string) => mediaInfoMediaTypeOptions[unionType]?.mediaTypes;
	const getMediaSubTypeOptions = (mediaType: string) => mediaInfoMediaSubTypeOptions[mediaType]?.mediaSubTypes;
	const getMediaSubTypeDetailsOptions = (mediaSubType: string) => mediaInfoMediaSubTypeDetailOptions[mediaSubType]?.mediaSubTypeDetails;
	const errors = formState.errors;
	const numberDefinedValue = 'NUMBER DEFINED';

	const deliverableTypeOptions = DeliverableTypeOptions.map((option) => {
		return {
			label: option,
			value: option
		};
	});

	const editingProvisionOptions = EditingProvisionsOptions.map((option) => {
		return {
			label: option,
			value: option
		};
	});

	const UNIONVALUE = 'UNION';
	const NONUNIONVALUE = 'NON UNION';

	return (
		<>
			<br></br>
			<div className={!props.isEdit && styles.inlineEdit || styles.EditRihtsContracted}>
				<section data-cy='add_payment_section'>
					<Grid item container columnSpacing={3} sx={{ pb: 1 }}>
						{!props.isEdit ? 
							<Grid item xs={12} md={6}>
								<h3>{props.isFromRenewalTerms ? 'Add Renewal Terms' : 'Add Rights Contracted'}</h3>
							</Grid> : 
							<Grid item xs={12} md={6}>
								<h3>{props.isFromRenewalTerms ? 'Edit Renewal Terms' : 'Edit Rights Contracted'}</h3>
							</Grid>}

						<Grid item md={12}>
							<Table className={styles.customTableMediaType}>
								<TableHead sx={{borderRadius:'8px'}}>
									<TableRow>
										<TableCell width={'25%'}>Media Type <sup>*</sup></TableCell>
										<TableCell width={'25%'}>Media Subtype <sup></sup></TableCell>
										<TableCell width={'30%'}>Media Subtype Details <sup></sup></TableCell>
										<TableCell width={'20%'}></TableCell>
									</TableRow>
								</TableHead>
								<TableBody>
									{/* Row 1 */}
									{ mediaInformationFormFields.fields.map((field, index) => (
										<TableRow key={field.id}>
											<TableCell width={'25%'}>
												<FormControl fullWidth>

													<Controller
														control={control}
														name={`mediaInformation.${index}.mediaType`}
														defaultValue=''
														render={({ field }) => (
															<NabuSelect
																{...field}
																options={getMediaTypeOptions(props?.isUnion ? UNIONVALUE : NONUNIONVALUE)?.map(item => {
																	return { value: item, label: item };
																})}
																onChange={(e) => {
																	field.onChange(e);
																	setValue(`mediaInformation.${index}.mediaSubType`, '');
																} }
																placeholder={'Media Type'} />
														)} />
													{formState?.errors?.mediaInformation && formState.errors.mediaInformation[index]?.mediaType && (
														<FormHelperText data-cy='rights_coverage_required' error={true}>
															{formState.errors.mediaInformation[index]?.mediaType?.message}
														</FormHelperText>)}
												</FormControl>
											</TableCell>
											<TableCell width={'25%'}>
												<FormControl fullWidth>

													<Controller
														control={control}
														name={`mediaInformation.${index}.mediaSubType`}
														defaultValue=''
														render={({ field }) => (
															<NabuSelect
																{...field}
																options={getMediaSubTypeOptions(watch(`mediaInformation.${index}.mediaType`))?.map(item => {
																	return { value: item, label: item };
																})}
																placeholder={'Media Subtype'}
																onChange={(e) => {
																	field.onChange(e);
																	setValue(`mediaInformation.${index}.mediaSubTypeDetails`, []);
																} } />
														)} />
												</FormControl>
											</TableCell>
											<TableCell width={'25%'}>
												<FormControl fullWidth sx={{maxWidth:500}}>

													<Controller
														control={control}
														name={`mediaInformation.${index}.mediaSubTypeDetails`}
														defaultValue={[]}
														render={({ field }) => (
															<NabuMultiselect
																{...field}
																options={
																	!props?.isUnion ? [] :
																		getMediaSubTypeDetailsOptions(
																			(watch(`mediaInformation.${index}.mediaSubType`) === MEDIAINFOMEDIASUBTYPES.WILDSPOT && 
																		(watch(`mediaInformation.${index}.mediaType`) === MEDIAINFOMEDIATYPES.AUDIO_RADIO_COMMERCIAL 
																			|| watch(`mediaInformation.${index}.mediaType`) === MEDIAINFOMEDIATYPES.AUDIO_FLEX)
																			) 
																				? `${MEDIAINFOMEDIATYPES.AUDIO_RADIO_COMMERCIAL}${MEDIAINFOMEDIASUBTYPES.WILDSPOT}` 
																				: watch(`mediaInformation.${index}.mediaSubType`))?.map(item => {
																			return { value: item, label: item };
																		})}
																placeholder={'MEDIA SUBTYPE DETAILS'}
																selectedItems={watch(`mediaInformation.${index}.mediaSubTypeDetails`) || []} />
														)} />
												</FormControl>
											</TableCell>
											<TableCell width={'20%'}>
												{index !== 0 && (
													<NabuButton
														variant='text'
														size='small'
														onClick={() => mediaInformationFormFields.remove(index)}
														data-cy='delete_btn'
													>
														<GridDeleteForeverIcon color='error' />
													</NabuButton>
												)}
												
												{index === mediaInformationFormFields?.fields?.length-1 && <NabuButton onClick={() => mediaInformationFormFields.append({ mediaType: '', mediaSubType: '', mediaSubTypeDetails: [] })} variant='filled'> <Add/>  Media Information</NabuButton>}
											</TableCell>
										</TableRow>
									))}
								</TableBody>
							</Table>
						</Grid>
						{/*{ mediaInformationFormFields.fields.map((field, index) => (
							
							<><Grid key={field.id} container item xs={12} columnSpacing={3}>
								<Grid item md={3}>
									
								</Grid>
								<Grid item md={3}>
									
								</Grid>
								<Grid item md={3}>
									
								</Grid>

								<Grid item md={1} sx={{ mt: 0 }}>
									
								</Grid>

								<Grid item xs={2} dir='rtl' sx={{ mt: 0 }}>

									

								</Grid>
							</Grid><hr></hr></>
						
						))}*/}

						<Grid item xs={12} md={2.4}>
							<FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
								<FormLabel id='demo-row-radio-buttons-group-label' data-cy='term_label'>
								Term <sup>*</sup>
								</FormLabel>
								<Controller
									control={control}
									name='term'
									render={({ field }) => (
										<RadioGroup
											row
											aria-labelledby='demo-row-radio-buttons-group-label'
											data-cy='term_group_checkbox'
											{...field}
										>
											<FormControlLabel
												value='Limited'
												control={<Radio />}
												label='Limited'
												data-cy='yes_term'
												onChange={() => {setTermLimited(true); setValue('startDate', ''); setValue('endDate', '');}}
											/>
											<FormControlLabel
												value='In Perpetuity'
												control={<Radio />}
												label='In Perpetuity'
												data-cy='no_term'
												onChange={() => {setTermLimited(false); setValue('unit', ''); setValue('period', ''); setValue('endDate', ''); setValue('startDate', '');}}
											/>
										</RadioGroup>
									)}
								/>
								{ formState.errors.term && (
									<FormHelperText data-cy='rights_coverage_required' error={true}>
										{formState.errors.term?.message}
									</FormHelperText>)
								}
							</FormControl>
						</Grid>

						{termLimited || watch('term') == 'Limited' ? 
							<Grid item xs={12} md={2.4}>
								<FormControl fullWidth>
									<FormLabel
										id='select-field-demo-label'
										htmlFor='select-field-demo-button'
										data-cy='period_label'
									>
									Period <sup>*</sup>
									</FormLabel>
									<Controller
										control={control}
										name='period'
										defaultValue=''
										render={({ field }) => (
											<NabuTextbox
												{...field} 
												data-cy='period_input' 
												placeholder='Period'
												onChange={e => {
													if(parseInt(e?.target?.value) >= 1 || e?.target?.value ==='') field.onChange(e);
													addDays();
												}}/>
										)}
									/>
									{ formState.errors.period && (
										<FormHelperText data-cy='rights_coverage_required' error={true}>
											{formState.errors.period?.message}
										</FormHelperText>)
									}
								</FormControl>
							</Grid>
							: null 
						}

						{termLimited || watch('term') == 'Limited' ? 
							<Grid item xs={12} md={2.4}>
								<FormControl fullWidth>
									<FormLabel data-cy='unit_label'>
									Unit <sup>*</sup>
									</FormLabel>
									<Controller
										control={control}
										name='unit'
										defaultValue=''
										render={({ field }) => (
											<Select
												size='small'
												placeholder='Select Unit'
												data-cy='unit_type_select'
												displayEmpty={true}
												{...field}
												onChange={(e: any) => { field.onChange(e);addDays();}}
											>
												<MenuItem disabled value=''>
												Select Unit
												</MenuItem>
												{PeriodUnitsOptions.map((type,index) => (
													<MenuItem
														key={index}
														value={type}
													>
														{type}
													</MenuItem>
												))}
											</Select>
										)}
									/>
									{ formState.errors.unit && (
										<FormHelperText data-cy='rights_coverage_required' error={true}>
											{formState.errors.unit?.message}
										</FormHelperText>)
									}
								</FormControl>
							</Grid>
							: null
						}

						<Grid item xs={12} md={2.4}>
							<FormControl fullWidth>
								<FormLabel
									id='select-field-demo-label'
									htmlFor='select-field-demo-button'
									data-cy='start_date_label'
								>
								Start Date <sup>*</sup>
								</FormLabel>
								<Controller
									control={control}
									name='startDate'
									render={({ field }) => (
										<NabuDatepicker {...field} 
											key={`startDate-${watch('term')}`}
											dis
											onChange={(e) => {
												field.onChange(e);
												addDays();
											}}/>
									)}
								/>
								{ formState.errors.startDate && (
									<FormHelperText data-cy='startDate_required' error={true}>
										{formState.errors.startDate?.message}
									</FormHelperText>)
								}
							</FormControl>
						</Grid>

						{termLimited || watch('term') == 'Limited' ? 
							<Grid item xs={12} md={2.4}>
								<FormControl fullWidth>
									<FormLabel
										id='select-field-demo-label'
										htmlFor='select-field-demo-button'
										data-cy='end_date_label'
									>
									End Date <sup></sup>
									</FormLabel>
									<Controller
										control={control}
										name='endDate'
										render={({ field }) => (
											<NabuDatepicker 
												key={`endDate-${watch('term')}`} 
												{...field}
												value={endDate || watch('endDate')} 
											/>
										)}
									/>
								</FormControl>
							</Grid>
							: null }

						<Grid item xs={12} md={2.4}>
							<FormControl fullWidth>
								<FormLabel
									id='select-field-demo-label'
									htmlFor='select-field-demo-button'
									data-cy='end_date_label'
								>
									Currency <sup></sup>
								</FormLabel>
								<Controller
									control={control}
									name='currency'
									defaultValue='USD'
									render={({ field }) => (
										<NabuSelect 
											{...field}
											options={CURRENCY_OPTIONS}
											placeholder={'Currency'}
										/>
									)}
								/>
							</FormControl>
						</Grid>

						<Grid item xs={12} md={2.4}>
							<FormControl fullWidth>
								<FormLabel
									id='select-field-demo-label'
									htmlFor='select-field-demo-button'
									data-cy='end_date_label'
								>
									Costs <sup></sup>
								</FormLabel>
								<Controller
									control={control}
									name='costs'
									render={({ field }) => (
										<NabuTextbox
											{...field} 
											data-cy='cost_input' 
											placeholder='Costs'
											onChange={(e: any) => {
												const value = e?.target?.value || '';
												if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
													field.onChange(value);
												} else {
													const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
													field.onChange(num);
												}
												onChangeCost();
											}}
											onBlur={() => {
												const numericValue = parseFloat((field.value ?? '0').toString());
												if (!isNaN(numericValue)) {
													field.onChange(numericValue.toFixed(2));
												} else {
													field.onChange('0.00');
												}
											}}/>
									)}
								/>
							</FormControl>
						</Grid>

						<Grid item xs={12} md={2.4}>
							<FormControl fullWidth>
								<FormLabel
									id='select-field-demo-label'
									htmlFor='select-field-demo-button'
									data-cy='end_date_label'
								>
									Handling/Mark Up %<sup></sup>
								</FormLabel>
								<Controller
									control={control}
									name='procurementFee'
									render={({ field }) => (
										<NabuTextbox
											{...field} 
											data-cy='agentPercentage_input' 
											placeholder='Handling/Mark Up %'
											onChange={(e: any) => {
												const inputValue = e.target.value;
		
												if (/^\d*\.?\d{0,2}$/.test(inputValue) && (inputValue === '' || parseFloat(inputValue) <= 100)) {
													field.onChange(inputValue);
												}
												onChangeCost();
											}}
											onBlur={() => {
												const numericValue = parseFloat(String(field.value));
												if (!isNaN(numericValue)) {
													const cappedValue = Math.min(100, numericValue);
													field.onChange(cappedValue.toFixed(2));
												} else {
													field.onChange('');
												}
											}}
										/>
									)}
								/>
							</FormControl>
						</Grid>

						<Grid item xs={12} md={2.4}>
							<FormControl fullWidth>
								<FormLabel
									id='select-field-demo-label'
									htmlFor='select-field-demo-button'
									data-cy='end_date_label'
								>
									Total Cost <sup></sup>
								</FormLabel>
								<Controller
									control={control}
									name='totalCost'
									render={({ field }) => (
										<NabuTextbox
											{...field} 
											data-cy='renewalCost_input' 
											placeholder='Total Cost'
											onChange={(e: any) => {
												const value = e?.target?.value || '';
												if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
													field.onChange(value);
												} else {
													const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
													field.onChange(num);
												}
											}}
											onBlur={() => {
												const numericValue = parseFloat((field.value ?? '0').toString());
												if (!isNaN(numericValue)) {
													field.onChange(numericValue.toFixed(2));
												} else {
													field.onChange('0.00');
												}
											}}/>
									)}
								/>
							</FormControl>
						</Grid>

						{termLimited || watch('term') == 'Limited' ?
							<Grid item xs={12} md={2.4}>
								<FormControl fullWidth>
									<FormLabel
										id='select-field-demo-label'
										htmlFor='select-field-demo-button'
										data-cy='end_date_label'
									>
									Renewal Costs <sup></sup>
									</FormLabel>
									<Controller
										control={control}
										name='renewalCost'
										render={({ field }) => (
											<NabuTextbox
												{...field} 
												data-cy='renewalCost_input' 
												placeholder='Renewal Costs'
												onChange={(e: any) => {
													const value = e?.target?.value || '';
													if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
														field.onChange(value);
													} else {
														const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
														field.onChange(num);
													}
												}}
												onBlur={() => {
													const numericValue = parseFloat((field.value ?? '0').toString());
													if (!isNaN(numericValue)) {
														field.onChange(numericValue.toFixed(2));
													} else {
														field.onChange('0.00');
													}
												}}
											/>
										)}
									/>
								</FormControl>
							</Grid> : null
						}

						<Grid item xs={6} md={2.4}>
							<FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
								<FormLabel id='demo-row-radio-buttons-group-label' data-cy='paid_label'>
								Paid<sup></sup>
								</FormLabel>
								<Controller
									control={control}
									name='paid'
									render={({ field }) => (
										<RadioGroup
											row
											aria-labelledby='demo-row-radio-buttons-group-label'
											data-cy='paid_radio_group'
											{...field}
										>
											<FormControlLabel
												value={true}
												control={<Radio />}
												label='Yes'
												data-cy='yes_paid_checkbox'
											/>
											<FormControlLabel
												value={false}
												control={<Radio />}
												label='No'
												data-cy='no_paid_checkbox'
											/>
										</RadioGroup>
									)}
								/>
							</FormControl>
						</Grid>

						{termLimited || watch('term') == 'Limited' ?
							<Grid item xs={6} md={2.4}>
								<FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
									<FormLabel id='demo-row-radio-buttons-group-label' data-cy='renewal_opt_label'>
								Renewal Options <sup></sup>
									</FormLabel>
									<Controller
										name='renewalOptions'
										control={control}
										render={({ field }) => (
											<RadioGroup
												row
												aria-labelledby='demo-row-radio-buttons-group-label'
												data-cy='renewal_radio'
												{...field}
											>
												<FormControlLabel
													value={true}
													control={<Radio />}
													label='Yes'
													data-cy='yes_renewal_radio'
												/>
												<FormControlLabel
													value={false}
													control={<Radio />}
													label='No'
													data-cy='no_renewal_radio'
												/>
											</RadioGroup>
										)}
									/>
								</FormControl>
							</Grid> : null
						}

						<Grid item xs={6} md={2.4}>
							<FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
								<FormLabel id='demo-row-radio-buttons-group-label' data-cy='renewal_opt_label'>
								Options For Talent<sup></sup>
								</FormLabel>
								<Controller
									name='optionsForTalent'
									control={control}
									render={({ field }) => (
										<RadioGroup
											row
											aria-labelledby='demo-row-radio-buttons-group-label'
											data-cy='renewal_radio'
											{...field}
										>
											<FormControlLabel
												value={true}
												control={<Radio />}
												label='Yes'
												data-cy='yes_renewal_radio'
											/>
											<FormControlLabel
												value={false}
												control={<Radio />}
												label='No'
												data-cy='no_renewal_radio'
											/>
										</RadioGroup>
									)}
								/>
							</FormControl>
						</Grid>

						<Grid item xs={12} md={2.4}>
							<FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
								<FormLabel id='demo-row-radio-buttons-group-label' data-cy='restriction_label'>
								Restrictions Applied <sup></sup>
								</FormLabel>	
								<Controller
									control={control}
									name='restrictionsApplied'
									render={({ field }) => (
										<RadioGroup
											row
											aria-labelledby='demo-row-radio-buttons-group-label'
											data-cy='restriction_radio'
											{...field}
										>
											<FormControlLabel
												value={true}
												control={<Radio />}
												label='Yes'
												data-cy='yes_restriction_radio'
												onChange={() => setIsRestrictionsApplied(true)}
											/>
											<FormControlLabel
												value={false}
												control={<Radio />}
												label='No'
												data-cy='no_restriction_radio'
												onChange={() => {setIsRestrictionsApplied(false); setValue('restrictionsApplied',false); setValue('restrictions', '');}}
											/>
										</RadioGroup>
									)}
								/>
							</FormControl>
						</Grid>

						{isRestrictionsApplied || watch('restrictionsApplied') == true ?
							<Grid item xs={12} md={2.4}>
								<FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
									<FormLabel id='demo-row-radio-buttons-group-label' data-cy='restriction_label'>
									Restrictions<sup></sup>
									</FormLabel>
									<Controller
										name='restrictions'
										control={control}
										render={({ field }) => (
											<NabuTextArea {...field} minRows={1} data-cy='restirctions_input'/>
										)} />
								</FormControl>
							</Grid>
							: null
						}

						<Grid item xs={12} md={2.4}>
							<FormControl fullWidth>
								<FormLabel id='demo-row-radio-buttons-group-label' data-cy='territory_usage_label'>
									{/*NOTE 4 Dev: territory usage: backend field(no need to display in UI, 
									set value in the backend based on territory info field) */}
								Territory Usage? <sup></sup>
								</FormLabel>
								<Controller
									control={control}
									name='territoryUsage'
									render={({ field }) => (
										<RadioGroup
											row
											aria-labelledby='demo-row-radio-buttons-group-label'
											data-cy='territory_usage_group_checkbox'
											{...field}
										>
											<FormControlLabel
												value={true}
												control={<Radio />}
												label='Yes'
												data-cy='yes_usage'
												onChange={() => {
													setTerritoryUsage(true);
													props?.isEdit && append({countries: [], region: ''});
												}}
											/>
											<FormControlLabel
												value={false}
												control={<Radio />}
												label='No'
												data-cy='no_usage'
												onChange={() => {
													setIsTerritoryRegion(false);
													setIsTerritoryCountry(false);
													setTerritoryUsage(false);
													setValue('isTerritoryRegion', false);
													setValue('isTerritoryAddress', false);
													setValue('territoryAddresses', [{country: '', state: '', city: ''}]);
													setValue('region', []);
													setValue('isWorldWide', false);
													props?.isEdit && remove(0);
												}}
											/>
										</RadioGroup>
									)}
								/>
							</FormControl>
						</Grid>
						{(territoryUsage || (props?.initialValue?.territoryUsage && watch('territoryUsage')?.toString() === 'true')) ?
							<>
								<Grid item md={12}>
									<hr></hr>
								</Grid>
								<Grid item md={2.25}>
									<FormControl fullWidth>
										<FormLabel
											id='select-field-demo-label'
											htmlFor='select-field-demo-button'
											data-cy='region_label'
										>
														Is WorldWide <sup></sup>
										</FormLabel>
										<Controller
											control={control}
											name={'isWorldWide'}
											render={({ field }) => (
												<NabuAntSwitch
													{...field}
													size='medium'
													checked={field.value || watch('isWorldWide')}
													value={field.value}
													color='primary'
													onChange={(_, checked) => {
														if(checked){
															resetTerritoriesValues();
														}
														else{
															fields.length == 0 && append({countries: [], region: ''});
														}
														field.onChange(checked);
													}}
												/>
											)}
										/>
									</FormControl>
								</Grid>
								{!watch('isWorldWide') && fields.map((region, index) => {
									return(
										<Grid item md={9.75} key={region.id}>
											<Grid item container columnSpacing={3}>
												<Grid item xs={12} md={3}>
													<FormControl fullWidth>
														<FormLabel
															id='select-field-demo-label'
															htmlFor='select-field-demo-button'
															data-cy='region_label'
														>
														Region <sup></sup>
														</FormLabel>
														<Controller
															control={control}
															name={`territories.${index}.region`}
															defaultValue={''}
															render={({ field }) => (
																<>
																	<NabuSelect
																		size='small'
																		placeholder='Select Region Type'
																		data-cy='region_select_input'
																		{...field}
																		onChange={(e: any) => { field.onChange(e); }}
																		options={continentsOptions}
																		disabled={watch('isWorldWide') || false}
																	>
																	</NabuSelect>
																</>
															)} />
													</FormControl>
												</Grid>
												<Grid item xs={12} md={3} >
													<FormControl fullWidth>
														<FormLabel
															id='select-field-demo-label'
															htmlFor='select-field-demo-button'
															data-cy='country_label'
														>
														Country <sup></sup>
														</FormLabel>
														<Controller
															control={control}
															name={`territories.${index}.countries`}
															defaultValue={[]}
															render={({ field }) => (
																<NabuMultiselect
																	size='small'
																	placeholder='SELECT COUNTRY	'
																	data-cy='country_select_input'
																	{...field}
																	onChange={(e: any) => { field.onChange(e); form.trigger('territories');}}
																	options={getContinentCountries(watch(`territories.${index}.region`))}
																	selectedItems={watch(`territories.${index}.countries`)}
																	disabled={watch('isWorldWide') || false}
																>
																</NabuMultiselect>
															)}
														/>
													</FormControl>
												</Grid>
												{index > 0 && <Grid item xs={12} md={0.5} >
													<Box sx={{pt:3.5}}>
														<NabuButton onClick={() => remove(index)} variant='text'><DeleteForever color='error' /></NabuButton> &nbsp;
													</Box>
												</Grid>}
												{index == (fields?.length-1) && <Grid item xs={12} md={3} >
													<Box sx={{pt:3.5}}>
														<NabuButton variant='text' onClick={() => append({countries: [], region: ''})}>
															<Add/> Additional Territory
														</NabuButton>
													</Box>
												</Grid>}
											</Grid>
										</Grid>
									);
								})}
								<Grid item xs={12}>
									<FormHelperText error={true} key={`territory_${watch('territories')}`}>
										{formState.errors?.territories?.message || ''}
									</FormHelperText>
								</Grid>
							</>
							: null
						}
						{permittedDeliverablesFormFields.fields.length === 0 && (
							<Grid item xs={12}  sx={{mt: 2, mb: 2}}>
								<NabuButton onClick={() => permittedDeliverablesFormFields.append({ numberOfAssets: '', type: '', length: 0, editingProvisions: 'UNLIMITED', noOfEdits: '' })} variant='filled'><Add /> Permitted Deliverable  </NabuButton>
							</Grid>)}
						{(props.for === 'ELEMENT' && permittedDeliverablesFormFields.fields.length > 0) && <>
							<Grid item xs={12} sx={{mt: 2, mb: 2}}>
								<h4>Permitted Deliverables Tracker</h4>
							</Grid>
							<Grid item xs={12}>
								<Table className={styles.customTableMediaType}>
									<TableHead sx={{borderRadius:'8px'}}>
										<TableRow>
											<TableCell>Number Of Assets<sup>*</sup></TableCell>
											<TableCell>Type<sup>*</sup></TableCell>
											<TableCell width={90}>Length <sup></sup></TableCell>
											<TableCell>Editing Provisions <sup></sup></TableCell>		
											<TableCell>Number Of Edits Allowed<sup></sup>	</TableCell>									
											<TableCell></TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										{/* Row 1 */}
										{ permittedDeliverablesFormFields.fields.map((field, index) => (
											<TableRow key={field.id}>
												<TableCell>
													<FormControl fullWidth>
														<Controller
															name={`deliverables.${index}.numberOfAssets`}
															control={form.control}
															render={({ field }) => (
																<NabuTextbox
																	className={styles.websiteInput}
																	data-cy='person-firstname'
																	{...field}
																	onChange={e => {
																		if (parseInt(e?.target?.value) >= 1 || e?.target?.value === '') field.onChange(e);
																	} }
																	placeholder={'NUMBER OF ASSETS'}
																	error={errors?.deliverables && errors?.deliverables[index]?.numberOfAssets ? true : false}
																	helperText={errors?.deliverables && errors?.deliverables[index]?.numberOfAssets && errors?.deliverables[index]?.numberOfAssets?.message} />
															)} />
													</FormControl>
												</TableCell>
												<TableCell>
													<FormControl fullWidth>
														<Controller
															name={`deliverables.${index}.type`}
															control={control}
															render={({ field }) => (
																<NabuSelect
																	data-cy='contact_name_select'
																	placeholder='Select Type'
																	{...field}
																	error={errors?.deliverables && errors?.deliverables[index]?.type ? true : false}
																	helperText={(errors?.deliverables && errors.deliverables[index]?.type as FieldError)?.message}
																	options={deliverableTypeOptions} />
															)} />
													</FormControl>
												</TableCell>
												<TableCell>
													<FormControl fullWidth className={styles.lengthField}>
														<Controller
															control={form.control}
															name={`deliverables.${index}.length`}
															render={({ field }) => (
																<LengthSelector
																	{...field}
																	placeholder='Length (MM : SS)'
																	rootClassName={styles.assetLengthSelector} />
															)} />
													</FormControl>
										
												</TableCell><TableCell>
													<FormControl fullWidth>
														<Controller
															name={`deliverables.${index}.editingProvisions`}
															control={form.control}
															render={({ field }) => (
																<NabuSelect
																	data-cy='contact_name_select'
																	placeholder='Select Editing Provision'
																	{...field}
																	options={editingProvisionOptions}
																	onChange={(e) => {
																		field.onChange(e);
																		if (e?.target?.value === 'UNLIMITED') {
																			form.setValue(`deliverables.${index}.noOfEdits`, '');
																		}
																	} } />
															)} />
													</FormControl>
												</TableCell><TableCell>
													{form.watch(`deliverables.${index}.editingProvisions`) == numberDefinedValue && <FormControl fullWidth>
														<Controller
															name={`deliverables.${index}.noOfEdits`}
															control={form.control}
															render={({ field }) => (
																<NabuTextbox
																	className={styles.websiteInput}
																	data-cy='person-firstname'
																	{...field}
																	onChange={e => {
																		if (parseInt(e?.target?.value) >= 1 || e?.target?.value === '') field.onChange(e);
																	} }
																	placeholder={'NUMBER OF EDITS'}
																	error={errors?.deliverables && errors?.deliverables[index]?.noOfEdits ? true : false}
																	helperText={errors?.deliverables && errors?.deliverables[index]?.noOfEdits && errors?.deliverables[index]?.noOfEdits?.message} />
															)} />
													</FormControl>}
												</TableCell><TableCell>
													{/* {index !== 0 && ( */}
													<NabuButton
														variant='text'
														size='small'
														onClick={() => permittedDeliverablesFormFields.remove(index)}
														data-cy='delete_btn'
													>
														<GridDeleteForeverIcon color='error' />
													</NabuButton>
													{index === permittedDeliverablesFormFields?.fields?.length - 1 && <NabuButton onClick={() => permittedDeliverablesFormFields.append({ numberOfAssets: '', type: '', length: 0, editingProvisions: 'UNLIMITED', noOfEdits: '' })} variant='filled'><Add /> Permitted Deliverable  </NabuButton>}
												</TableCell>
											</TableRow>
										))}
									</TableBody>
								</Table>
							</Grid>
						</>}
						<Grid item xs={12} dir='rtl'>
							<div style={{float:'right'}}>
								<NabuButton data-cy='cancel_btn' variant='outlined' onClick={props.onClose} shouldHandleConfirmAction={formState.isDirty}> Cancel</NabuButton>
						&nbsp;
								<NabuButton data-cy='save_btn' variant='filled' disabled={!formState.isDirty} onClick={handleSubmit(onSubmit)}>
							Save <Save />
								</NabuButton>
						&nbsp;
							</div>
						</Grid>

					</Grid>
					<Fragment>
						<NabuDialog
							open={mediaTypeValues.open}
							title={mediaTypeValues.title}
							dialogActions={[]}
							handleClose={() =>
								setMediaTypeValues({ open: false, data: [], title: ''})
							}
						>
							<Divider />
							<Stack mt={2}>
								<ul>
									{mediaTypeValues.data?.map((mediaType, i) => (
										<li key={i}>{mediaType}</li>
									))}
								</ul>
							</Stack>
						</NabuDialog>
					</Fragment>
					<Fragment>
						<NabuDialog
							open={mediaMainTypeValues.open}
							title={mediaMainTypeValues.title}
							dialogActions={[]}
							handleClose={() =>
								setMediaMainTypeValues({ open: false, data: [], title: ''})
							}
						>
							<Divider />
							<Stack mt={2}>
								<ul>
									{mediaMainTypeValues.data?.map((mediaType, i) => (
										<li key={i}>{mediaType}</li>
									))}
								</ul>
							</Stack>
						</NabuDialog>
					</Fragment>
					<Fragment>
						<NabuDialog
							open={selectedMediaTypeDialog.isOpen}
							maxWidth={'lg'}
							title={'Selected Media Type'}
							dialogActions={[]}
							handleClose={() =>
								setSelectedMediaTypeDialog({ isOpen: false, mediaType: [], selectedNodes : []})
							}
						>
							<Grid container>
								<Grid item sm={12} dir='rtl'>
									<ToggleButtonGroup 
										dir="ltr"
										color="primary"
										value={mediaTypeView}
										exclusive
										aria-label="Platform"
										className={styles.trackerGridListSection}
									>
										<ToggleButton 
											value={TABLEVIEW} 
											onClick={() => setMediaTypeView(TABLEVIEW)} 
											className={styles.buttonsList}
										>
											<Tooltip title='Tabular View' arrow><TableView /></Tooltip>
										</ToggleButton>
										<ToggleButton 
											value={TREEVIEW}
											onClick={() => setMediaTypeView(TREEVIEW)}
											className={styles.buttonsGrid}
										>
											<Tooltip title='Tree View' arrow><AccountTree/></Tooltip>
										</ToggleButton>
									</ToggleButtonGroup>
								</Grid>
								<Grid item sm={12}>
									{mediaTypeView === TABLEVIEW ? 
										<table className={styles.customTable} width={'100%'} border={0} cellPadding={0} cellSpacing={0}>
											<tr>
												<th>Media Type</th>
												<th>Media Main Type</th>
											</tr>
											{selectedMediaTypeDialog.mediaType.map((selectedNode: any, index) => (
												<tr key={index}>
													<td width={200}>{selectedNode?.title}</td>
													<td>{selectedNode?.children?.map((mainType: any, mainTypeTableIndex) => (
														<table key={mainTypeTableIndex} className={styles.customTableMediaType} width={'100%'} border={0} cellPadding={0} cellSpacing={0}>
															<tr >
																<td width={400}>{mainType?.title}</td>
																<td>{mainType?.children?.map((subType: any, subTypeIndex) => (
																	<table key={mainTypeTableIndex} className={styles.customTableMediaType} width={'100%'} border={0} cellPadding={0} cellSpacing={0}>
																		<tr key={subTypeIndex}>
																			<td>
																				{subType?.title}
																				{subType?.children?.length > 0 && 
																				<table className={styles.customTableMediaType} width={'100%'} border={0} cellPadding={0} cellSpacing={0}>
																					<tr>
																						<td>
																							{subType?.children?.map((nestedSubType, nestedSubTypeIndex) => (
																								<span key={nestedSubTypeIndex}>{nestedSubType?.title}{subType?.children?.length != nestedSubTypeIndex+1 ? ', ' : null}</span>
																							))}
																						</td>
																					</tr>
																				</table>
																				}
																			</td>
																		</tr>
																	</table>
																))}</td>
															</tr>
														</table>
													))}</td>
												</tr>
											))}
										</table> :
										<Tree
											defaultCheckedKeys={[]}
											treeData={selectedMediaTypeDialog.mediaType}
											rootStyle={{display: 'flex'}}
											defaultExpandedKeys={checkedNodes ? checkedNodes : []}	
											showLine={true}	
										>
										</Tree>}
								</Grid>
							</Grid>
						</NabuDialog>
					</Fragment>
				</section>
			</div>
		</>
	);
};

export default AddRightsAcquiredForm;

