import NabuButton from '@/common/components/atoms/button/button';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import useNotification from '@/common/hooks/useNotification';
import { getNotificationText } from '@/common/utils/utils';
import { mutateCompensation } from '@/services/contract';
import { ICompensationMutationPayload } from '@/services/models/contract';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add, DeleteForever, Save } from '@mui/icons-material';
import { FormControl, FormHelperText, FormLabel, Grid, Stack } from '@mui/material';
import { HttpStatusCode } from 'axios';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import styles from '../dealPoints.module.scss';
import { CompensationSchema } from './contractCompensationFormSchema';

export interface ICompensation {
	id?: string;
	type: string;
	ratePerHour: number;
}

interface ICompensationForm {
	compensations: ICompensation[];
}

interface Props {
	slot?: string;
	isEdit: boolean;
	initialValue?: ICompensation[];
	onClose: VoidFunction;
	refresh?: VoidFunction;
}

const CompensationForm = (props: Props) => {
	const contractId = useParams()?.contractId || '';
	const notification = useNotification();

	const compensationForm = useForm<ICompensationForm>({
		defaultValues: {
			compensations: props.initialValue || [{} as any],
		},
		resolver: yupResolver(CompensationSchema),
	});

	const compensationFormArray = useFieldArray({
		control: compensationForm.control,
		name: 'compensations',
	});

	const COMPENSATION_TYPES = [
		{ label: 'SESSION', value: 'SESSION' },
		{ label: 'TRAVEL', value: 'TRAVEL' },
		{ label: 'FITTING', value: 'FITTING' },
		{ label: 'Wardrobe', value: 'Wardrobe' },
	];

	// Helper functions
	const onClose = () => {
		compensationForm.setValue('compensations', [], { shouldDirty: true });
		props.onClose();
	};

	const addMoreCompensation = () => {
		compensationFormArray.append({} as any);
	};

	const isAddMoreDisabled = () =>
		compensationForm
			.watch('compensations')
			?.some(
				(compensation) => !compensation?.ratePerHour || !compensation?.type
			);
	
	// API functions and logics
	const { mutate: mutateCompensationData, isLoading: isMutationLoading } = useMutation({
		mutationKey: ['contract-compensation-mutate'],
		mutationFn: mutateCompensation,
		onSuccess: (res) => {			
			if(res?.status === HttpStatusCode.Ok) {
				notification.notify({
					message: getNotificationText('Compensation', 'UPDATE'),
					type: 'success'
				});
				onClose && onClose();
				props?.refresh && props.refresh();
			}
			else 
				notification.notify({
					message: getNotificationText('Compensation', 'UPDATE', true),
					type: 'error'
				});
		},
		onError: () =>{
			notification.notify({
				message: getNotificationText('Compensation', 'UPDATE', true),
				type: 'error'
			});
		}
	});

	const onSubmit = (data: ICompensationForm) => {
		const submitData: ICompensationMutationPayload[] = data?.compensations.map((compensation) => ({...compensation, contractId}));
		mutateCompensationData(submitData);
	};

	return (
		<form onSubmit={compensationForm.handleSubmit(onSubmit)}>
			<div className={styles.inlineEditDealPoint}>
				<h3>{props?.isEdit ? 'Edit' : 'Add'} Compensation </h3>
				<Grid item container columnSpacing={3}>
					<Grid item md={6}>
						{compensationFormArray.fields.map((compensation, i) => (
							<Grid item container columnSpacing={3} key={`compensation-${i}-${compensation.id}`}>
								<Grid item sm={12} md={6} lg={6}>
									<FormControl fullWidth>
										<FormLabel>
									Compensation Type <sup>*</sup>
										</FormLabel>
										<Controller
											control={compensationForm.control}
											name={`compensations.${i}.type`}
											render={({ field }) => (
												<NabuSelect
													{...field}
													options={COMPENSATION_TYPES}
													placeholder='Select Compensation Type'
												/>
											)}
										/>
										<FormHelperText error={true}>
											{
												compensationForm.formState?.errors?.compensations?.length && (compensationForm.formState?.errors?.compensations[i]?.type as any)?.message
											}
										</FormHelperText>
									</FormControl>
								</Grid>
								<Grid item sm={12} md={6} lg={6}>
									<FormControl fullWidth>
										<FormLabel>
									Rate Per (Hour/# Of Hours)<sup>*</sup>
										</FormLabel>
										<Controller
											control={compensationForm.control}
											name={`compensations.${i}.ratePerHour`}
											render={({ field }) => (
												<NabuTextbox
													{...field}
													value={field.value == 0 ? '' : field.value}
													// onChange={e => {
													// 	if (new RegExp(/^\d*\.?\d*$/).test(e?.target?.value || '') && Number(e?.target?.value) >= 0) {
													// 		field.onChange(e);
													// 	} else {
													// 		const value: string = e?.target?.value ?? '';
													// 		const num = value?.match(/^\d*\.?\d*/)?.at(0);

													// 		if (!isNaN(Number(num || ''))) {
													// 			field.onChange(Number(num || ''));
													// 		} else {
													// 			field.onChange(0);
													// 		}
													// 	}
													// }}
													onChange={(e: any) => {
														const value = e?.target?.value || '';
														if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
															field.onChange(value);
														} else {
															const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
															field.onChange(num);
														}
													}}
													onBlur={() => {
														const numericValue = parseFloat((field.value ?? '0').toString());
														if (!isNaN(numericValue)) {
															field.onChange(numericValue.toFixed(2));
														} else {
															field.onChange('0.00');
														}
													}}
													placeholder='Rate Per (Hour/# Of Hours)'
												/>
											)}
										/>
										<FormHelperText error={true}>
											{
												compensationForm.formState?.errors?.compensations?.length && compensationForm.formState?.errors?.compensations[i]?.ratePerHour?.message
											}
										</FormHelperText>
									</FormControl>
								</Grid>
								<Grid item xs={2}>
									<NabuButton
										variant='text'
										disabled={compensationFormArray.fields.length < 1}
										onClick={() => compensationFormArray.remove(i)}
									>
										<DeleteForever color='error' />
									</NabuButton>
								</Grid>
							</Grid>
						))}
					</Grid>
					{ !props?.isEdit && (<Grid item sm={12} md={3} lg={3} sx={{ mt: 3.8 }}>
						<NabuButton
							variant='text'
							disabled={isAddMoreDisabled()}
							onClick={addMoreCompensation}
						>
							<Add /> Additional Compensation
						</NabuButton>
					</Grid>)}
					<Grid xs={3} dir='rtl' sx={{ mt: 3 }}>
						<Stack direction='row' columnGap={1}>
							<NabuButton
								variant='outlined'
								data-cy='cancel_btn'
								shouldHandleConfirmAction={compensationForm.formState.isDirty}
								onClick={onClose}
							>
							Cancel
							</NabuButton>
							<div>
								<NabuButton
									variant='filled'
									type='submit'
									disabled={isMutationLoading}
								>
									{!isMutationLoading ? (
										<>
										Save <Save />
										</>
									) : (
										<>Saving</>
									)}
								</NabuButton>
							</div>
						</Stack>
					</Grid>
				</Grid>

				

			</div>
		</form>
	);
};

export default CompensationForm;
