import NabuButton from '@/common/components/atoms/button/button';
import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import LookAheadSelect from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';

import NabuMultiselect from '@/common/components/atoms/multiselect/multiselect';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import NabuDialog from '@/common/components/molecules/dialog/dialog';
import NabuTimePicker from '@/common/components/molecules/timepicker/timePicker';
import TreeLevelMenu, { ICheckedNodeProps } from '@/common/components/molecules/treelevelmenu/treeLevelMenu';
import AddCompanyAddressInline from '@/common/components/templates/pages/add/addCompanyAddressInline';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonAddressInline from '@/common/components/templates/pages/add/addPersonAddressInline';
import AddPersonInline from '@/common/components/templates/pages/add/addPersonInline';
import AddTalentAgentInline from '@/common/components/templates/pages/add/addTalentAgentInline';
import {
	AdditionalFeeDefineOptions,
	ContractStatus,
	ContractStatusOptions,
	DefaultPageSize,
	PeriodUnitsOptions,
	mediaInfoMediaSubTypeOptions,
	mediaInfoMediaTypeOptions,
	mediaMainTypeValue,
	mediaSubTypeValue,
	mediaType,
	mediaTypeOptions,
	mediaTypeValue
} from '@/common/constants/constants';
import { OPERATORS } from '@/common/enums';
import useNotification from '@/common/hooks/useNotification';
import useScrollToField from '@/common/hooks/useScrollToField';
import {
	CompanyMainType,
	ICompanyAddress,
	ICompanyRecord,
} from '@/common/types/company';
import {
	IAdditionalFees,
	IBaseContract,
	ICommercialDetail,
	IContractNonUnionForm,
	IPacketControlForm,
} from '@/common/types/contract';
import { IFilterOption } from '@/common/types/filterModel';
import {
	displayAddress,
	displayOnlyEmail,
	displayPhoneNumberWithoutLabel,
	findNodeAndAncestors,
	getBaseUrl,
	getCompanyDisplayName,
	getLocaleDate,
	getLocaleDateTime,
	getNotificationText,
	getPersonAliasFullName,
	getPersonFullName,
	getStateName,
	mutateContractAdditionalFormsData,
	sortEmailOrPhoneNumberForDropDown,
} from '@/common/utils/utils';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import { getAllAssetForBasicDetail, getAllSignatory } from '@/services/asset';
import {
	getAllAddressByCompany,
	getCompanyBasicDetails,
	getStaffListByCompany
} from '@/services/company';
import {
	addContractNonUnion,
	addMultipleContract,
	updateContractNonUnion,
} from '@/services/contract';
import { IAssetRecord } from '@/services/models/asset';
import { IPersonAddress, IPersonRecord } from '@/services/models/person';
import {
	GetByWithFullNameSearchForBasicDetail,
	getAgentInfo,
	getAllAddressByPerson,
} from '@/services/person';
import store from '@/store';
import { updateContractFormState } from '@/store/contract/reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add, CopyAll, DeleteForever, NoteAdd, Save } from '@mui/icons-material';
import {
	Autocomplete,
	Box,
	Checkbox,
	FormControl,
	FormHelperText,
	FormLabel,
	Grid,
	ListItemButton,
	ListItemText,
	MenuItem,
	Select,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
} from '@mui/material';
import { GridDeleteForeverIcon } from '@mui/x-data-grid';
import { HttpStatusCode } from 'axios';
import { continents, countries } from 'countries-list';
import { City, Country, State } from 'country-state-city';
import { addDays, addMonths, addWeeks, addYears, isBefore } from 'date-fns';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Controller, UseFormReturn, useFieldArray, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import ContractCAWageTheftPreventionForm from '../contractCAWageyTheftPreventionForm';
import ContractMinorTrustForm from '../contractMinorTrustForm';
import ContractNYWageTheftPreventionForm from '../contractNYWageTheftPreventionForm';
import ContractW4ShortForm from '../contractW4ShortForm';
import ContractW8BenShortForm from '../contractW8BenShortForm';
import ContractW9BenShortForm from '../contractW9BenShortForm';
import ExpiryDateForm from '../expiryDateForm';
import TalentDetailsForm from '../talentDetailsForm';
import { IContractFormState } from '../unionForms/standardSagAftraEmployment2022';
import contractTerritorySchema from './schema';

interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	initialValue?: IContractNonUnionForm;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	defaultValue?: IBaseContract,
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
	assets?:IAssetRecord[];
	isDuplicateContractName?: boolean;
}

// eslint-disable-next-line react/display-name
const NonUnionTalentAgreementExtra = forwardRef((props: Props, ref) => {
	const { scroll } = useScrollToField();
	const [params, setParams] = useSearchParams();
	useImperativeHandle(ref, () => ({
		handleContractNonUnionTalentExtraFormCancel: (
			data: IContractNonUnionForm,
			statusValue: string
		) => {
			if (data) {
				const workDays = data?.workDays?.filter((d) => d?.date) || [];
				const submitData = {
					...data,
					status: statusValue ? statusValue : ContractStatus.Draft,
					contractNonUnion: undefined,
					payrollVendorId: data?.payrollVendorId?.value,
					performerId: data?.performerId?.value,
					advertiserId: data?.advertiserId?.value,
					adAgencyId: data?.adAgencyId?.value,
					productId: data?.productId?.value,
					performerAddressId: data?.performerAddressId?.value,
					recordingCompanyId: data?.recordingCompanyId?.value,
					adAgencyAddressId: data?.adAgencyAddressId?.value,
					brandId:data?.brandId?.value,
					workDays
				};
				editContractDetails(submitData);
			}
		},
	}));

	const DEFAULT_COUNTRY_CODE = 'US';

	const getMediaTypeOptions = (unionType: string) => mediaInfoMediaTypeOptions[unionType]?.mediaTypes;
	const getMediaSubTypeOptions = (mediaType: string) => mediaInfoMediaSubTypeOptions[mediaType]?.mediaSubTypes;

	useEffect(() => {
		if(props?.defaultValue?.advertiserId)
			contractForm.setValue('advertiserId', props?.defaultValue?.advertiserId, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.advertiserId]);

	const notification = useNotification();


	const contractForm = useForm<IContractNonUnionForm>({
		defaultValues: !props?.initialValue
			? {
				...(props?.mainForm?.getValues() || {}),
				workDays: [{}],
				...(props?.defaultValue || {}),
				commercials: [{}],
				territories: [{countries: [], region: ''}],
				additionalFees:[{fees: 0,additionalFeeFlatFee:true,additionalFeeHourly:false,additionalFeeDefine:'',otherFee:''}],
				mediaInformation: [{mediaSubType: '', mediaType: '', mediaSubTypeDetails: []}],
			}
			: {...(props?.initialValue || {}), 
				commercials: props?.initialValue?.commercials?.length ? props?.initialValue?.commercials  :[{}], 
				workDays: props?.initialValue?.workDays?.length ? props?.initialValue?.workDays : [{}],
				additionalFees:props?.initialValue?.additionalFees?.length ? props?.initialValue?.additionalFees : [{}],
				mediaInformation: (props?.initialValue?.mediaInformation && props?.initialValue?.mediaInformation?.length > 0) ? [...(props?.initialValue?.mediaInformation || [])] : [{mediaSubType: '', mediaType: '', mediaSubTypeDetails: []}],
			},
		resolver: yupResolver(contractTerritorySchema),
		mode: 'onChange',
		shouldFocusError: false
	});

	const packetControlForm = useForm<IPacketControlForm>({
		defaultValues: {
			isW4shortForm: !!contractForm.watch('contractW4Form.firstAndMiddleName'),
			isW8BenShort: !!contractForm.watch('contractW8BenShortForm'),
			isW9BenShort: !!contractForm.watch('contractW9BenShortForm'),
			isMinorTrust: !!contractForm.watch('contractMinorTrustForm'),
			isNYTheftPreventionForm: !!contractForm.watch(
				'contractNYTheftPreventionForm'
			),
			isCATheftPreventionForm: !!contractForm.watch(
				'contractCATheftPreventionForm'
			),
		},
	});

	const [companyPopoverInline, setCompanyPopoverInline] = useState<IPopOverState>({
		isOpen: false,
	});
	const [personOptions, setPersonOptions] = useState<IPersonRecord[]>(
		props?.people || []
	);
	const [addressOptions, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [assetOptions, setAssetOptions] = useState<IAssetRecord[]>(
		props?.assets || []
	);
	const [contractFormState, setContractFormState] =
		useState<IContractFormState>({});
	const [addressPopOver, setAddressPopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const workDayFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'workDays',
	});

	const territoryFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'territories'
	});

	const AD_AGENCY_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'AD AGENCY',
		},
	];

	const ADVERTISER_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'ADVERTISER',
		},
	];

	const VENDOR_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'VENDOR',
		},
	];

	// helper functions
	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	const handlePackageNotification = (key: keyof IPacketControlForm) => {
		notification.notify({
			message: `Form ${
				packetControlForm.watch(key) ? 'Added to' : 'Removed from'
			} Packet.`,
			type: 'info',
		});
	};

	const handleStateOptions = (
		responseData: any[],
		type: 'PERSON' | 'ADDRESS'| 'ASSET'
	) => {
		const cb = {
			PERSON: setPersonOptions,
			ADDRESS: setAddressOptions,
			ASSET:setAssetOptions,
		}[type];

		if (responseData && Array.isArray(responseData)) {
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);
				return [...oldFilter, ...responseData];
			});
		}
	};

	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		const address = responseData && responseData?.reduce((acc, curr) => {
			if (curr?.addresses?.length) {
				acc.push(...(curr?.addresses || []));
			}
			return acc;
		}, [] as ICompanyAddress[]);

		handleStateOptions(address, 'ADDRESS');

		return responseData && responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};

	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const getTalentAliasName = () => {
		const person = personOptions?.find((a) => a?.id === contractForm.watch('performerId.value'));
		const aliasName = getPersonAliasFullName(person?.aliasName);
		const formData: any = contractForm.getValues();
		return aliasName === '' && formData?.contractNonUnion?.akaName || aliasName;
	};

	const adAgencyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const getPhoneNumberOptions = (addressId: string = '') => {
		const unsortedPhoneNumbers =
			addressOptions?.find((a) => a?.id === addressId)?.phoneNumbers || [];
		
		const phoneNumbers = sortEmailOrPhoneNumberForDropDown(unsortedPhoneNumbers);
		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	
	const onPayrollVendorAddressCreated = (createdAddress: ICompanyAddress) => {
		contractForm.setValue('payrollVendorAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const companyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		})) || [];
	};

	const getPerformerEmailsOptions = () => {
		const unsortedEmails =
			personOptions?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.emails || [];

		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const getCompanyEmailOptions = (addressId?: string) => {
		const unsortedEmails =
			addressOptions?.find((a) => a?.id === addressId)?.emails || [];
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const onClickSnippetBtn = () => {
		setContractFormState((prev) => ({
			isPacketOpen: !prev?.isPacketOpen,
		}));
	};

	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};

	const onAddWorkDay = () => {
		if (workDayFieldArray.fields.length) {
			const previousRow = {
				...contractForm.watch(
					`workDays.${workDayFieldArray.fields.length - 1}`
				),
				id: undefined,
			};

			workDayFieldArray.append({
				...previousRow,
				date: previousRow?.date ? addDays(new Date(previousRow?.date || ''), 1) : '',
			});
		} else {
			workDayFieldArray.append({} as any);
		}
	};

	const onRemoveWorkDay = (index: number) => {
		if (workDayFieldArray.fields.length > 1) {
			workDayFieldArray.remove(index);
		}
	};
	const { mutate: addContractDetails, isLoading: isAddingContract } =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addContractNonUnion(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const { mutate: editContractDetails, isLoading: isEditingContract } =
		useMutation({
			mutationKey: ['contract-edit'],
			mutationFn: (payload: any) =>
				updateContractNonUnion(props?.contractId || '', payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'UPDATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const handleContractSubmission = async (
		data: IContractNonUnionForm,
		handleMultipleContractAdd?: CallableFunction
	) => {
		// props?.handlePrintStateOfContract && props?.handlePrintStateOfContract(false);
		if (!packetControlForm.watch('isW4shortForm')) {
			data.w4FormId = undefined;
			data.contractW4Form = undefined;
		}
		if (!packetControlForm.watch('isW8BenShort')) {
			data.w8BenShortFormId = undefined;
			data.contractW8BenShortForm = undefined;
		}
		if (!packetControlForm.watch('isW9BenShort')) {
			data.w9BenShortFormId = undefined;
			data.contractW9BenShortForm = undefined;
		}
		if (!packetControlForm.watch('isMinorTrust')) {
			data.minorTrustFormId = undefined;
			data.contractMinorTrustForm = undefined;
		}
		if (!packetControlForm.watch('isNYTheftPreventionForm')) {
			data.nYTheftPreventionFormId = undefined;
			data.contractNYTheftPreventionForm = undefined;
		}
		if (!packetControlForm.watch('isCATheftPreventionForm')) {
			data.cATheftPreventionFormId = undefined;
			data.contractCATheftPreventionForm = undefined;
		}

		try {
			const notify = (message: string) => {
				notification.notify({
					message,
					type: 'error',
				});
			};
			// NOTE: These linked form's handled separately because the payload size is huge.
			const updatedContractIds = await mutateContractAdditionalFormsData(
				data,
				data?.performerId?.value || '',
				notify
			);
			data.w4FormId = updatedContractIds?.w4FormId;
			data.w8BenShortFormId = updatedContractIds?.w8BenShortFormId;
			data.w9BenShortFormId = updatedContractIds?.w9BenShortFormId;
			data.minorTrustFormId = updatedContractIds?.minorTrustFormId;
			data.nYTheftPreventionFormId =
				updatedContractIds?.nYTheftPreventionFormId;
			data.cATheftPreventionFormId =
				updatedContractIds?.cATheftPreventionFormId;

		} catch (error) {
			notification.notify({
				message: getNotificationText('Form', 'UPDATE', true),
				type: 'error',
			});
		} finally {
			data.contractW4Form = undefined;
			data.contractW8BenShortForm = undefined;
			data.contractW9BenShortForm = undefined;
			data.contractMinorTrustForm = undefined;
			data.contractNYTheftPreventionForm = undefined;
			data.contractCATheftPreventionForm = undefined;

			const workDays = data?.workDays?.filter((d) => d?.date) || [];

			const mainFormValues = props?.mainForm?.getValues();
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};

			const commercials = data?.commercials?.map((d) => ({
				...d,
				assetId: d?.assetId?.value,
			}));

			const submitData = {
				...data,
				...addMainFormValues,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value,
				contractNonUnion: undefined,
				payrollVendorId: data?.payrollVendorId?.value,
				performerId: data?.performerId?.value,
				advertiserId: data?.advertiserId?.value,
				adAgencyId: data?.adAgencyId?.value,
				productId: data?.productId?.value,
				performerAddressId: data?.performerAddressId?.value,
				recordingCompanyId: data?.recordingCompanyId?.value,
				adAgencyAddressId: data?.adAgencyAddressId?.value,
				talentAgencyAddressId: data?.talentAgencyAddressId?.value,
				talentAgencyId: data?.talentAgencyId?.value,
				talentAgencyStaffId: data?.talentAgencyStaffId?.value,
				brandId:data?.brandId?.value,
				workDays,
				commercials,
				productionCompanyId: data?.productionCompanyId?.value
			};

			if (!props?.isEdit) {
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					},
				});
			} else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 
		}
	};

	const onSubmitError = (data) => {
		scroll(data);
	};

	const onSubmit = (data: IContractNonUnionForm) => {
		if(!props?.mainForm?.watch('contractDisplayName') && !props?.isEdit){
			notification.notify({
				message: 'Please fill Contract Display Name.',
				type: 'error'
			});
		}
		else if(props?.isDuplicateContractName){
			notification.notify({
				message: 'Contract with the same name already exists',
				type: 'error',
			});
			return;
		}
		else{
			openExpiryDateForm(data);
		}
	};

	useEffect(() => {
		if(props?.mainForm){
			contractForm.setValue('contractDisplayName', props?.mainForm?.watch('contractDisplayName') || '', { shouldDirty: true});
		}
	}, [props?.mainForm?.watch('contractDisplayName')]);

	const { mutate: addMultipleContractDetails } = useMutation({
		mutationKey: ['contract-add'],
		mutationFn: (payload: any) => addMultipleContract(payload),
		onSuccess: (res) => {
			if (res?.status === HttpStatusCode.Ok) {
				const baseURL = getBaseUrl(window.location.href);
				const contractIds: any = res?.data || [];
				const urls =
					contractIds.map((contract) => {
						return `${baseURL}/#/contracts/${contract}/contract-view?isEdit=true`;
					}) || [];

				urls.forEach((url) => {
					const a = document.createElement('a');
					a.href = url;
					a.target = '_blank';
					const evt = document.createEvent('MouseEvents');
					evt.initMouseEvent(
						'click',
						true,
						true,
						window,
						0,
						0,
						0,
						0,
						0,
						false,
						false,
						false,
						false,
						0,
						null
					);
					a.dispatchEvent(evt);
				});
				notification.notify({
					message: getNotificationText('Contract', 'CREATE'),
					type: 'success',
				});
			} else
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
		},
		onError: () => {
			notification.notify({
				message: getNotificationText('Contract', 'CREATE', true),
				type: 'error',
			});
		},
	});

	const [contractFormData, setContractFormData] =
		useState<IContractNonUnionForm>();

	const [noOfTalentsSelected, setNoOfTalentsSelected] = useState(false);

	const handleSaveAndDuplicate = (data: IContractNonUnionForm) => {
		if(props?.isDuplicateContractName){
			notification.notify({
				message: 'Contract with the same name already exists',
				type: 'error',
			});
			return;
		}
		else{
			setNoOfTalentsSelected(true);
			setContractFormData(data);
		}
	};

	const handleTalentDetailsForm = (data: any) => {
		setNoOfTalentsSelected(false);
		const handleMultipleContractAdd = (payloadData) => {
			if(data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						status:ContractStatus?.Draft,
						partName: talent?.role,
						talentAgencyId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${index+1}`,
						compensation: talent?.compensation,
						performerAddressId:undefined,
						performerEmailId:undefined,
						performerPhoneId:undefined,
						talentAgencyAddressId:undefined,
						talentAgencyStaffId:undefined,
						talentAgencyPhoneId:undefined,
						talentAgencyEmailId:undefined
					};
					return submitData;
				});
				const multipleContractData = {
					contractAddNonUnionModels: contractAddModels,
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData &&
			handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};

	const getStateOptions = () => {
		const states = State.getStatesOfCountry(DEFAULT_COUNTRY_CODE).map(
			(state) => ({ label: state.name?.toUpperCase(), value: state.isoCode })
		);
		if (!states.length) states.unshift({ label: 'NA', value: 'NA' });
		return states;
	};

	const getStateLabel = () =>{
		const result= getStateName( DEFAULT_COUNTRY_CODE,contractForm?.watch('sessionState')||'')?.toUpperCase();
		return result;
	};

	const getCountryLabel = () =>{
		const result= getCountryName(contractForm.watch('sessionCountry')|| '' )?.toUpperCase();
		return result;
	};

	const getCityOptions = (stateCode?: string) =>
		stateCode
			? City.getCitiesOfState(DEFAULT_COUNTRY_CODE, stateCode)?.map((city) => ({
				label: city.name?.toUpperCase(),
				value: city.name?.toUpperCase(),
			})) || []
			: [];	
			
	const onPersonAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('performerId.value')
			);
		
			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
						person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('performerAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const getPerformerPhoneNumberOptions = () => {
		const phoneNumbers =
			personOptions?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.phoneNumbers || [];

		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const [staffOptions, setStaffOptions] = useState([] as any);
	
	useEffect(() => {
		if(contractForm.watch('talentAgencyId')){
			const getStaffOptions = (filter: any) => {
				getStaffListByCompany(filter, contractForm.watch('talentAgencyId')?.value).then((res) => {
					const options: any = res?.data?.records?.map((staff: any) => {
						return {value: staff?.id, label: getPersonFullName(staff?.personName)};
					}) ?? [];
					setStaffOptions(options);
				});
			};
			getStaffOptions({
				pageNumber: 0,
				pageSize: DefaultPageSize,
				sortDescending: true,
				sortField: 'createdAt',
			});
		}
	}, [contractForm.watch('talentAgencyId')]);

	const commercialFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'commercials',
	});
	const mediaInformationFormFields = useFieldArray({
		control: contractForm.control,
		name: 'mediaInformation'
	});

	const additionalFeeArray = useFieldArray({
		control: contractForm.control,
		name: 'additionalFees',
	});

	const getCommercialFilters = () => {
		const assetFilter: IFilterOption[] = [
			{
				isCaseSensitive: false,
				operator: OPERATORS.NOT_EQUAL,
				property: 'Title',
				value: '',
			},
			{
				isCaseSensitive: false,
				operator: OPERATORS.IN,
				property: 'Advertisers.Id',
				value: JSON.stringify([contractForm.watch('advertiserId.value')]),
			},
		];
		return assetFilter;
	};

	const getAssetAdIdByAssetIndex = (index: number) => {
		const assetId = contractForm.watch(`commercials.${index}.assetId`)?.value;
		return assetOptions?.find((d) => d?.id === assetId)?.adId || '';
	};

	const lookAheadAssetOptionGenerator = (responseData: IAssetRecord[]) => {
		handleStateOptions(responseData, 'ASSET');

		return responseData?.map((d) => ({
			value: d?.id || '',
			label: d?.title || '',
		}));
	};


	const onAddCommercial = () => {
		commercialFieldArray.append({} as ICommercialDetail);
	};

	const onRemoveCommercial = (index: number) => {
		if (commercialFieldArray.fields.length > 1) {
			commercialFieldArray.remove(index);
		}
	};

	const onAddAdditionalFees = () => {
		additionalFeeArray.append({} as IAdditionalFees);
	};

	const onRemoveAdditionalFees = (index: number) => {
		if (additionalFeeArray.fields.length > 1) {
			additionalFeeArray.remove(index);
		}
	};

	useEffect(()=> {
		if (props?.isEdit) {
			if (commercialFieldArray.fields.length === 0) {
				onAddCommercial();
			}
			if (additionalFeeArray.fields.length === 0) {
				onAddAdditionalFees();
			}
			if (workDayFieldArray.fields.length === 0) {
				onAddWorkDay();
			}
		}
	}, [props?.isEdit]);

	const continentsOptions = Object.keys(continents).map((key) =>
	{
		return {
			value: key,
			label: continents[key]
		};
	});

	const getContinentCountries = (continent: string) => {
		const continentCountries = Object.keys(countries)
			.filter((k) => k !== 'AQ')
			.map((k) => ({ ...countries[k], iso: k }))
			.sort((a, b) => a.name.localeCompare(b.name))
			.filter((c) => c.continent == continent)
			.map((c) => ({label: c.name.toUpperCase(), value: c.iso}));
		return continentCountries;
	};

	const getCountryName = (iso: string) => {
		const country = Object.keys(countries)
			.filter((k) => k !== 'AQ')
			.map((k) => ({ ...countries[k], iso: k }))
			.sort((a, b) => a.name.localeCompare(b.name))
			.find((c) => c.iso == iso);
		return country.name || '';
	};

	const resetTerritoriesValues = () => {
		contractForm.getValues('territories')?.map((_,index) => {
			contractForm.setValue(`territories.${index}`, {countries: [], region: ''});
		});
		contractForm.setValue('territories', [{countries: [], region: ''}]);
	};

	const isMinor = () => {
		return personOptions?.find(
			(a) => a?.id === contractForm.watch('performerId.value')
		)?.isMinor;
	};
	const getCountryOptions = Country.getAllCountries().map( country => ({ label: country.name?.toUpperCase(), value: country.isoCode }));

	//Media Type Logics 
	const [selectedIds, ] = useState<any[]>([]);
	const [checkedNodes, setCheckedNodes] = useState<any[]>([]);
	const [allCheckedNodes, setAllCheckedNodes] = useState<any[]>([]);

	const onSaveMediaFiles = (selectedNodes: ICheckedNodeProps) => {
		const nodesSelected: any[] = selectedNodes.firstColumnData.concat(selectedNodes.secondColumnData, selectedNodes.thirdColumnData, selectedNodes.fourthColumnData, selectedNodes.fifthColumnData);
		const fullySelectedNodes = selectedNodes.firstColSelectedKeys.concat(selectedNodes.secondColSelectedKeys, selectedNodes.thirdColSelectedKeys, selectedNodes.fourthColSelectedKeys, selectedNodes.fifthColSelectedKeys);
		setCheckedNodes(fullySelectedNodes);
		const mediaTypes = mediaType?.filter(e => nodesSelected.includes(e?.id) && e.type === mediaTypeValue).map((e) => e?.label) || undefined;
		const mediaMainTypes = mediaType?.filter(e => nodesSelected.includes(e?.id) && e.type === mediaMainTypeValue).map((e) => e?.label) || undefined;
		const mediaSubTypes = mediaType?.filter(e => nodesSelected.includes(e?.id) && e.type === mediaSubTypeValue).map((e) => e?.label) || undefined;
		contractForm.setValue('mediaType', mediaTypes, {shouldDirty: true});
		mediaTypes && contractForm.clearErrors('mediaType');
		contractForm.setValue('mediaMainType', mediaMainTypes);
		contractForm.setValue('mediaSubType', mediaSubTypes);
		fullySelectedNodes && (setCheckedNodes(fullySelectedNodes), contractForm.setValue('checkedMediaTypeIds', fullySelectedNodes));
		nodesSelected && setAllCheckedNodes(nodesSelected);
		closeMediaType();
		handleMediaTypeViewClick(fullySelectedNodes);
	};

	const [, setSelectedMediaTypeDialog] = useState<{
		isOpen: boolean,
		mediaType: any,
		selectedNodes: []
	}>({
		isOpen: false,
		mediaType: [],
		selectedNodes: []
	});

	const [ isMediaTypeVisible, setMediaTypeVisibility] = useState<boolean>(false);
	const closeMediaType = () =>{ 
		setMediaTypeVisibility(false);
		
	};

	const handleMediaTypeViewClick = (fullySelectedNodes: any) => {
		const selectedNodes: any = mediaType.filter(e => selectedIds.includes(e.id));
		setSelectedMediaTypeDialog({
			isOpen: true, 
			mediaType: findNodeAndAncestors(mediaTypeOptions, fullySelectedNodes.filter((item, index) => fullySelectedNodes.indexOf(item) === index)), 
			selectedNodes : selectedNodes
		});
	};

	const handleProducerChange = (index ?: any)=>{
		if(index ==0){
			contractForm.setValue('productionCompanyId',null,{shouldDirty:true});
		}	
		if(contractForm.watch(`commercials.${0}.assetId.value`)?.length == 0){
			contractForm.setValue('productionCompanyId',null,{shouldDirty:true});
		}
	};

	const NONUNIONVALUE = 'NON UNION';

	//Expiry Date Dialog Code
	const [isExpiryDateDialogOpen, setExpiryDateDialog] = useState<{isOpen: boolean, contractData: IContractNonUnionForm | undefined, contractExpiryDate: Date | undefined}>({isOpen: false, contractData: undefined, contractExpiryDate: undefined});

	const calculateEndDate = (termValues) => {
		if(termValues.termDuration && termValues.termDurationUnit && termValues.termFromFirstUseDate){
			const days = termValues.termDuration;
			const startDate = new Date(termValues.termFromFirstUseDate);
			const date = startDate.setDate(startDate.getDate() - 1);
			const unit = termValues.termDurationUnit;
			if(date){
				let finalDate;
				if(unit == 'WEEK (S)'){
					finalDate = (addWeeks(new Date(date), days));
				}
				else if(unit == 'MONTH (S)'){
					finalDate = addMonths(new Date(date), days);
				}
				else if(unit == 'YEAR (S)'){
					finalDate = addYears(new Date(date), days);
				}
				else{
					finalDate = addDays(new Date(date), days);
				}
				return finalDate;
			}
		}
	};

	const openExpiryDateForm = (data) => {
		if(!props?.mainForm?.watch('contractDisplayName') && !props?.isEdit){
			notification.notify({
				message: 'Please fill Contract Display Name.',
				type: 'error'
			});
		}
		const termValues = {
			termDuration: data.termDuration,
			termDurationUnit: data.termDurationUnit,
			termFromFirstUseDate: data.termFromDate || data.termFromFirstUseDate
		};
		const endDate = calculateEndDate(termValues) || undefined;
		setExpiryDateDialog({isOpen: true, contractData: data, contractExpiryDate: endDate});
	};

	const closeExpiryDateForm = () => setExpiryDateDialog({isOpen: false, contractData: undefined, contractExpiryDate: undefined});
	const onSubmitOfExpiryDateForm = (data) => {
		if(isExpiryDateDialogOpen.contractData){
			const expiredStatus = data.expirationDate ? (isBefore(data.expirationDate, new Date()) ? ContractStatus.Expired : undefined) : undefined;
			const submittedData: IContractNonUnionForm = {
				...isExpiryDateDialogOpen.contractData,
				expirationDate: data.expirationDate,
				status: expiredStatus
			};
			submittedData && handleContractSubmission(submittedData);
		}
	};

	return (
		<>
			<div className={props?.isPrint ? styles.formWrapperPreview : ''}>
				<form onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}>
					<div className={styles.packetTemplate} id='form'>
						<Grid item container columnSpacing={3}>
							{contractFormState?.isPacketOpen && (
								<Grid item sm={12} md={4} lg={3}>
									<div className={styles.snippetSection}>
										<Grid item container columnSpacing={3}>
											<Grid item md={12}>
												<h3 className={styles.pocketTitle}>
												Government & Payroll forms
												</h3>
											</Grid>
										</Grid>
										<div className={styles.contractFormBlockSnippet}>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW4shortForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW4shortForm');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW4shortForm');
																		}}
																	/>{' '}
																	<span>W4- short version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW8BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW8BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW8BenShort');
																		}}
																	/>
																	<span>W8 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW9BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW9BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW9BenShort');
																		}}
																	/>
																	<span>W9 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isMinorTrust'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isMinorTrust');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isMinorTrust');
																		}}
																	/>
																	<span>Minor Trust Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isNYTheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isNYTheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isNYTheftPreventionForm'
																			);
																		}}
																	/>
																	<span>NY Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isCATheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isCATheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isCATheftPreventionForm'
																			);
																		}}
																	/>
																	<span>CA Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
										</div>
									</div>
								</Grid>
							)}
							<Grid
								item
								sm={12}
								md={4}
								lg={!contractFormState?.isPacketOpen ? 12 : 9}
							>
								<div
									className={
										!contractFormState?.isPacketOpen
											? undefined
											: styles.scrollFIle
									}
								>
									<div className={styles.contractFormBlockPacket} id='contract-form-block-packet'>
										<div className={styles.contractFormBlock}>
											{props?.isEdit && (
												<div className={styles.formFiledsBlock}>
													<Grid container item columnSpacing={3}>
														<Grid item md={6} lg={3}>
															<FormControl fullWidth>
																<FormLabel>
															Contract Name<sup>*</sup>
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='contractDisplayName'
																	render={({ field }) => (
																		<>
																			<NabuTextbox
																				{...field}
																				placeholder={
																					!props?.isPrint ? 'Contract Name' : ''
																				}
																			/>
																		</>
																	)}
																/>
																{contractForm.formState?.errors?.contractDisplayName
																	?.message && (
																	<FormHelperText error={true}>
																		{
																			contractForm.formState.errors
																				?.contractDisplayName?.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={3}>
															<FormControl fullWidth>
																<FormLabel>
															Payroll Vendor <sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='payrollVendorId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				{...field}
																				placeholder={
																					!props?.isPrint ? 'Payroll Vendor' : ''
																				}
																				filterProperty='CompanyNames.Name'
																				APICaller={getCompanyBasicDetails}
																				optionGenerator={companyOptionGenerator}
																				defaultFilter={VENDOR_FILTER}
																				isClearable={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																				}}
																				cacheOptions={false}
																				defaultOptions
																			/>
																		</>
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors
																				?.payrollVendorId.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={3}>
															<FormControl fullWidth>
																<FormLabel>
															Payroll Vendor Address
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	key={`payroll-address-${contractForm.watch(
																		'payrollVendorAddressId.value'
																	)}`}
																	name='payrollVendorAddressId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				{...field}
																				placeholder={'Select Address'}
																				key={`payroll-vendor-address-${contractForm.watch(
																					'payrollVendorAddressId.value'
																				)}-${contractForm.watch('payrollVendorId.value')}`}
																				filterProperty='Line1'
																				APICaller={(filterModel) =>
																					getAllAddressByCompany(
																						filterModel,
																						contractForm.watch('payrollVendorId.value') || ''
																					)
																				}
																				optionGenerator={
																					companyAddressOptionGenerator
																				}
																				isDisabled={!contractForm.watch('payrollVendorId.value')}
																				dropDownsize={50}
																				isClearable={true}
																				isSearchText={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																					contractForm.trigger('payrollVendorAddressId.value');
																				}}
																				cacheOptions={false}
																				defaultOptions
																			/>
																			{contractForm.watch('payrollVendorId.value') && <div style={{float:'right'}}>
																				<Box sx={{mt:0.9}}>
																					<AddCompanyAddressInline
																						companyId={contractForm.watch('payrollVendorId.value')}
																						title='ADDRESS'
																						isOpen={addressPopOver?.isOpen}
																						event={addressPopOver?.event}
																						onAddressCreated={(d) => onPayrollVendorAddressCreated(d)}
																						onClose={() => setAddressPopOver({ isOpen: false })}
																						onPopOverOpen={(e) => setAddressPopOver({
																							isOpen: true,
																							event: e?.currentTarget,
																						})}
																						isPersistent={true} />
																				</Box>
																			</div>}
																		</> 
																	
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorAddressId?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors
																				?.payrollVendorAddressId.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={3}>
															<FormControl fullWidth>
																<FormLabel>
															Status
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='status'
																	render={({ field }) => (
																		<>
																			<NabuSelect
																				{...field}
																				placeholder={
																					!props?.isPrint ? 'Select Status' : ''
																				}
																				options={ContractStatusOptions || []}
																				convertValueToUpperCase={false}
																				onChange={(e) => field.onChange(e)}
																			/>
																		</>
																	)}
																/>
															</FormControl>
														</Grid>
													</Grid>
												</div>
											)}
											{/* Main Contract Form Start */}
											<>
												<div className={props?.isPrint ? styles.formWrapperPreview : ''}>
													<div className={styles.contractFormBlock}>
														<div className={styles.bgWhite} style={{padding:'20px'}}>
															<Grid item container columnSpacing={3}>
																<Grid item md={12} >
																	<Box>
																		<h4>{contractForm.watch('contractId') ? `#${contractForm.watch('contractId')}` : ''}</h4>
																	</Box>
																</Grid>
																<Grid item md={12} >
																	<Box sx={{pt:4}}>
																		<h2 style={{textAlign:'center'}}>NON-UNION TALENT AGREEMENT – EXTRA</h2>
																	</Box>
																</Grid>
															</Grid>
															<hr></hr>
															<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
																<tr>
																	<td width={160}>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<FormLabel>Agency:</FormLabel>
																		</FormControl>
																	</td>
																	<td width={400}>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				key={`adAgency-${contractForm.watch(
																					'adAgencyId.value'
																				)}`}
																				control={contractForm.control}
																				name='adAgencyId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							data-cy='ad_agency'
																							{...field}
																							placeholder={
																								!props?.isPrint
																									? 'Ad-Agency'
																									: ''
																							}
																							filterProperty='CompanyNames.Name'
																							APICaller={getCompanyBasicDetails}
																							optionGenerator={
																								companyOptionGenerator
																							}
																							defaultFilter={AD_AGENCY_FILTER}
																							isDisabled={props?.isPrint}
																							isClearable={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.setValue(
																									'adAgencyAddressId',
																									null,
																									{ shouldDirty: true }
																								);
																								contractForm.setValue(
																									'adAgencyPhoneId',
																									'',
																									{ shouldDirty: true }
																								);
																								contractForm.setValue(
																									'adAgencyEmailId',
																									'',
																									{ shouldDirty: true }
																								);
																							}}
																							cacheOptions={false}
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																					</>
																				)}
																			/>
																			{contractForm.formState.errors?.adAgencyId?.message && (
																				<FormHelperText
																					error={true}
																					data-cy='errorHelper_ad_agency'
																				>
																					{
																						contractForm.formState?.errors
																							?.adAgencyId.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																		{!props?.isPrint && (
																			<div className={styles.inlineAdd}>
																				<AddCompanyInline
																					title='Company'
																					companyType={{
																						mainType:'AD AGENCY' as CompanyMainType,
																						subType: [],
																					}}
																				/>
																			</div>
																		)}
																	</td>
																	<td width={150}>  
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<FormLabel>Agency Job#:</FormLabel>
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='agencyJobTitle'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						data-cy='job_name_textbox'
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={
																							!props?.isPrint ? 'Job' : ''
																						}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.agencyJobTitle?.message && (
																				<FormHelperText
																					data-cy='errorHelper-job-title-name'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.agencyJobTitle.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																</tr>
																<tr>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<FormLabel>Advertiser:</FormLabel>
																			<sup>*</sup>
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				key={`advertiser-${contractForm.watch(
																					'advertiserId.value'
																				)}`}
																				control={contractForm.control}
																				name='advertiserId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																							{...field}
																							placeholder={
																								!props?.isPrint ? 'Advertiser' : ''
																							}
																							filterProperty='CompanyNames.Name'
																							APICaller={getCompanyBasicDetails}
																							optionGenerator={
																								companyOptionGenerator
																							}
																							defaultFilter={ADVERTISER_FILTER}
																							isDisabled={props?.isPrint}
																							isClearable={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.resetField(
																									'productId'
																								);
																							}}
																							cacheOptions={false}
																							defaultOptions
																						/>
																					</>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.advertiserId?.message && (
																				<FormHelperText
																					error={true}
																					data-cy='errorHelper_ad_agency'
																				>
																					{
																						contractForm.formState?.errors
																							?.advertiserId.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl> 
																		{!props?.isPrint && (
																			<div className={styles.inlineAdd}>
																				<AddCompanyInline
																					title='Company'
																					companyType={{
																						mainType: 'ADVERTISER' as CompanyMainType,
																						subType: [],
																					}}
																				/>
																			</div>
																		)}
																	</td>
																	<td>  
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<FormLabel>Job Name:</FormLabel>
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='advertiserJobTitle'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						data-cy='advertiserJobTitle_textbox'
																						{...field}
																						disabled={props?.isPrint}
																						placeholder={
																							!props?.isPrint ? 'Job' : ''
																						}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.advertiserJobTitle?.message && (
																				<FormHelperText
																					data-cy='errorHelper-advertiserJobTitle-name'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.advertiserJobTitle.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																</tr>
																<tr>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<FormLabel>Production Co:</FormLabel>
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				key={`commercial-assetId
																					-${contractForm.watch(`commercials.${0}.assetId.value`)}
																					`}
																				control={contractForm.control}
																				name='productionCompanyId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							key={`commercial-assetId
																							-${contractForm.watch(`commercials.${0}.assetId.value`)}
																							`}
																							data-cy='productionCompanyId'
																							{...field}
																							placeholder={
																								!props?.isPrint
																									? 'Production Company'
																									: ''
																							}
																							filterProperty='CompanyNames.Name'
																							APICaller={(filterModel) => getAllSignatory(contractForm.watch(`commercials.${0}.assetId.value`),filterModel)}
																							optionGenerator={
																								companyOptionGenerator
																							}
																							// defaultFilter={PRODUCTION_COMPANY_FILTER}
																							isDisabled={props?.isPrint}
																							isClearable={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																							}}
																							cacheOptions={false}
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																					</>
																				)}
																			/>
																			{contractForm.formState.errors?.productionCompanyId?.message && (
																				<FormHelperText
																					error={true}
																					data-cy='errorHelper_productionCompanyId'
																				>
																					{
																						contractForm.formState?.errors
																							?.productionCompanyId.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																		{!props?.isPrint && (
																			<div className={styles.inlineAdd}>
																				<AddCompanyInline
																					title='Company'
																					companyType={{
																						mainType:'PRODUCTION COMPANY' as CompanyMainType,
																						subType: [],
																					}}
																				/>
																			</div>
																		)}
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<FormLabel>Talent Name:</FormLabel>
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				key={`performer-name-${contractForm.watch(
																					'performerId.value'
																				)}`}
																				control={contractForm.control}
																				name='performerId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							data-cy='name_select'
																							{...field}
																							placeholder={
																								!props?.isPrint
																									? 'Select Performer'
																									: ''
																							}
																							key={`performer-name-${contractForm.watch(
																								'performerId.value'
																							)}`}
																							isClearable={true}
																							filterProperty='PersonName.FirstName'
																							APICaller={(
																								filterModel,
																								searchText
																							) =>
																								GetByWithFullNameSearchForBasicDetail(
																									filterModel,
																									searchText
																								)
																							}
																							isSearchText={true}
																							isDisabled={props?.isPrint}
																							optionGenerator={
																								performerOptionGenerator
																							}
																							defaultFilter={getPerformerFilter()}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.setValue('performerPhoneId','',{shouldDirty:true});
																								contractForm.trigger('performerPhoneId');
																								contractForm.setValue(
																									'performerEmailId',
																									'',
																									{ shouldDirty: true }
																								);
																								contractForm.trigger(
																									'performerEmailId'
																								);
																								contractForm.setValue('printName','',{shouldDirty:true});
																								contractForm.setValue(
																									'akaName',
																									contractForm.watch('performerId.label') && getTalentAliasName() || '',
																									{ shouldDirty: true }
																								);
																								contractForm.setValue('performerAddressId',null,{shouldDirty:true});
																								contractForm.setValue('talentAgencyId',null,{shouldDirty:true});
																								contractForm.setValue('talentAgencyAddressId',null,{shouldDirty:true});
																								contractForm.setValue('talentAgencyEmailId','',{shouldDirty:true});
																								contractForm.setValue('talentAgencyPhoneId','',{shouldDirty:true});
																								contractForm.setValue('talentAgencyStaffId',null,{shouldDirty:true});
																							}}
																							cacheOptions={false}
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors
																				?.performerId?.message && (
																				<FormHelperText
																					data-cy='errorHelper_first_session_date'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.performerId?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																		{!props?.isPrint && (
																			<div className={styles.inlineAdd}>
																				<AddPersonInline
																					title='Person'
																					onPersonCreated={() => ({})}
																					initialValues={{
																						personType: ['TALENT'],
																						isMinor: false,
																					}}
																				/>
																			</div>
																		)}
																	</td>
																</tr>
																<tr>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<FormLabel>Part:</FormLabel>
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='partName'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors?.partName
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper-partName'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.partName.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td>  
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<FormLabel>Pay Master:</FormLabel>
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='payMaster'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors?.payMaster
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper-payMaster'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.payMaster.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																</tr>
																<tr>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<FormLabel>Session Workday (s):</FormLabel>
																		</FormControl>
																	</td>
																	<td>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='sessionWorkDays'
																				defaultValue=''
																				render={({ field }) => (
																					<NabuTextbox
																						{...field}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/>
																			{contractForm.formState.errors
																				?.sessionWorkDays?.message && (
																				<FormHelperText
																					data-cy='errorHelper-sessionWorkDays'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors
																							?.sessionWorkDays.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td colSpan={2}>
																		<p>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				<FormLabel>Location</FormLabel>
																			</FormControl>
																		</p>
																		<table width={'100%' }>
																			{(!props?.isPrint) && (<><tr>
																				<td width={50}>
																					<FormControl>
																						<FormLabel>Country: </FormLabel>
																					</FormControl>
																				</td>
																				<td valign='middle'>
																					<FormControl fullWidth>
																						<Controller
																							control={contractForm.control}
																							name='sessionCountry'
																							defaultValue={DEFAULT_COUNTRY_CODE}
																							render={({ field }) => (
																								<NabuSelect
																									{...field}
																									disabled={props?.isPrint}
																									options={getCountryOptions}
																									onChange={(e) => {
																										field.onChange(e);
																										contractForm.setValue('printState', '', { shouldDirty: true });
																										contractForm.setValue('sessionCity', '', { shouldDirty: true });
																										contractForm.setValue('sessionState', '', { shouldDirty: true });
																										getCountryLabel();
																									} }
																									placeholder='Select Country' />
																							)} />
																					</FormControl>
																				</td>
																				{(contractForm.watch('sessionCountry') === DEFAULT_COUNTRY_CODE) && (<>
																					<td width={50}>
																						<FormControl>
																							<FormLabel>State: </FormLabel>
																						</FormControl>
																					</td>
																					<td valign='middle'>
																						<FormControl
																							fullWidth
																						>
																							<Controller
																								control={contractForm.control}
																								name='sessionState'
																								defaultValue=''
																								render={({ field }) => (
																									<NabuSelect
																										{...field}
																										options={getStateOptions()}
																										disabled={props?.isPrint}
																										convertValueToUpperCase={false}
																										placeholder={!props?.isPrint ? 'Select State' : ''}
																										onChange={(e: any) => {
																											field.onChange(e);
																											contractForm.setValue(
																												'printState',
																												contractForm.watch(
																													'sessionState'
																												),
																												{ shouldDirty: true }
																											);
																											contractForm.setValue('sessionCity','',{shouldDirty:true});
																											getStateLabel();
																										} } />
																								)} />
																							{contractForm?.formState?.errors
																								?.sessionState?.message && (
																								<FormHelperText
																									data-cy='errorHelper_location_state'
																									error={true}
																								>
																									{contractForm.formState?.errors
																										?.sessionState?.message}
																								</FormHelperText>
																							)}
																						</FormControl>
																					</td>
																				</>)}

																			</tr><tr>
																				<td valign='middle' width={40}>
																					<FormControl

																					>
																						<FormLabel>City:</FormLabel>
																					</FormControl>
																				</td>
																				<td valign='middle' colSpan={3}>
																					<FormControl
																						fullWidth
																					>
																						<Controller
																							control={contractForm.control}
																							name='sessionCity'
																							defaultValue=''
																							render={({ field }) => (
																								(contractForm.watch('sessionCountry') === DEFAULT_COUNTRY_CODE) ?
																									(<NabuSelect
																										{...field}
																										disabled={props?.isPrint}
																										options={getCityOptions(
																											contractForm.watch('sessionState')
																										)}
																										convertValueToUpperCase={true}
																										placeholder={!props?.isPrint ? 'Select City' : ''} />) :
																									(<NabuTextbox
																										{...field}
																										placeholder={!props?.isPrint ? 'City' : ''}
																										disabled={props?.isPrint} />
																									)
																							)} />
																						{contractForm.formState?.errors?.sessionCity
																							?.message && (
																							<FormHelperText
																								data-cy='errorHelper_location_city'
																								error={true}
																							>
																								{contractForm.formState?.errors
																									?.sessionCity?.message}
																							</FormHelperText>
																						)}
																					</FormControl>
																				</td>
																			</tr></>)}
																			{props?.isPrint && (
																				<div style={{display:'flex', flexDirection: 'column', flexWrap:'wrap', textAlign:'left'}}>
																					{(contractForm.watch('sessionCountry') || contractForm.watch('sessionState') || contractForm.watch('sessionCity')) &&
																					<span>{`${contractForm.watch('sessionCity') || ''}${contractForm.watch('sessionCity') && (getCountryLabel() || contractForm.watch('sessionState')) ? '.' : ''}${getCountryLabel() || ''}${contractForm.watch('sessionState') ? '/' + getStateLabel() : ''}`}</span>
																					}
																				</div>
																			)}
																		</table>
																	</td>
																</tr>
															</table>
															<br></br>
															<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`} align='center' >
																<tr>
																	<td>Commercial Title(s)<sup></sup></td>
																	<td>Ad-ID(s)<sup></sup></td>
																	<td># Spots</td>
																	{!props?.isPrint && <td></td>}
																</tr>
																{commercialFieldArray.fields.map((field, index) => (
																	<tr key={`commercial-${field.id}`}>
																		<td width={300}>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				<Controller
																					control={contractForm.control}
																					name={`commercials.${index}.assetId`}
																					render={({ field }) => (
																						<>
																							<LookAheadSelect
																								data-cy='source-asset'
																								{...field}
																								placeholder={!props?.isPrint ? 'Commercial' : ''}
																								filterProperty='Title'
																								isDisabled={props?.isPrint || !contractForm.watch('advertiserId.value')}
																								defaultFilter={getCommercialFilters()}
																								APICaller={(filterModel) => getAllAssetForBasicDetail( filterModel, false)}
																								optionGenerator={lookAheadAssetOptionGenerator}
																								onChange={(e: any) => {field.onChange(e);handleProducerChange(index);}}
																								isClearable={true}  
																								cacheOptions
																								defaultOptions
																								dropDownsize={25}
																								className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																							/>
																						</>
																					)}
																				/>
																			</FormControl>
																		</td>
																		<td>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				<NabuTextbox
																					placeholder={!props?.isPrint ? 'Ad-Id' : ''}
																					isDisabled={props?.isPrint}
																					readOnly={true}
																					value={getAssetAdIdByAssetIndex(
																						index
																					)}
																				/>
																			</FormControl>
																		</td>
																		<td>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				<Controller
																					control={contractForm.control}
																					name={`commercials.${index}.spots`}
																					defaultValue=''
																					render={({ field }) => (
																						<NabuTextbox
																							{...field}
																							placeholder={!props?.isPrint ? 'Spots' : ''}
																							disabled={props?.isPrint}
																						/>
																					)}
																				/>
																			</FormControl>
																		</td>
																		{!props?.isPrint && (
																			<>
																				<td>
																					{commercialFieldArray.fields.length - 1 ===
																	index && (
																						<NabuButton
																							variant='text'
																							onClick={onAddCommercial}
																						>
																							<Add />
																						</NabuButton>
																					)}
																					{commercialFieldArray?.fields.length > 1 && (
																						<NabuButton
																							variant='text'
																							onClick={() => {onRemoveCommercial(index);handleProducerChange(index);}}
																						>
																							<DeleteForever color='error' />
																						</NabuButton>
																					)}
																				</td>
																			</>
																		)}
																	</tr>
																))}
															</table>
															<br></br>
															<table
																width={'100%'}
																border={1}
																cellPadding={0}
																cellSpacing={0}
																className={`${styles.contractFormsInner} keep-together`}
															>
																<tr style={{ background: '#808080' }}>
																	<th style={{ textAlign: 'center' }} colSpan={2}>
													Dates<sup></sup>
																	</th>
																	<th style={{ textAlign: 'center' }} colSpan={2}>
													Work Time
																		<div
																			style={{
																				display: 'flex',
																				justifyContent: 'space-around',
																			}}
																		>
																			<span>Time in</span> <span>Time Out</span>{' '}
																		</div>
																	</th>
																	<th style={{ textAlign: 'center' }} colSpan={2}>
																		Lunch Time
																		<div
																			style={{
																				display: 'flex',
																				justifyContent: 'space-around',
																			}}
																		>
																			<span>Lunch in</span> <span>Lunch Out</span>{' '}
																		</div>
																	</th>
																	<th style={{ textAlign: 'center' }} colSpan={2}>
																		Fitting Time
																		<div
																			style={{
																				display: 'flex',
																				justifyContent: 'space-around',
																			}}
																		>
																			<span>Fitting in</span> <span>Fitting Out</span>{' '}
																		</div>
																	</th>
																	{!props?.isPrint && (
																		<th style={{ textAlign: 'center' }}></th>
																	)}
																</tr>
																{workDayFieldArray.fields.map((field, index) => (
																	<tr key={`work-field-${field.id}`}>
																		<td height={40} colSpan={2}>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				<Controller
																					control={contractForm.control}
																					name={`workDays.${index}.date`}
																					defaultValue=''
																					render={({ field }) => (
																						<NabuDatepicker
																							{...field}
																							disabled={props?.isPrint}
																							placeholder={!props?.isPrint ? 'Date' : ''}
																						/>
																					)}
																				/>
																				{!!contractForm.formState?.errors?.workDays
																					?.length && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState?.errors?.workDays[
																								index
																							]?.date?.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>
																		</td>
																		<td>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				{props?.isPrint ? (
																					<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeStartAt`) || '', 'hh:mm aa')}</p>
																				) : (<Controller
																					control={contractForm.control}
																					name={`workDays.${index}.workTimeStartAt`}
																					render={({ field }) => (
																						<NabuTimePicker
																							{...field}
																							ampm={true}
																							disabled={!(contractForm.watch(`workDays.${index}.date`))}
																						/>
																					)}
																				/>)}
																				{!!contractForm.formState?.errors?.workDays
																					?.length && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState?.errors?.workDays[
																								index
																							]?.workTimeStartAt?.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>
																		</td>
																		<td>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				{props?.isPrint ? (
																					<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeEndAt`) || '', 'hh:mm aa')}</p>
																				) : (<Controller
																					control={contractForm.control}
																					name={`workDays.${index}.workTimeEndAt`}
																					render={({ field }) => (
																						<NabuTimePicker
																							{...field}
																							ampm={true}
																							disabled={!(contractForm.watch(`workDays.${index}.date`))}
																						/>
																					)}
																				/>)}
																				{!!contractForm.formState?.errors?.workDays
																					?.length && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState?.errors?.workDays[
																								index
																							]?.workTimeEndAt?.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>
																		</td>
																		<td>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.mealTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																				{props?.isPrint ? (
																					<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.mealTimeStartAt`) || '', 'hh:mm aa')}</p>
																				) : (<Controller
																					control={contractForm.control}
																					name={`workDays.${index}.mealTimeStartAt`}
																					render={({ field }) => (
																						<NabuTimePicker
																							{...field}
																							ampm={true}
																							disabled={!(contractForm.watch(`workDays.${index}.date`))}
																						/>
																					)}
																				/>)}
																				{!!contractForm.formState?.errors?.workDays
																					?.length && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState?.errors?.workDays[
																								index
																							]?.mealTimeStartAt?.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>
																		</td>
																		<td>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.mealTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																				{props?.isPrint ? (
																					<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.mealTimeEndAt`) || '', 'hh:mm aa')}</p>
																				) : (<Controller
																					control={contractForm.control}
																					name={`workDays.${index}.mealTimeEndAt`}
																					render={({ field }) => (
																						<NabuTimePicker
																							{...field}
																							ampm={true}
																							disabled={!(contractForm.watch(`workDays.${index}.date`))}
																						/>
																					)}
																				/>)}
																				{!!contractForm.formState?.errors?.workDays
																					?.length && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState?.errors?.workDays[
																								index
																							]?.mealTimeEndAt?.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>
																		</td>
																		<td>
																			<FormControl className={styles.tableForms}>
																				{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.makeUpTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																				{props?.isPrint ? (
																					<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.makeUpTimeStartAt`) || '', 'hh:mm aa')}</p>
																				) : (<Controller
																					control={contractForm.control}
																					name={`workDays.${index}.makeUpTimeStartAt`}
																					render={({ field }) => (
																						<NabuTimePicker
																							{...field}
																							ampm={true}
																							disabled={!(contractForm.watch(`workDays.${index}.date`))}
																						/>
																					)}
																				/>)}
																				{!!contractForm.formState?.errors?.workDays
																					?.length && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState?.errors?.workDays[
																								index
																							]?.makeUpTimeStartAt?.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>
																		</td>
																		<td>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.makeUpTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
																				{props?.isPrint ? (
																					<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.makeUpTimeEndAt`) || '', 'hh:mm aa')}</p>
																				) : (<Controller
																					control={contractForm.control}
																					name={`workDays.${index}.makeUpTimeEndAt`}
																					render={({ field }) => (
																						<NabuTimePicker
																							{...field}
																							ampm={true}
																							disabled={!(contractForm.watch(`workDays.${index}.date`))}
																						/>
																					)}
																				/>)}
																				{!!contractForm.formState?.errors?.workDays
																					?.length && (
																					<FormHelperText error={true}>
																						{
																							contractForm.formState?.errors?.workDays[
																								index
																							]?.makeUpTimeEndAt?.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>
																		</td>
																		{!props?.isPrint && (
																			<>
																				<td style={{ display: 'flex' }}>
																					{workDayFieldArray.fields.length - 1 ===
																	index && (
																						<NabuButton
																							variant='text'
																							onClick={onAddWorkDay}
																						>
																							<Add />
																						</NabuButton>
																					)}
																					{workDayFieldArray?.fields.length > 1 && (
																						<NabuButton
																							variant='text'
																							onClick={() => onRemoveWorkDay(index)}
																						>
																							<DeleteForever color='error' />
																						</NabuButton>
																					)}
																				</td>
																			</>
																		)}
																	</tr>
																))}
															</table>
															<br></br>

															<div className="keep-together">
																<p><b>COMPENSATION</b></p>
																<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
																	<tr>
																		<td>
																			<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																				<span>Session +Use Fee: $</span>
																				<FormControl
																					className={styles.tableForms}
																				>
																					<Controller
																						control={contractForm.control}
																						name='sessionFeePlusUseFee'
																						render={({ field }) => (
																							<input
																								{...field}
																								disabled={props?.isPrint}
																								min={0}
																								type='number'
																								onChange={(e: any) => {
																									const value = e?.target?.value || '';
																									if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																										field.onChange(value);
																									} else {
																										const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																										field.onChange(num);
																									}
																								}}
																								onBlur={() => {
																									const numericValue = parseFloat((field.value ?? '0').toString());
																									if (!isNaN(numericValue)) {
																										field.onChange(numericValue.toFixed(2));
																									} else {
																										field.onChange('0.00');
																									}
																								}}
																								placeholder =
																									{
																										!props?.isPrint
																											? 'Session Fee + Use Fee'
																											: ''
																									}
																							/>
																						)}
																					/>
																				</FormControl>
																				<span>Flat fee based on</span>
																				<FormControl
																					className={styles.tableForms}
																				>
																					<Controller
																						control={contractForm.control}
																						name='sessionFeePlusUseFeeHour'
																						render={({ field }) => (
																							<input
																								{...field}
																								disabled={props?.isPrint}
																								min={0}
																								type='number'
																								onChange={(e: any) => {
																									const value = e?.target?.value || '';
																									if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																										field.onChange(value);
																									} else {
																										const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																										field.onChange(num);
																									}
																								}}
																								onBlur={() => {
																									const numericValue = parseFloat((field.value ?? '0').toString());
																									if (!isNaN(numericValue)) {
																										field.onChange(numericValue.toFixed(2));
																									} else {
																										field.onChange('0.00');
																									}
																								}}
																								placeholder =
																									{
																										!props?.isPrint
																											? 'Session Fee + Use Fee Hour'
																											: ''
																									}
																							/>
																						)}
																					/>
																				</FormControl>
																				<span>hours worked per day  </span>
																			</div>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																				<span>Overtime: $</span>
																				<FormControl
																					className={styles.tableForms}
																				>
																					<Controller
																						control={contractForm.control}
																						name='overtime'
																						render={({ field }) => (
																							<input
																								{...field}
																								disabled={props?.isPrint}
																								min={0}
																								type='number'
																								onChange={(e: any) => {
																									const value = e?.target?.value || '';
																									if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																										field.onChange(value);
																									} else {
																										const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																										field.onChange(num);
																									}
																								}}
																								onBlur={() => {
																									const numericValue = parseFloat((field.value ?? '0').toString());
																									if (!isNaN(numericValue)) {
																										field.onChange(numericValue.toFixed(2));
																									} else {
																										field.onChange('0.00');
																									}
																								}}
																								placeholder =
																									{
																										!props?.isPrint
																											? 'Over Time '
																											: ''
																									}
																							/>
																						)}
																					/>
																				</FormControl>
																				<span>OT 1    9-10 hrs</span>
																				<FormControl
																					className={styles.tableForms}
																				>
																					<Controller
																						control={contractForm.control}
																						name='overtimeOt1'
																						render={({ field }) => (
																							<input
																								{...field}
																								disabled={props?.isPrint}
																								min={0}
																								type='number'
																								onChange={(e: any) => {
																									const value = e?.target?.value || '';
																									if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																										field.onChange(value);
																									} else {
																										const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																										field.onChange(num);
																									}
																								}}
																								onBlur={() => {
																									const numericValue = parseFloat((field.value ?? '0').toString());
																									if (!isNaN(numericValue)) {
																										field.onChange(numericValue.toFixed(2));
																									} else {
																										field.onChange('0.00');
																									}
																								}}
																							/>
																						)}
																					/>
																				</FormControl>
																				<span>OT2    11-12 hrs  </span>
																				<FormControl
																					className={styles.tableForms}
																				>
																					<Controller
																						control={contractForm.control}
																						name='overtimeOt2'
																						render={({ field }) => (
																							<input
																								{...field}
																								disabled={props?.isPrint}
																								min={0}
																								type='number'
																								onChange={(e: any) => {
																									const value = e?.target?.value || '';
																									if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																										field.onChange(value);
																									} else {
																										const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																										field.onChange(num);
																									}
																								}}
																								onBlur={() => {
																									const numericValue = parseFloat((field.value ?? '0').toString());
																									if (!isNaN(numericValue)) {
																										field.onChange(numericValue.toFixed(2));
																									} else {
																										field.onChange('0.00');
																									}
																								}}
																							/>
																						)}
																					/>
																				</FormControl>
																			</div>
																		</td>
																	</tr>
																	<tr>
																		<td>
																			<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																				<span>Fitting Fee: $ </span>
																				<FormControl
																					className={styles.tableForms}
																				>
																					<Controller
																						control={contractForm.control}
																						name='fittingFee'
																						render={({ field }) => (
																							<input
																								{...field}
																								disabled={props?.isPrint}
																								min={0}
																								type='number'
																								placeholder =
																									{
																										!props?.isPrint
																											? 'Fitting Fee '
																											: ''
																									}
																								onChange={(e: any) => {
																									const value = e?.target?.value || '';
																									if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																										field.onChange(value);
																									} else {
																										const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																										field.onChange(num);
																									}
																								}}
																								onBlur={() => {
																									const numericValue = parseFloat((field.value ?? '0').toString());
																									if (!isNaN(numericValue)) {
																										field.onChange(numericValue.toFixed(2));
																									} else {
																										field.onChange('0.00');
																									}
																								}}
																							/>
																						)}
																					/>
																				</FormControl>
																				<Controller
																					control={contractForm.control}
																					name='fittingFeeFlatFee'
																					defaultValue={true}
																					render={({ field }) => (
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e: any) => {
																									const value = e?.target?.value || '';
																									if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																										field.onChange(value);
																									} else {
																										const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																										field.onChange(num);
																									}
																									contractForm.setValue('fittingFeeHourly', !contractForm.watch('fittingFeeFlatFee'));
																								}}
																								onBlur={() => {
																									const numericValue = parseFloat((field.value ?? '0').toString());
																									if (!isNaN(numericValue)) {
																										field.onChange(numericValue.toFixed(2));
																									} else {
																										field.onChange('0.00');
																									}
																								}}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '2px' }}
																							/>
																							<span> Flat Fee </span>
																						</Box>
																					)}
																				/>
																				Or
																				<Controller
																					control={contractForm.control}
																					name='fittingFeeHourly'
																					// defaultValue={true}
																					render={({ field }) => (
																						<Box display={'flex'} alignItems={'flex-start'}>
																							<input
																								{...field}
																								value={field.value + ''}
																								checked={field.value}
																								onChange={(e) => {
																									field.onChange(e);
																									contractForm.setValue('fittingFeeFlatFee', !contractForm.watch('fittingFeeHourly'));
																								}}
																								type='checkbox'
																								disabled={props?.isPrint}
																								style={{ marginTop: '2px' }}
																							/>
																							<span> Hourly </span>
																						</Box>
																					)}
																				/>
																			</div>
																		</td>
																	</tr>
																</table>
																<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner} style={{borderTop:'0px'}}>
																	<tr>
																		<td width={120} style={{verticalAlign:'top'}}>
																			<span>Additional Fees: $ </span>
																		</td>	
																		<td>
																			{additionalFeeArray.fields.map((field, index) => (
																				<div  key={`additionalFees-${field.id}`} style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																					<FormControl
																						className={styles.tableForms}
																					>
																						<Controller
																							control={contractForm.control}
																							name={`additionalFees.${index}.fees`}
																							render={({ field }) => (
																								<input
																									{...field}
																									disabled={props?.isPrint}
																									min={0}
																									type='number'
																									placeholder =
																										{
																											!props?.isPrint
																												? 'Additional Fee'
																												: ''
																										}
																									onChange={(e: any) => {
																										const value = e?.target?.value || '';
																										if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																											field.onChange(value);
																										} else {
																											const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																											field.onChange(num);
																										}
																									}}
																									onBlur={() => {
																										const numericValue = parseFloat((field.value ?? '0').toString());
																										if (!isNaN(numericValue)) {
																											field.onChange(numericValue.toFixed(2));
																										} else {
																											field.onChange('0.00');
																										}
																									}}
																								/>
																							)}
																						/>
																					</FormControl>
																					<Controller
																						control={contractForm.control}
																						name={`additionalFees.${index}.additionalFeeFlatFee`}
																						defaultValue={true}
																						render={({ field }) => (
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value={field.value + ''}
																									checked={field.value}
																									onChange={(e) => {
																										field.onChange(e);
																										contractForm.setValue(`additionalFees.${index}.additionalFeeHourly`, !contractForm.watch(`additionalFees.${index}.additionalFeeFlatFee`));
																									}}
																									type='checkbox'
																									disabled={props?.isPrint}
																									style={{ marginTop: '2px' }}
																								/>
																								<span> Flat Fee </span>
																							</Box>
																						)}
																					/>
																				Or
																					<Controller
																						control={contractForm.control}
																						name={`additionalFees.${index}.additionalFeeHourly`}
																						// defaultValue={true}
																						render={({ field }) => (
																							<Box display={'flex'} alignItems={'flex-start'}>
																								<input
																									{...field}
																									value={field.value + ''}
																									checked={field.value}
																									onChange={(e) => {
																										field.onChange(e);
																										contractForm.setValue(`additionalFees.${index}.additionalFeeFlatFee`, !contractForm.watch(`additionalFees.${index}.additionalFeeHourly`));
																									}}
																									type='checkbox'
																									disabled={props?.isPrint}
																									style={{ marginTop: '2px' }}
																								/>
																								<span> Hourly </span>
																							</Box>
																						)}
																					/>
																					<span>Define</span>
																					<FormControl>
																						<Controller
																							control={contractForm.control}
																							name={`additionalFees.${index}.additionalFeeDefine`}
																							render={({ field }) => (
																								<>
																									<NabuSelect
																										{...field}
																										placeholder={
																											!props?.isPrint ? 'Select Additional Fee' : ''
																										}
																										options={AdditionalFeeDefineOptions || []}
																										convertValueToUpperCase={false}
																										onChange={(e) => field.onChange(e)}
																										disabled={props?.isPrint}
																									/>
																								</>
																							)}
																						/>
																					</FormControl> 
																					<span>Other</span>
																					<FormControl
																						className={styles.tableForms}
																					>
																						<Controller
																							control={contractForm.control}
																							name={`additionalFees.${index}.otherFee`}
																							render={({ field }) => (
																								<input
																									{...field}
																									disabled={props?.isPrint}
																								/>
																							)}
																						/>
																					</FormControl>
																					{!props?.isPrint && (
																						<>
																							{additionalFeeArray.fields.length - 1 ===
																	index && (
																								<NabuButton
																									variant='text'
																									onClick={onAddAdditionalFees}
																								>
																									<Add />
																								</NabuButton>
																							)}
																							{additionalFeeArray?.fields.length > 1 && (
																								<NabuButton
																									variant='text'
																									onClick={() => onRemoveAdditionalFees(index)}
																								>
																									<DeleteForever color='error' />
																								</NabuButton>
																							)}
																						</>
																					)}
																				</div>
																			))}
																		</td>
																	</tr>
																</table>
															</div>
															<br></br>
															<div className='keep-together'>
																<p><b>USE RIGHTS</b></p>
																<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
																	<tr>
																		<td style={{verticalAlign:'top'}}>
																			<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																				<span><b>Term : </b></span> 
																				<FormControl
																					className={styles.tableForms}
																					sx={{minWidth:200}}
																				>
																					<Controller
																						control={contractForm.control}
																						name='termDuration'
																						defaultValue=''
																						render={({ field }) => (
																							<NabuTextbox
																								{...field} 
																								data-cy='termDuration_input' 
																								placeholder='Duration'
																								disabled={props?.isPrint}
																								onChange={e => {
																									if(parseInt(e?.target?.value) >= 1 || e?.target?.value ==='') field.onChange(e);
																								}}/>
																						)}
																					/>
																				</FormControl>
																				<FormControl
																					className={styles.tableForms}
																					sx={{minWidth:200}}
																				>
																					<Controller
																						control={contractForm.control}
																						name='termDurationUnit'
																						defaultValue=''
																						render={({ field }) => (
																							<Select
																								size='small'
																								placeholder='Select Unit'
																								data-cy='termDurationUnit_select'
																								displayEmpty={true}
																								{...field}
																								value={field.value || ''}
																								onChange={(e: any) => { 
																									field.onChange(e);
																								}}
																								disabled={props?.isPrint}
																							>
																								<MenuItem value='' disabled>Select Unit</MenuItem>
																								{PeriodUnitsOptions.map((type,index) => (
																									<MenuItem
																										key={index}
																										value={type}
																									>
																										{type}
																									</MenuItem>
																								))}
																							</Select>
																						)}
																					/>
																				</FormControl>
																				<div>
																					<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																						<Controller
																							control={contractForm.control}
																							name='isTermFromFirstUseDate'
																							defaultValue={true}
																							render={({ field }) => (
																								<Box display={'flex'} alignItems={'flex-start'}>
																									<input
																										{...field}
																										value={field.value + ''}
																										checked={field.value}
																										onChange={(e) => {
																											field.onChange(e);
																											contractForm.setValue('isTermFromDate', !contractForm.watch('isTermFromFirstUseDate'));
																										}}
																										type='checkbox'
																										disabled={props?.isPrint}
																										style={{ marginTop: '2px' }}
																									/>
																									<span>From first use date</span>
																								</Box>
																							)}
																						/>
																						{contractForm.watch('isTermFromFirstUseDate') && (
																							<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																								{/* <span>(Tentative Date of first use)</span> */}
																								<FormControl
																									className={styles.tableForms}
																									sx={{minWidth:200}}
																								>
																									<Controller
																										control={contractForm.control}
																										name='termFromFirstUseDate'
																										render={({ field }) => (
																											<NabuDatepicker
																												{...field}
																												disabled={props?.isPrint}
																												placeholder={!props?.isPrint ? 'Date' : ''}
																											/>
																										)}
																									/>
																								</FormControl>
																							</div>
																						)}
																						<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																							<Controller
																								control={contractForm.control}
																								name='isTermFromDate'
																								render={({ field }) => (
																									<Box display={'flex'} alignItems={'flex-start'}>
																										<input
																											{...field}
																											value={field.value + ''}
																											checked={field.value}
																											onChange={(e) => {
																												field.onChange(e);
																												contractForm.setValue('isTermFromFirstUseDate', !contractForm.watch('isTermFromDate'));
																											}}
																											type='checkbox'
																											disabled={props?.isPrint}
																											style={{ marginTop: '2px' }}
																										/>
																										<span> From date </span>
																									</Box>
																								)}
																							/>
																							{contractForm.watch('isTermFromDate') && (
																								<>
																									<FormControl
																										className={styles.tableForms}
																										sx={{minWidth:200}}
																									>
																										<Controller
																											control={contractForm.control}
																											name='termFromDate'
																											render={({ field }) => (
																												<NabuDatepicker
																													{...field}
																													disabled={props?.isPrint}
																													placeholder={!props?.isPrint ? 'Date' : ''}
																												/>
																											)}
																										/>
																									</FormControl>
																								</>
																							)}
																						</div>
																					</div>
																				
																				</div>
																			</div>
																		</td>
																	</tr>
																</table>
																<tr>
																	<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={styles.contractFormsInner} style={{borderTop:'0', borderBottom:'0'}}>
																		<td style={{padding:'0'}}>
																			<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={styles.contractFormsInner} style={{borderTop:'0', borderBottom:'0'}}>
																				<tr>
																					<td colSpan={3}>
																						<div style={{display:'flex', gap:'5px'}}>
																							<Grid item md={12}>
																								{(!props.isPrint) && <Table className={styles.customTableMediaType}>
																									<TableHead sx={{borderRadius:'8px'}}>
																										<TableRow>
																											<TableCell width={'25%'}>Media Type <sup>*</sup></TableCell>
																											<TableCell width={'25%'}>Media Subtype <sup></sup></TableCell>
																											<TableCell width={'30%'}>Media Subtype Details <sup></sup></TableCell>
																											<TableCell width={'20%'}></TableCell>
																										</TableRow>
																									</TableHead>
																									<TableBody>
																										{/* Row 1 */}
																										{ mediaInformationFormFields.fields.map((field, index) => (
																											<TableRow key={field.id}>
																												<TableCell width={'25%'}>
																													<FormControl fullWidth>

																														<Controller
																															control={contractForm.control}
																															name={`mediaInformation.${index}.mediaType`}
																															defaultValue=''
																															render={({ field }) => (
																																<NabuSelect
																																	{...field}
																																	options={getMediaTypeOptions(NONUNIONVALUE)?.map(item => {
																																		return { value: item, label: item };
																																	})}
																																	onChange={(e) => {
																																		field.onChange(e);
																																		contractForm.setValue(`mediaInformation.${index}.mediaSubType`, '');
																																	} }
																																	placeholder={'Media Type'} />
																															)} />
																														{contractForm.formState?.errors?.mediaInformation && contractForm.formState.errors.mediaInformation[index]?.mediaType && (
																															<FormHelperText data-cy='rights_coverage_required' error={true}>
																																{contractForm.formState.errors.mediaInformation[index]?.mediaType?.message}
																															</FormHelperText>)}
																													</FormControl>
																												</TableCell>
																												<TableCell width={'25%'}>
																													<FormControl fullWidth>
																														<Controller
																															control={contractForm.control}
																															name={`mediaInformation.${index}.mediaSubType`}
																															defaultValue=''
																															render={({ field }) => (
																																<NabuSelect
																																	{...field}
																																	options={getMediaSubTypeOptions(contractForm.watch(`mediaInformation.${index}.mediaType`))?.map(item => {
																																		return { value: item, label: item };
																																	})}
																																	placeholder={'Media Subtype'}
																																	onChange={(e) => {
																																		field.onChange(e);
																																		contractForm.setValue(`mediaInformation.${index}.mediaSubTypeDetails`, []);
																																	} } />
																															)} />
																													</FormControl>
																												</TableCell>
																												<TableCell width={'25%'}>
																													<FormControl fullWidth sx={{maxWidth:500}}>

																														<Controller
																															control={contractForm.control}
																															name={`mediaInformation.${index}.mediaSubTypeDetails`}
																															defaultValue={[]}
																															render={({ field }) => (
																																<NabuMultiselect
																																	{...field}
																																	options={ [] }
																																	placeholder={'MEDIA SUBTYPE DETAILS'}
																																	selectedItems={contractForm.watch(`mediaInformation.${index}.mediaSubTypeDetails`) || []} />
																															)} />
																													</FormControl>
																												</TableCell>
																												<TableCell width={'20%'}>
																													{index !== 0 && (
																														<NabuButton
																															variant='text'
																															size='small'
																															onClick={() => mediaInformationFormFields.remove(index)}
																															data-cy='delete_btn'
																														>
																															<GridDeleteForeverIcon color='error' />
																														</NabuButton>
																													)}
												
																													{index === mediaInformationFormFields?.fields?.length-1 && <NabuButton onClick={() => mediaInformationFormFields.append({ mediaType: '', mediaSubType: '', mediaSubTypeDetails: [] })} variant='filled'> <Add/>  Media Information</NabuButton>}
																												</TableCell>
																											</TableRow>
																										))}
																									</TableBody>
																								</Table>}
																							</Grid>
																							<NabuDialog
																								title='Select MediaType'
																								open={isMediaTypeVisible}
																								maxWidth='lg'
																								handleClose={closeMediaType}
																								fullWidth={true}
																								data-cy='remove_dialog' 
																								dialogActions={[]}
																							>						
																								<TreeLevelMenu checkedNodes={checkedNodes} selectedIds={selectedIds} allCheckedNodes={allCheckedNodes} onSelectValues={(selectedNodes) => onSaveMediaFiles(selectedNodes)} />
																							</NabuDialog>
																						</div>
																		
																					</td>
																				</tr>
																				{(!props?.isPrint) ? ( <tr>
																					<td colSpan={3}>
																						<div>
																							{props?.initialValue?.mediaInformation.map((mediaInfo: any, index: number) => (
																								<span key={index}>
																									{`${mediaInfo?.mediaType} `}
																									{(mediaInfo?.mediaSubType) && '- '}
																									{`${mediaInfo?.mediaSubType} `}
																									{index !== (props?.initialValue?.mediaInformation?.length ? props?.initialValue?.mediaInformation?.length - 1 : 0) && ','}
																								
																								</span>
																							))}
																						</div>
																					</td>
																				</tr>):<tr>
																					<td colSpan={3}>
																						<div>
																							{props?.initialValue?.mediaInformation.map((mediaInfo: any, index: number) => (
																								<span key={index}>
																									{`${mediaInfo?.mediaType} `}
																									{(mediaInfo?.mediaSubType) && '- '}
																									{`${mediaInfo?.mediaSubType} `}
																									{index !== (props?.initialValue?.mediaInformation?.length ? props?.initialValue?.mediaInformation?.length - 1 : 0) && ','}
																								
																								</span>
																							))}
																						</div>
																					</td>
																				</tr>
																				}
																				<tr>
																					<td width={100}>
																						<span><b>Territory : </b></span>
																					</td>
																					<td>
																						<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																							{(contractForm.watch('isWorldWide') && props?.isPrint) && (
																								<Controller
																									control={contractForm.control}
																									name={'isWorldWide'}
																									render={({ field }) => (
																										<Box display={'flex'} alignItems={'flex-start'}>
																											<input
																												{...field}
																												checked={field.value || contractForm.watch('isWorldWide')}
																												value={field.value + ''}
																												onChange={(e) => {
																													if(e.target.checked){
																														resetTerritoriesValues();
																													}
																													else{
																														territoryFieldArray.fields.length == 0 && territoryFieldArray.append({countries: [], region: ''});
																													}
																													field.onChange(e);
																												}}
																												type='checkbox'
																												disabled={props?.isPrint}
																												style={{ marginTop: '2px' }}
																											/>
																											<span>WorldWide</span>
																										</Box>
																									)}
																								/>
																							)}
																							{(!props?.isPrint) && (
																								<Controller
																									control={contractForm.control}
																									name={'isWorldWide'}
																									render={({ field }) => (
																										<Box display={'flex'} alignItems={'flex-start'}>
																											<input
																												{...field}
																												checked={field.value || contractForm.watch('isWorldWide')}
																												value={field.value + ''}
																												onChange={(e) => {
																													if(e.target.checked){
																														resetTerritoriesValues();
																													}
																													else{
																														territoryFieldArray.fields.length == 0 && territoryFieldArray.append({countries: [], region: ''});
																													}
																													field.onChange(e);
																												}}
																												type='checkbox'
																												disabled={props?.isPrint}
																												style={{ marginTop: '2px' }}
																											/>
																											<span>WorldWide</span>
																										</Box>
																									)}
																								/>
																							)}

																							{props?.isPrint && (
																								<div style={{display:'flex', flexDirection: 'column', flexWrap:'wrap', textAlign:'left'}}>
																									{contractForm.watch('territories')?.filter(t => t.region && t.region != '').map((territory, index) => {
																										return (
																											<span key={index}>{`${continents[territory?.region]} - ${territory?.countries?.map((iso) => {
																												return getCountryName(iso);
																											}).join(', ')}`}</span>
																										);
																									})}
																								</div>
																							)}

																					
																						</div>
																					</td>
																					<td>
																						{!props?.isPrint &&territoryFieldArray.fields.map((region, index) => {
																							return(
																								<Grid item md={12} key={index}>
																									<Grid item container columnSpacing={3}>
																										<Grid item xs={12} md={4}>
																											<FormControl fullWidth>
																												<FormLabel
																													id='select-field-demo-label'
																													htmlFor='select-field-demo-button'
																													data-cy='region_label'
																												>
														Region <sup></sup>
																												</FormLabel>
																												<Controller
																													control={contractForm.control}
																													name={`territories.${index}.region`}
																													defaultValue={''}
																													render={({ field }) => (
																														<>
																															<NabuSelect
																																className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																																size='small'
																																placeholder={
																																	!props?.isPrint
																																		? 'Select Region Type'
																																		: ''
																																}
																																data-cy='region_select_input'
																																{...field}
																																onChange={(e: any) => { field.onChange(e); }}
																																options={continentsOptions}
																																disabled={props?.isPrint || contractForm.watch('isWorldWide') || false}
																															>
																															</NabuSelect>
																														</>
																													)} />
																											</FormControl>
																										</Grid>
																										<Grid item xs={12} md={4} >
																											<FormControl fullWidth>
																												<FormLabel
																													id='select-field-demo-label'
																													htmlFor='select-field-demo-button'
																													data-cy='country_label'
																												>
														Country <sup></sup>
																												</FormLabel>
																												<Controller
																													control={contractForm.control}
																													name={`territories.${index}.countries`}
																													defaultValue={[]}
																													render={({ field }) => (
																														<NabuMultiselect
																															size='small'
																															placeholder={
																																!props?.isPrint
																																	? 'Select country Type'
																																	: ''
																															}
																															data-cy='country_select_input'
																															selectedItems={field.value}
																															{...field}
																															onChange={(e: any) => { field.onChange(e); contractForm.trigger('territories');}}
																															options={getContinentCountries(contractForm.watch(`territories.${index}.region`))}
																															disabled={props?.isPrint || contractForm.watch('isWorldWide') || false}
																														>
																														</NabuMultiselect>
																													)}
																												/>
																											</FormControl>
																										</Grid>
																										{!props?.isPrint && (
																											<>
																												{index > 0 && <Grid item xs={12} md={0.5} >
																													<Box sx={{pt:3.5}}>
																														<NabuButton 
																															onClick={() => territoryFieldArray.remove(index)} 
																															variant='text' 
																															disabled={props?.isPrint || contractForm.watch('isWorldWide') || false}
																														>
																															<DeleteForever color='error' />
																														</NabuButton> &nbsp;
																													</Box>
																												</Grid>}
																												{index == (territoryFieldArray.fields?.length-1) && <Grid item xs={12} md={3} >
																													<Box sx={{pt:3.5}}>
																														<NabuButton 
																															variant='text' 
																															onClick={() => territoryFieldArray.append({countries: [], region: ''})}
																															disabled={props?.isPrint || contractForm.watch('isWorldWide') || false}
																														>
																															<Add/>
																														</NabuButton>
																													</Box>
																												</Grid>}
																											</>
																										)}
																									</Grid>
																								</Grid>
																							);
																						})}
																						{!props?.isPrint && <Grid item xs={12} >
																							<FormHelperText error={true} key={`territory_${contractForm.watch('territories')}_${territoryFieldArray.fields}_${contractForm.formState.errors?.territories?.message}`}>
																								{contractForm.formState.errors?.territories?.message || ''}
																							</FormHelperText>
																						</Grid>}
																					</td>
																				</tr>
																			</table>

																		</td>
																	</table>
																</tr>
																<tr>
																	<td>
																		<p><b>Historical uses: </b>Advertising materials may be used in perpetuity anywhere in the world by the Advertiser and its Agency at sales meetings and for intra-company, research, award shows, publicity (including, without limitation, on Ad Agency ‘s ‘reel’ and website), as a historical archive file for reference and educational purposes to be granted worldwide and in perpetuity. It is understood that social posts will remain in perpetuity on the Advertiser’s social media sites as long as no new reposts are made after the term.</p>
																	</td>
																</tr>
																<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={styles.contractFormsInner} style={{borderTop:'0', borderBottom:'0'}}>
																	<tr>
																		<td>
																			<p><b>Conditions:</b></p>
																			<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																				<p>	<span>All fees are plus</span>
																					<FormControl
																						className={styles.tableForms}
																					>
																						<Controller
																							control={contractForm.control}
																							name='allFee'
																							render={({ field }) => (
																								<input
																									{...field}
																									disabled={props?.isPrint}
																									min={0}
																									max={100} 
																									type='number'
																									onChange={(e: any) => {
																										const inputValue = e.target.value;
																
																										if (/^\d*\.?\d{0,2}$/.test(inputValue) && (inputValue === '' || parseFloat(inputValue) <= 100)) {
																											field.onChange(inputValue);
																										}
																									}}
																									onBlur={() => {
																										const numericValue = parseFloat(String(field.value));
																										if (!isNaN(numericValue)) {
																											const cappedValue = Math.min(100, numericValue);
																											field.onChange(cappedValue.toFixed(2));
																										} else {
																											field.onChange('');
																										}
																									}}
																								/>
																							)}
																						/>
																					</FormControl>
																					{/* <FormControl sx={{minWidth:'100px'}} className={styles.tableForms}><NabuTextbox placeholder=""/></FormControl> */}
																					<span>% agent fee, only if talent is represented.  All fees are paid in USD currency. Agency shall cause a third-party Pay Master to process and issue talent payments to Talent. Talent will be paid from timecard. The Pay Master shall be the employer of record for payroll purposes and shall process all the applicable state, federal and local taxes on such fees as noted above. Ad Agency is the hiring entity. Agent fees to be paid thru a payroll provider, no agent invoices needed. Session and Use payments are typically processed separately. </span>
																				</p>
																			</div>
																		</td>
																	</tr>
																</table>
															</div>

															<br></br>
															<p><b>For good and valuable consideration, receipt and sufficiency of which is hereby acknowledged, I hereby agree as follows:</b></p>
															<p>
						I am appearing as a talent in advertising material being produced by Agency and Advertiser on the work schedule listed above. I hereby agree to be so filmed, and I grant to Agency and Advertiser, and to other such persons as Agency or Advertiser may designate from time to time, the absolute right and permission to use any such material, including, without limitation, my likeness as portrayed therein and includes without limitation, the right to reproduce and use any recordings of my voice and all instrumental, musical, or other sounds produced by me during the Term in the Territory in accordance with the terms of Media and Historical Use Rights outlined above.
															</p>
															<br></br>
															<p>I agree that I will not hold Agency, Advertiser, or anyone who receives permission from either of them, responsible for any liability resulting from the use of my likeness, image and voice in the footage in accordance with the terms hereof, including what might be deemed to be misrepresentation of me, my character or my person due to distortion, optical illusion or faulty reproduction which may occur in the finished product. I hereby waive any right that I may have to inspect or approve the finished product or products or the advertising copy or other advertising materials that may be used in connection therewith.  </p>
															<br></br>
															<p>I hereby agree to not post any advertising material or behind the scenes material to my social media or any other venue which can be viewed by the public without the express written consent of Advertiser.  I also agree that I shall abide by any restrictions placed by Advertiser on my use of the advertising material.  I shall not use or disclose any of the Advertiser and or Agency trade secrets or confidential information (including, without limitation, the terms of this agreement). Any advertising materials placed during the Term which remain on display or in circulation following the expiration of the Term shall not give rise to a claim against the Advertiser and or Agency, provided, however that they shall not place any additional advertising materials produced hereunder in any media (other than as permitted herein) following the expiration of the Term. </p>

															<br></br>
															<p>I further agree in the event of a retake of any or all scenes in which I participate, or if additional scenes are required (whether originally completed or not) I will return to work and render my services in such scenes at the same rate of compensation as agreed for the original production hourly rate.</p>
															<br></br>
															<p> I understand that I will not receive any other payments and my compensation is outlined above. I understand that all payments and notices to me shall be delivered to the address listed on this agreement. I also understand that it is my sole responsibility to inform the Agency and Advertiser of any change of address in writing.  If a payment does not reach me due to a change of address, Agency and Advertiser will hold such payments until notified by me in writing of the new mailing address.  Notices with respect to any provisions herein, including but not limited to notices to renew, extend or exercise any rights or options herein shall be deemed accepted by me if Agency and/or Advertiser sent such notice to the address which I provided and is unable to reach me due to my failure to provide change of address notification to Agency and/or Advertiser in writing.</p>
															<br></br>
															<p> During the Term and throughout the Territory, I will not accept employment in, nor authorize use of my likeness, name or voice in any advertising material, for a competitive product or service of Advertiser, as defined above in the Exclusivity clause. I warrant and represent that this agreement does not in any way conflict with any existing exclusivity commitments on my part. Nothing herein will constitute any obligation on the Ad Agency or Advertiser to make any use of any of the rights set forth herein.</p>
															<br></br>
															<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																<p>This agreement is governed by the laws of the State of 
																	<FormControl className={styles.tableForms} >
																		{/* <NabuTextbox placeholder='Enter State'/>  */}
																		<Controller
																			control={contractForm.control}
																			name='printState'
																			// defaultValue=''
																			render={({ field }) => (
																				(contractForm.watch('sessionCountry') === DEFAULT_COUNTRY_CODE) ? (<NabuTextbox
																					placeholder={!props?.isPrint ? 'Enter State' : ''}
																					data-cy='job_name_textbox'
																					{...field}
																					value={getStateLabel()}
																					isDisabled={props?.isPrint}
																				/>):
																					(<input
																						{...field}
																						placeholder={!props?.isPrint ? 'State' : ''}
																						disabled={props?.isPrint}
																					/>
																					)
																			)}
																		/>
																	</FormControl>
																				, and the parties agree to that jurisdiction if a dispute arises. This Agreement contains the entire understanding between the parties regarding the subject matter hereof and supersedes all prior understandings. No waiver, modification or additions to this Agreement shall be valid unless in writing and signed by the parties hereto.</p>
															</div>
															<br></br>
															<p> By signing this agreement, I hereby agree that the Agency and/or Advertiser may take deductions from my earnings to adjust previous overpayments if and when said overpayments occur. I am over the age of 18 (19 in Alabama or Nebraska) and have the right to enter into this Agreement on my own behalf. By signing below, I acknowledge that I have read and understood all the terms and conditions of this Agreement and agree to be bound thereby.</p>
															<br></br>
															<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
																<tr>
																	<td>
								Signature 
																	</td>
																	<td>
																		<FormControl fullWidth className={styles.tableForms}><NabuTextbox placeholder="Signature" /></FormControl> 
																	</td>
																	<td>
								Date
																	</td>
																	<td>
																		<FormControl fullWidth className={styles.tableForms}><NabuDatepicker /></FormControl> 
																	</td>
																</tr>
																<tr>
																	<td>Print Legal Talent Name</td>
																	<td>
																		<FormControl fullWidth className={styles.tableForms} >
																			<Controller
																				key={`performer-print-name-${contractForm.watch(
																					'performerId.value'
																				)}`}
																				control={contractForm.control}
																				name='printName'
																				disabled={
																					!contractForm.watch(
																						'performerId.value'
																					) || props?.isPrint
																				}
																				render={({ field }) => (
																					<>
																						<NabuTextbox
																							key={`performer-print-name-${contractForm.watch(
																								'performerId.value'
																							)}`}
																							data-cy='printName_textbox'
																							placeholder='Performer'
																							{...field}
																							value={contractForm.watch('performerId.label')}
																						/>
																					</>
																				)}
																			/> 
																		</FormControl>
																	</td>
																	<td>AKA Name</td>
																	<td>
																		<FormControl fullWidth className={styles.tableForms} >
																			<Controller
																				control={contractForm.control}
																				name='akaName'
																				disabled={
																					!contractForm.watch(
																						'performerId.value'
																					) || props?.isPrint
																				}
																				render={({ field }) => (
																					<>
																						<NabuTextbox
																							data-cy='job_name_textbox'
																							placeholder='Performer'
																							{...field}
																							value={contractForm.watch('performerId.label') && getTalentAliasName() || ''}
																						/>
																					</>
																				)}
																			/> 
																		</FormControl>
																	</td>
																</tr>
																<tr>
																	<td width={200}>
								Talent Address
																	</td>
																	<td colSpan={3}>
																		<FormControl fullWidth className={styles.tableForms} >
																			<Controller
																				control={contractForm.control}
																				key={`select_Address_${contractForm.watch(
																					'performerId.value'
																				)}`}
																				name='performerAddressId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							{...field}
																							placeholder={
																								!props?.isPrint
																									? 'Select Address'
																									: ''
																							}
																							key={`performer-address-${contractForm.watch(
																								'performerId.value'
																							)}`}
																							filterProperty='Line1'
																							sortField='IsPrimary'
																							sortDescending={true}
																							onOptionLoaded={(options) => {
																								if(options?.length && !contractForm.watch('performerAddressId.value')){
																									contractForm.setValue('performerAddressId', options[0], { shouldDirty: true});
																									contractForm.trigger('performerAddressId', { shouldFocus: true});																						
																								}
																							}}
																							APICaller={(filterModel) =>
																								getAllAddressByPerson(
																									filterModel,
																									contractForm.watch(
																										'performerId.value'
																									) || ''
																								)
																							}
																							optionGenerator={
																								adAgencyAddressOptionGenerator
																							}
																							dropDownsize={50}
																							isDisabled={
																								props?.isPrint ||
																						!contractForm.watch(
																							'performerId.value'
																						)
																							}
																							isClearable={true}
																							isSearchText={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																							}}
																							cacheOptions={false}
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{																							
																							<Box sx={{mt:1.3}}>
																								{(contractForm.watch('performerId.value') && (!props.isPrint)) && 
																							<AddPersonAddressInline
																								personId={contractForm.watch('performerId.value')}
																								title='ADDRESS'
																								isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'performer-address' || false}
																								event={addressPopOver?.event}
																								onAddressCreated={(d) => onPersonAddressCreated(d)}
																								onClose={() => setAddressPopOver({ isOpen: false })}
																								onPopOverOpen={(e) => setAddressPopOver({
																									isOpen: true,
																									event: e?.currentTarget,
																									for: 'performer-address'
																								})}
																								isPersistent={true} />
																								}
																							</Box>
																						}
																					</>
																				)}
																			/>
																		</FormControl> 
																	</td>
																</tr>
																<tr>
																	<td>
								Talent Phone
																	</td>
																	<td width={450}>
																		<FormControl fullWidth className={styles.tableForms} >
																			<Controller
																				control={contractForm.control}
																				key={`select-phone-ctr${contractForm.watch(
																					'performerPhoneId'
																				)}-${contractForm.watch(
																					'performerId.value'
																				)}-${personOptions.length}`}
																				name='performerPhoneId'
																				defaultValue=''
																				render={({ field }) => (
																					<>
																						<NabuSelect
																							{...field}
																							key={`select-phone-${contractForm.watch(
																								'performerPhoneId'
																							)}-${contractForm.watch(
																								'performerId.value'
																							)}-${personOptions.length}`}
																							placeholder={!props?.isPrint ? 'Select Phone' : ''}
																							convertValueToUpperCase={false}
																							enableEmptySelection={true}
																							disabled={
																								props?.isPrint ||
																						!contractForm.watch(
																							'performerId.value'
																						)
																							}
																							options={getPerformerPhoneNumberOptions() || [] }
																							handleDefaultSelection={() => !contractForm.watch('performerPhoneId')}
																						/>
																					</>
																				)}
																			/>
																		</FormControl> 
																	</td>
																	<td width={120}>
								Email
																	</td>
																	<td>
																		<FormControl fullWidth className={styles.tableForms} >
																			<Controller
																				key={`select-email-${contractForm.watch(
																					'performerEmailId'
																				)}-${contractForm.watch(
																					'performerId.value'
																				)}`}
																				control={contractForm.control}
																				name='performerEmailId'
																				defaultValue=''
																				render={({ field }) => (
																					<>
																						<NabuSelect
																							{...field}
																							key={`select-phone-${contractForm.watch(
																								'performerEmailId'
																							)}-${contractForm.watch(
																								'performerId.value'
																							)}`}
																							placeholder={
																								!props?.isPrint ? 'Select Email' : ''
																							}
																							convertValueToUpperCase={false}
																							enableEmptySelection={true}
																							disabled={
																								props?.isPrint ||
																					!contractForm.watch(
																						'performerId.value'
																					)
																							}
																							options={getPerformerEmailsOptions()}
																							handleDefaultSelection={() => !contractForm.watch('performerEmailId')}
																						/>
																					</>																				
																				)}
																			/>
																		</FormControl>
																	</td>
																</tr>
															</table>
															<br></br>
															<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
																<tr>
																	<td width={200}>
								Talent Agency
																	</td>
																	<td width={450}>
																		<FormControl className={styles.tableForms} >
																			<Controller
																				key={`agency-cr-${contractForm.watch('performerId.value')}`}
																				control={contractForm.control}
																				name='talentAgencyId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							data-cy='ad_agency'
																							key={`agency-${contractForm.watch('performerId.value')}`}
																							{...field}
																							placeholder={
																								!props?.isPrint ? 'Ad-Agency' : ''
																							}
																							filterProperty='CompanyNames.Name'
																							APICaller={(filter) => {
																								filter.filters = filter.filters?.map((f) => f.property == 'CompanyNames.Name' ? {...f, value: f?.value?.trim() || '' } : f) || [];
																								return getAgentInfo(filter, contractForm.watch('performerId.value') || '');
																							}}
																							optionGenerator={
																								companyOptionGenerator
																							}
																							isClearable={true}
																							isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.setValue('talentAgencyAddressId', null, { shouldDirty: true } );
																								contractForm.setValue('talentAgencyEmailId', '', { shouldDirty: true } );
																								contractForm.setValue('talentAgencyPhoneId', '', { shouldDirty: true } );
																								contractForm.setValue('talentAgencyStaffId', null, { shouldDirty: true } );
																								contractForm.trigger('talentAgencyStaffId' );
																								contractForm.trigger('talentAgencyAddressId' );
																								contractForm.trigger('talentAgencyEmailId');
																								contractForm.trigger('talentAgencyPhoneId');
																							}}
																							onOptionLoaded={(options) => {
																								if(options?.length && !contractForm.watch('talentAgencyId.value')){
																									contractForm.setValue('talentAgencyId', options[0], { shouldDirty: true});
																									contractForm.trigger('talentAgencyId', { shouldFocus: true});
																								}
																							}}
																							cacheOptions
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{!props?.isPrint && contractForm.watch('performerId.value') && (<AddTalentAgentInline
																							onCompanyCreated={(createdAgent) => contractForm.setValue('talentAgencyId', createdAgent, { shouldDirty: true})}
																							onClose={() => setCompanyPopoverInline({ isOpen: false})}
																							onPopOverOpen={(e) => setCompanyPopoverInline({ isOpen: true, event: e?.currentTarget, for: 'talentAgency' })}
																							event={companyPopoverInline?.event}
																							isOpen={companyPopoverInline.isOpen && companyPopoverInline.for === 'talentAgency'}
																							personId={contractForm.watch('performerId.value') || ''}
																						/>)}
																					</>
																				)}
																			/>
																		</FormControl> 
																	</td>
																	<td width={120}>
								Agent
																	</td>
																	<td>
																		<FormControl className={styles.tableForms} >
																			<Controller
																				key={`agency-${contractForm.watch('performerId.value')}-${contractForm.watch('talentAgencyId.value')}`}
																				control={contractForm.control}
																				name='talentAgencyStaffId'
																				// disabled={
																				// 	!contractForm.watch(
																				// 		'talentAgencyStaffId.value'
																				// 	) || props?.isPrint
																				// }
																				render={({ field }) => (
																					<Autocomplete
																						key={`agency-${contractForm.watch('performerId.value')}-${contractForm.watch('talentAgencyId.value')}`}
																						data-cy='StaffSelect'
																						id="combo-box-demo"
																						options={staffOptions}
																						{...field}
																						onChange={(e, item :any) => {
																							contractForm.setValue('talentAgencyStaffId', item);
																						}}
																						disabled={
																							props?.isPrint ||
																				!contractForm.watch(
																					'talentAgencyId.value'
																				)
																						}
																						// onInputChange={(e: any,item) => {
																						// 	const lookAheadDefaultFilter: IFilterOption = {
																						// 		isCaseSensitive: false,
																						// 		operator: OPERATORS.CONTAINS,
																						// 		property: 'PersonName.FirstName',
																						// 		value: item,
																						// 	};
																						// 	setStaffPage({
																						// 		pageNumber: 0,
																						// 		pageSize: DefaultPageSize,
																						// 		sortDescending: true,
																						// 		sortField: 'PersonName.FirstName',
																						// 		filters: [lookAheadDefaultFilter]
																						// 	});
																						// 	item ? getStaffOptions(staffPage) : getStaffOptions({
																						// 		pageNumber: 0,
																						// 		pageSize: DefaultPageSize,
																						// 		sortDescending: true,
																						// 		sortField: 'PersonName.FirstName',
																						// 		filters: []
																						// 	});
																						// }}
																						sx={{ width: 180 }}
																						renderInput={(params) => <TextField placeholder='Agent Name' data-cy='StaffSelectTextField' {...params} />}
																					/>
																				)}
																			/> 
																		</FormControl>
																	</td>
																</tr>
																<tr>
																	<td>
								Talent Agency Address
																	</td>
																	<td colSpan={3}>
																		<FormControl fullWidth className={styles.tableForms} >
																			<Controller
																				control={contractForm.control}
																				key={`agency-address-cr-${contractForm.watch(
																					'talentAgencyId.value'
																				)}`}
																				name='talentAgencyAddressId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							{...field}
																							placeholder={
																								!props?.isPrint
																									? 'Select Address'
																									: ''
																							}
																							key={`agency-address-${contractForm.watch(
																								'talentAgencyId.value'
																							)}`}
																							filterProperty='Line1'
																							sortField='IsPrimary'
																							sortDescending={true}
																							onOptionLoaded={(options) => {
																								if(options?.length && !contractForm.watch('talentAgencyAddressId.value')){
																									contractForm.setValue('talentAgencyAddressId', options[0], { shouldDirty: true});
																									contractForm.trigger('talentAgencyAddressId', { shouldFocus: true});																						
																								}
																							}}
																							APICaller={(filterModel) =>
																								getAllAddressByCompany(
																									filterModel,
																									contractForm.watch(
																										'talentAgencyId.value'
																									) || ''
																								)
																							}
																							optionGenerator={
																								adAgencyAddressOptionGenerator
																							}
																							isDisabled={
																								!contractForm.watch(
																									'talentAgencyId.value'
																								) || props?.isPrint
																							}
																							dropDownsize={50}
																							isClearable={true}
																							isSearchText={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.setValue('talentAgencyEmailId','',{ shouldDirty: true });
																								contractForm.setValue('talentAgencyPhoneId','',{ shouldDirty: true });
																								contractForm.trigger('talentAgencyEmailId');
																								contractForm.trigger('talentAgencyPhoneId');
																							}}
																							cacheOptions={false}
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																					</>
																				)}
																			/>
																		</FormControl>
																	</td>
																</tr>
																<tr>
																	<td>
								Agent Phone
																	</td>
																	<td>
																		<FormControl className={styles.tableForms} style={{minWidth:200}}>
																			<Controller
																				control={contractForm.control}
																				key={`select-phone-${contractForm.watch(
																					'talentAgencyPhoneId'
																				)}-${contractForm.watch(
																					'talentAgencyAddressId.value'
																				)}`}
																				name='talentAgencyPhoneId'
																				defaultValue=''
																				render={({ field }) => (
																					<>
																						<NabuSelect
																							{...field}
																							key={`select-phone-${contractForm.watch(
																								'talentAgencyPhoneId'
																							)}-${contractForm.watch(
																								'talentAgencyAddressId.value'
																							)}`}
																							placeholder={
																								!props?.isPrint ? 'Select Phone' : ''
																							}
																							convertValueToUpperCase={false}
																							enableEmptySelection={true}
																							disabled={
																								props?.isPrint ||
																					!contractForm.watch(
																						'talentAgencyAddressId.value'
																					)
																							}
																							options={getPhoneNumberOptions(
																								contractForm.watch(
																									'talentAgencyAddressId.value'
																								)
																							)}
																							handleDefaultSelection={() => !contractForm.watch('talentAgencyPhoneId')}
																						/>
																					</>
																				)}
																			/>
																		</FormControl>
																	</td>
																	<td>
								Email
																	</td>
																	<td>
																		<FormControl className={styles.tableForms} style={{minWidth:200}} >
																			<Controller
																				control={contractForm.control}
																				key={`select-em-${contractForm.watch(
																					'talentAgencyAddressId.value'
																				)}-${contractForm.watch('talentAgencyEmailId')}`}
																				name='talentAgencyEmailId'
																				defaultValue=''
																				render={({ field }) => (
																					<>
																						<NabuSelect
																							{...field}
																							key={`select-em-${contractForm.watch(
																								'talentAgencyAddressId.value'
																							)}-${contractForm.watch('talentAgencyEmailId')}`}
																							placeholder={
																								!props?.isPrint ? 'Select Email' : ''
																							}
																							convertValueToUpperCase={false}
																							enableEmptySelection={true}
																							disabled={
																								props?.isPrint ||
																					!contractForm.watch(
																						'talentAgencyAddressId.value'
																					)
																							}
																							options={getCompanyEmailOptions(
																								contractForm.watch(
																									'talentAgencyAddressId.value'
																								)
																							)}
																							handleDefaultSelection={() => !contractForm.watch('talentAgencyEmailId')}
																						/>
																					</>	
																				)}
																			/>
																		</FormControl>
																		{/* <FormControl fullWidth className={styles.tableForms}><NabuTextbox placeholder="Enter Email" type="number" /></FormControl>  */}
																	</td>
																</tr>

															</table>
															<br></br>

															<div className='keep-together'>
																<p><b><u>Payment Authorization: </u></b></p>
																<p><b>Talent authorizes payments to which Talent may be entitled hereunder, as follows:</b></p>
																<div style={{display:'flex', gap:'5px', alignItems:'center'}}>
																	<div
																		style={{
																			display: 'inline-flex',
																			alignItems: 'center',
																		}}
																	>
																		<Controller
																			control={contractForm.control}
																			name='isPaymentAtPerformerAddress'
																			// defaultValue={true}
																			disabled={props?.isPrint}
																			render={({ field }) => (
																				<div
																					onClick={props?.isPrint ? undefined :  (() => {
																						field.onChange(!field.value);
																						(!props?.isPrint) && (contractForm.setValue('isPaymentAtAgentAddress', !contractForm.watch('isPaymentAtPerformerAddress'))) ;
																					})}
																					style={{ userSelect: 'none' }}
																				>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							{...field}
																							value={field.value + ''}
																							checked={field.value}
																							onChange={(e) => {
																								field.onChange(e);
																								contractForm.setValue('isPaymentAtAgentAddress', !contractForm.watch('isPaymentAtPerformerAddress'));
																							}}
																							type='checkbox'
																							disabled={props?.isPrint}
																							style={{ marginTop: '3px' }}
																						/>
																						<span>
																							<b>To Performer at address listed above</b>
																						</span>
																					</Box>
																				</div>
																			)}
																		/>
																	</div>
																	<div
																		style={{
																			display: 'inline-flex',
																			alignItems: 'center',
																		}}
																	>
																		<Controller
																			control={contractForm.control}
																			name='isPaymentAtAgentAddress'
																			disabled={props?.isPrint}
																			render={({ field }) => (
																				<div
																					onClick={props?.isPrint ? undefined : () => {
																						field.onChange(!field.value);
																						(!props?.isPrint) && contractForm.setValue('isPaymentAtPerformerAddress', !contractForm.watch('isPaymentAtAgentAddress'));
																					}}
																					style={{ userSelect: 'none' }}
																				>
																					<Box display={'flex'} alignItems={'flex-start'}>
																						<input
																							{...field}
																							value={field.value + ''}
																							checked={field.value}
																							onChange={(e) => {
																								field.onChange(e);
																								contractForm.setValue('isPaymentAtPerformerAddress', !contractForm.watch('isPaymentAtAgentAddress'));
																							}}
																							type='checkbox'
																							disabled={props?.isPrint}
																							style={{marginTop: '3px' }}
																						/>
																						<span>
																							<b>To Agency at address listed above</b>
																						</span>
																					</Box>
																				</div>
																			)}
																		/>
																	</div>
														
																</div>
															</div>
															<br></br>
															<div className='keep-together'>
																<p><b><u>Minor</u></b></p>
																<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
																	<tr>
																		<td colSpan={4}>
																			<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																				<span>I, as parent/legal guardian of</span>
																				<FormControl className={styles.tableForms} >
																					<Controller
																						control={contractForm.control}
																						name='printName'
																						disabled={
																							!contractForm.watch(
																								'performerId.value'
																							) || props?.isPrint
																						}
																						render={({ field }) => (
																							<>
																								<NabuTextbox
																									data-cy='performer_textbox'
																									placeholder='Performer'
																									{...field}
																									value={isMinor() && field.value || ''}
																								/>
																							</>
																						)}
																					/> 
																				</FormControl>
																				<span>, agree to the above and I sign this document to signify my agreement.</span>
																			</div>
																		</td>
																	</tr>
																	<tr>
																		<td width={250}>
								Parent/Guardian Signature
																		</td>
																		<td width={450}>
																			<FormControl fullWidth className={styles.tableForms}><NabuTextbox placeholder="Parent/Guardian Signature" /></FormControl> 
																		</td>
																		<td width={120}>
								Date
																		</td>
																		<td>
																			<FormControl  className={styles.tableForms} >
																				{props?.isPrint ? (
																					<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('signatureDate') || '')}</p>
																				) :(<Controller
																					control={contractForm.control}
																					name='signatureDate'
																					render={({ field }) => (
																						<NabuDatepicker
																							{...field}
																							disabled={props?.isPrint}
																							placeholder={!props?.isPrint ? 'Date' : ''}
																						/>
																					)}
																				/>)}
																			</FormControl>
																		</td>
																	</tr>
																	<tr>
																		<td width={250}>
								Parent/Guardian Print Name
																		</td>
																		<td colSpan={3}>
																			<FormControl fullWidth className={styles.tableForms}><NabuTextbox placeholder="Parent/Guardian Print Name" /></FormControl> 
																		</td>
																	</tr>
																</table>
															</div>
															<br></br>
															<div className='keep-together'>
																<p><b><u>Witnesses:</u></b></p>
																<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
																	<tr>
																		<td width={250}>
								Producer’s Signature
																		</td>
																		<td width={450}>
																			<FormControl fullWidth className={styles.tableForms}><NabuTextbox placeholder="Producer’s Signature" /></FormControl> 
																		</td>
																		<td width={120}>
								Date
																		</td>
																		<td>
																			<FormControl  className={styles.tableForms} >
																				{props?.isPrint ? (
																					<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('producerSignatureDate') || '')}</p>
																				) :(<Controller
																					control={contractForm.control}
																					name='producerSignatureDate'
																					render={({ field }) => (
																						<NabuDatepicker
																							{...field}
																							disabled={props?.isPrint}
																							placeholder={!props?.isPrint ? 'Date' : ''}
																						/>
																					)}
																				/>)}
																			</FormControl>
																		</td>
																	</tr>
																	<tr>
																		<td width={250}>
								Business Affairs Mgr
																		</td>
																		<td>
																			<FormControl fullWidth className={styles.tableForms}><NabuTextbox placeholder="Business Affairs Mgr" /></FormControl> 
																		</td>
																		<td width={120}>
								Date
																		</td>
																		<td>
																			<FormControl  className={styles.tableForms} >
																				{props?.isPrint ? (
																					<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('businessAffairDate') || '')}</p>
																				) :(<Controller
																					control={contractForm.control}
																					name='businessAffairDate'
																					render={({ field }) => (
																						<NabuDatepicker
																							{...field}
																							disabled={props?.isPrint}
																							placeholder={!props?.isPrint ? 'Date' : ''}
																						/>
																					)}
																				/>)}
																			</FormControl>
																		</td>
																	</tr>
																</table>
															</div>
														</div>
													</div>
												</div>
											</>
											{/* Main Contract Form End */}

											{/* W4 Short form */}
											{packetControlForm.watch('isW4shortForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW4ShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
											{/* W8 Ben Short form */}
											{packetControlForm.watch('isW8BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW8BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* W9 Ben Short form */}
											{packetControlForm.watch('isW9BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW9BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* Minor Trust form */}
											{packetControlForm.watch('isMinorTrust') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractMinorTrustForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* NY-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isNYTheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractNYWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* CA-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isCATheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractCAWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>

					{!props?.isPrint && (
						<div className={styles.contractTemplateBtn}>
							<Grid container>
								<Grid xs={12} md={6}>
									<NabuButton variant='filled' onClick={onClickSnippetBtn}>
										{!contractFormState?.isPacketOpen ? (
											<>
												<NoteAdd /> Create
											</>
										) : (
											'Close'
										)}
									&nbsp; Packet
									</NabuButton>
								</Grid>
								<Grid xs={12} md={6} dir='rtl'>
									<NabuButton
										variant='outlined'
										data-cy='cancel_btn'
										shouldHandleConfirmAction={contractForm.formState.isDirty}
										onClick={() => handleFormClose()}
									>
									Cancel
									</NabuButton>{' '}
								&nbsp;
									<NabuButton
										variant='filled'
										type='submit'
										data-cy='save_btn'
										disabled={
											!(
												contractForm.formState.isDirty ||
											packetControlForm.formState.isDirty
											) ||
										isAddingContract ||
										isEditingContract
										}
									>
										{isAddingContract || isEditingContract ? 'Saving' : 'Save'}
										<Save />
									</NabuButton>{' '}
								&nbsp;
									<NabuButton
										variant='filled'
										onClick={contractForm.handleSubmit(handleSaveAndDuplicate)}
										disabled={
											!(
												contractForm.formState.isDirty ||
											packetControlForm.formState.isDirty
											) ||
										isAddingContract ||
										isEditingContract || (!(props?.mainForm?.watch('contractDisplayName'))&& !props?.isEdit)
										}
									>
									Save & Duplicate <CopyAll />
									</NabuButton>
								&nbsp;
								</Grid>
							</Grid>
						</div>
					)}
				</form>
			</div>
			<TalentDetailsForm
				handleTalentDetailsForm={(value) => handleTalentDetailsForm(value)}
				noOfTalentsSelected={noOfTalentsSelected}
				setNoOfTalentsSelected={(value) => setNoOfTalentsSelected(value)}
				isUnion={false}
			/>
			{isExpiryDateDialogOpen.isOpen && <ExpiryDateForm
				contractExpiryDate={isExpiryDateDialogOpen.contractExpiryDate || props?.initialValue?.expirationDate}
				isOpen={isExpiryDateDialogOpen.isOpen}
				handleExpiryDateForm={(values) => onSubmitOfExpiryDateForm(values)}
				handleFormClose={closeExpiryDateForm}
			/>}
		</>
	);
});

export default NonUnionTalentAgreementExtra;
