
import NabuButton from '@/common/components/atoms/button/button';
import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import { NabuLink } from '@/common/components/atoms/link/link';
import TextWrap from '@/common/components/atoms/textWrap/textWrap';
import { NabuCardDefault } from '@/common/components/molecules/cardWhite/cardWhite';
import DataTable from '@/common/components/molecules/dataTable/dataTable';
import NabuDialog, { IDialogAction } from '@/common/components/molecules/dialog/dialog';
import { DefaultPageSize, mediaTypeOptions, mediaType as mediaTypeValues } from '@/common/constants/constants';
import { OPERATORS } from '@/common/enums';
import { StatusCodes } from '@/common/enums/StatusCodes';
import useNotification from '@/common/hooks/useNotification';
import { ITableActionMenuModel, columnsType } from '@/common/types/dataTable';
import { findNodeAndAncestors, getLocaleDate, getNotificationText } from '@/common/utils/utils';
import { deleteContractRightsAcquired, getContractRenewalTerms } from '@/services/contract';
import { deleteRightsAcquired, getRenewalTerms, updateRenewalDate } from '@/services/element';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add, DeleteForever, Edit, RemoveRedEye, Save } from '@mui/icons-material';
import { Box, Divider, FormControl, FormControlLabel, FormHelperText, FormLabel, Grid, Radio, RadioGroup, Stack, Tooltip } from '@mui/material';
import { Tree } from 'antd';
import { Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import AddRightsAcquiredForm from '../RightsAcquired/AddRightsAcquiredForm';
import NestedTable from '../RightsAcquired/nestedTable';
import { renewForm } from '../RightsAcquired/schema';
import styles from './renewalTerms.module.scss';

interface IProps {
	refresh?: () => void;
	elementData?: any;
	showNotification?: () => void;
	onFormClose?: () => void;
	onFormOpen?: (formAction: string) => void;
	isSectionVisible?: boolean;
	for: 'ELEMENT' | 'CONTRACT';
}

interface IRenewalTermsFormDrawer {
	isOpen: boolean;
	selectedRenewalTerms?: any;
	rIdx: number;
}

const RenewalTerms = (props: IProps) => {

	const [isOpen, setRightsAcqForm] = useState(false);

	const [renewalTermsToBeDeleted, setRenewalTermsToBeDeleted] = useState<{id: string}>({
		id: ''
	});

	const [renewalTermsForm, setRenewalTermsForm] = useState<IRenewalTermsFormDrawer>({isOpen: false, rIdx: -1});

	const columns: columnsType[] = [
		{
			id: 'mediaType',
			label: 'Media Type',
			maxWidth: '220px',
		},
		{
			id: 'viewMediaTypes',
			label: ''
		},
		{ 
			id: 'term',
			label: 'Term'
		},
		{
			id: 'startDate',
			label: 'Term Start',
		},
		{
			id: 'endDate',
			label: 'Term End',
		},
		{
			id: 'inPerpetuity',
			label: 'In Perpetuity'
		},
		{
			id: 'paid',
			label: 'Paid/ Used',
		},
		{
			id: 'renewalDate',
			label: 'Renewal Date',
			maxWidth: '220px'
		},
		{ 
			id: 'renew',
			label: 'Renew'
		},
		{
			id: 'actions',
			label: '',
		},
	];

	const handleRenewalTermsEdit = (data: any) => {
		if(props.isSectionVisible){
			props.onFormOpen && props.onFormOpen('Edit');
			const record = data;
			setRightsAcqForm(false);
			const editData = {
				...record,
				Id: record.id,
			};
			setRenewalTermsForm({
				isOpen: true,
				selectedRenewalTerms: editData,
				rIdx: record?.rowIndex ?? -1
			});
		}else {
			props.showNotification && props.showNotification();
		}
	};

	const [ isDeleteDialogVisible, setDeleteDialogVisibility] = useState<boolean>(false);
	const closeDeleteDialog = () => {
		setRenewalTermsToBeDeleted({id: ''});
		setDeleteDialogVisibility(false);
	};

	const openDeleteDialog = (e: any) => {
		setRenewalTermsToBeDeleted({id: e.id});
		setDeleteDialogVisibility(true);
	};

	const handleDeleteRenewalTerms = () => {
		const payload = {
			id: renewalTermsToBeDeleted.id,
		};
		const deleteRightsAcquiredAPI = payload => props.for === 'ELEMENT' ? deleteRightsAcquired(payload) : deleteContractRightsAcquired(payload);

		deleteRightsAcquiredAPI(payload).then((res: any) => {
			if (!(res?.status === StatusCodes.NO_CONTENT)) {
				notification.notify({
					message: res?.data.errorName || getNotificationText('Renewal Terms', 'DELETE', true),
					type: 'error'
				});
				closeDeleteDialog();
			} else {
				notification.notify({
					message: getNotificationText('Renewal Terms', 'DELETE'),
					type: 'success'
				});
				closeDeleteDialog();
				fetchRightsAcquired();
			}
		});	
	};

	const primaryActions: ITableActionMenuModel[] = [ 
		{
			title: () => 'Edit',
			icon: () => Edit,
			iconColor: 'primary',
			onClick: (e)=> handleRenewalTermsEdit(e),
			'data-cy': 'rights_acquired_edit_btn',
		},
		{
			title: () => 'Delete',
			icon: () => DeleteForever,
			iconColor: 'error',
			onClick: (e) => openDeleteDialog(e),
			'data-cy': 'rights_acquired_delete_btn',
		},
	];

	const deleteDialogActions : IDialogAction[] = [
		{	
			name: 'Yes, Remove', 
			onClick: handleDeleteRenewalTerms, 
			color: 'error', 
			variant: 'contained',
			'data-cy': 'yes_remove_btn'
		},
		{	
			name: 'Do not remove', 
			onClick: closeDeleteDialog,
			variant: 'outlined',
			'data-cy': 'no_remove_btn'
		},
	];

	const [renewalDialog, setRenewDialog] = useState<{isOpen: boolean, rId: string, data: any}>({
		isOpen: false,
		rId: '',
		data: ''
	});

	const openRenewalDialogOpen = (row: any) => {
		setRenewDialog({isOpen: true, rId: row.id, data: row});
		renewalDateForm.setValue('startDate', row?.startDate);
		renewalDateForm.setValue('endDate', row?.endDate);
	};

	const closeRenewalDialog = () => {
		renewalDateForm.reset();
		setRenewDialog({isOpen: false, rId: '', data: ''});
	};

	const Renew = (row: any) => {
		return (
			<div className={styles.copytoRenewal}>
				<NabuButton onClick={() => openRenewalDialogOpen(row)} variant='filled'>Renew</NabuButton>
			</div>
		);
	};

	const RenewalDate = (row: any) => {
		return (
			<>{row?.renewalDate && getLocaleDate(row?.renewalDate)}</>
		);
	};

	const [checkedNodes, setCheckedNodes] = useState<[]>([]);

	const [selectedMediaTypeDialog, setSelectedMediaTypeDialog] = useState<{
		isOpen: boolean,
		mediaType: any,
		selectedNodes: []
	}>({
		isOpen: false,
		mediaType: [],
		selectedNodes: []
	});

	const handleMediaTypeViewClick = (data: any) => {
		const checkedMediaTypes = data?.checkedMediaTypeIds;
		const selectedNodes: any = mediaTypeValues?.filter(e => checkedMediaTypes?.includes(e.id));
		setSelectedMediaTypeDialog({isOpen: true, mediaType: checkedMediaTypes.length > 0 ? findNodeAndAncestors(mediaTypeOptions, checkedMediaTypes.filter((item, index) => checkedMediaTypes.indexOf(item) === index)) : convertMediaObject(data?.mediaInformation), selectedNodes : selectedNodes.length > 0 ? selectedNodes : data?.mediaInformation});
		setCheckedNodes(data?.checkedMediaTypeIds);
	};

	const convertMediaObject = (mediaArray) => {
		let keyCounter = 1;
		return mediaArray.map(media => {
			const mainKey = keyCounter++; 
			return {
				key: String(mainKey),                 
				title: media.mediaType,        
				type: 'MEDIATYPE',             
				children: media.mediaSubType ? [{
					key: String(keyCounter++),              
					title: media.mediaSubType,   
					type: 'MEDIASUBTYPE',        
					children: media.mediaSubTypeDetails.map(detail => ({
						key: String(keyCounter++),             
						title: detail,        
						type: 'MEDIASUBTYPEDETAIL'
					}))
				}] : []
			};
		});
	};

	const TABLEVIEW = 'TABLEVIEW';
	const [, setMediaTypeView] = useState<string>(TABLEVIEW);

	const handleMediaTypeViewClose = () => {
		setSelectedMediaTypeDialog({ isOpen: false, mediaType: [], selectedNodes : []});
		setMediaTypeView(TABLEVIEW);
	};

	const CustomCellRightsCovered = (row: any) => {
		const rightsCoveredData = row?.mediaInformation?.length > 0 ? row?.mediaInformation[0]?.mediaType : row?.mediaType[0] || '';
		return (
			<Grid item container alignItems={'center'}>
				<TextWrap title={rightsCoveredData} maxLength={15}></TextWrap>
				{/* { row?.mediaInformation?.length > 1 ? 
					(
						<NabuLink underline='always' variant='body1' onClick={() => handleSubTypeViewMoreButton(row?.mediaInformation || null, 'Media Type Details')}>
								+{row?.mediaInformation?.length - 1}
						</NabuLink>
					)
					:row?.mediaType?.length > 1 && (
						<NabuLink underline='always' variant='body1' onClick={() => handleSubTypeViewMoreButton(row?.mediaType || null, 'Media Type Details')}>
									+{row?.mediaType?.length - 1}
						</NabuLink>
					)} */}
			</Grid>
		);
	};

	const CustomCellMediaMainTypeDetails = (row: any) => {
		const mainTypeDetails = row?.rightsCoverageMainType[0] || '';
		return(
			<Grid item container alignItems={'center'}>
				<Grid xs={4} sx={{pl:1.5}}>
					<TextWrap title={mainTypeDetails} maxLength={25}></TextWrap>
					{row?.rightsCoverageMainType?.length > 1 && (
						<NabuLink underline='always' variant='body1' onClick={() => handleSubTypeViewMoreButton(row?.rightsCoverageMainType || null, 'Main Type Details')}>
									+{row?.rightsCoverageMainType?.length - 1}
						</NabuLink>
					)}
				</Grid>
			</Grid>
		);
	};

	const notification = useNotification();
	const { elementId, contractId } = useParams();

	const DEFAULT_SORT_FIELD = 'Id';

	const [renewalTermsData, setRenewalTermsData] = useState<{data: any, totalRecords: number}>({
		data: [],
		totalRecords: 0,
	}); 

	const [renewalTermsPage,] = useState<any>({
		pageNumber: 0,
		pageSize: DefaultPageSize,
		sortDescending: false,
		sortField: DEFAULT_SORT_FIELD,
		filters: [
			{
				isCaseSensitive: false,
				operator: OPERATORS.EQUAL,
				property: 'IsRenewal',
				value: true,
			}
		]
	});

	const getRenewalTermsAPI = () => props.for === 'ELEMENT' ? getRenewalTerms(renewalTermsPage, elementId) : getContractRenewalTerms(renewalTermsPage, contractId || '');

	const { refetch: fetchRightsAcquired } = useQuery({
		queryKey: ['getRenewalTerms', renewalTermsPage, props?.for],
		queryFn: getRenewalTermsAPI,
		refetchOnWindowFocus: false,
		onSuccess: (res) => {			
			if (!(res?.status === StatusCodes.OK)) {
				notification.notify({
					type: 'error',
					message: getNotificationText('Renewal', 'FETCHING', true),	
				});
				return;
			}
			const data = res.data?.records?.length ? res.data.records : [];
			setRenewalTermsData({ data, totalRecords: res.data.totalRecords || 0 });
			props.refresh && props.refresh();
		},
		onError: () => {
			notification.notify({
				type: 'error',
				message: getNotificationText('RightsAcquired', 'FETCHING', true),
			});
		},
	});

	const [subType, setSubType] = useState<{data: any, open: boolean, title: string}>({
		open: false,
		title: '',
		data: []
	});

	const handleSubTypeViewMoreButton = (subType: any, title: string) => {
		setSubType({open: true, data: subType, title: title});
	};

	const CustomCellMediaSubTypeDetail = (row: any) => {
		const subTypeDetail = row?.rightsCoverageSubType[0] || '';
		return(
			<Grid item container alignItems={'center'}>
				<Grid xs={4} sx={{pl:1.5}}>
					<TextWrap title={subTypeDetail} maxLength={25}></TextWrap>
					{row?.rightsCoverageSubType?.length > 1 && (
						<NabuLink underline='always' variant='body1' onClick={() => handleSubTypeViewMoreButton(row?.rightsCoverageSubType || null, 'SubType Details')}>
									+{row?.rightsCoverageSubType?.length - 1}
						</NabuLink>
					)}
				</Grid>
			</Grid>
		);
	};

	const ViewMediaType = (row: any) => {
		return (
			<Tooltip title='View All Selected Media Types' arrow>
				<Stack sx={{mr:1, ml: 1, width:110}} dir="rtl">
					<NabuButton onClick={() => handleMediaTypeViewClick(row)} variant='text'>{'View All'}<RemoveRedEye/></NabuButton>
				</Stack>
			</Tooltip> 
		);
	};

	const CustomCellStartDate = (row: any) => {
		return (
			<Grid item container alignItems={'center'}>
				<Grid xs={4} sx={{pl:1.5}}>
					{row.startDate ? getLocaleDate(row.startDate) : null}
				</Grid>
			</Grid>
		);
	};

	const CustomCellEndDate = (row: any) => {
		return (
			<Grid item container alignItems={'center'}>
				<Grid xs={4} sx={{pl:1.5}}>
					{row.endDate ? getLocaleDate(new Date(row.endDate)) : null}
				</Grid>
			</Grid>
		);
	};

	const CustomCellInPerpetuity = (row: any) => {
		const InPerpetuity = row?.term === 'In Perpetuity' ? 'YES' : 'NO';
		return(
			<Box sx={{pl:1.5}}>{InPerpetuity}</Box>
		);
	};

	const CustomTermCell = (row: any) => {
		const term = row?.term;
		return(
			<>
				{term.toUpperCase()}
			</>
		);
	};

	const renewalDateForm = useForm<{startDate: Date | string, endDate: Date | string, paid: boolean, }>({
		resolver: yupResolver(renewForm),
	});
	const queryClient = useQueryClient();	


	const renewalDateSubmit = (data) => {
		if(data.startDate){
			const payload = {
				...data,
				rightsAcquiredId: renewalDialog?.rId,
				elementId: elementId,
			};
			updateRenewalDate(payload).then((res) => {
				if(res.status === StatusCodes.OK){
					notification.notify({
						message: 'Renewal Date Updated',
						type: 'success'
					});
					queryClient.refetchQueries(['rightsAcquired']);
					queryClient.refetchQueries(['getRenewalTerms']);
					closeRenewalDialog();
					fetchRightsAcquired();
				}
				else{
					notification.notify({
						message: 'Error occurred while updating renewal date',
						type: 'error'
					});
				}
			});
		}
	};

	return (
		<Box>
			<NabuCardDefault>
				<Grid item container>
					<Grid md={6}>
						<h4 data-cy='rights_acquired_header'>Renewal Terms</h4>
					</Grid>
					{!isOpen && <Grid md={6} dir='rtl'>
						<NabuButton
							variant='filled'
							onClick={() => {
								if(props.isSectionVisible){
									props.onFormOpen && props.onFormOpen('Add');
									setRightsAcqForm(true);
									setRenewalTermsForm({ isOpen: false, rIdx: -1 });
								}else {
									props.showNotification && props.showNotification();
								}
							}}
						>
							Add new renewal <Add/>
						</NabuButton>
					</Grid> 
					}
				</Grid>
				<Grid item container>
					<Grid md={12}>
						{isOpen && <><div className={styles.inlineEdit}>
							<AddRightsAcquiredForm
								isEdit={!!renewalTermsForm?.selectedRenewalTerms || false}
								onClose={() => {
									props.onFormClose && props.onFormClose();
									setRightsAcqForm(false); 
									setRenewalTermsForm({ isOpen: false, rIdx: -1 }); 
								}}
								fetchRightsAcquired={fetchRightsAcquired}
								initialValue={renewalTermsForm?.selectedRenewalTerms}
								refresh={props.refresh}
								elementData={props?.elementData && props?.elementData}
								isFromRenewalTerms={true}
								for={props.for}
								isUnion={props?.elementData?.union === 'NON UNION' ? false : true && props?.elementData?.union === '' ? false : true}
							/>
						</div></>}
					</Grid>
				</Grid>
				<Box sx={{ mt: 3 }}>
					<div className={'styles.dGrid'}>
						<div className={styles.stickyHeader}>
							<DataTable
								data-cy='all-rights-contracted-list'
								rows={renewalTermsData.data}
								actionsMenu={primaryActions}
								totalRowsCount={renewalTermsData.totalRecords}
								rowsPerPage={renewalTermsPage.pageSize}
								currentPage={renewalTermsPage.pageNumber}
								columns={columns}
								tableName='renewalTerms' 
								inlineRows={{
									slot: 'RenewalTerms',
									rowIndex: renewalTermsForm.rIdx,
									isOpen: renewalTermsForm.isOpen
								}}
							>
								<CustomCellRightsCovered slot='mediaType' />
								<CustomCellMediaMainTypeDetails slot='rightsCoverageMainType'/>
								<CustomCellMediaSubTypeDetail slot='mediaSubTypeDetail'/>
								<ViewMediaType slot='viewMediaTypes' />
								<CustomCellStartDate slot='startDate' />
								<CustomCellInPerpetuity slot='inPerpetuity' />
								<CustomCellEndDate slot='endDate' />
								<CustomTermCell slot = 'term'/>
								<NestedTable 
									slot='row-detail'
									isEdit={!!renewalTermsForm?.selectedRenewalTerms || false}
									fetchRightsAcquired={fetchRightsAcquired}
									initialValue={renewalTermsForm?.selectedRenewalTerms}
									for={props?.for}
								/>
								<AddRightsAcquiredForm
									slot='RenewalTerms'
									isEdit={!!renewalTermsForm?.selectedRenewalTerms || false}
									onClose={() => {props.onFormClose && props.onFormClose(); setRightsAcqForm(false); setRenewalTermsForm({ isOpen: false, rIdx: -1 });}}
									fetchRightsAcquired={fetchRightsAcquired}
									initialValue={renewalTermsForm?.selectedRenewalTerms}
									isFromRenewalTerms={true}
									for={props.for}
									isUnion={props?.elementData?.union === 'NON UNION' ? false : true && props?.elementData?.union === '' ? false : true}
								/>
								<Renew slot='renew'/>
								<RenewalDate slot='renewalDate' />
							</DataTable>
						</div>
					</div>
				</Box>
			</NabuCardDefault>
			<Fragment>
				<NabuDialog
					open={subType.open}
					title={subType.title}
					dialogActions={[]}
					handleClose={() =>
						setSubType({ open: false, data: [] , title: ''})
					}
				>
					<Divider />
					<Stack mt={2}>
						<ul>
							{subType.data?.map((subtype, i) => (
								<li key={i}>{subtype?.mediaType || subtype?.toUpperCase()}</li>
							))}
						</ul>
					</Stack>
				</NabuDialog>
			</Fragment>
			<Fragment>
				<NabuDialog
					title='Remove Renewal Terms ?'
					open={isDeleteDialogVisible}
					maxWidth='xs'
					handleClose={closeDeleteDialog}
					fullWidth={true}
					dialogActions={deleteDialogActions}
					isPersistent={true}>
					<strong>Are you sure to remove ?</strong>
					<p>This action <b>can not be undone</b></p>
				</NabuDialog>
			</Fragment>
			<Fragment>
				<NabuDialog
					title='Please Confirm The Renewal Terms'
					open={renewalDialog.isOpen}
					maxWidth='md'
					handleClose={closeRenewalDialog}
					fullWidth={true}
					dialogActions={[]}
					isPersistent={true}
				>
					<Grid container columnSpacing={2}>
						<Grid item md={12} sx={{mb:1}} data-cy='add_packagingRequirements_wrapper'>
							<FormControl fullWidth>
								<FormLabel>
								Paid <sup>*</sup>
								</FormLabel>
								<Controller
									control={renewalDateForm.control}
									name='paid'
									defaultValue={false}
									render={({ field }) => (
										<>
											<RadioGroup
												row
												aria-labelledby='demo-row-radio-buttons-group-label'
												data-cy='paid_radio_group'
												{...field}
											>
												<FormControlLabel
													value={true}
													control={<Radio />}
													label='Yes'
													data-cy='yes_paid_checkbox'
												/>
												<FormControlLabel
													value={false}
													control={<Radio />}
													label='No'
													data-cy='no_paid_checkbox'
													defaultChecked={true}
												/>
											</RadioGroup>
										</>
									)}
								/>
								{ renewalDateForm.formState.errors.paid && (
									<FormHelperText data-cy='rights_coverage_required' error={true}>
										{renewalDateForm.formState.errors.paid?.message}
									</FormHelperText>)
								}
							</FormControl>
						</Grid>
						<Grid item md={6} sx={{mb:1}} data-cy='add_packagingRequirements_wrapper'>
							<FormControl fullWidth>
								<FormLabel>
								Start Date <sup>*</sup>
								</FormLabel>
								<Controller
									control={renewalDateForm.control}
									name='startDate'
									render={({ field }) => (
										<>
											<NabuDatepicker
												{...field}
												placeholder='Renewal Date'
												onChange={(e) => field.onChange(e)}
											/>
										</>
									)}
								/>
								{ renewalDateForm.formState.errors.startDate && (
									<FormHelperText data-cy='rights_coverage_required' error={true}>
										{renewalDateForm.formState.errors.startDate?.message}
									</FormHelperText>)
								}
							</FormControl>
						</Grid>
						<Grid item md={6} sx={{mb:1}} data-cy='add_packagingRequirements_wrapper'>
							<FormControl fullWidth>
								<FormLabel>
								End Date <sup>*</sup>
								</FormLabel>
								<Controller
									control={renewalDateForm.control}
									name='endDate'
									render={({ field }) => (
										<>
											<NabuDatepicker
												{...field}
												placeholder='Renewal Date'
												onChange={(e) => field.onChange(e)}
											/>
										</>
									)}
								/>
								{ renewalDateForm.formState.errors.endDate && (
									<FormHelperText data-cy='rights_coverage_required' error={true}>
										{renewalDateForm.formState.errors.endDate?.message}
									</FormHelperText>)
								}
							</FormControl>
						</Grid>
						<Grid item md={12} dir='rtl'>
							<div >
								<NabuButton shouldHandleConfirmAction={renewalDateForm.formState.isDirty} variant='outlined' onClick={closeRenewalDialog}>Cancel</NabuButton>
						&nbsp;
								<NabuButton variant='filled' onClick={renewalDateForm.handleSubmit(renewalDateSubmit)}>
									{'Save'}
									<Save />
								</NabuButton>
							</div>
						</Grid>
					</Grid>
				</NabuDialog>
			</Fragment>
			<Fragment>
				<NabuDialog
					open={selectedMediaTypeDialog.isOpen}
					maxWidth={'lg'}
					title={'Selected Media Type'}
					dialogActions={[]}
					handleClose={handleMediaTypeViewClose}
				>
					<Grid container>
						<Grid item sm={12}>
							<Tree
								defaultCheckedKeys={checkedNodes ? checkedNodes : []}
								treeData={selectedMediaTypeDialog.mediaType}
								rootStyle={{display: 'flex'}}
								defaultExpandAll={true}
								showLine={true}
							>
							</Tree>
						</Grid>
					</Grid>
				</NabuDialog>
			</Fragment>
		</Box>
	);
};

export default RenewalTerms;
