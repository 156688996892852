import NabuButton from '@/common/components/atoms/button/button';
import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import LookAheadSelect from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import AddCompanyAddressInline from '@/common/components/templates/pages/add/addCompanyAddressInline';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonInline from '@/common/components/templates/pages/add/addPersonInline';
import { CONTRACT_REF_ID, ContractStatus, ContractStatusOptions } from '@/common/constants/constants';
import { OPERATORS } from '@/common/enums';
import useNotification from '@/common/hooks/useNotification';
import { CompanyMainType, ICompanyAddress, ICompanyRecord } from '@/common/types/company';
import {
	IBaseContract,
	IContractMSAForm, IPacketControlForm
} from '@/common/types/contract';
import { IFilterOption } from '@/common/types/filterModel';
import {
	displayAddress,
	displayPhoneNumberWithoutLabel,
	getBaseUrl,
	getCompanyDisplayName,
	getLocaleDate,
	getNotificationText,
	getPersonFullName,
	mutateContractAdditionalFormsData,
	sortEmailOrPhoneNumberForDropDown,
} from '@/common/utils/utils';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import {
	getAllAddressByCompany,
	getAllProducts,
	getCompanyBasicDetails,
} from '@/services/company';
import {
	addContractMSA,
	addMultipleContract,
	updateContractMSA
} from '@/services/contract';
import { IPersonRecord } from '@/services/models/person';
import { GetByWithFullNameSearchForBasicDetail } from '@/services/person';
import store from '@/store';
import { updateContractFormState } from '@/store/contract/reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { CopyAll, NoteAdd, Save } from '@mui/icons-material';
import {
	Box, Checkbox,
	FormControl,
	FormHelperText,
	FormLabel,
	Grid,
	ListItemButton,
	ListItemText
} from '@mui/material';
import { HttpStatusCode } from 'axios';
import { addDays, addMonths, addWeeks, addYears, format, isBefore } from 'date-fns';
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react';
import { Controller, UseFormReturn, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { ContractFormGeneralSchema } from '../contarctFormSchema';
import ContractCAWageTheftPreventionForm from '../contractCAWageyTheftPreventionForm';
import ContractMinorTrustForm from '../contractMinorTrustForm';
import ContractNYWageTheftPreventionForm from '../contractNYWageTheftPreventionForm';
import ContractW4ShortForm from '../contractW4ShortForm';
import ContractW8BenShortForm from '../contractW8BenShortForm';
import ContractW9BenShortForm from '../contractW9BenShortForm';
import TalentDetailsForm from '../talentDetailsForm';
import { IContractFormState } from '../unionForms/standardSagAftraEmployment2022';
import { useSearchParams } from 'react-router-dom';
import useScrollToField from '@/common/hooks/useScrollToField';
import ExpiryDateForm from '../expiryDateForm';

interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	initialValue?: IContractMSAForm;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	defaultValue?: IBaseContract,
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
	isDuplicateContractName?: boolean;
}

// eslint-disable-next-line react/display-name
const MsaInfluenceAgreement = forwardRef((props: Props, ref) => {
	const { scroll } = useScrollToField();
	const [params, setParams] = useSearchParams();
	useImperativeHandle(ref, () => ({
		handleMsaInfluenceAgreementFormCancel: (data: IContractMSAForm, statusValue: string) => {
			if(data){
				const submitData = {
					...data,
					status: statusValue ? statusValue : ContractStatus.Draft,
					// contractNonUnionPerAgrmntOffCamPri: undefined,
					payrollVendorId: data?.payrollVendorId?.value,
					performerId: data?.performerId?.value,
					advertiserId: data?.advertiserId?.value,
					adAgencyId: data?.adAgencyId?.value,
					adAgencyAddressId: data?.adAgencyAddressId?.value,
					talentAgencyId: data?.talentAgencyId?.value,
					talentAgencyAddressId: data?.talentAgencyAddressId?.value,
					productId: data?.productId?.value,
					// creator Agreement fields,
					lenderCompanyId: data?.lenderCompanyId?.value,
					lenderCompanyAddressId: data?.lenderCompanyAddressId?.value,
					lenderCompanyStaffId: data?.lenderCompanyStaffId?.value,
					onBehalfOfCompanyId: data?.onBehalfOfCompanyId?.value,
					onBehalfOfCompanyAddressId: data?.onBehalfOfCompanyAddressId?.value,
					onBehalfOfCompanyStaffId: data?.onBehalfOfCompanyStaffId?.value,
				};
				editContractDetails(submitData);
			}
		},
	}));

	useEffect(() => {
		if(props?.defaultValue?.advertiserId)
			contractForm.setValue('advertiserId', props?.defaultValue?.advertiserId, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.advertiserId]);

	const notification = useNotification();

	const contractForm = useForm<IContractMSAForm>({
		defaultValues: !props?.initialValue
			? {
				...(props?.mainForm?.getValues() || {}),
				...(props?.defaultValue || {})
			}
			: props?.initialValue,
		resolver: yupResolver(ContractFormGeneralSchema()),
		shouldFocusError: false
	});

	const [addressPopOver, setAddressPopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const packetControlForm = useForm<IPacketControlForm>({
		defaultValues: {
			isW4shortForm: !!contractForm.watch('contractW4Form.firstAndMiddleName'),
			isW8BenShort: !!contractForm.watch('contractW8BenShortForm'),
			isW9BenShort: !!contractForm.watch('contractW9BenShortForm'),
			isMinorTrust: !!contractForm.watch('contractMinorTrustForm'),
			isNYTheftPreventionForm: !!contractForm.watch(
				'contractNYTheftPreventionForm'
			),
			isCATheftPreventionForm: !!contractForm.watch(
				'contractCATheftPreventionForm'
			),
		},
	});

	const [, setPersonOptions] = useState<IPersonRecord[]>(
		props?.people || []
	);
	const [addressOptions, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [contractFormState, setContractFormState] =
		useState<IContractFormState>({});

	const AD_AGENCY_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'AD AGENCY',
		},
	];

	const ADVERTISER_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'ADVERTISER',
		},
	];

	const AGENT_REPRESENTATIVE_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'AGENT/REPRESENTATIVE',
		},
	];

	const PRODUCT_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.NOT_EQUAL,
			property: 'ProductName',
			value: '',
		},
	];

	const VENDOR_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'VENDOR',
		},
	];

	// helper functions
	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	const handlePackageNotification = (key: keyof IPacketControlForm) => {
		notification.notify({
			message: `Form ${packetControlForm.watch(key) ? 'Added to' : 'Removed from'} Packet.`,
			type: 'info',
		});
	};

	const handleStateOptions = (
		responseData: any[],
		type: 'PERSON' | 'ADDRESS'
	) => {
		const cb = {
			PERSON: setPersonOptions,
			ADDRESS: setAddressOptions,
		}[type];

		if (responseData && Array.isArray(responseData)) {
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);
				return [...oldFilter, ...responseData];
			});
		}
	};

	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		const address = responseData?.reduce((acc, curr) => {
			if (curr?.addresses?.length) {
				acc.push(...(curr?.addresses || []));
			}
			return acc;
		}, [] as ICompanyAddress[]);

		handleStateOptions(address, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};

	
	const onPayrollVendorAddressCreated = (createdAddress: ICompanyAddress) => {
		contractForm.setValue('payrollVendorAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const companyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const productOptionGenerator = (responseData) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: d?.productName?.toUpperCase(),
		}));
	};

	const getPhoneNumberOptions = (addressId: string = '') => {
		const unsortedPhoneNumbers =
			addressOptions?.find((a) => a?.id === addressId)?.phoneNumbers || [];
		const phoneNumbers = sortEmailOrPhoneNumberForDropDown(unsortedPhoneNumbers);
		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const onClickSnippetBtn = () => {
		setContractFormState((prev) => ({
			isPacketOpen: !prev?.isPacketOpen,
		}));
	};
	
	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};

	const { mutate: addContractDetails, isLoading: isAddingContract } =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addContractMSA(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						const tabPath = contractForm.watch('isGuaranteeOpted') ? 'guarantee-payment' : 'contract-view';
						props?.onSave(`/contracts/${res?.data?.id}/${tabPath}`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const { mutate: editContractDetails, isLoading: isEditingContract } =
		useMutation({
			mutationKey: ['contract-edit'],
			mutationFn: (payload: any) => updateContractMSA(
				props?.contractId || '',
				payload
			),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'UPDATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						const tabPath = contractForm.watch('isGuaranteeOpted') ? 'guarantee-payment' : 'contract-view';
						props?.onSave(`/contracts/${res?.data?.id}/${tabPath}`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const handleContractSubmission = async (
		data: IContractMSAForm,
		handleMultipleContractAdd?: CallableFunction
	) => {
		
		if (!packetControlForm.watch('isW4shortForm')) {
			data.w4FormId = undefined;
			data.contractW4Form = undefined;
		}
		if (!packetControlForm.watch('isW8BenShort')) {
			data.w8BenShortFormId = undefined;
			data.contractW8BenShortForm = undefined;
		}
		if (!packetControlForm.watch('isW9BenShort')) {
			data.w9BenShortFormId = undefined;
			data.contractW9BenShortForm = undefined;
		}
		if (!packetControlForm.watch('isMinorTrust')) {
			data.minorTrustFormId = undefined;
			data.contractMinorTrustForm = undefined;
		}
		if (!packetControlForm.watch('isNYTheftPreventionForm')) {
			data.nYTheftPreventionFormId = undefined;
			data.contractNYTheftPreventionForm = undefined;
		}
		if (!packetControlForm.watch('isCATheftPreventionForm')) {
			data.cATheftPreventionFormId = undefined;
			data.contractCATheftPreventionForm = undefined;
		}

		try {
			const notify = (message: string) => {
				notification.notify({
					message,
					type: 'error',
				});
			};
			// NOTE: These linked form's handled separately because the payload size is huge.
			const updatedContractIds = await mutateContractAdditionalFormsData(
				data,
				data?.performerId?.value || '',
				notify
			);
			data.w4FormId = updatedContractIds?.w4FormId;
			data.w8BenShortFormId = updatedContractIds?.w8BenShortFormId;
			data.w9BenShortFormId = updatedContractIds?.w9BenShortFormId;
			data.minorTrustFormId = updatedContractIds?.minorTrustFormId;
			data.nYTheftPreventionFormId =
				updatedContractIds?.nYTheftPreventionFormId;
			data.cATheftPreventionFormId =
				updatedContractIds?.cATheftPreventionFormId;

		} catch (error) {
			notification.notify({
				message: getNotificationText('Form', 'UPDATE', true),
				type: 'error',
			});
		} finally {
			data.contractW4Form = undefined;
			data.contractW8BenShortForm = undefined;
			data.contractW9BenShortForm = undefined;
			data.contractMinorTrustForm = undefined;
			data.contractNYTheftPreventionForm = undefined;
			data.contractCATheftPreventionForm = undefined;

			const mainFormValues = props?.mainForm?.getValues();
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};

			const submitData = {
				...data,
				...addMainFormValues,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value,
				contractMSA: undefined,
				payrollVendorId: data?.payrollVendorId?.value,
				performerId: data?.performerId?.value,
				advertiserId: data?.advertiserId?.value,
				adAgencyId: data?.adAgencyId?.value,
				adAgencyAddressId: data?.adAgencyAddressId?.value,
				talentAgencyId: data?.talentAgencyId?.value,
				talentAgencyAddressId: data?.talentAgencyAddressId?.value,
				productId: data?.productId?.value,
				// creator Agreement fields,
				lenderCompanyId: data?.lenderCompanyId?.value,
				lenderCompanyAddressId: data?.lenderCompanyAddressId?.value,
				lenderCompanyStaffId: data?.lenderCompanyStaffId?.value,
				onBehalfOfCompanyId: data?.onBehalfOfCompanyId?.value,
				onBehalfOfCompanyAddressId: data?.onBehalfOfCompanyAddressId?.value,
				onBehalfOfCompanyStaffId: data?.onBehalfOfCompanyStaffId?.value,
			};
			if (!props?.isEdit){
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			}
			else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 
		}
	};

	const onSubmitError = (data) => {
		scroll(data);
		notification.notify({
			message: 'Please fill all required fields',
			type: 'error',
		});
	};

	const onSubmit = (data: IContractMSAForm) => {
		if(!props?.mainForm?.watch('contractDisplayName') && !props?.isEdit){
			notification.notify({
				message: 'Please fill Contract Display Name.',
				type: 'error'
			});
		}
		else if(props?.isDuplicateContractName){
			notification.notify({
				message: 'Contract with the same name already exists',
				type: 'error',
			});
			return;
		}
		else{
			openExpiryDateForm(data);
		}
	};

	useEffect(() => {
		if(props?.mainForm){
			contractForm.setValue('contractDisplayName', props?.mainForm?.watch('contractDisplayName') || '', { shouldDirty: true});
		}
	}, [props?.mainForm?.watch('contractDisplayName')]);

	const { mutate: addMultipleContractDetails} =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addMultipleContract(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					const baseURL = getBaseUrl(window.location.href);
					const contractIds : any = res?.data || [];
					const urls =  contractIds.map((contract) => {
						return `${baseURL}/#/contracts/${contract}/contract-view?isEdit=true`;
					}) || [];

					urls.forEach((url) => {
						const a = document.createElement('a');	
						a.href = url;
						a.target = '_blank';
						const evt = document.createEvent('MouseEvents');
						evt.initMouseEvent('click', true, true, window, 0, 0, 0, 0, 0, false, false, false, false, 0, null);
						a.dispatchEvent(evt);
					});
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
				} else
					notification.notify({
						message:
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const [contractFormData, setContractFormData] = useState<IContractMSAForm>();

	const [noOfTalentsSelected, setNoOfTalentsSelected] = useState(false);

	const handleSaveAndDuplicate = (data: IContractMSAForm) => {
		if(props?.isDuplicateContractName){
			notification.notify({
				message: 'Contract with the same name already exists',
				type: 'error',
			});
			return;
		}
		else{
			setNoOfTalentsSelected(true);
			setContractFormData(data);
		}
	};

	const handleTalentDetailsForm = (data: any) => {
		setNoOfTalentsSelected(false);
		const handleMultipleContractAdd = (payloadData) => {
			if(data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						status:ContractStatus?.Draft,
						role: talent?.role,
						talentAgencyId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${index+1}`,
						compensation: talent?.compensation,
						talentAgencyAddressId:undefined
					};
					return submitData;
				});
				const multipleContractData = {
					contractAddMSAModels: contractAddModels
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData && handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};

	//Expiry Date Dialog Code
	const [isExpiryDateDialogOpen, setExpiryDateDialog] = useState<{isOpen: boolean, contractData: IContractMSAForm | undefined, contractExpiryDate: Date | undefined}>({isOpen: false, contractData: undefined, contractExpiryDate: undefined});

	const calculateEndDate = (termValues) => {
		if(termValues.termDuration && termValues.termDurationUnit && termValues.termFromFirstUseDate){
			const days = termValues.termDuration;
			const startDate = new Date(termValues.termFromFirstUseDate);
			const date = startDate.setDate(startDate.getDate() - 1);
			const unit = termValues.termDurationUnit;
			if(date){
				let finalDate;
				if(unit == 'WEEK (S)'){
					finalDate = (addWeeks(new Date(date), days));
				}
				else if(unit == 'MONTH (S)'){
					finalDate = addMonths(new Date(date), days);
				}
				else if(unit == 'YEAR (S)'){
					finalDate = addYears(new Date(date), days);
				}
				else{
					finalDate = addDays(new Date(date), days);
				}
				return finalDate;
			}
		}
	};

	const openExpiryDateForm = (data) => {
		const termValues = {
			termDuration: data.termDuration,
			termDurationUnit: data.termDurationUnit,
			termFromFirstUseDate: data.termFromDate || data.termFromFirstUseDate
		};
		const endDate = calculateEndDate(termValues) || undefined;
		setExpiryDateDialog({isOpen: true, contractData: data, contractExpiryDate: endDate});
	};

	const closeExpiryDateForm = () => setExpiryDateDialog({isOpen: false, contractData: undefined, contractExpiryDate: undefined});
	const onSubmitOfExpiryDateForm = (data) => {
		if(isExpiryDateDialogOpen.contractData){
			const expiredStatus = data.expirationDate ? (isBefore(data.expirationDate, new Date()) ? ContractStatus.Expired : undefined) : undefined;
			const submittedData: IContractMSAForm = {
				...isExpiryDateDialogOpen.contractData,
				expirationDate: data.expirationDate,
				status: expiredStatus
			};
			submittedData && handleContractSubmission(submittedData);
		}
	};

	return (
		<>
			<div className={props?.isPrint ? styles.formWrapperPreview : ''}>
				<form onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}>
					<div className={styles.packetTemplate} id='form'>
						<Grid item container columnSpacing={3}>
							{contractFormState?.isPacketOpen && (
								<Grid item sm={12} md={4} lg={3}>
									<div className={styles.snippetSection}>
										<Grid item container columnSpacing={3}>
											<Grid item md={12}>
												<h3 className={styles.pocketTitle}> Government & Payroll forms</h3>
											</Grid>
										</Grid>
										<div className={styles.contractFormBlockSnippet}>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW4shortForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW4shortForm');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW4shortForm');
																		}}
																	/>{' '}
																	<span>W4- short version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW8BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW8BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW8BenShort');
																		}}
																	/>
																	<span>W8 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW9BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isW9BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isW9BenShort');
																		}}
																	/>
																	<span>W9 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isMinorTrust'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification('isMinorTrust');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification('isMinorTrust');
																		}}
																	/>
																	<span>Minor Trust Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isNYTheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isNYTheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isNYTheftPreventionForm'
																			);
																		}}
																	/>
																	<span>NY Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isCATheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageNotification(
																		'isCATheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageNotification(
																				'isCATheftPreventionForm'
																			);
																		}}
																	/>
																	<span>CA Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
										</div>
									</div>
								</Grid>
							)}
							<Grid
								item
								sm={12}
								md={4}
								lg={!contractFormState?.isPacketOpen ? 12 : 9}
							>
								<div
									className={
										!contractFormState?.isPacketOpen ? undefined : styles.scrollFIle
									}
								>
									<div className={styles.contractFormBlockPacket} id='contract-form-block-packet'>
										<div className={styles.contractFormBlockPacketMSA}>
											<div className={styles.contractFormBlock}>
												{props?.isEdit && (
													<div className={styles.formFiledsBlock}>
														<Grid container item columnSpacing={3}>
															<Grid item md={6} lg={3}>
																<FormControl fullWidth>
																	<FormLabel>
														Contract Name<sup>*</sup>
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		name='contractDisplayName'
																		render={({ field }) => (
																			<>
																				<NabuTextbox
																					{...field}
																					placeholder={!props?.isPrint ? 'Contract Name' : ''}
																				/>
																			</>
																		)}
																	/>
																	{contractForm.formState?.errors?.contractDisplayName
																		?.message && (
																		<FormHelperText error={true}>
																			{
																				contractForm.formState.errors
																					?.contractDisplayName?.message
																			}
																		</FormHelperText>
																	)}
																</FormControl>
															</Grid>
															<Grid item md={6} lg={3}>
																<FormControl fullWidth>
																	<FormLabel>
														Payroll Vendor <sup />
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		name='payrollVendorId'
																		render={({ field }) => (
																			<>
																				<LookAheadSelect
																					{...field}
																					placeholder={!props?.isPrint ? 'Payroll Vendor' : ''}
																					filterProperty='CompanyNames.Name'
																					APICaller={getCompanyBasicDetails}
																					optionGenerator={companyOptionGenerator}
																					defaultFilter={VENDOR_FILTER}
																					isClearable={true}
																					onChange={(e: any) => {
																						field.onChange(e);
																					}}
																					cacheOptions={false}
																					defaultOptions
																					className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				/>
																			</>
																		)}
																	/>
																	{contractForm.formState.errors?.payrollVendorId?.message && (
																		<FormHelperText
																			error={true}
																			data-cy='errorHelper_ad_agency'
																		>
																			{
																				contractForm.formState?.errors?.payrollVendorId.message
																			}
																		</FormHelperText>
																	)}
																</FormControl>
															</Grid>
															<Grid item md={6} lg={3}>
																<FormControl fullWidth>
																	<FormLabel>
														Payroll Vendor Address
																		<sup />
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		key={`payroll-address-${contractForm.watch(
																			'payrollVendorAddressId.value'
																		)}`}
																		name='payrollVendorAddressId'
																		render={({ field }) => (
																			<>
																				<LookAheadSelect
																					{...field}
																					placeholder={'Select Address'}
																					key={`payroll-vendor-address-${contractForm.watch(
																						'payrollVendorAddressId.value'
																					)}-${contractForm.watch('payrollVendorId.value')}`}
																					filterProperty='Line1'
																					APICaller={(filterModel) =>
																						getAllAddressByCompany(
																							filterModel,
																							contractForm.watch('payrollVendorId.value') || ''
																						)
																					}
																					optionGenerator={
																						companyAddressOptionGenerator
																					}
																					isDisabled={!contractForm.watch('payrollVendorId.value')}
																					dropDownsize={50}
																					isClearable={true}
																					isSearchText={true}
																					onChange={(e: any) => {
																						field.onChange(e);
																						contractForm.trigger('payrollVendorAddressId.value');
																					}}
																					cacheOptions={false}
																					defaultOptions
																					className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																				/>
																				{contractForm.watch('payrollVendorId.value') && <div style={{float:'right'}}>
																					<Box sx={{mt:0.9}}>
																						<AddCompanyAddressInline
																							companyId={contractForm.watch('payrollVendorId.value')}
																							title='ADDRESS'
																							isOpen={addressPopOver?.isOpen}
																							event={addressPopOver?.event}
																							onAddressCreated={(d) => onPayrollVendorAddressCreated(d)}
																							onClose={() => setAddressPopOver({ isOpen: false })}
																							onPopOverOpen={(e) => setAddressPopOver({
																								isOpen: true,
																								event: e?.currentTarget,
																							})}
																							isPersistent={true} />
																					</Box>
																				</div>}
																			</>
																	
																		)}
																	/>
																	{contractForm.formState.errors?.payrollVendorAddressId?.message && (
																		<FormHelperText
																			error={true}
																			data-cy='errorHelper_ad_agency'
																		>
																			{
																				contractForm.formState?.errors
																					?.payrollVendorAddressId.message
																			}
																		</FormHelperText>
																	)}
																</FormControl>
															</Grid>
															<Grid item md={6} lg={3}>
																<FormControl fullWidth>
																	<FormLabel>
														Status
																		<sup />
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		name='status'
																		render={({ field }) => (
																			<>
																				<NabuSelect
																					{...field}
																					placeholder={!props?.isPrint ? 'Select Status' : ''}
																					options={ContractStatusOptions || []}
																					convertValueToUpperCase={false}
																					onChange={(e) => field.onChange(e)}
																				/>
																			</>
																		)}
																	/>
																</FormControl>
															</Grid>
														</Grid>
													</div>
												)}
												{/* Main Contract Form Start */}
												<>
													<div className={styles.contractFormBlock}>
														<div id={CONTRACT_REF_ID.MAIN_FORM} className={styles.bgWhite} style={{padding:'20px'}}>
															<table className={styles.contractForms} width={'100%'}>
																<tbody>
																	<tr>
																		<h4>{contractForm.watch('contractId') ? `#${contractForm.watch('contractId')}` : ''}</h4>
																	</tr>
																	<tr>
																		<td align='center'>
																			<h2>INFLUENCER AGREEMENT</h2>{' '}
																		</td>
																	</tr>
																	<tr>
																		<td align='center'>
																			<div style={{display:'flex', alignItems:'center', gap:'5px', flexWrap:'wrap'}}>
																				<b>This agreement (“Agreement”)</b> is made on{' '}
																				{props?.isPrint ? (
																					<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('producerDate') || '')}</p>
																				) :(<Controller
																					control={contractForm.control}
																					name='producerDate'
																					render={({ field }) => (
																						<NabuDatepicker
																							{...field}
																							disabled={props?.isPrint}
																						/>
																					)}
																				/>)}
																				{contractForm.formState.errors?.producerDate
																					?.message && (
																					<FormHelperText
																						data-cy='errorHelper-producerDate'
																						error={true}
																					>
																						{
																							contractForm.formState?.errors?.producerDate
																								.message
																						}
																					</FormHelperText>
																				)}
																				<span>{contractForm.watch('producerDate') &&  format(new Date(contractForm.watch('producerDate')?.toString() || ''), 'EEEE do \'of\' MMMM yyyy')}</span>{' '}
																				{' '}between:
																			</div>
																		</td>
																	</tr>
																</tbody>
															</table>
															<br></br>
															<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner} 	align='center'
															>
																<tr>
																	<td width={'5%'}>1</td>
																	<td style={{paddingBottom:'15px', width:'95%'}}>
																		<div style={{display:'flex', alignItems:'center', gap:'5px', flexWrap:'wrap'}}>
																			<FormControl className={styles.tableFormsSelect}>
																				<Controller
																					control={contractForm.control}
																					name='adAgencyId'
																					render={({ field }) => (
																						<>
																							<LookAheadSelect
																								data-cy='ad_agency'
																								{...field}
																								placeholder={!props?.isPrint ? 'Ad-Agency' : ''}
																								filterProperty='CompanyNames.Name'
																								APICaller={getCompanyBasicDetails}
																								optionGenerator={companyOptionGenerator}
																								defaultFilter={AD_AGENCY_FILTER}
																								isClearable={true}
																								isDisabled={props?.isPrint}
																								onChange={(e: any) => {
																									field.onChange(e);
																									contractForm.setValue('adAgencyAddressId', null, { shouldDirty: true});
																									contractForm.trigger('adAgencyAddressId');
																								}}
																								cacheOptions
																								defaultOptions
																								className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																							/>
																							{!props?.isPrint && (
																								<div className={styles.inlineEditContract}>
																									<Box sx={{ mt:0.5}}>
																										<AddCompanyInline
																											title='Company'
																											companyType={{ mainType: 'AD AGENCY' as CompanyMainType, subType: [] }} />
																									</Box>
																								</div>
																							)}
																						</>
																					)}
																				/>
																				{contractForm.formState.errors?.adAgencyId?.message && (
																					<FormHelperText
																						error={true}
																						data-cy='errorHelper_ad_agency'
																					>
																						{
																							contractForm.formState?.errors?.adAgencyId.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>{' '}
															reg. no.{' '}
																			<FormControl className={styles.tableForms} fullWidth style={{maxWidth:'120px'}}>
																				<Controller
																					control={contractForm.control}
																					name='adAgencyRegNo'
																					defaultValue=''
																					render={({ field }) => (
																						<NabuTextbox
																							data-cy='job_name_textbox'
																							{...field}
																							disabled={props?.isPrint}
																							placeholder='Reg. No.'
																						/>
																					)}
																				/>
																				{contractForm.formState.errors?.adAgencyRegNo
																					?.message && (
																					<FormHelperText
																						data-cy='errorHelper-adAgencyRegNo'
																						error={true}
																					>
																						{
																							contractForm.formState?.errors
																								?.adAgencyRegNo.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>
															, whose registered office is at{' '}
																			<FormControl className={styles.tableFormsSelect}>
																				<Controller
																					control={contractForm.control}
																					key={`agency-address-cr-${contractForm.watch(
																						'adAgencyId.value'
																					)}`}
																					name='adAgencyAddressId'
																					render={({ field }) => (
																						<LookAheadSelect
																							{...field}
																							placeholder={!props?.isPrint ? 'Select Address' : ''}
																							key={`agency-address-${contractForm.watch(
																								'adAgencyId.value'
																							)}`}
																							filterProperty='Line1'
																							sortField='IsPrimary'
																							sortDescending={true}
																							onOptionLoaded={(options) => {
																								if(options?.length && !contractForm.watch('adAgencyAddressId.value')){
																									contractForm.setValue('adAgencyAddressId', options[0], { shouldDirty: true});
																									contractForm.trigger('adAgencyAddressId', { shouldFocus: true});																						
																								}
																							}}
																							APICaller={(filterModel) =>
																								getAllAddressByCompany(
																									filterModel,
																									contractForm.watch(
																										'adAgencyId.value'
																									) || ''
																								)
																							}
																							optionGenerator={
																								companyAddressOptionGenerator
																							}
																							isDisabled={
																								!contractForm.watch('adAgencyId.value') ||
																			props?.isPrint
																							}
																							dropDownsize={50}
																							isClearable={true}
																							isSearchText={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																							}}
																							cacheOptions={false}
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																					)}
																				/>
																				{contractForm.formState.errors?.adAgencyAddressId?.message && (
																					<FormHelperText
																						error={true}
																						data-cy='errorHelper_ad_agency'
																					>
																						{
																							contractForm.formState?.errors
																								?.adAgencyAddressId.message
																						}
																					</FormHelperText>
																				)}{' '}
																			</FormControl>
															(&quot;Agency&quot;, &quot;<b>we</b>&quot;, &quot;
																			<b>us</b>&quot; or &quot;<b>our</b>&quot;);
																		</div>
																	</td>
																</tr>
																<tr>
																	<td colSpan={20} align='center'>
																		<b>and</b>
																	</td>
																</tr>
																<tr>
																	<td>2</td>
																	<td>
																		<div style={{display:'flex', alignItems:'center', gap:'5px', flexWrap:'wrap'}}>
																			<FormControl className={styles.tableFormsSelect}>
																				<Controller
																					control={contractForm.control}
																					name='talentAgencyId'
																					render={({ field }) => (
																						<>
																							<LookAheadSelect
																								data-cy='ad_agency'
																								{...field}
																								placeholder={!props?.isPrint ? 'Talent-Agency' : ''}
																								filterProperty='CompanyNames.Name'
																								APICaller={getCompanyBasicDetails}
																								optionGenerator={companyOptionGenerator}
																								defaultFilter={
																									AGENT_REPRESENTATIVE_FILTER
																								}
																								isClearable={true}
																								isDisabled={props?.isPrint}
																								onChange={(e: any) => {
																									field.onChange(e);
																									contractForm.setValue(
																										'talentAgencyAddressId', null, { shouldDirty: true}
																									);
																									contractForm.trigger(
																										'talentAgencyAddressId'
																									);
																									contractForm.setValue(
																										'talentAgencyPhoneId', '', { shouldDirty: true}
																									);
																								}}
																								cacheOptions
																								defaultOptions
																								className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																							/>
																							{!props?.isPrint && (
																								<div className={styles.inlineEditContract}>
																									<Box sx={{mt:0.5}}>
																										<AddCompanyInline
																											title='Company'
																											companyType={{ mainType: 'AGENT/REPRESENTATIVE' as CompanyMainType, subType: [] }} />
																									</Box>
																								</div>
																							)}
																						</>
																					)}
																				/>
																				{contractForm.formState.errors?.talentAgencyId?.message && (
																					<FormHelperText
																						error={true}
																						data-cy='errorHelper_ad_agency'
																					>
																						{
																							contractForm.formState?.errors?.talentAgencyId.message
																						}
																					</FormHelperText>
																				)}
																			</FormControl>{' '}
															of
																			<FormControl className={styles.tableFormsSelect}>
																				<Controller
																					control={contractForm.control}
																					key={`talentAgency-address-cr-${contractForm.watch(
																						'talentAgencyId.value'
																					)}`}
																					name='talentAgencyAddressId'
																					render={({ field }) => (
																						<LookAheadSelect
																							{...field}
																							placeholder={!props?.isPrint ? 'Select Address' : ''}
																							key={`talentAgency-address-${contractForm.watch(
																								'talentAgencyId.value'
																							)}`}
																							filterProperty='Line1'
																							sortField='IsPrimary'
																							sortDescending={true}
																							onOptionLoaded={(options) => {
																								if(options?.length && !contractForm.watch('talentAgencyAddressId.value')){
																									contractForm.setValue('talentAgencyAddressId', options[0], { shouldDirty: true});
																									contractForm.trigger('talentAgencyAddressId', { shouldFocus: true});																						
																								}
																							}}
																							APICaller={(filterModel) =>
																								getAllAddressByCompany(
																									filterModel,
																									contractForm.watch(
																										'talentAgencyId.value'
																									) || ''
																								)
																							}
																							optionGenerator={
																								companyAddressOptionGenerator
																							}
																							isDisabled={
																								!contractForm.watch(
																									'talentAgencyId.value'
																								) || props?.isPrint
																							}
																							dropDownsize={50}
																							isClearable={true}
																							isSearchText={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.setValue('talentAgencyPhoneId', '', { shouldDirty: true});
																								contractForm.trigger('talentAgencyPhoneId');
																							}}
																							cacheOptions={false}
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																					)}
																				/>
																				{contractForm.formState.errors?.talentAgencyAddressId?.message && (
																					<FormHelperText
																						error={true}
																						data-cy='errorHelper_ad_agency'
																					>
																						{
																							contractForm.formState?.errors?.talentAgencyAddressId.message
																						}
																					</FormHelperText>
																				)}{' '}
																			</FormControl>
															with registered company number:{' '}
																			<Box sx={{marginTop:'14px'}}>
																				<FormControl className={styles.tableFormsSelect}>
																					<Controller
																						control={contractForm.control}
																						key={`select-phone-ctr${contractForm.watch(
																							'talentAgencyPhoneId'
																						)}-${contractForm.watch(
																							'talentAgencyAddressId.value'
																						)}`}
																						name='talentAgencyPhoneId'
																						defaultValue=''
																						render={({ field }) => (
																							<NabuSelect
																								{...field}
																								key={`select-phone-${contractForm.watch(
																									'talentAgencyPhoneId'
																								)}-${contractForm.watch(
																									'talentAgencyAddressId.value'
																								)}`}
																								placeholder={!props?.isPrint ? 'Select Phone' : ''}
																								convertValueToUpperCase={false}
																								enableEmptySelection={true}
																								disabled={
																									props?.isPrint ||
																			!contractForm.watch(
																				'talentAgencyAddressId.value'
																			)
																								}
																								options={getPhoneNumberOptions(
																									contractForm.watch(
																										'talentAgencyAddressId.value'
																									)
																								)}
																								handleDefaultSelection={() => !contractForm.watch('talentAgencyPhoneId')}
																							/>
																						)}
																					/>
																				</FormControl>
																			</Box>
																			{contractForm.formState?.errors
																				?.talentAgencyPhoneId?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.talentAgencyPhoneId?.message
																					}
																				</FormHelperText>
																			)}{' '}
															(&quot;
																			<b>Services Entity</b>&quot;, &quot;<b>you</b>
															&quot; or &quot;<b>your</b>&quot;) f/s/o 
																			<div>
																				<FormControl style={{minWidth:'200px'}}>
																					<Controller
																						control={contractForm.control}
																						name='performerId'
																						render={({ field }) => (
																							<>
																								<LookAheadSelect
																									data-cy='name_select'
																									{...field}
																									key={`performer-name-${contractForm.watch('performerId.value')}`}
																									isClearable={true}
																									placeholder={!props?.isPrint ? 'Select Performer' : ''}
																									filterProperty='PersonName.FirstName'
																									APICaller={(
																										filterModel,
																										searchText
																									) =>
																										GetByWithFullNameSearchForBasicDetail(
																											filterModel,
																											searchText
																										)
																									}
																									isSearchText={true}
																									isDisabled={props?.isPrint}
																									optionGenerator={
																										performerOptionGenerator
																									}
																									defaultFilter={getPerformerFilter()}
																									onChange={(e: any) => {
																										field.onChange(e);
																										contractForm.setValue('performerPrintName', contractForm.watch('performerId.label'),{ shouldDirty: true });
																									}}
																									cacheOptions={false}
																									defaultOptions
																									className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																								/>
	
																								{!props?.isPrint && (
																									<Box>
																										<div style={{marginTop:'-7px'}}>
																											<AddPersonInline
																												title='Person'
																												onPersonCreated={() => ({})}
																												initialValues={{personType: ['TALENT'], isMinor: false}}
																											/>
																										</div>
																									</Box>
																								)}
																							</>
																						)}
																					/>
																				</FormControl>
																			</div>
																			{contractForm.formState?.errors?.performerId?.message && (
																				<FormHelperText
																					data-cy='errorHelper_first_session_date'
																					error={true}
																				>
																					{
																						contractForm.formState?.errors?.performerId?.message
																					}
																				</FormHelperText>
																			)}
																			<div style={{marginTop:'-15px'}}>
															(&quot;Influencer&quot;)
																			</div>
																		</div>
																	</td>
																</tr>
															</table>
															<br></br>
															<p>
																<b>Introduction:</b>
															</p>
															<p>
													This Agreement consists of this Introduction, the
													Brief below and the attached General Conditions.
															</p>
															<br></br>
															<p>
													It takes effect from the Effective Date (as defined
													below).
															</p>
															<br></br>
															<p>
													By signing the Brief, you confirm that you have read
													and understood the Brief and the General Conditions
													and you agree to provide your services in accordance
													with this Agreement
															</p>

															<br></br>
															<p>
																<b>Brief:</b>
															</p>

															<table style={{width:'100%',}} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
																<tr>
																	<td width={'3%'}>1</td>
																	<td width={'10%'}>
																		<b>Client</b>
																	</td>
																	<td width={'87%'}>
																		<Box>
																			<Controller
																				key={`advertiser-${contractForm.watch('advertiserId.value')}-${contractForm.watch('productId.value')}`}
																				control={contractForm.control}
																				name='advertiserId'
																				render={({ field }) => (
																					<>
																						<LookAheadSelect
																							{...field}
																							placeholder={!props?.isPrint ? 'Client' : ''}
																							filterProperty='CompanyNames.Name'
																							APICaller={getCompanyBasicDetails}
																							optionGenerator={companyOptionGenerator}
																							defaultFilter={ADVERTISER_FILTER}
																							isDisabled={props?.isPrint}
																							isClearable={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																								contractForm.resetField('productId');
																							}}
																							cacheOptions={false}
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																						{!props?.isPrint && (
																							<Box sx={{mt:1.3}}>
																								<AddCompanyInline
																									title='Company' />
																							</Box>
																						)}
																					</>
																				)}
																			/>
																		</Box>
																		{contractForm.formState.errors?.advertiserId?.message && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper_ad_agency'
																			>
																				{
																					contractForm.formState?.errors?.advertiserId.message
																				}
																			</FormHelperText>
																		)}</td>
																</tr>
																<tr>
																	<td>2</td>
																	<td>
																		<b>Campaign</b>
																	</td>
																	<td>
																		<Box>
																			<FormControl fullWidth>
																				<Controller
																					control={contractForm.control}
																					name='campaignName'
																					defaultValue=''
																					render={({ field }) => (
																						<NabuTextbox
																							data-cy='job_name_textbox'
																							{...field}
																							disabled={props?.isPrint}
																							placeholder={!props?.isPrint ? 'Campaign Name' : ''}
																		
																						/>
																					)}
																				/>
																			</FormControl>
																		</Box>
																		{contractForm.formState.errors?.campaignName
																			?.message && (
																			<FormHelperText
																				data-cy='errorHelper-job-title-name'
																				error={true}
																			>
																				{
																					contractForm.formState?.errors?.campaignName
																						.message
																				}
																			</FormHelperText>
																		)}
																	</td>
																</tr>
																<tr>
																	<td>3</td>
																	<td>
																		<b>Product</b>
																	</td>
																	<td>
																		<Box>
																			<FormControl fullWidth>
																				<Controller
																					control={contractForm.control}
																					name='productId'
																					render={({ field }) => (
																						<LookAheadSelect
																							{...field}
																							placeholder={!props?.isPrint ? 'Select Product' : ''}
																							key={`product-${contractForm.watch(
																								'advertiserId.value'
																							)}`}
																							filterProperty='ProductName'
																							APICaller={(filterModel) =>
																								getAllProducts(
																									filterModel,
																									contractForm.watch('advertiserId.value')
																								)
																							}
																							optionGenerator={productOptionGenerator}
																							isDisabled={
																								!contractForm.watch('advertiserId.value') ||
																		props?.isPrint
																							}
																							defaultFilter={PRODUCT_FILTER}
																							dropDownsize={20}
																							isClearable={true}
																							onChange={(e: any) => {
																								field.onChange(e);
																							}}
																							cacheOptions={false}
																							defaultOptions
																							className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
																						/>
																					)}
																				/>
																			</FormControl>
																		</Box>
																		{contractForm.formState?.errors?.productId?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors?.productId?.message
																				}
																			</FormHelperText>
																		)}</td>
																</tr>
																<tr>
																	<td>4</td>
																	<td>
																		<b>Effective Date</b>
																	</td>
																	<td>
																		<Box>
																			<FormControl fullWidth>
																				<Controller
																					control={contractForm.control}
																					name='effectiveDate'
																					defaultValue={new Date()}
																					render={({ field }) => (
																						<NabuDatepicker
																							{...field}
																							disabled={props?.isPrint}
																						/>
																					)}
																				/>
																			</FormControl>
																		</Box>
																		{contractForm.formState?.errors
																			?.effectiveDate?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.effectiveDate.message
																				}
																			</FormHelperText>
																		)}
																		<p key={`${contractForm.watch('effectiveDate')}`}>
																			{contractForm.watch('effectiveDate') && format(new Date(contractForm.watch('effectiveDate') || ''), 'EEEE do \'of\' MMMM yyyy')}
																		</p>
																	</td>
																</tr>
																<tr>
																	<td width={'3%'}>5</td>
																	<td width={'10%'}>
																		<b>Services</b>
																	</td>
																	<td width={'87%'}>
															The following services to promote the Client and
															the Campaign:
																		<p>
																			<b>
																	1. x1 Custom song/sound: 20-30 seconds in
																	length
																			</b>{' '}
																(the “Recording”)
																		</p>
																		<ul>
																			<li>
																	Song to include hints to Sour Path Kids brand
																	ethos
																			</li>
																			<li>
																	Song to include subtle hit to Halloween time
																	of year
																			</li>
																			<li>
																	If fitting, creative to hint at Rebecca’s past
																	“Friday” ghost
																			</li>
																			<li>
																	No expletives in song as Tiktok will remove it
																			</li>
																			<li>
																	Song must be available to be used as a sound
																	on TikTok by the Brand as embodied in the
																	Video (defined below), subject to the terms
																	herein.
																			</li>
																		</ul>
																		<p>
																Client and Agency hereby acknowledge acceptance
																and delivery of the Recording..
																		</p>
																		<br></br>
																		<p>
																			<b>
																	2. x1 30 seconds custom branded video for
																	TikTok{' '}
																			</b>
																(the “Video”)
																		</p>
																		<ul>
																			<li>
																	Video to be more elevated than a DIY user
																	generated content, but should feel organic to
																	platform
																			</li>
																			<li>Caption to be mutually approved</li>
																			<li>
																	Clothing should not feature any other logos or
																	brand names
																			</li>
																			<li>
																	Product should be featured via organic
																	placement; talent does not have to interact
																	with product if it doesn’t make sense for
																	creative
																			</li>
																			<li>
																	Video will be presented as a “Tiktok
																	Challenge” encouraging others to participate
																			</li>
																			<li>
																	Influencer to suggest caption copy + Client to
																	suggest all necessary tags and handles
																			</li>
																			<li>
																	Client and Agency hereby acknowledge
																	acceptance and delivery of the Video
																			</li>
																		</ul>
																		<p>
																			<b>
																	2. x1 30 seconds custom branded video for
																	TikTok
																			</b>{' '}
																(the “Video”)
																		</p>
																		<ul>
																			<li>
																	Video to be more elevated than a DIY user
																	generated content, but should feel organic to
																	platform{' '}
																			</li>
																			<li>Caption to be mutually approved</li>
																			<li>
																	Clothing should not feature any other logos or
																	brand names
																			</li>
																			<li>
																	Product should be featured via organic
																	placement; talent does not have to interact
																	with product if it doesn’t make sense for
																	creative
																			</li>
																			<li>
																	Video will be presented as a “Tiktok
																	Challenge” encouraging others to participate
																			</li>
																			<li>
																	Influencer to suggest caption copy + Client to
																	suggest all necessary tags and handles
																			</li>
																			<li>
																	Client and Agency hereby acknowledge
																	acceptance and delivery of the Video
																			</li>
																		</ul>
																		<br />
																		<p>
																3. TikTok video code (after publication) for
																boosting purposes (refer to “Paid Media”)
																		</p>
																		<p>
																			<b>Media & PR related activities</b>
																		</p>
																		<p>
																Content to be included in traditional media
																outreach. All media outreach and content subject
																to mutual approval.
																		</p>
																		<ol>
																			<li>
																				<b>Brand Quote</b>
																				<p>
																		The Influencer will provide one (1) quote
																		(“Brand Quote”) about the program, Brand and
																		Product for use in all publicity materials.
																		The agency can also draft the Brand Quote
																		for Influencer and Client to approve.
																				</p>
																			</li>
																			<br />
																			<li>
																				<b>Press Materials</b>
																				<p>
																		The Influencer name to be featured in press
																		release and related promotional materials
																		(“Promo Materials”) to generate campaign
																		awareness with earned media, provided
																		Influencer has approved of such Promo
																		Materials.
																				</p>
																			</li>
																			<li>
																				<b>
																		Social media + Branded Assets for Media Use
																				</b>
																				<p>
																		Brand to boost one (1) talent content
																		(custom branded video) with a paid media
																		budget of $ 15,000 solely on TikTok.
																				</p>
																			</li>
																		</ol>
																		<p>
																			<b>Timetable & Campaign Roll-out:</b>
																		</p>
																		<ul>
																			<li>
																	Sept 25th – final Recording delivered *Client
																	and Agency hereby acknowledge acceptance and
																	delivery of the Recording.
																			</li>
																			<li>
																	Sept 27-28th – 1st round of video delivered
																	for review *subject to mutually approved
																	change of date.
																			</li>
																			<li>
																	Sept 29th – final video and recording
																	delivered + caption for review *subject to
																	mutually approved change of date.
																			</li>
																			<li>
																	Oct 20th – Video goes live on Rebecca’s TikTok
																	*subject to mutually approved change of date.
																			</li>
																		</ul>
																		<br></br>
																		<p>
																All dates, timings and locations for shoots,
																appearances, posts and other commitments under
																this Brief shall, unless specified, be mutually
																agreed.
																		</p>
																		<br></br>
																		<p>
																			<b>Social posting requirements:</b>
																		</p>
																		<ul>
																			<li>
																	Use the Campaign-specific Hashtags:
																	#GhostsFromYourPast
																			</li>
																			<li>
																	Use the <b>“Paid Partnership”</b> TikTok
																	option to disclose the collaboration embedded
																	in the video
																			</li>
																			<li>
																	Use #AD, #paid, or #sponsored hashtag within
																	the first few lines of your caption
																			</li>
																		</ul>
																		<br></br>
																		<p>
																All Materials (as defined in the attached
																General Conditions), including, but not limited
																to, captions for social posts, must be
																transparent about the nature of the posts as
																marketing communications, in accordance with all
																applicable laws which shall include full,
																upfront disclosure in a clear and prominent
																manner appropriate for the applicable social
																media channel, the nature of the Materials
																(e.g., static post, video, etc.), and suitable
																for all potential devices, that Influencer
																receives compensation from Client for the
																Services. Both parties shall approve the
																language of any such disclosures, including any
																hashtags, prior to posting.
																		</p>
																		<br></br>
																		<p>
																For clarity, you acknowledge and agree that
																Client may comment on, reply to, “like” and
																otherwise interact with any posts provided by
																you under this Agreement.
																		</p>
																	</td>
																</tr>
																<tr>
																	<td>6</td>
																	<td>
																		<b>Term</b>
																	</td>
																	<td>
																		<p>
																From the Effective Date until {' '}
																			<span key={`${contractForm.watch('effectiveDate')}`}>
																				{contractForm.watch('effectiveDate') && format(addYears(new Date(contractForm.watch('effectiveDate') || ''), 1), 'EEEE do \'of\' MMMM yyyy')}
																			</span> (1 year) (the “Term”) Content created
																pursuant to this Agreement shall remain on your
																social channels during the Term (subject to any
																right to remove which you may have as set out in
																this Agreement), unless we ask you to take such
																content down, in which case you shall do so as
																soon as possible (and in any event within 48
																hours). For the sake of clarity, Influencer may
																remove posts, including without limitation the
																Video or Recording, following the Term.
																		</p>
																	</td>
																</tr>
																<tr>
																	<td width={'3%'}>7</td>
																	<td width={'10%'}>
																		<b>Territory</b>
																	</td>
																	<td width={'87%'}>Worldwide</td>
																</tr>
																<tr>
																	<td width={'3%'}>8</td>
																	<td width={'10%'}>
																		<b>Fee</b>
																	</td>
																	<td>
																		<p>
																$ 160,000 USD (excluding VAT, if applicable -
																including all taxes, fees and surcharges. The
																fee (“Fee”) is payable within 60 days following
																successful completion of the Services being no
																later than 31 Dec 2023, subject to our receipt
																of a valid invoice (which shall be a VAT
																invoice, if applicable). The Fee shall be
																payable without deduction, withholding or offset
																of any amounts. Late payments of 7 days or less
																will not be considered a material breach.
																		</p>
																		<br></br>
																		<p>
																The Influencer is responsible for production
																costs including the makeup artist, the
																transportation of the Influencer to the venue,
																the outfit and the camera person.
																		</p>
																		<br></br>
																		<p>
																The Agency is responsible for production costs
																incurred by the Agency, including the venue,
																product, mutually agreed props, lightning and
																any travel costs for its members.
																		</p>
																	</td>
																</tr>
																<tr>
																	<td>9</td>
																	<td>
																		<b>Editorial Approval</b>
																	</td>
																	<td>
																		<p>
																Concepts for Materials (as defined in the
																attached General Conditions) must be approved by
																both parties (email deemed sufficient). Unless
																otherwise specifically agreed with us in writing
																in advance, all Materials shall be submitted to
																us for approval before publication (email deemed
																sufficient). You will permit us one (1) round of
																feedback and revision of the provided Recording
																& Video. Our approval does not relieve you from
																your obligations, representations and warranties
																in this Agreement.
																		</p>
																	</td>
																</tr>
																<tr>
																	<td>10</td>
																	<td>
																		<b>Authorisation</b>
																	</td>
																	<td>
															You authorise Kenny Consor to act on your behalf
															in all matters connected with this Agreement and
															to receive and collect all sums payable to you
															under this Agreement. Receipt by your agent of the
															Fee will be good and valid discharge of our
															payment obligations under this Agreement.
																	</td>
																</tr>
																<tr>
																	<td>11</td>
																	<td>
																		<b>Insurance</b>
																	</td>
																	<td>
																		<p>
																You shall maintain for the duration of this
																Agreement the following insurance with the
																indicated coverage limits: (i) commercial
																general liability insurance (including, without
																limitation, coverage for bodily injury and
																property damage), in an amount not less than
																$1,000,000 per occurrence and $1,000,000 in the
																aggregate. We shall maintain for the duration of
																this Agreement the following insurance with the
																indicated coverage limits: (i) workers
																compensation insurance, meeting all state and
																local requirements, and employer’s liability
																insurance; and (ii) commercial general liability
																insurance (including, without limitation,
																coverage for bodily injury and property damage),
																in an amount not less than $1,000,000 per
																occurrence and $3,000,000 in the aggregate. Each
																Party shall provide a certificate of insurance
																evidencing such coverage upon execution of this
																Agreement.
																		</p>
																	</td>
																</tr>
																<tr>
																	<td>12</td>
																	<td>
																		<b>Special Conditions</b>
																	</td>
																	<td>
															You also warrant and represent that: (a) you are
															aged 18 or older.
																	</td>
																</tr>
															</table>
															<br />
															<p>
																<b>Signed</b> by the parties or their duly authorised
														representatives on the date of this Agreement.{' '}
															</p>
															<table
																width={'100%'}
																border={1}
																cellPadding={0}
																cellSpacing={0}
																className={styles.contractFormsInner}
																align='center'
															>
																<tr>
																	<td>
																		<p>
																			<b>Signed for the Agency: {contractForm.watch('adAgencyId.label') || ''}</b>
																		</p>
																	</td>
																	<td>
																		<b>Signed by or for you: {contractForm.watch('performerId.label') || ''}</b>
																	</td>
																</tr>
																<tr>
																	<td>
																		<p>Signature:</p>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<NabuTextbox disabled />
																		</FormControl>
																		<p>Print name</p>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='adAgencyPrintName'
																				render={({ field }) => (
																					<>
																						<NabuTextbox
																							{...field}
																							disabled={props?.isPrint}
																						/>
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors?.adAgencyPrintName
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper_?.addressId'
																					error={true}
																				>
																					{
																						contractForm.formState.errors
																							?.adAgencyPrintName?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																		<p>Position</p>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='adAgencyPosition'
																				render={({ field }) => (
																					<>
																						<NabuTextbox
																							{...field}
																							disabled={props?.isPrint}
																						/>
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors?.adAgencyPosition
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper_?.addressId'
																					error={true}
																				>
																					{
																						contractForm.formState.errors
																							?.adAgencyPosition?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																	<td>
																		<p>Signature:</p>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<NabuTextbox disabled />
																		</FormControl>
																		<p>Print name</p>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerPrintName'
																				render={({ field }) => (
																					<>
																						<NabuTextbox
																							{...field}
																							disabled={props?.isPrint}
																						/>
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors?.performerPrintName
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper_?.addressId'
																					error={true}
																				>
																					{
																						contractForm.formState.errors
																							?.performerPrintName?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																		<p>Position</p>
																		<FormControl
																			fullWidth
																			className={styles.tableForms}
																		>
																			<Controller
																				control={contractForm.control}
																				name='performerPosition'
																				render={({ field }) => (
																					<>
																						<NabuTextbox
																							{...field}
																							disabled={props?.isPrint}
																						/>
																					</>
																				)}
																			/>
																			{contractForm.formState?.errors?.performerPosition
																				?.message && (
																				<FormHelperText
																					data-cy='errorHelper_?.addressId'
																					error={true}
																				>
																					{
																						contractForm.formState.errors
																							?.performerPosition?.message
																					}
																				</FormHelperText>
																			)}
																		</FormControl>
																	</td>
																</tr>
															</table>
														</div>
													</div>
													<div id={CONTRACT_REF_ID.MAIN_FORM_PART_2}  style={{padding:'20px'}}>
														<div className={styles.contractFormBlock}>
															<div className={styles.bgWhite}>
																<p>
																	<b>Influencer&apos;s</b> personal services: Unless the
														context otherwise requires: (a) each of your
														obligations in this Agreement shall be interpreted as
														an obligation on you to perform the obligation (as far
														as capable of performance by you) and to procure that
														the Influencer shall personally perform the obligation
														(as far as capable of performance by the Influencer,
														and references below to &quot;you&quot; or
														&quot;your&quot; are deemed to include an additional
														reference to the Influencer to such extent); an&quot;d
														(b) all rights, consents and waivers granted by you
														under this Agreement shall be interpreted as granted
														by you both (i) in your own capacity as the Services
														Entity and (ii) with due authority as agent for the
														Influencer.
																</p>
																<br></br>
																<table
																	width={'100%'}
																	border={1}
																	cellPadding={0}
																	cellSpacing={0}
																	className={styles.contractFormsInner}
																	align='center'
																>
																	<tr>
																		<td colSpan={2}>
																			<b>INDUCEMENT</b>
																		</td>
																	</tr>
																	<tr>
																		<td colSpan={2}>
																			<p>
																	In return for the Agency&apos;s agreement at my
																	request to enter into this Agreement with the
																	Services Entity (which I, the undersigned,
																	acknowledge as a sufficient benefit to me), I
																	agree with the Agency as follows:
																			</p>
																			<ol>
																				<li>
																		I have read and approve this Agreement. I
																		warrant and undertake that the Services Entity
																		is entitled to enter into this Agreement and
																		to grant the rights granted to the Agency
																		under this Agreement and is, and shall at all
																		times be, entitled to make my services
																		available to the Agency in accordance with the
																		provisions of this Agreement.
																				</li>
																				<li>
																		I shall perform and be bound by this Agreement
																		(to the extent that its provisions are to be
																		performed and observed by me) in the same
																		manner as if I had entered into this
																		Agreement. I also hereby covenant directly
																		with the Agency in terms of all grants of
																		rights, consents and waivers and all
																		warranties, representations and undertakings
																		contained in this Agreement.
																				</li>
																				<li>
																		I shall look solely to the Services Entity for
																		all remuneration, compensation and
																		reimbursement that may be due to me in
																		connection with: (a) any and all services and
																		materials provided by me in connection with
																		this Agreement; (b) the performance and
																		observance of my obligations under this
																		Inducement; and (c) my grants of rights,
																		consents and waivers under this Inducement. I
																		acknowledge and agree that I shall be without
																		recourse against Agency or Client if Services
																		Entity fails to pay me my share of the Fee
																		hereunder.
																				</li>
																			</ol>
																		</td>
																	</tr>
																	<tr>
																		<td width={'75%'}>
																			<b>Signed by: {contractForm.watch('performerId.label') || ''}</b>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				<NabuTextbox disabled />
																			</FormControl>
																		</td>
																		<td width={'25%'}>
																			<p>
																				<b>Date</b>
																			</p>
																			<FormControl
																				fullWidth
																				className={styles.tableForms}
																			>
																				<NabuTextbox disabled />
																			</FormControl>
																		</td>
																	</tr>
																</table>
																<ol>
																	<li>
																		<b>Definitions and interpretation</b>
																	</li>
																</ol>
																<ol type='A'>
																	<li>
																		<p>
																	In these General Conditions
																	(&quot;Conditions&quot;), in addition to the
																	definitions used in the Brief:
																		</p>
																		<p>
																	&quot;Image&quot; means, Influencer’s approved
																	name, approved image, approved likeness, approved
																	voice, and/or approved biography;{' '}
																		</p>
																		<p>
																	&quot;<b>Materials</b>&quot; means any materials
																	created and provided solely by you in the course
																	of providing the Services to us (e.g., Video,
																	Recording and any underlying compositions),
																	including (a) materials that are commissioned by
																	us and created by you (&quot;
																			<b>Commissioned Materials</b>&quot;), (b)
																	materials that you have previously created and are
																	provided by you to us (&quot;
																			<b>Existing Materials</b>&quot;) and/or (c)
																	materials that have been or are created by a third
																	party provided by you to us (&quot;
																			<b>Third-party Materials</b>
																	&quot;), which includes any such material that is
																	posted or otherwise communicated to the public by
																	you, including via any blog, sharing platform or
																	other social media;
																		</p>
																		<p>
																	&quot;<b>Personnel</b>&quot; means, in relation to
																	a person, such person’s affiliated entities and
																	its and their respective members, officers,
																	employees, agents, advisers, consultants,
																	contractors and representatives; and{' '}
																		</p>
																		<p>
																			<b>&quot;VAT&quot;</b> means any applicable value
																	added or equivalent tax.
																		</p>
																	</li>
																	<li>
																In this Agreement, unless the context otherwise
																requires, any reference to:
																	</li>
																	<ol>
																		<li>
																			<b>
																		&quot;including&quot;, &quot;other&quot;,
																		&quot;for example&quot;, &quot;such as&quot;,
																		&quot;in particular&quot;
																			</b>{' '}
																	or any similar words is without limitation;
																		</li>
																		<li>
																	any reference to <b>&quot;law&quot;</b> includes
																	any legislation, rules, regulations, guidelines,
																	codes, or decisions which now have, or which have
																	at the relevant time had, the force of law in any
																	jurisdiction;
																		</li>
																		<li>
																			<b>&quot;materials&quot;</b> includes material(s),
																	work(s), performance(s) and/or information of any
																	kind (e.g. any photograph, film, video, musical
																	work, sound recording, graphic work or literary
																	work, regardless of medium or format);
																		</li>
																		<li>
																	a <b>&quot;person&quot;</b> includes any
																	individual, company, firm or joint venture
																	(whether or not having separate legal
																	personality);
																		</li>
																		<li>
																			<b>&quot;writing&quot;</b> or{' '}
																			<b>&quot;written&quot;</b> includes (if
																	successfully transmitted) email;
																		</li>
																		<li>
																	the Client includes (for all purposes of grants
																	and/or assignments of rights, consents and waivers
																	under this Agreement) the Client’s successors, and
																	assigns;
																		</li>
																		<li>
																	the singular includes the plural and vice versa,
																	and use of any gender includes the other genders;
																		</li>
																		<li>
																	any law or document shall refer to it as amended
																	or replaced from time to time; and
																		</li>
																		<li>
																	any obligation imposed on you includes an
																	obligation to procure compliance by each of your
																	relevant Personnel with such obligation, as far as
																	reasonably practicable.
																		</li>
																	</ol>
																</ol>
																<ol>
																	<li value={2}>
																		<b>Term</b>
																		<p>
																	This Agreement shall commence on the Effective
																	Date and last for any period specified in the
																	Brief, in either event unless terminated in
																	accordance with clause 10 (termination) below.
																	After the Term, Client shall have the right to use
																	the Video that has been approved in accordance
																	with the terms hereof solely for internal,
																	non-consumer facing purposes in and in shareholder
																	meetings and corporate events.
																		</p>
																	</li>
																	<br></br>
																	<li>
																		<b>Services</b>
																	</li>
																</ol>
																<ol type='a'>
																	<li>
																		<p>
																	You shall make reasonable efforts to cause
																	Influencer to provide the Services to us in
																	accordance with the Brief and these Conditions and
																	any other terms that you and we may agree.{' '}
																		</p>
																	</li>
																	<li>
																You shall ensure that all Materials shall: (a) not
																feature or refer to any brand names, logos, emblems
																or products that do not belong to us or otherwise
																include third-party intellectual property (e.g.,
																music, artwork) without our express prior written
																consent; (b) not include anything that is offensive,
																defamatory, obscene, indecent or unlawful, or
																prejudicial or to bring us and/or the Client into
																disrepute; (c) comply with all applicable laws
																(including advertising regulations) and with the
																terms and conditions of any social media sites on
																which they are distributed by Influencer; and (d) be
																communicated to the public in a way which makes
																reasonably clear to users that you have a commercial
																relationship with us. In particular, you should
																familiarise yourself with the Advertising Standards
																Authority guidance on blogging and vlogging and the
																FTC Guides Concerning the Use of Endorsements and
																Testimonials in Advertising, making sure that you
																comply with it in the course of providing the
																Services.
																	</li>
																</ol>
																<ol>
																	<li value={4}>
																		<b>Use of your Image and Materials</b>{' '}
																	</li>
																	<ol type='a'>
																		<li>You hereby irrevocably and unconditionally:</li>
																		<li>
																	grant us and the Client a, worldwide licence to
																	use the Materials (including consent to use your
																	approved Image to the extent incorporated in
																	those) solely on TikTok and solely in connection
																	with the Campaign subject to the Term herein.{' '}
																		</li>
																		<li>
																			{' '}
																	grant us and the Client a, perpetual, worldwide
																	licence to use the Materials (including consent to
																	use your approved Image to the extent incorporated
																	in those) for internal, noncommercial purposes
																	(including industry awards, exhibitions,
																	competitions, legacy publications and/or
																	archiving).
																		</li>
																		<li>
																	For the sake of clarity, except for what is
																	strictly permitted herein, no other exploitations
																	of the Materials are granted hereunder and we
																	acknowledge Influencer and you reserve all rights
																	to collect any public performance, neighboring
																	rights or similar monies.{' '}
																		</li>
																	</ol>
																</ol>
															</div>
														</div>												
													</div>
													<div id={CONTRACT_REF_ID.MAIN_FORM_PART_3}  style={{padding:'20px'}}>
														<div className={styles.contractFormBlock}>
															<div className={styles.bgWhite}>
																<div>
																	<ol>
																		<li>
																	To the fullest extent that you are lawfully entitled
																	to do so, you irrevocably and unconditionally waive,
																	and agree not to assert, any and all moral rights to
																	which you may be entitled under the present or
																	future laws of each jurisdiction in relation to the
																	Commissioned Materials and Existing Materials and
																	shall procure from each relevant third party: all
																	relevant consents required for the purposes of
																	clause 4.1(a) above.{' '}
																		</li>
																		<li>
																	We shall have the right to determine the final form
																	of any Materials used by us, including the right to
																	amend and adapt the Materials as we see fit subject
																	to Influencer’s approval of the use of Influencer’s
																	image and any material changes to the Video or
																	Recording.{' '}
																		</li>
																		<li>
																	You shall procure that the Influencer shall comply
																	with all reasonable instructions and reasonable
																	requests from us and/or the Client in relation to
																	the Services and the making or production of any
																	Materials that form part of the Services
																		</li>
																		<li>
																	For the avoidance of doubt, as between you and us,
																	we shall own all right, title and interest in and to
																	all films, photographs, recordings and/or other
																	materials (including any &apos;behind the
																	scenes&apos; images) that are created and produced
																	solely by us in connection with this Agreement
																	(&quot;<b>Client Materials</b>
																	&quot;). We hereby grant you a non- exclusive,
																	non-sublicensable, royalty-free licence to use the
																	Client Materials (solely in the final form provided
																	by or for us to you) during the Term to the extent
																	reasonably required by you in order to perform your
																	Services and other obligations under this Agreement.{' '}
																		</li>
																		<li>
																	You undertake, at our reasonable request, to do all
																	such reasonable acts and to cause Influencer to
																	execute and deliver all such further documents as we
																	may reasonably require in order to have the full
																	benefit of this Agreement, including to confirm,
																	protect, enjoy and/or enforce the rights, consents
																	and waivers expressed to be granted under it
																		</li>
																		<li> </li>
																	</ol>
																	<ol>
																		<li value={5}>
																			<b>Fees and payment</b>
																			<ul>
																				<li>
																			5.1 In consideration for the provision of the
																			Services we shall pay you (or procure the
																			payment to you of) withholding taxes or similar
																			taxes (if any) required to deduct under any
																			applicable laws.
																				</li>
																			</ul>
																		</li>
																	</ol>
																	<ol>
																		<li value={6}>
																			<b>Warranties</b>
																			<ul>
																				<li>
																			6.1 You warrant, represent and undertake to us
																			and the Client that:
																					<ol type='a'>
																						<li>
																					you are free to enter into and perform this
																					Agreement and are not subject to any
																					contractual or other obligation that
																					prevents, restricts, limits or in any way
																					affects your capacity:
																							<p>
																						(i) to enter into this Agreement; (ii) to
																						provide the Services; or (iii) to grant
																						any rights, consents or waivers or to
																						perform any obligations under this
																						Agreement; (iiii) you have the appropriate
																						rights/license to grant rights to use of
																						any ThirdParty Materials in accordance
																						with the Agreement.
																							</p>
																						</li>
																						<li>
																					) the use by or for us and/or the Client of
																					your approved Image and the Materials in
																					accordance with this Agreement shall not
																					misappropriate or infringe any rights of any
																					third party;
																						</li>
																						<li>
																					you shall provide the Services: (i) with
																					reasonable care and skill; (ii) in a
																					competent, professional, timely manner;
																					(iii) in accordance with all applicable laws
																					(including advertising regulations), and
																					with the terms and conditions of any social
																					media sites on which you are distributing
																					Materials; (iv) in good faith and in willing
																					co-operation with others; and (v) in
																					compliance with our/the Client’s reasonable
																					instructions and any rules of any
																					shoot/event locations relevant to the
																					Services as reasonably specified by us
																					and/or by the Agency;
																						</li>
																						<li>
																					you are, so far as you are aware, in a state
																					of sufficiently good health to be able to
																					perform your obligations under this
																					Agreement;
																						</li>
																						<li>
																					to the best of your knowledge you are not
																					the subject of any current or pending legal
																					proceedings that would affect the grant of
																					rights hereunder, nor do you have a criminal
																					record nor are not subject to any
																					outstanding criminal investigation;
																						</li>
																						<li>
																					you have never received nor been recommended
																					treatment for any form of addiction to
																					alcohol, drugs or gambling;you shall keep us
																					informed in a timely manner of any material
																					risk that you may not be able to perform any
																					obligation under this Agreement, or that you
																					and/or any Materials may not in fact comply
																					with any warranty, representation or
																					undertaking under this Agreement, and you
																					confirm that are not aware of any such risk
																					as at the date of signature of this
																					Agreement; and
																						</li>
																						<li>
																					you shall not incur any costs or liabilities
																					on our behalf or on behalf of the Agency,
																					nor purport to have any authority to do so,
																					without our approval.
																						</li>
																					</ol>
																					<br></br>
																					<p>
																				6.2 We warrant, represent and undertake to you
																				that:
																					</p>
																					<ol type='a'>
																						<li>
																					(a) Agency and Client are free to enter into
																					and perform this Agreement and are not
																					subject to any contractual or other
																					obligation that prevents, restricts, limits
																					or in any way affects such party’s capacity:
																							<p>
																						(i) to enter into this Agreement; to grant
																						any rights, consents or waivers or to
																						perform any obligations under this
																						Agreement; and Agency and Client have the
																						appropriate rights/license to grant rights
																						to use of any Client Materials in
																						accordance with the Agreement.
																							</p>
																						</li>
																						<li>
																					the use of the Client Materials and any
																					Client trademarks or intellectual property
																					in accordance with this Agreement shall not
																					misappropriate or infringe any rights of any
																					third party;
																						</li>
																						<li>
																					we shall keep you informed in a timely
																					manner of any material risk that Agency or
																					Client may not be able to perform any
																					obligation under this Agreement, or that you
																					and/or any Client Materials may not in fact
																					comply with any warranty, representation or
																					undertaking under this Agreement, and we
																					confirm that we are not aware of any such
																					risk as at the date of signature of this
																					Agreement; and
																						</li>
																						<li>
																					We shall not incur any costs or liabilities
																					on your behalf, nor purport to have any
																					authority to do so, without your approval.
																						</li>
																						<li>
																					Agency and Client shall perform all
																					activities under this Agreement in
																					accordance with all applicable Federal,
																					state, local, and international laws and
																					regulations;
																						</li>
																						<li>
																					All of the elements of the Campaign, other
																					than those created by you, will not violate,
																					conflict with, or infringe upon any rights
																					whatsoever of any person or entity and that,
																					we will clear all third party rights with
																					respect to the Campaign (other than with
																					respect to those elements created or
																					supplied by you);
																						</li>
																						<li>
																					Agency or Client shall not, nor authorize
																					any third party, to distribute or otherwise
																					exploit any Materials, including without
																					limitation the Video and Recording, other
																					than on TikTok;
																						</li>
																						<li>
																					The Campaign will not violate the rights of
																					any person or entity.
																						</li>
																						<li>
																					TikTok, Inc. has not contributed any
																					materials to the Campaign.
																						</li>
																					</ol>
																				</li>
																			</ul>
																		</li>
																	</ol>
																</div>
																<ol>
																	<li value={7}>
																		<b>Restrictions</b>
																		<ol>
																			<li>
																			You shall not: (a) during the Term, publish (or
																			cause to be published) by way of comment or
																			otherwise anything derogatory about us, the
																			Client, the Campaign, the Product and/or our
																			products or services; and/or (b) during the Term
																			behave in any manner that is prejudicial to the
																			Client or its legitimate interests such that the
																			Client would no longer wish to continue its
																			association with you; and/or (c) In the event
																			you receive a bona fide offer from a third party
																			to enter into an agreement which as a material
																			term, authorizes the use of Influencer’s image
																			and/or name prior to January 20, 2024 for the
																			direct benefit of an entity which manufactures,
																			distributes and sells non-chocolate candies, you
																			shall use your best efforts to notify us of the
																			offer in writing (email shall suffice), and we
																			shall have the opportunity to notify you within
																			forty-eight (48) hours of receipt of your notice
																			to extend a competing offer, provided that you
																			shall have no obligation to accept such offer.
																			</li>
																			<li>
																			Except as otherwise specified in Brief or
																			following our request in writing that you do so,
																			you shall not during the Term remove or take
																			down (or cause to be removed or taken down) the
																			Video posted online. If we request that you so
																			remove or take down the Video, you shall do so
																			as soon as reasonably practicable.
																			</li>
																		</ol>
																	</li>
																</ol>														
															</div>
														</div>
													</div>
													<div id={CONTRACT_REF_ID.MAIN_FORM_PART_4}  style={{padding:'20px'}}>
														<div className={styles.contractFormBlock}>
															<div className={styles.bgWhite}>
																<ol>
																	<li value={8}>
																		<b>Confidentiality and data protection </b>
																		<ol>
																			<li>
																			You shall keep confidential any confidential
																			information that may be supplied to you by us
																			and/or the Client in connection with this
																			Agreement, and we shall keep confidential any
																			confidential information that may be supplied to
																			us by or for you. Confidential information will
																			include the provisions of this Agreement, any
																			information marked as being confidential and any
																			other information that ought reasonably to be
																			assumed to be confidential. Each of us shall be
																			entitled to disclose the other party’s
																			confidential information to any professional
																			advisor/employee/contractor whose duties justify
																			their need to know such confidential
																			information. In such circumstances the
																			disclosing party shall ensure the disclosee
																			complies with the terms of this clause and
																			disclosing party shall be fully responsible and
																			liable for any breach of this clause by
																			disclosee. The obligations as to confidentiality
																			in this Agreement will not apply to any
																			information that: (a) is available to the public
																			otherwise than because of any breach of this
																			Agreement or law; (b) is, when it is supplied,
																			already known to whomever it is disclosed to in
																			circumstances in which they are not prevented
																			from disclosing it to others; (c) is
																			independently obtained by whomever it is
																			disclosed to in circumstances in which they are
																			not prevented from disclosing it to others; or
																			(d) is required to be disclosed by law or by any
																			court, tribunal or other body with proper
																			authority to order its disclosure.
																			</li>
																			<li>
																			You acknowledge that we will, for any and all
																			purposes connected with performing this
																			Agreement, running the Campaign and/or
																			exercising our rights under this Agreement,
																			process personal data about you, and you
																			acknowledge that (to the extent not necessary
																			for the performance of this Agreement or for the
																			performance of a legal obligation) we and the
																			Client each have a legitimate interest in doing
																			so (and a legitimate interest that is consistent
																			with your own interests, rights and freedoms).
																			Such processing may, to the extent required or
																			desired by us and/or the Client, include the
																			transfer of such personal data outside the EEA,
																			and any such transfer will be undertaken on a
																			lawful basis. If and to the extent that we
																			and/or the Agency require your explicit consent
																			to any such data processing (e.g. for processing
																			of special-category data, if any), you hereby
																			grant such consent.
																			</li>
																		</ol>
																	</li>
																</ol>
																<ol>
																	<li value={9}>
																		<b>Limitation of remedies</b>
																		<ol>
																			<li>
																			Despite anything in this Agreement, neither
																			party shall be liable to the other for any
																			indirect, consequential or special loss or
																			damage (including any loss of business, profits
																			or goodwill), even if aware of the possibility
																			of such loss or damage.
																			</li>
																			<li>
																			Without limiting the foregoing, each
																			party&apos;s total liability under this
																			Agreement (whether contractual, tortious or
																			otherwise) shall be limited to an amount equal
																			to the aggregate of the remuneration payable to
																			you by us under this Agreement, provided that:
																			(a) this shall not exclude or restrict any
																			liability that cannot be excluded or restricted
																			under applicable law; and (b) such limitation
																			shall not apply to any breach of (i) any
																			warranties or representations under this
																			Agreement or (ii) any undertakings or other
																			obligations relating to intellectual property,
																			confidential information or data protection
																			under this Agreement.
																			</li>
																		</ol>
																	</li>
																</ol>
																<ol>
																	<li value={10}>
																		<b>Termination</b>
																		<ol>
																			<li>
																			.We may terminate this Agreement at any time for
																			convenience by giving not less than one
																			month&apos;s written notice, or at any time with
																			immediate effect if the Campaign is cancelled.
																			</li>
																			<li>
																			Either party may terminate this Agreement
																			immediately by written notice to the other party
																			if the other party commits a material breach of
																			any provision of this Agreement and it is not
																			possible to remedy that breach or if it is
																			possible to remedy that breach, the other party
																			fails to do so within 48 hours of being asked to
																			do so.
																			</li>
																			<li>
																			We may terminate this Agreement immediately by
																			written notice to you if you are the subject of
																			any adverse publicity as a result of which we
																			and/or the Agency reasonably consider (in our
																			and/or its sole discretion) that continued
																			association with you may cause damage to our
																			and/or the Client&apos;s reputation.
																			</li>
																			<li>
																			Where this Agreement is terminated pursuant to
																			clause 10.1, 10.2 and 10.3, we shall be required
																			to pay you pro rata portion of the Fee for
																			Services completed prior to the termination. .
																			Where this Agreement is terminated by you
																			pursuant to clauses 10.2 we will be required to
																			pay you the full Fee.
																			</li>
																			<li>
																			Termination of this Agreement for any reason
																			will not affect any accrued rights or
																			liabilities that either party may have at the
																			time termination takes effect, nor the coming
																			into or continuance in force of any provision of
																			this Agreement that is expressly or by
																			implication intended to come into or continue in
																			force by or after such termination.
																			</li>
																			<li>
																			Upon our request, you shall promptly deliver up
																			to us, or otherwise dispose of at our direction,
																			any and all property (including data) belonging
																			to us as may be in your possession or under your
																			control and you shall not retain any copies
																			(whether in hard copy or electronic or any other
																			form) of the same without our prior written
																			consent.
																			</li>
																			<li>
																			7 If either party is prevented from performing
																			its obligations hereunder by reason of any act
																			of God, earthquake, flood, fire, epidemic,
																			pandemic, accident, explosion, casualty, labor
																			controversy, riot, civil disturbance, war or
																			armed conflict, act or threat of terrorism, or
																			by reason of any other cause or causes of any
																			similar nature beyond a party’s reasonable
																			control (all, a “Force Majeure Event”), then
																			such party’s nonperformance will not be deemed a
																			breach of this Agreement. If, as a result of a
																			Force Majeure Event Client is unable to use your
																			Services during any period of the Term, or if
																			the threat of a Force Majeure Event makes
																			execution of the Campaign impracticable,
																			inadvisable or infeasible, then we will have the
																			right to: (i) terminate this Agreement without
																			liability effective immediately, or (ii) extend
																			the Term for a mutually-agreed period, without
																			any additional compensation to you, subject to
																			your prior professional commitments, provided
																			that if, upon request by us hereunder, you do
																			not wish to extend the Term, then this Agreement
																			will be terminated and you will be paid pro rata
																			for Services completed prior to the termination
																			due to the Force Majeure Event.
																			</li>
																		</ol>
																	</li>
																</ol>
																<ol>
																	<li value={11}>
																		<b>Indemnity</b>
																		<p>
																		You shall indemnify us and the Client on demand
																		against any and all third party claims, actions,
																		proceedings, demands, damages, losses,
																		liabilities, reasonable costs and expenses
																		(including reasonable legal fees) suffered or
																		incurred by the indemnified party as a result of
																		or in connection with your breach of any provision
																		of this Agreement,. Agency shall indemnify you on
																		demand against any and all third party claims,
																		actions, proceedings, demands, damages, losses,
																		liabilities, reasonable costs and expenses
																		(including reasonable legal fees) suffered or
																		incurred by you as a result of or in connection
																		with Agency’s breach of any provision of this
																		Agreement. Further, Agency shall indemnify you
																		with respect to Agency’s or Client’s
																		contributions, including the Client Materials, and
																		with respect to claims arising from the
																		development, marketing and implementation of the
																		Campaign,
																		</p>
																	</li>
																</ol>
																<ol>
																	<li value={12}>
																		<b>General</b>
																		<ol>
																			<br />
																			<li>
																			You may not assign, sub-contract or otherwise
																			dispose of or delegate any of your rights and/or
																			obligations under this Agreement without our
																			prior written consent, except for the right to
																			assign payment hereunder.
																			</li>
																			<li>
																			All notices and consents relating to this
																			Agreement must be in writing, including (if
																			successfully transmitted) email. Copies of all
																			notices to you shall be simultaneously sent to
																			Mark Music & Media Law, P.C., 120 El Camino
																			Drive, Suite 104, Beverly Hills, CA, 90212,
																			Attn: Harrison S. Roberts, Esq.
																			</li>
																			<li>
																			Any variation to this Agreement must be agreed,
																			set out in writing and signed on behalf of both
																			parties before such variation takes effect.
																			</li>
																			<li>
																			This Agreement (which shall include any attached
																			briefings or client band guidelines) sets out
																			all of the terms that have been agreed between
																			the parties in relation to the subjects covered
																			by it. No other representations or terms
																			(whether written or oral) shall apply or form
																			part of this Agreement. Each party acknowledges
																			that, in entering into this Agreement, it is not
																			relying on (and shall have no remedies in
																			respect of) any warranty, representation,
																			statement or other assurance (whether made
																			innocently or negligently) except as expressly
																			set out or referred to in this Agreement.
																			Nothing in this Agreement shall, however, limit
																			or exclude any liability for fraud.
																			</li>
																			<li>
																			The failure of either party to enforce or
																			exercise at any time any provision of or any
																			right under this Agreement does not constitute
																			and shall not be construed as a waiver of such
																			provision or right and shall in no way affect
																			that party&apos;s later right to enforce or to
																			exercise it.
																			</li>
																			<li>
																			The Client may enforce any provisions of this
																			Agreement that confer a benefit on the Client.
																			The parties do not require the consent of any
																			third party to rescind or terminate this
																			Agreement. Except as expressly provided in this
																			clause, no provision of this Agreement is
																			intended to confer a benefit on, or to be
																			enforceable by, any person who is not a party to
																			this Agreement, except for Client. You are
																			engaged as an independent contractor. Nothing in
																			this Agreement shall render you an employee,
																			worker, agent, partner or joint venturer of us
																			or of the Agency (or of any other person
																			referred to in this Agreement), nor shall you
																			purport to be such.
																			</li>
																			<br />
																			<br />
																			<li>
																			If a provision of this Agreement is
																			unenforceable (in whole or in part), the
																			enforceability of the remainder of this
																			Agreement shall not be affected and the Parties
																			shall use reasonable endeavours to agree on a
																			valid replacement provision whose content
																			corresponds to the original provision as much as
																			possible.
																			</li>
																			<li>
																			This Agreement may be entered into in
																			counterparts (including, if successfully
																			transmitted, by scanned copies sent by email).
																			</li>
																			<li>
																			This Agreement and any related dispute or claim
																			(contractual or non-contractual) shall be
																			governed by, and construed in accordance with,
																			the laws of the State of New York, United States
																			of America and subject to the exclusive
																			jurisdiction of the state and federal courts
																			sitting in the county of New York, State of New
																			York.
																			</li>
																			<li>
																			You: (a) nor anyone working on your behalf has
																			not artificially exaggerated or inflated the
																			size of Influencer’s social media following,
																			subscribers, likes, shares or comments (or
																			similar actions);; and (c) will use reasonable
																			efforts to verify the authenticity of
																			Influencer’s followers, subscribers, likes and
																			comments and should you learn, or reasonably
																			believe that the authenticity of the
																			aforementioned is suspect, you will take
																			reasonable action to resolve such issues
																			promptly.
																			</li>
																		</ol>
																	</li>
																</ol>
															</div>
														</div>
													</div>
												</>
												{/* Main Contract Form End */}

												{/* W4 Short form */}
												{packetControlForm.watch('isW4shortForm') && (
													<div className={styles.defaultTemplate}>
														<div className={styles.contractFormBlock}>
															<ContractW4ShortForm
																contractForm={contractForm}
																isPrint={props?.isPrint}
															/>
														</div>
													</div>
												)}
												{/* W8 Ben Short form */}
												{packetControlForm.watch('isW8BenShort') && (
													<div className={styles.defaultTemplate}>
														<div className={styles.contractFormBlock}>
															<ContractW8BenShortForm
																contractForm={contractForm}
																isPrint={props?.isPrint}
															/>
														</div>
													</div>
												)}

												{/* W9 Ben Short form */}
												{packetControlForm.watch('isW9BenShort') && (
													<div className={styles.defaultTemplate}>
														<div className={styles.contractFormBlock}>
															<ContractW9BenShortForm
																contractForm={contractForm}
																isPrint={props?.isPrint}
															/>
														</div>
													</div>
												)}

												{/* Minor Trust form */}
												{packetControlForm.watch('isMinorTrust') && (
													<div className={styles.defaultTemplate}>
														<div className={styles.contractFormBlock}>
															<ContractMinorTrustForm
																contractForm={contractForm}
																isPrint={props?.isPrint}
															/>
														</div>
													</div>
												)}

												{/* NY-Wage-Theft-prevention  form */}
												{packetControlForm.watch('isNYTheftPreventionForm') && (
													<div className={styles.defaultTemplate}>
														<div className={styles.contractFormBlock}>
															<ContractNYWageTheftPreventionForm
																contractForm={contractForm}
																isPrint={props?.isPrint}
															/>
														</div>
													</div>
												)}

												{/* CA-Wage-Theft-prevention  form */}
												{packetControlForm.watch('isCATheftPreventionForm') && (
													<div className={styles.defaultTemplate}>
														<div className={styles.contractFormBlock}>
															<ContractCAWageTheftPreventionForm
																contractForm={contractForm}
																isPrint={props?.isPrint}
															/>
														</div>
													</div>
												)}
											</div>
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>

					{!props?.isPrint && (
						<div className={styles.contractTemplateBtn}>
							<Grid container>
								<Grid xs={12} md={6}>
									<NabuButton variant='filled' onClick={onClickSnippetBtn}>
										{!contractFormState?.isPacketOpen ? (
											<>
												<NoteAdd /> Create
											</>
										) : (
											'Close'
										)}
									&nbsp; Packet
									</NabuButton>
								</Grid>
								<Grid xs={12} md={6} dir='rtl'>
									<NabuButton
										variant='outlined'
										data-cy='cancel_btn'
										shouldHandleConfirmAction={contractForm.formState.isDirty}
										onClick={() => handleFormClose()}
									>
									Cancel
									</NabuButton>{' '}
								&nbsp;
									<NabuButton
										variant='filled'
										type='submit'
										data-cy='save_btn'
										disabled={
											!(
												contractForm.formState.isDirty ||
											packetControlForm.formState.isDirty
											) ||
										isAddingContract ||
										isEditingContract
										}
									>
										{isAddingContract || isEditingContract ? 'Saving' : 'Save'}
										<Save />
									</NabuButton>{' '}
								&nbsp;
									<NabuButton
										onClick={contractForm.handleSubmit(handleSaveAndDuplicate)}
										variant='filled' 
										disabled={
											!(
												contractForm.formState.isDirty ||
											packetControlForm.formState.isDirty
											) ||
										isAddingContract ||
										isEditingContract || (!(props?.mainForm?.watch('contractDisplayName'))&& !props?.isEdit)
										}>
									Save & Duplicate <CopyAll />
									</NabuButton>
								&nbsp;
								</Grid>
							</Grid>
						</div>
					)}
				</form>
			</div>
			<TalentDetailsForm handleTalentDetailsForm={(value) => handleTalentDetailsForm(value)} noOfTalentsSelected={noOfTalentsSelected} setNoOfTalentsSelected={(value) => setNoOfTalentsSelected(value)} isUnion={false} />
			{isExpiryDateDialogOpen.isOpen && <ExpiryDateForm
				contractExpiryDate={isExpiryDateDialogOpen.contractExpiryDate || props?.initialValue?.expirationDate}
				isOpen={isExpiryDateDialogOpen.isOpen}
				handleExpiryDateForm={(values) => onSubmitOfExpiryDateForm(values)}
				handleFormClose={closeExpiryDateForm}
			/>}
		</>
	);
});

export default MsaInfluenceAgreement;
