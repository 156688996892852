import { IconButton, Icon } from '@mui/material';
import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MoreHoriz } from '@mui/icons-material';
import { ITableActionMenuModel } from '@/common/types/dataTable';

export const TableActionMenu = (props: { menus: ITableActionMenuModel[], record?: Record<string, any> }) => {
	
	const [isActionMenu, setActionMenu] = useState<null | HTMLElement>(null);
	const isMenuOpen = Boolean(isActionMenu);

	const handleMenuOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
		setActionMenu(event.currentTarget);
	};

	const handleMenuClose = () => {
		setActionMenu(null);
	};

	return (
		<div>
			<IconButton size="small" aria-controls={isMenuOpen ? 'basic-menu' : undefined} aria-haspopup="true" color="primary"
				aria-expanded={isMenuOpen ? 'true' : undefined} onClick={handleMenuOpen}><MoreHoriz/></IconButton>            
			<Menu id="basic-menu" anchorEl={isActionMenu} open={isMenuOpen} onClose={handleMenuClose}
				MenuListProps={{
					'aria-labelledby': 'basic-button',
				}}
			>
				{props.menus.map((action, index) =>
				{ return !action.visible || (action.visible && action.visible(props.record)) ?
					<MenuItem key={`menu-item-${index}`} disabled={action.disabled && action.disabled(props.record)} 
						onClick={(event: any)=>{
							if(action?.onClick){
								action.onClick(props.record, event);
							}
							handleMenuClose();
						}} sx={{ fontSize: '14px' }}
						data-cy={action['data-cy']}  style={{minHeight: '48px'}}>
						{!(typeof action.iconColor === 'function') && <Icon color={action.iconColor} component={action.icon(props.record)} sx={{ marginRight: '10px', fontSize: '20px', color: action.iconColor }} />}
						{action.title(props.record)}
					</MenuItem> : <></> ;}
				)}
			</Menu>
		</div>);
};
