import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import { IBaseContract } from '@/common/types/contract';
import { FormControl, FormHelperText } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import styles from '../forms/forms.module.scss';
import QE from '../forms/img/qe.png';
import { CONTRACT_REF_ID } from '@/common/constants/constants';

interface Props {
	contractForm: UseFormReturn<IBaseContract | any, any, undefined>;
	isPrint?: boolean;
}

const ContractW4ShortForm = (props: Props) => {
	const contractForm = props?.contractForm as UseFormReturn<IBaseContract, any, undefined>;

	const getAmountValue = (targetValue?: string, isInt: boolean = false): number => {

		const getNumber = (value: number | string | undefined): number => {
			if(isInt)
				return parseInt(`${isNaN(Number(value)) ? 0 : Number(value)}`);
			else
				return isNaN(Number(value)) ? 0 : Number(value);
		};

		if (new RegExp(/^\d*\.?\d*$/).test(targetValue || '') && Number(targetValue) >= 0) {
			return getNumber(targetValue);
		} 
		else {
			const value: string = targetValue ?? '';
			const num = value?.match(/^\d*\.?\d*/)?.at(0);
			return getNumber(num);
		}
	};

	return (
		<>
			<div className={styles.contractFormBlock} id={CONTRACT_REF_ID.W4_SHORT_FORM_PART_1} style={{padding:'20px'}}>
				<div className={styles.bgWhite}>
					<table
						className={styles.contractForms}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
					>
						<tbody>
							<tr>
								<td rowSpan={2} width={250}>
									Form{' '}
									<span style={{ fontSize: '40px', fontWeight: 'bold' }}>
										W-4
									</span>
									<p>
										Department of the Treasury<br></br>
										Internal Revenue Service
									</p>
								</td>
								<td rowSpan={2} align='center' color='black'>
									<h5>Employee’s Withholding Certificate</h5>
									<p>
										<b>
											{' '}
											Complete Form W-4 so that your employer can withhold the
											correct federal income tax from your pay.<br></br>
											Give Form W-4 to your employer.<br></br>
											Your withholding is subject to review by the IRS.
										</b>
									</p>
								</td>
								<td align='center' width={250}>
									OMB No.
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW4Form.ombNo'
											defaultValue='1545-0074'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractW4Form?.ombNo?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractW4Form?.ombNo?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
							<tr>
								<td align='center'>
									<h5>2024</h5>
								</td>
							</tr>
						</tbody>
					</table>

					<table
						className={styles.contractForms}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
						style={{ borderTop: '0px' }}
					>
						<tbody>
							<tr>
								<td rowSpan={4} width={250}>
									<b className={styles.heading3}>
										Step 1:<br></br>
										Enter <br></br>
										Personal <br></br>
										Information
									</b>
								</td>
								<td>
									(a) First name and middle initial
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW4Form.firstAndMiddleName'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuTextbox {...field} placeholder='Enter First name and middle Name' disabled={props?.isPrint}/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW4Form?.firstAndMiddleName?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW4Form?.firstAndMiddleName?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</td>
								<td>
									(a) Last Name
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW4Form.lastName'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuTextbox {...field} placeholder='Enter First name and middle Name' disabled={props?.isPrint}/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW4Form?.lastName?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW4Form?.lastName?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</td>
								<td>
									(b) Social security number
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW4Form.ssn'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuTextbox {...field} placeholder='Enter Social security #' disabled={props?.isPrint}/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW4Form?.ssn?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW4Form?.ssn?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									Address
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW4Form.address'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuTextbox {...field} placeholder='Enter Address' disabled={props?.isPrint}/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW4Form?.address?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW4Form?.address?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</td>
								<td rowSpan={2} width={250}>
									<b>
										Does your name match the<br></br>
										name on your social security <br></br>
										card?{' '}
									</b>{' '}
									If not, to ensure you get <br></br>
									credit for your earnings,<br></br>
									contact SSA at 800-772-1213<br></br>
									or go to <em>www.ssa.gov.</em>
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									City or town, state, and Zip Code
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW4Form.cityStateZipCode'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuTextbox {...field} placeholder='Enter City or town, state, and ZIP' disabled={props?.isPrint}/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW4Form?.cityStateZipCode?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW4Form?.cityStateZipCode?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</td>
							</tr>
							<tr>
								<td colSpan={3}>
									<p>
										<b>(c)</b>
									</p>
									<p>
										<div
											style={{ display: 'inline-flex', alignItems: 'center' }}
										>
											<Controller
												control={contractForm.control}
												name='contractW4Form.isMarriedFilingSeparately'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => field.onChange(e)}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ width: '20px', marginTop: '2px' }}
														/>
														<b>Single or Married filing separately </b>
													</div>
												)}
											/>
										</div>
									</p>
									<p>
										<div
											style={{ display: 'inline-flex', alignItems: 'center' }}
										>
											<Controller
												control={contractForm.control}
												name='contractW4Form.isMarriedFilingJointly'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => field.onChange(e)}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ width: '20px', marginTop: '2px' }}
														/>
														<span>
															<b>
																Married filing jointly or Qualifying surviving spouse
															</b>
														</span>
													</div>
												)}
											/>											
										</div>
									</p>
									<p>
										<div
											style={{ display: 'inline-flex', alignItems: 'center' }}
										>
											<Controller
												control={contractForm.control}
												name='contractW4Form.isHeadOfHouseHold'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => field.onChange(e)}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ width: '20px', marginTop: '2px' }}
														/>
														<span>
															<b>Head of household</b> (Check only if you’re unmarried
															and pay more than half the costs of keeping up a home
															for yourself and a qualifying individual.)
														</span>
													</div>
												)}
											/>	
										</div>
									</p>
								</td>
							</tr>
						</tbody>
					</table>

					<table
						className={styles.contractForms}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
						style={{ borderTop: '0px' }}
					>
						<tbody>
							<tr>
								<td>
									<p>
										<b>
											Complete Steps 2–4 ONLY if they apply to you; otherwise,
											skip to Step 5.
										</b>{' '}
										See page 2 for more information on each step, who can claim
										exemption from withholding, other details, and privacy.
									</p>
								</td>
							</tr>
						</tbody>
					</table>

					<table
						className={styles.contractForms}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
						style={{ borderTop: '0px' }}
					>
						<tbody>
							<tr>
								<td width={250}>
									<b className={styles.heading3}>
										Step 2:<br></br>
										Multiple Jobs<br></br>
										or Spouse<br></br>
										Works
									</b>
								</td>
								<td>
									<p>
										Complete this step if you (1) hold more than one job at a
										time, or (2) are married filing jointly and your spouse also
										works. The correct amount of withholding depends on income
										earned from all of these jobs.
									</p>
									<p>
										Do <b>only one </b>of the following
									</p>
									<p>
										<b>(a)</b> Reserved for future use
									</p>
									<p>
										<b>(b)</b> Use the Multiple Jobs Worksheet on page 3 and
										enter the result in Step 4(c) below; <b>or</b>
									</p>
									<Controller
										control={contractForm.control}
										name='contractW4Form.isMultipleJobsOrSpouseWorks'
										defaultValue={false}
										render={({ field }) => (
											<div
												onClick={() => field.onChange(!field.value)}
												style={{ userSelect: 'none' }}
											>												
												<p style={{ display: 'inline-block' }}>
													<b>(c)</b> If there are only two jobs total, you may check
										this box. Do the same on Form W-4 for the other job. This
										option is generally more accurate than (b) if pay at the
										lower paying job is more than half of the pay at the higher
										paying job. Otherwise, (b) is more accurate ......
													<div style={{ float: 'right' }}>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => field.onChange(e)}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ width: '20px', marginTop: '2px' }}
														/>
													</div>
												</p>
											</div>
										)}
									/>										
									<p>TIP: If you have self-employment income, see page 2.</p>
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<p>
										<b>
											Complete Steps 3–4(b) on Form W-4 for only ONE of these
											jobs.
										</b>{' '}
										Leave those steps blank for the other jobs. (Your
										withholding will be most accurate if you complete Steps
										3–4(b) on the Form W-4 for the highest paying job.)
									</p>
								</td>
							</tr>
						</tbody>
					</table>
					<table
						className={styles.contractForms}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
						style={{ borderTop: '0px' }}
					>
						<tbody>
							<tr>
								<td rowSpan={4} width={250}>
									<b className={styles.heading3}>
										Step 3:<br></br>
										Claim<br></br>
										Dependent<br></br>
										and Other<br></br>
										Credits
									</b>
								</td>
								<td>
									<p style={{ width: '100%', clear: 'both' }}>
										If your total income will be $200,000 or less ($400,000 or
										less if married filing jointly): Multiply the number of
										qualifying children under age 17 by $2,000{' '}
										<div
											style={{
												float: 'right',
												display: 'flex',
												alignItems: 'center',
											}}
										>
											{' '}
											$
											<FormControl className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='contractW4Form.underAgeDependentClaim'
													render={({ field }) => (
														<>
															<NabuTextbox 
																{...field}
																onChange={e => {
																	field.onChange(getAmountValue(e?.target?.value || ''));
																}}
																disabled={props?.isPrint}
															/>
														</>
													)}
												/>
												{contractForm?.formState?.errors?.contractW4Form?.underAgeDependentClaim?.message && (
													<FormHelperText error={true}>
														{
															contractForm?.formState?.errors?.contractW4Form?.underAgeDependentClaim?.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</div>
									</p>
									<p style={{ width: '100%', clear: 'both' }}>
										Multiply the number of other dependents by $500{' '}
										<div
											style={{
												float: 'right',
												display: 'flex',
												alignItems: 'center',
											}}
										>
											{' '}
											$
											<FormControl className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='contractW4Form.otherDependentClaim'
													render={({ field }) => (
														<>
															<NabuTextbox 
																{...field}
																onChange={e => {
																	field.onChange(getAmountValue(e?.target?.value || ''));
																}}
																disabled={props?.isPrint}
															/>
														</>
													)}
												/>
												{contractForm?.formState?.errors?.contractW4Form?.otherDependentClaim?.message && (
													<FormHelperText error={true}>
														{
															contractForm?.formState?.errors?.contractW4Form?.otherDependentClaim?.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</div>
									</p>
									<p style={{ width: '100%', clear: 'both' }}>
										Add the amounts above for qualifying children and other
										dependents. You may add to this the amount of any other
										credits. Enter the total here
									</p>
								</td>
								<td width={25} valign='bottom'>
									<b>3</b>
								</td>
								<td width={200} valign='bottom'>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div>$</div>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='contractW4Form.totalDependentClaim'
												render={({ field }) => (
													<>
														<NabuTextbox 
															{...field}
															onChange={e => {
																field.onChange(getAmountValue(e?.target?.value || ''));
															}}
															disabled={props?.isPrint}
														/>
													</>
												)}
											/>
											{contractForm?.formState?.errors?.contractW4Form?.totalDependentClaim?.message && (
												<FormHelperText error={true}>
													{
														contractForm?.formState?.errors?.contractW4Form?.totalDependentClaim?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</div>
								</td>
							</tr>
						</tbody>
					</table>

					<table
						className={styles.contractForms}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
						style={{ borderTop: '0px' }}
					>
						<tbody>
							<tr>
								<td rowSpan={3} width={250}>
									<b className={styles.heading3}>
										Step 4<br></br>
										(optional):<br></br>
										Other<br></br>
										Adjustments
									</b>
								</td>
								<td>
									<div style={{ display: 'flex' }}>
										<div>
											<b>(a)</b>
										</div>
										<div>
											<b>Other income (not from jobs).</b> If you want tax
											withheld for other income you expect this year that won’t
											have withholding, enter the amount of other income here.
											This may include interest, dividends, and retirement
											income
										</div>
									</div>
								</td>
								<td valign='bottom'>
									<b>4(a)</b>
								</td>
								<td>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div>$</div>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='contractW4Form.otherIncomeAmount'
												render={({ field }) => (
													<>
														<NabuTextbox 
															{...field}
															onChange={e => {
																field.onChange(getAmountValue(e?.target?.value || ''));
															}}
															disabled={props?.isPrint}
														/>
													</>
												)}
											/>
											{contractForm?.formState?.errors?.contractW4Form?.otherIncomeAmount?.message && (
												<FormHelperText error={true}>
													{
														contractForm?.formState?.errors?.contractW4Form?.otherIncomeAmount?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div style={{ display: 'flex' }}>
										<div>
											<b>(b)</b>
										</div>
										<div>
											<b>Deductions.</b>If you expect to claim deductions other
											than the standard deduction and want to reduce your
											withholding, use the Deductions Worksheet on page 3 and
											enter the result here
										</div>
									</div>
								</td>
								<td valign='bottom'>
									<b>4(b)</b>
								</td>
								<td valign='bottom'>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div>$</div>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='contractW4Form.deductionAmount'
												render={({ field }) => (
													<>
														<NabuTextbox 
															{...field}
															onChange={e => {
																field.onChange(getAmountValue(e?.target?.value || ''));
															}}
															disabled={props?.isPrint}
														/>
													</>
												)}
											/>
											{contractForm?.formState?.errors?.contractW4Form?.deductionAmount?.message && (
												<FormHelperText error={true}>
													{
														contractForm?.formState?.errors?.contractW4Form?.deductionAmount?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</div>
								</td>
							</tr>
							<tr>
								<td>
									<div style={{ display: 'flex' }}>
										<div>
											<b>(c)</b>
										</div>
										<div>
											<b>Extra withholding.</b> Enter any additional tax you
											want withheld each <b>pay period</b>
										</div>
									</div>
								</td>
								<td valign='bottom'>
									<b>4(c)</b>
								</td>
								<td valign='bottom'>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<div>$</div>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='contractW4Form.extraWithHoldingAmount'
												render={({ field }) => (
													<>
														<NabuTextbox 
															{...field}
															onChange={e => {
																field.onChange(getAmountValue(e?.target?.value || ''));
															}}
															disabled={props?.isPrint}
														/>
													</>
												)}
											/>
											{contractForm?.formState?.errors?.contractW4Form?.extraWithHoldingAmount?.message && (
												<FormHelperText error={true}>
													{
														contractForm?.formState?.errors?.contractW4Form?.extraWithHoldingAmount?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</div>
								</td>
							</tr>
						</tbody>
					</table>

					<table
						className={styles.contractForms}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
						style={{ borderTop: '0px' }}
					>
						<tbody>
							<tr>
								<td rowSpan={2} width={250}>
									<b className={styles.heading3}>
										Step 4<br></br>
										(optional):<br></br>
										Other<br></br>
										Adjustments
									</b>
								</td>
								<td colSpan={2}>
									<p>
										Under penalties of perjury, I declare that this certificate,
										to the best of my knowledge and belief, is true, correct,
										and complete.
									</p>
								</td>
							</tr>
							<tr>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<NabuTextbox disabled={true} />
									</FormControl>
									<p>
										<b>Employee’s signature</b> (This form is not valid unless
										you sign it.)
									</p>
								</td>
								<td width={320}>
									<FormControl fullWidth className={styles.tableForms}>
										<NabuTextbox disabled={true} />
									</FormControl>
									<p>
										<b>Date</b> (This form is not valid unless you sign it.)
									</p>
								</td>
							</tr>
						</tbody>
					</table>

					<table
						className={styles.contractForms}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
						style={{ borderTop: '0px' }}
					>
						<tbody>
							<tr>
								<td width={250}>
									<b className={styles.heading3}>
										Employers<br></br>
										Only
									</b>
								</td>
								<td>
									<p>Employer’s Name and Address</p>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW4Form.employNameAndAddress'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW4Form?.employNameAndAddress?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW4Form?.employNameAndAddress?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</td>
								<td>
									<p>First date of employment</p>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name={'contractW4Form.firstDateOfEmployment'}
											defaultValue=''
											render={({ field }) => <NabuDatepicker {...field} disabled={props?.isPrint} />}
										/>
										{contractForm?.formState?.errors?.contractW4Form?.firstDateOfEmployment?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW4Form?.firstDateOfEmployment?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</td>
								<td width={320}>
									<p>Employer identification number (EIN)</p>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW4Form.employerIdentificationNumber'
											render={({ field }) => (
												<>
													<NabuTextbox 
														{...field}
														disabled={props?.isPrint}
													/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW4Form?.employerIdentificationNumber?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW4Form?.employerIdentificationNumber?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>

			<div className={styles.contractFormBlock} id={CONTRACT_REF_ID.W4_SHORT_FORM_PART_2} style={{padding:'20px'}}>
				<div className={styles.bgWhite}>
					<table
						className={styles.contractForms}
						border={0}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
					>
						<tr>
							<td width={'50%'} valign='top'>
								<h5>General Instructions</h5>
								<p>Section references are to the Internal Revenue Code. </p>
								<br></br>
								<h5>Future Developments</h5>
								<p>
									For the latest information about developments related to Form
									W-4, such as legislation enacted after it was published, go to{' '}
									<em>www.irs.gov/FormW4.</em>
								</p>
								<h5>Purpose of Form</h5>
								<p>
									Complete Form W-4 so that your employer can withhold the
									correct federal income tax from your pay. If too little is
									withheld, you will generally owe tax when you file your tax
									return and may owe a penalty. If too much is withheld, you
									will generally be due a refund. Complete a new Form W-4 when
									changes to your personal or financial situation would change
									the entries on the form. For more information on withholding
									and when you must furnish a new Form W-4, see Pub. 505, Tax
									Withholding and Estimated Tax.
								</p>
								<br></br>
								<p>
									<b>Exemption from withholding.</b> You may claim exemption
									from withholding for 2024 if you meet both of the following
									conditions: you had no federal income tax liability in 2023
									and you expect to have no federal income tax liability in
									2024. You had no federal income tax liability in 2023 if (1)
									your total tax on line 24 on your 2023 Form 1040 or 1040-SR is
									zero (or less than the sum of lines 27, 28, and 29), or (2)
									you were not required to file a return because your income was
									below the filing threshold for your correct filing status. If
									you claim exemption, you will have no income tax withheld from
									your paycheck and may owe taxes and penalties when you file
									your 2024 tax return. To claim exemption from withholding,
									certify that you meet both of the conditions above by writing
									“Exempt” on Form W-4 in the space below Step 4(c). Then,
									complete Steps 1(a), 1(b), and 5. Do not complete any other
									steps. You will need to submit a new Form W-4 by February 15,
									2025.
								</p>
								<br></br>
								<p>
									<b>Your privacy.</b> Steps 2(c) and 4(a) ask for information
									regarding income you received from sources other than the job
									associated with this Form W-4. If you have concerns with
									providing the information asked for in Step 2(c), you may
									choose Step 2(b) as an alternative; if you have concerns with
									providing the information asked for in Step 4(a), you may
									enter an additional amount you want withheld per pay period in
									Step 4(c) as an alternative.
								</p>
								<br></br>
								<p>
									<b>When to use the estimator.</b> Consider using the estimator
									at
									<em>www.irs.gov/W4App</em> if you:
								</p>
								<ol>
									<li>Expect to work only part of the year;</li>
									<li>
										Receive dividends, capital gains, social security, bonuses,
										or business income, or are subject to the Additional
										Medicare Tax or Net Investment Income Tax; or
									</li>
									<li>
										Prefer the most accurate withholding for multiple job
										situations.
									</li>
								</ol>
								<p>
									<b>Self-employment.</b> Generally, you will owe both income
									and self-employment taxes on any self-employment income you
									receive separate from the wages you receive as an employee. If
									you want to pay these taxes through withholding from your
									wages, use the estimator at www.irs.gov/W4App to figure the
									amount to have withheld
								</p>
								<br></br>
								<p>
									<b>Nonresident alien.</b> If you’re a nonresident alien, see
									Notice 1392, Supplemental Form W-4 Instructions for
									Nonresident Aliens, before completing this form.
								</p>
							</td>
							<td width={'50%'} valign='top'>
								<h5>Specific Instructions</h5>
								<p>
									<b>Step 1(c).</b> Check your anticipated filing status. This
									will determine the standard deduction and tax rates used to
									compute your withholding.
								</p>
								<br></br>
								<p>
									<b>Step 2.</b> Use this step if you (1) have more than one job
									at the same time, or (2) are married filing jointly and you
									and your spouse both work.{' '}
								</p>
								<br></br>
								<p>
									Option <b>(a) </b>most accurately calculates the additional
									tax you need to have withheld, while option <b>(b)</b> does so
									with a little less accuracy.
								</p>
								<br></br>
								<p>
									Instead, if you (and your spouse) have a total of only two
									jobs, you may check the box in option (c). The box must also
									be checked on the Form W-4 for the other job. If the box is
									checked, the standard deduction and tax brackets will be cut
									in half for each job to calculate withholding. This option is
									accurate for jobs with similar pay; otherwise, more tax than
									necessary may be withheld, and this extra amount will be
									larger the greater the difference in pay is between the two
									jobs.
								</p>
								<br></br>
								<div style={{ display: 'flex', gap: '10px' }}>
									<img src={QE} width={50} />
									<div>
										<b>Multiple jobs.</b> Complete Steps 3 through 4(b) on only
										one Form W-4. Withholding will be most accurate if you do
										this on the Form W-4 for the highest paying job.
									</div>
								</div>
								<br></br>
								<p>
									<b>Step 3.</b> This step provides instructions for determining
									the amount of the child tax credit and the credit for other
									dependents that you may be able to claim when you file your
									tax return. To qualify for the child tax credit, the child
									must be under age 17 as of December 31, must be your dependent
									who generally lives with you for more than half the year, and
									must have the required social security number. You may be able
									to claim a credit for other dependents for whom a child tax
									credit can’t be claimed, such as an older child or a
									qualifying relative. For additional eligibility requirements
									for these credits, see Pub. 501, Dependents, Standard
									Deduction, and Filing Information. You can also include other
									tax credits for which you are eligible in this step, such as
									the foreign tax credit and the education tax credits. To do
									so, add an estimate of the amount for the year to your credits
									for dependents and enter the total amount in Step 3. Including
									these credits will increase your paycheck and reduce the
									amount of any refund you may receive when you file your tax
									return.
								</p>
								<br></br>
								<p>
									<b>Step 4 (optional).</b>
								</p>
								<p>
									<b>
										<em>Step 4(a).</em>
									</b>{' '}
									Enter in this step the total of your other estimated income
									for the year, if any. You shouldn’t include income from any
									jobs or self-employment. If you complete Step 4(a), you likely
									won’t have to make estimated tax payments for that income. If
									you prefer to pay estimated tax rather than having tax on
									other income withheld from your paycheck, see Form 1040-ES,
									Estimated Tax for Individuals.
								</p>
								<br></br>
								<p>
									<b>
										<em>Step 4(b).</em>
									</b>{' '}
									Enter in this step the amount from the Deductions Worksheet,
									line 5, if you expect to claim deductions other than the basic
									standard deduction on your 2024 tax return and want to reduce
									your withholding to account for these deductions. This
									includes both itemized deductions and other deductions such as
									for student loan interest and IRAs.
								</p>
								<br></br>
								<p>
									<b>Step 4(c).</b> Enter in this step any additional tax you
									want withheld from your pay <b>each pay period,</b> including
									any amounts from the Multiple Jobs Worksheet, line 4. Entering
									an amount here will reduce your paycheck and will either
									increase your refund or reduce any amount of tax that you owe.
								</p>
							</td>
						</tr>
					</table>
				</div>
			</div>

			<div className={styles.contractFormBlock} id={CONTRACT_REF_ID.W4_SHORT_FORM_PART_3} style={{padding:'20px'}}>
				<div className={styles.bgWhite}>
					<table
						className={styles.contractForms}
						border={0}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
					>
						<tr>
							<td
								colSpan={2}
								align='center'
								style={{
									borderTop: '#d4caca solid 1px',
									borderBottom: '#d4caca solid 1px',
								}}
							>
								<p>
									<b style={{ fontSize: '20px' }}>
										Step 2(b)—Multiple Jobs Worksheet
									</b>{' '}
									(Keep for your records.)
								</p>
							</td>
						</tr>
						<tr>
							<td colSpan={2}>
								<br></br>
								<p>
									If you choose the option in Step 2(b) on Form W-4, complete
									this worksheet (which calculates the total extra tax for all
									jobs) on only
									<b>ONE</b> Form W-4. Withholding will be most accurate if you
									complete the worksheet and enter the result on the Form W-4
									for the highest paying job. To be accurate, submit a new Form
									W-4 for all other jobs if you have not updated your
									withholding since 2019.
								</p>
								<br></br>
								<p>
									<b>Note:</b> If more than one job has annual wages of more
									than $120,000 or there are more than three jobs, see Pub. 505
									for additional tables; or, you can use the online withholding
									estimator at www.irs.gov/W4App
								</p>
							</td>
						</tr>
						<tr>
							<td style={{ borderBottom: '#d4caca solid 1px' }}>
								<ol>
									<li value='1'>
										<b>Two jobs. </b>If you have two jobs or you’re married
										filing jointly and you and your spouse each have one job,
										find the amount from the appropriate table on page 4. Using
										the “Higher Paying Job” row and the “Lower Paying Job”
										column, find the value at the intersection of the two
										household salaries and enter that value on line 1. Then,{' '}
										<b>skip</b> to line 3 .
									</li>
								</ol>
							</td>
							<td valign='bottom' style={{ borderBottom: '#d4caca solid 1px' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ width: '40px' }}>
										<b>1</b> $
									</div>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW4Form.twoJobsAmount'
											render={({ field }) => (
												<>
													<NabuTextbox 
														{...field}
														onChange={e => {
															field.onChange(getAmountValue(e?.target?.value || ''));
														}}
														disabled={props?.isPrint}
													/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW4Form?.twoJobsAmount?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW4Form?.twoJobsAmount?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<ol style={{ margin: '0px' }}>
									<li value='2'>
										<b> Three jobs.</b> If you and/or your spouse have three
										jobs at the same time, complete lines 2a, 2b, and 2c below.
										Otherwise, skip to line 3.
									</li>
								</ol>
							</td>
							<td valign='bottom'></td>
						</tr>
						<tr>
							<td>
								<ul style={{ margin: '0px' }}>
									<li value='a'>
										Find the amount from the appropriate table on page 4 using
										the annual wages from the highest paying job in the “Higher
										Paying Job” row and the annual wages for your next highest
										paying job in the “Lower Paying Job” column. Find the value
										at the intersection of the two household salaries and enter
										that value on line 2a
									</li>
								</ul>
							</td>
							<td>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ width: '40px' }}>
										<b>2a</b> $
									</div>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW4Form.threeJobsAmount'
											render={({ field }) => (
												<>
													<NabuTextbox 
														{...field}
														onChange={e => {
															field.onChange(getAmountValue(e?.target?.value || ''));
														}}
														disabled={props?.isPrint}
													/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW4Form?.threeJobsAmount?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW4Form?.threeJobsAmount?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<ul style={{ margin: '0px' }}>
									<li value='b'>
										Add the annual wages of the two highest paying jobs from
										line 2a together and use the total as the wages in the
										“Higher Paying Job” row and use the annual wages for your
										third job in the “Lower Paying Job” column to find the
										amount from the appropriate table on page 4 and enter this
										amount on line 2b
									</li>
								</ul>
							</td>
							<td>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ width: '40px' }}>
										<b>2b</b> $
									</div>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW4Form.threeJobsAnnualWageAmount'
											render={({ field }) => (
												<>
													<NabuTextbox 
														{...field}
														onChange={e => {
															field.onChange(getAmountValue(e?.target?.value || ''));
														}}
														disabled={props?.isPrint}
													/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW4Form?.threeJobsAnnualWageAmount?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW4Form?.threeJobsAnnualWageAmount?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</div>
							</td>
						</tr>
						<tr>
							<td style={{ borderBottom: '#d4caca solid 1px' }}>
								<ul style={{ margin: '0px' }}>
									<li value='c'>
										Add the amounts from lines 2a and 2b and enter the result on
										line 2c
									</li>
								</ul>
							</td>
							<td style={{ borderBottom: '#d4caca solid 1px' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ width: '40px' }}>
										<b>2c</b> $
									</div>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW4Form.threeJobsTotalAmount'
											render={({ field }) => (
												<>
													<NabuTextbox 
														{...field}
														onChange={e => {
															field.onChange(getAmountValue(e?.target?.value || ''));
														}}
														disabled={props?.isPrint}
													/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW4Form?.threeJobsTotalAmount?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW4Form?.threeJobsTotalAmount?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</div>
							</td>
						</tr>
						<tr>
							<td style={{ borderBottom: '#d4caca solid 1px' }}>
								<ol>
									<li value='3'>
										Enter the number of pay periods per year for the highest
										paying job. For example, if that job pays weekly, enter 52;
										if it pays every other week, enter 26; if it pays monthly,
										enter 12, etc.
									</li>
								</ol>
							</td>
							<td style={{ borderBottom: '#d4caca solid 1px' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ width: '40px' }}>
										<b>3</b>
									</div>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW4Form.payPeriod'
											render={({ field }) => (
												<>
													<NabuTextbox 
														{...field}
														onChange={e => {
															field.onChange(getAmountValue(e?.target?.value || '', true));
														}}
														disabled={props?.isPrint}
													/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW4Form?.payPeriod?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW4Form?.payPeriod?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</div>
							</td>
						</tr>
						<tr>
							<td style={{ borderBottom: '#d4caca solid 1px' }}>
								<ol>
									<li value='4'>
										<b>Divide</b> the annual amount on line 1 or line 2c by the
										number of pay periods on line 3. Enter this amount here and
										in <b>Step 4(c)</b> of Form W-4 for the highest paying job
										(along with any other additional amount you want withheld)
									</li>
								</ol>
							</td>
							<td style={{ borderBottom: '#d4caca solid 1px' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ width: '40px' }}>
										<b>4</b>$
									</div>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW4Form.divideAmount'
											render={({ field }) => (
												<>
													<NabuTextbox 
														{...field}
														onChange={e => {
															field.onChange(getAmountValue(e?.target?.value || ''));
														}}
														disabled={props?.isPrint}
													/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW4Form?.divideAmount?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW4Form?.divideAmount?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</div>
							</td>
						</tr>
					</table>

					<table
						className={styles.contractForms}
						border={0}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
					>
						<tr>
							<td
								colSpan={2}
								align='center'
								style={{
									borderTop: '#d4caca solid 1px',
									borderBottom: '#d4caca solid 1px',
								}}
							>
								<p>
									<b style={{ fontSize: '20px' }}>
										Step 4(b)—Deductions Worksheet{' '}
									</b>{' '}
									<em>(Keep for your records.)</em>
								</p>
							</td>
						</tr>

						<tr>
							<td style={{ borderBottom: '#d4caca solid 1px' }}>
								<ol>
									<li value='1'>
										Enter an estimate of your 2024 itemized deductions (from
										Schedule A (Form 1040)). Such deductions may include
										qualifying home mortgage interest, charitable contributions,
										state and local taxes (up to $10,000), and medical expenses
										in excess of 7.5% of your income
									</li>
								</ol>
							</td>
							<td valign='bottom' style={{ borderBottom: '#d4caca solid 1px' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ width: '40px' }}>
										<b>1</b> $
									</div>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW4Form.estimateDeductionAmount'
											render={({ field }) => (
												<>
													<NabuTextbox 
														{...field}
														onChange={e => {
															field.onChange(getAmountValue(e?.target?.value || ''));
														}}
														disabled={props?.isPrint}
													/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW4Form?.estimateDeductionAmount?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW4Form?.estimateDeductionAmount?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</div>
							</td>
						</tr>
						<tr>
							<td style={{ borderBottom: '#d4caca solid 1px' }}>
								<ol style={{ margin: '0px' }}>
									<li value='2'>
										Enter:<br></br>
										<ul>
											<li>
												$29,200 if you’re married filing jointly or a qualifying
												surviving spouse
											</li>
											<li>$21,900 if you’re head of household</li>
											<li>
												$14,600 if you’re single or married filing separately
											</li>
										</ul>
									</li>
								</ol>
							</td>
							<td valign='bottom' style={{ borderBottom: '#d4caca solid 1px' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ width: '40px' }}>
										<b>2</b> $
									</div>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW4Form.fixedDeductionAmount'
											render={({ field }) => (
												<>
													<NabuSelect 
														{...field}
														placeholder='Select Amount'
														options={[
															{ label: '29,200', value: 29200},
															{ label: '21,900', value: 21900},
															{ label: '14,600', value: 14600},
														]}
														disabled={props?.isPrint}
													/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW4Form?.fixedDeductionAmount?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW4Form?.fixedDeductionAmount?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</div>
							</td>
						</tr>
						<tr>
							<td style={{ borderBottom: '#d4caca solid 1px' }}>
								<ol>
									<li value='2'>
										If line 1 is greater than line 2, subtract line 2 from line
										1 and enter the result here. If line 2 is greater than line
										1, enter “-0-”
									</li>
								</ol>
							</td>
							<td style={{ borderBottom: '#d4caca solid 1px' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ width: '40px' }}>
										<b>3</b> $
									</div>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW4Form.estimateFixedDifferenceAmount'
											render={({ field }) => (
												<>
													<NabuTextbox 
														{...field}
														onChange={e => {
															field.onChange(getAmountValue(e?.target?.value || ''));
														}}
														disabled={props?.isPrint}
													/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW4Form?.estimateFixedDifferenceAmount?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW4Form?.estimateFixedDifferenceAmount?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</div>
							</td>
						</tr>
						<tr>
							<td style={{ borderBottom: '#d4caca solid 1px' }}>
								<ol>
									<li value='3'>
										Enter an estimate of your student loan interest, deductible
										IRA contributions, and certain other adjustments (from Part
										II of Schedule 1 (Form 1040)). See Pub. 505 for more
										information
									</li>
								</ol>
							</td>
							<td style={{ borderBottom: '#d4caca solid 1px' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ width: '40px' }}>
										<b>4</b> $
									</div>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW4Form.otherDeductionAmount'
											render={({ field }) => (
												<>
													<NabuTextbox 
														{...field}
														onChange={e => {
															field.onChange(getAmountValue(e?.target?.value || ''));
														}}
														disabled={props?.isPrint}
													/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW4Form?.otherDeductionAmount?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW4Form?.otherDeductionAmount?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</div>
							</td>
						</tr>
						<tr>
							<td style={{ borderBottom: '#d4caca solid 1px' }}>
								<ol>
									<li value='c'>
										<b>Add</b> lines 3 and 4. Enter the result here and in{' '}
										<b>Step 4(b) </b>of Form W-4 . .
									</li>
								</ol>
							</td>
							<td style={{ borderBottom: '#d4caca solid 1px' }}>
								<div style={{ display: 'flex', alignItems: 'center' }}>
									<div style={{ width: '40px' }}>
										<b>5</b> $
									</div>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW4Form.otherDeductionDifferenceAmount'
											render={({ field }) => (
												<>
													<NabuTextbox 
														{...field}
														onChange={e => {
															field.onChange(getAmountValue(e?.target?.value || ''));
														}}
														disabled={props?.isPrint}
													/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW4Form?.otherDeductionDifferenceAmount?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW4Form?.otherDeductionDifferenceAmount?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</div>
							</td>
						</tr>
					</table>
				</div>
			</div>

			{/* <div className={styles.contractFormBlock}>
				<div className={styles.bgWhite}>
					<img src={Table} width={'100%'} />
				</div>
			</div> */}
		</>
	);
};

export default ContractW4ShortForm;
