import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { IContractContext, IContractContextFormState } from './types';


const initialState: IContractContext = {
	formState: {}
};

export const contractContextSlice = createSlice({
	name: 'contractContext',
	initialState,
	reducers: {		
		updateContractFormState: (state, action: PayloadAction<IContractContextFormState>) => {
			state.formState = action.payload;
		}
	}
});

export const { updateContractFormState } = contractContextSlice.actions;
export default contractContextSlice.reducer;