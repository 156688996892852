import { ILookAheadSelectOption } from '../../../staff/shared/models/staffModel';

export class MemberFormModel{
	companyId?:string='';
	name:ILookAheadSelectOption=new ILookAheadSelectOption();
	phoneNumber?:PhoneNumberFormModel=new PhoneNumberFormModel();
	email?:EmailFormModel=new EmailFormModel();
	addNewEmail?:boolean=false;
	addNewPhoneNumber?:boolean=false;
	personId?:string='';
	emailId?:string='';
	phoneNumberId?:string='';
}

export class PhoneNumberFormModel {
	countryCode?: string = '1';
	number?: string = '';
	extension?: string = '';
	phoneNumberLabel: string = '';
	isPrimary?: boolean = false;
	id?:string='';
}

export class EmailFormModel {
	emailId?: string = '';
	emailLabel?: string = '';
	id?:string='';
	isPrimary?:boolean=false;
	constructor(isPrimary:boolean=false){
		this.isPrimary=isPrimary;
	}
}