import { useEffect, useState } from 'react';

import NabuButton from '@/common/components/atoms/button/button';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import { CompanyMainType, ICompanyAddress } from '@/common/types/company';
import {
	IBaseContract,
	ICommercialDetail,
	IContractSagAftraTvExtraHTForm
} from '@/common/types/contract';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import { IPersonAddress, IPersonRecord } from '@/services/models/person';
import { Box, FormControl, Tooltip } from '@mui/material';
import { UseFormReturn, useForm } from 'react-hook-form';

import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import LookAheadSelect, { ILookAheadSelectOption } from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';

import { NabuBaseTextArea } from '@/common/components/atoms/textArea/textArea';
import NabuTimePicker from '@/common/components/molecules/timepicker/timePicker';
import AddAssetInline from '@/common/components/templates/pages/add/addAssetInline';
import AddCompanyAddressInline from '@/common/components/templates/pages/add/addCompanyAddressInline';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import AddEmailInline from '@/common/components/templates/pages/add/addEmailInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonInline from '@/common/components/templates/pages/add/addPersonInline';
import AddPhoneNumberInline from '@/common/components/templates/pages/add/addPhoneNumberInline';
import AddTalentAgentInline from '@/common/components/templates/pages/add/addTalentAgentInline';
import { ContractStatus, DefaultPageSize, SagAftraExhibit2PerformerTypeOptions } from '@/common/constants/constants';
import { OPERATORS } from '@/common/enums';
import { StatusCodes } from '@/common/enums/StatusCodes';
import { ICompanyRecord } from '@/common/types/company';
import { FilterModel, FilterOption, IFilterOption } from '@/common/types/filterModel';
import { addressShortner, displayAddress, displayOnlyEmail, displayPhoneNumberWithoutLabel, getCompanyDisplayName, getLocaleDate, getLocaleDateTime, getNotificationText, getPersonFullName, sortEmailOrPhoneNumberForDropDown } from '@/common/utils/utils';
import { EmailFormModel, PhoneNumberFormModel } from '@/modules/company/shared/models/company';
import { getAllAssetForBasicDetail, getAllSignatory, retriveAssetProducts } from '@/services/asset';
import { createCompanyAddressPhoneNumber, getAllAddressByCompany, getAllProducts, getCompanyBasicDetails } from '@/services/company';
import { addContractSagAftraTvExtraHT, updateContractSagAftraTvExtraHT } from '@/services/contract';
import { IAssetRecord } from '@/services/models/asset';
import { GetByWithFullNameSearchForBasicDetail, createPersonEmail, createPersonPhoneNumber, getAddressBasedOnPinCode, getAgentInfo, getAllAddressByPerson } from '@/services/person';
import store from '@/store';
import { setDisableLoader } from '@/store/app/reducer';
import { updateContractFormState } from '@/store/contract/reducer';
import { yupResolver } from '@hookform/resolvers/yup';
import { Add, DeleteForever, HelpOutline } from '@mui/icons-material';
import {
	FormHelperText,
	Grid
} from '@mui/material';
import { HttpStatusCode } from 'axios';
import { City, State } from 'country-state-city';
import { addDays } from 'date-fns';
import { Controller, useFieldArray } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { SagAftraTvExtraHTSchema } from '../contarctFormSchema';
import ContractMutationWrapper from '../contractMutationWrapper/contractMutationWrapper';
import useContractMutation from '../contractMutationWrapper/useContractMutation';
import LocationFreeTextSelect from './locationFreeTextSelect';
import { IContractFormState } from './standardSagAftraEmployment2022';

interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	initialValue?: IContractSagAftraTvExtraHTForm;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	defaultValue?: IBaseContract | any;
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
	assets?: IAssetRecord[];
	isDuplicateContractName?: boolean;
}

const SagAftraTvExtraHT = (props: Props) => {
	// const { scroll } = useScrollToField();
	// const [params, setParams] = useSearchParams();

	const { 
		handlePreContractSubmissionTasks, 
		addMultipleContractDetails, 
		notification, 
		setPacketFormState, 
		params, 
		setParams, 
		onSubmitError ,
		// ADVERTISER_FILTER, 
		// AD_AGENCY_FILTER, 
		DEFAULT_COUNTRY_CODE, 
	} = useContractMutation<IContractSagAftraTvExtraHTForm>();

	useEffect(() => {
		if(props?.defaultValue?.advertiserId)
			contractForm.setValue('advertiserId', props?.defaultValue?.advertiserId, { shouldDirty: true, shouldValidate: true});
		if(props?.defaultValue?.productId)
			contractForm.setValue('productId', props?.defaultValue?.productId, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.advertiserId]);

	useEffect(() => {
		contractForm.setValue('isJPCAuthorizer', props?.defaultValue?.isJPCAuthorizer, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.isJPCAuthorizer]);

	const NON_EVENING_WEAR_COST = 20.36;
	const EVENING_WEAR_COST = 33.91;

	const contractForm = useForm<IContractSagAftraTvExtraHTForm>({
		defaultValues: !props?.initialValue
			? {
				...(props?.mainForm?.getValues() || {}),
				...(props?.defaultValue || {}),
				workDays: [{}],
				commercials: [{}],
			}
			: {...(props?.initialValue || {}), 
				commercials: props?.initialValue?.commercials?.length ? props?.initialValue?.commercials : [{}],
				workDays: props?.initialValue?.workDays?.length ? props?.initialValue?.workDays : [{}],
			},
		resolver: yupResolver(SagAftraTvExtraHTSchema),
		shouldFocusError: false
	});

	const [companyOptions, setCompanyOptions] = useState<ICompanyRecord[]>([]);
	const [personOptions, setPersonOptions] = useState<IPersonRecord[]>(
		props?.people || []
	);
	const [assetOptions, setAssetOptions] = useState<IAssetRecord[]>(props?.assets || []);
	const [addressOptions, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [selectedAddress, setSelectedAddress] = useState<{id:any, type:any}>();
	const [contractFormState, setContractFormState] =
		useState<IContractFormState>({});
	const [addressPopOver, setAddressPopOver] = useState<IPopOverState>({
		isOpen: false,
	});
	const [companyPopoverInline, setCompanyPopoverInline] = useState<IPopOverState>({
		isOpen: false,
	});

	const workDayFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'workDays',
	});

	const commercialFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'commercials',
	});

	const AD_AGENCY_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'AD AGENCY',
		},
	];

	const ADVERTISER_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'ADVERTISER',
		},
	];

	const PRODUCT_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.NOT_EQUAL,
			property: 'ProductName',
			value: '',
		},
	];

	// helper functions
	const getCommercialFilters = () => {
		const assetFilter: IFilterOption[] = [
			{
				isCaseSensitive: false,
				operator: OPERATORS.NOT_EQUAL,
				property: 'Title',
				value: '',
			},
			{
				isCaseSensitive: false,
				operator: OPERATORS.IN,
				property: 'Advertisers.Id',
				value: JSON.stringify([contractForm.watch('advertiserId.value')]),
			},
		];
		return assetFilter;
	};

	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	const onAddWorkDay = () => {
		if (workDayFieldArray.fields.length) {
			const previousRow = {
				...contractForm.watch(
					`workDays.${workDayFieldArray.fields.length - 1}`
				),
				id: undefined,
			};

			workDayFieldArray.append({
				...previousRow,
				date: previousRow?.date ? addDays(new Date(previousRow?.date || ''), 1)?.toISOString() : '',
			});
		}
	};

	const onRemoveWorkDay = (index: number) => {
		if (workDayFieldArray.fields.length > 1) {
			workDayFieldArray.remove(index);
		}
	};
	
	const onAddCommercial = () => {
		commercialFieldArray.append({} as ICommercialDetail);
	};

	const onAddressChange = (addressId: string, field: 'performer' | 'performerAdAgency') => {
		setSelectedAddress({type: field, id: addressId});
		const address = [...addressOptions, ...(props?.address || [])]?.find( ad => ad?.id === addressId);
		
		if(address) {
			contractForm.setValue(`${field}Zip`, address?.zipCode, {shouldDirty: true});
			contractForm.setValue(`${field}State`, address?.state, {shouldDirty: true});
			contractForm.setValue(`${field}City`, address?.city?.toUpperCase(), {shouldDirty: true});
		} else {
			contractForm.setValue(`${field}State`, '', {shouldDirty: true});
			contractForm.setValue(`${field}Zip`, '', {shouldDirty: true});
			contractForm.setValue(`${field}City`, '', {shouldDirty: true});
		}
	};

	const handleStateOptions = (
		responseData: any[],
		type: 'COMPANY' | 'PERSON' | 'ASSET' | 'ADDRESS'
	) => {
		const cb = {
			COMPANY: setCompanyOptions,
			PERSON: setPersonOptions,
			ASSET: setAssetOptions,
			ADDRESS: setAddressOptions,
		}[type];

		if (responseData && Array.isArray(responseData)) {
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);

				return [...oldFilter, ...responseData];
			});
		}
	};

	useEffect(() => {
		if(selectedAddress?.type !== ''){
			onAddressChange(selectedAddress?.id, selectedAddress?.type);
		}
	}, [addressOptions]);

	const setJPCAuthorizer = () => {
		const adAgency = !!companyOptions?.find(
			(d) => d?.id === contractForm.watch('adAgencyId.value')
		)?.isJPCAuthorizer;
		const advertiser = !!companyOptions?.find(
			(d) => d?.id === contractForm.watch('advertiserId.value')
		)?.isJPCAuthorizer;

		contractForm.setValue('isJPCAuthorizer', adAgency || advertiser, {
			shouldDirty: true,
		});
	};
	
	const getAssetAdIdByAssetIndex = (index: number) => {		
		const assetId = contractForm.watch(`commercials.${index}.assetId`)?.value;
		return assetOptions?.find((d) => d?.id === assetId)?.adId || '';
	};

	const getStateOptions = () => {
		const states = State.getStatesOfCountry(DEFAULT_COUNTRY_CODE).map(
			(state) => ({ label: state.name?.toUpperCase(), value: state.isoCode })
		);
		if (!states.length) states.unshift({ label: 'NA', value: 'NA' });
		return states;
	};

	const getCityOptions = (stateCode?: string) =>
		stateCode
			? City.getCitiesOfState(DEFAULT_COUNTRY_CODE, stateCode)?.map((city) => ({
				label: city.name?.toUpperCase(),
				value: city.name?.toUpperCase(),
			})) || []
			: [];

	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		handleStateOptions(responseData, 'COMPANY');

		return responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};


	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const addressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');
		
		return responseData?.map((d) => ({
			value: d?.id,
			label: addressShortner(d),
		}));
	};
	const agentAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');
		
		return responseData?.map((d) => ({
			value: d?.id,
			label: addressShortner(d),
		}));
	};

	const adAgencyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const productOptionGenerator = (responseData) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: d?.productName?.toUpperCase(),
		}));
	};

	const lookAheadAssetOptionGenerator = (responseData: IAssetRecord[]) => {
		handleStateOptions(responseData, 'ASSET');

		return responseData?.map((d) => ({
			value: d?.id || '',
			label: d?.title || '',
		}));
	};

	const onZipCodeChange = (zipCode: string, field: 'performer' | 'performerAdAgency') => {
		if (zipCode) {
			store.dispatch(setDisableLoader(true));
			getAddressBasedOnPinCode(zipCode)
				.then((res) => {
					if (res?.data?.['country abbreviation'] === 'US') {
						if(res.data){
							const place = res.data.places[0];
							contractForm.setValue(`${field}State`, place['state abbreviation'], { shouldDirty: true });
							contractForm.setValue(`${field}City`, place['place name']?.toUpperCase() ?? '', { shouldDirty: true });
						}
					}
				})
				.catch((e) => console.error(e?.message))
				.finally(() => {
					store.dispatch(setDisableLoader(false));
				});
		}
	};

	const getPhoneNumberOptions = (addressId: string = '') => {
		const unsortedPhoneNumbers =
			addressOptions?.find((a) => a?.id === addressId)?.phoneNumbers || [];

		const phoneNumbers = sortEmailOrPhoneNumberForDropDown(unsortedPhoneNumbers);
		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const getPerformerPhoneNumberOptions = () => {
		const unsortedPhoneNumbers =
			[...(personOptions || []), ...(props?.people || [])]?.find((a) => a?.id === contractForm.watch('performerId.value'))?.phoneNumbers || [];
		
		const phoneNumbers = sortEmailOrPhoneNumberForDropDown(unsortedPhoneNumbers);
		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const getPerformerEmailsOptions = () => {
		const unsortedEmails =
			[...(personOptions || []), ...(props?.people || [])]?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.emails || [];
	
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
			
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const calculateTotalWardrobeFee = () => {
		const getNumber = (value: number | string | undefined): number =>
			isNaN(Number(value)) ? 0 : Number(value);

		const eveningWearCost =
			getNumber(contractForm.watch('eveningWearCount')) * EVENING_WEAR_COST;
		const nonEveningWearCost =
			getNumber(contractForm.watch('nonEveningWearCount')) *
			NON_EVENING_WEAR_COST;

		contractForm.setValue(
			'totalWardrobeFee',
			parseFloat((eveningWearCost + nonEveningWearCost).toFixed(4)),
			{ shouldDirty: true }
		);
	};

	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};

	const { mutate: addContractDetails, isLoading: isAddingContract } =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addContractSagAftraTvExtraHT(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const { mutate: editContractDetails, isLoading: isEditingContract } =
		useMutation({
			mutationKey: ['contract-edit'],
			mutationFn: (payload: any) => updateContractSagAftraTvExtraHT(
				props?.contractId || '',
				payload
			),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'UPDATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const handleContractSubmission = async (
		submitData: IContractSagAftraTvExtraHTForm,
		handleMultipleContractAdd?: CallableFunction
	) => {
		handlePreContractSubmissionTasks(submitData, (data) => {
			const commercials = data?.commercials?.map((d) => ({
				...d,
				assetId: d?.assetId?.value,
			}));
			const workDays = data?.workDays?.filter((d) => d?.date) || [];
	
			const mainFormValues = props?.mainForm?.getValues();	
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};
	
			const submitData = {
				...data,
				...addMainFormValues,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value,
				contractSagAftraTvExtraHT: undefined,
				payrollVendorId: data?.payrollVendorId?.value,
				performerId: data?.performerId?.value,
				advertiserId: data?.advertiserId?.value,
				returnToEmail: data?.returnToEmail?.value,
				adAgencyId: data?.adAgencyId?.value,
				adAgencyAddressId: data?.adAgencyAddressId?.value,
				productId: data?.productId?.map(product => product?.value),
				performerAddressId: data?.performerAddressId?.value,
				talentAgencyId: data?.talentAgencyId?.value,
				performerAdAgencyId: data?.performerAdAgencyId?.value,
				performerAdAgencyAddressId: data?.performerAdAgencyAddressId?.value,
				producerCompanyId: data?.producerCompanyId?.value,
				commercials,
				workDays
			};			
	
			if (!props?.isEdit){
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			}
			else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 
		});

	};


	const onSubmit = (data: IContractSagAftraTvExtraHTForm) => {
		if(props?.isDuplicateContractName){
			notification.notify({
				message: 'Contract with the same name already exists',
				type: 'error',
			});
			return;
		}
		handleContractSubmission(data);
	};


	const [producerEmails,setProducerEmails]=useState([]);
	useQuery({
		queryKey: ['contract-union', 'getBy', contractForm.watch('producerCompanyId.value') ?? ''],
		queryFn: () => getAllAddressByCompany(new FilterModel([new FilterOption('true', 'IsPrimary')]), contractForm.watch('producerCompanyId.value') ?? ''),
		refetchOnWindowFocus: false,
		enabled: !!contractForm.watch('producerCompanyId.value'),
		onSuccess: (res) => {
			if(res?.data?.records?.length){
				const producerEmails = res?.data?.records[0]?.emails;
				const emails = sortEmailOrPhoneNumberForDropDown(producerEmails);
				setProducerEmails(emails?.map((e) => ({
					label: displayOnlyEmail(e) || '-',
					value: e?.id || '',
				})) || []);
			}
			else {
				return (
					setProducerEmails([])
				);
			}
		},
		onError: () => {
			return (
				setProducerEmails([])
			);
		},
	});
	
	const [contractFormData,setContractFormData ] = useState<IContractSagAftraTvExtraHTForm>();

	const handleTalentDetailsForm = (data: any) => {
		const handleMultipleContractAdd = (payloadData) => {
			if(data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						status:ContractStatus?.Draft,
						jobTitle: talent?.role,
						performerAdAgencyId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${index+1}`,
						compensation: talent?.compensation,
						performerTypes:talent?.perfCategory?.toUpperCase(),
						performerAddressId:undefined,
						performerEmailId:undefined,
						performerPhoneId:undefined,
						performerAdAgencyAddressId:undefined,
						performerAdAgencyZip:undefined,
						performerAdAgencyCity:undefined,
						performerAdAgencyState:undefined,
						performerAdAgencyPhoneId:undefined
					};
					return submitData;
				});
				const multipleContractData = {
					ContractAddSagAftraTvExtraHTModels: contractAddModels
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData && handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};


	const handleSaveAndDuplicate = (data: IContractSagAftraTvExtraHTForm) => {
		if(props?.isDuplicateContractName){
			notification.notify({
				message: 'Contract with the same name already exists',
				type: 'error',
			});
			return;
		}
		else{
			// setNoOfTalentsSelected(true);
			setContractFormData(data);
		}
	};

	useEffect(()=> {
		if(props?.isEdit && commercialFieldArray.fields.length === 0){
			onAddCommercial();
		}
	}, [props?.isEdit]);

	const onAdAgencyAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('adAgencyId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('adAgencyAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const PerformerAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('performerId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('performerAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const PerformerAdAgencyAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('performerAdAgencyId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('performerAdAgencyAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const [phoneNumberPopOver, setPhoneNumberInlinePopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const closePhoneNumberInlinePopOver = () => {
		setPhoneNumberInlinePopOver({ isOpen: false });
	};

	const openPhoneNumberInlinePopOver = (e: React.MouseEvent<HTMLButtonElement | null>, key?: string) => {
		setPhoneNumberInlinePopOver({ isOpen: true, event: e?.currentTarget, for: key });
	};

	const onPerformerPhoneNumberInlineSubmit = (formData: any) => {
		const personId = contractForm.watch('performerId.value') || '';
		createPersonPhoneNumber({ personId, phoneNumber: {...formData, isPrimary: true} }).then((res: any) => {
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Phone Number', 'CREATE'),
					type: 'success',
				});
				closePhoneNumberInlinePopOver();

				setPersonOptions(prev => (
					prev?.map(pn => (pn?.id == contractForm.watch('performerId.value') ? ({
						...pn,
						phoneNumbers: [
							{ id: res?.data?.id, ...formData },
							...(pn?.phoneNumbers || {}) as any
						]
					}) : pn))
				));	
				
				contractForm.trigger('performerId');
				contractForm.trigger('performerId.value');
				contractForm.setValue('performerPhoneId', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Phone Number.',
					type: 'error',
				});
			}
		});
	};

	const onPhoneNumberInlineSubmit = (formData: any) => {
		const addressId = contractForm.watch('performerAdAgencyAddressId.value') || '';
		createCompanyAddressPhoneNumber({ addressId, phoneNumber: {...formData, isPrimary: true} }).then((res: any) => {
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Phone Number', 'CREATE'),
					type: 'success',
				});
				closePhoneNumberInlinePopOver();
				setAddressOptions((prev) =>
					prev.map((ad) =>
						ad.id === contractForm.watch('performerAdAgencyAddressId.value')
							? {
								...ad,
								phoneNumbers: [
									{ id: res?.data?.id, ...formData },
									...(ad?.phoneNumbers || {}),
								],
							}
							: ad
					)
				);
				contractForm.setValue('performerAdAgencyPhoneId', res.data.id);
				contractForm.trigger('performerAdAgencyAddressId', { shouldFocus: true });
			}
			else {
				notification.notify({
					message: 'Error While Adding Phone Number.',
					type: 'error',
				});
			}
		});
	};

	const [emailPopOver, setEmailInlinePopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const openEmailInlinePopOver = (e: React.MouseEvent<HTMLButtonElement | null>, key?: string) => {
		setEmailInlinePopOver({ isOpen: true, event: e?.currentTarget, for: key });
	};

	const closeEmailInlinePopOver = () => {
		setEmailInlinePopOver({ isOpen: false });
	};

	const onPerformerEmailInlineSubmit = (formData: any) => {
		const personId = contractForm.watch('performerId.value') || '';
		createPersonEmail({personId, Email: {...formData, isPrimary: true}}).then((res: any) =>{
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Email', 'CREATE'),
					type: 'success',
				});
				closeEmailInlinePopOver();

				setPersonOptions(prev => (
					prev?.map(em => (em?.id == contractForm.watch('performerId.value') ? ({
						...em,
						emails: [
							{ id: res?.data?.id, ...formData },
							...(em?.emails || {}) as any
						]
					}) : em))
				));	

				contractForm.trigger('performerId');
				contractForm.trigger('performerId.value');
				contractForm.setValue('performerEmailId', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Email Address.',
					type: 'error',
				});
			}
		});
	};

	const handleCommercialChange = async (id: string) => {
		const filterModel = {
			pageSize: DefaultPageSize,
			sortDescending: false,
			sortField: 'CreatedAt',
			pageNumber: 0,
			filters: [{
				property: 'Asset.Id',
				value: id,
				operator: OPERATORS.EQUAL,
				isCaseSensitive: false
			}]
		};

		const res: any = await retriveAssetProducts(filterModel);

		if(res?.data?.records){
			const existingProducts: ILookAheadSelectOption[] = contractForm.getValues('productId') ?? [];
			let products: any = res?.data?.records?.map(product => {
				return { value: product.id, label: product.productName, assetId: id };
			});

			products = products.filter(product => {
				const foundProduct = existingProducts.find(eProduct => eProduct.value === product.value);
				if(foundProduct) return false;
				else return true;
			});

			contractForm.setValue('productId', [...products, ...existingProducts], { shouldDirty: true, shouldValidate: true });
		}
	};
	
	const handleProducerChange = (index ?: any)=>{
		if(index ==0){
			contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
			// setProducerEmails([]);
			contractForm.setValue('producerPrintName','',{shouldDirty:true});
		}	
		if(contractForm.watch(`commercials.${0}.assetId.value`)?.length == 0){
			contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
			// setProducerEmails([]);
			contractForm.setValue('producerPrintName','',{shouldDirty:true});
		}
	};

	return (
		<>
			<ContractMutationWrapper
				isEdit={props?.isEdit}
				isPrint={props?.isPrint}
				contractForm={contractForm}
				contractFormState={contractFormState}
				setContractFormState={setContractFormState}
				handleFormClose={handleFormClose}
				handleSaveAndDuplicate={handleSaveAndDuplicate}
				handleTalentDetailsForm={handleTalentDetailsForm}
				isAddingContract={isAddingContract}
				isEditingContract={isEditingContract}
				onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}
				setPacketFormState={setPacketFormState}
				performerCategory={SagAftraExhibit2PerformerTypeOptions}
				isUnion={true}
				mainForm={props?.mainForm}
				isDuplicateContractName={props.isDuplicateContractName}
			>
				<div className={styles.contractFormBlock}>
					<div className={styles.bgWhite} style={{padding:'20px'}}>
						<Grid item container columnSpacing={3} sx={{ alignItems: 'center',}}>
							<Grid item md={6} >
								<h4>{contractForm.watch('contractId') ? `#${contractForm.watch('contractId')}` : ''}</h4>	
								<Box sx={{pt:4}}>
									<h2 style={{textAlign:'left'}}>STANDARD SAG-AFTRA EMPLOYMENT CONTRACT FOR PERFORMERS
										ENGAGED AS EXTRAS IN COMMERCIALS </h2>
									<h4 style={{textAlign:'left'}}>EXHIBIT A-2	</h4>
								</Box>
							</Grid>
							<Grid item md={6}>
								<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
									<tr>
										<td>Ad Agency</td>
										<td>
											<FormControl
												fullWidth
												className={styles.tableForms}
											>
												<Controller
													control={contractForm.control}
													name='adAgencyId'
													render={({ field }) => (
														<>
															<LookAheadSelect
																data-cy='ad_agency'
																{...field}
																placeholder={!props?.isPrint ? 'Ad-Agency' : ''}
																filterProperty='CompanyNames.Name'
																APICaller={getCompanyBasicDetails}
																optionGenerator={
																	companyOptionGenerator
																}
																defaultFilter={AD_AGENCY_FILTER}
																isClearable={true}
																isDisabled={props?.isPrint}
																onChange={(e: any) => {
																	field.onChange(e);
																	setJPCAuthorizer();
																	contractForm.setValue(
																		'adAgencyAddressId', null, { shouldDirty: true}
																	);
																	contractForm.trigger('adAgencyAddressId');
																}}
																cacheOptions
																defaultOptions
																className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
															/>
															{!props?.isPrint && (
																<Box sx={{mt:0.5}}>
																	<AddCompanyInline
																		title='Company'
																		companyType={{ mainType: 'AD AGENCY' as CompanyMainType, subType: [] }} />
																</Box>
															)}
														</>
													)}
												/>
											</FormControl>
										</td>
									</tr>
									<tr>
										<td>Producer{ !props?.isPrint && (
											<Tooltip
												placement='right-end'
												title={'Signatory'}
											>
												<HelpOutline fontSize='inherit' />
											</Tooltip>)
										}</td>
										<td> 
											<FormControl
												fullWidth
												className={styles.tableForms}
												
											>
												<Controller
													key={`commercial-assetId
													-${contractForm.watch(`commercials.${0}.assetId.value`)}
													`}
													control={contractForm.control}
													name='producerCompanyId'
													render={({ field }) => (
														<>
															<LookAheadSelect
																key={`commercial-assetId
																-${contractForm.watch(`commercials.${0}.assetId.value`)}
																`}
																{...field}
																placeholder={!props?.isPrint ? 'Producer' : ''}
																filterProperty='CompanyNames.Name'
																APICaller={(filterModel) => getAllSignatory(contractForm.watch(`commercials.${0}.assetId.value`),filterModel)}
																optionGenerator={companyOptionGenerator}
																isClearable={true}
																onChange={(e: any) => {
																	field.onChange(e);
																	contractForm.setValue('producerCompanyEmail', '', { shouldDirty: true});
																	contractForm.setValue('producerCompanyPhone', '', { shouldDirty: true});
																	contractForm.trigger('producerCompanyEmail');
																	contractForm.trigger('producerCompanyPhone');
																	contractForm.setValue('producerPrintName',contractForm.watch('producerCompanyId.label'),{shouldDirty:true});
																}}
																isDisabled={props?.isPrint}
																cacheOptions
																defaultOptions
																className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
															/>
															{/* {!props?.isPrint && (
																<Box sx={{mt:0.5}}>
																	<AddCompanyInline
																		title='Company'
																	/>
																</Box>
															)} */}
														</>
													)}
												/>
											</FormControl>
										</td>
									</tr>
									<tr>
										<td>Product</td>
										<td>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<Controller
													control={contractForm.control}
													name='productId'
													render={({ field }) => (
														<LookAheadSelect
															{...field}
															placeholder={!props?.isPrint ? 'Select Product' : ''}
															key={`product-${contractForm.watch(
																'advertiserId.value'
															)}`}
															isMulti
															filterProperty='ProductName'
															APICaller={(filterModel) =>
																getAllProducts(
																	filterModel,
																	contractForm.watch('advertiserId.value')
																)
															}
															optionGenerator={productOptionGenerator}
															isDisabled={
																!contractForm.watch('advertiserId.value') ||
																			props?.isPrint
															}
															defaultFilter={PRODUCT_FILTER}
															dropDownsize={20}
															isClearable={true}
															onChange={(e: any) => {
																field.onChange(e);
															}}
															cacheOptions={false}
															defaultOptions
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
													)}
												/>
											</FormControl>
										</td>
									</tr>
								</table>
								<td
									align='right'
									style={{
										display: 'flex',
										justifyContent: 'flex-end',
									}}
								>
									<div style={{ display: 'flex' }}>
										<Controller
											control={contractForm.control}
											name='isJPCAuthorizer'
											render={({ field }) => (
												<Box display={'flex'} alignItems={'flex-start'}>
													<input
														{...field}
														key={`jpc-${contractForm.watch(
															'adAgencyId.value'
														)}-${contractForm.watch(
															'advertiserId.value'
														)}`}
														value={`${field.value}`}
														checked={field.value || false}
														onChange={undefined}
														type='checkbox'
														style={{
															marginTop: '3px',
														}}
													/>
													<span>
																						JPC Authorizer Agency or Advertiser
													</span>
												</Box>
											)}
										/>
									</div>
								</td>
							</Grid>
						</Grid>
						<hr></hr>
						<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
							<tr>
								<td width={50}>Job #</td>
								<td width={250}>
									<FormControl
										fullWidth
										className={styles.tableForms}
									>
										<Controller
											control={contractForm.control}
											name='jobTitle'
											defaultValue=''
											render={({ field }) => (
												<NabuTextbox
													data-cy='job_name_textbox'
													{...field}
													disabled={props?.isPrint}
													placeholder={!props?.isPrint ? 'Job #' : ''}
												/>
											)}
										/>
									</FormControl>
								</td>
								<td width={250}>COMMERCIAL TITLE(S) AND Ad-ID®(s)<sup>*</sup></td>
								<td>
									{/* <FormControl fullWidth className={styles.tableForms}><NabuTextbox placeholder='Enter COMMERCIAL TITLE(S) AND Ad-ID®(s)'/></FormControl>  */}
									{commercialFieldArray.fields.map((field, index) => (
										<div key={`commercial-${field.id}`}>
											<FormControl
												fullWidth
												className={styles.tableForms}
											>
												<Controller
													control={contractForm.control}
													name={`commercials.${index}.assetId`}
													render={({ field }) => (
														<>
															<LookAheadSelect
																data-cy='source-asset'
																{...field}
																key={`commercial-${contractForm.watch(`commercials.${index}.assetId`)}`}
																placeholder={!props?.isPrint ? 'Commercial' : ''}
																filterProperty='Title'
																isDisabled={props?.isPrint || !contractForm.watch('advertiserId.value')}
																defaultFilter={getCommercialFilters()}
																APICaller={(filterModel) => getAllAssetForBasicDetail(filterModel, false)}
																optionGenerator={
																	lookAheadAssetOptionGenerator
																}
																onChange={(e: any) => { handleCommercialChange(e?.value), field.onChange(e);
																	contractForm.setValue('commercialTitle','', { shouldDirty: true });
																	contractForm.setValue('numberOfCommercials',0, { shouldDirty: true });
																	handleProducerChange(index);	
																}}
																isClearable={true}
																cacheOptions
																defaultOptions
																dropDownsize={25}
																className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
															/>
															{!props?.isPrint && (
																<Box sx={{mt: 1}}>
																	<AddAssetInline
																		title='Asset' />
																</Box>
															)}
														</>
													)}
												/>
												{contractForm.formState?.errors?.commercials
													?.length && (
													<FormHelperText error={true}>
														{
															contractForm.formState.errors?.commercials[
																0
															]?.assetId?.message
														}
													</FormHelperText>
												)}
											</FormControl>
											{(contractForm.watch(`commercials.${index}.assetId`)) && (<div>
												<span>AD-ID</span>
												<FormControl
													fullWidth
													className={styles.tableForms}
												>
													<NabuTextbox
														disabled={props?.isPrint}
														value={getAssetAdIdByAssetIndex(index)}
													/>
												</FormControl>
											</div>)}
										</div>
									))}
								</td>
							</tr>
						</table>
						<br></br>
						<table
							width={'100%'}
							border={1}
							cellPadding={0}
							cellSpacing={0}
							className={`${styles.contractFormsInner} keep-together`}
						>
							<tr style={{ background: '#808080' }}>
								<th style={{ textAlign: 'center' }} colSpan={2}>
														Dates Worked<sup></sup>
								</th>
								<th style={{ textAlign: 'center' }} colSpan={2}>
														Work Time
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-around',
										}}
									>
										<span>From</span> <span>To</span>{' '}
									</div>
								</th>
								<th style={{ textAlign: 'center' }} colSpan={2}>
														Meals
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-around',
										}}
									>
										<span>From</span> <span>To</span>{' '}
									</div>
								</th>
								<th style={{ textAlign: 'center' }} colSpan={2}>
														Travel to Location
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-around',
										}}
									>
										<span>From</span> <span>To</span>{' '}
									</div>
								</th>
								<th style={{ textAlign: 'center' }} colSpan={2}>
														Travel from Location
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-around',
										}}
									>
										<span>From</span> <span>To</span>{' '}
									</div>
								</th>
								<th style={{ textAlign: 'center' }} colSpan={2}>
															Makeup/Fitting
									<div
										style={{
											display: 'flex',
											justifyContent: 'space-around',
										}}
									>
										<span>From</span> <span>To</span>{' '}
									</div>
								</th>
								{!props?.isPrint && (
									<th style={{ textAlign: 'center' }}></th>
								)}
							</tr>
							{workDayFieldArray.fields.map((field, index) => (
								<tr key={`work-field-${field.id}`}>
									<td height={40} colSpan={2}>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											<Controller
												control={contractForm.control}
												name={`workDays.${index}.date`}
												defaultValue=''
												render={({ field }) => (
													<NabuDatepicker
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Date' : ''}
													/>
												)}
											/>
											{!!contractForm.formState?.errors?.workDays
												?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[
															index
														]?.date?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											{props?.isPrint ? (
												<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeStartAt`) || '', 'hh:mm aa')}</p>
											) : (<Controller
												control={contractForm.control}
												name={`workDays.${index}.workTimeStartAt`}
												render={({ field }) => (
													<NabuTimePicker
														{...field}
														ampm={true}
														disableOpenPicker={true}
														disabled={!(contractForm.watch(`workDays.${index}.date`))}
													/>
												)}
											/>)}
											{!!contractForm.formState?.errors?.workDays
												?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[
															index
														]?.workTimeStartAt?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											{props?.isPrint ? (
												<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeEndAt`) || '', 'hh:mm aa')}</p>
											) : (<Controller
												control={contractForm.control}
												name={`workDays.${index}.workTimeEndAt`}
												render={({ field }) => (
													<NabuTimePicker
														{...field}
														ampm={true}
														disableOpenPicker={true}
														disabled={!(contractForm.watch(`workDays.${index}.date`))}
													/>
												)}
											/>)}
											{!!contractForm.formState?.errors?.workDays
												?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[
															index
														]?.workTimeEndAt?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											{/* <Controller
																					control={contractForm.control}
																					name={`workDays.${index}.mealTimeStartAt`}
																					render={({ field }) => (
																						<NabuTimePicker
																							{...field}
																							ampm={true}
																							disabled={props?.isPrint}
																						/>
																					)}
																				/> */}
											{props?.isPrint ? (
												<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.mealTimeStartAt`) || '', 'hh:mm aa')}</p>
											) : (<Controller
												control={contractForm.control}
												name={`workDays.${index}.mealTimeStartAt`}
												render={({ field }) => (
													<NabuTimePicker
														{...field}
														ampm={true}
														disableOpenPicker={true}
														disabled={!(contractForm.watch(`workDays.${index}.date`))}
													/>
												)}
											/>)}
											{!!contractForm.formState?.errors?.workDays
												?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[
															index
														]?.mealTimeStartAt?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											{/* <Controller
																					control={contractForm.control}
																					name={`workDays.${index}.mealTimeEndAt`}
																					render={({ field }) => (
																						<NabuTimePicker
																							{...field}
																							ampm={true}
																							disabled={props?.isPrint}
																						/>
																					)}
																				/> */}
											{props?.isPrint ? (
												<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.mealTimeEndAt`) || '', 'hh:mm aa')}</p>
											) : (<Controller
												control={contractForm.control}
												name={`workDays.${index}.mealTimeEndAt`}
												render={({ field }) => (
													<NabuTimePicker
														{...field}
														ampm={true}
														disableOpenPicker={true}
														disabled={!(contractForm.watch(`workDays.${index}.date`))}
													/>
												)}
											/>)}
											{!!contractForm.formState?.errors?.workDays
												?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[
															index
														]?.mealTimeEndAt?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											{/* <Controller
																					control={contractForm.control}
																					name={`workDays.${index}.travelTimeToLocationStartAt`}
																					render={({ field }) => (
																						<NabuTimePicker
																							{...field}
																							ampm={true}
																							disabled={props?.isPrint}
																						/>
																					)}
																				/> */}
											{props?.isPrint ? (
												<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeToLocationStartAt`) || '', 'hh:mm aa')}</p>
											) : (<Controller
												control={contractForm.control}
												name={`workDays.${index}.travelTimeToLocationStartAt`}
												render={({ field }) => (
													<NabuTimePicker
														{...field}
														ampm={true}
														disableOpenPicker={true}
														disabled={!(contractForm.watch(`workDays.${index}.date`))}
													/>
												)}
											/>)}
											{!!contractForm.formState?.errors?.workDays
												?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[
															index
														]?.travelTimeToLocationStartAt?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											{/* <Controller
																					control={contractForm.control}
																					name={`workDays.${index}.travelTimeToLocationEndAt`}
																					render={({ field }) => (
																						<NabuTimePicker
																							{...field}
																							ampm={true}
																							disabled={props?.isPrint}
																						/>
																					)}
																				/> */}
											{props?.isPrint ? (
												<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeToLocationEndAt`) || '', 'hh:mm aa')}</p>
											) : (<Controller
												control={contractForm.control}
												name={`workDays.${index}.travelTimeToLocationEndAt`}
												render={({ field }) => (
													<NabuTimePicker
														{...field}
														ampm={true}
														disableOpenPicker={true}
														disabled={!(contractForm.watch(`workDays.${index}.date`))}
													/>
												)}
											/>)}
											{!!contractForm.formState?.errors?.workDays
												?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[
															index
														]?.travelTimeToLocationEndAt?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											{/* <Controller
																					control={contractForm.control}
																					name={`workDays.${index}.travelTimeFromLocationStartAt`}
																					render={({ field }) => (
																						<NabuTimePicker
																							{...field}
																							ampm={true}
																							disabled={props?.isPrint}
																						/>
																					)}
																				/> */}
											{props?.isPrint ? (
												<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeFromLocationStartAt`) || '', 'hh:mm aa')}</p>
											) : (<Controller
												control={contractForm.control}
												name={`workDays.${index}.travelTimeFromLocationStartAt`}
												render={({ field }) => (
													<NabuTimePicker
														{...field}
														ampm={true}
														disableOpenPicker={true}
														disabled={!(contractForm.watch(`workDays.${index}.date`))}
													/>
												)}
											/>)}
											{!!contractForm.formState?.errors?.workDays
												?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[
															index
														]?.travelTimeFromLocationStartAt?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											{/* <Controller
																					control={contractForm.control}
																					name={`workDays.${index}.travelTimeFromLocationEndAt`}
																					render={({ field }) => (
																						<NabuTimePicker
																							{...field}
																							ampm={true}
																							disabled={props?.isPrint}
																						/>
																					)}
																				/> */}
											{props?.isPrint ? (
												<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeFromLocationEndAt`) || '', 'hh:mm aa')}</p>
											) : (<Controller
												control={contractForm.control}
												name={`workDays.${index}.travelTimeFromLocationEndAt`}
												render={({ field }) => (
													<NabuTimePicker
														{...field}
														ampm={true}
														disableOpenPicker={true}
														disabled={!(contractForm.watch(`workDays.${index}.date`))}
													/>
												)}
											/>)}
											{!!contractForm.formState?.errors?.workDays
												?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[
															index
														]?.travelTimeFromLocationEndAt?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
										<FormControl className={styles.tableForms}>
											{/* <Controller
																					control={contractForm.control}
																					name={`workDays.${index}.makeUpTimeStartAt`}
																					render={({ field }) => (
																						<NabuTimePicker
																							{...field}
																							ampm={true}
																							disabled={props?.isPrint}
																						/>
																					)}
																				/> */}
											{props?.isPrint ? (
												<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.makeUpTimeStartAt`) || '', 'hh:mm aa')}</p>
											) : (<Controller
												control={contractForm.control}
												name={`workDays.${index}.makeUpTimeStartAt`}
												render={({ field }) => (
													<NabuTimePicker
														{...field}
														ampm={true}
														disableOpenPicker={true}
														disabled={!(contractForm.watch(`workDays.${index}.date`))}
													/>
												)}
											/>)}
											{!!contractForm.formState?.errors?.workDays
												?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[
															index
														]?.makeUpTimeStartAt?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
										<FormControl
											fullWidth
											className={styles.tableForms}
										>
											{/* <Controller
																					control={contractForm.control}
																					name={`workDays.${index}.makeUpTimeEndAt`}
																					render={({ field }) => (
																						<NabuTimePicker
																							{...field}
																							ampm={true}
																							disabled={props?.isPrint}
																						/>
																					)}
																				/> */}
											{props?.isPrint ? (
												<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.makeUpTimeEndAt`) || '', 'hh:mm aa')}</p>
											) : (<Controller
												control={contractForm.control}
												name={`workDays.${index}.makeUpTimeEndAt`}
												render={({ field }) => (
													<NabuTimePicker
														{...field}
														ampm={true}
														disableOpenPicker={true}
														disabled={!(contractForm.watch(`workDays.${index}.date`))}
													/>
												)}
											/>)}
											{!!contractForm.formState?.errors?.workDays
												?.length && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors?.workDays[
															index
														]?.makeUpTimeEndAt?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									{!props?.isPrint && (
										<>
											<td style={{ display: 'flex' }}>
												{workDayFieldArray.fields.length - 1 ===
																		index && (
													<NabuButton
														variant='text'
														onClick={onAddWorkDay}
													>
														<Add />
													</NabuButton>
												)}
												{workDayFieldArray?.fields.length > 1 && (
													<NabuButton
														variant='text'
														onClick={() => onRemoveWorkDay(index)}
													>
														<DeleteForever color='error' />
													</NabuButton>
												)}
											</td>
										</>
									)}
								</tr>
							))}
						</table>
						<br></br>
						<Grid item container columnSpacing={3}>
							<Grid item md={12}>
								<Box sx={{float:'right'}}>
									<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
										<span>Performer’s Signature or Initials:</span>	 
										<FormControl sx={{minWidth:'350px'}} className={styles.tableForms}><NabuTextbox placeholder='Performer’s Signature or Initials'/></FormControl>
									</div>
								</Box>
							</Grid>
						</Grid>
						<hr></hr>

						<table width={'100%'} border={0} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
							<tr>
								<td width={30}>
								Date
								</td>
								<td width={200}>
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										{props?.isPrint ? (
											<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('producerDate')) || ''}</p>
										) :(<Controller
											control={contractForm.control}
											name='producerDate'
											render={({ field }) => (
												<NabuDatepicker
													{...field}
													disabled={props?.isPrint}
													placeholder={!props?.isPrint ? 'Date' : ''}
												/>
											)}
										/>)}
									</FormControl>
								</td>
								<td width={60}>
								Producer{ !props?.isPrint && (
										<Tooltip
											placement='right-end'
											title={'Signatory'}
										>
											<HelpOutline fontSize='inherit' />
										</Tooltip>)
									}
								</td>
								<td width={300}>
									<div style={{display:'flex', alignItems:'center'}}>
										<FormControl
											className={styles.tableForms}
											fullWidth
										>
											<Controller
												key={`commercial-assetId
									-${contractForm.watch(`commercials.${0}.assetId.value`)}
									`}
												control={contractForm.control}
												name='producerCompanyId'
												render={({ field }) => (
													<>
														<LookAheadSelect
															key={`commercial-assetId
												-${contractForm.watch(`commercials.${0}.assetId.value`)}
												`}
															{...field}
															placeholder={!props?.isPrint ? 'Producer' : ''}
															filterProperty='CompanyNames.Name'
															APICaller={(filterModel) => getAllSignatory(contractForm.watch(`commercials.${0}.assetId.value`),filterModel)}
															optionGenerator={companyOptionGenerator}
															isClearable={true}
															onChange={(e: any) => {
																field.onChange(e);
																contractForm.setValue('producerCompanyEmail', '', { shouldDirty: true});
																contractForm.setValue('producerCompanyPhone', '', { shouldDirty: true});
																contractForm.trigger('producerCompanyEmail');
																contractForm.trigger('producerCompanyPhone');
																contractForm.setValue('producerPrintName','',{shouldDirty:true});
															}}
															isDisabled={props?.isPrint}
															cacheOptions
															defaultOptions
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
														{/* {!props?.isPrint && (
												<Box sx={{mt:0.5}}>
													<AddCompanyInline
														title='Company'
													/>
												</Box>
											)} */}
													</>
												)}
											/>
										
										</FormControl>  
										<span> ,</span>
									</div>
								</td>
								<td width={180}>
								engages Extra Performer<sup>*</sup>
								</td>
								<td>
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										<Controller
											control={contractForm.control}
											name='performerId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														data-cy='name_select'
														{...field}
														key={`performer-name
																											-${contractForm.watch('performerId.value')}
																											-${contractForm.watch('performerPhoneId')}-${contractForm.watch('performerEmailId')}`}
														isClearable={true}
														placeholder={!props?.isPrint ? 'Performer' : ''}
														filterProperty='PersonName.FirstName'
														APICaller={(
															filterModel,
															searchText
														) =>
															GetByWithFullNameSearchForBasicDetail(
																filterModel,
																searchText
															)
														}
														isSearchText={true}
														isDisabled={props?.isPrint}
														optionGenerator={
															performerOptionGenerator
														}
														defaultFilter={getPerformerFilter()}
														onChange={(e: any) => {
															field.onChange(e);
															contractForm.trigger('performerEmailId');
															contractForm.setValue('performerEmailId', '', { shouldDirty: true});
															contractForm.setValue('performerPhoneId', '', { shouldDirty: true});
															// contractForm.resetField('performerEmailId');
															// contractForm.resetField('performerPhoneId');
															contractForm.setValue('performerPrintName', contractForm.watch('performerId.label'),{ shouldDirty: true });
															contractForm.setValue('performerAddressId', null, { shouldDirty: true});
															contractForm.setValue('performerAdAgencyId',null,{shouldDirty:true});
															contractForm.setValue('performerZip', '', { shouldDirty: true});
															contractForm.setValue('performerState', '', { shouldDirty: true});
															contractForm.setValue('performerCity', '', { shouldDirty: true});
														}}
														cacheOptions={false}
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
													{!props?.isPrint && (
														<Box sx={{marginBottom:'-27px'}} >
															<AddPersonInline
																title='Person'
																onPersonCreated={() => ({})}
																initialValues={{personType: ['TALENT'], isMinor: false}}
															/>
														</Box>
													)}
												</>
											)}
										/>
										{(contractForm.formState?.errors?.performerId) && (
											<FormHelperText
												data-cy='errorHelper_first_session_date'
												error={true}
											>
												{
													contractForm.formState?.errors
														?.performerId?.value?.message ||
																									contractForm.formState?.errors
																										?.performerId?.message 
												}
											</FormHelperText>
										)}
									</FormControl>
								</td>
							</tr>
						</table>

						<br></br>
						<p><b>and Extra Performer agrees to perform services for Producer in Commercials as follows:</b></p>

						<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
							<tr>
								<td>
									No. of Commercials
									<FormControl
										className={styles.tableForms}
										style={{ width: '50%' }}
									><Controller
											control={contractForm.control}
											name='numberOfCommercials'
											render={({ field }) => (
												<NabuTextbox
													{...field}
													disabled={true}
													key={contractForm
														.watch('commercials')
														?.map((d) => d?.assetId)
														?.join('-')}
													value={contractForm
														?.watch(`commercials.${0}.assetId`)
														? 1:0
													}
												/>)}
										/>
									</FormControl> 
								</td>
								<td>
									Commercial Title(s) and Ad-ID® (s)
									<FormControl
										fullWidth
										className={styles.tableForms}
									>
										<Controller
											key={`commercial-title-${contractForm.watch(`commercials.${0}.assetId`)}`}
											control={contractForm.control}
											name='commercialTitle'
											render={({ field }) => (
												<NabuTextbox
													{...field}
													placeholder={!props?.isPrint ? 'Commercial Title' : ''}
													disabled={true}
													value={contractForm.watch(`commercials.${0}.assetId.label`)}
												/>
											)}
										/>
									</FormControl>
									<FormControl
										fullWidth
										className={styles.tableForms}
									>
										<NabuTextbox
											disabled={props?.isPrint}
											value={getAssetAdIdByAssetIndex(0)}
										/>
									</FormControl> 
								</td>
								<td>
									Such commercial(s) are to be produced by
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										<Controller
											control={contractForm.control}
											name='adAgencyId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														data-cy='ad_agency'
														{...field}
														placeholder={!props?.isPrint ? 'Ad-Agency' : ''}
														filterProperty='CompanyNames.Name'
														APICaller={getCompanyBasicDetails}
														optionGenerator={companyOptionGenerator}
														defaultFilter={AD_AGENCY_FILTER}
														isDisabled={props?.isPrint}
														isClearable={true}
														onChange={(e: any) => {
															field.onChange(e);
															setJPCAuthorizer();
															contractForm.setValue(
																'adAgencyAddressId', null, { shouldDirty: true}
															);
															contractForm.trigger('adAgencyAddressId');
														}}
														cacheOptions={false}
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
													{!props?.isPrint && (
														<Box sx={{mt:0.5}}>
															<AddCompanyInline
																title='Company'
																companyType={{ mainType: 'AD AGENCY' as CompanyMainType, subType: [] }} />
														</Box>
													)}
												</>
											)}
										/>
									</FormControl>
								</td>
							</tr>
							<tr>
								<td>
									
											Address
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										<Controller
											control={contractForm.control}
											key={`agency-address-cr-${contractForm.watch(
												'adAgencyId.value'
											)}-${contractForm.watch('adAgencyAddressId.value')}`}
											name='adAgencyAddressId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														{...field}
														placeholder={!props?.isPrint ? 'Select Address' : ''}
														key={`agency-address-${contractForm.watch(
															'adAgencyId.value'
														)}-${contractForm.watch('adAgencyAddressId.value')}`}
														filterProperty='Line1'
														sortField='IsPrimary'
														sortDescending={true}
														onOptionLoaded={(options) => {
															if(options?.length && !contractForm.watch('adAgencyAddressId.value')){
																contractForm.setValue('adAgencyAddressId', options[0], { shouldDirty: true});
																contractForm.trigger('adAgencyAddressId', { shouldFocus: true});																						
															}
														}}
														APICaller={(filterModel) =>
															getAllAddressByCompany(
																filterModel,
																contractForm.watch('adAgencyId.value') ||
																						''
															)
														}
														optionGenerator={
															adAgencyAddressOptionGenerator
														}
														isDisabled={
															!contractForm.watch('adAgencyId.value') ||
																				props?.isPrint
														}
														dropDownsize={50}
														isClearable={true}
														isSearchText={true}
														onChange={(e: any) => {
															field.onChange(e);
														}}
														cacheOptions={false}
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
													{
														<Box sx={{mt:0.5}}>
															{(contractForm.watch('adAgencyId.value')&& (!props.isPrint)) &&
																							<AddCompanyAddressInline
																								companyId={contractForm.watch('adAgencyId.value')}
																								title = 'ADDRESS'
																								isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'AdAgency-address'|| false}
																								event={addressPopOver?.event}
																								onAddressCreated={(d) => onAdAgencyAddressCreated(d)}
																								onClose={() => setAddressPopOver({ isOpen: false})}
																								onPopOverOpen={(e) => setAddressPopOver({
																									isOpen: true,
																									event: e?.currentTarget,
																									for: 'AdAgency-address'
																								})}
																								isPersistent = {true} />
															}
														</Box>

													}
												</>

											)}
										/>
									</FormControl>
									
								</td>
								<td>
								
										on behalf of
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										<Controller
											control={contractForm.control}
											name='advertiserId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														{...field}
														placeholder={!props?.isPrint ? 'Advertiser' : ''}
														filterProperty='CompanyNames.Name'
														APICaller={getCompanyBasicDetails}
														optionGenerator={companyOptionGenerator}
														defaultFilter={ADVERTISER_FILTER}
														isDisabled={props?.isPrint}
														isClearable={true}
														onChange={(e: any) => {
															field.onChange(e);
															contractForm.setValue('commercials', [{} as any], { shouldDirty: true});
															contractForm.setValue(`commercials.${0}.assetId`, null, { shouldDirty: true});
															contractForm.setValue('productId', null, { shouldDirty: true});
															contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
															contractForm.setValue('producerCompanyEmail','',{shouldDirty:true});
															contractForm.setValue('producerPrintName','',{shouldDirty:true});
															setJPCAuthorizer();
														}}
														cacheOptions={false}
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
													{!props?.isPrint && (
														<Box sx={{mt:0.5}}>
															<AddCompanyInline
																title='Company'
																companyType={{ mainType: 'ADVERTISER' as CompanyMainType, subType: [] }} />
														</Box>
													)}
												</>
											)}
										/>
									</FormControl>
									
								</td>
								<td>
									
									Product(s)
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										<Controller
											control={contractForm.control}
											name='productId'
											render={({ field }) => (
												<LookAheadSelect
													{...field}
													placeholder={!props?.isPrint ? 'Select Product' : ''}
													key={`product-${contractForm.watch(
														'advertiserId.value'
													)}`}
													isMulti
													filterProperty='ProductName'
													APICaller={(filterModel) =>
														getAllProducts(
															filterModel,
															contractForm.watch('advertiserId.value')
														)
													}
													optionGenerator={productOptionGenerator}
													isDisabled={
														!contractForm.watch('advertiserId.value') ||
																			props?.isPrint
													}
													defaultFilter={PRODUCT_FILTER}
													dropDownsize={20}
													isClearable={true}
													onChange={(e: any) => {
														field.onChange(e);
													}}
													cacheOptions={false}
													defaultOptions
													className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
												/>
											)}
										/>
									</FormControl>
									
								</td>
							</tr>
							<tr>
								<td>
									<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
										Date and time of engagement:
										<FormControl
											className={styles.tableForms}
											fullWidth
										>
											<Controller
												control={contractForm.control}
												name='engagementDate'
												render={({ field }) => (
													<NabuDatepicker
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Engagement Date' : ''}
													/>
												)}
											/>
										</FormControl>
									</div>
								</td>
								<td colSpan={2}>
									<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
										Place of Engagement
										<FormControl
											className={styles.tableForms}
											fullWidth
										>
											<Controller
												control={contractForm.control}
												name='placeOfEngagement'
												render={({ field }) => (
													<LocationFreeTextSelect
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Place of Engagement' : ''}
													/>
												)}
											/>
										</FormControl>
									</div>
								</td>
							</tr>
						</table>
						<br></br>
						<Grid item container columnSpacing={3}>
							<Grid item md={7}>
								<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`} style={{minHeight:'160px'}}>
									<tr>
										<td>
											<b> Category Type</b>
											<Grid item container columnSpacing={3}>
												<Grid item md={6}>
													<FormControl className={styles.tableForms} fullWidth>
														<div style={{ display: 'inline-flex', alignItems: 'center' }}>
															<Controller
																control={contractForm.control}
																name='performerTypes'
																render={({ field }) => (
																	<div
																		onClick={() => (!props?.isPrint) && field.onChange('COMMERCIAL_EXTRA_PERFORMER')}
																		style={{ userSelect: 'none' }}
																	>
																		<Box display={'flex'} alignItems={'flex-start'}>
																			<input
																				{...field}
																				value="COMMERCIAL_EXTRA_PERFORMER"
																				checked={field.value === 'COMMERCIAL_EXTRA_PERFORMER'}
																				onChange={(e) => field.onChange(e.target.value)}
																				type='radio'
																				disabled={props?.isPrint}
																				style={{ marginTop: '3px' }}
																			/>
																			<span>Commercial Extra Performer</span>
																		</Box>
																	</div>
																)}
															/>
														</div>
													</FormControl>

													<FormControl className={styles.tableForms} fullWidth>
														<div style={{ display: 'inline-flex', alignItems: 'center' }}>
															<Controller
																control={contractForm.control}
																name='performerTypes'
																render={({ field }) => (
																	<div
																		onClick={() => (!props?.isPrint) && field.onChange('HAND_MODEL')}
																		style={{ userSelect: 'none' }}
																	>
																		<Box display={'flex'} alignItems={'flex-start'}>
																			<input
																				{...field}
																				value="HAND_MODEL"
																				checked={field.value === 'HAND_MODEL'}
																				onChange={(e) => field.onChange(e.target.value)}
																				type='radio'
																				disabled={props?.isPrint}
																				style={{ marginTop: '3px' }}
																			/>
																			<span>Hand Model</span>
																		</Box>
																	</div>
																)}
															/>
														</div>
													</FormControl>

													<FormControl className={styles.tableForms} fullWidth>
														<div style={{ display: 'inline-flex', alignItems: 'center' }}>
															<Controller
																control={contractForm.control}
																name='performerTypes'
																render={({ field }) => (
																	<div
																		onClick={() => (!props?.isPrint) && field.onChange('STAND_IN')}
																		style={{ userSelect: 'none' }}
																	>
																		<Box display={'flex'} alignItems={'flex-start'}>
																			<input
																				{...field}
																				value="STAND_IN"
																				checked={field.value === 'STAND_IN'}
																				onChange={(e) => field.onChange(e.target.value)}
																				type='radio'
																				disabled={props?.isPrint}
																				style={{ marginTop: '3px' }}
																			/>
																			<span>Stand-In</span>
																		</Box>
																	</div>
																)}
															/>
														</div>
													</FormControl>

													<FormControl className={styles.tableForms} fullWidth>
														<div style={{ display: 'inline-flex', alignItems: 'center' }}>
															<Controller
																control={contractForm.control}
																name='performerTypes'
																render={({ field }) => (
																	<div
																		onClick={() => (!props?.isPrint) && field.onChange('PHOTO_DOUBLE')}
																		style={{ userSelect: 'none' }}
																	>
																		<Box display={'flex'} alignItems={'flex-start'}>
																			<input
																				{...field}
																				value="PHOTO_DOUBLE"
																				checked={field.value === 'PHOTO_DOUBLE'}
																				onChange={(e) => field.onChange(e.target.value)}
																				type='radio'
																				disabled={props?.isPrint}
																				style={{ marginTop: '3px' }}
																			/>
																			<span>Photo Double</span>
																		</Box>
																	</div>
																)}
															/>
														</div>
													</FormControl>

													<FormControl className={styles.tableForms} fullWidth>
														<div style={{ display: 'inline-flex', alignItems: 'center' }}>
															<Controller
																control={contractForm.control}
																name='performerTypes'
																render={({ field }) => (
																	<div
																		onClick={() => (!props?.isPrint) && field.onChange('OTHER')}
																		style={{ userSelect: 'none' }}
																	>
																		<Box display={'flex'} alignItems={'flex-start'}>
																			<input
																				{...field}
																				value="OTHER"
																				checked={field.value === 'OTHER'}
																				onChange={(e) => field.onChange(e.target.value)}
																				type='radio'
																				disabled={props?.isPrint}
																				style={{ marginTop: '3px' }}
																			/>
																			<span>Other:</span>
																		</Box>
																	</div>
																)}
															/>
														</div>
													</FormControl>

													{contractForm.watch('performerTypes') === 'OTHER' && (
														<div>
															{props?.isPrint ? (
																<p className={styles.descriptionPrintText} style={{ padding: '0px' }}>
																	{contractForm.watch('otherCategory')}
																</p>
															) : (
																<>
																	<Controller
																		control={contractForm.control}
																		name='otherCategory'
																		render={({ field }) => (
																			<textarea
																				{...field}
																				disabled={props?.isPrint}
																				placeholder={!props?.isPrint ? 'Other category' : ''}
																				style={{ width: '100%' }}
																			/>
																		)}
																	/>
																</>
															)}
														</div>
													)}
												</Grid>

												<Grid item md={6}>
													<FormControl
														className={styles.tableForms}
														fullWidth
													>
														<div
															style={{
																display: 'inline-flex',
																alignItems: 'center',
															}}
														>
															<Controller
																control={contractForm.control}
																name='is13WeeksUse'
																defaultValue={false}
																render={({ field }) => (
																	<div
																		onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																		style={{ userSelect: 'none' }}
																	>
																		<Box display={'flex'} alignItems={'flex-start'}>
																			<input
																				{...field}
																				value={field.value + ''}
																				checked={field.value}
																				onChange={(e) => field.onChange(e)}
																				type='checkbox'
																				disabled={props?.isPrint}
																				style={{ marginTop: '3px' }}
																			/>
																			<span>13 Weeks Use</span>
																		</Box>
																	</div>
																)}
															/>
														</div>
													</FormControl>
													<FormControl
														className={styles.tableForms}
														fullWidth
													>
														<div
															style={{
																display: 'inline-flex',
																alignItems: 'center',
															}}
														>
															<Controller
																control={contractForm.control}
																name='isUnlimitedUse'
																defaultValue={false}
																render={({ field }) => (
																	<div
																		onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																		style={{ userSelect: 'none' }}
																	>
																		<Box display={'flex'} alignItems={'flex-start'}>
																			<input
																				{...field}
																				value={field.value + ''}
																				checked={field.value}
																				onChange={(e) => field.onChange(e)}
																				type='checkbox'
																				disabled={props?.isPrint}
																				style={{ marginTop: '3px' }}
																			/>
																			<span>Unlimited Use</span>
																		</Box>
																	</div>
																)}
															/>
														</div>
													</FormControl>
													<FormControl
														className={styles.tableForms}
														fullWidth
													>
														<div
															style={{
																display: 'inline-flex',
																alignItems: 'center',
															}}
														>
															<Controller
																control={contractForm.control}
																name='isProducedForCableOnly'
																defaultValue={false}
																render={({ field }) => (
																	<div
																		onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																		style={{ userSelect: 'none' }}
																	>
																		<Box display={'flex'} alignItems={'flex-start'}>
																			<input
																				{...field}
																				value={field.value + ''}
																				checked={field.value}
																				onChange={(e) => field.onChange(e)}
																				type='checkbox'
																				disabled={props?.isPrint}
																				style={{ marginTop: '3px' }}
																			/>
																			<span>Produced for Cable Only</span>
																		</Box>
																	</div>
																)}
															/>
														</div>
													</FormControl>
													<FormControl
														className={styles.tableForms}
														fullWidth
													>
														<div
															style={{
																display: 'inline-flex',
																alignItems: 'center',
															}}
														>
															<Controller
																control={contractForm.control}
																name='isProducedForInternetOnly'
																defaultValue={false}
																render={({ field }) => (
																	<div
																		onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																		style={{ userSelect: 'none' }}
																	>
																		<Box display={'flex'} alignItems={'flex-start'}>
																			<input
																				{...field}
																				value={field.value + ''}
																				checked={field.value}
																				onChange={(e) => field.onChange(e)}
																				type='checkbox'
																				disabled={props?.isPrint}
																				style={{ marginTop: '3px' }}
																			/>
																			<span>Produced for Internet Only</span>
																		</Box>
																	</div>
																)}
															/>
														</div>
													</FormControl>
													<FormControl
														className={styles.tableForms}
														fullWidth
													>
														<div
															style={{
																display: 'inline-flex',
																alignItems: 'center',
															}}
														>
															<Controller
																control={contractForm.control}
																name='isProducedForNewMediaOnly'
																defaultValue={false}
																render={({ field }) => (
																	<div
																		onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																		style={{ userSelect: 'none' }}
																	>
																		<Box display={'flex'} alignItems={'flex-start'}>
																			<input
																				{...field}
																				value={field.value + ''}
																				checked={field.value}
																				onChange={(e) => field.onChange(e)}
																				type='checkbox'
																				disabled={props?.isPrint}
																				style={{ marginTop: '3px' }}
																			/>
																			<span>Produced for New Media Only</span>
																		</Box>
																	</div>
																)}
															/>
														</div>
													</FormControl>
												</Grid>
											</Grid>
										</td>
									</tr>
								</table>	
							</Grid>
							<Grid item md={5}>
								<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
									<tr>
										<td valign='top'>
											<p>
												<b>Adjustments (check all that apply)</b>
											</p>
											<br></br>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<div
													style={{
														display: 'inline-flex',
														alignItems: 'center',
													}}
												>
													<Controller
														control={contractForm.control}
														name='isWetSnowSmokeOrDust'
														defaultValue={false}
														render={({ field }) => (
															<div
																onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => field.onChange(e)}
																		type='checkbox'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Wet,Snow, Smoke or Dust ($53.25)</span>
																</Box>
															</div>
														)}
													/>
												</div>
											</FormControl>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<div
													style={{
														display: 'inline-flex',
														alignItems: 'center',
													}}
												>
													<Controller
														control={contractForm.control}
														name='isHazardAdjustment'
														defaultValue={false}
														render={({ field }) => (
															<div
																onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => field.onChange(e)}
																		type='checkbox'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Hazard Adjustment $</span>{' '}
																</Box>
															</div>
														)}
													/>
													<Controller
														control={contractForm.control}
														name='adjustmentAmount'
														render={({ field }) => (
															<input
																{...field}
																disabled={props?.isPrint}
																placeholder={!props?.isPrint ? 'Adjustment Amount' : ''}
																onChange={(e: any) => {
																	const value = e?.target?.value || '';
																	if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																		field.onChange(value);
																	} else {
																		const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																		field.onChange(num);
																	}
																}}
																onBlur={() => {
																	const numericValue = parseFloat((field.value ?? '0').toString());
																	if (!isNaN(numericValue)) {
																		field.onChange(numericValue.toFixed(2));
																	} else {
																		field.onChange('0.00');
																	}
																}}
															/>
														)}
													/>
													{contractForm.formState.errors?.adjustmentAmount
														?.message && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors
																	?.adjustmentAmount.message
															}
														</FormHelperText>
													)}
												</div>
											</FormControl>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<div
													style={{
														display: 'inline-flex',
														alignItems: 'center',
													}}
												>
													<Controller
														control={contractForm.control}
														name='isMakeUpSkullCapHairGoodsHairCut'
														defaultValue={false}
														render={({ field }) => (
															<div
																onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => field.onChange(e)}
																		type='checkbox'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>
																						Make-up, Skull Cap, Hairgoods, Haircuts
																						($41.85)
																	</span>
																</Box>
															</div>
														)}
													/>
												</div>
											</FormControl>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<div
													style={{
														display: 'inline-flex',
														alignItems: 'center',
													}}
												>
													<Controller
														control={contractForm.control}
														name='isNightPremium'
														defaultValue={false}
														render={({ field }) => (
															<div
																onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => field.onChange(e)}
																		type='checkbox'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Night Premium</span>
																</Box>
															</div>
														)}
													/>
												</div>
											</FormControl>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<div
													style={{
														display: 'inline-flex',
														alignItems: 'center',
													}}
												>
													<Controller
														control={contractForm.control}
														name='isOtherAdjustment'
														defaultValue={false}
														render={({ field }) => (
															<div
																onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => field.onChange(e)}
																		type='checkbox'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Other:</span>
																</Box>
															</div>
														)}
													/>
												</div>
											</FormControl>
											{(contractForm.watch('isOtherAdjustment'))&&(<div>
												{
													props?.isPrint ? (
														<p className={styles.descriptionPrintText} style={{padding:'0px'}}>{contractForm.watch('otherAdjustment')}</p>
													) : ( <>
														<Controller
															control={contractForm.control}
															name='otherAdjustment'
															render={({ field }) => (
																<textarea 
																	{...field}
																	disabled={props?.isPrint}
																	placeholder={!props?.isPrint ? 'Other Adjustment' : ''}
																	style={{width:'100%'}}
																/>
															)}
														/>
													</>)
												}
											</div>)}
																	
										</td>
									</tr>
								</table>	
							</Grid>
						</Grid>
						<br></br>
						<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`} >
							<tr>
								<td>
									<p>Allowances (Check if applicable) </p>
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										<div
											style={{
												display: 'inline-flex',
												alignItems: 'center',
											}}
										>
											<Controller
												control={contractForm.control}
												name='isFlightInsurancePayable'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>
																						Flight Insurance (@ $13.39) Payable
															</span>
														</Box>
													</div>
												)}
											/>
										</div>
									</FormControl>
								</td>
								<td>
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										<div
											style={{
												display: 'inline-flex',
												alignItems: 'center',
											}}
										>
											<Controller
												control={contractForm.control}
												name='isVehicleAllowance'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => {
																	field.onChange(e);
																	if(e?.target?.value) {
																		contractForm.setValue('vehicleType', '', { shouldDirty: true });
																		contractForm.setValue('vehicleTolls', '', { shouldDirty: true });
																		contractForm.setValue('vehicleMileage', '', { shouldDirty: true });
																		contractForm.setValue('vehicleParking', '', { shouldDirty: true });
																	}
																}}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Vehicle</span>
														</Box>
													</div>
												)}
											/>
										</div>

										<ul>
											<p>
												<FormControl className={styles.tableForms}>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
														}}
													>
																					Type:
														<Controller
															control={contractForm.control}
															name='vehicleType'
															render={({ field }) => (
																<NabuTextbox
																	{...field}
																	disabled={props?.isPrint || !contractForm.watch('isVehicleAllowance')}
																	placeholder={!props?.isPrint ? 'Vehicle Type' : ''}
																/>
															)}
														/>
														{contractForm.formState.errors?.vehicleType
															?.message && (
															<FormHelperText error={true}>
																{
																	contractForm.formState?.errors
																		?.vehicleType.message
																}
															</FormHelperText>
														)}
													</div>
												</FormControl>
											</p>
											<p>
												<FormControl className={styles.tableForms}>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
														}}
													>
																					Tolls:
														<Controller
															control={contractForm.control}
															name='vehicleTolls'
															render={({ field }) => (
																<NabuTextbox
																	{...field}
																	disabled={props?.isPrint || !contractForm.watch('isVehicleAllowance')}
																	placeholder={!props?.isPrint ? 'Vehicle Tolls' : ''}
																/>
															)}
														/>
														{contractForm.formState.errors?.vehicleTolls
															?.message && (
															<FormHelperText error={true}>
																{
																	contractForm.formState?.errors
																		?.vehicleTolls.message
																}
															</FormHelperText>
														)}
													</div>
												</FormControl>
											</p>
											<p>
												<FormControl className={styles.tableForms}>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
														}}
													>
																					Mileage:
														<Controller
															control={contractForm.control}
															name='vehicleMileage'
															render={({ field }) => (
																<NabuTextbox
																	{...field}
																	disabled={props?.isPrint || !contractForm.watch('isVehicleAllowance')}
																	placeholder={!props?.isPrint ? 'Vehicle Mileage' : ''}
																/>
															)}
														/>
														{contractForm.formState.errors?.vehicleMileage
															?.message && (
															<FormHelperText error={true}>
																{
																	contractForm.formState?.errors
																		?.vehicleMileage.message
																}
															</FormHelperText>
														)}
													</div>
												</FormControl>
											</p>
											<p>
												<FormControl className={styles.tableForms}>
													<div
														style={{
															display: 'flex',
															alignItems: 'center',
														}}
													>
																					Parking:
														<Controller
															control={contractForm.control}
															name='vehicleParking'
															render={({ field }) => (
																<NabuTextbox
																	{...field}
																	disabled={props?.isPrint || !contractForm.watch('isVehicleAllowance')}
																	placeholder={!props?.isPrint ? 'Vehicle Parking' : ''}
																/>
															)}
														/>
														{contractForm.formState.errors?.vehicleParking
															?.message && (
															<FormHelperText error={true}>
																{
																	contractForm.formState?.errors
																		?.vehicleParking.message
																}
															</FormHelperText>
														)}
													</div>
												</FormControl>
											</p>
										</ul>
									</FormControl>
									{/* <div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																			<span>Type</span>
																			<FormControl sx={{minWidth:'150px'}}  className={styles.tableForms}><NabuTextbox placeholder='Enter Type'/></FormControl> 
																		</div>
																		<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																			<span>Tolls</span>
																			<FormControl sx={{minWidth:'150px'}}  className={styles.tableForms}><NabuTextbox placeholder='Enter Tolls'/></FormControl> 
																		</div> */}
								</td>
								<td>
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										<div
											style={{
												display: 'inline-flex',
												alignItems: 'center',
											}}
										>
											<Controller
												control={contractForm.control}
												name='isTravelWithinStudioZone'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Travel within Studio Zone ($8.00)</span>
														</Box>
													</div>
												)}
											/>
										</div>
									</FormControl>
									{/* <div style={{display:'flex', alignItems:'center', flexWrap:'wrap', textAlign:'left'}}>
																			<Box style={{display:'flex', alignItems:'center', flexWrap:'wrap', textAlign:'left'}}  >
																				<input type='checkbox' style={{ marginTop: '3px', }}/>
																				<span>Mileage</span>
																				<FormControl sx={{minWidth:'150px'}}  className={styles.tableForms}><NabuTextbox placeholder='Enter Mileage'/></FormControl> 
																			</Box>
																		</div>
																		<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
																			<Box style={{display:'flex', alignItems:'center', flexWrap:'wrap', textAlign:'left'}}>
																				<input type='checkbox' style={{ marginTop: '3px', }}/>
																				<span>Parking</span>
																				<FormControl sx={{minWidth:'150px'}}  className={styles.tableForms}><NabuTextbox placeholder='Enter Parking'/></FormControl> 
																			</Box>
																		</div> */}
								</td>
							</tr>
							<tr>
								<td colSpan={3}>
									<span> <b>Wardrobe to be furnished</b> </span>
									<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
										<FormControl className={styles.tableForms} fullWidth>
											<div style={{ display: 'inline-flex', alignItems: 'center' }}>
												<Controller
													control={contractForm?.control}
													name='wardrobeFurnishedBy'
													defaultValue='none'
													render={({ field }) => (
														<div
															onClick={() => (!props?.isPrint) && field.onChange('isproducer')}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	type='radio'
																	value='isproducer'
																	checked={field.value === 'isproducer'}
																	onChange={(e) => field.onChange(e.target.value)}
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																<span>Producer:</span>
															</Box>
														</div>
													)}
												/>
											</div>
										</FormControl>

										<FormControl className={styles.tableForms} fullWidth>
											<div style={{ display: 'inline-flex', alignItems: 'center' }}>
												<Controller
													control={contractForm?.control}
													name='wardrobeFurnishedBy'
													defaultValue='none'
													render={({ field }) => (
														<div
															onClick={() => (!props?.isPrint) && field.onChange('isextraPerformer')}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	type='radio'
																	value='isextraPerformer'
																	checked={field.value === 'isextraPerformer'}
																	onChange={(e) => field.onChange(e.target.value)}
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																<span>Extra Performer:</span>
															</Box>
														</div>
													)}
												/>
											</div>
										</FormControl>
									</div>
								</td>
							</tr>
							<tr>
								<td width={250}>
									<b>If furnished by Extra Performer: </b>
									No. of costumes requested by Producer
								</td>
								<td>
									<b>Non-Evening Wear</b>
									<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
										<FormControl className={styles.tableForms} sx={{minWidth:'180px'}} >
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
																			Non-Evening Wear :
												<Controller
													control={contractForm.control}
													name='nonEveningWearCount'
													render={({ field }) => (
														<input
															{...field}
															type='number'
															onChange={(e) => {
																const value = e.target.value || '';
																if (/^\d*$/.test(value)) {
																	field.onChange(value);
																}
																calculateTotalWardrobeFee();
															}}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'Count' : ''}
														/>
													)}
												/>(@{NON_EVENING_WEAR_COST})
											</div>
										</FormControl>
										<FormControl sx={{maxWidth:'60px'}} fullWidth  className={styles.tableForms}>
											<NabuTextbox
												disabled={true}
												value={(contractForm.watch('nonEveningWearCount') && (Number(contractForm.watch('nonEveningWearCount') || 0)) * (NON_EVENING_WEAR_COST) || '')}
											/>
										</FormControl>			
									</div>
						
								</td>
								<td>
									<b>(Evening Wear)</b>
									<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
										<FormControl className={styles.tableForms} style={{minWidth:180}}>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
																			Evening Wear:
												<Controller
													control={contractForm.control}
													name='eveningWearCount'
													render={({ field }) => (
														<input
															{...field}
															onChange={(e) => {
																const value = e.target.value || '';
																if (/^\d*$/.test(value)) {
																	field.onChange(value);
																}
																calculateTotalWardrobeFee();
															}}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'Evening Wear Count' : ''}
														/>
													)}
												/>(@{EVENING_WEAR_COST})
											</div>
										</FormControl>
										<FormControl sx={{maxWidth:'60px'}} fullWidth className={styles.tableForms}>
											<NabuTextbox 
												disabled={true}
												value={(contractForm.watch('eveningWearCount') && (Number(contractForm.watch('eveningWearCount') || 0)) * (EVENING_WEAR_COST) || '')}
											/>
										</FormControl> 				
									</div>
								</td>
							</tr>
							<tr>
								<td colSpan={2}></td>
								<td align='right'>
									<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', }}>
										<FormControl className={styles.tableForms}>
											<div
												style={{
													display: 'flex',
													alignItems: 'center',
												}}
											>
																			Total Wardrobe Fee:
												<Controller
													control={contractForm.control}
													name='totalWardrobeFee'
													render={({ field }) => (
														<input
															{...field}
															disabled={true}
															placeholder={!props?.isPrint ? 'Total Wardrobe Fee' : ''}
															onChange={(e) => {
																if (
																	new RegExp(/^\d*\.?\d*$/).test(
																		e?.target?.value || ''
																	) &&
																				Number(e?.target?.value) >= 0
																) {
																	field.onChange(e);
																} else {
																	const value: string =
																					e?.target?.value ?? '';
																	const num = value
																		?.match(/^\d*\.?\d*/)
																		?.at(0);
																	if (!isNaN(Number(num || ''))) {
																		field.onChange(Number(num || ''));
																	} else {
																		field.onChange(0);
																	}
																}
															}}
														/>
													)}
												/>
											</div>
										</FormControl> 				
									</div>
								</td>
							</tr>
							<tr>
								<td colSpan={3}>
									<b>Props: </b>
									<Grid item container columnSpacing={3}>
										<Grid item md={4}>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<div
													style={{
														display: 'inline-flex',
														alignItems: 'center',
													}}
												>
													<Controller
														control={contractForm.control}
														name='isBooks'
														defaultValue={false}
														render={({ field }) => (
															<div
																onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => field.onChange(e)}
																		type='checkbox'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Books ($2.95 each)</span>
																</Box>
															</div>
														)}
													/>
												</div>
											</FormControl>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<div
													style={{
														display: 'inline-flex',
														alignItems: 'center',
													}}
												>
													<Controller
														control={contractForm.control}
														name='isBinocularOperaGlasses'
														defaultValue={false}
														render={({ field }) => (
															<div
																onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => field.onChange(e)}
																		type='checkbox'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Binoculars, Opera Glasses ($6.52)</span>
																</Box>
															</div>
														)}
													/>
												</div>
											</FormControl>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<div
													style={{
														display: 'inline-flex',
														alignItems: 'center',
													}}
												>
													<Controller
														control={contractForm.control}
														name='isCamera'
														defaultValue={false}
														render={({ field }) => (
															<div
																onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => field.onChange(e)}
																		type='checkbox'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>
																								Camera ($6.52)
																	</span>
																</Box>
															</div>
														)}
													/>
												</div>
											</FormControl>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<div
													style={{
														display: 'inline-flex',
														alignItems: 'center',
													}}
												>
													<Controller
														control={contractForm.control}
														name='isGolfClubs'
														defaultValue={false}
														render={({ field }) => (
															<div
																onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => field.onChange(e)}
																		type='checkbox'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Golf Clubs and Bag ($14.24)</span>
																</Box>
															</div>
														)}
													/>
												</div>
											</FormControl>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<div
													style={{
														display: 'inline-flex',
														alignItems: 'center',
													}}
												>
													<Controller
														control={contractForm.control}
														name='isLargePortableRadio'
														defaultValue={false}
														render={({ field }) => (
															<div
																onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => field.onChange(e)}
																		type='checkbox'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>
																										Large Portable Radio ($6.52)
																	</span>
																</Box>
															</div>
														)}
													/>
												</div>
											</FormControl>
										</Grid>
										<Grid item md={4}>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<div
													style={{
														display: 'inline-flex',
														alignItems: 'center',
													}}
												>
													<Controller
														control={contractForm.control}
														name='isPet'
														defaultValue={false}
														render={({ field }) => (
															<div
																onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => field.onChange(e)}
																		type='checkbox'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Pet ($27.22)</span>
																</Box>
															</div>
														)}
													/>
												</div>
											</FormControl>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<div
													style={{
														display: 'inline-flex',
														alignItems: 'center',
													}}
												>
													<Controller
														control={contractForm.control}
														name='isSkis'
														defaultValue={false}
														render={({ field }) => (
															<div
																onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => field.onChange(e)}
																		type='checkbox'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Skis ($14.24, incl. poles, boots)</span>
																</Box>
															</div>
														)}
													/>
												</div>
											</FormControl>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<div
													style={{
														display: 'inline-flex',
														alignItems: 'center',
													}}
												>
													<Controller
														control={contractForm.control}
														name='isLuggage'
														defaultValue={false}
														render={({ field }) => (
															<div
																onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => field.onChange(e)}
																		type='checkbox'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>
																						Luggage ($6.52 each piece, incl. Bookbags,
																						Briefcases)
																	</span>
																</Box>
															</div>
														)}
													/>
												</div>
											</FormControl>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<div
													style={{
														display: 'inline-flex',
														alignItems: 'center',
													}}
												>
													<Controller
														control={contractForm.control}
														name='isHandHeldMobile'
														defaultValue={false}
														render={({ field }) => (
															<div
																onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => field.onChange(e)}
																		type='checkbox'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>
																										Handheld Mobile/PDA device ($6.52)
																	</span>
																</Box>
															</div>
														)}
													/>
												</div>
											</FormControl>
										</Grid>
										<Grid item md={4}>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<div
													style={{
														display: 'inline-flex',
														alignItems: 'center',
													}}
												>
													<Controller
														control={contractForm.control}
														name='isTennisRacket'
														defaultValue={false}
														render={({ field }) => (
															<div
																onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => field.onChange(e)}
																		type='checkbox'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Tennis Racquet ($6.52) (Only if not being paid as part
																									of tennis wardrobe)</span>
																</Box>
															</div>
														)}
													/>
												</div>
											</FormControl>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<div
													style={{
														display: 'inline-flex',
														alignItems: 'center',
													}}
												>
													<Controller
														control={contractForm.control}
														name='isLaptopOrSimilarDevice'
														defaultValue={false}
														render={({ field }) => (
															<div
																onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => field.onChange(e)}
																		type='checkbox'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>Laptop or Similar Device ($6.52)</span>
																</Box>
															</div>
														)}
													/>
												</div>
											</FormControl>
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<div
													style={{
														display: 'inline-flex',
														alignItems: 'center',
													}}
												>
													<Controller
														control={contractForm.control}
														name='isIpod'
														defaultValue={false}
														render={({ field }) => (
															<div
																onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																style={{ userSelect: 'none' }}
															>
																<Box display={'flex'} alignItems={'flex-start'}>
																	<input
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => field.onChange(e)}
																		type='checkbox'
																		disabled={props?.isPrint}
																		style={{ marginTop: '3px' }}
																	/>
																	<span>iPod/MP3Player ($6.52)</span>
																</Box>
															</div>
														)}
													/>
												</div>
											</FormControl>
											<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
												<FormControl
													className={styles.tableForms}
													fullWidth
												>
													<div
														style={{
															display: 'inline-flex',
															alignItems: 'center',
														}}
													>
														<Controller
															control={contractForm.control}
															name='isOtherAllowance'
															defaultValue={false}
															render={({ field }) => (
																<div
																	onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
																	style={{ userSelect: 'none' }}
																>
																	<Box display={'flex'} alignItems={'flex-start'}>
																		<input
																			{...field}
																			value={field.value + ''}
																			checked={field.value}
																			onChange={(e) => field.onChange(e)}
																			type='checkbox'
																			disabled={props?.isPrint}
																			style={{ marginTop: '3px' }}
																		/>
																		<span>Other:</span>
																	</Box>
																										
																</div>
															)}
														/>
																							
													</div>
													<ul>
														<div
															style={{
																display: 'flex',
																alignItems: 'center',
															}}
														>
																			Fee $:
															<Controller
																control={contractForm.control}
																name='allowanceFees'
																render={({ field }) => (
																	<NabuTextbox
																		{...field}
																		disabled={props?.isPrint}
																		placeholder={!props?.isPrint ? 'Allowance Fee' : ''}
																		onChange={(e: any) => {
																			const value = e?.target?.value || '';
																			if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																				field.onChange(value);
																			} else {
																				const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																				field.onChange(num);
																			}
																		}}
																		onBlur={() => {
																			const numericValue = parseFloat((field.value ?? '0').toString());
																			if (!isNaN(numericValue)) {
																				field.onChange(numericValue.toFixed(2));
																			} else {
																				field.onChange('0.00');
																			}
																		}}
																	/>
																)}
															/>
														</div>
													</ul>
												</FormControl>
											</div>
										</Grid>
									</Grid>
								</td>
							</tr>
						</table>
						<br></br>
						<table
							width={'100%'}
							border={1}
							cellPadding={0}
							cellSpacing={0}
							className={`${styles.contractFormsInner} keep-together`}
						>
							<tr style={{ background: '#808080' }}>
								<th colSpan={4} style={{ textAlign: 'left' }}>
																Extra Performer authorizes Producer to make all
																payments to Extra Performer as follows:
								</th>
							</tr>
							<tr>
								<td style={{ paddingTop: '9px' }} colSpan={4}>
									<div
										style={{
											display: 'inline-flex',
											alignItems: 'center',
										}}
									>
										<Controller
											control={contractForm.control}
											name='isPaymentAtExtraPerformerAddress'
											defaultValue={true}
											render={({ field }) => (
												<div
													onClick={() => {
														(!props?.isPrint) && field.onChange(!field.value);
														contractForm.setValue('isPaymentAtPerformerAgencyAddress', !contractForm.watch('isPaymentAtExtraPerformerAddress'));
													}}
													style={{ userSelect: 'none' }}
												>
													<Box display={'flex'} alignItems={'flex-start'}>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => {
																field.onChange(e);
																contractForm.setValue('isPaymentAtPerformerAgencyAddress', !contractForm.watch('isPaymentAtExtraPerformerAddress'));
															}}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ marginTop: '3px' }}
														/>
														<span>
															<b>To Extra Performer at (Address)</b>
														</span>
													</Box>
												</div>
											)}
										/>
									</div>
								</td>
							</tr>
							<tr>
								<td>
								(Performer&apos;s Address):
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										<Controller
											control={contractForm.control}
											key={`select_Address_${contractForm.watch(
												'performerId.value'
											)}`}
											name='performerAddressId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														{...field}
														placeholder={!props?.isPrint ? 'Select Address' : ''}
														key={`performer-address-${contractForm.watch(
															'performerId.value'
														)}`}
														filterProperty='Line1'
														sortField='IsPrimary'
														sortDescending={true}
														onOptionLoaded={(options) => {
															if(options?.length && !contractForm.watch('performerAddressId.value')){
																contractForm.setValue('performerAddressId', options[0], { shouldDirty: true});									
																contractForm.trigger('performerAddressId', { shouldFocus: true});	
																onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');																					
															}
														}}
														APICaller={(filterModel) =>
															getAllAddressByPerson(
																filterModel,
																contractForm.watch(
																	'performerId.value'
																) || ''
															)
														}
														optionGenerator={
															addressOptionGenerator
														}
														dropDownsize={50}
														isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
														isClearable={true}
														isSearchText={true}
														onChange={(e: any) => {
															field.onChange(e);
															onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');
														}}
														cacheOptions={false}
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
													{
														<Box sx={{mt:1.3}}>
															{(contractForm.watch('performerId.value')&& (!props.isPrint)) &&
																							<AddCompanyAddressInline
																								companyId={contractForm.watch('performerId.value')}
																								title = 'ADDRESS'
																								isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'Performer-address'|| false}
																								event={addressPopOver?.event}
																								onAddressCreated={(d) => PerformerAddressCreated(d)}
																								onClose={() => setAddressPopOver({ isOpen: false})}
																								onPopOverOpen={(e) => setAddressPopOver({
																									isOpen: true,
																									event: e?.currentTarget,
																									for: 'Performer-address'
																								})}
																								isPersistent = {true} />
															}
														</Box>

													}
												</>
											)}
										/>
									</FormControl>
								</td>
								<td>
								(ZIP) :
									<FormControl 	className={styles.tableForms} fullWidth >
										<Controller
											key={`performer-zip-${contractForm.watch('performerAddressId.value')}`}
											control={contractForm.control}
											name='performerZip'
											defaultValue=''
											render={({ field }) => (
												<NabuTextbox
													key={`performer-zip-${contractForm.watch('performerAddressId.value')}`}
													{...field}
													placeholder={!props?.isPrint ? 'ZIP' : ''}
													disabled={props?.isPrint}
													onChange={(e) => {
														field.onChange(e);
														onZipCodeChange(
															contractForm.watch('performerZip') || '',
															'performer'
														);
													}}
												/>
											)}
										/>
									</FormControl>
								</td>
								<td>
								(State) :
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										<Controller
											key={`select-performer-state-${contractForm.watch('performerState')}`}	
											control={contractForm.control}
											name='performerState'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuSelect
														key={`select-performer-state-${contractForm.watch('performerState')}`}
														{...field}
														disabled={props?.isPrint}
														options={getStateOptions()}
														convertValueToUpperCase={false}
														placeholder={!props?.isPrint ? 'Select State' : ''}
													/>
												</>
											)}
										/>
									</FormControl>
								</td>
								<td>
								(City):
									<FormControl
										key={`select-performer-city-${contractForm.watch('performerState')}-${contractForm.watch('performerCity')}`}
										className={styles.tableForms}
										fullWidth
									>
										<Controller
											key={`select-performer-city-${contractForm.watch('performerState')}-${contractForm.watch('performerCity')}`}
											control={contractForm.control}
											name='performerCity'
											defaultValue=''
											render={({ field }) => (
												<NabuSelect
													{...field}
													disabled={props?.isPrint}
													options={getCityOptions(
														contractForm.watch('performerState')
													)}
													convertValueToUpperCase={true}
													placeholder={!props?.isPrint ? 'Select City' : ''}
												/>
											)}
										/>
									</FormControl>
								</td>
							</tr>
							<tr>

								<td>
									(Performer&apos;s Email Address)
									<FormControl
										className={styles.emaillowerCase}
										fullWidth
									>
										<Controller
											control={contractForm.control}
											key={`select-phone-ctr${contractForm.watch(
												'performerPhoneId'
											)}-${contractForm.watch(
												'performerId.value'
											)}`}
											name='performerEmailId'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuSelect
														{...field}
														key={`select-phone-${contractForm.watch(
															'performerPhoneId'
														)}-${contractForm.watch(
															'performerId.value'
														)}`}			
														defaultCase={true}			
														placeholder={!props?.isPrint ? 'Select Email' : ''}
														convertValueToUpperCase={false}
														enableEmptySelection={true}
														disabled={props?.isPrint || !contractForm.watch('performerId.value')}
														options={getPerformerEmailsOptions()}
														handleDefaultSelection={() => {
															return !contractForm.watch('performerEmailId');
														}}
													/>
													{
														<Box>
															{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																							<AddEmailInline
																								formData={{ ...new EmailFormModel()}}
																								isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Performer-email-address'|| false}	
																								onSubmit={onPerformerEmailInlineSubmit}
																								onClose={closeEmailInlinePopOver}
																								title='Email address'
																								onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Performer-email-address')}
																								event={emailPopOver?.event}
																							/>
															}
														</Box>	
													}
												</>
											)}
										/>
									</FormControl>
								</td>
								<td colSpan={3}>
								(Performer&apos;s Phone)
									<FormControl
										className={styles.tableForms}
										fullWidth 
									>
										<Controller
											control={contractForm.control}
											key={`select-phone-ctr${contractForm.watch(
												'performerPhoneId'
											)}-${contractForm.watch(
												'performerId.value'
											)}`}
											name='performerPhoneId'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuSelect
														{...field}
														key={`select-phone-${contractForm.watch(
															'performerPhoneId'
														)}-${contractForm.watch(
															'performerId.value'
														)}`}
														placeholder={!props?.isPrint ? 'Select Phone' : ''}
														convertValueToUpperCase={false}
														enableEmptySelection={true}
														disabled={props?.isPrint || !contractForm.watch('performerId.value')}
														options={getPerformerPhoneNumberOptions()}
														handleDefaultSelection={() => {
															return !contractForm.watch('performerPhoneId');
														}}
													/>
													{
														<Box>
															{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																							<div style={{float:'right'}}>
																								<AddPhoneNumberInline
																									formData={{ ...new PhoneNumberFormModel()}}
																									onSubmit={onPerformerPhoneNumberInlineSubmit}
																									isOpen={phoneNumberPopOver.isOpen && phoneNumberPopOver?.for == 'PerformerTelephone-address'|| false}	
																									onClose={closePhoneNumberInlinePopOver}
																									title='Phone Number'
																									onPopOverOpen={(e) => openPhoneNumberInlinePopOver(e, 'PerformerTelephone-address')}
																									event={phoneNumberPopOver?.event}	
																								/>
																							</div>

															}
														</Box>	
													}
												</>
											)}
										/>
									</FormControl>
								</td>
							</tr>
							<tr>
								<td colSpan={4}>
									<Controller
										control={contractForm.control}
										name='isPaymentAtPerformerAgencyAddress'
										defaultValue={false}
										render={({ field }) => (
											<div
												onClick={() =>{ 
													(!props?.isPrint) && field.onChange(!field.value);
													contractForm.setValue('isPaymentAtExtraPerformerAddress', !contractForm.watch('isPaymentAtPerformerAgencyAddress'));
												}}
												style={{ userSelect: 'none' }}
											>
												<Box display={'flex'} alignItems={'flex-start'}>
													<input
														{...field}
														value={field.value + ''}
														checked={field.value}
														onChange={(e) =>{ field.onChange(e);
															contractForm.setValue('isPaymentAtExtraPerformerAddress', !contractForm.watch('isPaymentAtPerformerAgencyAddress'));
														}}
														type='checkbox'
														disabled={props?.isPrint}
														style={{ marginTop: '3px' }}
													/>
													<span>
														<b>
																						To Performer c/o (Agent/Representative,
																						Address):
														</b>
													</span>
												</Box>
											</div>
										)}
									/>
								</td>
							</tr>
							<tr>
								<td>
								(Agent/Representative)
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										<Controller
											key={`agency-${contractForm.watch('performerId.value')}`}
											control={contractForm.control}
											name='performerAdAgencyId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														{...field} 
														value={contractForm.watch('performerAdAgencyId')}
														key={`agency-${contractForm.watch('performerId.value')}`}
														placeholder='Agency'
														filterProperty='CompanyNames.Name'
														APICaller={(filter) => {
															filter.filters = filter.filters?.map((f) => f.property == 'CompanyNames.Name' ? {...f, value: f?.value?.trim() || '' } : f) || [];
															return getAgentInfo(filter, contractForm.watch('performerId.value') || '');
														}}
														optionGenerator={companyOptionGenerator}
														onChange={(e: any) => {
															field.onChange(e);
															contractForm.setValue('performerAdAgencyAddressId', null, { shouldDirty: true});
															contractForm.setValue('performerAdAgencyPhoneId', '', { shouldDirty: true});
															onAddressChange(contractForm.watch('performerAdAgencyAddressId.value') || '', 'performerAdAgency');
														}}
														onOptionLoaded={(options) => {
															if(options?.length && !contractForm.watch('performerAdAgencyId.value')){
																contractForm.setValue('performerAdAgencyId', options[0], { shouldDirty: true});									
																contractForm.trigger('performerAdAgencyId', { shouldFocus: true});																					
															}
														}}
														cacheOptions
														isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
														defaultOptions
														dropDownsize={25}
														isClearable={true}
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
													{!props?.isPrint && contractForm.watch('performerId.value') && (<div style={{float:'right'}}><AddTalentAgentInline
														onCompanyCreated={(createdAgent) => contractForm.setValue('performerAdAgencyId', createdAgent, { shouldDirty: true})}
														onClose={() => setCompanyPopoverInline({ isOpen: false})}
														onPopOverOpen={(e) => setCompanyPopoverInline({ isOpen: true, event: e?.currentTarget })}
														event={companyPopoverInline?.event}
														isOpen={companyPopoverInline.isOpen}
														personId={contractForm.watch('performerId.value') || ''}
													/></div>)}
												</>
											)}
										/>
									</FormControl>
								</td>
								<td colSpan={2}>
								(Agent&apos;s Address)
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										<Controller
											control={contractForm.control}
											key={`select_Address
																			-${contractForm.watch('performerAdAgencyId.value')}
																			-${contractForm.watch('performerAdAgencyPhoneId')}`
											}
											name='performerAdAgencyAddressId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														{...field}
														placeholder={!props?.isPrint ? 'Select Address' : ''}
														key={`performer-ad-address-${contractForm.watch(
															'performerAdAgencyId.value'
														)}`}
														filterProperty='Line1'
														sortField='IsPrimary'
														sortDescending={true}
														onOptionLoaded={(options) => {
															if(options?.length && !contractForm.watch('performerAdAgencyAddressId.value')){
																contractForm.setValue('performerAdAgencyAddressId', options[0], { shouldDirty: true});
																contractForm.trigger('performerAdAgencyAddressId', { shouldFocus: true});	
																onAddressChange(contractForm.watch('performerAdAgencyAddressId.value') || '', 'performerAdAgency');																					
															}
														}}
														isDisabled={props?.isPrint || !contractForm.watch('performerAdAgencyId.value')}
														APICaller={(filterModel) =>
															getAllAddressByCompany(
																filterModel,
																contractForm.watch(
																	'performerAdAgencyId.value'
																) || ''
															)
														}
														optionGenerator={
															agentAddressOptionGenerator
														}
														dropDownsize={50}
														isClearable={true}
														isSearchText={true}
														onChange={(e: any) => {
															field.onChange(e);
															contractForm.setValue('performerAdAgencyPhoneId', '', { shouldDirty: true});
															onAddressChange(contractForm.watch('performerAdAgencyAddressId.value') || '', 'performerAdAgency');
														}}
														cacheOptions
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
													{
														<Box sx={{mt:1.3}}>
															{(contractForm.watch('performerAdAgencyId.value')&& (!props.isPrint)) &&
																							<AddCompanyAddressInline
																								companyId={contractForm.watch('performerAdAgencyId.value')}
																								title = 'ADDRESS'
																								isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'PerformerAdAgency-address'|| false}
																								event={addressPopOver?.event}
																								onAddressCreated={(d) => PerformerAdAgencyAddressCreated(d)}
																								onClose={() => setAddressPopOver({ isOpen: false})}
																								onPopOverOpen={(e) => setAddressPopOver({
																									isOpen: true,
																									event: e?.currentTarget,
																									for: 'PerformerAdAgency-address'
																								})}
																								isPersistent = {true} />
															}
														</Box>
													}
												</>
											)}
										/>
									</FormControl>
								</td>
								<td>
								(Agent&apos;s Phone)
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										<Controller
											control={contractForm.control}
											key={`select-phone-ctr${contractForm.watch(
												'performerAdAgencyPhoneId'
											)}-${contractForm.watch(
												'performerAdAgencyAddressId.value'
											)}`}
											name='performerAdAgencyPhoneId'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuSelect
														{...field}
														key={`select-phone-${contractForm.watch(
															'performerAdAgencyPhoneId'
														)}-${contractForm.watch(
															'performerAdAgencyAddressId.value'
														)}`}
														placeholder={!props?.isPrint ? 'Select Phone' : ''}
														convertValueToUpperCase={false}
														enableEmptySelection={true}
														disabled={props?.isPrint || !contractForm.watch('performerAdAgencyAddressId.value')}
														options={getPhoneNumberOptions(
															contractForm.watch(
																'performerAdAgencyAddressId.value'
															)
														)}
														handleDefaultSelection={() => !contractForm.watch('performerAdAgencyPhoneId')}
													/>
													<div style={{float:'right'}}> 
														{contractForm.watch('performerAdAgencyAddressId.value') &&
																			(!props?.isPrint) && (
														
														
															<AddPhoneNumberInline
																formData={{ ...new PhoneNumberFormModel()}}
																onSubmit={onPhoneNumberInlineSubmit}
																onClose={closePhoneNumberInlinePopOver}
																title='Phone Number'
																onPopOverOpen={(e) =>openPhoneNumberInlinePopOver(e, 'PerformerAdAgency-Adress')}
																event={phoneNumberPopOver?.event}
																isOpen={phoneNumberPopOver.isOpen && phoneNumberPopOver?.for == 'PerformerAdAgency-Adress' || false}																		/>
														)}
													</div>
												</>
												
											)}
										/>
									</FormControl>
								</td>
							</tr>
							<tr>
								<td>
								(ZIP)
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										<Controller
											control={contractForm.control}
											name='performerAdAgencyZip'
											defaultValue=''
											render={({ field }) => (
												<NabuTextbox
													{...field}
													placeholder={!props?.isPrint ? 'ZIP' : ''}
													disabled={props?.isPrint}
													onChange={(e) => {
														field.onChange(e);
														onZipCodeChange(
															contractForm.watch('performerAdAgencyZip') || '',
															'performerAdAgency'
														);
													}}
												/>
											)}
										/>
									</FormControl>
								</td>
								<td colSpan={2}>
								(State)
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										<Controller
											control={contractForm.control}
											name='performerAdAgencyState'
											defaultValue=''
											render={({ field }) => (
												<NabuSelect
													key={`select-state-${contractForm.watch('performerAdAgencyState')}`}
													{...field}
													disabled={props?.isPrint}
													options={getStateOptions()}
													convertValueToUpperCase={true}
													placeholder={!props?.isPrint ? 'Select State' : ''}
												/>
											)}
										/>
									</FormControl>
								</td>
								<td>
								(City)
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										<Controller
											control={contractForm.control}
											name='performerAdAgencyCity'
											defaultValue=''
											render={({ field }) => (
												<NabuSelect
													{...field}
													disabled={props?.isPrint}
													options={getCityOptions(
														contractForm.watch('performerAdAgencyState')
													)}
													convertValueToUpperCase={true}
													placeholder={!props?.isPrint ? 'Select City' : ''}
												/>
											)}
										/>
									</FormControl>
								</td>
							</tr>
						</table>
						<br></br>    
						<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
							<tr>
								<td colSpan={4} style={{ background: '#515151', color: '#FFF' }}>
									<p><b>SPECIAL PROVISIONS </b></p>
								</td>
							</tr>
							<tr>
								<td colSpan={4}>
									<div>
										{
											props?.isPrint ? (
												<p className={styles.descriptionPrintText}>{contractForm.watch('specialProvisions')}</p>
											) : (
												<div className={styles.textareaSection}>
													<Controller
														control={contractForm.control}
														name='specialProvisions'
														render={({ field }) => (
															<NabuBaseTextArea
																className={styles.specialProvision}
																wrap='wrap'
																{...field}
																disabled={props?.isPrint}
																placeholder={!props?.isPrint ? 'SPECIAL PROVISIONS' : ''}
																rows={5}
															/>
														)}
													/>
												</div>
											)
										}		
									</div>	
								</td>
							</tr>
							<tr>
								<td colSpan={4}>
									<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
									This contract is subject to all of the terms and conditions which pertain to Extra Performers in the applicable Commercials Contract. Employer of Record
	for income tax and unemployment insurance purposes is: <u>Highland Talent Payments Inc. {contractForm.watch('payrollVendorAddressId.label') ? contractForm.watch('payrollVendorAddressId.label'): '5885 Landerbrook Dr., Cleveland, OH 44124'}</u>
										{/* <FormControl
												className={styles.tableForms}
												style={{minWidth:'120px'}}
											>
												<Controller
													key={`payroll-address-${contractForm.watch(
														'payrollVendorAddressId.value'
													)}`}
													control={contractForm.control}
													name='payrollVendorAddressPrint'
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={props?.isPrint}
															value={contractForm.watch('payrollVendorAddressId') ?  contractForm.watch('payrollVendorAddressId.value') : '5885 Landerbrook Dr., Cleveland, OH 44124'}

														/>
													)}
												/>
											</FormControl> */}
	‘Confidential Information`&apos;` means trade secrets, confidential data, and other non-public confidential proprietary information (whether
	or not labeled as confidential) including any and all financial terms of and products involved in the production and any and all scripts
	whether communicated orally, in written form, or electronically. Confidential information does not include information that was lawfully in Performer`&apos;`s
	possession prior to being disclosed in connection with the employment of Performer, is now, or hereafter becomes generally known to the
	public, or that Performer rightfully obtained without restriction from a third party. Extra Performer acknowledges that Extra Performer has and
	will become aware of certain Confidential Information. Unless otherwise required by law, Extra Performer agrees that, without Producer`&apos;`s
	prior written approval, Extra Performer shall hold such Confidential Information in the strictest confidence and that Extra Performer
	will not disclose such Confidential Information to anyone (other than Extra Performer`&apos;`s representatives in the course of their duties
	to Extra Performer, which representatives shall be bound by the same restrictions as set forth in this Agreement) or utilize such
	Confidential Information for Extra Performer`&apos;`s benefit or for the benefit of a third party. Notwithstanding the foregoing, nothing herein
	shall prohibit Performer from disclosing Confidential Information concerning Extra Performer`&apos;`s wages, hours, and other terms and conditions of
	employment as that term is defined under Section 7 of the National Labor Relations Act. For clarity, except as set forth above, Producer may not
	demand or request that Extra Performer execute any non-disclosure agreement that has not been approved in advance and in writing by the Union.
									</div>
								</td>
							</tr>
						</table>
						<br></br>

						<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
							<tr>
								<td width={100}>
									PRODUCER BY
								</td>
								<td>
									<FormControl
										fullWidth
										className={styles.tableForms}
									>
										<Controller
											key={`producer-${contractForm.watch(
												'producerCompanyId.value'
											)}`}
											control={contractForm.control}
											name='producerPrintName'
											render={({ field }) => (
												<NabuTextbox
													{...field}
													disabled={true}
													placeholder={!props?.isPrint ? 'Producer' : ''}
													value={contractForm.watch('producerCompanyId.label')}
												/>
											)}
										/>
									</FormControl>
								</td>
								<td width={120}>
									EXTRA PERFORMER
								</td>
								<td>
									<FormControl
										fullWidth
										className={styles.tableForms}
									>
										<Controller
											key={`performer-${contractForm.watch(
												'performerId.value'
											)}`}
											control={contractForm.control}
											name='performerPrintName'
											render={({ field }) => (
												<NabuTextbox
													{...field}
													disabled={true}
													placeholder={!props?.isPrint ? 'Performer' : ''}
													value={contractForm.watch('performerId.label')}
												/>
											)}
										/>
									</FormControl>
								</td>

							</tr>
							<tr>
								<td width={100}>
									Producer Email
								</td>
								<td>
									<FormControl
										className={styles.tableForms}
										fullWidth
									>
										<Controller
											key={`select-em-${contractForm.watch(
												'producerCompanyId'
											)}`}
											control={contractForm.control}
											name='producerCompanyEmail'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuSelect
														key={`select-em-${contractForm.watch(
															'producerCompanyId'
														)}`}
														{...field}
														placeholder={!props?.isPrint ? 'Select Email' : ''}
														convertValueToUpperCase={false}
														enableEmptySelection={true}
														disabled={props?.isPrint || !contractForm.watch('producerCompanyId.value')}
														options={producerEmails}
														handleDefaultSelection={() => contractForm.watch('producerCompanyId.label') && !contractForm.watch('producerCompanyEmail')}
													/>
													{/* {
																							<Box sx={{mt:1.3}}>
																								{(contractForm.watch('producerCompanyAddressId.value') && (!props.isPrint)) &&
																							<AddEmailInline
																								formData={{ ...new EmailFormModel()}}
																								isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Producer-email-address'|| false}	
																								onSubmit={onProducerEmailInlineSubmit}
																								onClose={closeEmailInlinePopOver}
																								title='Email address'
																								onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Producer-email-address')}
																								event={emailPopOver?.event}
																							/>
																								}
																							</Box>	
																						} */}
												</>
											)}
										/>
									</FormControl>
								</td>
								<td width={120}>
									Performer Email 
								</td>
								<td>
									<FormControl
										fullWidth
										className={styles.emaillowerCase}
									>
										<Controller
											control={contractForm.control}
											key={`select-phone-ctr${contractForm.watch(
												'performerPhoneId'
											)}-${contractForm.watch(
												'performerId.value'
											)}`}
											name='performerEmailId'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuSelect
														{...field}
														key={`select-phone-${contractForm.watch(
															'performerPhoneId'
														)}-${contractForm.watch(
															'performerId.value'
														)}`}
														defaultCase={true}
														placeholder={!props?.isPrint ? 'Select Email' : ''}
														convertValueToUpperCase={false}
														enableEmptySelection={true}
														disabled={props?.isPrint || !contractForm.watch('performerId.value')}
														options={getPerformerEmailsOptions()}
														handleDefaultSelection={() => !contractForm.watch('performerEmailId')}
													/>
													{
														<Box>
															{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																							<AddEmailInline
																								formData={{ ...new EmailFormModel()}}
																								isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Performer-email-address'|| false}	
																								onSubmit={onPerformerEmailInlineSubmit}
																								onClose={closeEmailInlinePopOver}
																								title='Email address'
																								onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Performer-email-address')}
																								event={emailPopOver?.event}
																							/>
															}
														</Box>	
													}
												</>
											)}
										/>
									</FormControl>
								</td>

							</tr>
						</table>

						<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
							<p><b>Extra Performer hereby certifies that he or she is 21 years of age or over. (If under 21 years of age, this contract must be signed below by a parent or guardian.)</b></p>
								I, the undersigned, hereby state that I am the 
							<FormControl
								className={styles.tableForms}
								style={{minWidth:'280px'}}
							>
								<Controller
									key={`guardian-${contractForm.watch(
										'guardian'
									)}`}
									control={contractForm.control}
									name='guardian'
									render={({ field }) => (
										<NabuTextbox
											{...field}
											disabled={props?.isPrint}
											placeholder={!props?.isPrint ? '(Mother,Father or Guardian)' : ''}
										/>
									)}
								/>
							</FormControl>
									of the above name Extra Performer and do hereby consent and give my
								permission to this agreement.
						</div>
			
						<hr></hr>
						<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
								
							<FormControl sx={{minWidth:350}} className={styles.tableForms}>
								<NabuTextbox placeholder='(Signature of Parent or Guardian)' />
							</FormControl> 
								
						</div>
						<b>Signature of Parent or Guardian</b>
					</div>
				</div>
			</ContractMutationWrapper>
		</>
	);
};

export default SagAftraTvExtraHT;
