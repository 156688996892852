import { Autocomplete, TextField } from '@mui/material';
import { ComponentProps, forwardRef, useEffect } from 'react';

interface Props<T = string> extends Omit<ComponentProps<typeof Autocomplete<T>>, 'onChange'> {
	[key: string]: any;
	onChange?: (value: T, event?: any) => void;
	handleDefaultSelection?: (options: T[]) => boolean | undefined;
	shouldPushOption?: boolean;
}

// eslint-disable-next-line react/display-name
const NabuDynamicInputDropdown = forwardRef((props: Props, ref: any) => {

	const getCasing = (value?: string | null) => {
		return (props?.convertValueToUpperCase == false ? value : `${value || ''}`?.toUpperCase()) || '';
	};

	const getOptions = () => {
		if(props?.convertValueToUpperCase == false){
			return props?.options || [];
		}
		else {
			return props?.options?.map(op => op?.toUpperCase()) || [];
		}
	};

	// NOTE: This is to select the first option by default.
	// 'handleDefaultSelection' prop is a callback, that excepts the boolean value in return to select the 1st option on options loaded.
	// It provides the available options as callbacks argument. You can also select the custom logic and return false or undefined to not select the first option.
	useEffect(() => {
		if (
			props?.handleDefaultSelection &&
			props?.options &&
			props?.options.length > 0
		) {
			const shouldSelectFirstOne =
				props?.handleDefaultSelection((props?.options || []) as string[]) ?? false;
			if (shouldSelectFirstOne) {
				props?.onChange && props.onChange(props?.options[0]);
			}
		}
	}, [props?.options]);

	return (
		<>
			<Autocomplete			
				{...props}
				aria-label={props?.name}
				ref={ref}
				options={
					props?.value && !getOptions()?.includes(getCasing(props?.value)) ? [...getOptions(), getCasing(props?.value)] : getOptions()
				}
				renderInput={(params) => (
					<TextField
						{...params}
						placeholder={props?.placeholder}
					/>
				)}
				filterOptions={(options, params) => {
					const { inputValue } = params;
					const isExisting = options.some((option) => inputValue === option);
					if (inputValue !== '' && !isExisting) {
						props?.shouldPushOption ? options.push(getCasing(inputValue)) : options.unshift(getCasing(inputValue));
					}
					return options;
				}}
				onChange={(e, value) => {
					props?.onChange && props?.onChange(getCasing(value), e);
				}}
			/>
		</>
	);
});

export default NabuDynamicInputDropdown;
