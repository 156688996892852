import { CompanyMainType, CompanyType, IAddCompanyForm } from '@/common/types/company';
import {
	getBaseUrl
} from '@/common/utils/utils';
import style from './addPerson.module.scss';
import { useForm } from 'react-hook-form';
import AddIcon from '@mui/icons-material/Add';
import { useEffect } from 'react';

interface Props {
	onCompanyCreated?: CallableFunction;
	onClose?: VoidFunction;
	isOpen?: boolean;
	event?: HTMLButtonElement | null;
	onPopOverOpen?: CallableFunction;
	mainType?: CompanyMainType;
	subType?: string[];
	title?: string;
	companyType?: CompanyType;
	isPersistent?: boolean;
}

const AddCompanyInline = (props: Props) => {
	const { companyType } = props;
	const { reset } =
		useForm<IAddCompanyForm>({ defaultValues: { generalInfo: { companyNames: [{ isDisplayName: true, nameType: 'legalName', name: ''}], companyType}}, mode: 'onChange' });
	
	useEffect(() => {
		reset({ generalInfo: { companyNames: [{ isDisplayName: true, nameType: 'legalName', name: ''}], companyType: { mainType: companyType?.mainType ? companyType.mainType : '', subType: companyType?.subType ? companyType.subType : [] }}});
	}, []);

	useEffect(() => {
		reset({ generalInfo: { companyNames: [{ isDisplayName: true, nameType: 'legalName', name: ''}], companyType: { mainType: companyType?.mainType ? companyType.mainType : '', subType: companyType?.subType ? companyType.subType : [] }}});
	}, [companyType?.mainType]);

	const baseURL = getBaseUrl(window.location.href);
	const handleCreateAgent = () => {
		const link = document.createElement('a');
		link.href = companyType?.mainType ? `${baseURL}/#/add/company?mainType=${companyType?.mainType}${companyType?.subType.length >=1 ? '&subType='+JSON.stringify(companyType.subType) : ''}` : `${baseURL}/#/add/company`;
		link.target = '_blank';
		link.click();
	};

	return (
		<>
			<div
				onClick={handleCreateAgent}
				className={style.addIcon}
			>
				<AddIcon/>{props.title ? props.title : 'Advertiser'}
			</div>
		</>
	);
};

export default AddCompanyInline;
