import NabuButton from '@/common/components/atoms/button/button';
import { Grid, InputAdornment } from '@mui/material';
import styles from './form.module.scss';
import { useForm, Controller } from 'react-hook-form';
import { Add, Search } from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { OPERATORS } from '@/common/enums';
import { NabuSearchFilters } from '@/common/components/molecules/searchFIlter/searchFIlter';
import { IFilterOption } from '@/common/types/filterModel';
import { TemplateFilterModel } from '../shared/models/template';

interface ISnippetFilterProps {
  onChange?: any;
  openTemplateForm?: any;
}

const TemplateFilters = (props: ISnippetFilterProps) => {
	const [state, setStateData] = useState({
		searchText: {
			filter: {},
			isSet: false,
		}
	} as any);

	const filterForm = useForm<TemplateFilterModel>({
		defaultValues: {...new TemplateFilterModel()},
		mode: 'onChange'
	});

	useEffect(() => {
		let filters: any = [];
		if(state.searchText.isSet){
			filters = [...filters, state.searchText.filter];
		}
		props.onChange(filters);
	}, [state.searchText]);

	const containsOnlyNumbers = (inputString: string) => {
		const regex = /^[0-9]+$/;
		return regex.test(inputString);
	};

	const handleFilters = (filterName: string, filter: IFilterOption) => {
		if(containsOnlyNumbers(filter.value)){
			filter.property = 'SeriesNo',
			filter.operator = OPERATORS.EQUAL;
		}

		setStateData((currentData: any) => {
			return {
				...currentData,
				[filterName]: {
					isSet: true,
					filter
				}
			};
		});
	};

	return <>
		<Grid item container sx={{ mb: 3 }} >
			<Grid item xs={6} md={6} >
				<Controller
					control={filterForm.control}
					name='searchText'
					defaultValue=''
					render={({ field }) => (								
						<NabuSearchFilters
							className={styles.searchMain}
							{...field}
							fullWidth
							id='search-input-text-field'
							placeholder='Search by name'
							variant='outlined'
							onChange={(event: any) => {field.onChange(event); handleFilters('searchText', {
								value: filterForm.getValues().searchText,
								operator: OPERATORS.CONTAINS,
								property: 'TemplateName',
								isCaseSensitive: false
							});}}
							size='small'
							InputProps={{
								startAdornment: (
									<InputAdornment position='start'  >
										<Search/>
									</InputAdornment>
								),
							}}
							data-cy='searchInputFilterTextBox'
						/>
					)}
				/>
			</Grid>
			<Grid item xs={6} md={6} dir='rtl'>
				<NabuButton variant='filled' onClick={props.openTemplateForm && props.openTemplateForm}>
          Add Template<Add/>
				</NabuButton>
			</Grid>
		</Grid>
	</>;
};

export default TemplateFilters;