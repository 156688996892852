import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import { IBaseContract } from '@/common/types/contract';
import { FormControl, FormHelperText, Link } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import styles from '../forms/forms.module.scss';
import { CONTRACT_REF_ID } from '@/common/constants/constants';

interface Props {
	contractForm: UseFormReturn<IBaseContract | any, any, undefined>;
	isPrint?: boolean;
}

const ContractW8BenShortForm = (props: Props) => {
	const contractForm = props?.contractForm as UseFormReturn<IBaseContract, any, undefined>;

	return (
		<>
			<div className={styles.contractFormBlock}>
				<div className={styles.bgWhite}>
					<div className={styles.bgWhite} id={CONTRACT_REF_ID.W8_BEN_SHORT_FORM_PART_1} style={{padding:'20px'}}>
						<table className={styles.contractForms}  border={1} cellPadding={0} cellSpacing={0} width={'100%'}>
							<tbody>
								<tr>
									<td  rowSpan={2} width={250}>
														Form <span style={{fontSize:'40px', fontWeight:'bold'}}>W-8BEN</span>
										<p>
										(Rev. October 2021)<br></br>
										Department of the Treasury<br></br>
						Internal Revenue Service
										</p>
						
									</td>
									<td rowSpan={2} align='center' color='black'>
										<h5 style={{fontSize:'20px'}}>Certificate of Foreign Status of Beneficial Owner for United<br></br>
						States Tax Withholding and Reporting (Individuals)</h5><br></br>
										<h6 className={styles.headingLast}>▶ For use by individuals. Entities must use Form W-8BEN-E.</h6>
										<h6 className={styles.headingLast}>▶ Go to <Link><em>www.irs.gov/FormW8BEN</em></Link> for instructions and the latest information.</h6>
										<h6 className={styles.headingLast}>▶ Give this form to the withholding agent or payer. Do not send to the IRS</h6>
									</td>
									<td align='center' width={250} rowSpan={2}>OMB No.
										<FormControl className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='contractW8BenShortForm.ombNo'
												defaultValue='1545-0074'
												render={({ field }) => (
													<>
														<NabuTextbox {...field} disabled={props?.isPrint}/>
													</>
												)}
											/>
										</FormControl>
										{contractForm?.formState?.errors?.contractW8BenShortForm?.ombNo?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW8BenShortForm?.ombNo?.message
												}
											</FormHelperText>
										)}
									</td>
								</tr>
							</tbody>
						</table>
						<table className={styles.contractForms}  border={1} cellPadding={0} cellSpacing={0} width={'100%'} style={{borderTop:'0px'}}>
							<tbody>
								<tr>
									<td >
										<b>Do NOT use this form if: </b>
									</td>
									<td align='right' width={150}>
										<b>Instead, use Form:</b>
									</td>
								</tr>
								<tr>
									<td>
									You are NOT an individual
									</td>
									<td align='right'>
									W-8BEN-E
									</td>
								</tr>
								<tr>
									<td>
									You are a U.S. citizen or other U.S. person, including a resident alien individual
									</td>
									<td align='right'>
									W-8BEN-E
									</td>
								</tr>
								<tr>
									<td>
									You are a beneficial owner claiming that income is effectively connected with the conduct of trade or business within the United States
						(other than personal services)
									</td>
									<td align='right'>
									W-8ECI
									</td>
								</tr>
								<tr>
									<td>
									You are a beneficial owner who is receiving compensation for personal services performed in the United States
									</td>
									<td align='right'>
									8233 or W-4
									</td>
								</tr>
								<tr>
									<td>
									You are a person acting as an intermediary
									</td>
									<td align='right'>
									W-8IMY
									</td>
								</tr>
							</tbody>
						</table>
						<hr></hr>
						<p>
							<b>Note: </b>If you are resident in a FATCA partner jurisdiction (that is, a Model 1 IGA jurisdiction with reciprocity), certain tax account information may be
						provided to your jurisdiction of residence.
						</p><br></br>
						<table cellPadding={0} cellSpacing={0} width={'100%'}>
							<tr>
								<td colSpan={2} style={{padding:'0'}}>
									<div className={styles.partSection}>
										<b>Part I</b> <span style={{fontWeight:'600'}}>Identification of Beneficial Owner</span> (see instructions)
									</div>
								</td>
							</tr>
						</table>
						<br></br>
						<table className={styles.contractForms}  border={1} cellPadding={0} cellSpacing={0} width={'100%'} >
							<tbody>
								<tr>
									<td >
										<ol style={{margin:'0'}}>
											<li value={1}>
											Name of individual who is the beneficial owner
												<FormControl fullWidth className={styles.tableForms}>
													<Controller
														control={contractForm.control}
														name='contractW8BenShortForm.beneficialOwnerName'
														defaultValue=''
														render={({ field }) => (
															<>
																<NabuTextbox {...field} disabled={props?.isPrint}/>
															</>
														)}
													/>
													{contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerName?.message && (
														<FormHelperText error={true}>
															{
																contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerName?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</li>
										</ol>
									</td>
									<td >
										<ol style={{margin:'0'}}>
											<li value={2}>
											Country of citizenship
												<FormControl fullWidth className={styles.tableForms}>
													<Controller
														control={contractForm.control}
														name='contractW8BenShortForm.beneficialOwnerCitizenshipCountry'
														defaultValue=''
														render={({ field }) => (
															<>
																<NabuTextbox {...field} disabled={props?.isPrint}/>
															</>
														)}
													/>
													{contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerCitizenshipCountry?.message && (
														<FormHelperText error={true}>
															{
																contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerCitizenshipCountry?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</li>
										</ol>
									</td>
								</tr>
								<tr>
									<td colSpan={2}>
										<ol style={{margin:'0'}}>
											<li value={3}>
											Permanent residence address (street, apt. or suite no., or rural route). <b>Do not use a P.O. box or in-care-of address.</b>
												<FormControl fullWidth className={styles.tableForms}>
													<Controller
														control={contractForm.control}
														name='contractW8BenShortForm.beneficialOwnerPermanentAddress'
														defaultValue=''
														render={({ field }) => (
															<>
																<NabuTextbox {...field} disabled={props?.isPrint}/>
															</>
														)}
													/>
													{contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerPermanentAddress?.message && (
														<FormHelperText error={true}>
															{
																contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerPermanentAddress?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</li>
										</ol>
									</td>
								</tr>
								<tr>
									<td>
										<ul style={{margin:'0'}}>
											<li style={{listStyle:'none'}}>
											City or town, state or province. Include postal code where appropriate.
												<FormControl fullWidth className={styles.tableForms}>
													<Controller
														control={contractForm.control}
														name='contractW8BenShortForm.beneficialOwnerCityState'
														defaultValue=''
														render={({ field }) => (
															<>
																<NabuTextbox {...field} disabled={props?.isPrint}/>
															</>
														)}
													/>
													{contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerCityState?.message && (
														<FormHelperText error={true}>
															{
																contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerCityState?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</li>
										</ul>
									</td>
									<td>
										<ul style={{margin:'0'}}>
											<li style={{listStyle:'none'}}>
											Country
												<FormControl fullWidth className={styles.tableForms}>
													<Controller
														control={contractForm.control}
														name='contractW8BenShortForm.beneficialOwnerCountry'
														defaultValue=''
														render={({ field }) => (
															<>
																<NabuTextbox {...field} disabled={props?.isPrint}/>
															</>
														)}
													/>
													{contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerCountry?.message && (
														<FormHelperText error={true}>
															{
																contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerCountry?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<td colSpan={2}>
										<ol style={{margin:'0'}}>
											<li value={4}>
											Mailing address (if different from above)
												<FormControl fullWidth className={styles.tableForms}>
													<Controller
														control={contractForm.control}
														name='contractW8BenShortForm.beneficialOwnerMailingAddress'
														defaultValue=''
														render={({ field }) => (
															<>
																<NabuTextbox {...field} disabled={props?.isPrint}/>
															</>
														)}
													/>
													{contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerMailingAddress?.message && (
														<FormHelperText error={true}>
															{
																contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerMailingAddress?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</li>
										</ol>
									</td>
								</tr>
								<tr>
									<td>
										<ul style={{margin:'0'}}>
											<li style={{listStyle:'none'}}>
											City or town, state or province. Include postal code where appropriate.
												<FormControl fullWidth className={styles.tableForms}>
													<Controller
														control={contractForm.control}
														name='contractW8BenShortForm.beneficialOwnerMailingCityState'
														defaultValue=''
														render={({ field }) => (
															<>
																<NabuTextbox {...field} disabled={props?.isPrint}/>
															</>
														)}
													/>
													{contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerMailingCityState?.message && (
														<FormHelperText error={true}>
															{
																contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerMailingCityState?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</li>
										</ul>
									</td>
									<td>
										<ul style={{margin:'0'}}>
											<li style={{listStyle:'none'}}>
											Country
												<FormControl fullWidth className={styles.tableForms}>
													<Controller
														control={contractForm.control}
														name='contractW8BenShortForm.beneficialOwnerMailingCountry'
														defaultValue=''
														render={({ field }) => (
															<>
																<NabuTextbox {...field} disabled={props?.isPrint}/>
															</>
														)}
													/>
													{contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerMailingCountry?.message && (
														<FormHelperText error={true}>
															{
																contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerMailingCountry?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<td colSpan={2}>
										<ol style={{margin:'0'}}>
											<li value={5}>
											U.S. taxpayer identification number (SSN or TIN), if required (see instructions)
												<FormControl fullWidth className={styles.tableForms}>
													<Controller
														control={contractForm.control}
														name='contractW8BenShortForm.beneficialOwnerSsn'
														defaultValue=''
														render={({ field }) => (
															<>
																<NabuTextbox {...field} disabled={props?.isPrint}/>
															</>
														)}
													/>
													{contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerSsn?.message && (
														<FormHelperText error={true}>
															{
																contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerSsn?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</li>
										</ol>
									</td>
								</tr>
								<tr>
									<td>
										<ul style={{margin:'0'}}>
											<li style={{listStyle:'none'}}>
										6a. Foreign tax identifying number (see instructions)
												<FormControl fullWidth className={styles.tableForms}>
													<Controller
														control={contractForm.control}
														name='contractW8BenShortForm.beneficialOwnerForeignTaxIdentifyingNumber'
														defaultValue=''
														render={({ field }) => (
															<>
																<NabuTextbox {...field} disabled={props?.isPrint}/>
															</>
														)}
													/>
													{contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerForeignTaxIdentifyingNumber?.message && (
														<FormHelperText error={true}>
															{
																contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerForeignTaxIdentifyingNumber?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</li>
										</ul>
									</td>
									<td>
										<ul style={{margin:'0'}}>
											<li style={{listStyle:'none'}}>
												<Controller
													control={contractForm.control}
													name='contractW8BenShortForm.isFtinLegallyRequired'
													defaultValue={false}
													render={({ field }) => (
														<div
															onClick={() => field.onChange(!field.value)}
															style={{ userSelect: 'none' }}
														>
															<span>6b. Check if FTIN not legally required</span>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ width: '20px', marginTop: '2px' }}
															/>
														</div>
													)}
												/>
											</li>
										</ul>
									</td>
								</tr>
								<tr>
									<td>
										<ol style={{margin:'0'}}>
											<li value={7}>
												Reference number(s) (see instructions)
												<FormControl fullWidth className={styles.tableForms}>
													<Controller
														control={contractForm.control}
														name='contractW8BenShortForm.referenceNumber'
														defaultValue=''
														render={({ field }) => (
															<>
																<NabuTextbox {...field} disabled={props?.isPrint}/>
															</>
														)}
													/>
													{contractForm?.formState?.errors?.contractW8BenShortForm?.referenceNumber?.message && (
														<FormHelperText error={true}>
															{
																contractForm?.formState?.errors?.contractW8BenShortForm?.referenceNumber?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</li>
										</ol>
									</td>
									<td>
										<ol style={{margin:'0'}}>
											<li value={8}>
												Date of birth (MM-DD-YYYY) (see instructions)
												<FormControl fullWidth className={styles.tableForms}>
													<Controller
														control={contractForm.control}
														name='contractW8BenShortForm.beneficialOwnerDateOfBirth'
														defaultValue=''
														render={({ field }) => (
															<>
																<NabuDatepicker {...field} isDOB={true} disabled={props?.isPrint} />
															</>
														)}
													/>
													{contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerDateOfBirth?.message && (
														<FormHelperText error={true}>
															{
																contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerDateOfBirth?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</li>
										</ol>
									</td>
								</tr>
							</tbody>
						</table><br></br>
					</div>

					<div className={styles.bgWhite} id={CONTRACT_REF_ID.W8_BEN_SHORT_FORM_PART_2} style={{padding:'20px'}}>
						<table cellPadding={0} cellSpacing={0} width={'100%'}>
							<tr>
								<td colSpan={2} style={{padding:'0'}}>
									<div className={styles.partSection}>
										<b>Part II</b> <span style={{fontWeight:'600'}}>Claim of Tax Treaty Benefits</span> (for chapter 3 purposes only) (see instructions)
									</div>
								</td>
							</tr>
						</table>
						<ol className={styles.tableList}>
							<li value={9}>
								<div style={{display:'flex', alignItems:'center',flexWrap:'wrap',}}>
									<span>I certify that the beneficial owner is a resident of</span>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW8BenShortForm.beneficialOwnerResidentOf'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerResidentOf?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW8BenShortForm?.beneficialOwnerResidentOf?.message
												}
											</FormHelperText>
										)}
									</FormControl>
									<span>within the meaning of the income tax treaty between the United States and that country. </span>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW8BenShortForm.incomeTaxTreatyCountry'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW8BenShortForm?.incomeTaxTreatyCountry?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW8BenShortForm?.incomeTaxTreatyCountry?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</div>
							</li>
						</ol>
						<ol className={styles.tableList}>
							<li value={10}>
								<div style={{display:'flex', alignItems:'center',flexWrap:'wrap', }}>
									<span><b>Special rates and conditions</b> (if applicable—see instructions): The beneficial owner is claiming the provisions of Article and paragraph</span>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW8BenShortForm.provisions'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW8BenShortForm?.provisions?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW8BenShortForm?.provisions?.message
												}
											</FormHelperText>
										)}
									</FormControl>
									<span>of the treaty identified on line 9 above to claim a </span>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW8BenShortForm.claim'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW8BenShortForm?.claim?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW8BenShortForm?.claim?.message
												}
											</FormHelperText>
										)}
									</FormControl>
									<span>% rate of withholding on (specify type of income):</span>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW8BenShortForm.withholding'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW8BenShortForm?.withholding?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW8BenShortForm?.withholding?.message
												}
											</FormHelperText>
										)}
									</FormControl>
									<span>Explain the additional conditions in the Article and paragraph the beneficial owner meets to be eligible for the rate of withholding:</span> 
									<FormControl className={styles.tableForms} style={{width:'250px'}}>
										<Controller
											control={contractForm.control}
											name='contractW8BenShortForm.additionalWithholding'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
										{contractForm?.formState?.errors?.contractW8BenShortForm?.additionalWithholding?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractW8BenShortForm?.additionalWithholding?.message
												}
											</FormHelperText>
										)}
									</FormControl>
								</div>
							</li>
						</ol>
						<br></br>
						<table cellPadding={0} cellSpacing={0} width={'100%'}>
							<tr>
								<td colSpan={2} style={{padding:'0'}}>
									<div className={styles.partSection}>
										<b>Part |||</b> <span style={{fontWeight:'600'}}>Certification</span>
									</div>
								</td>
							</tr>
						</table>
						<p>Under penalties of perjury, I declare that I have examined the information on this form and to the best of my knowledge and belief it is true, correct, and complete. I further certify under penalties of perjury that:</p>
						<ul>
							<li>
							I am the individual that is the beneficial owner (or am authorized to sign for the individual that is the beneficial owner) of all the income or proceeds to which this form
						relates or am using this form to document myself for chapter 4 purposes;
							</li>
							<li>
							The person named on line 1 of this form is not a U.S. person;
							</li>
							<li>
								This form relates to:
							</li>
							<li style={{listStyle:'none'}}>
								(a) income not effectively connected with the conduct of a trade or business in the United States;
							</li>
							<li style={{listStyle:'none'}}>
								(b) income effectively connected with the conduct of a trade or business in the United States but is not subject to tax under an applicable income tax treaty;
							</li>
							<li style={{listStyle:'none'}}>
								(c) the partner’s share of a partnership’s effectively connected taxable income; or
							</li>
							<li style={{listStyle:'none'}}>
								(d) the partner’s amount realized from the transfer of a partnership interest subject to withholding under section 1446(f);
							</li>
							<li>
								The person named on line 1 of this form is a resident of the treaty country listed on line 9 of the form (if any) within the meaning of the income tax treaty between the United States and that country; and
							</li>
							<li>
								For broker transactions or barter exchanges, the beneficial owner is an exempt foreign person as defined in the instructions.
							</li>
						</ul><br></br>
						<p>
						Furthermore, I authorize this form to be provided to any withholding agent that has control, receipt, or custody of the income of which I am the beneficial owner or any withholding agent that can
						disburse or make payments of the income of which I am the beneficial owner. I agree that I will submit a new form within 30 days if any certification made on this form becomes incorrect.
						</p><br></br>
						<table cellPadding={0} cellSpacing={0} width={'100%'}>
							<tr>
								<td width={150}>
									<h3>
									Sign Here
									</h3>
								</td>
								<td>
									<FormControl className={styles.tableForms}>
										<div style={{display:'flex'}}>
											<Controller
												control={contractForm.control}
												name='contractW8BenShortForm.isSignatureCertified'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<input
															{...field}
															value={field.value + ''}
															checked={field.value}
															onChange={(e) => field.onChange(e)}
															type='checkbox'
															disabled={props?.isPrint}
															style={{ width: '20px', marginTop: '2px' }}
														/>
														<span>I certify that I have the capacity to sign for the person identified on line 1 of this form</span>
													</div>
												)}
											/>
										</div>
									</FormControl><br></br>
									<p>Signature of beneficial owner (or individual authorized to sign for beneficial owner)</p>
									<FormControl fullWidth className={styles.tableForms}>
										<NabuTextbox disabled />
									</FormControl>
									<p>Print name of signer</p>
									<FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractW8BenShortForm.signerPrintName'
											defaultValue=''
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractW8BenShortForm?.signerPrintName?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractW8BenShortForm?.signerPrintName?.message
											}
										</FormHelperText>
									)}
								</td>
								<td width={300}>
									Date (MM-DD-YYYY)
									<FormControl fullWidth className={styles.tableForms}>
										<NabuTextbox disabled />
									</FormControl>
								</td>
							</tr>
						</table>
						<hr></hr>
						<table cellPadding={0} cellSpacing={0} width={'100%'}>
							<tr>
								<td >
								For Paperwork Reduction Act Notice, see separate instructions.
								</td>
								<td align='center'>
								Cat. No. 25047Z
								</td>
								<td align='right'>
								Form <b>W-8BEN</b> (Rev. 10-2021)
								</td>
							</tr>
						</table>
					</div>
				</div>
			</div>           

		</>


	);
};

export default ContractW8BenShortForm;
