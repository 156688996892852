import { InputNumber, InputNumberProps } from 'antd';

const LengthSelector = (props: InputNumberProps<number>) => {
	const MAX_SEC = 60;

	return (
		<>
			<InputNumber 
				min={0}
				formatter={(rowValue) => {
					const value = rowValue || 0;
					
					const sec = (value % MAX_SEC).toString().padStart(2, '0');
					const min = (Math.floor(value / MAX_SEC)).toString().padStart(2, '0');																				
					
					if(value == MAX_SEC) return `00 : ${value}`;
					return `${min} : ${sec}`;
				}}
				parser={(value?: string) => {
					// NOTE: when user uses the Min:Sec format, we need to convert it.
					if(value?.includes(':')) {
						const [min, sec] = value?.split(':') || [];
						return (parseInt(min || '0') * MAX_SEC) + parseInt(sec || '0');
					}
					//NOTE: if the user clears and directly enters the seconds, we need to convert it.
					return parseInt(value || '0');
				}}
				changeOnWheel={true}
				size='middle'
				onBeforeInputCapture={(e: any) => {
					if(isNaN(Number(e?.data))){
						e?.preventDefault();
					}					
				}}
				{...props}
			/>
		</>
	);
};

export default LengthSelector;