import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import { IBaseContract } from '@/common/types/contract';
import { FormControl, FormHelperText } from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';
import styles from '../forms/forms.module.scss';
import { CONTRACT_REF_ID } from '@/common/constants/constants';

interface Props {
	contractForm: UseFormReturn<IBaseContract | any, any, undefined>;
	isPrint?: boolean;
}

const ContractCAWageTheftPreventionForm = (props: Props) => {
	const contractForm = props?.contractForm as UseFormReturn<IBaseContract, any, undefined>;

	return (
		<>
			<div id={CONTRACT_REF_ID.CA_WAGE_THEFT_PREVENTION_FORM} className={styles.contractFormBlock} style={{padding:'20px'}}>
				<div className={styles.bgWhite}>
					<h1 className={styles.nyFormTitle}>CA Wage Theft Prevention Form </h1>
					<p>
						<b>NOTICE TO EMPLOYEE AND ACKNOWLEDGMENT OF PAY RATE AND PAY DAY</b>
					</p>
					<br></br>
					<p>
						In Compliance with California State Labor Law under Section 2810.5
					</p>
					<br></br>

					<table
						className={styles.contractForms}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
					>
						<tbody>
							<tr style={{ background: '#00b0b9' }}>
								<td colSpan={4} style={{ color: '#FFF' }}>
									EMPLOYEE INFORMATION
								</td>
							</tr>
							<tr>
								<td width={120}>Employee Name</td>
								<td>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractCATheftPreventionForm.employeeName'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractCATheftPreventionForm?.employeeName?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractCATheftPreventionForm?.employeeName?.message
											}
										</FormHelperText>
									)}
								</td>
								<td width={120}>Hire Date</td>
								<td>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractCATheftPreventionForm.hireDate'
											render={({ field }) => (
												<>
													<NabuDatepicker {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractCATheftPreventionForm?.hireDate?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractCATheftPreventionForm?.hireDate?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
						</tbody>
					</table>
					<table
						className={styles.contractForms}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
						style={{ borderTop: '0px' }}
					>
						<tbody>
							<tr style={{ background: '#00b0b9' }}>
								<td colSpan={4} style={{ color: '#FFF' }}>
									EMPLOYER OF RECORD AND WORKERS COMPENSATION INSURANCE
									INFORMATION
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<div style={{ display: 'flex', gap: '5px' }}>
										<div style={{ width: '150px' }}>Name :</div>
										<FormControl className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='contractCATheftPreventionForm.employerName'
												render={({ field }) => (
													<>
														<NabuTextbox {...field} disabled={props?.isPrint}/>
													</>
												)}
											/>
										</FormControl>
										{contractForm?.formState?.errors?.contractCATheftPreventionForm?.employerName?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractCATheftPreventionForm?.employerName?.message
												}
											</FormHelperText>
										)}
									</div>
									<div style={{ display: 'flex', gap: '5px' }}>
										<div style={{ width: '150px' }}>Address</div>
										<div>
											<FormControl className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='contractCATheftPreventionForm.employerAddress'
													render={({ field }) => (
														<>
															<NabuTextbox {...field} disabled={props?.isPrint}/>
														</>
													)}
												/>
											</FormControl>
											{contractForm?.formState?.errors?.contractCATheftPreventionForm?.employerAddress?.message && (
												<FormHelperText error={true}>
													{
														contractForm?.formState?.errors?.contractCATheftPreventionForm?.employerAddress?.message
													}
												</FormHelperText>
											)}
										</div>
									</div>
								</td>
								<td colSpan={2}>
									<div style={{ display: 'flex', gap: '5px' }}>
										<div style={{ width: '150px' }}>Insurance :</div>
										<div><FormControl className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='contractCATheftPreventionForm.employerInsurance'
												render={({ field }) => (
													<>
														<NabuTextbox {...field} disabled={props?.isPrint}/>
													</>
												)}
											/>
										</FormControl>
										{contractForm?.formState?.errors?.contractCATheftPreventionForm?.employerInsurance?.message && (
											<FormHelperText error={true}>
												{
													contractForm?.formState?.errors?.contractCATheftPreventionForm?.employerInsurance?.message
												}
											</FormHelperText>
										)}</div>
									</div>
									<div style={{ display: 'flex', gap: '5px' }}>
										<div style={{ width: '150px' }}>Contact</div>
										<div>
											<FormControl className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='contractCATheftPreventionForm.employerContact'
													render={({ field }) => (
														<>
															<NabuTextbox {...field} disabled={props?.isPrint}/>
														</>
													)}
												/>
											</FormControl>
											{contractForm?.formState?.errors?.contractCATheftPreventionForm?.employerContact?.message && (
												<FormHelperText error={true}>
													{
														contractForm?.formState?.errors?.contractCATheftPreventionForm?.employerContact?.message
													}
												</FormHelperText>
											)}
										</div>
									</div>
								</td>
							</tr>
						</tbody>
					</table>
					<table
						className={styles.contractForms}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
						style={{ borderTop: '0px' }}
					>
						<tbody>
							<tr style={{ background: '#00b0b9' }}>
								<td colSpan={3} style={{ color: '#FFF' }}>
									HIRING EMPLOYER INFORMATION
								</td>
							</tr>
							<tr>
								<td>
									<p>Name</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractCATheftPreventionForm.hiringEmployerName'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractCATheftPreventionForm?.hiringEmployerName?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractCATheftPreventionForm?.hiringEmployerName?.message
											}
										</FormHelperText>
									)}
								</td>
								<td>
									<p>Address</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractCATheftPreventionForm.hiringEmployerAddress'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractCATheftPreventionForm?.hiringEmployerAddress?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractCATheftPreventionForm?.hiringEmployerAddress?.message
											}
										</FormHelperText>
									)}
								</td>
								<td>
									<p>Phone Number</p>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractCATheftPreventionForm.hiringEmployerPhone'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractCATheftPreventionForm?.hiringEmployerPhone?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractCATheftPreventionForm?.hiringEmployerPhone?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
						</tbody>
					</table>
					<table
						className={styles.contractForms}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
						style={{ borderTop: '0px' }}
					>
						<tbody>
							<tr style={{ background: '#00b0b9' }}>
								<td style={{ color: '#FFF' }} colSpan={4}>
									WAGE INFORMATION
								</td>
							</tr>
							<tr>
								<td width={150}>
									<p>Rate(s) of Pay:</p>
								</td>
								<td>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractCATheftPreventionForm.rateOfPay'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractCATheftPreventionForm?.rateOfPay?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractCATheftPreventionForm?.rateOfPay?.message
											}
										</FormHelperText>
									)}
								</td>
								<td width={200}>
									<p>Overtime Rate(s) of Pay:</p>
								</td>
								<td>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractCATheftPreventionForm.overtimeRateOfPay'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractCATheftPreventionForm?.overtimeRateOfPay?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractCATheftPreventionForm?.overtimeRateOfPay?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
							<tr>
								<td colSpan={4}>
									<div style={{ display: 'flex', flexWrap: 'wrap' }}>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												flexWrap: 'wrap',
												gap: '5px',
											}}
										>
											<div
												style={{
													display: 'flex',
													minWidth: '10%',
													alignItems: 'center',
													gap: '5px',
												}}
											>
												<span>Rate(s) of Pay:</span>
											</div>
											<div
												style={{
													display: 'flex',
													minWidth: '10%',
													alignItems: 'center',
													gap: '5px',
												}}
											>
												<Controller
													control={contractForm.control}
													name='contractCATheftPreventionForm.isHourlyRateOfPay'
													defaultValue={false}
													render={({ field }) => (
														<div
															onClick={() => field.onChange(!field.value)}
															style={{ userSelect: 'none' }}
														>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ width: '20px', marginTop: '2px' }}
															/>
															<span>Hour</span>
														</div>
													)}
												/>
											</div>
											<div
												style={{
													display: 'flex',
													minWidth: '10%',
													alignItems: 'center',
													gap: '5px',
												}}
											>
												<Controller
													control={contractForm.control}
													name='contractCATheftPreventionForm.isDailyRateOfPay'
													defaultValue={false}
													render={({ field }) => (
														<div
															onClick={() => field.onChange(!field.value)}
															style={{ userSelect: 'none' }}
														>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ width: '20px', marginTop: '2px' }}
															/>
															<span>Day (8hrs)</span>
														</div>
													)}
												/>
											</div>
											<div
												style={{
													display: 'flex',
													minWidth: '10%',
													alignItems: 'center',
													gap: '5px',
												}}
											>
												<Controller
													control={contractForm.control}
													name='contractCATheftPreventionForm.isUseFeeRateOfPay'
													defaultValue={false}
													render={({ field }) => (
														<div
															onClick={() => field.onChange(!field.value)}
															style={{ userSelect: 'none' }}
														>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ width: '20px', marginTop: '2px' }}
															/>
															<span>Use Fee</span>
														</div>
													)}
												/>
											</div>
											<div
												style={{
													display: 'flex',
													minWidth: '10%',
													alignItems: 'center',
													gap: '5px',
												}}
											>
												<Controller
													control={contractForm.control}
													name='contractCATheftPreventionForm.isOtherRateOfPay'
													defaultValue={false}
													render={({ field }) => (
														<div
															onClick={() => field.onChange(!field.value)}
															style={{ userSelect: 'none' }}
														>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ width: '20px', marginTop: '2px' }}
															/>
															<span>Other:</span>
														</div>
													)}
												/>
												<FormControl className={styles.tableForms}>
													<Controller
														control={contractForm.control}
														name='contractCATheftPreventionForm.otherRateOfPay'
														render={({ field }) => (
															<>
																<NabuTextbox {...field} disabled={props?.isPrint}/>
															</>
														)}
													/>
												</FormControl>
												{contractForm?.formState?.errors?.contractCATheftPreventionForm?.otherRateOfPay?.message && (
													<FormHelperText error={true}>
														{
															contractForm?.formState?.errors?.contractCATheftPreventionForm?.otherRateOfPay?.message
														}
													</FormHelperText>
												)}
											</div>
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<td colSpan={4}>
									<div style={{ display: 'flex', flexWrap: 'wrap' }}>
										<div
											style={{
												display: 'flex',
												alignItems: 'center',
												flexWrap: 'wrap',
												gap: '5px',
											}}
										>
											<div
												style={{
													display: 'flex',
													minWidth: '10%',
													alignItems: 'center',
													gap: '5px',
												}}
											>
												<span>Employment agreement is:</span>
											</div>
											<div
												style={{
													display: 'flex',
													minWidth: '10%',
													alignItems: 'center',
													gap: '5px',
												}}
											>
												<Controller
													control={contractForm.control}
													name='contractCATheftPreventionForm.isEmploymentAgreementOral'
													defaultValue={false}
													render={({ field }) => (
														<div
															onClick={() => field.onChange(!field.value)}
															style={{ userSelect: 'none' }}
														>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ width: '20px', marginTop: '2px' }}
															/>
															<span>Oral</span>
														</div>
													)}
												/>
											</div>
											<div
												style={{
													display: 'flex',
													minWidth: '10%',
													alignItems: 'center',
													gap: '5px',
												}}
											>
												<Controller
													control={contractForm.control}
													name='contractCATheftPreventionForm.isEmploymentAgreementWritten'
													defaultValue={false}
													render={({ field }) => (
														<div
															onClick={() => field.onChange(!field.value)}
															style={{ userSelect: 'none' }}
														>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ width: '20px', marginTop: '2px' }}
															/>
															<span>Written</span>
														</div>
													)}
												/>
											</div>
										</div>
									</div>
								</td>
							</tr>
							<tr>
								<td colSpan={2}>
									<span>
										Allowances claimed as part of minimum wage (including meal
										or lodging):{' '}
									</span>
								</td>
								<td colSpan={2}>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractCATheftPreventionForm.minimumAllowanceClaimed'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractCATheftPreventionForm?.minimumAllowanceClaimed?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractCATheftPreventionForm?.minimumAllowanceClaimed?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
							<tr>
								<td colSpan={4}>
									<p>
										<b>PAY DAY</b> If you work between the 1st and the 15th of
										the month, your regular payday will be the 26th of the
										month. If you work between the 16th through the 30/31st of
										the month your regular payday will be the 10th of the
										following month
									</p>
								</td>
							</tr>
						</tbody>
					</table>
					<table
						className={styles.contractForms}
						border={0}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
						style={{ border: '#515151 solid 1px', borderTop: '0px' }}
					>
						<tr>
							<td>
								<p>
									Unless exempt, the employee identified on this notice is
									entitled to minimum requirements for paid sick leave under
									state law which provides that an employee working more than 30
									days within a year for the same hiring employer:
								</p>
								<ol>
									<li>
										May accrue paid sick leave and may request and use up to 3
										days or 24hours of accrued paid sick leave per year;
									</li>
									<li>
										May not be terminated or retaliated against for using or
										requesting the use of accrued paid sick leave; and
									</li>
									<li>
										Has the right to file a complaint against an employer who
										retaliates or discriminates against an employee for
										<ul>
											<li>Requesting or using accrued sick days;</li>
											<li>
												Attempting to exercise the right to use accrued sick
												days;
											</li>
											<li>
												Filing a complaint or alleging a violation of Article
												1.5 section 245 et seq. of the California Labor Code;
											</li>
											<li>
												Cooperating in an investigation or prosecution of an
												alleged violation of this Article or opposing any policy
												or practice that is prohibited by Article 1.5 section
												245 et seq. of the California Labor Code.
											</li>
										</ul>
									</li>
								</ol>
								<p>
									<b>
										The following applies to the employee identified on this
										notice (Check one box):
									</b>
								</p>
								<p>
									<div
										style={{
											display: 'flex',
											minWidth: '10%',
											alignItems: 'center',
											gap: '5px',
										}}
									>
										<Controller
											control={contractForm.control}
											name='contractCATheftPreventionForm.isSickLeavePursuantToCalc245'
											defaultValue={false}
											render={({ field }) => (
												<div
													onClick={() => field.onChange(!field.value)}
													style={{ userSelect: 'none' }}
												>
													<input
														{...field}
														value={field.value + ''}
														checked={field.value}
														onChange={(e) => field.onChange(e)}
														type='checkbox'
														disabled={props?.isPrint}
														style={{ width: '20px', marginTop: '2px' }}
													/>
													<span>Accrues paid sick leave pursuant to the requirements of CA Labor Code §245.</span>
												</div>
											)}
										/>
									</div>
								</p>
								<p>
									<div
										style={{
											display: 'flex',
											minWidth: '10%',
											alignItems: 'center',
											gap: '5px',
										}}
									>
										<Controller
											control={contractForm.control}
											name='contractCATheftPreventionForm.isSickLeavePursuantToEmpPolicyOrExceedsCalc246'
											defaultValue={false}
											render={({ field }) => (
												<div
													onClick={() => field.onChange(!field.value)}
													style={{ userSelect: 'none' }}
												>
													<input
														{...field}
														value={field.value + ''}
														checked={field.value}
														onChange={(e) => field.onChange(e)}
														type='checkbox'
														disabled={props?.isPrint}
														style={{ width: '20px', marginTop: '2px' }}
													/>
													<span>
																Accrues paid sick leave pursuant to employer’s policy
																which satisfies or exceeds the requirements of CA Labor
																Code §246.
													</span>
												</div>
											)}
										/>
									</div>
								</p>
								<p>
									<div
										style={{
											display: 'flex',
											minWidth: '10%',
											alignItems: 'center',
											gap: '5px',
										}}
									>
										<Controller
											control={contractForm.control}
											name='contractCATheftPreventionForm.isSickLeaveLessThan24Hour'
											defaultValue={false}
											render={({ field }) => (
												<div
													onClick={() => field.onChange(!field.value)}
													style={{ userSelect: 'none' }}
												>
													<input
														{...field}
														value={field.value + ''}
														checked={field.value}
														onChange={(e) => field.onChange(e)}
														type='checkbox'
														disabled={props?.isPrint}
														style={{ width: '20px', marginTop: '2px' }}
													/>
													<span>Employer provides no less than 24 hours (or 3 days) of paid sick leave at the beginning of each 12-month period</span>
												</div>
											)}
										/>
									</div>
								</p>
								<br />
							</td>
						</tr>
					</table>
					<table
						className={styles.contractForms}
						border={1}
						cellPadding={0}
						cellSpacing={0}
						width={'100%'}
						style={{ borderTop: '0px' }}
					>
						<tbody>
							<tr style={{ background: '#00b0b9' }}>
								<td style={{ color: '#FFF' }} colSpan={4}>
									ACKNOWLEDGMENT OF RECEIPT
								</td>
							</tr>
							<tr>
								<td width={210}>
									<p>Employee Name</p>
								</td>
								<td>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractCATheftPreventionForm.employeeName'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractCATheftPreventionForm?.employeeName?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractCATheftPreventionForm?.employeeName?.message
											}
										</FormHelperText>
									)}
								</td>
								<td width={180}>
									<p>Email</p>
								</td>
								<td>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractCATheftPreventionForm.employeeEmail'
											render={({ field }) => (
												<>
													<input {...field} disabled={props?.isPrint} type='email'/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractCATheftPreventionForm?.employeeEmail?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractCATheftPreventionForm?.employeeEmail?.message
											}
										</FormHelperText>
									)}
								</td>
							</tr>
							<tr>
								<td>
									<p>Employee Address</p>
								</td>
								<td>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractCATheftPreventionForm.employeeAddress'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractCATheftPreventionForm?.employeeAddress?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractCATheftPreventionForm?.employeeAddress?.message
											}
										</FormHelperText>
									)}
								</td>
								<td>
									<p>Employee Signature</p>
								</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<NabuTextbox disabled={true}/>
									</FormControl>
								</td>
							</tr>
							<tr>
								<td>
									<p>Employer Representative</p>
								</td>
								<td>
									<FormControl className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='contractCATheftPreventionForm.employerRepresentative'
											render={({ field }) => (
												<>
													<NabuTextbox {...field} disabled={props?.isPrint}/>
												</>
											)}
										/>
									</FormControl>
									{contractForm?.formState?.errors?.contractCATheftPreventionForm?.employerRepresentative?.message && (
										<FormHelperText error={true}>
											{
												contractForm?.formState?.errors?.contractCATheftPreventionForm?.employerRepresentative?.message
											}
										</FormHelperText>
									)}
								</td>
								<td>
									<p>Signature</p>
								</td>
								<td>
									<FormControl fullWidth className={styles.tableForms}>
										<NabuTextbox disabled={true}/>
									</FormControl>
								</td>
							</tr>
						</tbody>
					</table>
					<p style={{ marginTop: '12px'}}>
						Labor Code section 2810.5(b) requires that the employer notify you
						in writing of any changes to the information set forth in this
						Notice within seven calendar days after the time of the changes,
						unless one of the following applies: (a) All changes are reflected
						on a timely wage statement furnished in accordance with Labor Code
						section 226; (b) Notice of all changes is provided in another
						writing required by law within seven days of the changes.
					</p>
					<p style={{ marginTop: '8px'}}>
						This Notice is NOT required if (a) you are directly employed by the
						state or any political subdivision thereof, (b) you are an employee
						who is exempt from the payment of overtime wages by statute or wage
						order, or (c) you are covered by a collective bargaining agreement
						that expressly provides for wages, hours of work and working
						conditions, and provides for premium wage rates for all overtime
						worked.
					</p>
					<p style={{ marginTop: '8px'}}>
						The employee’s signature on this notice merely constitutes
						acknowledgment of receipt. In accordance with an employer’s general
						recordkeeping requirements under the law, it is the employer’s
						obligation to ensure that the employment and wage-related
						information provided on this notice is accurate and complete.
						Furthermore, the employee’s signature acknowledging receipt of this
						notice does not constitute a voluntary written agreement as required
						under the law between the employer and employee in order to credit
						any meals or lodging against the minimum wage. Any such voluntary
						written agreement must be evidenced by a separate document.
					</p>
					<p style={{ marginTop: '8px'}}>
						PRODUCER Please note copies of the completed and signed forms must
						be distributed: One copy to the employee, one copy to {contractForm.watch('contractCATheftPreventionForm.employerName')} with other employment documents, and retain a copy for your
						records.
					</p>
					<br></br>
					<p>
						{contractForm.watch('contractCATheftPreventionForm.employerName')} &nbsp; is the employer of record for the sole purposes
						of withholding and remitting employment taxes, providing workers’
						compensation insurance and facilitating unemployment benefit claims.
					</p>
				</div>
			</div>
		</>
	);
};

export default ContractCAWageTheftPreventionForm;
