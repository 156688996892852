import NabuButton from '@/common/components/atoms/button/button';
import { Box, FormControl, FormLabel, Grid, Popover } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import style from './addPerson.module.scss';
import AddIcon from '@mui/icons-material/Add';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import { EmailFormModel } from '@/modules/company/components/companyInfo/components/tabs/members/shared/models/memberModel';
import { yupResolver } from '@hookform/resolvers/yup';
import { formSchema } from '@/modules/people/components/peopleList/shared/schema/emailSchema';
import NabuSelect from '@/common/components/atoms/select/select';

interface Props {
	onSubmit: CallableFunction;
	onClose: VoidFunction;
	isOpen: boolean;
	event?: HTMLButtonElement | null;
	onPopOverOpen: CallableFunction;
    title?: string;
    formData: any;
}

const AddEmailInline = (props: Props) => {
	const form = useForm<EmailFormModel>({
		defaultValues: { ...props.formData },
		resolver: yupResolver(formSchema),
		mode: 'onChange'
	});
    
	const onSubmit = (data: any) => {
		props.onSubmit && props.onSubmit(data);
	};

	useEffect(() => {
		form.reset({...new EmailFormModel()});
	}, [props.formData]);

	const emailLabelOptions = [
		{ label: 'Personal', value: 'personal' },
		{ label: 'Work', value: 'work' },
		{ label: 'Other', value: 'other' }
	];

	return (
		<>
			<div
				onClick={(e: any) => props?.onPopOverOpen(e)}
				className={style.addIcon}>
				<AddIcon/>{props.title ? props.title : 'Add New Email'}
			</div>
			<Box>
				<Popover
					open={props?.isOpen || false}
					anchorEl={props?.event}
					onClose={props?.onClose}
					slotProps={{ paper: { className: 'InlinePopover' } }}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
				>
					<div className={style.popoverSection}>
						<Box>
							<form>
								<Grid item container columnSpacing={3}>
									<Grid item xs={12} md={12}>
										<FormControl fullWidth>
											<Controller
												name={'emailId'}
												control={form.control}
												rules={{ required: 'This field is required.' }}
												render={({ field }) => (
													<>
														<FormLabel>Email Address<sup></sup></FormLabel>
														<NabuTextbox
															{...field}
															convertCase={'default'}
															className={style.emailInput}
															onChange={(event: any) => { field.onChange(event); }}
															placeholder="Value"
															error={form.formState?.errors?.emailId ? true : false}
															helperText={form.formState?.errors?.emailId?.message}
														/>
													</>
												)} />
										</FormControl>
									</Grid>
									<Grid item xs={12} md={5}>
										<FormControl fullWidth>
											<FormLabel data-cy='type_input_label' id="select-field-demo-label" htmlFor="select-field-demo-button">Type <sup></sup>
											</FormLabel>
											<Controller
												name={'emailLabel'}
												control={form.control}
												rules={{ required: 'This field is required.' }}
												render={({ field }) => (
													<NabuSelect
														placeholder={'Select Type'}
														data-cy='email_type_select_input'
														{...field}
														onChange={(event: any) => { field.onChange(event); }}
														options={emailLabelOptions} />
												)} />
										</FormControl>
									</Grid>
								</Grid>
								<Box className={style.nestedInput}>
									<NabuButton
										type='button'
										variant={'filled'}
										data-cy='phone-number-save'
										onClick={form.handleSubmit(onSubmit)}
									>
										Save
									</NabuButton> &nbsp;

									<NabuButton
										onClick={props.onClose}
										variant='outlined'
										data-cy='phone-number-cancel'
									>
										Cancel
									</NabuButton>
								</Box>
							</form>
						</Box>
					</div>
				</Popover>
			</Box>
		</>
	);
};

export default AddEmailInline;