import { DraggableSyntheticListeners } from '@dnd-kit/core';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { DragIndicator } from '@mui/icons-material';
import { IconButton, Tooltip } from '@mui/material';
import { createContext, useContext, useMemo } from 'react';

interface Props {
	id: number;
	type: string;
	component: any;
	children: any;
}

interface Context {
  attributes: Record<string, any>;
  listeners: DraggableSyntheticListeners;
  ref(node: HTMLElement | null): void;
}

const SortableItemContext = createContext<Context>({
	attributes: {},
	listeners: undefined,
	ref() {
		//
	}
});

const FormPart = (props: Props) => {
	const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform, transition, isDragging } = useSortable({
		id: 'F' + props.id,
		data: {
			...props
		}
	});
	const context = useMemo(
		() => ({
			attributes,
			listeners,
			ref: setActivatorNodeRef
		}),
		[attributes, listeners, setActivatorNodeRef]
	);

	const style = {
		transition,
		transform: CSS.Transform.toString(transform)
	};

	if(isDragging){
		return <div ref={setNodeRef} style={{ ...style, visibility: 'hidden' }}>
			{props.children}
		</div>;
	}

	return (<SortableItemContext.Provider value={context}>
		<div ref={setNodeRef} style={style}>
			{props.children}
		</div>
	</SortableItemContext.Provider>);
};

export const DragHandle = () => {
	const { attributes, listeners, ref } = useContext(SortableItemContext);

	return <IconButton {...attributes} {...listeners} ref={ref} aria-label="delete" size="small">
		<Tooltip title='Drag'><DragIndicator fontSize="inherit" /></Tooltip>
	</IconButton>;
};

export default FormPart;