import NabuButton from '@/common/components/atoms/button/button';
import { Box, FormControl, FormLabel, Grid, Popover } from '@mui/material';
import React, { useEffect } from 'react';
import { Controller, useForm } from 'react-hook-form';
import style from './addPerson.module.scss';
import AddIcon from '@mui/icons-material/Add';
import NabuSelect from '@/common/components/atoms/select/select';
import { Country as CountriesData } from 'country-state-city';
import PhoneInput from '@/common/components/molecules/phoneInput/phoneInput';
import { PhoneNumberFormModel } from '@/modules/company/components/companyInfo/components/tabs/members/shared/models/memberModel';
import { yupResolver } from '@hookform/resolvers/yup';
import { formSchema } from '@/modules/people/components/peopleList/shared/schema/phoneNumberSchema';

interface Props {
	onSubmit: CallableFunction;
	onClose: VoidFunction;
	isOpen: boolean;
	event?: HTMLButtonElement | null;
	onPopOverOpen: CallableFunction;
    title?: string;
    formData: any;
}

const AddPhoneNumberInline = (props: Props) => {
	const form = useForm<PhoneNumberFormModel>({
		defaultValues: { ...props.formData },
		resolver: yupResolver(formSchema),
		mode: 'onChange'
	});
    
	const countryNames = CountriesData.getAllCountries();
	const countryCodes: any = countryNames.filter((country) => country.name != 'United States Minor Outlying Islands').map((country: any) => {
		return { label: `${country.name} (${country.phonecode})`, value: country.phonecode };
	});

	const onSubmit = (data: any) => {
		data.isPrimary = true;
		props.onSubmit && props.onSubmit(data);
	};

	useEffect(() => {
		form.reset({...new PhoneNumberFormModel()});
	}, [props.formData]);

	const phoneNumberLabelOptions = [
		{ label: 'Cellular', value: 'cellular' },
		{ label: 'Business', value: 'business' },
		{ label: 'FAX', value: 'fax' }
	];
	return (
		<>
			<div
				onClick={(e: any) => props?.onPopOverOpen(e)}
				className={style.addIcon}>
				<AddIcon/>{props.title ? props.title : 'Add New Phone Number'}
			</div>
			<Box>
				<Popover
					open={props?.isOpen || false}
					anchorEl={props?.event}
					onClose={props?.onClose}
					slotProps={{ paper: { className: 'InlinePopover' } }}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
				>
					<div className={style.popoverSection}>
						<Box>
							<form>
								<Grid item container columnSpacing={3}>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth>
											<Controller
												name={'countryCode'}
												control={form.control}
												rules={{ required: 'This field is required.' }}
												render={({ field }) => (
													<>
														<FormLabel>Country Code <sup></sup></FormLabel>
														<NabuSelect
															data-cy='phone_numCode_selectInput'
															{...field}
															options={countryCodes}
														/>
													</>
												)} />
										</FormControl>
									</Grid>
									<Grid item xs={12} md={6}>
										<FormControl fullWidth>
											<Controller
												name={'number'}
												control={form.control}
												rules={{ required: 'This field is required.' }}
												render={({ field }) => (
													<>
														<FormLabel>Phone Number<sup></sup></FormLabel>
														<PhoneInput
															countryCode={form.watch('countryCode')}
															data-cy={'phone_num_textBox'}
															{...field}
															onChange={(event: any) => {field.onChange(event);}}
															placeholder="Number"
															error={form.formState?.errors?.number ? true : false}
															helperText={form.formState?.errors?.number?.message}
														/>
													</>
												)} />
										</FormControl>
									</Grid>
									<Grid item xs={12} md={5}>
										<FormControl fullWidth>
											<Controller
												name={'phoneNumberLabel'}
												control={form.control}
												rules={{ required: 'This field is required.' }}
												render={({ field }) => (
													<><FormLabel> Label <sup></sup></FormLabel><NabuSelect {...field}
														data-cy='isPrimary_phone_input'
														onChange={(event: any) => { field.onChange(event); }}
														placeholder={'Select Label'}
														options={phoneNumberLabelOptions} /></>
												)} />
										</FormControl>
									</Grid>
								</Grid>
								<Box className={style.nestedInput}>
									<NabuButton
										type='button'
										variant={'filled'}
										data-cy='phone-number-save'
										onClick={form.handleSubmit(onSubmit)}
									>
										Save
									</NabuButton> &nbsp;

									<NabuButton
										onClick={props.onClose}
										variant='outlined'
										data-cy='phone-number-cancel'
									>
										Cancel
									</NabuButton>
								</Box>
							</form>
						</Box>
					</div>
				</Popover>
			</Box>
		</>
	);
};

export default AddPhoneNumberInline;