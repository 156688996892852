import NabuButton from '@/common/components/atoms/button/button';
import LookAheadSelect, { ILookAheadSelectOption } from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import AddCompanyAddressInline from '@/common/components/templates/pages/add/addCompanyAddressInline';
import {
	ContractStatusOptions,
	ContractUnionType,
	UNION_TYPES,
} from '@/common/constants/constants';
import { OPERATORS, UNION_TYPE } from '@/common/enums';
import useNotification from '@/common/hooks/useNotification';
import { ICompanyAddress, ICompanyRecord } from '@/common/types/company';
import { IBaseContract, IPacketControlForm } from '@/common/types/contract';
import { IFilterOption } from '@/common/types/filterModel';
import { displayAddress, getCompanyDisplayName } from '@/common/utils/utils';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import {
	getAllAddressByCompany,
	getCompanyBasicDetails,
} from '@/services/company';
import { CopyAll, NoteAdd, Save } from '@mui/icons-material';
import {
	Box,
	Checkbox,
	FormControl,
	FormHelperText,
	FormLabel,
	Grid,
	ListItemButton,
	ListItemText,
} from '@mui/material';
import { Dispatch, useEffect, useState } from 'react';
import {
	Controller,
	UseFormReturn,
	useForm
} from 'react-hook-form';
import ContractCAWageTheftPreventionForm from '../contractCAWageyTheftPreventionForm';
import ContractMinorTrustForm from '../contractMinorTrustForm';
import ContractNYWageTheftPreventionForm from '../contractNYWageTheftPreventionForm';
import ContractW4ShortForm from '../contractW4ShortForm';
import ContractW8BenShortForm from '../contractW8BenShortForm';
import ContractW9BenShortForm from '../contractW9BenShortForm';
import TalentDetailsForm from '../talentDetailsForm';

export interface IContractFormState {
	isPacketOpen?: boolean;
}

type IAddressPopOverState = {
	isPayrollVendorAddressOpen?: boolean;
	isAdAgencyAddressOpen?: boolean;
	isPerformerAddressOpen?: boolean;
	performerAdAgencyAddressOpen?: boolean;
	isProducerAddressOpen?: boolean;
	event?: HTMLButtonElement | null;
	for?: string;
};

interface Props<TForm extends IBaseContract> {
	isPrint?: boolean;
	isEdit?: boolean;
	children: React.ReactNode;
	contractFormState: IContractFormState;
	contractForm: UseFormReturn<TForm>;
	onSubmit: React.FormEventHandler<HTMLFormElement>;
	setContractFormState: Dispatch<React.SetStateAction<IContractFormState>>;
	handleFormClose: CallableFunction;
	isAddingContract: boolean;
	isEditingContract: boolean;
	handleTalentDetailsForm: CallableFunction;
	handleSaveAndDuplicate: CallableFunction;
	setPacketFormState: Dispatch<React.SetStateAction<IPacketControlForm>>;
	performerCategory?: ILookAheadSelectOption[];
	isUnion?:boolean;
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	isPrintModel?:boolean;
	isDuplicateContractName?: boolean;
}

function ContractMutationWrapper<TForm extends IBaseContract>(
	props: Props<TForm>
) {
	const {
		contractFormState,
		isPrint,
		children,
		setContractFormState,
		handleFormClose,
		isAddingContract,
		isEditingContract,
	} = props;
	const contractForm =
		props?.contractForm as unknown as UseFormReturn<IBaseContract>;

	// #region State and Hooks section
	const notification = useNotification();

	const packetControlForm = useForm<IPacketControlForm>({
		defaultValues: {
			isW4shortForm: !!contractForm.watch('contractW4Form'),
			isW8BenShort: !!contractForm.watch('contractW8BenShortForm'),
			isW9BenShort: !!contractForm.watch('contractW9BenShortForm'),
			isMinorTrust: !!contractForm.watch('contractMinorTrustForm'),
			isNYTheftPreventionForm: !!contractForm.watch(
				'contractNYTheftPreventionForm'
			),
			isCATheftPreventionForm: !!contractForm.watch(
				'contractCATheftPreventionForm'
			),
		},
	});
	const [addressPopOver, setAddressPopOver] = useState<IAddressPopOverState>({
		isAdAgencyAddressOpen: false,
		isPayrollVendorAddressOpen: false,
		isPerformerAddressOpen: false,
		performerAdAgencyAddressOpen: false,
	});
	const [noOfTalentsSelected, setNoOfTalentsSelected] =
		useState<boolean>(false);
	// #endregion

	// #region constants and other variables section
	const VENDOR_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'VENDOR',
		},
	];
	// #endregion

	// #region Helper functions section
	const handleTalentDetailsForm = (data) => {
		setNoOfTalentsSelected(false);
		props?.handleTalentDetailsForm(data);
	};

	const handleSaveAndDuplicate = (data: TForm) => {
		if(!props.isDuplicateContractName){
			setNoOfTalentsSelected(true);
		}
		props?.handleSaveAndDuplicate(data);
	};

	const onPayrollVendorAddressCreated = (createdAddress: ICompanyAddress) => {
		contractForm.setValue(
			'payrollVendorAddressId',
			{
				value: createdAddress?.id || '',
				label: displayAddress(createdAddress),
			},
			{
				shouldDirty: true,
				shouldValidate: true,
			}
		);
	};

	const getUnions = (): any[] =>
		[UNION_TYPE.AFM, UNION_TYPE.SAG_AFTRA].map((value) => ({
			label: value,
			value: value,
		})) || [];

	const getUnionAgreement = (unionType: string): any[] =>
		ContractUnionType[unionType]?.agreements?.map((op) => ({
			label: op?.toUpperCase(),
			value: op?.toUpperCase(),
		})) || [];

	const handlePackageChange = (key: keyof IPacketControlForm) => {
		props?.setPacketFormState && props?.setPacketFormState(packetControlForm.getValues());
		notification.notify({
			message: `Form ${
				packetControlForm.watch(key) ? 'Added to' : 'Removed from'
			} Packet.`,
			type: 'info',
		});
	};

	const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
		event.preventDefault();
		if(!props?.isPrintModel){
			if(!props?.mainForm?.watch('contractDisplayName') && !props?.isEdit){
				notification.notify({
					message: 'Please fill Contract Display Name.',
					type: 'error'
				});
				return;
			}
		}
		props?.onSubmit && props?.onSubmit(event);
	};

	const onClickSnippetBtn = () => {
		setContractFormState((prev) => ({
			isPacketOpen: !prev?.isPacketOpen,
		}));
	};
	// #endregion

	// #region Look a head option generator helper functions section
	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};

	const companyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		return (
			(responseData || [])?.map((d) => ({
				value: d?.id,
				label: displayAddress(d)?.toUpperCase(),
			})) || []
		);
	};
	// #endregion

	// #region useEffect
	useEffect(() => {
		if(props?.mainForm){
			contractForm.setValue('contractDisplayName', props?.mainForm?.watch('contractDisplayName') || '', { shouldDirty: true});
		}
	}, [props?.mainForm?.watch('contractDisplayName')]);
	// #endregion

	return (
		<>
			<div className={isPrint ? styles.formWrapperPreview : ''}>
				<form onSubmit={handleSubmit}>
					<div className={styles.packetTemplate} id='form'>
						<Grid item container columnSpacing={3}>
							{contractFormState?.isPacketOpen && (
								<Grid item sm={12} md={4} lg={3}>
									<div className={styles.snippetSection}>
										<Grid item container columnSpacing={3}>
											<Grid item md={12}>
												<h3 className={styles.pocketTitle}>
													{' '}
													Government & Payroll forms
												</h3>
											</Grid>
										</Grid>
										<div className={styles.contractFormBlockSnippet}>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW4shortForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageChange('isW4shortForm');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageChange(
																				'isW4shortForm'
																			);
																		}}
																	/>{' '}
																	<span>W4- short version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW8BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageChange('isW8BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageChange('isW8BenShort');
																		}}
																	/>
																	<span>W8 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isW9BenShort'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageChange('isW9BenShort');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageChange('isW9BenShort');
																		}}
																	/>
																	<span>W9 Ben Short Version</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
											<div className={styles.formGroupSelect}>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isMinorTrust'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageChange('isMinorTrust');
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageChange('isMinorTrust');
																		}}
																	/>
																	<span>Minor Trust Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isNYTheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageChange(
																		'isNYTheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageChange(
																				'isNYTheftPreventionForm'
																			);
																		}}
																	/>
																	<span>NY Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
												<ListItemButton
													role={undefined}
													dense
													className={styles.listItemBordder}
													sx={{ p: 0 }}
												>
													<Controller
														control={packetControlForm.control}
														name='isCATheftPreventionForm'
														defaultValue={false}
														render={({ field }) => (
															<ListItemText
																onClick={() => {
																	field.onChange(!field.value);
																	handlePackageChange(
																		'isCATheftPreventionForm'
																	);
																}}
															>
																<div className={styles.inlineBox}>
																	<Checkbox
																		{...field}
																		value={field.value + ''}
																		checked={field.value}
																		onChange={(e) => {
																			field.onChange(e);
																			handlePackageChange(
																				'isCATheftPreventionForm'
																			);
																		}}
																	/>
																	<span>CA Wage Theft Prevention Form</span>
																</div>
															</ListItemText>
														)}
													/>
												</ListItemButton>
											</div>
										</div>
									</div>
								</Grid>
							)}
							<Grid
								item
								sm={12}
								md={4}
								lg={!contractFormState?.isPacketOpen ? 12 : 9}
							>
								<div
									className={
										!contractFormState?.isPacketOpen
											? undefined
											: styles.scrollFIle
									}
								>
									<div
										className={styles.contractFormBlockPacket}
										id='contract-form-block-packet'
									>
										<div className={styles.contractFormBlock}>
											{props?.isEdit && (
												<div className={styles.formFiledsBlock}>
													<Grid container item columnSpacing={3}>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
																	Contract Name<sup>*</sup>
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='contractDisplayName'
																	render={({ field }) => (
																		<>
																			<NabuTextbox
																				{...field}
																				placeholder={
																					!props?.isPrint ? 'Contract Name' : ''
																				}
																			/>
																		</>
																	)}
																/>
																{contractForm.formState?.errors
																	?.contractDisplayName?.message && (
																	<FormHelperText error={true}>
																		{
																			contractForm?.formState?.errors
																				?.contractDisplayName?.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
																	Payroll Vendor <sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='payrollVendorId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				className={
																					props?.isPrint
																						? styles.lookAheadSelectContract
																						: styles.asyncSelect
																				}
																				{...field}
																				placeholder={
																					!props?.isPrint
																						? 'Payroll Vendor'
																						: ''
																				}
																				filterProperty='CompanyNames.Name'
																				APICaller={getCompanyBasicDetails}
																				optionGenerator={companyOptionGenerator}
																				defaultFilter={VENDOR_FILTER}
																				isClearable={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																				}}
																				cacheOptions
																				defaultOptions
																			/>
																		</>
																	)}
																/>
																{contractForm.formState.errors?.payrollVendorId
																	?.value?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors
																				?.payrollVendorId?.value?.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
																	Payroll Vendor Address
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	key={`payroll-address-${contractForm.watch(
																		'payrollVendorAddressId.value'
																	)}`}
																	name='payrollVendorAddressId'
																	render={({ field }) => (
																		<>
																			<LookAheadSelect
																				className={
																					props?.isPrint
																						? styles.lookAheadSelectContract
																						: styles.asyncSelect
																				}
																				{...field}
																				placeholder={'Select Address'}
																				key={`payroll-vendor-address-${contractForm.watch(
																					'payrollVendorAddressId.value'
																				)}-${contractForm.watch(
																					'payrollVendorId.value'
																				)}`}
																				filterProperty='Line1'
																				APICaller={(filterModel) =>
																					getAllAddressByCompany(
																						filterModel,
																						contractForm.watch(
																							'payrollVendorId.value'
																						) || ''
																					)
																				}
																				optionGenerator={
																					companyAddressOptionGenerator
																				}
																				isDisabled={
																					!contractForm.watch(
																						'payrollVendorId.value'
																					)
																				}
																				dropDownsize={50}
																				isClearable={true}
																				isSearchText={true}
																				onChange={(e: any) => {
																					field.onChange(e);
																					contractForm.trigger(
																						'payrollVendorAddressId.value'
																					);
																				}}
																				cacheOptions={false}
																				defaultOptions
																			/>
																			{contractForm.watch(
																				'payrollVendorId.value'
																			) && (
																				<div style={{ float: 'right' }}>
																					<Box sx={{ mt: 0.9 }}>
																						<AddCompanyAddressInline
																							companyId={contractForm.watch(
																								'payrollVendorId.value'
																							)}
																							title='ADDRESS'
																							isOpen={
																								addressPopOver?.isPayrollVendorAddressOpen ||
																								false
																							}
																							event={addressPopOver?.event}
																							onAddressCreated={(d) =>
																								onPayrollVendorAddressCreated(d)
																							}
																							onClose={() =>
																								setAddressPopOver({
																									isPayrollVendorAddressOpen:
																										false,
																								})
																							}
																							onPopOverOpen={(e) =>
																								setAddressPopOver({
																									isPayrollVendorAddressOpen:
																										true,
																									event: e?.currentTarget,
																								})
																							}
																							isPersistent={true}
																						/>
																					</Box>
																				</div>
																			)}
																		</>
																	)}
																/>
																{contractForm.formState.errors
																	?.payrollVendorAddressId?.value?.message && (
																	<FormHelperText
																		error={true}
																		data-cy='errorHelper_ad_agency'
																	>
																		{
																			contractForm.formState?.errors
																				?.payrollVendorAddressId.value.message
																		}
																	</FormHelperText>
																)}
															</FormControl>
														</Grid>
														<Grid item md={6} lg={2.4}>
															<FormControl fullWidth>
																<FormLabel>
																	Status
																	<sup />
																</FormLabel>
																<Controller
																	control={contractForm.control}
																	name='status'
																	render={({ field }) => (
																		<>
																			<NabuSelect
																				{...field}
																				placeholder={
																					!props?.isPrint ? 'Select Status' : ''
																				}
																				options={ContractStatusOptions || []}
																				convertValueToUpperCase={false}
																				onChange={(e) => field.onChange(e)}
																			/>
																		</>
																	)}
																/>
															</FormControl>
														</Grid>
														{!(contractForm.watch('contractType')?.toUpperCase() === UNION_TYPES.NON_UNION) && (
															<Grid item md={6} lg={2.4}>
																<FormControl fullWidth>
																	<FormLabel>
																	Union
																		<sup />
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		name='unionType'
																		defaultValue={undefined}
																		render={({ field }) => (
																			<NabuSelect
																				{...field}
																				enableEmptySelection={true}
																				placeholder='Select Union'
																				options={getUnions()}
																			/>
																		)}
																	/>
																	{contractForm.formState?.errors?.unionType
																		?.message && (
																		<FormHelperText error={true}>
																			{
																				contractForm.formState.errors.unionType
																					.message
																			}
																		</FormHelperText>
																	)}
																</FormControl>
															</Grid>)}
														{getUnionAgreement(contractForm.watch('unionType'))
															?.length > 0 && (
															<Grid item sm={12} md={6} lg={2.4}>
																<FormControl fullWidth>
																	<FormLabel>
																		Union Agreement <sup />
																	</FormLabel>
																	<Controller
																		control={contractForm.control}
																		name='unionAgreement'
																		defaultValue=''
																		render={({ field }) => (
																			<NabuSelect
																				{...field}
																				placeholder='Select Agreement'
																				options={getUnionAgreement(
																					contractForm.watch('unionType')
																				)}
																			/>
																		)}
																	/>
																	{contractForm.formState?.errors
																		?.unionAgreement?.message && (
																		<FormHelperText error={true}>
																			{
																				contractForm.formState?.errors
																					?.unionAgreement?.message
																			}
																		</FormHelperText>
																	)}
																</FormControl>
															</Grid>
														)}
													</Grid>
												</div>
											)}
											{/* Main Form Start*/}
											<div className={styles.bgWhite}>{children}</div>
											{/* Main Form End */}

											{/* W4 Short form */}
											{packetControlForm.watch('isW4shortForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW4ShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
											{/* W8 Ben Short form */}
											{packetControlForm.watch('isW8BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW8BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* W9 Ben Short form */}
											{packetControlForm.watch('isW9BenShort') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractW9BenShortForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* Minor Trust form */}
											{packetControlForm.watch('isMinorTrust') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractMinorTrustForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* NY-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isNYTheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractNYWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}

											{/* CA-Wage-Theft-prevention  form */}
											{packetControlForm.watch('isCATheftPreventionForm') && (
												<div className={styles.defaultTemplate}>
													<div className={styles.contractFormBlock}>
														<ContractCAWageTheftPreventionForm
															contractForm={contractForm}
															isPrint={props?.isPrint}
														/>
													</div>
												</div>
											)}
										</div>
									</div>
								</div>
							</Grid>
						</Grid>
					</div>

					{!props?.isPrint && (
						<div className={styles.contractTemplateBtn}>
							<Grid container>
								<Grid xs={12} md={6}>
									<NabuButton variant='filled' onClick={onClickSnippetBtn}>
										{!contractFormState?.isPacketOpen ? (
											<>
												<NoteAdd /> Create
											</>
										) : (
											'Close'
										)}
										&nbsp; Packet
									</NabuButton>
								</Grid>
								<Grid xs={12} md={6} dir='rtl'>
									<NabuButton
										variant='outlined'
										data-cy='cancel_btn'
										shouldHandleConfirmAction={contractForm.formState.isDirty}
										onClick={() => handleFormClose()}
									>
										Cancel
									</NabuButton>{' '}
									&nbsp;
									<NabuButton
										variant='filled'
										type='submit'
										data-cy='save_btn'
										disabled={
											!(
												contractForm.formState.isDirty ||
												packetControlForm.formState.isDirty
											) ||
											isAddingContract ||
											isEditingContract
										}
									>
										{isAddingContract || isEditingContract ? 'Saving' : 'Save'}
										<Save />
									</NabuButton>{' '}
									&nbsp;
									<NabuButton
										variant='filled'
										onClick={contractForm.handleSubmit(
											handleSaveAndDuplicate as any
										)}
										disabled={
											!(
												contractForm.formState.isDirty ||
												packetControlForm.formState.isDirty
											) ||
											isAddingContract ||
											isEditingContract || (props?.isPrintModel || (!(props?.mainForm?.watch('contractDisplayName'))&& !props?.isEdit))
										}
									>
										{' '}
										Save & Duplicate <CopyAll />
									</NabuButton>
									&nbsp;
								</Grid>
							</Grid>
						</div>
					)}
				</form>
			</div>
			<TalentDetailsForm
				handleTalentDetailsForm={(value) => handleTalentDetailsForm(value)}
				noOfTalentsSelected={noOfTalentsSelected}
				setNoOfTalentsSelected={(value) => setNoOfTalentsSelected(value)}
				performerCategory={props?.performerCategory}
				isUnion={props?.isUnion}
			/>
		</>
	);
}

export default ContractMutationWrapper;
