import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import { Delete } from '@mui/icons-material';
import { IconButton, Tooltip, FormControl } from '@mui/material';
import styles from './formsNonUnion.module.scss';
import { PartType } from '@/common/constants/constants';
import { DragHandle } from '../components/formPart';

export const NonUnionOnCameraPrincipalParts = (handleDelete: any, isActionVisible: boolean = true) => {
	return [
		{
			id: 1,
			type: PartType.FORMPART,
			component: <div>
				{isActionVisible && <div className={styles.rearangeButtonControls}>
					<IconButton onClick={() => handleDelete(1)} aria-label="delete" size="small">
						<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>
					</IconButton>
					<DragHandle />
				</div>}
				<table className={styles.contractForms} width={'100%'}>
					<tbody>
						<tr>
							<td align='center'><h2>NON-UNION PERFORMER AGREEMENT
								<br></br>
								<span style={{ color: '#000' }}>ON CAMERA PRINCIPAL</span></h2> </td>
						</tr>
					</tbody>
				</table>
				<br></br>
			</div>
		}, {
			id: 2,
			type: PartType.FORMPART,
			component: <div>
				{isActionVisible && <div className={styles.rearangeButtonControls}>
					<IconButton onClick={() => handleDelete(2)} aria-label="delete" size="small">
						<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>
					</IconButton>
					<DragHandle />
				</div>}
				<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
					<tr>
						<td width={'50%'}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Agency: </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox placeholder="Test Agency" />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Agency Job# </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox placeholder="Agency Job#" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td width={'50%'}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Client:  </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Vital Proteins" />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Product:  </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Vital Proteins" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td width={'50%'}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Production Co:  </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Smuggler" />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Campaign Name:  </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Q1 Brand Campaign" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td width={'50%'}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Performer Name:  </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Nora Langdon" />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Spot Title(s):</span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="1 X :15,1 X :30" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Role:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Lifter" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td width={'50%'}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Session Workday (s):   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="11/16/2023 (session for up to 5 hours)" />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Location (City/State):</span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Mexico City, MX" />
								</FormControl>
							</div>
						</td>
					</tr>
				</table>
				<br></br>
			</div>
		}, {
			id: 3,
			type: PartType.FORMPART,
			component: <div>
				{isActionVisible && <div className={styles.rearangeButtonControls}>
					<IconButton onClick={() => handleDelete(3)} aria-label="delete" size="small">
						<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>
					</IconButton>
					<DragHandle />
				</div>}
				<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
					<tr style={{ background: '#808080' }}>

						<th style={{ textAlign: 'center' }} colSpan={2}><div style={{ display: 'flex', justifyContent: 'space-around' }}><span>Time In</span> <span>Out</span>  </div></th>
						<th style={{ textAlign: 'center' }} colSpan={2}><div style={{ display: 'flex', justifyContent: 'space-around' }}><span>Lunch In</span> <span>Out</span>  </div></th>
					</tr>
					<tr>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<input type='time' />
							</FormControl>
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<input type='time' />
							</FormControl>
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<input type='time' />
							</FormControl>
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<input type='time' />
							</FormControl>
						</td>
					</tr>
				</table>
				<p><em><b>**see attached timecard for more details</b></em></p>
				<br></br>
			</div>
		}, {
			id: 4,
			type: PartType.FORMPART,
			component: <div>
				{isActionVisible && <div className={styles.rearangeButtonControls}>
					<IconButton onClick={() => handleDelete(4)} aria-label="delete" size="small">
						<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>
					</IconButton>
					<DragHandle />
				</div>}
				<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
					<tr>
						<td colSpan={2}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Term:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="1 Year from first use" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Territory:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="North America for Broadcast, Industrial and OOH (print & Digital), Worldwide as it pertains to Internet and New Media" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td width={'50%'}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Exclusivity:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="None" />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Estimated First Air Date:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Tentatively scheduled for 12/26/2023" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Session Fee:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value=": $520 + 20% for 8 hour shoot day, with OT of $90.00 + 20% /per hour for 9th and 10th hours" />
								</FormControl>
							</div>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Fitting Fee:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="$350 flat fee for fitting day" />
								</FormControl>
							</div>
							<p><b>Travel Fee (if Applicable): $350 X 2 days flat fee.</b></p>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<p><b>Cancellation Fee: $700 + 20%</b> shall be due to performer if client should cancel engagement after booking.</p>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<p><b>Use Fee: $4,500 + 20%</b> To be paid within 30 business days of first use, only if performer makes final edit and if used</p>
						</td>
					</tr>

					<tr>
						<td colSpan={2}>
							<p>
								<b>Renewal Option:</b> Advertiser may extend all above terms and conditions for a non-consecutive term for an additional payment of
								$4,950 plus 20% agent fee, for an additional 1-year use period, only if talent is presented. Option can be exercised by Advertiser
								within the original term from first use and if talent remains in final edit. Renewal option to be available for a 5-year window from
								first use date
							</p>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<p>
								<b>Permitted Use:</b> All images, footage, and/or audio recordings to be used in any and all types of media, either known now or in the
								future including but not limited to broadcast, internet, photo stills, industrial, and new media including all lifts, edits, variations and
								cut downs.
							</p>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<p>
								<b>Historical uses:</b> Advertising materials may be used anywhere in the world by the Client and its Agency at sales meetings and for
								intra-company, research, award, publicity (including, without limitation, on Client’s Ad Agency ‘reel’ and website), file and
								reference purposes to be granted in perpetuity.
							</p>
						</td>
					</tr>
				</table>
				<br></br>
			</div>
		}, {
			id: 5,
			type: PartType.FORMPART,
			component: <div>
				{isActionVisible && <div className={styles.rearangeButtonControls}>
					<IconButton onClick={() => handleDelete(5)} aria-label="delete" size="small">
						<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>
					</IconButton>
					<DragHandle />
				</div>}
				<p>
					I understand that I will not receive any other payments and my compensation is outlined above. I understand that all
					payments and notices to me shall be delivered to the address listed on this release. I also understand that it is my sole responsibility
					to inform the Agency and Client of any change of address in writing. If a payment does not reach me due to a change of address,
					Agency and Client will hold such payments until notified by me in writing of the new mailing address. Notices with respect to any
					provisions herein, including but not limited to notices to renew, extend or exercise any rights or options herein shall be deemed
					accepted by me if Agency and/or Client sent such notice to the address which I provided and is unable to reach me due to my failure
					to provide change of address notification to Agency and/or Client in writing.
				</p>
				<br></br>
				<p>
					During the Term and throughout the Territory, I will not accept employment in, nor authorize use of my name or voice in any
					advertising material, for a competitive product or service of Client, as defined above in the Exclusivity clause.
				</p><br></br>
				<p>This agreement is governed by the laws of the State of California, and the parties agree to that jurisdiction if a dispute arises.
				</p>
				<br></br>
				<p>I warrant and represent that this agreement does not in any way conflict with any existing commitments on my part. Nothing
					herein will constitute any obligation on the licensed parties to make any use of any of the rights set forth herein.</p>
				<br></br>
				<p>I am over eighteen (18) years of age and have the right to make this agreement and am authorized to grant these rights.</p>
				<hr></hr>
			</div>
		}, {
			id: 6,
			type: PartType.FORMPART,
			component: <div>
				{isActionVisible && <div className={styles.rearangeButtonControls}>
					<IconButton onClick={() => handleDelete(6)} aria-label="delete" size="small">
						<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>
					</IconButton>
					<DragHandle />
				</div>}
				<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
					<tr>
						<td width={'50%'}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Agency: </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox placeholder="Test Agency" />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Agency Job# </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox placeholder="Agency Job#" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td width={'50%'}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Client:  </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Vital Proteins" />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Product:  </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Vital Proteins" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td width={'50%'}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Production Co:  </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Smuggler" />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Campaign Name:  </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Q1 Brand Campaign" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td width={'50%'}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Performer Name:  </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Nora Langdon" />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Spot Title(s):</span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="1 X :15,1 X :30" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Role:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Lifter" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td width={'50%'}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Session Workday (s):   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="11/16/2023 (session for up to 5 hours)" />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Location (City/State):</span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Mexico City, MX" />
								</FormControl>
							</div>
						</td>
					</tr>
				</table>
				<br></br>
			</div>
		}, {
			id: 7,
			type: PartType.FORMPART,
			component: <div>
				{isActionVisible && <div className={styles.rearangeButtonControls}>
					<IconButton onClick={() => handleDelete(7)} aria-label="delete" size="small">
						<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>
					</IconButton>
					<DragHandle />
				</div>}
				<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
					<tr style={{ background: '#808080' }}>

						<th style={{ textAlign: 'center' }} colSpan={2}><div style={{ display: 'flex', justifyContent: 'space-around' }}><span>Time In</span> <span>Out</span>  </div></th>
						<th style={{ textAlign: 'center' }} colSpan={2}><div style={{ display: 'flex', justifyContent: 'space-around' }}><span>Lunch In</span> <span>Out</span>  </div></th>
					</tr>
					<tr>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<input type='time' />
							</FormControl>
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<input type='time' />
							</FormControl>
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<input type='time' />
							</FormControl>
						</td>
						<td>
							<FormControl fullWidth className={styles.tableForms}>
								<input type='time' />
							</FormControl>
						</td>
					</tr>
				</table>
				<p><em><b>**see attached timecard for more details</b></em></p>
				<br></br>
			</div>
		}, {
			id: 8,
			type: PartType.FORMPART,
			component: <div>
				{isActionVisible && <div className={styles.rearangeButtonControls}>
					<IconButton onClick={() => handleDelete(8)} aria-label="delete" size="small">
						<Tooltip title='Delete'><Delete fontSize="inherit" color='error' /></Tooltip>
					</IconButton>
					<DragHandle />
				</div>}
				<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={styles.contractFormsInner}>
					<tr>
						<td colSpan={2}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Term:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="1 Year from first use" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Territory:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="North America for Broadcast, Industrial and OOH (print & Digital), Worldwide as it pertains to Internet and New Media" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td width={'50%'}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Exclusivity:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="None" />
								</FormControl>
							</div>
						</td>
						<td>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Estimated First Air Date:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="Tentatively scheduled for 12/26/2023" />
								</FormControl>
							</div>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Session Fee:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value=": $520 + 20% for 8 hour shoot day, with OT of $90.00 + 20% /per hour for 9th and 10th hours" />
								</FormControl>
							</div>
							<div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}><span style={{ flex: 'none' }}>Fitting Fee:   </span>
								<FormControl fullWidth className={styles.tableForms}>
									<NabuTextbox value="$350 flat fee for fitting day" />
								</FormControl>
							</div>
							<p><b>Travel Fee (if Applicable): $350 X 2 days flat fee.</b></p>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<p><b>Cancellation Fee: $700 + 20%</b> shall be due to performer if client should cancel engagement after booking.</p>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<p><b>Use Fee: $4,500 + 20%</b> To be paid within 30 business days of first use, only if performer makes final edit and if used</p>
						</td>
					</tr>

					<tr>
						<td colSpan={2}>
							<p>
								<b>Renewal Option:</b> Advertiser may extend all above terms and conditions for a non-consecutive term for an additional payment of
								$4,950 plus 20% agent fee, for an additional 1-year use period, only if talent is presented. Option can be exercised by Advertiser
								within the original term from first use and if talent remains in final edit. Renewal option to be available for a 5-year window from
								first use date
							</p>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<p>
								<b>Permitted Use:</b> All images, footage, and/or audio recordings to be used in any and all types of media, either known now or in the
								future including but not limited to broadcast, internet, photo stills, industrial, and new media including all lifts, edits, variations and
								cut downs.
							</p>
						</td>
					</tr>
					<tr>
						<td colSpan={2}>
							<p>
								<b>Historical uses:</b> Advertising materials may be used anywhere in the world by the Client and its Agency at sales meetings and for
								intra-company, research, award, publicity (including, without limitation, on Client’s Ad Agency ‘reel’ and website), file and
								reference purposes to be granted in perpetuity.
							</p>
						</td>
					</tr>
				</table>
				<br></br>
			</div>
		}
	];
};