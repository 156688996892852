import React, { useState } from 'react';
import { PhoneNumberUtil, PhoneNumberFormat } from 'google-libphonenumber';
import { FormHelperText } from '@mui/material';
import styles from './phoneInput.module.scss';

const PhoneInput: React.FC<any> = (props: any) => {
	const [phoneNumber, setPhoneNumber] = useState(props.value ?? '');
	const [isEditing, setIsEditing] = useState(false);
    
	const handlePhoneNumberChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const rawInput = event.target.value.replace(/\D/g, '');
		const limitedInput = rawInput.slice(0, 10);
		setPhoneNumber(limitedInput);
		props.onChange && props.onChange(limitedInput);
	};

	// Format the phone number based on the country code using libphonenumber
	const formattedPhoneNumber = () => {
		const phoneUtil = PhoneNumberUtil.getInstance();
		try {
			const parsedNumber = phoneUtil.parse(`+${props.countryCode}${phoneNumber}`, 'ZZ');
			let formattedNumber = phoneUtil.format(parsedNumber, PhoneNumberFormat.INTERNATIONAL);
			// Replace spaces with hyphens
			formattedNumber = formattedNumber.replace(/\s/g, '-');
			return formattedNumber;
		} catch (error) {
			return `+${props.countryCode}-${phoneNumber}`;
		}
	};
    
	const handleInputBlur = (e) => {
		setIsEditing(false);
		props.onBlur && props.onBlur(e);
	};
    
	return (
		<>
			<div className={styles.phoneBox}>
				{isEditing ? (
					<>
						<div className={styles.countryCode}>{`+${props.countryCode}`}</div>
						<input
							{...props}
							type='tel'
							onChange={handlePhoneNumberChange}
							onBlur={handleInputBlur}
							className={styles.phoneInput}
							autoFocus={true}
						/>
					</>
				) 
					: (
						<>
							<input
								className={styles.phoneInputRead}
								value={formattedPhoneNumber()}
								readOnly
								onClick={() => setIsEditing(true)}
							/>
						</>
					)}
			</div>
			{props.error && <FormHelperText error={props.error}>{props.helperText}</FormHelperText>}
		</>
	);
};

export default PhoneInput;
