import NabuButton from '@/common/components/atoms/button/button';
import NabuSelect from '@/common/components/atoms/select/select';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import useNotification from '@/common/hooks/useNotification';
import {
	getNotificationText
} from '@/common/utils/utils';
import {
	saveAddresses
} from '@/services/company';
import { getAddressBasedOnPinCode } from '@/services/person';
import AddIcon from '@mui/icons-material/Add';
import {
	Box,
	FormControl,
	FormHelperText,
	FormLabel,
	Grid,
	Popover,
	Stack
} from '@mui/material';
import { HttpStatusCode } from 'axios';
import { City, Country, State } from 'country-state-city';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import style from './addPerson.module.scss';

interface IAddAddress {
	line1: string;
	line2: string;
	zipCode: string;
	country: string;
	state: string;
	city: string;
}

interface Props {
	onAddressCreated: CallableFunction;
	onClose: VoidFunction;
	isOpen: boolean;
	event?: HTMLButtonElement | null;
	onPopOverOpen: CallableFunction;
	title?: string;
	personId: string;
	isPersistent?: boolean;
}

const AddPersonAddressInline = (props: Props) => {
	const { onAddressCreated } = props;
	const {
		control,
		reset,
		getValues,
		setValue,
		formState,
		setError,
		clearErrors,
		watch,
	} = useForm<IAddAddress>();
	const notification = useNotification();

	const [stateOptions, setStateOptions] = useState<any>(State.getStatesOfCountry('US').map(
		(state) => ({ label: state.name, value: state.isoCode })
	));
	const [cityOptions, setCityOptions] = useState<any>([]);

	const getCountryOptions = Country.getAllCountries().map((country) => ({
		label: country.name?.toUpperCase(),
		value: country.isoCode,
	}));

	
	const onClose = () => {
		props?.onClose && props?.onClose();
		reset({ line1: '', line2: '', zipCode: '', country: 'US', state: '', city: ''});
		reset();
	};

	const { mutate: AddPersonAddress, isLoading } = useMutation({
		mutationFn: saveAddresses,
		onSuccess: (res) => {
			if(res?.status !== HttpStatusCode.Ok) {
				notification.notify({
					message: getNotificationText('Company Address', 'CREATE', true),
					type: 'error',
				});
			} else {
				// Success block
				notification.notify({
					message: getNotificationText('Company Address', 'CREATE'),
					type: 'success',
				});
				
				onAddressCreated({
					...(res?.data as any[])?.at(0),
					...getValues()
				});
				onClose();
			}
		},
		onError: () =>			
			notification.notify({
				message: getNotificationText('Company Address', 'CREATE', true),
				type: 'error',
			}),
	});

	const handleError = (type: 'LINE' | 'ZIP_CODE' | 'COUNTRY' | 'ALL' = 'ALL'): boolean => {
		let hasError = false;
		const { country, line1, zipCode } = getValues();

		if(type === 'ALL' || type === 'LINE')
			if (!line1) {
				setError('line1', { message: 'Address Line 1 field is required.' });
				hasError = true;
			} else clearErrors('line1');

		if(type === 'ALL' || type === 'ZIP_CODE')
			if (!zipCode) {
				setError('zipCode', { message: 'Address Zip Code field is required.' });
				hasError = true;
			} else clearErrors('zipCode');

		if(type === 'ALL' || type === 'COUNTRY')
			if (!country) {
				setError('country', { message: 'Address country field is required.' });
				hasError = true;
			} else clearErrors('country');

		return hasError;
	};

	const getStateOptions = (countryCode: string) => {
		if (!countryCode) {
			setStateOptions([]);
			return;
		}
		setCityOptions([]);
		const states = State.getStatesOfCountry(countryCode).map(
			(state) => ({ label: state.name, value: state.isoCode })
		);
		if (!states.length) states.unshift({ label: 'NA', value: 'NA' });
		setStateOptions(states);
	};

	const getCityOptions = (countryCode: string, stateCode?: string, placeName?: string) => {
		if (!stateCode) {
			setCityOptions([]);
			return;
		}
		const citiesData = City.getCitiesOfState(countryCode, stateCode);
		const cityOptions = citiesData.map((city: any) => {
			return {label: city.name, value: city.name.toUpperCase()};
		});
		if(placeName && placeName != '' && !cityOptions.find(x => x.value === placeName)){
			cityOptions.push({label: placeName, value: placeName.toUpperCase()});
		}
		setCityOptions(cityOptions);
	};

	const onSubmit = () => {
		if (!handleError()) {
			const { line1, line2,  zipCode, state, city, country } = getValues();

			AddPersonAddress({
				entityId: props.personId,
				addresses: [{ line1, line2, zipCode, state, city, country }],
				apiLabel: 'People'
			});
		}
	};

	const handleZipCodeChange = (value: string) => {
		if(value)
			getAddressBasedOnPinCode(value).then((res: any) => {
				if(res.data){
					const place = res.data.places[0];
					setValue('country', res.data['country abbreviation'].toUpperCase(), { shouldDirty: true });
					setValue('state', place['state abbreviation'].toUpperCase(), { shouldDirty: true });
					getStateOptions(res.data['country abbreviation']);
					getCityOptions(place['country abbreviation'], place['state abbreviation'], place['place name']);
					setValue('city', place['place name'].toUpperCase(), { shouldDirty: true });
				}
			});
	};

	return (
		<>
			<div
				onClick={(e: any) => props?.onPopOverOpen(e)}
				className={style.addIcon}
			>
				<AddIcon />
				{props.title ? props.title : 'Address'}
			</div>
			<Box>
				<Popover
					open={props?.isOpen || false}
					anchorEl={props?.event}
					onClose={!props?.isPersistent ? props?.onClose : undefined}
					slotProps={{ paper: { className: 'InlinePopover' } }}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
				>
					<div className={style.popoverSection}>
						<Box>
							<form>
								<Grid
									container
									columnSpacing={1}
									className={style.talentContainer}
								>
									<Grid item xs={4}>
										<FormControl fullWidth={true}>
											<FormLabel>Line 1</FormLabel>
											<Controller
												control={control}
												name='line1'
												defaultValue=''
												render={({ field }) => (
													<NabuTextbox
														{...field}
														onChange={(e) => {
															field.onChange(e);
															handleError('LINE');
														}}
														placeholder='Line 1'
													/>
												)}
											/>
											{formState.errors?.line1?.message && (
												<FormHelperText
													data-cy='errorHelper_key_crew'
													error={true}
												>
													{formState.errors?.line1?.message}
												</FormHelperText>
											)}
										</FormControl>
									</Grid>
									<Grid item xs={4}>
										<FormControl fullWidth={true}>
											<FormLabel>Line 2</FormLabel>
											<Controller
												control={control}
												name='line2'
												defaultValue=''
												render={({ field }) => (
													<NabuTextbox
														{...field}
														onChange={(e) => {
															field.onChange(e);
														}}
														placeholder='Line 2'
													/>
												)}
											/>
											{formState.errors?.line2?.message && (
												<FormHelperText
													data-cy='errorHelper_key_crew'
													error={true}
												>
													{formState.errors?.line2?.message}
												</FormHelperText>
											)}
										</FormControl>
									</Grid>
									<Grid item xs={4}>
										<FormControl fullWidth={true}>
											<FormLabel>ZipCode</FormLabel>
											<Controller
												control={control}
												name='zipCode'
												defaultValue=''
												render={({ field }) => (
													<NabuTextbox
														{...field}
														onChange={(e) => {
															if (
																new RegExp(/^\d*$/).test(
																	e?.target?.value || ''
																) &&
																Number(e?.target?.value) >= 0
															) {
																field.onChange(e);
																handleZipCodeChange(e.target?.value.trim());
															} else {
																const value: string = e?.target?.value ?? '';
																const num = value?.match(/\d+/)?.at(0);

																if (!isNaN(Number(num || ''))) {
																	field.onChange(num || '');
																} else {
																	field.onChange('');
																}
																handleZipCodeChange(value);
															}
															handleError('ZIP_CODE');
														}}
														placeholder='Zip Code'
													/>
												)}
											/>
											{formState.errors?.zipCode?.message && (
												<FormHelperText
													data-cy='errorHelper_key_crew'
													error={true}
												>
													{formState.errors?.zipCode?.message}
												</FormHelperText>
											)}
										</FormControl>
									</Grid>
									<Grid item xs={4}>
										<FormControl fullWidth={true}>
											<FormLabel>Country</FormLabel>
											<Controller
												control={control}
												name='country'
												defaultValue='US'
												render={({ field }) => (
													<NabuSelect
														{...field}
														options={getCountryOptions}
														onChange={(e) => {
															field.onChange(e);
															handleError('COUNTRY');
														}}
														convertValueToUpperCase={false}
														placeholder='Select Country'
													/>
												)}
											/>
											{formState.errors?.country?.message && (
												<FormHelperText
													data-cy='errorHelper_key_crew'
													error={true}
												>
													{formState.errors?.country?.message}
												</FormHelperText>
											)}
										</FormControl>
									</Grid>
									<Grid item xs={4}>
										<FormControl fullWidth={true}>
											<FormLabel>State</FormLabel>
											<Controller
												control={control}
												name='state'
												render={({ field }) => (
													<NabuSelect
														{...field}
														key={`addr-state-${watch('state')}`}
														options={stateOptions}
														disabled={!watch('country')}
														convertValueToUpperCase={false}
														placeholder={ 'Select State'}
													/>
												)}
											/>
											{formState?.errors?.state
												?.message && (
												<FormHelperText error={true}>
													{
														formState?.errors?.state
															.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</Grid>
									<Grid item xs={4}>
										<FormControl fullWidth={true}>
											<FormLabel>City</FormLabel>
											<Controller
												key={`city-${watch('city')}`}
												control={control}
												name='city'
												render={({ field }) => (
													<NabuSelect
														{...field}
														key={`addr-city-${watch('city')}`}
														options={cityOptions}
														disabled={!watch('state')}
														convertValueToUpperCase={false}
														placeholder={ 'Select City'}
													/>
												)}
											/>
											{formState?.errors?.city
												?.message && (
												<FormHelperText error={true}>
													{
														formState?.errors?.city
															.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</Grid>
									<Grid item xs={4} className={style.addTalent}>
										<Stack direction='row' spacing={1}>
											<NabuButton
												variant={'filled'}
												data-cy='addCOntact-add-staff-Button'
												disabled={!formState?.isDirty || isLoading}
												onClick={onSubmit}
											>
												Save
											</NabuButton>
											<NabuButton
												onClick={onClose}
												variant='outlined'
												data-cy='addCOntact-form-searchPerson-cancel'
											>
												Cancel
											</NabuButton>
										</Stack>
									</Grid>
								</Grid>
							</form>
						</Box>
					</div>
				</Popover>
			</Box>
		</>
	);
};

export default AddPersonAddressInline;
