import * as schema from 'yup';

const lookAHeadSchema = (field: string) =>  schema.object({
	value: schema.string().required(`${field} is required.`),
	label: schema.string().required(`${field} is required.`)
})
	.required(`${field} is required.`)
	.typeError(`${field} is required.`)
	.test('look-head-test', `${field} is required.`, (optionObj) => {
		return !!optionObj?.value?.length;
	});


const contractTerritorySchema = schema.object({
	territories: schema.array().of(schema.object({
		region: schema.string().nullable(),
		countries: schema.array().nullable(), 
	}
	)).test('duplicateCountry', 'Duplicate Country is not allowed', (value) => {
		if (!value) return true; 
		const allCountries: any = [];
		for (let i = 0; i < value.length; i++) {
			const countries = value[i]?.countries || [];
			for (let j = 0; j < countries.length; j++) {
				const country = countries[j];
				if (allCountries.includes(country)) {
					return false; // Duplicate found
				}
				allCountries.push(country);
			}
		}
		return true; // No duplicates found
	}),
	advertiserId : lookAHeadSchema('Advertiser'),
});

export default contractTerritorySchema;