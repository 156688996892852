import * as schema from 'yup';

const rightsAcquiredSchema = (isDeliverablesRequired) => schema.object({
	mediaInformation: schema.array().of(
		schema.object({
			mediaType: schema.string().required('Media Type is required field'),
			mediaSubType: schema.string().nullable(),
			mediaSubTypeDetails: schema.array().of(schema.string()).nullable(),
		})
	),
	deliverables: isDeliverablesRequired ? schema.array().of(
		schema.object({
			type: schema.string().required('Type is required.'),
			numberOfAssets: schema.string().matches(/^$|^[0-9]+$/,'Invalid value').required('Number Of Assets is required.'),
			noOfEdits: schema.string().matches(/^$|^[0-9]+$/,'Invalid value').nullable(),
		}) 
	) : schema.array().nullable(),
	term: schema.string().required('Term is required field'),
	period: schema.string().matches(/^$|^[0-9]+$/, 'Invalid value').nullable()
		.when('term', {
			is: 'Limited',
			then: schema.string().required('Period is required field'),
		}),
	unit: schema.string().nullable().when('term', {
		is: 'Limited',
		then: schema.string().required('Unit is required field'),
	}),
	startDate: schema.date()
		.required('Start Date is required').typeError('Start Date is required'),
	region: schema.array().of(schema.string()).nullable(),
	territories: schema.array().of(schema.object({
		region: schema.string().nullable(),
		countries: schema.array().nullable(), 
	}
	)).test('duplicateCountry', 'Duplicate Country is not allowed', (value) => {
		if (!value) return true; 
		const allCountries: any = [];
		for (let i = 0; i < value.length; i++) {
			const countries = value[i]?.countries || [];
			for (let j = 0; j < countries.length; j++) {
				const country = countries[j];
				if (allCountries.includes(country)) {
					return false; // Duplicate found
				}
				allCountries.push(country);
			}
		}
		return true; // No duplicates found
	}),
});

export default rightsAcquiredSchema;

export const renewForm = schema.object({
	startDate: schema.date().required('Start Date is required field').typeError('Start Date is required field'),
	endDate: schema.date().required('End Date is required field').typeError('End Date is required field'),
	paid: schema.bool().required('Paid is required field'),
});