import NabuButton from '@/common/components/atoms/button/button';
import { NabuLink } from '@/common/components/atoms/link/link';
import TextWrap from '@/common/components/atoms/textWrap/textWrap';
import { NabuCardDefault } from '@/common/components/molecules/cardWhite/cardWhite';
import DataTable from '@/common/components/molecules/dataTable/dataTable';
import NabuDialog, { IDialogAction } from '@/common/components/molecules/dialog/dialog';
import { DefaultPageSize, mediaTypeOptions, mediaType as mediaTypeValues } from '@/common/constants/constants';
import { OPERATORS } from '@/common/enums';
import { StatusCodes } from '@/common/enums/StatusCodes';
import useNotification from '@/common/hooks/useNotification';
import { ITableActionMenuModel, columnsType } from '@/common/types/dataTable';
import { IRegionAddressDialog } from '@/common/types/element';
import { convertMediaObject, displayAddress, findNodeAndAncestors, getLocaleDate, getNotificationText } from '@/common/utils/utils';
import { addContractRenewalTerms, deleteContractRightsAcquired, getContractRenewalTerms, getContractRightsAcquired } from '@/services/contract';
import { addRenewalTerms, deleteRightsAcquired, getRenewalTerms, getRightsAcquired, updateElementExpirationDate } from '@/services/element';
import { DeleteForever, Edit, RemoveRedEye } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import { Typography } from '@mui/joy';
import { Box, Divider, Grid, Stack, Tooltip } from '@mui/material';
import { Tree } from 'antd';
import { Fragment, useState } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import styles from '../../../../company/components/companyList/companyList.module.scss';
import AddRightsAcquiredForm from './AddRightsAcquiredForm';
import NestedTable from './nestedTable';

interface IRightsAcquiredFormDrawer {
	isOpen: boolean;
	selectedRightsAcquired?: any;
	rIdx: number; 
}

interface IProps {
	refresh?: () => void;
	elementData?: any;
	isRenewalSection?: boolean;
	onFormClose?: () => void;
	onFormOpen?: (formAction: string) => void;
	showNotification?: () => void;
	isSectionVisible?: boolean;
	for: 'ELEMENT' | 'CONTRACT';
}

const RightsAcquiredPage = (props: IProps) => {
	const [open, setOpen] = useState(false);
	const [isOpen, setRightsAcqForm] = useState(false);
	const [rightsAcquiredForm, setRightsAcquiredForm] = useState<IRightsAcquiredFormDrawer>({isOpen: false, rIdx: -1});

	const columns: columnsType[] = [
		{
			id: 'mediaType',
			label: 'Media Type',
			maxWidth: '400px',
		},
		{
			id: 'viewMediaTypes',
			label: ''
		},
		{ 
			id: 'term',
			label: 'Term'
		},
		{
			id: 'startDate',
			label: 'Term Start',
		},
		{
			id: 'endDate',
			label: 'Term End',
		},
		{
			id: 'inPerpetuity',
			label: 'In Perpetuity'
		},
		{
			id: 'paid',
			label: 'Paid/ Used',
		},
		{
			id: 'copyRenewal',
			label: 'Renewals'
		},
		{
			id: 'actions',
			label: '',
		},
	];

	const [territoryDialog, setTerritoryDialog] = useState<IRegionAddressDialog>(
		{} as IRegionAddressDialog
	);

	const [restrictionsData, setRestrictionsData] = useState({
		isRestrictionDialogOpen: false,
		restrictionData: ''
	});

	const [mediaType, setMediaType] = useState<{data: any, open: boolean}>({
		open: false,
		data: []
	});

	const CustomCellRightsCovered = (row: any) => {
		const rightsCoveredData = row?.mediaInformation?.length > 0 ? row?.mediaInformation[0]?.mediaType : row?.mediaType[0] || '';
		return (
			<Grid item container alignItems={'center'}>
				<TextWrap title={rightsCoveredData} maxLength={15}></TextWrap>
				{/* { row?.mediaInformation?.length > 1 ? 
					(
						<NabuLink underline='always' variant='body1' onClick={() => handleMediaTypeViewMoreButton(row?.mediaInformation || null)}>
								+{row?.mediaInformation?.length - 1}
						</NabuLink>
					)
					:row?.mediaType?.length > 1 && (
						<NabuLink underline='always' variant='body1' onClick={() => handleMediaTypeViewMoreButton(row?.mediaType || null)}>
									+{row?.mediaType?.length - 1}
						</NabuLink>
					)} */}
			</Grid>
		);
	};

	const [subType, setSubType] = useState<{data: any, open: boolean}>({
		open: false,
		data: []
	});

	const handleSubTypeViewMoreButton = (subType) => {
		setSubType({open: true, data: subType});
	};

	const [mainType, setMainType] = useState<{data: any, open: boolean}>({
		open: false,
		data: []
	});

	const CustomCellMediaSubTypeDetail = (row: any) => {
		const subTypeDetail = row?.rightsCoverageSubType[0] || '';
		return(
			<Grid item container alignItems={'center'}>
				<Grid xs={4} sx={{pl:1.5}}>
					<TextWrap title={subTypeDetail} maxLength={25}></TextWrap>
					{row?.rightsCoverageSubType?.length > 1 && (
						<NabuLink underline='always' variant='body1' onClick={() => handleSubTypeViewMoreButton(row?.rightsCoverageSubType || null)}>
									+{row?.rightsCoverageSubType?.length - 1}
						</NabuLink>
					)}
				</Grid>
			</Grid>
		);
	};

	const CustomCellMediaMainTypeDetails = (row: any) => {
		const mainTypeDetails = row?.rightsCoverageMainType[0] || '';
		return(
			<Grid item container alignItems={'center'}>
				<Grid xs={4} sx={{pl:1.5}}>
					<TextWrap title={mainTypeDetails} maxLength={25}></TextWrap>
					{row?.rightsCoverageMainType?.length > 1 && (
						<NabuLink underline='always' variant='body1' onClick={() => handleSubTypeViewMoreButton(row?.rightsCoverageMainType || null)}>
									+{row?.rightsCoverageMainType?.length - 1}
						</NabuLink>
					)}
				</Grid>
			</Grid>
		);
	};

	const CustomCellStartDate = (row: any) => {
		return (
			<Grid item container alignItems={'center'}>
				<Grid xs={4} sx={{pl:1.5}}>
					{row.startDate ? getLocaleDate(row.startDate) : null}
				</Grid>
			</Grid>
		);
	};

	const CopyToRenewals = (row: any) => {
		return (
			<div className={styles.copytoRenewal}>
				<Box sx={{pl:1.5}}>
					<NabuButton variant='filled' onClick={() => handleCopyToRenewals(row)}>Copy to Renewals</NabuButton>
				</Box>
			</div>
		);
	};

	const ViewMediaType = (row: any) => {
		return (
			<Tooltip title='View All Selected Media Types' arrow>
				<Stack sx={{mr:1, ml: 1, width:110}} dir="rtl">
					<NabuButton onClick={() => handleMediaTypeViewClick(row)} variant='text'>{'View All'}<RemoveRedEye/></NabuButton>
				</Stack>
			</Tooltip> 
		);
	};

	const CustomCellEndDate = (row: any) => {
		return (
			<Grid item container alignItems={'center'}>
				<Grid xs={4} sx={{pl:1.5}} className={row?.isExpired ? styles.expire1 : ''}>
					{row?.endDate ? getLocaleDate(new Date(row?.endDate)) : null}
				</Grid>
			</Grid>
		);
	};

	const CustomCellInPerpetuity = (row: any) => {
		const InPerpetuity = row?.term === 'In Perpetuity' ? 'YES' : 'NO';
		return(
			<Box sx={{pl:1.5}}>{InPerpetuity}</Box>
		);
	};

	const CustomTermCell = (row: any) => {
		const term = row?.term;
		return(
			<>
				<TextWrap title = {term.toUpperCase()}/>
			</>
		);
	};

	const queryClient = useQueryClient();	

	const handleCopyToRenewals = (data) => {
		const payload = {
			rightsAcquiredId: data?.id,
			elementId: elementId,
			contractId,
		};
		const renewalTermAPI = payload => props.for === 'ELEMENT' ? addRenewalTerms(payload) : addContractRenewalTerms(payload);

		renewalTermAPI(payload).then((res) => {
			if(res.status === StatusCodes.CREATED){
				notification.notify({
					message: getNotificationText('Renewal Terms', 'CREATE'),
					type: 'success'
				});
				queryClient.refetchQueries(['rightsAcquired', true]);
				queryClient.refetchQueries(['getRenewalTerms']);
			}
		});
	};
	
	const handleRightsAcquiredEdit = (data: any) => {
		if(props.isSectionVisible){
			props.onFormOpen && props.onFormOpen('Edit');
			const record = data;
			setRightsAcqForm(false);
			const editData = {
				...record,
				Id: record.id,
			};
			setRightsAcquiredForm({
				isOpen: true,
				selectedRightsAcquired: editData,
				rIdx: record?.rowIndex ?? -1
			});
		}else {
			props.showNotification && props.showNotification();
		}
	};

	const primaryActions: ITableActionMenuModel[] = [
		{
			title: () => 'Edit',
			icon: () => Edit,
			iconColor: 'primary',
			onClick: (e)=> handleRightsAcquiredEdit(e),
			'data-cy': 'rights_acquired_edit_btn',
		},
		{
			title: () => 'Delete',
			icon: () => DeleteForever,
			iconColor: 'error',
			onClick: (e) => openDeleteDialog(e),
			'data-cy': 'rights_acquired_delete_btn',
		},
	];
	
	const[rightsAcquired, setRightsAcquired] = useState<any>({
		id: '',
	});

	const [ isDeleteDialogVisible, setDeleteDialogVisibility] = useState<boolean>(false);
	const closeDeleteDialog = () => setDeleteDialogVisibility(false);

	const openDeleteDialog = (e) => {
		setRightsAcquired(e);
		setDeleteDialogVisibility(true);
	};

	const [isDeleteActive, setDeleteActive] = useState(false);

	const handleDeleteRightsAcquired = () => {
		const payload = {
			id: rightsAcquired.id,
		};
		const deleteRightsAcquiredAPI = payload => props.for === 'ELEMENT' ? deleteRightsAcquired(payload) : deleteContractRightsAcquired(payload);

		deleteRightsAcquiredAPI(payload).then((res: any) => {
			if (!(res?.status === StatusCodes.NO_CONTENT)) {
				notification.notify({
					message: res?.data.errorName || getNotificationText('Rights Contracted', 'DELETE', true),
					type: 'error'
				});
				closeDeleteDialog();
			} else {
				setDeleteActive(true);
				notification.notify({
					message: getNotificationText('Rights Contracted', 'DELETE'),
					type: 'success'
				});
				closeDeleteDialog();
				fetchRightsAcquired();
				props?.refresh && props?.refresh();
			}
		});	
	};

	const deleteDialogActions : IDialogAction[] = [
		{	
			name: 'Yes, Remove', 
			onClick: handleDeleteRightsAcquired, 
			color: 'error', 
			variant: 'contained',
			'data-cy': 'yes_remove_btn'
		},
		{	
			name: 'Do not remove', 
			onClick: closeDeleteDialog,
			variant: 'outlined',
			'data-cy': 'no_remove_btn'
		},
	];

	const [rightsAcquiredData, setRightsAcquiredData] = useState<any>({
		data: [],
		totalRecords: 0,
	}); 
	const notification = useNotification();
	const { elementId, contractId } = useParams();

	const DEFAULT_SORT_FIELD = 'CreatedAt';
	const [rightsAcquiredPage, setRightsAcquiredPage] = useState<any>({
		pageNumber: 0,
		pageSize: DefaultPageSize,
		sortDescending: false,
		sortField: DEFAULT_SORT_FIELD,
		filters: [
			{
				isCaseSensitive: false,
				operator: OPERATORS.EQUAL,
				property: 'IsRenewal',
				value: false,
			}
		]
	});

	const [renewalTermsPage,] = useState<any>({
		pageNumber: 0,
		pageSize: DefaultPageSize,
		sortDescending: false,
		sortField: DEFAULT_SORT_FIELD,
		filters: [
			{
				isCaseSensitive: false,
				operator: OPERATORS.EQUAL,
				property: 'IsRenewal',
				value: true,
			}
		]
	});

	const getAPIForFetchingRightsAcquired = () => {
		if(props.isRenewalSection) {
			return props.for === 'ELEMENT' ? getRenewalTerms(renewalTermsPage, elementId) : getContractRenewalTerms(renewalTermsPage, contractId || '');
		}
		return props.for === 'ELEMENT' ? getRightsAcquired(rightsAcquiredPage, elementId) : getContractRightsAcquired(rightsAcquiredPage, contractId || '');
	};

	const { refetch: fetchRightsAcquired } = useQuery({
		queryKey: ['rightsAcquired', props.isRenewalSection, rightsAcquiredPage, renewalTermsPage, props.for],
		queryFn: getAPIForFetchingRightsAcquired,
		refetchOnWindowFocus: false,
		onSuccess: (res) => {			
			if (!(res?.status === StatusCodes.OK)) {
				notification.notify({
					type: 'error',
					message: getNotificationText('RightsAcquired', 'FETCHING', true),	
				});
				return;
			}
			const data = res.data?.records?.length ? res.data.records : [];
			if(data.length == 0 && isDeleteActive){
				const payload = {
					elementId: elementId,
					expirationDate: undefined
				};
				if(props?.for === 'ELEMENT') {
					updateElementExpirationDate(payload);
				}
				setDeleteActive(false);
			}
			setRightsAcquiredData({ data, totalRecords: res.data.totalRecords || 0 });
			props.refresh && props.refresh();
		},
		onError: () => {
			notification.notify({
				type: 'error',
				message: getNotificationText('RightsAcquired', 'FETCHING', true),
			});
		},
	});

	const onPageChange = (currentPage: number) => {
		setRightsAcquiredPage({
			...rightsAcquiredPage,
			pageNumber: currentPage,
		});
	};

	const onNumberOfRowsPerPageChange = (numberOfRows: number) => {
		setRightsAcquiredPage({
			...rightsAcquiredPage,
			pageNumber: 0,
			pageSize: numberOfRows
		});
	};

	const [selectedMediaTypeDialog, setSelectedMediaTypeDialog] = useState<{
		isOpen: boolean,
		mediaType: any,
		selectedNodes: []
	}>({
		isOpen: false,
		mediaType: [],
		selectedNodes: []
	});

	const [, setCheckedNodes] = useState<[]>([]);
	
	const handleMediaTypeViewClick = (data: any) => {
		const checkedMediaTypes = data?.checkedMediaTypeIds;
		const selectedNodes: any = mediaTypeValues?.filter(e => checkedMediaTypes?.includes(e.id));
		setSelectedMediaTypeDialog({isOpen: true, mediaType: checkedMediaTypes.length > 0 ? 
			findNodeAndAncestors(mediaTypeOptions, checkedMediaTypes.filter((item, index) => checkedMediaTypes.indexOf(item) === index)) : 
			convertMediaObject(data?.mediaInformation), selectedNodes : selectedNodes.length > 0 ? selectedNodes : data?.mediaInformation });
		setCheckedNodes(data?.checkedMediaTypeIds);
	};

	const handleMediaTypeViewClose = () => {
		setSelectedMediaTypeDialog({ isOpen: false, mediaType: [], selectedNodes : []});
	};
 
	return (
		<Box>
			<NabuCardDefault>
				<Grid item container>
					<Grid md={6}>
						<h4 data-cy='rights_acquired_header'>{props?.isRenewalSection ? 'Renewal Terms' : 'Rights Contracted'}</h4>
					</Grid>
					{!isOpen && 
					(
						<><Grid item md={6} dir='rtl'>
							<div className={styles.rightsAQ}>
								<NabuButton
									variant='filled'
									data-cy='add_rights'
									onClick={() => {
										if(props.isSectionVisible){
											props.onFormOpen && props.onFormOpen('Add');
											setRightsAcqForm(true); 
											setRightsAcquiredForm({ isOpen: false, rIdx: -1 }); 
										}else {
											props.showNotification && props.showNotification();
										}
									}}
								>
									{props?.isRenewalSection ? 'Renewal Terms' : 'Rights Contracted'} <AddIcon />
								</NabuButton>
								{/* <NabuSearchFilters onDebounceTextChange={handleInputChange} searchByField='Media Type' dir="ltr" /> */}
							</div>
						</Grid></>
					)}
				</Grid>
				<Grid item container>
					<Grid md={12} >
						{isOpen && <><></><div>
							<AddRightsAcquiredForm
								isEdit={!!rightsAcquiredForm?.selectedRightsAcquired || false}
								onClose={() => { 
									props.onFormClose && props.onFormClose(); 
									setRightsAcqForm(false); 
									setRightsAcquiredForm({ isOpen: false, rIdx: -1 }); 
								}}
								fetchRightsAcquired={fetchRightsAcquired}
								initialValue={rightsAcquiredForm?.selectedRightsAcquired}
								refresh={props.refresh}
								elementData={props?.elementData && props?.elementData}
								isFromRenewalTerms={!!props?.isRenewalSection}
								for={props.for}
								isUnion={props?.elementData?.union === 'NON UNION' ? false : true && props?.elementData?.union === '' ? false : true}
							/>
						</div></>}
					</Grid>
				</Grid>
				<Box sx={{ mt: 3 }}>
					<div className={'styles.dGrid'}>
						<div className={styles.stickyHeader}>
							<DataTable
								data-cy='all-rights-contracted-list'
								rows={rightsAcquiredData.data}
								actionsMenu={primaryActions}
								columns={columns}
								totalRowsCount={rightsAcquiredData.totalRecords}
								currentPage={rightsAcquiredPage.pageNumber}
								rowsPerPage={rightsAcquiredPage.pageSize}
								onPageChange={onPageChange}
								onNumberOfRowsPerPageChange={onNumberOfRowsPerPageChange}
								tableName='rightsAcquired'
								inlineRows={{
									slot: 'RightsAcquired',
									rowIndex: rightsAcquiredForm.rIdx,
									isOpen: rightsAcquiredForm.isOpen
								}}
							>
								<NestedTable
									slot='row-detail'
									isEdit={!!rightsAcquiredForm?.selectedRightsAcquired || false}
									fetchRightsAcquired={fetchRightsAcquired}
									initialValue={rightsAcquiredForm?.selectedRightsAcquired}
									for={props.for}
								/>
								<CustomCellStartDate slot='startDate' />
								<CustomCellEndDate slot='endDate' />
								<CustomCellInPerpetuity slot='inPerpetuity' />
								<CustomCellRightsCovered slot='mediaType' />
								<CustomCellMediaSubTypeDetail slot = 'mediaSubTypeDetail'/>
								<CustomCellMediaMainTypeDetails slot = 'rightsCoverageMainType' />
								<CopyToRenewals slot='copyRenewal' />
								<ViewMediaType slot='viewMediaTypes' />
								<CustomTermCell slot = 'term' />
								<AddRightsAcquiredForm
									slot='RightsAcquired'
									isEdit={!!rightsAcquiredForm?.selectedRightsAcquired || false}
									onClose={() => {
										props.onFormClose && props.onFormClose(); 
										setRightsAcqForm(false); 
										setRightsAcquiredForm({ isOpen: false, rIdx: -1 });
									}}
									fetchRightsAcquired={fetchRightsAcquired}
									initialValue={rightsAcquiredForm?.selectedRightsAcquired}
									isFromRenewalTerms={!!props?.isRenewalSection}
									for={props.for}
									isUnion={props?.elementData?.union === 'NON UNION' ? false : true && props?.elementData?.union === '' ? false : true}
								/>
							</DataTable>
						</div>
					</div>
				</Box>
				<NabuDialog
					open={open}
					title='Territory'
					dialogActions={[]}
					handleClose={()=> setOpen(false)}
				>
					<Divider />
					<Stack>
						<ul>
							<li>
							country
							</li>
							<li>
							city
							</li>
							<li>
							state
							</li>
							<li>region</li>
						</ul>
					</Stack>
				</NabuDialog>
				<NabuDialog
					title='Remove Rights Contracted ?'
					open={isDeleteDialogVisible}
					maxWidth='xs'
					handleClose={closeDeleteDialog}
					fullWidth={true}
					dialogActions={deleteDialogActions}
					isPersistent={true}>
					<strong>Are you sure to remove ?</strong>
					<p>This action <b>can not be undone</b></p>
				</NabuDialog>
				<NabuDialog
					open={territoryDialog?.open || false}
					title='Additional Territory'
					dialogActions={[]}
					handleClose={() =>
						setTerritoryDialog({ open: false, regions: [], addresses: [] })
					}
				>
					<Divider />
					<Stack mt={2}>
						{territoryDialog.addressesAndRegions ? 
							<>
								<Typography level='title-sm'>Addresses</Typography>
								<ul>
									{
										territoryDialog.addressesAndRegions.addresses?.map((address, i) => (
											<li key={i}>{(displayAddress(address))}</li>
										))
									}
								</ul>
								<Typography level='title-sm'>Regions</Typography>
								<ul>
									{
										territoryDialog.addressesAndRegions.regions?.map((region, i) => (
											<li key={i}>{region}</li>
										))
									}
								</ul>
							</>
							: 
							<>
								<Typography level='title-sm'>{territoryDialog?.regions ? 'Regions' : 'Addresses'}</Typography>
								<ul>
									{ territoryDialog?.regions ? territoryDialog.regions?.map((region, i) => (
										<li key={i}>{region}</li>
									))
										:
										territoryDialog.addresses?.map((address, i) => (
											<li key={i}>{(displayAddress(address))}</li>
										))
									}
								</ul>
							</>
						}
					</Stack>
				</NabuDialog>
				<NabuDialog
					open={restrictionsData?.isRestrictionDialogOpen}
					title='Restrictions Details'
					dialogActions={[]}
					handleClose={() =>
						setRestrictionsData({ isRestrictionDialogOpen: false, restrictionData: '' })
					}
				>
					<p>{restrictionsData?.restrictionData}</p>
				</NabuDialog>
				<NabuDialog
					open={subType.open}
					title='Sub Type Details'
					dialogActions={[]}
					handleClose={() =>
						setSubType({ open: false, data: [] })
					}
				>
					<Divider />
					<Stack mt={2}>
						<ul>
							{subType.data?.map((subtype, i) => (
								<li key={i}>{subtype}</li>
							))}
						</ul>
					</Stack>
				</NabuDialog>
				<NabuDialog
					open={mainType.open}
					title='Main Type Details'
					dialogActions={[]}
					handleClose={() =>
						setMainType({ open: false, data: [] })
					}
				>
					<Divider />
					<Stack mt={2}>
						<ul>
							{mainType.data?.map((mainType, i) => (
								<li key={i}>{mainType}</li>
							))}
						</ul>
					</Stack>
				</NabuDialog>
				<NabuDialog
					open={mediaType.open}
					title='Media Type Details'
					dialogActions={[]}
					handleClose={() =>
						setMediaType({ open: false, data: [] })
					}
				>
					<Divider />
					<Stack mt={2}>
						<ul>
							{mediaType.data?.map((mediaType, i) => (
								<li key={i}>{mediaType?.mediaType || mediaType?.toUpperCase()}</li>
							))}
						</ul>
					</Stack>
				</NabuDialog>
				<Fragment>
					<NabuDialog
						open={selectedMediaTypeDialog.isOpen}
						maxWidth={'lg'}
						title={'Selected Media Type'}
						dialogActions={[]}
						handleClose={handleMediaTypeViewClose}
					>
						<Grid container>
							<Grid item sm={12} dir='rtl'>
							</Grid>
							<Grid item sm={12}>
								<Tree
									treeData={selectedMediaTypeDialog?.mediaType}
									rootStyle={{display: 'flex'}}
									defaultExpandAll={true}
									showLine={true}
								>
								</Tree>
							</Grid>
						</Grid>
					</NabuDialog>
				</Fragment>
			</NabuCardDefault>
		</Box>
	);
};

export default RightsAcquiredPage;
