import { useEffect, useState } from 'react';

import NabuButton from '@/common/components/atoms/button/button';
import { NabuTextbox } from '@/common/components/atoms/textbox/textbox';
import { CompanyMainType, ICompanyAddress } from '@/common/types/company';
import {
	IBaseContract,
	ICommercialDetail,
	IContractSagAftraSdExtComExTeamForm,
} from '@/common/types/contract';
import styles from '@/modules/contracts/components/addContracts.module.scss';
import { IPersonAddress, IPersonRecord } from '@/services/models/person';
import { Box, FormControl, Tooltip } from '@mui/material';
import { UseFormReturn, useForm } from 'react-hook-form';

import { NabuDatepicker } from '@/common/components/atoms/datepicker/datepicker';
import LookAheadSelect, { ILookAheadSelectOption } from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import NabuSelect from '@/common/components/atoms/select/select';

import NabuDynamicInputDropdown from '@/common/components/atoms/dynamicInputDropdown/dynamicInputDropdown';
import { NabuBaseTextArea } from '@/common/components/atoms/textArea/textArea';
import NabuTimePicker from '@/common/components/molecules/timepicker/timePicker';
import AddAssetInline from '@/common/components/templates/pages/add/addAssetInline';
import AddCompanyAddressInline from '@/common/components/templates/pages/add/addCompanyAddressInline';
import AddCompanyInline from '@/common/components/templates/pages/add/addCompanyInline';
import AddEmailInline from '@/common/components/templates/pages/add/addEmailInline';
import { IPopOverState } from '@/common/components/templates/pages/add/addNewAsset';
import AddPersonInline from '@/common/components/templates/pages/add/addPersonInline';
import AddPhoneNumberInline from '@/common/components/templates/pages/add/addPhoneNumberInline';
import { CONTRACT_GUARDIAN, ContractStatus, DefaultPageSize, SagAftraExhibit2PerformerTypeOptions } from '@/common/constants/constants';
import { COMPANY_MAIN_TYPE, OPERATORS } from '@/common/enums';
import { StatusCodes } from '@/common/enums/StatusCodes';
import { ICompanyRecord } from '@/common/types/company';
import { IFilterOption } from '@/common/types/filterModel';
import { addressShortner, displayAddress, displayOnlyEmail, displayPhoneNumberWithoutLabel, getCompanyDisplayName, getLocaleDate, getLocaleDateTime, getNotificationText, getPersonFullName, numberOnChangeEventHandler, sortEmailOrPhoneNumberForDropDown } from '@/common/utils/utils';
import { EmailFormModel, PhoneNumberFormModel } from '@/modules/company/shared/models/company';
import { getAllAssetForBasicDetail, getAllSignatory, retriveAssetProducts } from '@/services/asset';
import { addNewCompanyEmail, getAllAddressByCompany, getAllProducts, getBusinessTaxInfo, getCompanyBasicDetails } from '@/services/company';
import { addContractTeamSagAftraSdEmpCtrCom, updateContractTeamSagAftraSdExtCom } from '@/services/contract';
import { IAssetRecord } from '@/services/models/asset';
import { GetByWithFullNameSearchForBasicDetail, createPersonEmail, createPersonPhoneNumber, getAddressBasedOnPinCode, getAllAddressByPerson } from '@/services/person';
import store from '@/store';
import { setDisableLoader } from '@/store/app/reducer';
import { updateContractFormState } from '@/store/contract/reducer';
import { Add, DeleteForever, HelpOutline } from '@mui/icons-material';
import {
	FormHelperText,
	Grid
} from '@mui/material';
import { HttpStatusCode } from 'axios';
import { City, State } from 'country-state-city';
import { addDays } from 'date-fns';
import { Controller, useFieldArray } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import TEAMS from '../../forms/img/teamCompanies.png';
import ContractMutationWrapper from '../contractMutationWrapper/contractMutationWrapper';
import useContractMutation from '../contractMutationWrapper/useContractMutation';
import LocationFreeTextSelect from './locationFreeTextSelect';
import { IContractFormState } from './standardSagAftraEmployment2022';

interface Props {
	mainForm?: UseFormReturn<IBaseContract, any, undefined>;
	onCancel?: VoidFunction;
	onSave?: CallableFunction;
	handlePrintStateOfContract?: CallableFunction;
	initialValue?: IContractSagAftraSdExtComExTeamForm;
	isPrint?: boolean;
	isEdit?: boolean;
	contractId?: string;
	defaultValue?: IBaseContract | any;
	address?: ICompanyAddress[];
	people?: IPersonRecord[];
	assets?: IAssetRecord[];
	companies?: ICompanyRecord[];
	isDuplicateContractName?: boolean;
}

// eslint-disable-next-line react/display-name
const TeamExtraContract = (props: Props) => {
	const { 
		handlePreContractSubmissionTasks,
		addMultipleContractDetails, 
		notification, 
		setPacketFormState, 
		params, 
		setParams, 
		onSubmitError ,
		ADVERTISER_FILTER, 
		AD_AGENCY_FILTER, 
		DEFAULT_COUNTRY_CODE, 
	} = useContractMutation<IContractSagAftraSdExtComExTeamForm>();

	useEffect(() => {
		if(props?.defaultValue?.advertiserId)
			contractForm.setValue('advertiserId', props?.defaultValue?.advertiserId, { shouldDirty: true, shouldValidate: true});
		if(props?.defaultValue?.productId)
			contractForm.setValue('productId', props?.defaultValue?.productId, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.advertiserId]);

	useEffect(() => {
		contractForm.setValue('isJPCAuthorizer', props?.defaultValue?.isJPCAuthorizer, { shouldDirty: true, shouldValidate: true});
	}, [props.defaultValue?.isJPCAuthorizer]);

	const NON_EVENING_WEAR_COST = 20.36;
	const EVENING_WEAR_COST = 33.91;
	const COMPENSATION_OPTIONS = [ 'SCALE', 'DOUBLE SCALE' ];

	const contractForm = useForm<IContractSagAftraSdExtComExTeamForm>({
		defaultValues: !props?.initialValue
			? {
				...(props?.mainForm?.getValues() || {}),
				...(props?.defaultValue || {}),
				workDays: [{}],
				commercials: [{}],
			}
			: {...(props?.initialValue || {}), 
				commercials: props?.initialValue?.commercials?.length ? props?.initialValue?.commercials : [{}], 
				workDays: props?.initialValue?.workDays?.length ? props?.initialValue?.workDays : [{}],
			},
	});

	const [locTrigger] = useState<boolean>(false);

	const [, setCompanyOptions] = useState<ICompanyRecord[]>([]);
	const [personOptions, setPersonOptions] = useState<IPersonRecord[]>(
		props?.people || []
	);
	const [assetOptions, setAssetOptions] = useState<IAssetRecord[]>(props?.assets || []);
	const [addressOptions, setAddressOptions] = useState<ICompanyAddress[]>(
		props?.address || []
	);
	const [selectedAddress, setSelectedAddress] = useState<{id:any, type:any}>();
	const [contractFormState, setContractFormState] =
		useState<IContractFormState>({});
	const [addressPopOver, setAddressPopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const workDayFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'workDays',
	});

	const commercialFieldArray = useFieldArray({
		control: contractForm.control,
		name: 'commercials',
	});

	const AGENT_REPRESENTATIVE_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.EQUAL,
			property: 'CompanyType.MainType',
			value: 'AGENT/REPRESENTATIVE',
		},
	];

	const getLoanOutCorpFilter = (): IFilterOption[] => {
		const personIdContract = contractForm.watch('performerId.value') || '';
		const defaultFilter: IFilterOption[] = [
			{
				isCaseSensitive: false,
				operator: OPERATORS.EQUAL,
				property: 'CompanyType.MainType',
				value: COMPANY_MAIN_TYPE.LOAN_OUT_CORPORATION?.toUpperCase(),
			}
		];

		defaultFilter.push({
			isCaseSensitive: false,
			operator: OPERATORS.IN,
			property: 'LoanoutPeople.Id',
			value: JSON.stringify([personIdContract]),
		});

		return [...defaultFilter];
	};

	const PROD_COM_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.NOT_EQUAL,
			property: 'CompanyNames.Name',
			value: '',
		},
	];

	const PRODUCT_FILTER: IFilterOption[] = [
		{
			isCaseSensitive: false,
			operator: OPERATORS.NOT_EQUAL,
			property: 'ProductName',
			value: '',
		},
	];

	// helper functions
	const getCommercialFilters = () => {
		const assetFilter: IFilterOption[] = [
			{
				isCaseSensitive: false,
				operator: OPERATORS.NOT_EQUAL,
				property: 'Title',
				value: '',
			},
			{
				isCaseSensitive: false,
				operator: OPERATORS.IN,
				property: 'Advertisers.Id',
				value: JSON.stringify([contractForm.watch('advertiserId.value')]),
			},
		];
		return assetFilter;
	};

	const getPerformerFilter = (): IFilterOption[] => {
		const DEFAULT_FILTER: IFilterOption = {
			value: JSON.stringify(['TALENT']),
			property: 'PersonType',
			operator: OPERATORS.IN,
			isCaseSensitive: false,
		};
		return [DEFAULT_FILTER];
	};

	const onAddWorkDay = () => {
		if (workDayFieldArray.fields.length) {
			const previousRow = {
				...contractForm.watch(
					`workDays.${workDayFieldArray.fields.length - 1}`
				),
				id: undefined,
			};

			workDayFieldArray.append({
				...previousRow,
				date: previousRow?.date ? addDays(new Date(previousRow?.date || ''), 1).toISOString() : '',
			});
		}
	};

	const onRemoveWorkDay = (index: number) => {
		if (workDayFieldArray.fields.length > 1) {
			workDayFieldArray.remove(index);
		}
	};
	
	const onAddCommercial = () => {
		commercialFieldArray.append({} as ICommercialDetail);
	};

	const getProducts = async () => {
		const commercials = contractForm.watch('commercials');
		let totalProducts: any = [];
		
		if(commercials){
			for(let i = 0; i < commercials.length; i++){
				const filterModel = {
					pageSize: DefaultPageSize,
					sortDescending: false,
					sortField: 'CreatedAt',
					pageNumber: 0,
					filters: [{
						property: 'Asset.Id',
						value: commercials[i].assetId?.value ?? '',
						operator: OPERATORS.EQUAL,
						isCaseSensitive: false
					}]
				};
		
				const res: any = await retriveAssetProducts(filterModel);
		
				if(res?.data?.records){
					const products: any = res?.data?.records?.map(product => {
						return { value: product.id, label: product.productName };
					});
					
					totalProducts = [...totalProducts, ...products];
				}
			}

			contractForm.setValue('productId', totalProducts, { shouldDirty: true, shouldValidate: true });
		}
	};

	const onRemoveCommercial = (index: number) => {
		if (commercialFieldArray.fields.length > 1) {
			commercialFieldArray.remove(index);
			getProducts();
		}
	};


	const onAddressChange = (addressId: string, field: 'performer' | 'performerAdAgency') => {
		setSelectedAddress({type: field, id: addressId});
		const address = [...addressOptions, ...(props?.address || [])]?.find( ad => ad?.id === addressId);
		
		if(address) {
			contractForm.setValue(`${field}Zip`, address?.zipCode, {shouldDirty: true});
			contractForm.setValue(`${field}State`, address?.state, {shouldDirty: true});
			contractForm.setValue(`${field}City`, address?.city?.toUpperCase(), {shouldDirty: true});
		} else {
			contractForm.setValue(`${field}State`, '', {shouldDirty: true});
			contractForm.setValue(`${field}Zip`, '', {shouldDirty: true});
			contractForm.setValue(`${field}City`, '', {shouldDirty: true});
		}
	};

	const handleStateOptions = (
		responseData: any[],
		type: 'COMPANY' | 'PERSON' | 'ASSET' | 'ADDRESS'
	) => {
		const cb = {
			COMPANY: setCompanyOptions,
			PERSON: setPersonOptions,
			ASSET: setAssetOptions,
			ADDRESS: setAddressOptions,
		}[type];

		if (responseData && Array.isArray(responseData)) {
			cb((prev) => {
				const oldFilter = prev?.filter(
					(d) => !responseData?.some((r) => r?.id === d?.id)
				);

				return [...oldFilter, ...responseData];
			});
		}
	};

	useEffect(() => {
		if(selectedAddress?.type !== ''){
			onAddressChange(selectedAddress?.id, selectedAddress?.type);
		}
	}, [addressOptions]);

	const getAssetAdIdByAssetIndex = (index: number) => {		
		const assetId = contractForm.watch(`commercials.${index}.assetId`)?.value;
		return assetOptions?.find((d) => d?.id === assetId)?.adId || '';
	};

	const getStateOptions = () => {
		const states = State.getStatesOfCountry(DEFAULT_COUNTRY_CODE).map(
			(state) => ({ label: state.name?.toUpperCase(), value: state.isoCode })
		);
		if (!states.length) states.unshift({ label: 'NA', value: 'NA' });
		return states;
	};

	const getCityOptions = (stateCode?: string) =>
		stateCode
			? City.getCitiesOfState(DEFAULT_COUNTRY_CODE, stateCode)?.map((city) => ({
				label: city.name?.toUpperCase(),
				value: city.name?.toUpperCase(),
			})) || []
			: [];

	const companyOptionGenerator = (responseData: ICompanyRecord[]) => {
		handleStateOptions(responseData, 'COMPANY');

		return responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));
	};


	const performerOptionGenerator = (responseData: IPersonRecord[]) => {
		handleStateOptions(responseData, 'PERSON');
		return responseData?.map((d) => ({
			value: d?.id,
			label: getPersonFullName(d?.personName),
		}));
	};

	const addressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');
		
		return responseData?.map((d) => ({
			value: d?.id,
			label: addressShortner(d),
		}));
	};

	const adAgencyAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: displayAddress(d)?.toUpperCase(),
		}));
	};

	const productOptionGenerator = (responseData) => {
		return responseData?.map((d) => ({
			value: d?.id,
			label: d?.productName?.toUpperCase(),
		}));
	};

	const lookAheadAssetOptionGenerator = (responseData: IAssetRecord[]) => {
		handleStateOptions(responseData, 'ASSET');

		return responseData?.map((d) => ({
			value: d?.id || '',
			label: d?.title || '',
		}));
	};

	const onZipCodeChange = (zipCode: string, field: 'performer' | 'performerAdAgency' | 'service') => {
		if (zipCode) {
			store.dispatch(setDisableLoader(true));
			getAddressBasedOnPinCode(zipCode)
				.then((res) => {
					if (res?.data?.['country abbreviation'] === 'US') {
						if(res.data){
							const place = res.data.places[0];
							contractForm.setValue(`${field}State`, place['state abbreviation'], { shouldDirty: true });
							contractForm.setValue(`${field}City`, place['place name']?.toUpperCase() ?? '', { shouldDirty: true });
						}
					}
				})
				.catch((e) => console.error(e?.message))
				.finally(() => {
					store.dispatch(setDisableLoader(false));
				});
		}
	};

	const getPerformerPhoneNumberOptions = () => {
		const unsortedPhoneNumbers =
			[...(personOptions || []), ...(props?.people || [])]?.find((a) => a?.id === contractForm.watch('performerId.value'))?.phoneNumbers || [];
		
		const phoneNumbers = sortEmailOrPhoneNumberForDropDown(unsortedPhoneNumbers);
		return (
			phoneNumbers?.map((pn) => ({
				label: displayPhoneNumberWithoutLabel(pn),
				value: pn?.id || '',
			})) || []
		);
	};

	const getPerformerEmailsOptions = () => {
		const unsortedEmails =
			[...(personOptions || []), ...(props?.people || [])]?.find(
				(a) => a?.id === contractForm.watch('performerId.value')
			)?.emails || [];
	
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
			
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};


	const calculateTotalWardrobeFee = () => {
		const getNumber = (value: number | string | undefined): number =>
			isNaN(Number(value)) ? 0 : Number(value);

		const eveningWearCost =
			getNumber(contractForm.watch('eveningWearCount')) * EVENING_WEAR_COST;
		const nonEveningWearCost =
			getNumber(contractForm.watch('nonEveningWearCount')) *
			NON_EVENING_WEAR_COST;

		contractForm.setValue(
			'totalWardrobeFee',
			parseFloat((eveningWearCost + nonEveningWearCost).toFixed(4)),
			{ shouldDirty: true }
		);
	};

	const handleFormClose = () => {
		contractForm?.reset();
		props?.onCancel && props?.onCancel();
	};

	const { mutate: addContractDetails, isLoading: isAddingContract } =
		useMutation({
			mutationKey: ['contract-add'],
			mutationFn: (payload: any) => addContractTeamSagAftraSdEmpCtrCom(payload),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'CREATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'CREATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const { mutate: editContractDetails, isLoading: isEditingContract } =
		useMutation({
			mutationKey: ['contract-edit'],
			mutationFn: (payload: any) => updateContractTeamSagAftraSdExtCom(
				props?.contractId || '',
				payload
			),
			onSuccess: (res) => {
				if (res?.status === HttpStatusCode.Ok) {
					if (res.data?.error?.errorMessage) {
						notification.notify({
							message: res.data?.error?.errorMessage,
							type: 'error',
						});
						return;
					}
					notification.notify({
						message: getNotificationText('Contract', 'UPDATE'),
						type: 'success',
					});
					handleFormClose();
					if(res?.data?.id && props?.onSave){
						props?.onSave(`/contracts/${res?.data?.id}/contract-view`);
					}
					store.dispatch(updateContractFormState({
						isContractUpdated: true,
						updatedContractId: res?.data?.id
					}));
				} else
					notification.notify({
						message:
							res?.data?.error?.errorMessage ??
							getNotificationText('Contract', 'UPDATE', true),
						type: 'error',
					});
			},
			onError: () => {
				notification.notify({
					message: getNotificationText('Contract', 'CREATE', true),
					type: 'error',
				});
			},
		});

	const handleContractSubmission = async (
		submitData: IContractSagAftraSdExtComExTeamForm,
		handleMultipleContractAdd?: CallableFunction
	) => {		
		handlePreContractSubmissionTasks(submitData, (data) => {
			const commercials = data?.commercials?.map((d) => ({
				...d,
				assetId: d?.assetId?.value,
			}));
			const workDays = data?.workDays?.filter((d) => d?.date) || [];

			const mainFormValues = props?.mainForm?.getValues();	
			const addMainFormValues = !props?.isEdit ? {
				contractType: mainFormValues?.contractType,
				contractDisplayName: mainFormValues?.contractDisplayName,
				unionType: mainFormValues?.unionType,
				unionAgreement: mainFormValues?.unionAgreement,
				payrollVendorAddressId: mainFormValues?.payrollVendorAddressId || '',
			} : {};

			const submitData = {
				...data,
				...addMainFormValues,
				payrollVendorAddressId: data?.payrollVendorAddressId?.value,
				contractSagAftraSdExtComEx2Y22: undefined,
				payrollVendorId: data?.payrollVendorId?.value,
				performerId: data?.performerId?.value,
				advertiserId: data?.advertiserId?.value,
				returnToEmail: data?.returnToEmail?.value,
				adAgencyId: data?.adAgencyId?.value,
				adAgencyAddressId: data?.adAgencyAddressId?.value,
				productId: data?.productId?.map(product => product?.value),
				performerAddressId: data?.performerAddressId?.value,
				talentAgencyId: data?.talentAgencyId?.value,
				performerAdAgencyId: data?.performerAdAgencyId?.value,
				performerAdAgencyAddressId: data?.performerAdAgencyAddressId?.value,
				producerCompanyId: data?.producerCompanyId?.value,
				producerCompanyAddressId: data?.producerCompanyAddressId?.value,
				loanOutId: data?.loanOutId?.value,
				commercials,
				workDays
			};			

			if (!props?.isEdit){
				addContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			}
			else {
				editContractDetails(submitData, {
					onSuccess: () => {
						handleMultipleContractAdd && handleMultipleContractAdd(submitData);
						params.delete('isEdit');
						setParams(params);
					}
				});
			} 
		});
	};

	const onSubmit = (data: IContractSagAftraSdExtComExTeamForm) => {
		if(!props?.mainForm?.watch('contractDisplayName')){
			notification.notify({
				message: 'Please fill Contract Display Name.',
				type: 'error'
			});
		}
		if(props?.isDuplicateContractName){
			notification.notify({
				message: 'Contract with the same name already exists',
				type: 'error',
			});
			return;
		}
		handleContractSubmission(data);
	};

	const [contractFormData,setContractFormData ] = useState<IContractSagAftraSdExtComExTeamForm>();

	const [, setNoOfTalentsSelected] = useState(false);

	const handleTalentDetailsForm = (data: any) => {
		setNoOfTalentsSelected(false);
		const handleMultipleContractAdd = (payloadData) => {
			if(data) {
				const contractAddModels = data?.talentRecords?.map((talent, index) => {
					const submitData = {
						...payloadData,
						id: undefined,
						status:ContractStatus?.Draft,
						jobTitle: talent?.role,
						performerAdAgencyId: talent?.agent?.value,
						performerId: talent?.talentName?.value,
						contractDisplayName: `${payloadData?.contractDisplayName}_copy_${index+1}`,
						compensation: talent?.compensation,
						performerType:talent?.perfCategory?.toUpperCase(),
						performerAddressId:undefined,
						performerEmailId:undefined,
						performerPhoneId:undefined,
						performerAdAgencyAddressId:undefined,
						loanOutId: undefined,
						fedId:undefined,
					};
					return submitData;
				});
				const multipleContractData = {
					contractAddTeamSagAftraSdExtComModels: contractAddModels
				};
				addMultipleContractDetails(multipleContractData);
			}
		};
		contractFormData && handleContractSubmission(contractFormData, handleMultipleContractAdd);
	};


	const handleSaveAndDuplicate = (data: IContractSagAftraSdExtComExTeamForm) => {
		if(props?.isDuplicateContractName){
			notification.notify({
				message: 'Contract with the same name already exists',
				type: 'error',
			});
			return;
		}
		else{
			setNoOfTalentsSelected(true);
			setContractFormData(data);
		}
	};

	useEffect(()=> {
		if(props?.isEdit && commercialFieldArray.fields.length === 0){
			onAddCommercial();
		}
	}, [props?.isEdit]);

	const onAdAgencyAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('adAgencyId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('adAgencyId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const PerformerAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('performerId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('performerAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const [phoneNumberPopOver, setPhoneNumberInlinePopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const closePhoneNumberInlinePopOver = () => {
		setPhoneNumberInlinePopOver({ isOpen: false });
	};

	const openPhoneNumberInlinePopOver = (e: React.MouseEvent<HTMLButtonElement | null>, key?: string) => {
		setPhoneNumberInlinePopOver({ isOpen: true, event: e?.currentTarget, for: key });
	};

	const onPerformerPhoneNumberInlineSubmit = (formData: any) => {
		const personId = contractForm.watch('performerId.value') || '';
		createPersonPhoneNumber({ personId, phoneNumber: {...formData, isPrimary: true} }).then((res: any) => {
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Phone Number', 'CREATE'),
					type: 'success',
				});
				closePhoneNumberInlinePopOver();

				setPersonOptions(prev => (
					prev?.map(pn => (pn?.id == contractForm.watch('performerId.value') ? ({
						...pn,
						phoneNumbers: [
							{ id: res?.data?.id, ...formData },
							...(pn?.phoneNumbers || {}) as any
						]
					}) : pn))
				));	
				
				contractForm.trigger('performerId');
				contractForm.trigger('performerId.value');
				contractForm.setValue('performerPhoneId', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Phone Number.',
					type: 'error',
				});
			}
		});
	};

	useQuery({
		queryKey: ['company', 'loan-out-corp', contractForm.watch('loanOutId.value'), props?.isPrint],
		queryFn: () => getBusinessTaxInfo(contractForm.watch('loanOutId.value') || ''),
		refetchOnWindowFocus: false,
		enabled: !!contractForm.watch('loanOutId.value') && !props?.isPrint,
		onSuccess: (res: any) => {
			const ein = res?.data?.ein;
			
			!props?.isPrint && contractForm.setValue('fedId', ein, { shouldDirty: true});
				
		},
		onError: () => contractForm.setValue('fedId', '', { shouldDirty: true}),
	});

	const [emailPopOver, setEmailInlinePopOver] = useState<IPopOverState>({
		isOpen: false,
	});

	const openEmailInlinePopOver = (e: React.MouseEvent<HTMLButtonElement | null>, key?: string) => {
		setEmailInlinePopOver({ isOpen: true, event: e?.currentTarget, for: key });
	};

	const closeEmailInlinePopOver = () => {
		setEmailInlinePopOver({ isOpen: false });
	};

	const onPerformerEmailInlineSubmit = (formData: any) => {
		const personId = contractForm.watch('performerId.value') || '';
		createPersonEmail({personId, Email: {...formData, isPrimary: true}}).then((res: any) =>{
			if(res.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Email', 'CREATE'),
					type: 'success',
				});
				closeEmailInlinePopOver();

				setPersonOptions(prev => (
					prev?.map(em => (em?.id == contractForm.watch('performerId.value') ? ({
						...em,
						emails: [
							{ id: res?.data?.id, ...formData },
							...(em?.emails || {}) as any
						]
					}) : em))
				));	

				contractForm.trigger('performerId');
				contractForm.trigger('performerId.value');
				contractForm.setValue('performerEmailId', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Email Address.',
					type: 'error',
				});
			}
		});
	};

	const handleCommercialChange = async (id: string) => {
		const filterModel = {
			pageSize: DefaultPageSize,
			sortDescending: false,
			sortField: 'CreatedAt',
			pageNumber: 0,
			filters: [{
				property: 'Asset.Id',
				value: id,
				operator: OPERATORS.EQUAL,
				isCaseSensitive: false
			}]
		};

		const res: any = await retriveAssetProducts(filterModel);

		if(res?.data?.records){
			const existingProducts: ILookAheadSelectOption[] = contractForm.getValues('productId') ?? [];
			let products: any = res?.data?.records?.map(product => {
				return { value: product.id, label: product.productName, assetId: id };
			});

			products = products.filter(product => {
				const foundProduct = existingProducts.find(eProduct => eProduct.value === product.value);
				if(foundProduct) return false;
				else return true;
			});

			contractForm.setValue('productId', [...products, ...existingProducts], { shouldDirty: true, shouldValidate: true });
		}
	};

	const onProducerAddressCreated = (createdAddress: IPersonAddress) => {
		setPersonOptions((prev) => {
			const person = personOptions.find(
				(op) => op.id === contractForm.watch('producerCompanyId.value')
			);

			!person?.addresses?.some((ad) => ad.id === createdAddress?.id) &&
				person?.addresses?.push(createdAddress);
			return prev?.map((c) => (c.id === person?.id ? person : c));
		});
		contractForm.setValue('producerCompanyAddressId', { value : createdAddress?.id || '', label: displayAddress(createdAddress)}, {
			shouldDirty: true,
			shouldValidate: true,
		});
	};

	const producerAddressOptionGenerator = (responseData: ICompanyAddress[]) => {
		handleStateOptions(responseData, 'ADDRESS');

		return responseData?.map((d) => ({
			value: d?.id,
			label: addressShortner(d),
		}));
	};

	const onProducerEmailInlineSubmit = (formData: any) => {
		const addressId = contractForm.watch('producerCompanyAddressId.value') || '';
		addNewCompanyEmail(addressId, {...formData, isPrimary: true}).then((res: any) =>{
			if(res?.status === StatusCodes.OK){
				notification.notify({
					message: getNotificationText('Email', 'CREATE'),
					type: 'success',
				});
				closeEmailInlinePopOver();

				setAddressOptions((prev) =>
					prev.map((ad) =>
						ad.id === contractForm.watch('producerCompanyAddressId.value')
							? {
								...ad,
								emails: [
									{ id: res?.data?.id, ...formData },
									...(ad?.emails || []),
								],
							}
							: ad
					)
				);
				contractForm.trigger('producerCompanyAddressId', { shouldFocus: true });
				contractForm.setValue('producerCompanyEmailId', res?.data?.id);
			}
			else {
				notification.notify({
					message: 'Error While Adding Email Address.',
					type: 'error',
				});
			}
		});
	};

	const getCompanyEmailOptions = (addressId?: string) => {
		const unsortedEmails =
			addressOptions?.find(
				(a) => a?.id === addressId
			)?.emails || [];
		
		const emails = sortEmailOrPhoneNumberForDropDown(unsortedEmails);
		return (
			emails?.map((e) => ({
				label: displayOnlyEmail(e) || '-',
				value: e?.id || '',
			})) || []
		);
	};

	const handleProducerChange = (index ?: any)=>{
		if(index ==0){
			contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
			contractForm.setValue('producerCompanyAddressId',null,{shouldDirty:true});
			contractForm.setValue('producerCompanyEmailId','',{shouldDirty:true});
		}	
		if(contractForm.watch(`commercials.${0}.assetId.value`)?.length == 0){
			contractForm.setValue('producerCompanyId',null,{shouldDirty:true});
			contractForm.setValue('producerCompanyAddressId',null,{shouldDirty:true});
			contractForm.setValue('producerCompanyEmailId','',{shouldDirty:true});
		}
	};

	return (
		<>
			<ContractMutationWrapper
				isEdit={props?.isEdit}
				isPrint={props?.isPrint}
				contractForm={contractForm}
				contractFormState={contractFormState}
				setContractFormState={setContractFormState}
				handleFormClose={handleFormClose}
				handleSaveAndDuplicate={handleSaveAndDuplicate}
				handleTalentDetailsForm={handleTalentDetailsForm}
				isAddingContract={isAddingContract}
				isEditingContract={isEditingContract}
				onSubmit={contractForm.handleSubmit(onSubmit, onSubmitError)}
				setPacketFormState={setPacketFormState}
				performerCategory = {SagAftraExhibit2PerformerTypeOptions}
				isUnion={true}
				mainForm={props?.mainForm}
				isDuplicateContractName={props.isDuplicateContractName}
			>
				{/* Main Contract Form Start */}
				<>
					<div className={styles.contractFormBlock}>
						<div className={styles.bgWhite} style={{padding:'20px'}}>
							<h4>{contractForm.watch('contractId') ? `#${contractForm.watch('contractId')}` : ''}</h4>
							<Grid item container columnSpacing={3}>
								<Grid item md={2}>
									<img src={TEAMS} width={'100%'} style={{marginTop:35}}/>
								</Grid>
								<Grid item md={6} >
									<Box sx={{pt:4}}>
										<h2 style={{textAlign:'center', fontSize:'28px'}}>2022 SAG-AFTRA <br></br>STANDARD EMPLOYMENT CONTRACT</h2>
										<h4 style={{textAlign:'center'}}>COMMERCIALS - Extra Performers</h4>
										<h4 style={{textAlign:'center'}}><b>EXHIBIT 2</b></h4>
									</Box>
								</Grid>
								<Grid item md={4}>
									<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
										<tr>
											<td>Date</td>
											<td><FormControl fullWidth className={styles.tableForms}>
												{props?.isPrint ? (
													<p className={styles.dateAndTimePrintText} style={{padding:'10px'}}>{getLocaleDate(contractForm.watch('producerDate')) || ''}</p>
												) : (<Controller
													control={contractForm.control}
													name='producerDate'
													render={({ field }) => (
														<NabuDatepicker
															{...field}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'Date' : ''}
														/>
													)}
												/>)}
												{contractForm.formState?.errors
													?.producerDate?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors
																?.producerDate.message
														}
													</FormHelperText>
												)}
											</FormControl></td>
										</tr>
										<tr>
											<td>Est. #</td>
											<td> <FormControl fullWidth className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='est'
													defaultValue=''
													render={({ field }) => (
														<NabuTextbox
															data-cy='est_name_textbox'
															{...field}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'Est' : ''}
														/>
													)}
												/>
												{contractForm.formState.errors?.jobTitle
													?.message && (
													<FormHelperText
														data-cy='errorHelper-jobTitle'
														error={true}
													>
														{
															contractForm.formState?.errors?.jobTitle
																.message
														}
													</FormHelperText>
												)}
											</FormControl></td>
										</tr>
										<tr>
											<td>Job #</td>
											<td><FormControl fullWidth className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='jobTitle'
													defaultValue=''
													render={({ field }) => (
														<NabuTextbox
															data-cy='job_name_textbox'
															{...field}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'Job' : ''}
														/>
													)}
												/>
												{contractForm.formState.errors?.jobTitle
													?.message && (
													<FormHelperText
														data-cy='errorHelper-job-title-name'
														error={true}
													>
														{
															contractForm.formState?.errors?.jobTitle
																.message
														}
													</FormHelperText>
												)}
											</FormControl></td>
										</tr>
									</table>
									<div style={{float:'right'}}>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isJPCAuthorizer'
												render={({ field }) => (
													<Box display={'flex'} alignItems={'flex-start'}>
														<input
															{...field}
															key={`jpc-${contractForm.watch(
																'adAgencyId.value'
															)}-${contractForm.watch(
																'advertiserId.value'
															)}`}
															value={`${field.value}`}
															checked={field.value || false}
															onChange={undefined}
															type='checkbox'
															style={{
																marginTop: '3px',
															}}
														/>
														<span> JPC AUTHORIZER </span>
													</Box>
												)}
											/>
										</Box>
									</div>
								</Grid>
							</Grid>
							<hr></hr>

							<table width={'100%'} border={0} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
								<tr>
									<td width={140}>
										<span>Between <b> (Producer){ !props?.isPrint && (
											<Tooltip
												placement='right-end'
												title={'Signatory'}
											>
												<HelpOutline fontSize='inherit' />
											</Tooltip>)
										}</b></span>
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												key={`commercial-assetId
																	-${contractForm.watch(`commercials.${0}.assetId.value`)}
																	`}
												control={contractForm.control}
												name='producerCompanyId'
												render={({ field }) => (
													<>
														<LookAheadSelect
															key={`commercial-assetId
																				-${contractForm.watch(`commercials.${0}.assetId.value`)}
																				`}
															{...field}
															placeholder={!props?.isPrint ? 'Producer' : ''}
															filterProperty='CompanyNames.Name'
															APICaller={(filterModel) => getAllSignatory(contractForm.watch(`commercials.${0}.assetId.value`),filterModel)}
															optionGenerator={companyOptionGenerator}
															isClearable={true}
															onChange={(e: any) => {
																field.onChange(e);
																contractForm.setValue('producerCompanyAddressId', null, { shouldDirty: true});
																contractForm.setValue('producerCompanyEmailId', '', { shouldDirty: true});
																contractForm.trigger('producerCompanyAddressId');
																contractForm.trigger('producerCompanyEmailId');
																// onAddressChange(contractForm.watch('producerCompanyAddressId.value') || '', 'producer');
															}}
															isDisabled={props?.isPrint}
															cacheOptions
															defaultOptions
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
														{!props?.isPrint && (
															<Box sx={{mt:1.3, marginBottom:'-27px'}}>
																<AddCompanyInline
																	title='Company' />
															</Box>
														)}
													</>
												)}
											/>
											{contractForm.formState.errors?.producerCompanyId
												?.message && (
												<FormHelperText
													error={true}
													data-cy='errorHelper-producerCompanyId'
												>
													{
														contractForm.formState?.errors
															?.producerCompanyId?.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td width={130}>
										<span>, and <b>(Performer).</b></span>
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='performerId'
												render={({ field }) => (
													<>
														<LookAheadSelect
															data-cy='name_select'
															{...field}
															key={`performer-name
																										-${contractForm.watch('performerId.value')}
																										-${contractForm.watch('performerPhoneId')}-${contractForm.watch('performerEmailId')}`}
															isClearable={true}
															placeholder={!props?.isPrint ? 'Performer' : ''}
															filterProperty='PersonName.FirstName'
															APICaller={(
																filterModel,
																searchText
															) =>
																GetByWithFullNameSearchForBasicDetail(
																	filterModel,
																	searchText
																)
															}
															isSearchText={true}
															isDisabled={props?.isPrint}
															optionGenerator={
																performerOptionGenerator
															}
															defaultFilter={getPerformerFilter()}
															onChange={(e: any) => {
																field.onChange(e);
																contractForm.trigger('performerEmailId');
																contractForm.setValue('performerEmailId', '', { shouldDirty: true});
																contractForm.setValue('performerPhoneId', '', { shouldDirty: true});
																contractForm.setValue('performerPrintName', contractForm.watch('performerId.label'),{ shouldDirty: true });
																contractForm.setValue('performerAddressId', null, { shouldDirty: true});
																contractForm.setValue('performerZip', '', { shouldDirty: true});
																contractForm.setValue('performerState', '', { shouldDirty: true});
																contractForm.setValue('performerCity', '', { shouldDirty: true});
																contractForm.setValue('performerPrintName','',{shouldDirty:true});
																contractForm.setValue('loanOutId', null, { shouldDirty: true});
																contractForm.trigger('loanOutId');
															}}
															cacheOptions={false}
															defaultOptions
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
														{!props?.isPrint && (
															<Box sx={{marginBottom:'-27px'}}>
																<AddPersonInline
																	title='Person'
																	onPersonCreated={() => ({})}
																	initialValues={{personType: ['TALENT'], isMinor: false}}
																/>
															</Box>
														)}
													</>
												)}
											/>
											{(contractForm.formState?.errors?.performerId) && (
												<FormHelperText
													data-cy='errorHelper_first_session_date'
													error={true}
												>
													{
														contractForm.formState?.errors
															?.performerId?.value?.message ||
																								contractForm.formState?.errors
																									?.performerId?.message 
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
								</tr>
							</table>
							
							<br></br>

							<p><b>Producer engages Performer and Performer agrees to perform services for Producer in Commercials as follows:</b></p>
							<br></br>
							<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
								<tr>
									<td width={150}>For Agency</td>
									<td><FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='adAgencyId'
											render={({ field }) => (
												<>
													<LookAheadSelect
														data-cy='ad_agency'
														{...field}
														placeholder={!props?.isPrint ? 'Ad-Agency' : ''}
														filterProperty='CompanyNames.Name'
														APICaller={getCompanyBasicDetails}
														optionGenerator={
															companyOptionGenerator
														}
														defaultFilter={AD_AGENCY_FILTER}
														isClearable={true}
														isDisabled={props?.isPrint}
														onChange={(e: any) => {
															field.onChange(e);
															contractForm.setValue('adAgencyAddressId', null, { shouldDirty: true });
														}}
														cacheOptions
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
													{!props?.isPrint && (
														<Box sx={{mt:0.5}}>
															<AddCompanyInline
																title='Company'
																companyType={{ mainType: 'AD AGENCY' as CompanyMainType, subType: [] }} />
														</Box>
													)}
																				
												</>
											)}
										/>
										{contractForm.formState.errors?.adAgencyId
											?.message && (
											<FormHelperText
												error={true}
												data-cy='errorHelper_ad_agency'
											>
												{
													contractForm.formState?.errors
														?.adAgencyId?.message
												}
											</FormHelperText>
										)}
									</FormControl> </td>
									<td width={150}>Engagement Date:</td>
									<td><FormControl fullWidth className={styles.tableForms}>
										<Controller
											control={contractForm.control}
											name='engagementDate'
											render={({ field }) => (
												<NabuDatepicker
													{...field}
													disabled={props?.isPrint}
													placeholder={!props?.isPrint ? 'Date' : ''}
												/>
											)}
										/>
										{/* {contractForm.formState?.errors
																		?.engagementDate?.message && (
																		<FormHelperText
																			data-cy='errorHelper_first_session_date'
																			error={true}
																		>
																			{
																				contractForm.formState?.errors
																					?.engagementDate.message
																			}
																		</FormHelperText>
																	)} */}
									</FormControl> </td>
								</tr>
								<tr>
									<td style={{lineHeight:'10px'}}>
                Agency Address: 
										<span style={{fontSize:'10px', }}>(acting as agent for)</span>
									</td>
									<td>
										<FormControl fullWidth>
											<Controller
												control={contractForm.control}
												key={`agency-address-cr-${contractForm.watch(
													'adAgencyId.value'
												)}`}
												name='adAgencyAddressId'
												render={({ field }) => (
													<>
														<LookAheadSelect
															{...field}
															placeholder={!props?.isPrint ? 'Select Address' : ''}
															key={`agency-address-${contractForm.watch(
																'adAgencyId.value'
															)}`}
															filterProperty='Line1'
															sortField='IsPrimary'
															sortDescending={true}
															onOptionLoaded={(options) => {
																if(options?.length && !contractForm.watch('adAgencyAddressId.value')){
																	contractForm.setValue('adAgencyAddressId', options[0], { shouldDirty: true});
																	contractForm.trigger('adAgencyAddressId', { shouldFocus: true});																						
																}
															}}
															APICaller={(filterModel) =>
																getAllAddressByCompany(
																	filterModel,
																	contractForm.watch('adAgencyId.value') ||
																					''
																)
															}
															optionGenerator={
																adAgencyAddressOptionGenerator
															}
															isDisabled={
																!contractForm.watch('adAgencyId.value') ||
																			props?.isPrint
															}
															dropDownsize={50}
															isClearable={true}
															isSearchText={true}
															onChange={(e: any) => {
																field.onChange(e);
															}}
															cacheOptions={false}
															defaultOptions
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
														{contractForm.watch('adAgencyId.value')&& (!props.isPrint) && <div style={{float:'right'}}>
															<Box sx = {{mt: 0.9}}>
																<AddCompanyAddressInline
																	companyId= {contractForm.watch('adAgencyId.value')}
																	title='ADDRESS'
																	isOpen = {addressPopOver?.isOpen && addressPopOver?.for =='AdAgency-address'}
																	event={addressPopOver?.event}
																	onAddressCreated={(d) => onAdAgencyAddressCreated(d)}
																	onClose={() => setAddressPopOver({isOpen: false})}
																	onPopOverOpen={(e) => setAddressPopOver({
																		isOpen: true,
																		event: e?.currentTarget,
																		for: 'AdAgency-address'
																	})}
																	isPersistent={true} />
															</Box>
														</div>}
													</>
												)}
											/>
											{contractForm.formState.errors?.adAgencyAddressId
												?.value?.message && (
												<FormHelperText
													error={true}
													data-cy='errorHelper_ad_agency'
												>
													{
														contractForm.formState?.errors
															?.adAgencyAddressId.value.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
                Place
									</td>
									<td style={{verticalAlign:'top'}}>
										<FormControl fullWidth>
											<Controller
												control={contractForm.control}
												name='placeOfEngagement'
												render={({ field }) => (
													<LocationFreeTextSelect
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Place of Engagement' : ''}
													/>
												)}
											/>
											{/* {contractForm.formState.errors?.placeOfEngagement
																			?.message && (
																			<FormHelperText error={true}>
																				{
																					contractForm.formState?.errors
																						?.placeOfEngagement.message
																				}
																			</FormHelperText>
																		)} */}
										</FormControl>
									</td>
								</tr>
								<tr>
									<td>
                Advertiser
									</td>
									<td>
										<FormControl fullWidth>
											<Controller
												control={contractForm.control}
												name='advertiserId'
												render={({ field }) => (
													<>
														<LookAheadSelect
															{...field}
															placeholder={!props?.isPrint ? 'Advertiser' : ''}
															filterProperty='CompanyNames.Name'
															APICaller={getCompanyBasicDetails}
															optionGenerator={companyOptionGenerator}
															defaultFilter={ADVERTISER_FILTER}
															isDisabled={props?.isPrint}
															isClearable={true}
															onChange={(e: any) => {
																field.onChange(e);
																contractForm.setValue('commercials', [{} as any], { shouldDirty: true});
																contractForm.setValue('productId', null, { shouldDirty: true});
															}}
															cacheOptions={false}
															defaultOptions
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
														{!props?.isPrint && (
															<Box sx={{mt:0.5}}>
																<AddCompanyInline
																	title='Company'
																	companyType={{ mainType: 'ADVERTISER' as CompanyMainType, subType: [] }} />
															</Box>
														)}
													</>
												)}
											/>
											{contractForm.formState.errors?.advertiserId?.value
												?.message && (
												<FormHelperText
													error={true}
													data-cy='errorHelper_ad_agency'
												>
													{
														contractForm.formState?.errors?.advertiserId
															.value.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td colSpan={2}>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<div>
                     Zip
												<FormControl fullWidth>
													<Controller
														control={contractForm.control}
														name='serviceZip'
														defaultValue=''
														render = {({field}) => (
															<NabuTextbox
																{...field}
																disabled={props?.isPrint}
																placeholder={!props?.isPrint ? 'Zip Code' : ''}
																onChange={(e) => {
																	field.onChange(e);
																	onZipCodeChange(
																		contractForm.watch('serviceZip') || '',
																		'service'
																	);
																}}
															/>	
														)}
													/>
												</FormControl>
											</div>
											<div>
                     City
												<FormControl fullWidth>
													<Controller
														control={contractForm.control}
														name='serviceCity'
														defaultValue=''
														render={({ field }) => (
															<NabuSelect
																{...field}
																disabled={props?.isPrint}
																options={getCityOptions(
																	contractForm.watch('serviceState')
																)}
																convertValueToUpperCase={true}
																placeholder={!props?.isPrint ? 'Select City' : ''}
															/>
														)}
													/>
													{contractForm.formState?.errors?.serviceCity
														?.message && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.serviceCity
																	.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</div>
										
										</div>
										<div>
                     State
											<FormControl fullWidth>
												<Controller
													control={contractForm.control}
													name='serviceState'
													defaultValue=''
													render={({ field }) => (
														<NabuSelect
															{...field}
															disabled={props?.isPrint}
															options={getStateOptions()}
															convertValueToUpperCase={false}
															placeholder={!props?.isPrint ? 'Select State' : ''}
														/>
													)}
												/>
												{contractForm.formState?.errors?.serviceState
													?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors?.serviceState
																.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</div>
									</td>
								</tr>
								<tr>
									<td>
                Product
									</td>
									<td>
										<FormControl fullWidth>
											<Controller
												control={contractForm.control}
												name='productId'
												render={({ field }) => (
													<LookAheadSelect
														{...field}
														isMulti
														placeholder={!props?.isPrint ? 'Select Product' : ''}
														key={`product-${contractForm.watch(
															'advertiserId.value'
														)}`}
														filterProperty='ProductName'
														APICaller={(filterModel) =>
															getAllProducts(
																filterModel,
																contractForm.watch('advertiserId.value')
															)
														}
														optionGenerator={productOptionGenerator}
														isDisabled={
															!contractForm.watch('advertiserId.value') ||
																		props?.isPrint
														}
														defaultFilter={PRODUCT_FILTER}
														dropDownsize={20}
														isClearable={true}
														onChange={(e: any) => {
															field.onChange(e);
														}}
														cacheOptions={false}
														defaultOptions
														className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
													/>
												)}
											/>
										</FormControl>
									</td>
									<td>
                Time From: 
									</td>
									<td>
										<FormControl fullWidth>
											<Controller
												control={contractForm.control}
												name='timeFrom'
												render={({ field }) => (
													<NabuTimePicker
														{...field}
														disabled={props?.isPrint}
													/>
												)}
											/>
											{contractForm.formState?.errors
												?.timeFrom?.message && (
												<FormHelperText
													data-cy='errorHelper_first_session_date'
													error={true}
												>
													{
														contractForm.formState?.errors
															?.timeFrom.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
								</tr>
								<tr>
									<td>
                Compensation{ !props?.isPrint && (
											<Tooltip
												placement='right-end'
												title={'You can manually Enter the required option'}
											>
												<HelpOutline fontSize='inherit' />
											</Tooltip>)
										}
									</td>
									<td>
										<FormControl fullWidth>
											<Controller
												control={contractForm.control}
												name='compensation'
												defaultValue='SCALE'
												render={({ field }) => (
													<NabuDynamicInputDropdown
														{...field}
														options={COMPENSATION_OPTIONS}
														placeholder={!props?.isPrint ? 'Compensation' : ''}
														disabled={props?.isPrint}
													/>
												)}
											/>
											{contractForm.formState.errors?.compensation
												?.message && (
												<FormHelperText
													data-cy='errorHelper-job-title-name'
													error={true}
												>
													{
														contractForm.formState?.errors?.compensation
															.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
                  Time To
									</td>
									<td>
										<FormControl fullWidth>
											<Controller
												control={contractForm.control}
												name='termTo'
												render={({ field }) => (
													<NabuDatepicker
														{...field}
														disabled={props?.isPrint}
														placeholder={!props?.isPrint ? 'Date' : ''}
													/>
												)}
											/>
											{contractForm.formState?.errors
												?.termTo?.message && (
												<FormHelperText
													data-cy='errorHelper_first_session_date'
													error={true}
												>
													{
														contractForm.formState?.errors
															?.termTo.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
								</tr>
							</table>
							<br></br>
							<p><b>Employer of Record for income tax and unemployment insurance:</b> : Talent Entertainment and Media Services, LLC dba <b>The Team Companies, LLC, 2300 Empire Ave., 5th Floor, Burbank, CA 91504</b></p>

							<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
								<tr style={{ background: '#808080' }}>
									<th style={{textAlign:'left'}}>
                Ad-ID (s) 
									</th>
									<th style={{textAlign:'left'}}>
                Title (s)
									</th>
									{!props?.isPrint && (
										<th>
                  
										</th>
									)}
								</tr>
								{commercialFieldArray.fields.map((field, index) => (
									<tr key={`commercial-${field.id}`}>
										<td>
											<FormControl
												fullWidth
												className={styles.tableForms}
											>
												<Controller
													control={contractForm.control}
													name={`commercials.${index}.assetId`}
													render={({ field }) => (
														<>
															<LookAheadSelect
																data-cy='source-asset'
																{...field}
																placeholder={!props?.isPrint ? 'Ad-ID' : ''}
																filterProperty='Title'
																isDisabled={props?.isPrint || !contractForm.watch('advertiserId.value')}
																defaultFilter={getCommercialFilters()}
																APICaller={(filterModel) => getAllAssetForBasicDetail( filterModel, false)}
																optionGenerator={
																	lookAheadAssetOptionGenerator
																}
																onChange={(e: any) => { handleCommercialChange(e?.value); handleProducerChange(index);field.onChange(e);}}
																isClearable={true}
																cacheOptions
																defaultOptions
																dropDownsize={25}
																className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
															/>
															{!props?.isPrint && (
																<Box sx={{mt: 1}}>
																	<AddAssetInline
																		title='Asset' />
																</Box>
															)}
														</>
													)}
												/>
												{contractForm.formState?.errors?.commercials
													?.length && (
													<FormHelperText error={true}>
														{
															contractForm.formState.errors?.commercials[
																index
															]?.assetId?.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
										<td>
											<FormControl
												fullWidth
												className={styles.tableForms}
											>
												<NabuTextbox
													value={getAssetAdIdByAssetIndex(index)}
												/>
											</FormControl>
										</td>
										{!props?.isPrint && (
											<>
												<td style={{ display: 'flex' }}>
													{commercialFieldArray.fields.length - 1 ===
																	index && (
														<NabuButton
															variant='text'
															onClick={onAddCommercial}
														>
															<Add />
														</NabuButton>
													)}
													{commercialFieldArray?.fields.length > 1 && (
														<NabuButton
															variant='text'
															onClick={() =>{ onRemoveCommercial(index);handleProducerChange(index);}}
														>
															<DeleteForever color='error' />
														</NabuButton>
													)}
												</td>
											</>
										)}
									</tr>
								))}
								<tr>
									<td colSpan={props?.isPrint ? 2 : 3}>
								No. of Commercials
										<FormControl fullWidth className={styles.tableForms}>
											<NabuTextbox
												disabled={true}
												key={contractForm
													.watch('commercials')
													?.map((d) => d?.assetId)
													?.join('-')}
												value={contractForm
													?.watch('commercials')
													?.reduce(
														(prev, cur) => prev + (cur?.assetId ? 1 : 0),
														0
													)}
											/>
										</FormControl>
									</td>
								</tr>
							</table>
							<br></br>
							<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
								<tr style={{ background: '#808080' }}>
									<th style={{textAlign:'left'}}>
                Category 
									</th>
									<th style={{textAlign:'left'}}>
                Type
									</th>
									<th>
								Adjustments
									</th>
								</tr>
								<tr>
									<td style={{verticalAlign:'top'}}>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='performerType'
												defaultValue=''
												render={({ field }) => (
													<div
														onClick={() => 
															(!props?.isPrint) &&
																							field.onChange(!field.value)
														}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value='COMMERCIAL_EXTRA_PERFORMER'
																checked={field.value === 'COMMERCIAL_EXTRA_PERFORMER'}
																onChange={(e) => {field.onChange(e.target.value);
																	contractForm.setValue('otherCategory','',{ shouldDirty: true});
																}}
																type='radio'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Commercial Extra Performer</span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='performerType'
												defaultValue=''
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value='HAND_MODEL'
																checked={field.value === 'HAND_MODEL'}
																onChange={(e) => {field.onChange(e.target.value);
																	contractForm.setValue('otherCategory','',{ shouldDirty: true});
																}}
																type='radio'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Hand Model</span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='performerType'
												defaultValue=''
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value='STAND_IN'
																checked={field.value === 'STAND_IN'}
																onChange={(e) => {field.onChange(e.target.value);
																	contractForm.setValue('otherCategory','',{ shouldDirty: true});
																}}
																type='radio'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Stand-In</span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='performerType'
												defaultValue=''
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value='PHOTO_DOUBLE'
																checked={field.value === 'PHOTO_DOUBLE'}
																onChange={(e) => {field.onChange(e.target.value);
																	contractForm.setValue('otherCategory','',{ shouldDirty: true});
																}}
																type='radio'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Photo Double</span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='performerType'
												defaultValue=''
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value='OTHER'
																checked={field.value === 'OTHER'}
																onChange={(e) => {
																	field.onChange(e.target.value);
																}}
																type='radio'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Other:</span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<FormControl fullWidth className={styles.tableForms}>
											{
												props?.isPrint ? (
													<p className={styles.descriptionPrintText} style={{padding:'0px'}}>{contractForm.watch('otherCategory')}</p>
												) : (<>
													<Controller
														control={contractForm.control}
														name='otherCategory'
														render={({ field }) => (
															<textarea
																{...field}
																disabled={props?.isPrint || contractForm.watch('performerType') !== 'OTHER'}
																placeholder={!props?.isPrint ? 'Other Category' : ''}
																onChange={(e) => {
																	field.onChange(e.target.value);
																}}
																style={{width:'100%'}}
															/>
														)}
													/>
													{contractForm.formState.errors?.otherCategory
														?.message && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors
																	?.otherCategory.message
															}
														</FormHelperText>
													)}
												</>)
											}
										</FormControl>
									</td>
									<td style={{verticalAlign:'top'}}>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isUnlimitedUse'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> Unlimited Use</span>
														</Box>
													</div>
												)}
											/>
																		
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='is13WeeksUse'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>13 Weeks Use</span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isTraditionalDigitalCommercial'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> Stand-In Traditional Digital Commercial(s) (no Streaming/Linear)</span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isPhotoDouble'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> Photo Double </span>
														</Box>
													</div>
												)}
											/>
																		
										</Box>
									</td>
									<td>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isWetSnowSmokeOrDust'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Wet,Snow, Smoke or Dust ($53.25)</span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<Box display={'flex'} alignItems={'flex-start'}>
												<Controller
													control={contractForm.control}
													name='isHazardAdjustment'
													defaultValue={false}
													render={({ field }) => (
														<div
															onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value={field.value + ''}
																	checked={field.value}
																	onChange={(e) => field.onChange(e)}
																	type='checkbox'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																<span>Hazard Adjustment</span>{' '}
															</Box>
														</div>
													)}
												/>
											</Box>
											<span>$ </span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:150}}>
												<Controller
													control={contractForm.control}
													name='adjustmentAmount'
													render={({ field }) => (
														<input
															{...field}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'Hazard Adjustment' : ''}
															onChange={(e: any) => {
																const value = e?.target?.value || '';
																if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																	field.onChange(value);
																} else {
																	const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																	field.onChange(num);
																}
															}}
															onBlur={() => {
																const numericValue = parseFloat((field.value ?? '0').toString());
																if (!isNaN(numericValue)) {
																	field.onChange(numericValue.toFixed(2));
																} else {
																	field.onChange('0.00');
																}
															}}
														/>
													)}
												/>
												{contractForm.formState.errors?.adjustmentAmount
													?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors
																?.adjustmentAmount.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</div>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isMakeUpSkullCapHairGoodsHairCut'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>
																					Make-up, Skull Cap, Hairgoods
																					($41.85)
															</span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<Box display={'flex'} alignItems={'flex-start'}>
												<Controller
													control={contractForm.control}
													name='isNightPremium'
													defaultValue={false}
													render={({ field }) => (
														<div
															onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value={field.value + ''}
																	checked={field.value}
																	onChange={(e) => field.onChange(e)}
																	type='checkbox'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																<span>Night Premium</span>
															</Box>
														</div>
													)}
												/>
											</Box>
											<span> $</span>
											<FormControl className={styles.tableForms} style={{maxWidth:150}}>
												{/* <NabuTextbox placeholder="Night Premium"/> */}
												<Controller
													control={contractForm.control}
													name='nightPremiumValue'
													render={({ field }) => (
														<input
															{...field}
															type='number'
															onChange={(e) => {
																const value = e.target.value || '';
																if (/^\d*$/.test(value)) {
																	field.onChange(value);
																}
															}}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'Night Premium' : ''}
														/>
													)}
												/>
											</FormControl>
										</div>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isOtherAdjustment'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => {field.onChange(e);
																	contractForm.setValue('otherAdjustment','',{shouldDirty:true});
																}}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Other</span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='otherAdjustment'
												render={({ field }) => (
													<textarea 
														{...field}
														disabled={props?.isPrint || !contractForm.watch('isOtherAdjustment')}
														placeholder={!props?.isPrint ? 'Other Adjustment' : ''}
														style={{width:'100%'}}
													/>
												)}
											/>
										</FormControl>
									</td>
								</tr>
							</table>
							<br></br>
							<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
								<tr style={{ background: '#808080' }}>
									<th style={{textAlign:'center'}}>
                Allowances
									</th>
									<th style={{textAlign:'center'}} colSpan={3}>
                Props
									</th>
								</tr>
								<tr>
									<td style={{verticalAlign:'top', width:'20%'}}>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isTravelWithinStudioZone'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> Travel within Studio Zone ($8.00) Payable </span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isFlightInsurancePayable'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> Flight Insurance ($13.39) Payable </span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<hr style={{margin:'2px 0 '}}></hr>
										<p><b>Wardrobe to be furnished by:</b></p>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='wardrobeFurnishedBy'
												defaultValue='none'
												render={({ field }) => (
													<div>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value='producer'
																checked={field.value === 'producer'}
																onChange={(e) => field.onChange(e.target.value)}
																type='radio'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Producer</span>&nbsp;
														</Box>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value='performer'
																checked={field.value === 'performer'}
																onChange={(e) => field.onChange(e.target.value)}
																type='radio'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Performer</span>
														</Box>
													</div>
												)}
											/>
										</Box>

										<p style={{marginTop:'10px'}}>If furnished by Extra Performer,
									# of Costumes requested by Producer:</p>
										<p style={{marginTop:'10px'}}>Non-Evening Wear (${NON_EVENING_WEAR_COST}) x</p>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											{/* <span>@ $20.36 x</span> */}
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:75}}>
												<Controller
													control={contractForm.control}
													name='nonEveningWearCount'
													render={({ field }) => (
														<input
															{...field}
															type='number'
															onChange={(e) => {
																const value = e.target.value || '';
												
																if (/^\d*$/.test(value)) {
																	field.onChange(value);
																}
																calculateTotalWardrobeFee();
															}}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'Non-Evening Wear Count' : ''}
														/>
													)}
												/>
												{/* {contractForm.formState.errors?.nonEveningWearCount
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.nonEveningWearCount.message
																					}
																				</FormHelperText>
																			)} */}
											</FormControl> 
											<span>(#)</span>
										</div>
										<p>Evening Wear (${EVENING_WEAR_COST})x</p>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											{/* <span>@ $33.91 x</span> */}
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:75}}>
												<Controller
													control={contractForm.control}
													name='eveningWearCount'
													render={({ field }) => (
														<input
															{...field}
															onChange={(e) => {
																const value = e.target.value || '';
												
																if (/^\d*$/.test(value)) {
																	field.onChange(value);
																}
																calculateTotalWardrobeFee();
															}}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'Evening Wear Count' : ''}
														/>
													)}
												/>
												{/* {contractForm.formState.errors?.eveningWearCount
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.eveningWearCount.message
																					}
																				</FormHelperText>
																			)} */}
											</FormControl> 
											<span>(#)</span>
										</div>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<span><b>Total wardrobe fee: $</b></span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:75}}>
												<Controller
													control={contractForm.control}
													name='totalWardrobeFee'
													render={({ field }) => (
														<input
															{...field}
															disabled={true}
															placeholder={!props?.isPrint ? 'Total Wardrobe Fee' : ''}
															onChange={(e) => {
																if (
																	new RegExp(/^\d*\.?\d*$/).test(
																		e?.target?.value || ''
																	) &&
																			Number(e?.target?.value) >= 0
																) {
																	field.onChange(e);
																} else {
																	const value: string =
																				e?.target?.value ?? '';
																	const num = value
																		?.match(/^\d*\.?\d*/)
																		?.at(0);
																	if (!isNaN(Number(num || ''))) {
																		field.onChange(Number(num || ''));
																	} else {
																		field.onChange(0);
																	}
																}
															}}
														/>
													)}
												/>
												{/* {contractForm.formState.errors?.totalWardrobeFee
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.totalWardrobeFee.message
																					}
																				</FormHelperText>
																			)} */}
											</FormControl> 
											<span>(#)</span>
										</div>

									</td>
									<td style={{verticalAlign:'top', width:'20%'}}>
										<p><b>Vehicle & Type</b></p>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isMoped'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Moped ($21.38)</span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<span>Type:</span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:100}}>
												<Controller
													control={contractForm.control}
													name='vehicleType'
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={props?.isPrint || !contractForm.watch('isMoped')}
															placeholder={!props?.isPrint ? 'Vehicle Type' : ''}
														/>
													)}
												/>
												{/* {contractForm.formState.errors?.vehicleType
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.vehicleType.message
																					}
																				</FormHelperText>
																			)} */}
											</FormControl> 
										</div>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<span>Tolls $:</span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:100}}>
												<Controller
													control={contractForm.control}
													name='vehicleTolls'
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={props?.isPrint || !contractForm.watch('isMoped')}
															placeholder={!props?.isPrint ? 'Vehicle Tolls' : ''}
															onChange={(e: any) => {
																const value = e?.target?.value || '';
																if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																	field.onChange(value);
																} else {
																	const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																	field.onChange(num);
																}
															}}
															onBlur={() => {
																const numericValue = parseFloat((field.value ?? '0').toString());
																if (!isNaN(numericValue)) {
																	field.onChange(numericValue.toFixed(2));
																} else {
																	field.onChange('0.00');
																}
															}}
														/>
													)}
												/>
												{/* {contractForm.formState.errors?.vehicleTolls
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.vehicleTolls.message
																					}
																				</FormHelperText>
																			)} */}
											</FormControl> 
										</div>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<span>Mileage $:</span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:100}}>
												<Controller
													control={contractForm.control}
													name='vehicleMileage'
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={props?.isPrint || !contractForm.watch('isMoped')}
															placeholder={!props?.isPrint ? 'Vehicle Mileage' : ''}
															onChange={(e: any) => {
																const value = e?.target?.value || '';
																if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																	field.onChange(value);
																} else {
																	const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																	field.onChange(num);
																}
															}}
															onBlur={() => {
																const numericValue = parseFloat((field.value ?? '0').toString());
																if (!isNaN(numericValue)) {
																	field.onChange(numericValue.toFixed(2));
																} else {
																	field.onChange('0.00');
																}
															}}
														/>
													)}
												/>
												{/* {contractForm.formState.errors?.vehicleMileage
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.vehicleMileage.message
																					}
																				</FormHelperText>
																			)} */}
											</FormControl> 
										</div>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<span>Parking $:</span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:100}}>
												<Controller
													control={contractForm.control}
													name='vehicleParking'
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={props?.isPrint || !contractForm.watch('isMoped')}
															placeholder={!props?.isPrint ? 'Vehicle Parking' : ''}
															onChange={(e: any) => {
																const value = e?.target?.value || '';
																if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																	field.onChange(value);
																} else {
																	const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																	field.onChange(num);
																}
															}}
															onBlur={() => {
																const numericValue = parseFloat((field.value ?? '0').toString());
																if (!isNaN(numericValue)) {
																	field.onChange(numericValue.toFixed(2));
																} else {
																	field.onChange('0.00');
																}
															}}
														/>
													)}
												/>
												{/* {contractForm.formState.errors?.vehicleParking
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.vehicleParking.message
																					}
																				</FormHelperText>
																			)} */}
											</FormControl> 
										</div>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isAutomobile'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> <b>Automobile ($42.71)</b> </span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<p><em>incl: trailer/motorcycle</em></p>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<span>Type:</span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:100}}>
												<Controller
													control={contractForm.control}
													name='autoMobileType'
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={props?.isPrint || !contractForm.watch('isAutomobile')}
															placeholder={!props?.isPrint ? 'Vehicle Type' : ''}
														/>
													)}
												/>
												{/* {contractForm.formState.errors?.autoMobileType
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.autoMobileType.message
																					}
																				</FormHelperText>
																			)} */}
											</FormControl> 
										</div>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<span>Tolls $:</span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:100}}>
												<Controller
													control={contractForm.control}
													name='autoMobileTolls'
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={props?.isPrint || !contractForm.watch('isAutomobile')}
															placeholder={!props?.isPrint ? 'Vehicle Tolls' : ''}
															onChange={(e: any) => {
																const value = e?.target?.value || '';
																if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																	field.onChange(value);
																} else {
																	const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																	field.onChange(num);
																}
															}}
															onBlur={() => {
																const numericValue = parseFloat((field.value ?? '0').toString());
																if (!isNaN(numericValue)) {
																	field.onChange(numericValue.toFixed(2));
																} else {
																	field.onChange('0.00');
																}
															}}
														/>
													)}
												/>
												{/* {contractForm.formState.errors?.vehicleTolls
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.vehicleTolls.message
																					}
																				</FormHelperText>
																			)} */}
											</FormControl> 
										</div>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<span>Mileage $:</span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:100}}>
												<Controller
													control={contractForm.control}
													name='autoMobileMileage'
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={props?.isPrint || !contractForm.watch('isAutomobile')}
															placeholder={!props?.isPrint ? 'Vehicle Mileage' : ''}
															onChange={(e: any) => {
																const value = e?.target?.value || '';
																if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																	field.onChange(value);
																} else {
																	const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																	field.onChange(num);
																}
															}}
															onBlur={() => {
																const numericValue = parseFloat((field.value ?? '0').toString());
																if (!isNaN(numericValue)) {
																	field.onChange(numericValue.toFixed(2));
																} else {
																	field.onChange('0.00');
																}
															}}
														/>
													)}
												/>
												{/* {contractForm.formState.errors?.autoMobileMileage
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.autoMobileMileage.message
																					}
																				</FormHelperText>
																			)} */}
											</FormControl> 
										</div>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<span>Parking $:</span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:100}}>
												<Controller
													control={contractForm.control}
													name='autoMobileParking'
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={props?.isPrint || !contractForm.watch('isAutomobile')}
															placeholder={!props?.isPrint ? 'Vehicle Parking' : ''}
															onChange={(e: any) => {
																const value = e?.target?.value || '';
																if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																	field.onChange(value);
																} else {
																	const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																	field.onChange(num);
																}
															}}
															onBlur={() => {
																const numericValue = parseFloat((field.value ?? '0').toString());
																if (!isNaN(numericValue)) {
																	field.onChange(numericValue.toFixed(2));
																} else {
																	field.onChange('0.00');
																}
															}}
														/>
													)}
												/>
												{/* {contractForm.formState.errors?.autoMobileParking
																				?.message && (
																				<FormHelperText error={true}>
																					{
																						contractForm.formState?.errors
																							?.autoMobileParking.message
																					}
																				</FormHelperText>
																			)} */}
											</FormControl> 
										</div>
									</td>
									<td style={{verticalAlign:'top', width:'30%'}}>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isBicycle'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Bicycle ($14.29)</span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isBinocularOperaGlasses'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Binoculars, Opera Glasses ($6.52)</span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isBooks'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Books ($2.95 each)</span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isCameraLargePortableRadio'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>
																					Camera ($6.52)
															</span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isMobileLaptop'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> Handheld Mobile/PDA ($6.52) </span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isGolfClubs'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Golf Clubs and Bag ($14.24)</span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isIpodOrMp3'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> iPod or other MP3 Player($6.52) </span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isLaptopOrSimilar'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> Laptop or Similar Device($6.52) </span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isLuggage'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span> Luggage ($6.52 ea. piece) </span>
														</Box>
													</div>
												)}
											/>
											<p><em>includes book bags & briefcases</em></p>
										</Box>
																	
									</td>
									<td style={{verticalAlign:'top', width:'30%'}}>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isPet'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Pet ($27.22)</span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isRadioLargePortableRadio'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Radio - Large portable ($6.52) </span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isSkatesAndSkateboard'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Skates and Skateboard ($10.72)</span>
														</Box>
													</div>
												)}
											/>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isSkis'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Skis ($14.24) </span>
														</Box>
													</div>
												)}
											/>
											<p><em>includes poles & boots</em></p>
										</Box>
										<Box display={'flex'} alignItems={'flex-start'}>
											<Controller
												control={contractForm.control}
												name='isTennisRacket'
												defaultValue={false}
												render={({ field }) => (
													<div
														onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
														style={{ userSelect: 'none' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																value={field.value + ''}
																checked={field.value}
																onChange={(e) => field.onChange(e)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>Tennis Racquet ($6.52)</span>
														</Box>
													</div>
												)}
											/>
											<p><em>if not already paid as part of wardrobe</em></p>
										</Box>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<Box display={'flex'} alignItems={'flex-start'}>
												<Controller
													control={contractForm.control}
													name='isOtherAllowance'
													defaultValue={false}
													render={({ field }) => (
														<div
															onClick={() => (!props?.isPrint) && field.onChange(!field.value)}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value={field.value + ''}
																	checked={field.value}
																	onChange={(e) => {
																		field.onChange(e);
																		contractForm.setValue('otherAllowanceValue','',{shouldDirty:true});
																	}}
																	type='checkbox'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																{/* <span>Other:</span> */}
																<span> Other</span>
															</Box>
															
														</div>
													)}
												/>
											</Box>
											
											<FormControl fullWidth className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='otherAllowanceValue'
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={props?.isPrint || !contractForm.watch('isOtherAllowance')}
															placeholder={!props?.isPrint ? 'Other Allowance' : ''}
														/>
													)}
												/>
											</FormControl> 
										</div>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
									
											<span><span> Fee $ </span></span>
											<FormControl fullWidth className={styles.tableForms} style={{maxWidth:200}}>
												<Controller
													control={contractForm.control}
													name='allowanceFees'
													render={({ field }) => (
														<NabuTextbox
															{...field}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'Allowance Fee' : ''}
															type = 'number'
															onChange={(e: any) => {
																const value = e?.target?.value || '';
																if (/^\d*\.?\d{0,2}$/.test(value) && Number(value) >= 0) {
																	field.onChange(value);
																} else {
																	const num = value.match(/^\d*\.?\d{0,2}/)?.[0] || '0';
																	field.onChange(num);
																}
															}}
															onBlur={() => {
																const numericValue = parseFloat((field.value ?? '0').toString());
																if (!isNaN(numericValue)) {
																	field.onChange(numericValue.toFixed(2));
																} else {
																	field.onChange('0.00');
																}
															}}
														/>
													)}
												/>
												{contractForm.formState.errors?.allowanceFees
													?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors
																?.allowanceFees.message
														}
													</FormHelperText>
												)}
											</FormControl> 
										</div>

									</td>
								</tr>
							</table>
					
							<br></br>
							<div className='keep-together'>
								<p><b>Extra Performer authorizes Producer to make payment to Extra Performer as follows:</b></p>
								<table
									width={'100%'}
									border={1}
									cellPadding={0}
									cellSpacing={0}
									className={`${styles.contractFormsInner} keep-together`}
								>
									<tr style={{ background: '#808080' }}>
										<th colSpan={3} style={{ textAlign: 'left' }}>
															Extra Performer authorizes Producer to make all
															payments to Extra Performer as follows:
										</th>
									</tr>
									<tr>
										<td style={{ paddingTop: '9px' }} colSpan={3}>
											<div
												style={{
													display: 'inline-flex',
													alignItems: 'center',
												}}
											>
												<Controller
													control={contractForm.control}
													name='isPaymentAtExtraPerformerAddress'
													// defaultValue={true}
													render={({ field }) => (
														<div
															onClick={() => {
																(!props?.isPrint) && field.onChange(!field.value);
																contractForm.setValue('isExtraPerformer', !contractForm.watch('isPaymentAtExtraPerformerAddress'));
															}}
															style={{ userSelect: 'none' }}
														>
															<Box display={'flex'} alignItems={'flex-start'}>
																<input
																	{...field}
																	value={field.value + ''}
																	checked={field.value}
																	onChange={(e) => {
																		field.onChange(e);
																		contractForm.setValue('isExtraPerformer', !contractForm.watch('isPaymentAtExtraPerformerAddress'));
																	}}
																	type='checkbox'
																	disabled={props?.isPrint}
																	style={{ marginTop: '3px' }}
																/>
																<span>
																	<b>To Extra Performer at (Address):</b>
																</span>
															</Box>
														</div>
													)}
												/>
											</div>
										</td>
									</tr>
									<tr>
										<td colSpan={3}>
									(Performer&apos;s Address):
											{contractForm.watch('isPaymentAtExtraPerformerAddress') && <sup></sup>}
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<Controller
													control={contractForm.control}
													key={`select_Address_${contractForm.watch(
														'performerId.value'
													)}`}
													name='performerAddressId'
													render={({ field }) => (
														<>
															<LookAheadSelect
																{...field}
																placeholder={!props?.isPrint ? 'Select Address' : ''}
																key={`performer-address-${contractForm.watch(
																	'performerId.value'
																)}`}
																filterProperty='Line1'
																sortField='IsPrimary'
																sortDescending={true}
																onOptionLoaded={(options) => {
																	if(options?.length && !contractForm.watch('performerAddressId.value')){
																		contractForm.setValue('performerAddressId', options[0], { shouldDirty: true});									
																		contractForm.trigger('performerAddressId', { shouldFocus: true});	
																		onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');																					
																	}
																}}
																APICaller={(filterModel) =>
																	getAllAddressByPerson(
																		filterModel,
																		contractForm.watch(
																			'performerId.value'
																		) || ''
																	)
																}
																optionGenerator={
																	addressOptionGenerator
																}
																dropDownsize={50}
																isDisabled={props?.isPrint || !contractForm.watch('performerId.value')}
																isClearable={true}
																isSearchText={true}
																onChange={(e: any) => {
																	field.onChange(e);
																	onAddressChange(contractForm.watch('performerAddressId.value') || '', 'performer');
																}}
																cacheOptions={false}
																defaultOptions
																className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
															/>
															{
																<Box sx={{mt:1.3}}>
																	{(contractForm.watch('performerId.value')&& (!props.isPrint)) &&
																<div style={{marginBottom:'-14px', float:'right'}}>
																	<AddCompanyAddressInline
																		companyId={contractForm.watch('performerId.value')}
																		title = 'ADDRESS'
																		isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'Performer-address'|| false}
																		event={addressPopOver?.event}
																		onAddressCreated={(d) => PerformerAddressCreated(d)}
																		onClose={() => setAddressPopOver({ isOpen: false})}
																		onPopOverOpen={(e) => setAddressPopOver({
																			isOpen: true,
																			event: e?.currentTarget,
																			for: 'Performer-address'
																		})}
																		isPersistent = {true} />
																</div>
																	}
																
																</Box>

															}
														</>
													)}
												/>
												{contractForm.formState?.errors?.performerAddressId
													?.value?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState.errors
																?.performerAddressId?.value?.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
									</tr>
									<tr>
										<td>
									(ZIP) :
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<Controller
													control={contractForm.control}
													key={`performer-zip${contractForm.watch('performerAddressId')}`}
													name='performerZip'
													defaultValue=''
													render={({ field }) => (
														<NabuTextbox
															{...field}
															key={`performer-zip${contractForm.watch('performerAddressId')}`}
															placeholder={!props?.isPrint ? 'ZIP' : ''}
															disabled={props?.isPrint}
															onChange={(e) => {
																field.onChange(e);
																onZipCodeChange(
																	contractForm.watch('performerZip') || '',
																	'performer'
																);
															}}
														/>
													)}
												/>
												{contractForm.formState.errors?.performerZip
													?.message && (
													<FormHelperText
														data-cy='errorHelper-performerZip'
														error={true}
													>
														{
															contractForm.formState?.errors?.performerZip
																.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
										<td>
									(State) :
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<Controller
													control={contractForm.control}
													name='performerState'
													defaultValue=''
													render={({ field }) => (
														<>
															<NabuSelect
																key={`select-performer-state-${contractForm.watch('performerState')}`}
																{...field}
																disabled={props?.isPrint}
																options={getStateOptions()}
																convertValueToUpperCase={false}
																placeholder={!props?.isPrint ? 'Select State' : ''}
															/>
														</>
													)}
												/>
												{contractForm.formState?.errors?.performerState
													?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors?.performerState
																.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
										<td>
									(City):
											<FormControl
												className={styles.tableForms}
												fullWidth
											>
												<Controller
													control={contractForm.control}
													name='performerCity'
													defaultValue=''
													render={({ field }) => (
														<NabuSelect
															{...field}
															disabled={props?.isPrint}
															options={getCityOptions(
																contractForm.watch('performerState')
															)}
															convertValueToUpperCase={true}
															placeholder={!props?.isPrint ? 'Select City' : ''}
														/>
													)}
												/>
												{contractForm.formState?.errors?.performerCity
													?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors?.performerCity
																.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
									</tr>
									<tr> 
										<td>
									(Performer&apos;s Email Address)
											<FormControl
												className={styles.emaillowerCase}
												fullWidth
											>
												<Controller
													control={contractForm.control}
													key={`select-phone-ctr${contractForm.watch(
														'performerPhoneId'
													)}-${contractForm.watch(
														'performerId.value'
													)}`}
													name='performerEmailId'
													defaultValue=''
													render={({ field }) => (
														<>
															<NabuSelect
																{...field}
																key={`select-phone-${contractForm.watch(
																	'performerPhoneId'
																)}-${contractForm.watch(
																	'performerId.value'
																)}`}			
																defaultCase={true}			
																placeholder={!props?.isPrint ? 'Select Email' : ''}
																convertValueToUpperCase={false}
																enableEmptySelection={true}
																disabled={props?.isPrint || !contractForm.watch('performerId.value')}
																options={getPerformerEmailsOptions()}
																handleDefaultSelection={() => {
																	return !contractForm.watch('performerEmailId');
																}}
															/>
															{
																<Box>
																	{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																<div style={{marginBottom:'-10px', float:'right', marginTop:'-4px'}}>
																	<AddEmailInline
																		formData={{ ...new EmailFormModel()}}
																		isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Performer-email-address'|| false}	
																		onSubmit={onPerformerEmailInlineSubmit}
																		onClose={closeEmailInlinePopOver}
																		title='Email address'
																		onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Performer-email-address')}
																		event={emailPopOver?.event}
																	/>
																</div>
																	}
																</Box>	
															}
														</>
													)}
												/>
												{contractForm.formState?.errors
													?.performerPhoneId?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors
																?.performerPhoneId?.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
										<td>
									(Performer&apos;s Phone)
											<FormControl
												className={styles.tableForms}
												fullWidth 
											>
												<Controller
													control={contractForm.control}
													key={`select-phone-ctr${contractForm.watch(
														'performerPhoneId'
													)}-${contractForm.watch(
														'performerId.value'
													)}`}
													name='performerPhoneId'
													defaultValue=''
													render={({ field }) => (
														<>
															<NabuSelect
																{...field}
																key={`select-phone-${contractForm.watch(
																	'performerPhoneId'
																)}-${contractForm.watch(
																	'performerId.value'
																)}`}
																placeholder={!props?.isPrint ? 'Select Phone' : ''}
																convertValueToUpperCase={false}
																enableEmptySelection={true}
																disabled={props?.isPrint || !contractForm.watch('performerId.value')}
																options={getPerformerPhoneNumberOptions()}
																handleDefaultSelection={() => {
																	return !contractForm.watch('performerPhoneId');
																}}
															/>
															{
																<Box>
																	{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																<div style={{marginBottom:'-10px', float:'right', marginTop:'-4px'}}>
																	<AddPhoneNumberInline
																		formData={{ ...new PhoneNumberFormModel()}}
																		onSubmit={onPerformerPhoneNumberInlineSubmit}
																		isOpen={phoneNumberPopOver.isOpen && phoneNumberPopOver?.for == 'PerformerTelephone-address'|| false}	
																		onClose={closePhoneNumberInlinePopOver}
																		title='Phone Number'
																		onPopOverOpen={(e) => openPhoneNumberInlinePopOver(e, 'PerformerTelephone-address')}
																		event={phoneNumberPopOver?.event}	
																	/>
																</div>
																	}
																</Box>	
															}
														</>
													)}
												/>
												{contractForm.formState?.errors
													?.performerPhoneId?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors
																?.performerPhoneId?.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
										<td>

										</td>
									</tr>
								</table>
							</div>
							<br></br>
							<table
								width={'100%'}
								border={1}
								cellPadding={0}
								cellSpacing={0}
								className={`${styles.contractFormsInner} keep-together`}
							>
								<tr>
									<td>
										<b>I am represented on this production by talent agency:</b>
										<FormControl
											className={styles.tableForms}
											fullWidth
										>
											<Controller
												control={contractForm.control}
												key={`talent-agency-cr-${contractForm.watch(
													'talentAgencyId.value'
												)}`}
												name='talentAgencyId'
												render={({ field }) => (
													<>
														<LookAheadSelect
															data-cy='ad_agency'
															{...field}
															placeholder={!props?.isPrint ? 'Ad-Agency' : ''}
															filterProperty='CompanyNames.Name'
															APICaller={getCompanyBasicDetails}
															optionGenerator={companyOptionGenerator}
															defaultFilter={AGENT_REPRESENTATIVE_FILTER}
															isClearable={true}
															isDisabled={props?.isPrint}
															onChange={(e: any) => {
																field.onChange(e);
															}}
															onOptionLoaded={(options) => {
																if(options?.length && !contractForm.watch('talentAgencyId.value')){
																	contractForm.setValue('talentAgencyId', options[0], { shouldDirty: true});									
																	contractForm.trigger('talentAgencyId', { shouldFocus: true});																					
																}
															}}
															cacheOptions
															defaultOptions
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
														{!props?.isPrint && (
															<Box sx={{mt:0.5}}>
																<AddCompanyInline
																	title='Company'
																	companyType={{ mainType: 'AD AGENCY' as CompanyMainType, subType: [] }} />
															</Box>
														)}
													</>
												)}
											/>
											{contractForm.formState.errors?.talentAgencyId
												?.value?.message && (
												<FormHelperText
													error={true}
													data-cy='errorHelper_ad_agency'
												>
													{
														contractForm.formState?.errors
															?.talentAgencyId.value.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
									<td>
															Agent’s commission % to be included in gross pay
										<FormControl
											className={styles.tableForms}
											fullWidth
										>
											<Controller
												control={contractForm.control}
												name='talentAgencyCommission'
												defaultValue=""
												render={({ field }) => (	
													<NabuTextbox
														{...field}
														placeholder={!props?.isPrint ? 'Commission' : ''}
														disabled={props?.isPrint}
														onChange={e => {
															const value = Number(e?.target?.value);
															if(Number(value) >=0 && Number(value) <= 100){
																numberOnChangeEventHandler(e, field.onChange);
															}
														}}
													/>
												)}
											/>
											{contractForm.formState.errors?.talentAgencyCommission
												?.message && (
												<FormHelperText
													data-cy='errorHelper-talentAgencyCommission'
													error={true}
												>
													{
														contractForm.formState?.errors?.talentAgencyCommission
															.message
													}
												</FormHelperText>
											)}
										</FormControl>
									</td>
								</tr>
							</table>
							<br></br>
							<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
								<Box display={'flex'} alignItems={'flex-start'}>
									<Controller
										control={contractForm.control}
										name='isExtraPerformer'
										defaultValue={false}
										render={({ field }) => (
											<div
												style={{ userSelect: 'none' }}
												onClick={() => {
													(!props?.isPrint) && field.onChange(!field.value);
													contractForm.setValue('isPaymentAtExtraPerformerAddress', !contractForm.watch('isExtraPerformer'));
												}}
											>
												<Box display={'flex'} alignItems={'flex-start'}>
													<input
														{...field}
														value={field.value + ''}
														checked={field.value}
														type='checkbox'
														onChange={(e) => {
															field.onChange(e);
															contractForm.setValue('isPaymentAtExtraPerformerAddress', !contractForm.watch('isExtraPerformer'));
														}}
														disabled={props?.isPrint}
														style={{ marginTop: '3px' }}
													/>
													<span> To Extra Performer c/o: (name) </span>
												</Box>
											</div>
										)}
									/>
								</Box>
								<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'200px'}}>
									<Controller
										control={contractForm.control}
										name='extraPerformerName'
										defaultValue=''
										render={({ field }) => (
											<NabuTextbox
												{...field}
												placeholder={!props?.isPrint ? 'Name' : ''}
												disabled={props?.isPrint || !contractForm.watch('isExtraPerformer')}
											/>
										)}
									/>
								</FormControl>
								<span>(address)</span>
								<FormControl fullWidth className={styles.tableForms} style={{maxWidth:'350px'}}>
									<Controller
										control={contractForm.control}
										name='extraPerformerAddress'
										defaultValue=''
										render={({ field }) => (
											<NabuTextbox
												{...field}
												placeholder={!props?.isPrint ? 'Address' : ''}
												disabled={props?.isPrint || !contractForm.watch('isExtraPerformer')}
											/>
										)}
									/>
								</FormControl>
							</div>
							<br></br>

							<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
								<tr>
									<td colSpan={2} style={{ background: '#515151', color: '#FFF' }}>
										<b>SPECIAL PROVISIONS</b>: 
									</td>
								</tr>
								<tr>
									<td colSpan={2}>
										{
											props?.isPrint ? (
												<p className={styles.descriptionPrintText}>{contractForm.watch('specialProvisions')}</p>
											) : (<div className={styles.textareaSection}> 
												<Controller
													control={contractForm.control}
													name='specialProvisions'
													defaultValue=''
													render={({ field }) => (
														<NabuBaseTextArea
															className={styles.specialProvision}
															data-cy='job_name_textbox'
															{...field}
															rows={5}
															disabled={props?.isPrint}
															placeholder={!props?.isPrint ? 'SPECIAL PROVISIONS' : ''}
														/>
													)}
												/>
												{contractForm.formState.errors?.specialProvisions
													?.message && (
													<FormHelperText
														data-cy='errorHelper-job-title-name'
														error={true}
													>
														{
															contractForm.formState?.errors?.specialProvisions
																.message
														}
													</FormHelperText>
												)}
											</div> )
										}
									</td>
								</tr>
								<tr>
									<td>
								Performer acknowledges that he or she has read all the terms and conditions in the Special Provisions herein and hereby agrees thereto
									</td>
									<td width={300}>
										<FormControl fullWidth className={styles.tableForms}>
											<NabuTextbox disabled = {true} placeholder="(Signature of Performer)"/>
										</FormControl>
									</td>
								</tr>
							</table>
							<br></br>
							<p><b>The CONFIDENTIAL INFORMATION PROVISION printed on the reverse side/page 2 hereof is a part of this contract.</b></p>
							<br></br>
							<div className='keep-together'>
								<p><b>All notices to Producer shall be addressed as follows:</b></p>
								<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
									<tr>
										<td width={200}>
								PRODUCER{ !props?.isPrint && (
												<Tooltip
													placement='right-end'
													title={'Signatory'}
												>
													<HelpOutline fontSize='inherit' />
												</Tooltip>)
											}
										</td>
										<td>
											<FormControl fullWidth className={styles.tableForms}>
												<Controller
													key={`commercial-assetId
																			-${contractForm.watch(`commercials.${0}.assetId.value`)}
																			`}
													control={contractForm.control}
													name='producerCompanyId'
													render={({ field }) => (
														<>
															<LookAheadSelect
																key={`commercial-assetId
															-${contractForm.watch(`commercials.${0}.assetId.value`)}`}
																{...field}
																placeholder={!props?.isPrint ? 'Producer' : ''}
																filterProperty='CompanyNames.Name'
																APICaller={(filterModel) => getAllSignatory(contractForm.watch(`commercials.${0}.assetId.value`),filterModel)}
																optionGenerator={companyOptionGenerator}
																isClearable={true}
																defaultFilter={PROD_COM_FILTER}
																onChange={(e: any) => {
																	field.onChange(e);
																	contractForm.setValue('producerCompanyAddressId', null, { shouldDirty: true});
																	contractForm.setValue('producerCompanyEmailId', '', { shouldDirty: true});
																	contractForm.trigger('producerCompanyAddressId');
																	contractForm.trigger('producerCompanyEmailId');
																	contractForm.setValue('producerPrintName','',{shouldDirty: true} );
																// contractForm.trigger('producerPrintName');
																}}
																isDisabled={props?.isPrint}
																cacheOptions
																defaultOptions
																className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
															/>
															{!props?.isPrint && (
																<Box sx={{mt:0.5}}>
																	<AddCompanyInline
																		title='Company' />
																</Box>
															)}
														</>
													)}
												/>
												{/* {contractForm.formState.errors?.producerCompanyId
																			?.message && (
																			<FormHelperText
																				error={true}
																				data-cy='errorHelper-producerCompanyId'
																			>
																				{
																					contractForm.formState?.errors
																						?.producerCompanyId.message
																				}
																			</FormHelperText>
																		)} */}
											</FormControl>
										</td>
										<td rowSpan={3} width={400}>
											<p>
								This contract is subject to all of the terms and conditions which
pertain to Extra Performers in the SAG-AFTRA Commercials
Contract.
											</p>
											<br></br>
											<p>
								This contract is subject to all of the terms and conditions which
pertain to Extra Performers in the SAG-AFTRA Commercials
Contract.
											</p>
										</td>
									</tr>
									<tr>
										<td width={200}>
								Producer Address
										</td>
										<td>
											<FormControl fullWidth className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													key={`producer-address-cr-${contractForm.watch(
														'producerCompanyId.value'
													)}`}
													name='producerCompanyAddressId'
													render={({ field }) => (
														<>
															<LookAheadSelect
																{...field}
																placeholder={!props?.isPrint ? 'Select Address' : ''}
																key={`producer-address-${contractForm.watch(
																	'producerCompanyId.value'
																)}-${contractForm.watch(
																	'producerCompanyAddressId.value'
																)}`}
																filterProperty='Line1'
																sortField='IsPrimary'
																sortDescending={true}
																onOptionLoaded={(options) => {
																	if(options?.length && !contractForm.watch('producerCompanyAddressId.value')){
																		contractForm.setValue('producerCompanyAddressId', options[0], { shouldDirty: true});
																		contractForm.trigger('producerCompanyAddressId', { shouldFocus: true});																				
																	}
																}}
																APICaller={(filterModel) =>
																	getAllAddressByCompany(
																		filterModel,
																		contractForm.watch(
																			'producerCompanyId.value'
																		) || ''
																	)
																}
																optionGenerator={
																	producerAddressOptionGenerator
																}
																isDisabled={
																	!contractForm.watch(
																		'producerCompanyId.value'
																	) || props?.isPrint
																}
																dropDownsize={50}
																isClearable={true}
																isSearchText={true}
																onChange={(e: any) => {
																	field.onChange(e);
																	contractForm.setValue('producerCompanyEmailId', '', { shouldDirty: true});
																	contractForm.trigger('producerCompanyEmailId');
																// onAddressChange(contractForm.watch('producerCompanyAddressId.value') || '', 'producer');
																}}
																cacheOptions={false}
																defaultOptions
																className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
															/>
															{
																<Box sx={{mt:1.3}}>
																	{(contractForm.watch('producerCompanyId.value')&& (!props.isPrint)) &&
																							<AddCompanyAddressInline
																								companyId={contractForm.watch('producerCompanyId.value')}
																								title = 'ADDRESS'
																								isOpen={addressPopOver?.isOpen && addressPopOver?.for == 'ProducerCompany-address'|| false}
																								event={addressPopOver?.event}
																								onAddressCreated={(d) => onProducerAddressCreated(d)}
																								onClose={() => setAddressPopOver({ isOpen: false})}
																								onPopOverOpen={(e) => setAddressPopOver({
																									isOpen: true,
																									event: e?.currentTarget,
																									for: 'ProducerCompany-address'
																								})}
																								isPersistent = {true} />
																	}
																</Box>
															}
														</>
													)}
												/>
												{contractForm.formState.errors?.producerCompanyAddressId
													?.value?.message && (
													<FormHelperText
														error={true}
														data-cy='errorHelper_producer'
													>
														{
															contractForm.formState?.errors
																?.producerCompanyAddressId.value.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
									</tr>
									<tr>
										<td>
								Producer Email
										</td>
										<td>
											<FormControl fullWidth className={styles.tableForms}>
												<Controller
													control={contractForm.control}
													name='producerCompanyEmailId'
													defaultValue=''
													render={({ field }) => (
														<>
															<NabuSelect
																{...field}
																key={`select-em-${contractForm.watch(
																	'producerCompanyAddressId.value'
																)}-${contractForm.watch('producerCompanyEmailId')}`}
																placeholder={!props?.isPrint ? 'Select Email' : ''}
																convertValueToUpperCase={false}
																enableEmptySelection={true}
																disabled={props?.isPrint || !contractForm.watch('producerCompanyAddressId.value')}
																options={getCompanyEmailOptions(contractForm.watch('producerCompanyAddressId.value'))}
																handleDefaultSelection={() => !contractForm.watch('producerCompanyEmailId')}
															/>
															{
																<Box sx={{mt:1.3}}>
																	{(contractForm.watch('producerCompanyAddressId.value') && (!props.isPrint)) &&
																						<AddEmailInline
																							formData={{ ...new EmailFormModel()}}
																							isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Producer-email-address'|| false}	
																							onSubmit={onProducerEmailInlineSubmit}
																							onClose={closeEmailInlinePopOver}
																							title='Email address'
																							onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Producer-email-address')}
																							event={emailPopOver?.event}
																						/>
																	}
																</Box>	
															}
														</>
													)}
												/>
												{contractForm.formState?.errors
													?.producerCompanyEmailId?.message && (
													<FormHelperText error={true}>
														{
															contractForm.formState?.errors
																?.producerCompanyEmailId?.message
														}
													</FormHelperText>
												)}
											</FormControl>
										</td>
									</tr>
								</table>
							</div>
							<br></br>
							<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
								<tr>
									<td width={200}>
								Producer (Print Name):
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												key={`producerPrintName_${contractForm.watch('producerCompanyId.label')}`}
												name='producerPrintName'
												render={({ field }) => (
													<>
														<NabuTextbox
															{...field}
															placeholder={!props?.isPrint ? 'Performer' : ''}
															disabled={props?.isPrint}
															value={contractForm.watch('producerCompanyId.label')}
															onChange={(e: any) => {
																field.onChange(e);

															}}
														/>
													</>
												)}
											/>
										</FormControl>
									</td>
									<td width={200}>
								Performer (Print Name):
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												key={`performerPrintName_${contractForm.watch('performerId.label')}`}
												name='performerPrintName'
												render={({ field }) => (
													<>
														<NabuTextbox
															{...field}
															placeholder={!props?.isPrint ? 'Performer' : ''}
															disabled={props?.isPrint}
															value={contractForm.watch('performerId.label')}
															onChange={(e: any) => {
																field.onChange(e);

															}}
														/>
													</>
												)}
											/>
										</FormControl>
									</td>
								</tr>
								<tr>
									<td>
								SIGNED BY PRODUCER:
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<NabuTextbox disabled = {true}placeholder="SIGNED BY PRODUCER"/>
										</FormControl>
									</td>
									<td>
								SIGNED BY PERFORMER:
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<NabuTextbox disabled = {true} placeholder="SIGNED BY PERFORMER"/>
										</FormControl>
									</td>
								</tr>

							</table>
							<br></br>
							<div className="keep-together">
								<p><b>MINORS ONLY</b></p>
								<p>If Extra Performer is a minor (under 21 years of age) this contract must be signed here by a parent or guardian.</p>
								<br></br>
								<p>I, the undersigned, hereby state that I am the parent/guardian of the above named Extra Performer and do
						hereby consent and give my permission to this agreement</p>

								<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
									<span>Signature of Parent/Guardian:</span>
									<FormControl className={styles.tableForms} fullWidth style={{maxWidth:'200px'}}>
										<NabuTextbox disabled = {true} placeholder=""/>
									</FormControl>
									<Box display={'flex'} alignItems={'flex-start'}>
										<Controller
											control={contractForm.control}
											name='guardian'
											// defaultValue={CONTRACT_GUARDIAN.GUARDIAN}
											render={({ field }) => (
												<div 
													style={{ display: 'inline-flex', alignItems: 'center'}}
												>
													<div
														onClick={() => (!props?.isPrint) && field.onChange(CONTRACT_GUARDIAN.MOTHER)}
														style={{ userSelect: 'none', display: 'inline-flex' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																checked={field?.value?.toUpperCase() === CONTRACT_GUARDIAN.MOTHER}
																onChange={() => field.onChange(CONTRACT_GUARDIAN.MOTHER)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>
																{CONTRACT_GUARDIAN.MOTHER}
															</span></Box>
													</div>
													<div
														onClick={() => (!props?.isPrint) && field.onChange(CONTRACT_GUARDIAN.FATHER)}
														style={{ userSelect: 'none', display: 'inline-flex' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																checked={field?.value?.toUpperCase() === CONTRACT_GUARDIAN.FATHER}
																onChange={() => field.onChange(CONTRACT_GUARDIAN.FATHER)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>
																{CONTRACT_GUARDIAN.FATHER}
															</span></Box>
													</div>
													<div
														onClick={() => (!props?.isPrint) && field.onChange(CONTRACT_GUARDIAN.GUARDIAN)}
														style={{ userSelect: 'none', display: 'inline-flex' }}
													>
														<Box display={'flex'} alignItems={'flex-start'}>
															<input
																{...field}
																checked={field?.value?.toUpperCase() === CONTRACT_GUARDIAN.GUARDIAN}
																onChange={() => field.onChange(CONTRACT_GUARDIAN.GUARDIAN)}
																type='checkbox'
																disabled={props?.isPrint}
																style={{ marginTop: '3px' }}
															/>
															<span>
																{CONTRACT_GUARDIAN.GUARDIAN}
															</span></Box>
													</div>
												</div>
											)}
										/>
									</Box>

								</div>
							</div>
							<hr></hr>

							<br></br>
							<div className='keep-together'>
								<h3 style={{textAlign:'center'}}>TIME SHEET & STANDARD PROVISIONS</h3>
								<h5 style={{textAlign:'center'}}>HOURS EMPLOYED</h5>
								<table width={'100%'} border={1} cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
									<tr style={{ background: '#808080' }}>
										<th style={{width:'110px'}}>Date</th>
										<th style={{ textAlign: 'center', maxWidth:'92px' }} colSpan={2}>WORKTIME
											<div 	style={{ display: 'flex', justifyContent: 'space-around', }} >
												<span>From</span> <span>To</span>{' '}
											</div>
										</th>
										<th style={{ textAlign: 'center', maxWidth:'92px' }} colSpan={2}>MEALS
											<div 	style={{ display: 'flex', justifyContent: 'space-around', }} >
												<span>From</span> <span>To</span>{' '}
											</div>
										</th>
										<th style={{ textAlign: 'center', maxWidth:'92px' }} colSpan={2}>MAKEUP/FITTING/TEST
											<div 	style={{ display: 'flex', justifyContent: 'space-around', }} >
												<span>From</span> <span>To</span>{' '}
											</div>
										</th>
										<th style={{ textAlign: 'center', maxWidth:'92px' }} colSpan={2}>TRAVEL TO LOC
											<div 	style={{ display: 'flex', justifyContent: 'space-around', }} >
												<span>From</span> <span>To</span>{' '}
											</div>
										</th>
										<th style={{ textAlign: 'center', maxWidth:'92px' }} colSpan={2}>TRAVEL FROM LOC
											<div 	style={{ display: 'flex', justifyContent: 'space-around', }} >
												<span>From</span> <span>To</span>{' '}
											</div>
										</th>
										<th style={{ textAlign: 'center', width:'70px' }} colSpan={2} >PERFORMER’S INITIALS</th>
										{!props?.isPrint && (	<th> </th>)}
									</tr>
									{workDayFieldArray.fields.map((field, index) => (
										<tr key={`work-field-${field.id}`}>
											<td width={110}>
												<FormControl
													fullWidth
													className={styles.tableForms}
												>
													<Controller
														control={contractForm.control}
														name={`workDays.${index}.date`}
														defaultValue=''
														render={({ field }) => (
															<NabuDatepicker
																{...field}
																disabled={props?.isPrint}
																placeholder={!props?.isPrint ? 'Date' : ''}
															/>
														)}
													/>
													{!!contractForm.formState?.errors?.workDays
														?.length && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.workDays[
																	index
																]?.date?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</td>
											<td style={{minWidth:'46px', maxWidth:'46px'}}>
												<FormControl
													fullWidth 
												>
													{props?.isPrint ? (
														<p className={styles.dateAndTimePrintText}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeStartAt`) || '', 'hh:mm aa')}</p>
													) : (<Controller
														control={contractForm.control}
														name={`workDays.${index}.workTimeStartAt`}
														render={({ field }) => (
															<NabuTimePicker
																{...field}
																ampm={true}
																disableOpenPicker={true}
																disabled={!(contractForm.watch(`workDays.${index}.date`))}
																className={styles.datePicForm}
															/>
														)}
													/>)}
													{!!contractForm.formState?.errors?.workDays
														?.length && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.workDays[
																	index
																]?.workTimeStartAt?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</td>
											<td style={{minWidth:'46px', maxWidth:'46px'}}>
												<FormControl
													fullWidth
												>
													{props?.isPrint ? (
														<p className={styles.dateAndTimePrintText} style={{padding:'8px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.workTimeEndAt`) || '', 'hh:mm aa')}</p>
													) : (<Controller
														control={contractForm.control}
														name={`workDays.${index}.workTimeEndAt`}
														render={({ field }) => (
															<NabuTimePicker
																{...field}
																ampm={true}
																disableOpenPicker={true}
																disabled={!(contractForm.watch(`workDays.${index}.date`))}
																className={styles.datePicForm}
															/>
														)}
													/>)}
													{!!contractForm.formState?.errors?.workDays
														?.length && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.workDays[
																	index
																]?.workTimeEndAt?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</td>
											<td style={{minWidth:'46px', maxWidth:'46px'}}>
												<FormControl
													fullWidth
												>
													{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.mealTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
													{props?.isPrint ? (
														<p className={styles.dateAndTimePrintText} style={{padding:'8px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.mealTimeStartAt`) || '', 'hh:mm aa')}</p>
													) : (<Controller
														control={contractForm.control}
														name={`workDays.${index}.mealTimeStartAt`}
														render={({ field }) => (
															<NabuTimePicker
																{...field}
																ampm={true}
																disableOpenPicker={true}
																disabled={!(contractForm.watch(`workDays.${index}.date`))}
																className={styles.datePicForm}
															/>
														)}
													/>)}
													{!!contractForm.formState?.errors?.workDays
														?.length && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.workDays[
																	index
																]?.mealTimeStartAt?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</td>
											<td style={{minWidth:'46px', maxWidth:'46px'}}>
												<FormControl 	fullWidth >
													{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.mealTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
													{props?.isPrint ? (
														<p className={styles.dateAndTimePrintText} style={{padding:'8px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.mealTimeEndAt`) || '', 'hh:mm aa')}</p>
													) : (<Controller
														control={contractForm.control}
														name={`workDays.${index}.mealTimeEndAt`}
														render={({ field }) => (
															<NabuTimePicker
																{...field}
																ampm={true}
																disableOpenPicker={true}
																disabled={!(contractForm.watch(`workDays.${index}.date`))}
																className={styles.datePicForm}
															/>
														)}
													/>)}
													{!!contractForm.formState?.errors?.workDays
														?.length && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.workDays[
																	index
																]?.mealTimeEndAt?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</td>
											<td style={{minWidth:'46px', maxWidth:'46px'}}>
												<FormControl fullWidth>
													{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.makeUpTimeStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
													{props?.isPrint ? (
														<p className={styles.dateAndTimePrintText} style={{padding:'8px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.makeUpTimeStartAt`) || '', 'hh:mm aa')}</p>
													) : (<Controller
														control={contractForm.control}
														name={`workDays.${index}.makeUpTimeStartAt`}
														render={({ field }) => (
															<NabuTimePicker
																{...field}
																ampm={true}
																disableOpenPicker={true}
																disabled={!(contractForm.watch(`workDays.${index}.date`))}
																className={styles.datePicForm}
															/>
														)}
													/>)}
													{!!contractForm.formState?.errors?.workDays
														?.length && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.workDays[
																	index
																]?.makeUpTimeStartAt?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</td>
											<td style={{minWidth:'46px', maxWidth:'46px'}}>
												<FormControl
													fullWidth
												
												>
													{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.makeUpTimeEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
													{props?.isPrint ? (
														<p className={styles.dateAndTimePrintText} style={{padding:'8px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.makeUpTimeEndAt`) || '', 'hh:mm aa')}</p>
													) : (<Controller
														control={contractForm.control}
														name={`workDays.${index}.makeUpTimeEndAt`}
														render={({ field }) => (
															<NabuTimePicker
																{...field}
																ampm={true}
																disableOpenPicker={true}
																disabled={!(contractForm.watch(`workDays.${index}.date`))}
																className={styles.datePicForm}
															/>
														)}
													/>)}
													{!!contractForm.formState?.errors?.workDays
														?.length && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.workDays[
																	index
																]?.makeUpTimeEndAt?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</td>
											<td style={{minWidth:'46px', maxWidth:'46px'}}>
												<FormControl
													fullWidth
													
												>
													{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeToLocationStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
													{props?.isPrint ? (
														<p className={styles.dateAndTimePrintText} style={{padding:'8px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeToLocationStartAt`) || '', 'hh:mm aa')}</p>
													) : (<Controller
														control={contractForm.control}
														name={`workDays.${index}.travelTimeToLocationStartAt`}
														render={({ field }) => (
															<NabuTimePicker
																{...field}
																ampm={true}
																disableOpenPicker={true}
																disabled={!(contractForm.watch(`workDays.${index}.date`))}
																className={styles.datePicForm}
															/>
														)}
													/>)}
													{!!contractForm.formState?.errors?.workDays
														?.length && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.workDays[
																	index
																]?.travelTimeToLocationStartAt?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</td>
											<td style={{minWidth:'46px', maxWidth:'46px'}}>
												<FormControl
													fullWidth
												>
													{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeToLocationEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
													{props?.isPrint ? (
														<p className={styles.dateAndTimePrintText} style={{padding:'8px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeToLocationEndAt`) || '', 'hh:mm aa')}</p>
													) : (<Controller
														control={contractForm.control}
														name={`workDays.${index}.travelTimeToLocationEndAt`}
														render={({ field }) => (
															<NabuTimePicker
																{...field}
																ampm={true}
																disableOpenPicker={true}
																disabled={!(contractForm.watch(`workDays.${index}.date`))}
																className={styles.datePicForm}
															/>
														)}
													/>)}
													{!!contractForm.formState?.errors?.workDays
														?.length && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.workDays[
																	index
																]?.travelTimeToLocationEndAt?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</td>
											<td style={{minWidth:'46px', maxWidth:'46px'}}>
												<FormControl
													fullWidth
												>
													{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeFromLocationStartAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
													{props?.isPrint ? (
														<p className={styles.dateAndTimePrintText} style={{padding:'8px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeFromLocationStartAt`) || '', 'hh:mm aa')}</p>
													) : (<Controller
														control={contractForm.control}
														name={`workDays.${index}.travelTimeFromLocationStartAt`}
														render={({ field }) => (
															<NabuTimePicker
																{...field}
																ampm={true}
																disableOpenPicker={true}
																disabled={!(contractForm.watch(`workDays.${index}.date`))}
																className={styles.datePicForm}
															/>
														)}
													/>)}
													{!!contractForm.formState?.errors?.workDays
														?.length && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.workDays[
																	index
																]?.travelTimeFromLocationStartAt?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</td>
											<td style={{minWidth:'46px', maxWidth:'46px'}}>
												<FormControl
													fullWidth
												>
													{/* <Controller
																				control={contractForm.control}
																				name={`workDays.${index}.travelTimeFromLocationEndAt`}
																				render={({ field }) => (
																					<NabuTimePicker
																						{...field}
																						ampm={true}
																						disabled={props?.isPrint}
																					/>
																				)}
																			/> */}
													{props?.isPrint ? (
														<p className={styles.dateAndTimePrintText} style={{padding:'8px'}}>{getLocaleDateTime(contractForm.watch(`workDays.${index}.travelTimeFromLocationEndAt`) || '', 'hh:mm aa')}</p>
													) : (<Controller
														control={contractForm.control}
														name={`workDays.${index}.travelTimeFromLocationEndAt`}
														render={({ field }) => (
															<NabuTimePicker
																{...field}
																ampm={true}
																disableOpenPicker={true}
																disabled={!(contractForm.watch(`workDays.${index}.date`))}
																className={styles.datePicForm}
															/>
														)}
													/>)}
													{!!contractForm.formState?.errors?.workDays
														?.length && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.workDays[
																	index
																]?.travelTimeFromLocationEndAt?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</td>
											<td colSpan={2} width={70} className={styles.intials}>
												<FormControl
													fullWidth
												>
													<Controller
														control={contractForm.control}
														name={`workDays.${index}.performersInitials`}
														render={({ field }) => (
															<NabuTextbox
																{...field}
																disabled={!(contractForm.watch(`workDays.${index}.date`))}
																placeholder={!props?.isPrint ? 'Initials' : ''}
															/>
														)}
													/>
													{!!contractForm.formState?.errors?.workDays
														?.length && (
														<FormHelperText error={true}>
															{
																contractForm.formState?.errors?.workDays[
																	index
																]?.performersInitials?.message
															}
														</FormHelperText>
													)}
												</FormControl>
											</td>
											{!props?.isPrint && (
																		
												<td>
													<div style={{display:'flex'}}>
														{workDayFieldArray.fields.length - 1 ===
																	index && (
															<NabuButton
																variant='text'
																onClick={onAddWorkDay}
															>
																<Add />
															</NabuButton>
														)}
														{workDayFieldArray?.fields.length > 1 && (
															<NabuButton
																variant='text'
																onClick={() => onRemoveWorkDay(index)}
															>
																<DeleteForever color='error' />
															</NabuButton>
														)}
													</div>
												</td>
											)}
										</tr>
									))}
								</table>
							</div>
							<br></br>

							<div className='keep-together'>
								<h4>STANDARD PROVISION </h4>
								<p><b>CONFIDENTIAL INFORMATION</b></p>
								<p>
						Confidential Information” means trade secrets, confidential data, and other non-public confidential proprietary information (whether or not
labeled as confidential) including any and all financial terms of and products involved in the production and any and all information regarding
the creative elements (including, without limitation, scripts and storyboards) whether communicated orally, in written form, or electronically.
Confidential information does not include information that was lawfully in Extra Performer’s possession prior to being disclosed in connection
with the employment of Extra Performer, is now, or hereafter becomes generally known to the public, or that Extra Performer rightfully obtained
without restriction from a third party. Extra Performer acknowledges that Extra Performer has and will become aware of certain Confidential
Information. Unless otherwise required by law, Extra Performer agrees that, without Producer’s prior written approval, Extra Performer shall
hold such Confidential Information in the strictest confidence and that Extra Performer will not disclose such Confidential Information to anyone (other than Extra Performer’s representatives in the course of their duties to Extra Performer, which representatives shall be bound by the
same restrictions as set forth in this Agreement) or utilize such Confidential Information for Extra Performer’s benefit or for the benefit of a third
party. Notwithstanding the foregoing, nothing herein shall prohibit Extra Performer from disclosing Confidential Information concerning Extra
Performer’s wages, hours, and other terms and conditions of employment as that term is defined under Section 7 of the National Labor Relations
Act. For clarity, except as set forth above, Producer may not demand or request that Extra Performer execute any non-disclosure agreement that
has not been approved in advance and in writing by the Union.
								</p>
							</div>
							<br></br>
							<table width={'100%'} border={1} 	cellPadding={0} cellSpacing={0} className={`${styles.contractFormsInner} keep-together`}>
								<tr>
									<td colSpan={4}>
										<div style={{display:'flex', alignItems:'center', gap:'10px', flexWrap:'wrap', textAlign:'left'}}>
											<b>LOAN-OUT CORPORATION</b>
											<Box display={'flex'} alignItems={'flex-start'}>
												<input type='checkbox' style={{ marginTop: '3px', }}/>
												<span>Performer is working through a loan-out Corporation.  </span>
											</Box>
											<span>Submit W-9 if incorporated.</span>
										</div>
									</td>
								</tr>
								<tr>
									<td width={200}>Corporation name:</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												name='loanOutId'
												key={`loan-out-ctr-${contractForm.watch('performerId.value')}`}
												render={({ field }) => (
													<>
														<LookAheadSelect
															{...field}
															key={`loan-out-${contractForm.watch('performerId.value')}-${locTrigger}`}
															placeholder={!props?.isPrint ? 'Loan Out Corporation' : ''}
															filterProperty='CompanyNames.Name'
															APICaller={getCompanyBasicDetails}
															optionGenerator={companyOptionGenerator}
															defaultFilter={getLoanOutCorpFilter()}
															isClearable={true}
															onChange={(e: any) => {
																field.onChange(e);
																contractForm.setValue('fedId',undefined,{shouldDirty: true});
															}}
															cacheOptions={false}
															defaultOptions
															className={props?.isPrint ? styles.lookAheadSelectContract :  styles.asyncSelect}
														/>
													</>
												)}
											/>
											{contractForm.formState.errors?.loanOutId?.message && (
												<FormHelperText
													error={true}
													data-cy='errorHelper_ad_agency'
												>
													{
														contractForm.formState?.errors?.loanOutId
															?.message
													}
												</FormHelperText>
											)}
										</FormControl> 
									</td>
									<td width={200}>FED-ID #</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												control={contractForm.control}
												key={`fedId_${contractForm.watch('fedId')}`}
												name = 'fedId'
												render={({ field }) => (
													<NabuTextbox 
														{...field}
														onChange={undefined}
														value = {contractForm.watch('fedId')}
														placeholder="FED-ID #" 
														disabled = {true}
													/>
												)}
																		
											/>
										</FormControl> 
									</td>
								</tr>
								<tr>
									<td>
								Performer’s Tel:
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												key={`select-phone-${contractForm.watch(
													'performerPhoneId'
												)}-${contractForm.watch(
													'performerId.value'
												)}`}
												control={contractForm.control}
												name='performerPhoneId'
												defaultValue=''
												render={({ field }) => (
													<>
														<NabuSelect
															{...field}
															key={`select-phone-${contractForm.watch(
																'performerPhoneId'
															)}-${contractForm.watch(
																'performerId.value'
															)}`}
															placeholder={!props?.isPrint ? 'Select Phone' : ''}
															convertValueToUpperCase={false}
															enableEmptySelection={true}
															disabled={props?.isPrint || !contractForm.watch('performerId.value')}
															options={getPerformerPhoneNumberOptions()}
															handleDefaultSelection={() => !contractForm.watch('performerPhoneId')}
														/>
														{
															<Box sx={{mt:1.3}}>
																{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																							<AddPhoneNumberInline
																								formData={{ ...new PhoneNumberFormModel()}}
																								onSubmit={onPerformerPhoneNumberInlineSubmit}
																								isOpen={phoneNumberPopOver.isOpen && phoneNumberPopOver?.for == 'PerformerTelephone-address'|| false}	
																								onClose={closePhoneNumberInlinePopOver}
																								title='Phone Number'
																								onPopOverOpen={(e) => openPhoneNumberInlinePopOver(e, 'PerformerTelephone-address')}
																								event={phoneNumberPopOver?.event}	
																							/>
																}
															</Box>	
														}
													</>
												)}
											/>
											{contractForm.formState?.errors
												?.performerPhoneId?.message && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors
															?.performerPhoneId?.message
													}
												</FormHelperText>
											)}
										</FormControl> 
									</td>
									<td>
								Performer’s Emai
									</td>
									<td>
										<FormControl fullWidth className={styles.tableForms}>
											<Controller
												key={`select-phone-${contractForm.watch(
													'performerId.value'
												)}-${contractForm.watch('performerEmailId')}`}
												control={contractForm.control}
												name='performerEmailId'
												defaultValue=''
												render={({ field }) => (
													<>
														<NabuSelect
															{...field}
															key={`select-phone-${contractForm.watch(
																'performerId.value'
															)}-${contractForm.watch('performerEmailId')}`}
															defaultCase={true}
															placeholder={!props?.isPrint ? 'Select Email' : ''}
															convertValueToUpperCase={false}
															enableEmptySelection={true}
															disabled={props?.isPrint || !contractForm.watch('performerId.value')}
															options={getPerformerEmailsOptions()}
															handleDefaultSelection={() => !contractForm.watch('performerEmailId')}
														/>
														{
															<Box sx={{mt:1.3}}>
																{(contractForm.watch('performerId.value') && (!props.isPrint)) &&
																						<AddEmailInline
																							formData={{ ...new EmailFormModel()}}
																							isOpen={emailPopOver.isOpen && emailPopOver?.for == 'Performer-email-address'|| false}	
																							onSubmit={onPerformerEmailInlineSubmit}
																							onClose={closeEmailInlinePopOver}
																							title='Email address'
																							onPopOverOpen={(e) => openEmailInlinePopOver(e, 'Performer-email-address')}
																							event={emailPopOver?.event}
																						/>
																}
															</Box>	
														}
													</>
												)}
											/>
											{contractForm.formState?.errors
												?.performerEmailId?.message && (
												<FormHelperText error={true}>
													{
														contractForm.formState?.errors
															?.performerEmailId?.message
													}
												</FormHelperText>
											)}
										</FormControl> 
									</td>
								</tr>

							</table>

						</div>
					</div>
				</>
				{/* Main Contract Form End */}
			</ContractMutationWrapper>
		</>
	);
};

export default TeamExtraContract;
