import store from '@/store';
import { backwardNavigation, clearBackNavigation, forwardNavigation, setProfileUrl } from '@/store/app/reducer';
import { useLocation, useNavigate } from 'react-router-dom';

const useBackNavigation = () => {
	const location = useLocation();
	const navigation = useNavigate();
	const currentPath = location.pathname + location.search;

	const startBackNavigation = () => {
		store.dispatch(setProfileUrl({url: '', blobId: '', profileType: ''}));
		store.dispatch(forwardNavigation({ currentPath, visible: true }));
	};

	const startBackNavigationWithUrl = (backUrlPath?: string) => {
		store.dispatch(forwardNavigation({ currentPath: backUrlPath || currentPath, visible: true }));
	};

	const goBack = () => {
		const urlStack = [...store.getState().appContext.urlStack];
		const visit = urlStack.pop();
		
		if (visit) navigation(visit);

		store.dispatch(backwardNavigation({ updatedUrlStack: urlStack }));
	};

	const clear = () => {
		store.dispatch(clearBackNavigation());
	};

	return { startBackNavigation, goBack, clear, startBackNavigationWithUrl };
};

export default useBackNavigation;