import NabuButton from '@/common/components/atoms/button/button';
import { ILookAheadSelectOption } from '@/common/components/atoms/lookAheadSelect/lookAheadSelect';
import { OPERATORS } from '@/common/enums';
import { StatusCodes } from '@/common/enums/StatusCodes';
import useNotification from '@/common/hooks/useNotification';
import { IFilterModel, IFilterOption } from '@/common/types/filterModel';
import {
	getCompanyDisplayName,
	getNotificationText,
} from '@/common/utils/utils';
import { getCompanyBasicDetails } from '@/services/company';
import { createAgentInformation } from '@/services/person';
import AddIcon from '@mui/icons-material/Add';
import { Autocomplete, Box, FormControl, FormHelperText, FormLabel, Grid, Popover, Stack, TextField, createFilterOptions } from '@mui/material';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import style from './addPerson.module.scss';
import AddCompanyInline from './addCompanyInline';
import { CompanyMainType } from '@/common/types/company';

interface Props {
	onCompanyCreated: CallableFunction;
	onClose: VoidFunction;
	isOpen: boolean;
	event?: HTMLButtonElement | null;
	onPopOverOpen: CallableFunction;
	title?: string;
	personId: string;
	isPersistent?: boolean;
}

const AddTalentAgentInline = (props: Props) => {
	const { onCompanyCreated } = props;
	const {
		control,
		reset,
		getValues,
		watch,
		setValue,
		handleSubmit
	} = useForm<{company : ILookAheadSelectOption}>();
	const notification = useNotification();
	const [, setInputValue] = useState<string>('');
	const [companyOptions, setCompanyOptions] = useState<ILookAheadSelectOption[]>([]);
	const [companyOptionsFilter, setCompanyOptionsFilter] = useState<IFilterModel>({
		pageNumber: 0,
		pageSize: 5,
		sortDescending: false,
		sortField: 'Id',
	});

	const { mutate: AddAgentInfo, isLoading: isAgentCreating } = useMutation({
		mutationFn: createAgentInformation,
		onError: () =>
			notification.notify({
				message: getNotificationText('Agent Information', 'CREATE', true),
				type: 'error',
			}),
	});

	const filter = createFilterOptions<ILookAheadSelectOption>();
	const onClose = () => {
		props?.onClose && props?.onClose();
		reset();
	};

	const onSubmit = () => {

		const addAgentLogic = (talentAgency: ILookAheadSelectOption) => AddAgentInfo(
			{
				personId: props?.personId,
				talentAgencyId: talentAgency?.value,
				representativeType: 'TALENT AGENT',
				talentAgency,
			},
			{
				onSuccess: (res) => {	
					if(res.status === StatusCodes.OK){
						notification.notify({
							message: getNotificationText('Agent Information', 'CREATE'),
							type: 'success',
						});
						onCompanyCreated && onCompanyCreated({
							value: talentAgency.value,
							label: talentAgency.label,
						});
						onClose && onClose();
						reset();
						setValue('company', { label: '', value: ''});
						setInputValue('');
					}
					else {
						notification.notify({
							message: getNotificationText('Agent Information', 'CREATE', true),
							type: 'error',
						});
					}
				},
			});		

		const talentAgency = { label: getValues().company.label ?? '', value: getValues().company.value ?? ''};

		// if(inputValue) {
		// 	AddCompanyMutate({
		// 		companyNames: [{ isDisplayName: true, nameType: 'legalName', name: inputValue}],
		// 		companyType: { mainType: 'AGENT/REPRESENTATIVE', subType: []},
		// 		is7MilesClient: false,
		// 		isIp360Client: false,
		// 		isJPCAuthorizer: false,
		// 		isUSCompany: false,
		// 		website: '',
		// 	}, {
		// 		onSuccess: (res) => {
		// 			if (res.data?.error?.errorType === ErrorType.DUPLICATE_NAME) {
		// 				const companyName = res.data.error?.errorDetails
		// 					.map((detail) => detail)
		// 					.join(', ');
		// 				notification.notify({
		// 					message: `The company name(s) ${companyName} already exists.`,
		// 					type: 'error',
		// 				});
		// 			} else if (res.status === StatusCodes.OK) {
		// 				notification.notify({
		// 					message: getNotificationText('Company', 'CREATE'),
		// 					type: 'success',
		// 				});
		// 				addAgentLogic &&
		// 				addAgentLogic({
		// 					value: res?.data?.id ?? '',
		// 					label: talentAgency?.label || inputValue,
		// 				});
		// 				reset();
		// 			} else {
		// 				notification.notify({
		// 					message: 'Error While Adding Company.',
		// 					type: 'error',
		// 				});
		// 			}
		// 		}
		// 	}
		// 	);
		// } else {
		// 	if(talentAgency?.label && talentAgency?.value) addAgentLogic(talentAgency);
		// }
		if(talentAgency?.label && talentAgency?.value) addAgentLogic(talentAgency);
	};

	const lookAheadOptionGenerator = (responseData) =>
		responseData?.map((d) => ({
			value: d?.id,
			label: getCompanyDisplayName(d?.companyNames),
		}));

	const lookAheadDefaultFilter: IFilterOption = {
		isCaseSensitive: false,
		operator: OPERATORS.EQUAL,
		property: 'CompanyType.MainType',
		value: 'AGENT/REPRESENTATIVE',
	};

	useQuery({
		queryKey: ['talent-agency', companyOptionsFilter],
		queryFn: () => getCompanyBasicDetails(companyOptionsFilter),
		refetchOnWindowFocus: false,
		onSuccess: (res) => {
			if(res?.status === StatusCodes.OK) {
				setCompanyOptions(lookAheadOptionGenerator(res?.data?.records));
			}
		},
	});

	return (
		<>
			<div
				onClick={(e: any) => props?.onPopOverOpen(e)}
				className={style.addIcon}
				style={{ marginTop: 7 }}
			>
				<AddIcon />
				{props.title ? props.title : 'Talent Agent'}
			</div>
			<Box>
				<Popover
					open={props?.isOpen || false}
					anchorEl={props?.event}
					onClose={!props?.isPersistent ? props?.onClose : undefined}
					slotProps={{ paper: { className: 'InlinePopover' } }}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'left',
					}}
				>
					<div className={style.popoverSection}>
						<Box>
							<form>
								<Grid container columnSpacing={1} className={style.talentContainer}>
									<Grid item xs={8}>
										<FormControl fullWidth={true}>
											<FormLabel>Company Name</FormLabel>
											<Controller
												control={control}
												rules={{
													required: 'Required',
												}}
												name='company'
												defaultValue={ { label: '', value: ''}}
												render={({ field }) => (
													<>
														{/* <LookAheadSelect
															{...field}
															placeholder='TALENT AGENCY'
															filterProperty='CompanyNames.Name'
															APICaller={getCompanyBasicDetails}
															optionGenerator={lookAheadOptionGenerator}
															defaultFilter={[lookAheadDefaultFilter]}
															onChange={(e: any) => field.onChange(e)}
															cacheOptions
															defaultOptions
															isClearable={true}
														/> */}
														<Autocomplete
															freeSolo
															options={companyOptions}
															{...field}
															renderInput={(params) => <TextField {...params} placeholder='TALENT AGENCY' />}
															getOptionLabel={(option) => {
																// Value selected with enter, right from the input
																if (typeof option === 'string') {
																	return option;
																}
																// Regular option
																return option.label;
															}}															
															onChange={(e: any, op) => {
																if(typeof op === 'string')
																	field.onChange({ label: op, value: op});
																else 
																	field.onChange(op || e);																
															}}
															onInputChange={(_, inputValue) => {														
																setCompanyOptionsFilter({
																	...companyOptionsFilter,
																	filters: [
																		lookAheadDefaultFilter,
																		{
																			isCaseSensitive: false,
																			operator: OPERATORS.CONTAINS,
																			property: 'CompanyNames.Name',
																			value: inputValue?.trim() || ''
																		}
																	]
																});
															}}
															filterOptions={(options, params) => {
																const filtered = filter(options, params);
												
																const { inputValue } = params;
																// Suggest the creation of a new value
																const isExisting = options.some((option) => inputValue === option.label);
																if (inputValue !== '' && !isExisting && companyOptions?.length === 0) {																	
																	// filtered.push({
																	// 	value: inputValue?.toUpperCase(),
																	// 	label: 'Above entered company is not there, plese click on Add Company',
																	// });
																	setInputValue(inputValue?.toUpperCase() || '');
																}												
																return filtered;
															}}
															getOptionDisabled={(op) => op?.label?.toUpperCase() === 'NO OPTIONS'}
														/>
													</>
												)}
											/>
										</FormControl>
									</Grid>
									<Grid item xs={4} className={style.addTalent}>
										<FormControl fullWidth>
											<FormLabel>&nbsp;</FormLabel>
											<Stack direction='row' spacing={1}>
												{companyOptions?.length != 0 ? 
													<NabuButton
														variant={'filled'}
														data-cy='addCOntact-add-staff-Button'
														disabled={(!watch('company.value') || companyOptions?.length === 0) || (isAgentCreating)}
														// disabled={(!watch('company.value') && !inputValue) || (isAgentCreating || isCompanyCreating)}
														onClick={handleSubmit(onSubmit)}
													>
												Save
													</NabuButton> : 
													<AddCompanyInline
														title='Company'
														companyType={{ mainType: 'AGENT/REPRESENTATIVE' as CompanyMainType, subType:[]}}
													/>
												}
												<NabuButton
													onClick={onClose}
													variant='outlined'
													data-cy='addCOntact-form-searchPerson-cancel'
												>
												Cancel
												</NabuButton>
											</Stack> 
										</FormControl>
									</Grid>
									{companyOptions?.length === 0 && <Grid item xs={8}>
										<FormHelperText data-cy='vendor_required' error={true}>
										Company Name is not there, click on Add Company
										</FormHelperText>
									</Grid>}
								</Grid>								
							</form>
						</Box>
					</div>
				</Popover>
			</Box>
		</>
	);
};

export default AddTalentAgentInline;
