import NabuButton from '@/common/components/atoms/button/button';
import TextEditor from '@/modules/dashboard/components/text-editor';
import { Add, Edit, Save } from '@mui/icons-material';
import { FormControl, Grid, Stack } from '@mui/material';
import { useState } from 'react';
import styles from './dealPoints.module.scss';
import { useParams } from 'react-router-dom';
import { IFormState } from '@/common/types/asset';
import { IGeneralNote } from '@/common/types/contract';
import { HttpStatusCode } from 'axios';
import { useMutation, useQuery } from 'react-query';
import {
	getContractContractSpecialProvision,
	getContractFreeForm,
	getContractNote,
	mutateContractFreeForm,
	mutateContractNote,
	mutateContractSpecialProvision,
} from '@/services/contract';
import useNotification from '@/common/hooks/useNotification';
import { getNotificationText, notificationMessageForFormOpenState } from '@/common/utils/utils';
import { Controller, useForm } from 'react-hook-form';
import { Contract_Note_Type } from '@/common/constants/constants';
import { useSelector } from 'react-redux';
import store, { IStore } from '@/store';
import { setFormName, setIsEditOpen } from '@/store/app/reducer';

interface Props {
	noteType: 'Special Provisions' | 'Free Form' | 'Notes';
}

const ContractNoteForm = (props: Props) => {
	const contractId = useParams().contractId || '';
	const notification = useNotification();
	const appContext = useSelector((state: IStore) => state.appContext);

	const [freeFormData, setFreeFormData] = useState<IGeneralNote>();
	const [modelState, setModelState] = useState<IFormState<IGeneralNote>>();

	const noteForm = useForm<IGeneralNote>();

	const FUNCTIONS_MAPPING = {
		MUTATE: {
			[Contract_Note_Type.SPECIAL_PROVISIONS]: (payload: IGeneralNote) =>
				mutateContractSpecialProvision(contractId, payload),
			[Contract_Note_Type.FREE_FORM]: (payload: IGeneralNote) =>
				mutateContractFreeForm(contractId, payload),
			[Contract_Note_Type.NOTES]: (payload: IGeneralNote) =>
				mutateContractNote(contractId, payload),
		},
		FETCH: {
			[Contract_Note_Type.SPECIAL_PROVISIONS]: () =>
				getContractContractSpecialProvision(contractId),
			[Contract_Note_Type.FREE_FORM]: () => getContractFreeForm(contractId),
			[Contract_Note_Type.NOTES]: () => getContractNote(contractId),
		},
	};

	// Helper functions
	const onClickUpdateNote = () => {
		if(!appContext?.isEditOpen) {
			store.dispatch(setFormName(`${props?.noteType}`));
			store.dispatch(setIsEditOpen(true));

			noteForm.setValue('description', freeFormData?.description || '', {
				shouldDirty: true,
			});
			setModelState((prev) => ({ ...prev, isOpen: true, for: 'update-note' }));
		} else {
			showEditSectionNotification();
		}
	};

	const onCloseNoteForm = () => {
		setModelState({});
		noteForm.setValue('description', '', { shouldDirty: true });
		fetchNoteDetail();
		store.dispatch(setFormName(''));
		store.dispatch(setIsEditOpen(false));
	};

	const showEditSectionNotification = () => {
		notification.notify({
			type: 'error',
			message: notificationMessageForFormOpenState(appContext?.formName),
		});
	};

	// API and Filter logics
	const { refetch: fetchNoteDetail } = useQuery({
		queryKey: [props.noteType, contractId],
		queryFn: FUNCTIONS_MAPPING.FETCH[props.noteType],
		refetchOnWindowFocus: false,
		onSuccess: (res) => {
			if (
				!(
					res?.status >= HttpStatusCode.Ok &&
					res?.status < HttpStatusCode.MultipleChoices
				)
			) {
				notification.notify({
					type: 'error',
					message: getNotificationText('Contract Free Form', 'FETCHING', true),
				});
				return;
			}
			setFreeFormData(res?.data);
		},
		onError: () => {
			notification.notify({
				type: 'error',
				message: getNotificationText('Contract Free Form', 'FETCHING', true),
			});
		},
	});

	const { mutate: mutateNoteData, isLoading: isMutationLoading } = useMutation({
		mutationFn: FUNCTIONS_MAPPING.MUTATE[props.noteType],
		onSuccess: (res) => {
			if (res?.status === HttpStatusCode.Ok) {
				notification.notify({
					message: getNotificationText('Contract Free Form', 'UPDATE'),
					type: 'success',
				});
				onCloseNoteForm();
			} else
				notification.notify({
					message: getNotificationText('Contract Free Form', 'UPDATE', true),
					type: 'error',
				});
		},
		onError: () => {
			notification.notify({
				message: getNotificationText('Contract Free Form', 'UPDATE', true),
				type: 'error',
			});
		},
	});

	const onSubmitNote = (data: IGeneralNote) => {
		const submitData: IGeneralNote = {
			...freeFormData,
			...data,
		};
		mutateNoteData(submitData);
	};

	return (
		<>
			<div className={styles.sectionRightsUsedExcluvity}>
				<Grid item container columnSpacing={3} sx={{ mb: 2 }}>
					<Grid item md={6}></Grid>
					<Grid item md={6} dir='rtl'>
						<NabuButton variant='filled' onClick={onClickUpdateNote}>
							{props.noteType} {!freeFormData?.description ? <Add /> : <Edit />}
						</NabuButton>
					</Grid>
				</Grid>
				{modelState?.isOpen && modelState?.for == 'update-note' ? (
					<Grid item container columnSpacing={3} p={2}>
						<div className={styles.inlineEditDealPoint}>
							<Grid item md={12}>
								<h3>
									{!freeFormData?.description ? 'Add' : 'Edit'} {props.noteType}
								</h3>
							</Grid>
							<form onSubmit={noteForm.handleSubmit(onSubmitNote)}>
								<Grid item md={12}>
									<FormControl fullWidth sx={{ mt: 1, mb: 1 }}>
										<Controller
											control={noteForm.control}
											name='description'
											defaultValue=''
											render={({ field }) => (
												<TextEditor
													isNoteEditor={
														modelState?.isOpen &&
														modelState?.for == 'update-note'
													}
													{...field}
													initialValue={freeFormData?.description || ''}
													onChange={(e) => field.onChange(e)}
												/>
											)}
										/>
									</FormControl>
								</Grid>
								<Grid xs={12} dir='rtl' sx={{ mb: 1, mt:2 }}>
									<Stack direction='row' columnGap={1}>
										<NabuButton
											variant='outlined'
											data-cy='cancel_btn'
											shouldHandleConfirmAction={noteForm.formState.isDirty}
											onClick={onCloseNoteForm}
										>
											Cancel
										</NabuButton>
										<div>
											<NabuButton
												variant='filled'
												type='submit'
												disabled={isMutationLoading}
											>
												{!isMutationLoading ? (
													<>
														Save <Save />
													</>
												) : (
													<>Saving</>
												)}
											</NabuButton>
										</div>
									</Stack>
								</Grid>
							</form>
						</div>
					</Grid>
				) : (
					<div className={styles.addRowHeaderExcluvity}>
						<Grid item container columnSpacing={3} sx={{ p: 2 }}>
							<Grid
								item
								md={12}
								sx={{ pt: 1 }}
								className={styles.specialprovision}
							>
								<p
									dangerouslySetInnerHTML={{
										__html: freeFormData?.description || '',
									}}
								></p>
							</Grid>
						</Grid>
					</div>
				)}
			</div>
		</>
	);
};
export default ContractNoteForm;
