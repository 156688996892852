import { configureStore } from '@reduxjs/toolkit';
import userContextReducer, { setUserContext } from './auth/reducer';
import appContextReducer from './app/reducer';
import companyContextReducer from './company/reducer';
import personContextReducer from './people/reducer';
import contractContextReducer from './contract/reducer';
import { IUserContextModel, IUserProfileModel } from './auth/types';
import { IAppContextModel } from './app/types';
import { ICompanyContext } from './company/types';
import { IPersonContext } from './people/types';
import { IElementContext } from './element/types';
import { setModuleName } from '@/services/common';
import { IContractContext } from './contract/types';

export interface IStore {
	userContext: IUserContextModel;
	appContext: IAppContextModel;
	companyContext: ICompanyContext;
	peopleContext: IPersonContext;
	elementContext: IElementContext;
	contractContext: IContractContext;
}

const store = configureStore({
	reducer: {
		userContext: userContextReducer,
		appContext: appContextReducer,
		companyContext: companyContextReducer,
		peopleContext: personContextReducer,
		contractContext: contractContextReducer,
	}
});

const restoreDataFromLocal = () => {
	const userInfo = localStorage.getItem('user_info');
	const accessToken = localStorage.getItem('access_token');
	const moduleName = localStorage.getItem('moduleName');
	if (userInfo) {
		const parsed: IUserProfileModel = JSON.parse(userInfo);
		store.dispatch(setUserContext({
			isAuthenticated: accessToken ? true : false,
			userInfo: parsed
		}));
	}
	if (moduleName) {
		setModuleName(moduleName);
	}
};

restoreDataFromLocal();

export default store;