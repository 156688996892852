import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { ICompanyContext, ISelectedCompany } from './types';

const initialState: ICompanyContext = {
	totalCompany: 0,
};

export const companyContextSlice = createSlice({
	name: 'companyContect',
	initialState,
	reducers: {		
		setTotalCompany: (state, action: PayloadAction<{totalCompany: number}>) => {
			state.totalCompany = action.payload.totalCompany;
		},
		setSelectedCompany: (state, action: PayloadAction<ISelectedCompany>) => {
			state.selectedCompany = action.payload;
		},
	}
});

export const { setTotalCompany, setSelectedCompany} = companyContextSlice.actions;
export default companyContextSlice.reducer;